import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { CgArrowsExchange } from 'react-icons/cg';
import { RiInformationLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import { setBotTerminationOptions } from '../../store/actions/bot';
import { epicFlowGradientColors } from '../../theme';
import axios from '../../utilities/axios';
import { usdFormatter } from '../../utilities/currencyConverter';
import { calculateExchangeID } from '../../utilities/exchangeHelpers';
import TerminationCard from '../cards/terminationCard';
import EFButton from '../UI/Button/efButton';

const OandaTermination = props => {
  // if option 1 nothing is being selected otherwise select the current option when modal opens
  const [selected, setSelected] = useState();

  const [loading, setLoading] = useState(false);

  const [terminationOptions, setTerminationOptions] = useState([
    `Stop the bot now without buying/selling anything further.`,
    `Close the position and stop the bot.`,
    `Stop the bot after a successful run.`,
    `Close the position when max loss amount is reached.`
  ]);

  const { colorMode, toggleColorMode } = useColorMode();

  const [radioValue, setRadioValue] = useState();

  const [limitInput, setLimitInput] = useState('');

  const dispatch = useDispatch();

  const [isRestored, setIsRestored] = useState(false);

  const botState = useSelector(state => state.bot);

  const authState = useSelector(state => state.auth);

  const [isReviewed, setIsReviewed] = useState(false);

  const [isTerminated, setIsTerminated] = useState(false);

  const [isTerminationError, setIsTerminationError] = useState(false);

  const [maxLossAmountInput, setMaxLossAmountInput] = useState('');

  const [mlaError, setMlaError] = useState(null);

  const [mlaGreaterError, setMlaGreaterError] = useState(false);

  const userState = useSelector(state => state.user);

  const handleInput = value => {
    value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    return value;
  };

  //   const handleChange = useCallback(
  //     event => {
  //       let v = event.target.value;
  //       console.log(v);
  //       v = handleInput(v);
  //       setMaxLossAmountInput(v);
  //       if (Number(v) < Number(props.marginUtilized)) {
  //         setMlaError(
  //           `This bot has already used ${props.quoteSpend}. The amount should be equal or greater than current.`
  //         );
  //         return;
  //       }
  //       if (Number(v) > Number(userState.oandaData.marginAvailable + props.marginUtilized)) {
  //         setMlaError(
  //           `Maximum loss amount should be less than your the current margin utilized for this bot + margin available in your account. ( ${usdFormatter.format(
  //             userState.oandaData.marginAvailable + props.marginUtilized
  //           )} )`
  //         );
  //         return;
  //       }
  //       setMlaError(null);
  //     },
  //     [userState.oandaData.marginAvailable]
  //   );

  const liftMaxLossRestriction = useCallback(async () => {
    let body = {
      bot_id: props.botID,
      max_loss_amount: 0,
      uuid: authState.id,
      exchange_id: calculateExchangeID(authState.currentOption)
    };
    const API_URL = process.env.REACT_APP_API_URL;
    const response = await axios.post(`${API_URL}editfxbotsettings`, body);
    //TODO check response data here
    // console.log('response ', response);
    if (response.data.error) {
      setLoading(false);
      setIsReviewed(true);
      setIsTerminationError(response.data.error);
      return;
    }
    setLoading(false);
    setIsTerminated(true);
    setIsReviewed(true);
    // console.log(response.data);
    // dispatch(setBotTerminationOptions(response.data));
  }, [maxLossAmountInput]);

  const handleChange = event => {
    let v = event.target.value;
    v = handleInput(v);
    setMaxLossAmountInput(Number(v));
    if (v === '') {
      setMaxLossAmountInput();
      setMlaError(null);
      setMlaGreaterError(false);
    }
    // else if (Number(v) < Number(props.marginUtilized)) {
    //   setMlaError(
    //     `This bot has already used ${props.quoteSpend}. The amount should be equal or greater than current.`
    //   );
    //   setMlaGreaterError(false);
    //   return;
    // }
    else if (Number(v) < Math.abs(Number(props.currentPlayGain))) {
      setMlaError(
        `Maximum loss amount should be greater than your current loss for this bot. ( ${usdFormatter.format(
          props.currentPlayGain
        )} )`
      );
      setMlaGreaterError(true);
      return;
    }
    // else if (Number(v) < Number(props.marginUtilized)) {
    //   setMlaError(
    //     `This bot has already used ${props.quoteSpend}. The amount should be equal or greater than current.`
    //   );
    //   setMlaGreaterError(false);
    //   return;
    // }
    setMlaGreaterError(false);
    setMlaError(null);
  };

  useEffect(() => {
    if (mlaGreaterError) {
      setMlaError(
        `Maximum loss amount should be greater than your the current loss for this bot. ( ${usdFormatter.format(
          props.currentPlayGain
        )} )`
      );
    }
  }, [props.currentPlayGain]);

  //most likely termination actions will be handled here instead of redux. However, when current bot status is updated, trading bot page
  //will be needing to be updated too. <== This is where redux should be involved.

  const handleSelection = useCallback(option => {
    setSelected(option);
    if (option !== 5) setMaxLossAmountInput('');
  }, []);

  useEffect(() => {
    if (props.terminatedOption === 3) {
      setSelected(props.terminatedOption + 1);
    } else if (props.maxLossAmount !== 0) {
      setSelected(5);
    } else if (props.terminatedOption === 0 || props.terminatedOption === 4) {
      setSelected();
    }
    setMaxLossAmountInput('');
    setIsReviewed(false);
    setIsTerminationError(false);
    setIsTerminated(false);
    setIsRestored(false);
  }, [props.isopen, props.terminatedOption]);

  const handleTermination = useCallback(
    async option => {
      setLoading(true);
      let body = {
        bot_id: props.botID,
        //   terminate_options: option - 1,
        exchange_id: calculateExchangeID(authState.currentOption),
        uuid: authState.id
      };
      if (option === 5) {
        body = {
          ...body,
          max_loss_amount: maxLossAmountInput
          // max
        };
      } else {
        body = {
          ...body,
          terminate_options: option - 1
        };
      }
      const API_URL = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${API_URL}editfxbotsettings`, body);
      // //TODO check response data here
      // console.log('response ', response);
      if (response.data.error) {
        setLoading(false);
        setIsReviewed(true);
        setIsTerminationError(response.data.error);
        return;
      }
      if (option === 1) {
        setIsRestored(true);
      }
      // setIsTerminationError('This is the error!');
      setLoading(false);
      setIsTerminated(true);
      setIsReviewed(true);
      // console.log(response.data);
      // dispatch(setBotTerminationOptions(response.data));
    },
    [maxLossAmountInput, selected]
  );

  const handleCancel = useCallback(() => {
    setIsTerminated(false);
    setIsReviewed(false);
    setIsTerminationError(false);
    if (props.terminatedOption !== 1) {
      setSelected(props.terminatedOption);
    } else {
      setSelected();
    }
  }, []);

  const handleReview = useCallback(
    async option => {
      if (option === 5 && mlaError) {
        //   setMlaError('Maximum loss should be less your balance');
        return;
      }
      setIsReviewed(true);
    },
    [mlaError]
  );

  const setBotMessage = sel => {
    switch (sel) {
      case 2:
        return `Your ${props.name} bot will be terminated immediately. It will not buy or sell what it has accumalated during the run. All the gains up to this run will stay in your gains except this last run since it is prematurely terminated.`;
      case 3:
        return `Your ${props.name} bot will be terminated immediately by selling the units you currently have.`;
      case 4:
        return `Your ${props.name} bot will be terminated once your run completes successfully.`;
      case 5:
        return `Your ${props.name} bot will be terminated once your max loss limits is reached. ( $${maxLossAmountInput} )`;
      default:
        return `Your ${props.name} Opps!!! this should have not happened, please reach out to our support`;
    }
  };

  return (
    <Modal
      isOpen={props.isopen}
      onClose={props.onclose}
      isCentered
      returnFocusOnClose={false}
      motionPreset="slideInBottom"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent borderRadius="2xl" bg={colorMode === 'light' ? 'white' : '#1f2124'}>
        <ModalCloseButton
          _focus={{
            outline: 'none'
          }}
        />
        <ModalHeader fontWeight="bold" fontSize="xl" mt={10} pb={0} pt={0}>
          {props.title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={2}>
          <Flex direction="column" justify="flex-start" align="flex-start">
            {/* <Text fontSize="sm">
              Current Net Gain:{' '}
              <chakra.span color={props.currentPlayGain < 0 ? '#ef233c' : '#48BB78'}>
                {usdFormatter.format(props.currentPlayGain)}
              </chakra.span>
            </Text> */}
            {/* <Text fontSize="xs" color="gray.500">
              *All of the Sessions&apos; total profit plus/minus current sessions&apos; gain.
            </Text> */}
            <Text mb={6} fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
              {`We have bought ${props.units} units so far with ${props.quoteSpend}.`}
            </Text>
            <Divider mb={4} />
            {!isReviewed && (
              <Flex mt={2} direction="column">
                {terminationOptions.map((el, idx) => {
                  return (
                    <TerminationCard
                      selected={idx + 2 === selected}
                      onClick={() => handleSelection(idx + 2)}
                      option={el}
                      oanda
                      index={idx}
                      mla={props.maxLossAmount}
                      mlaPlaceholder={props.mlaPlaceholder}
                      key={idx}
                      isTerminated={props.terminatedOption === 3}
                      isMla={props.maxLossAmount !== 0}
                      liftMlaRestriction={liftMaxLossRestriction}
                      onLetItRunClick={() => handleTermination(1)}
                      //   setMarketRadioValue={() => setRadioValue('market')}
                      //   setLimitRadioValue={() => setRadioValue('limit')}
                      value={idx === 3 && maxLossAmountInput}
                      error={mlaError}
                      handleChange={handleChange}
                      //   radioValue={radioValue}
                    />
                  );
                })}
              </Flex>
            )}
          </Flex>
          {/* {!isReviewed && (
            <Flex justify="flex-start" align="center">
              <Icon
                as={RiInformationLine}
                w="16px"
                h="16px"
                mr={2}
                variant="unstyled"
                _focus={{
                  outline: 'none'
                }}
                color="red.300"
              />
              <Text color="red.300" fontSize="0.65rem" mt={2}>
                The actual amounts may change due to market fluctuations and subject to how your Crypto Exchange
                executes these trades.
              </Text>
            </Flex>
          )} */}
          {isReviewed && !isTerminated && !isTerminationError && (
            <Flex w="full" direction="column" alignItems="center" mt={2}>
              <AlertTitle fontWeight="semibold">
                {selected !== 5 ? 'Terminate Bot?' : 'Set Max Loss Amount?'}
              </AlertTitle>
              {/* <Text my={2}>Are you sure you want to terminate {props.name}?</Text> */}
              <Alert
                status="info"
                variant="unstyled"
                textAlign="center"
                mb={4}
                flexDirection="column"
                alignItems="center"
              >
                <AlertIcon boxSize="24px" mb={4} />
                <AlertDescription fontSize="sm">{setBotMessage(selected)}</AlertDescription>
              </Alert>
              <Flex
                px={2}
                py={4}
                w="full"
                borderRadius="xl"
                mt={2}
                mb={4}
                bg={colorMode === 'light' ? 'white' : '#1f2124'}
                borderWidth={1}
              >
                <Text
                  fontSize="13px"
                  w="full"
                  textAlign="center"
                  color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
                >
                  {/* Termination Option: {terminationOptions[selected - 2]} */}
                  {terminationOptions[selected - 2]}
                </Text>
              </Flex>
            </Flex>
          )}
          {isReviewed && isTerminated && (
            <Flex direction="column" alignItems="center" mt={8}>
              <Alert
                w="full"
                textAlign="center"
                px={0}
                status="success"
                variant="unstyled"
                fontSize="13px"
                flexDirection="column"
              >
                <AlertIcon color="green.400" mb={4} boxSize="28px" />
                {/* <AlertTitle mt={4} mb={1} fontSize="lg">
                  Succesfull!
                </AlertTitle> */}
                {/* <AlertDescription maxWidth="sm">
                  {isRestored ? 'Your bot will continue to run.' : ''}
                </AlertDescription> */}
                {isRestored ? 'Your bot will continue to run.' : 'Your bot successfully terminated.'}
              </Alert>
            </Flex>
          )}
          {isReviewed && isTerminationError && (
            <Flex direction="column" alignItems="center" mt={4}>
              <Alert
                status="error"
                variant="unstyled"
                flexDirection="column"
                alignItems="center"
                px={0}
                fontSize="13px"
                justifyContent="center"
                textAlign="center"
              >
                <AlertIcon boxSize="32px" mr={0} color="red.400" />
                <AlertTitle mt={2} mb={1} fontSize="md">
                  Oh Snap!
                </AlertTitle>
                <AlertDescription maxWidth="sm">{isTerminationError}</AlertDescription>
              </Alert>
              {/* <Button
                w={36}
                my={6}
                fontSize="xs"
                colorScheme="gray"
                _focus={{ outline: 'none' }}
                onClick={props.onclose}
                //   _active={{ background: epicFlowGradientColors.right }}
              >
                Close
              </Button> */}
            </Flex>
          )}
        </ModalBody>
        <ModalFooter>
          {!isReviewed && (
            <EFButton
              title="Review"
              isDisabled={
                !selected || (mlaError && selected === 5) || (selected === 5 && maxLossAmountInput === '')
              }
              w="full"
              mt={8}
              mb={4}
              isLoading={loading}
              color={colorMode === 'light' ? 'white' : 'black'}
              bg={colorMode === 'light' ? 'black' : 'white'}
              onClick={() => handleReview(selected)}
            />
          )}
          {isReviewed && !isTerminated && !isTerminationError && (
            <Flex alignItems="center" justifyContent="space-evenly" mt={2} w="full" pb={8}>
              <Button
                // maxW="fit-content"
                // size="lg"
                onClick={handleCancel}
                w="full"
                fontSize="13px"
                bg={colorMode === 'light' ? 'white' : 'transparent'}
                // borderColor={epicFlowGradientColors.left}
                mr={4}
                borderColor={props.bg}
                borderWidth={1}
                _hover={{
                  bg: colorMode === 'light' ? 'white' : 'transparent'
                }}
                _focus={{
                  outline: 'none'
                }}
                _active={{
                  bg: colorMode === 'light' ? 'white' : 'transparent'
                }}
                // _hover={{ background: 'none' }}
                // _focus={{ outline: 'none' }}
              >
                Cancel
              </Button>
              <Button
                w="full"
                fontSize="13px"
                bg="red.500"
                color="white"
                isLoading={loading}
                _hover={{ background: 'red' }}
                _focus={{ outline: 'none' }}
                onClick={() => handleTermination(selected)}
                //   _active={{ background: epicFlowGradientColors.right }}
              >
                Confirm
              </Button>
            </Flex>
          )}
          {(isReviewed && isTerminationError) || (isReviewed && isTerminated) ? (
            <Button
              w="full"
              fontSize="13px"
              mb={8}
              mt={2}
              onClick={props.onclose}
              color={colorMode === 'light' ? 'white' : 'black'}
              bg={colorMode === 'light' ? 'black' : 'white'}
              _hover={{
                bg: colorMode === 'light' ? 'black' : 'transparent'
              }}
              _focus={{
                outline: 'none'
              }}
              _active={{
                bg: colorMode === 'light' ? 'black' : 'transparent'
              }}
              //   _active={{ background: epicFlowGradientColors.right }}
            >
              Close
            </Button>
          ) : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OandaTermination;
