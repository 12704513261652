import { EditIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  chakra,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useColorMode,
  useDisclosure,
  WrapItem
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IoIosSettings } from 'react-icons/io';
import { MdQueryStats } from 'react-icons/md';
import {
  RiBillLine,
  RiCheckboxCircleFill,
  RiDeleteBin6Line,
  RiEqualizerLine,
  RiInformationFill,
  RiInformationLine,
  RiLineChartLine,
  RiPlayCircleLine,
  RiShutDownLine,
  RiStopCircleLine
} from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import { getCompletedBots, getPlayStat } from '../../store/actions/bot';
import { epicFlowGradientColors, statusColors } from '../../theme';
import axios from '../../utilities/axios';
import { getMoney, usdFormatter } from '../../utilities/currencyConverter';
import { calculateExchangeID } from '../../utilities/exchangeHelpers';
import BotSettings from '../modals/botSettings';
import PlayStats from '../modals/playStats';
import Termination from '../modals/termination';

const BotCard = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const { isOpen: isTerminationOpen, onOpen: onTerminationOpen, onClose: onTerminationClose } = useDisclosure();

  const { isOpen: isBotSettingsOpen, onOpen: onBotSettingsOpen, onClose: onBotSettingsClose } = useDisclosure();

  const { isOpen: isPlayStatsOpen, onOpen: onPlayStatsOpen, onClose: onPlayStatsClose } = useDisclosure();

  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onConfirmationModalOpen,
    onClose: onConfirmationModalClose
  } = useDisclosure();

  // const botState = useSelector(state => state.bot);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();

  const [result, setResult] = useState();

  const [modalContent, setModalContent] = useState();

  const authState = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const handlePlayStatsOpen = useCallback(
    async id => {
      try {
        onPlayStatsOpen();
        setLoading(true);
        const body = {
          bot_id: id
        };
        const response = await axios.post(`${process.env.REACT_APP_API_URL}playstats`, body);
        const finalData = [];
        for (let item in response.data) {
          finalData.push({
            ...response.data[item],
            id: item
          });
        }
        setData(finalData.slice(0).reverse());
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [props.botID]
  );

  const handleResumeBot = useCallback(async id => {
    try {
      setLoading(true);
      const body = {
        bot_id: id,
        stripeCustomerId: authState.stripeCustomerId,
        max_amount: props.maxSpent,
        uuid: authState.id
      };
      // const response = await axios.post(`http://localhost:5020/resumebot`, body);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}resumebot`, body);
      if (response.data.success) {
        setResult({ success: true });
      } else {
        setResult({ error: response.data });
      }
      setLoading(false);
      // console.log(response.data);
    } catch (error) {
      setResult({ error: error });
      setLoading(false);
    }
  }, []);

  const handleArchiveBot = useCallback(async id => {
    try {
      setLoading(true);
      const body = {
        bot_id: id
      };
      // const response = await axios.post(`http://localhost:5020/archivebot`, body);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}archivebot`, body);
      // console.log(response.data);
      if (response.data.success) {
        setResult({ success: true });
      } else {
        setResult({ error: 'error' });
      }
      setLoading(false);
    } catch (error) {
      setResult({ error: error });
      setLoading(false);
    }
  }, []);

  const resumeOrArchiveBot = useCallback(type => {
    onConfirmationModalOpen();
    if (type === 'resume') {
      setModalContent({
        content: `Do you want to resume ${props.name}?`,
        action: 'resume',
        confirm: () => handleResumeBot(props.botID),
        buttonColor: 'blue.500'
      });
    } else {
      setModalContent({
        content: `Do you want to delete ${props.name} permanently?`,
        action: 'archive',
        confirm: () => handleArchiveBot(props.botID),
        buttonColor: 'red.500'
      });
    }
  }, []);

  const handleConfirmationModalClose = () => {
    if (result) {
      dispatch(getCompletedBots(authState.id, calculateExchangeID(authState.currentOption)));
    }
    setModalContent();
    setResult();
    onConfirmationModalClose();
  };

  // const handlePlayStatsOpen = async () => {
  //   // dispatch(getPlayStat(props.botID));
  //   try {
  //     console.log('handleplayStatsopen');
  //     onPlayStatsOpen();
  //     setLoading(true);
  //     const body = {
  //       bot_id: props.botID
  //     };
  //     const response = await axios.post(`https://api.epicflow.io/playstats`, body);
  //     const finalData = [];
  //     for (let item in response.data) {
  //       finalData.push({
  //         ...response.data[item],
  //         id: item
  //       });
  //     }
  //     setData(finalData.slice(0).reverse());
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log('bot play stat error ', error);
  //   }
  // };

  const handleEditNameButton = () => {
    onBotSettingsOpen();
  };

  // useEffect(() => {
  //   // console.log('play stat data ', data);
  // }, [data]);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      // console.log('updating bot card');
      // do componentDidUpdate logic
    }
  });

  return (
    <WrapItem w={['full', 'full', '385px', '385px', '385px', '385px']} position="relative">
      <Flex
        w="full"
        // h={props.active ? '400px' : '240px'}
        rounded="xl"
        justify="flex-start"
        align="center"
        direction="column"
        borderWidth={colorMode === 'light' ? 0 : 1}
        // borderColor={colorMode === 'light' ? 'gray.200' : 'whiteAlpha.300'}
        borderColor={colorMode === 'light' ? 'gray.200' : 'whiteAlpha.300'}
        // boxShadow="rgba(0, 0, 0, 0.10) 0px 3px 15px"
        boxShadow={
          props.terminatedOption !== 1 && props.active
            ? 'rgba(229, 62, 62, 0.4) 0px 3px 15px'
            : 'rgba(0, 0, 0, 0.10) 0px 3px 15px'
        }
      >
        <Modal
          isOpen={isConfirmationModalOpen}
          onClose={handleConfirmationModalClose}
          isCentered
          returnFocusOnClose={false}
          motionPreset="slideInBottom"
          size="lg"
        >
          <ModalOverlay />
          <ModalContent borderRadius="sm" maxH="sm">
            <ModalCloseButton
              _focus={{
                outline: 'none'
              }}
            />
            <ModalHeader fontWeight="bold" fontSize="md" mt={10} pb={0} pt={0} textAlign="center">
              Confirmation
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pt={2} maxH="2xl" pl={10}>
              <Flex direction="column" justify="flex-start" alignItems="center">
                {!result && (
                  <Text fontSize="sm" mb={4}>
                    {modalContent?.content}
                  </Text>
                )}
                {!result && (
                  <Flex pb={4}>
                    <Button
                      mr={4}
                      size="sm"
                      fontSize="xs"
                      _focus={{ outline: 'none' }}
                      onClick={handleConfirmationModalClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      fontSize="xs"
                      _focus={{ outline: 'none' }}
                      bg={modalContent?.buttonColor}
                      color="white"
                      onClick={modalContent?.confirm}
                      isLoading={loading}
                      _hover={{ background: modalContent?.buttonColor === 'red.500' ? 'red' : '#0162d1' }}
                    >
                      Confirm
                    </Button>
                  </Flex>
                )}
                {result ? (
                  <Alert
                    fontSize="sm"
                    status={result.error ? 'error' : 'success'}
                    variant="subtle"
                    mb={4}
                    mt={4}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <AlertIcon boxSize="28px" my={2} />
                    <AlertTitle mt={1} mb={2} fontSize="lg">
                      {result.error ? 'Error!' : 'Succesful!'}
                    </AlertTitle>
                    {`${
                      result.error
                        ? result.error
                        : result.success && modalContent.action === 'resume'
                        ? `${props.name} was succesfully reinitiated. It will appear in active bots soon.`
                        : `${props.name} was successfully deleted.`
                    }`}
                  </Alert>
                ) : null}
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Flex w="90%" justify="space-between" py="1rem" align="flex-start">
          <Flex direction="column" w="full">
            <Flex justifyContent="space-between" alignItems="center" w="full">
              <Flex alignItems="center">
                <Text fontSize="xl" fontWeight="semibold" letterSpacing="0.05rem">
                  {props.name}
                </Text>
                {/* {props.active ? (
                  <IconButton
                    bg={colorMode === 'light' ? 'white' : '#0f1114'}
                    aria-label="Edit Bot Name"
                    onClick={handleEditNameButton}
                    w="16px"
                    h="16px"
                    icon={<EditIcon w="16px" h="16px" />}
                    _active={{
                      bg: colorMode === 'light' ? 'white' : '#0f1114'
                    }}
                    _hover={{
                      bg: colorMode === 'light' ? 'white' : '#0f1114'
                    }}
                  />
                ) : null} */}
              </Flex>
              {props.active ? (
                <Button variant="unstyled" onClick={onTerminationOpen} _focus={{ outline: 'none' }}>
                  <Icon as={RiShutDownLine} boxSize={5} color={colorMode === 'light' ? 'gray.500' : 'gray.300'} />
                </Button>
              ) : (
                <Flex alignItems="center">
                  <Button variant="unstyled" onClick={() => resumeOrArchiveBot('resume')}>
                    <Icon as={RiPlayCircleLine} boxSize={5} color="blue.500" />
                  </Button>
                  <Button variant="unstyled" onClick={() => resumeOrArchiveBot('archive')}>
                    <Icon as={RiDeleteBin6Line} boxSize={5} color="red.400" />
                  </Button>
                </Flex>
              )}
            </Flex>
            <Flex mt={1}>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                {props.pair}
              </Text>
              {/* <Flex justify="center" align="center">
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} ml={3} mr={2}>
                {props.target}
              </Text>
              <Icon as={FiTarget} boxSize={4} color={colorMode === 'light' ? 'gray.500' : 'gray.300'} />
            </Flex> */}
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} ml={3}>
                {props.exchange}
              </Text>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} ml={3}>
                {props.botStyle}
              </Text>
            </Flex>
            {/* <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
            Profit in {props.target}
          </Text> */}
            <Flex mt={2} justify="flex-start" align="center" w="full">
              <Text fontSize="xs" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                {props.since}
              </Text>
              <Text fontSize="xs" ml={3} color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                {props.playCount} {props.playCount > 1 ? 'Runs' : 'Run'} in Total
              </Text>
              <Text fontSize="xs" ml={3} color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                Profit in: {props.target}
              </Text>
              {props.active ? (
                <Tooltip
                  hasArrow
                  label={`${props.botState}`}
                  whiteSpace="break-spaces"
                  bg="gray.200"
                  color="gray.700"
                  pt={1}
                  pb={1}
                  fontSize="xs"
                >
                  <span
                    style={{
                      marginLeft: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '16px',
                      height: '16px'
                    }}
                  >
                    <Icon
                      as={RiInformationLine}
                      w={props.botState.includes('WARNING:') ? '20px' : '16px'}
                      h={props.botState.includes('WARNING:') ? '20px' : '16px'}
                      variant="unstyled"
                      _focus={{
                        outline: 'none'
                      }}
                      color={
                        props.botState.includes('WARNING:')
                          ? statusColors.down
                          : props.botState.includes('Completed')
                          ? statusColors.up
                          : epicFlowGradientColors.left
                      }
                    />
                  </span>
                </Tooltip>
              ) : (
                <Icon as={RiCheckboxCircleFill} ml={2} w="18px" h="18px" color={statusColors.up} />
              )}
            </Flex>
          </Flex>
          <BotSettings
            // maxAmountPlaceholder={`Current Max: ${props.maxSpent}`}
            // isDisabled={authState.stripeCurrentPlan === 'Free' || authState.stripeCurrentPlan === 'Basic'}
            maxAmountDesc={props.maxAmountDesc}
            botNameDesc={props.botNameDesc}
            botStyleDesc={props.botStyleDesc}
            minProfitDesc={props.minProfitDesc}
            ddmDesc={props.ddmDesc}
            slbDesc={props.slbDesc}
            slgDesc={props.slgDesc}
            slpsDesc={props.slpsDesc}
            ebuDesc={props.ebuDesc}
            octDesc={props.octDesc}
            ebtDesc={props.ebtDesc}
            btcDesc={props.btcDesc}
            spDesc={props.spDesc}
            fboDesc={props.fboDesc}
            tfDesc={props.tfDesc}
            onclose={onBotSettingsClose}
            isopen={isBotSettingsOpen}
            name={props.name}
            maxAmountPlaceholder={props.maxSpent}
            minAmount={props.minAmount}
            isBase={props.isBase}
            quote={props.quote}
            botID={props.botID}
            TDPlaceholder={props.tradeFrequency}
            botStylePlaceholder={props.botStyle}
            doubledownMultiplierPlaceholder={props.doubledownMultiplier}
            stopLossGranularityPlaceholder={`${props.stopLossGranularity}%`}
            boostTimerConstantPlaceholder={props.boostTimerConstant}
            extraBuyTimerPlaceholder={props.extraBuyTimer}
            tradeFrequencyPlaceholder={props.tradeFrequency}
            slotPricePlaceholder={`${props.slotPrice} ${props.quote}`}
            stopLossPriceSensitivityPlaceholder={`${props.stopLossPriceSensitivity}%`}
            stopLossBufferlaceholder={`${props.stopLossBuffer}%`}
            firstBoostOrderPercentagePlaceholder={props.firstBoostOrder}
            ebuPlaceholder={`${props.ebu}%`}
            orderCancelTimerPlaceholder={props.orderCancelTimer}
            botNamePlaceholder={props.name}
            minProfitMarginPlaceholder={`${props.minProfitMargin}%`}
          />
          <PlayStats
            name={props.name}
            playStatsData={data}
            precision={props.precision}
            exchange={props.exchange}
            botStyle={props.botStyle}
            since={props.since}
            quoteGraph={props.quoteGraph}
            baseGraph={props.baseGraph}
            playCount={props.playCount + 1}
            botID={props.botID}
            bots
            maxSpent={props.maxSpent}
            quote={props.quote}
            target={props.target}
            exchangeName={props.exchangeName}
            quoteAssetPrecision={props.quoteAssetPrecision}
            pair={props.pair}
            isopen={isPlayStatsOpen}
            inverse={props.inverse}
            onclose={onPlayStatsClose}
            // data={botState.playStats}
            loading={loading}
            baseCurrency={props.baseCurrency}
          />
          <Termination
            onclose={onTerminationClose}
            isopen={isTerminationOpen}
            title={`Stop Bot ( ${props.name} )`}
            name={props.name}
            cpg={props.cpg}
            currentPlayGain={props.currentPlayGain}
            terminatedOption={props.terminatedOption}
            botID={props.botID}
            inverse={props.inverse}
            total_qty_purchased={props.qtyPurchased}
            quoteSpend={props.quoteSpend}
            symbol_to_profit={props.target}
            symbol_pair={props.pair.slice(0, props.target.length)}
          />
        </Flex>
        <Accordion w="full" textAlign="center" index={props.index}>
          <AccordionItem
            css={{
              borderBottom: 'none !important'
            }}
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  _focus={{
                    outline: 'none'
                  }}
                  p={0}
                  display="flex"
                  _hover={{
                    cursor: 'default'
                  }}
                  pointerEvents="none"
                  justifyContent="center"
                  w="full"

                  // onClick={() =>
                  //   handleTransactionData(props.botID, props.playNumber, props.exchangeName, isExpanded)
                  // }
                >
                  <Flex w="90%" justify="space-between" py="1rem" align="center">
                    <Flex justify="space-between" alignItems="center" w="full" direction="column">
                      <Text fontSize="sm" fontWeight="semibold" w="90%" textAlign="center">
                        Profits ({props.target})
                      </Text>
                      <Flex w="full" justify={props.active ? 'space-between' : 'center'} align="flex-start" mt={2}>
                        {/* <Flex direction="column">
            <Text fontSize="sm">Max Spent {props.quote}</Text>
            <Text fontWeight="semibold" fontSize="2xl">
              {props.isBase === 1 ? usdFormatter.format(props.maxSpent) : parseFloat(props.maxSpent.toFixed(4))}
              {props.quote === 'USD' ? getMoney(props.maxSpent) : parseFloat(props.maxSpent)}
            </Text>
          </Flex> */}
                        {/* <Flex direction="column" align="flex-start">
            <Flex align="center">
              <Text fontSize="0.825rem">Completed Runs</Text>
            </Flex>
            <Flex align="center">
              <Text fontWeight="semibold" fontSize="xl" color={props.overallGain < 0 ? '#ef233c' : '#48BB78'}>
                {!props.inverse && props.isBase === 1 ? props.overallGain : props.gain}
              </Text>
            </Flex>
          </Flex> */}
                        {props.active && (
                          <Flex direction="column" align="flex-start">
                            <Flex align="center">
                              <Text fontSize="0.825rem">
                                Current Run{' '}
                                <chakra.span fontSize="xs" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                                  ( {props.currentSince} )
                                </chakra.span>
                              </Text>
                            </Flex>
                            <Flex alignItems="center">
                              <Flex alignItems="center">
                                <Text
                                  fontWeight="semibold"
                                  fontSize="xl"
                                  color={props.currentPlayGain < 0 ? '#ef233c' : '#48BB78'}
                                >
                                  {!props.inverse && props.isBase === 1
                                    ? //TODO handle USDT here
                                      usdFormatter.format(props.currentPlayGain)
                                    : props.isBase !== 1
                                    ? Number(props.bcpg)
                                    : (Number(props.currentPlayGain) / Number(props.cp)).toFixed(
                                        props.baseAssetPrecision
                                      )}
                                </Text>
                              </Flex>
                              <Tooltip
                                hasArrow
                                label="This is the current net profit of the active run that is in progress. 
                This number does not include the net confirmed profits of all the previously completed runs on the right."
                                whiteSpace="break-spaces"
                                bg="gray.200"
                                color="gray.700"
                                pt={1}
                                pb={1}
                                fontSize="xs"
                              >
                                <span
                                  style={{
                                    marginLeft: '4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '16px',
                                    height: '16px'
                                  }}
                                >
                                  <Icon
                                    as={QuestionOutlineIcon}
                                    w="14px"
                                    h="14px"
                                    variant="unstyled"
                                    _focus={{
                                      outline: 'none'
                                    }}
                                    color="unset"
                                  />
                                </span>
                              </Tooltip>
                            </Flex>
                            <Flex alignItems="center">
                              {props.inverse || props.isBase !== 1 ? (
                                <Text fontSize="sm" color={props.currentPlayGain < 0 ? '#ef233c' : '#48BB78'}>
                                  {usdFormatter.format(props.currentPlayGain)}
                                </Text>
                              ) : null}
                            </Flex>
                          </Flex>
                        )}
                        <Flex align="center">
                          <Flex direction="column" align="flex-end" pr={1}>
                            {props.active && (
                              <Flex align="center">
                                <Text fontSize="0.825rem">Completed Runs</Text>
                              </Flex>
                            )}
                            <Flex align={props.active ? 'flex-end' : 'center'} direction="column">
                              <Text
                                fontWeight="semibold"
                                fontSize="xl"
                                color={props.overallGain < 0 ? '#ef233c' : '#48BB78'}
                              >
                                {!props.inverse && props.isBase === 1 ? props.overallGain : props.gain}
                              </Text>
                              <Flex>
                                {props.inverse || props.isBase !== 1 ? (
                                  <Text fontSize="sm" color={props.overallGain < 0 ? '#ef233c' : '#48BB78'}>
                                    {props.overallGain}
                                  </Text>
                                ) : null}
                                <Flex alignItems="center" ml={2}>
                                  <Text fontSize="sm" color={props.overallGain < 0 ? '#ef233c' : '#48BB78'}>
                                    {props.overallGainPercent}%
                                  </Text>
                                  <Tooltip
                                    hasArrow
                                    label="Annualized Rate of Return % (ARoR %)"
                                    whiteSpace="break-spaces"
                                    bg="gray.200"
                                    color="gray.700"
                                    pt={1}
                                    pb={1}
                                    fontSize="xs"
                                  >
                                    <span
                                      style={{
                                        marginLeft: '4px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '16px',
                                        height: '16px'
                                      }}
                                    >
                                      <Icon
                                        as={QuestionOutlineIcon}
                                        w="14px"
                                        h="14px"
                                        variant="unstyled"
                                        _focus={{
                                          outline: 'none'
                                        }}
                                        color="unset"
                                      />
                                    </span>
                                  </Tooltip>
                                </Flex>
                              </Flex>
                            </Flex>
                          </Flex>
                          {/* <AccordionIcon /> */}
                        </Flex>
                      </Flex>
                      <Flex alignItems="center" justify="center" mt={6} w="full">
                        <Text fontSize="0.825rem" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                          Max Allocated Investment Amount:
                        </Text>
                        <Text fontSize="0.825rem" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} ml={1}>
                          {/* {props.isBase === 1 ? usdFormatter.format(props.maxSpent) : parseFloat(props.maxSpent.toFixed(4))} */}
                          <chakra.span fontWeight="medium">{`${
                            props.quote === 'USD' ? getMoney(props.maxSpent) : parseFloat(props.maxSpent)
                          } ${props.quote}`}</chakra.span>
                        </Text>
                        <Tooltip
                          hasArrow
                          label="This is the total amount of capital investment you want to be allocated from your connected exchange account only for this particular bot.
                Your bot follows your strategy and continues operating as long as its total invested amount (Quote Spent) is below this max investment amount setting.
                You can increase or decrease this max allocated amount limit from your Basic bot settings. i.e. Increase it for giving greater runway to your bot or decrease to minimize the over-spent risk."
                          whiteSpace="break-spaces"
                          bg="gray.200"
                          color="gray.700"
                          pt={1}
                          pb={1}
                          fontSize="xs"
                        >
                          <span
                            style={{
                              marginLeft: '4px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '16px',
                              height: '16px'
                            }}
                          >
                            <Icon
                              as={QuestionOutlineIcon}
                              w="14px"
                              h="14px"
                              variant="unstyled"
                              _focus={{
                                outline: 'none'
                              }}
                              color="unset"
                            />
                          </span>
                        </Tooltip>
                      </Flex>
                    </Flex>
                  </Flex>
                </AccordionButton>
                <AccordionPanel p={0} w="full" display="flex" justifyContent="center">
                  <Flex direction="column" w="90%">
                    <Flex w="full" justify="">
                      <Flex w="full" justify="space-between" align="flex-start" mt={2}>
                        <Flex direction="column" align="flex-start" flex={1}>
                          <Text fontSize="xs">{props.quantityTitle}</Text>
                          <Text fontSize="xs" fontWeight="semibold">
                            {props.qtyPurchased}
                          </Text>
                        </Flex>
                        {props.quoteSpend ? (
                          <Flex direction="column" align="center" flex={1}>
                            <Text fontSize="xs" textAlign="left">
                              Quote Spent
                            </Text>
                            <Text fontSize="xs" fontWeight="semibold">
                              {props.quoteSpend}
                            </Text>
                          </Flex>
                        ) : null}
                        {props.ra ? (
                          <Flex direction="column" align="flex-end" flex={1}>
                            <Text fontSize="xs">Remaining Amount</Text>
                            <Text fontSize="xs" fontWeight="semibold">
                              {props.ra}
                            </Text>
                          </Flex>
                        ) : null}
                      </Flex>
                    </Flex>
                    <Flex w="full" justify="">
                      <Flex w="full" justify="space-between" align="flex-start" mt={6}>
                        {props.currentPrice ? (
                          <Flex direction="column" align="flex-start" flex={1}>
                            <Text fontSize="xs">Current Price</Text>
                            <Text fontSize="xs" fontWeight="semibold">
                              {props.currentPrice}
                            </Text>
                          </Flex>
                        ) : null}
                        {props.prp ? (
                          <Flex direction="column" align="center" flex={1}>
                            <Text fontSize="xs">Price for Exit</Text>
                            <Text fontSize="xs" fontWeight="semibold">
                              {props.prp}
                            </Text>
                          </Flex>
                        ) : null}
                        {props.avgCost ? (
                          <Flex direction="column" align="flex-end" flex={1}>
                            <Text fontSize="xs">Average Cost</Text>
                            <Text fontSize="xs" fontWeight="semibold">
                              {props.avgCost}
                            </Text>
                          </Flex>
                        ) : null}
                      </Flex>
                    </Flex>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
        {props.active ? (
          <Flex w="30%" justify="space-around" align="flex-start" mt={6}>
            {/* <Button variant="unstyled">
            <Icon as={RiLineChartLine} boxSize={6} color={colorMode === 'light' ? 'gray.500' : 'gray.300'} />
          </Button> */}
            {props.active ? (
              <Flex w="20%" justify="space-around" align="flex-start" mt={6}>
                {/* <Button variant="unstyled">
            <Icon as={RiLineChartLine} boxSize={6} color={colorMode === 'light' ? 'gray.500' : 'gray.300'} />
          </Button> */}
                <Button variant="unstyled" onClick={onBotSettingsOpen}>
                  <Icon as={IoIosSettings} boxSize={5} color={colorMode === 'light' ? 'black' : 'white'} />
                </Button>
                <Button variant="unstyled" onClick={() => handlePlayStatsOpen(props.botID)}>
                  <Icon as={MdQueryStats} boxSize={5} color={colorMode === 'light' ? 'black' : 'white'} />
                </Button>
              </Flex>
            ) : null}
          </Flex>
        ) : null}
        {!props.active ? (
          <Flex mt={0}>
            <Button variant="unstyled" onClick={() => handlePlayStatsOpen(props.botID)}>
              <Icon as={MdQueryStats} boxSize={5} color={colorMode === 'light' ? 'black' : 'white'} />
            </Button>
          </Flex>
        ) : null}
      </Flex>
      {props.terminatedOption !== 1 && props.active && (
        <Tooltip
          hasArrow
          label="This bot will be terminated after current run is completed."
          whiteSpace="break-spaces"
          bg="gray.200"
          color="gray.700"
          pt={1}
          pb={1}
          fontSize="xs"
        >
          <span
            style={{
              position: 'absolute',
              right: -11,
              zIndex: 3,
              top: -11,
              marginLeft: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '22px',
              height: '22px'
            }}
          >
            <Icon
              as={RiInformationFill}
              w="22px"
              h="22px"
              variant="unstyled"
              _focus={{
                outline: 'none'
              }}
              color="red.500"
            />
          </span>
        </Tooltip>
      )}
    </WrapItem>
  );
};

BotCard.propTypes = {
  name: PropTypes.string
};

export default BotCard;
