import { Flex, Icon, Image, Link, Text, Tooltip, useColorMode } from '@chakra-ui/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { RiSwapLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import Reel from 'react-reel';
import { Link as RouterLink } from 'react-router-dom';

import { epicFlowGradientColors, statusColors } from '../../theme';
import { dynamicCurrencyFormatter, usdFormatter } from '../../utilities/currencyConverter';
import { titleCase } from '../../utilities/stringConverters';

const OandaCard = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const displayOptions = ['none', 'none', 'flex', 'flex', 'flex', 'flex'];

  const userState = useSelector(state => state.user);

  const [data, setData] = useState({
    labels: props.sparklineData,
    datasets: [
      {
        label: '',
        data: props.sparklineData,
        fill: true,
        backgroundColor: 'transparent',
        borderColor: props.weekUp ? '#48BB78' : '#F56565',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        borderWidth: 2
      }
    ]
  });

  const reelTheme = {
    group: {
      transitionDelay: '0ms',
      transitionTimingFunction: 'ease-in-out',
      transform: 'translate(0, 0)',
      height: '1em'
    },
    reel: {
      height: '1em',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      letterSpacing: '-0.8px',
      overflowY: 'hidden',
      paddingRight: '1px',
      paddingLeft: '1px',
      fontSize: '0.875rem',
      // fontWeight: "300"
      lineHeight: '1em'
    },
    number: {
      height: '1em'
    }
  };

  return (
    <Flex
      w="full"
      //   cursor="pointer"
      role="group"
      //   as={RouterLink}
      //   to={props.to}
      _focus={{
        outline: 'none'
      }}
      mt={props.total ? 4 : 0}
      display={[props.total ? 'none' : 'flex', props.total ? 'none' : 'flex', 'flex']}
      borderBottomWidth="1px"
      // bg={
      //   props.total
      //     ? ''
      //     : props.index % 2 !== 0 && colorMode === 'light'
      //     ? 'gray.50'
      //     : props.index % 2 !== 0 && colorMode === 'dark'
      //     ? 'gray.800'
      //     : ''
      // }
    >
      <Flex
        w="full"
        m="auto"
        py={4}
        justifyContent="space-between"
        // borderTopWidth={props.index !== 0 ? 1 : 0}
        // borderBottomWidth={props.index === props.length - 1 ? 1 : 0}
        // _groupHover={{
        //   borderColor: 'transparent'
        // }}
      >
        <Flex justify="flex-start" align="center" fontSize={3.5} w="full" order={0}>
          {/* <Image src={props.imgUrl} borderRadius="full" boxSize={8} /> */}
          <Flex align="center" lineHeight={5}>
            <Text
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              textAlign="left"
              fontSize="sm"
              fontWeight="medium"
            >
              {props.market?.split('_').join('/')}
            </Text>
            {props.isSwitched && (
              <Tooltip
                hasArrow
                label="Direction of this position has been changed."
                whiteSpace="break-spaces"
                bg="gray.200"
                color="gray.700"
                pt={1}
                pb={1}
                fontSize="xs"
              >
                <span
                  style={{
                    marginLeft: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '16px',
                    height: '16px'
                  }}
                >
                  <Icon
                    as={RiSwapLine}
                    w="16px"
                    h="16px"
                    variant="unstyled"
                    _focus={{
                      outline: 'none'
                    }}
                    // color={epicFlowGradientColors.left}
                  />
                </span>
              </Tooltip>
            )}
          </Flex>
        </Flex>
        <Text
          w="full"
          display="flex"
          alignItems="center"
          justifyContent={['flex-end', 'flex-end', 'center']}
          fontSize="sm"
          order={[2, 2, 1, 1, 1, 1]}
          fontWeight="medium"
        >
          <Text>{props.type}</Text>
        </Text>
        <Flex
          w="full"
          display={displayOptions}
          direction="column"
          order={2}
          align="flex-start"
          justify="center"
          lineHeight={5}
        >
          <Text
            w="full"
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="left"
            fontWeight={props.total ? 'bold' : 'medium'}
            fontSize="sm"
          >
            {props.units}
          </Text>
        </Flex>
        <Flex
          w="full"
          display={displayOptions}
          // order={[1, 1, 2, 2, 2, 2]}
          direction="column"
          // order={2}
          align="flex-start"
          justify="center"
          order={2}
          lineHeight={5}
        >
          <Flex
            w="full"
            display="flex"
            alignItems="center"
            justifyContent={['center', 'center']}
            textAlign="left"
            fontWeight={props.total ? 'bold' : 'medium'}
            fontSize="sm"
          >
            <Reel
              text={
                props.total
                  ? String(dynamicCurrencyFormatter(userState.baseCurrency).format(props.mu))
                  : String(dynamicCurrencyFormatter(userState.baseCurrency).format((props.mu / 1000).toFixed(2)))
              }
              theme={reelTheme}
            />
          </Flex>
        </Flex>
        <Flex w="full" order={[1, 1, 2, 2, 2, 2]} align="flex-start" justify="center">
          <Flex
            w="full"
            alignItems="center"
            justifyContent={['center']}
            fontWeight={props.total ? 'bold' : 'medium'}
            fontSize="sm"
            color={props.up ? 'rgb(72, 187, 120)' : '#ef233c'}
          >
            <Reel
              text={String(dynamicCurrencyFormatter(userState.baseCurrency).format(props.upl))}
              theme={reelTheme}
            />
          </Flex>
        </Flex>
        <Flex
          w="full"
          display={displayOptions}
          // order={[1, 1, 2, 2, 2, 2]}
          direction="column"
          // order={2}
          order={3}
          lineHeight={5}
        >
          <Flex
            w="full"
            display="flex"
            alignItems="center"
            justifyContent={['center', 'flex-end']}
            textAlign="left"
            fontWeight={props.total ? 'bold' : 'medium'}
            fontSize="sm"
            color={props.rup ? 'rgb(72, 187, 120)' : '#ef233c'}
          >
            <Reel
              text={String(dynamicCurrencyFormatter(userState.baseCurrency).format(props.rpl))}
              theme={reelTheme}
            />
          </Flex>
        </Flex>
        {/* <Flex minW={36} maxW={36} alignItems="center" order={[1, 1, 4, 4, 4, 4]} justifyContent="flex-start">
          <Flex w="full" h="full" align="flex-end" pointerEvents="none">
            {props.sparklineData ? <Line data={data} width={100} height={null} options={options}></Line> : null}
          </Flex>
        </Flex> */}
      </Flex>
    </Flex>
  );
};

OandaCard.propTypes = {};

export default OandaCard;
