import { epicFlowGradientColors } from '../../theme';
import * as actionTypes from '../actions/actionTypes';

const defaultGraphData = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      fill: true,
      backgroundColor: 'transparent',
      borderColor: epicFlowGradientColors.left,
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointHoverBackgroundColor: epicFlowGradientColors.left,
      pointHoverBorderColor: '#fff',
      pointHoverRadius: 6,
      pointHoverBorderWidth: 1,
      pointRadius: 10,
      borderWidth: 2
    }
  ]
};

const initialState = {
  assetData: null,
  initialPortfolioData: [],
  assetLoading: true,
  graphData: null,
  currentGraphData: null,
  colorStatus: null,
  baseCurrency: null,
  mgd: {
    binanceus: {
      data: {},
      timeStamps: {}
    },
    binance: {
      data: {},
      timeStamps: {}
    },
    coinbasepro: {
      data: {},
      timeStamps: {}
    },
    oandademo: {
      data: {},
      timeStamps: {}
    },
    oandalive: {
      data: {},
      timeStamps: {}
    },
    combined: {
      data: {},
      timeStamps: {}
    }
  },
  bgd: {
    binanceus: {
      data: {},
      timeStamps: {}
    },
    binance: {
      data: {},
      timeStamps: {}
    },
    coinbasepro: {
      data: {},
      timeStamps: {}
    },
    oandademo: {
      data: {},
      timeStamps: {}
    },
    oandalive: {
      data: {},
      timeStamps: {}
    },
    combined: {
      data: {},
      timeStamps: {}
    }
  },
  gd: {
    binanceus: {
      data: {},
      timeStamps: {}
    },
    binance: {
      data: {},
      timeStamps: {}
    },
    coinbasepro: {
      data: {},
      timeStamps: {}
    },
    oandademo: {
      data: {},
      timeStamps: {}
    },
    oandalive: {
      data: {},
      timeStamps: {}
    },
    combined: {
      data: {},
      timeStamps: {}
    }
  },
  initialValues: {},
  displayValue: '',
  isInitialized: false,
  portfolioTotalAmounts: null,
  porfolioLoading: true,
  portfolioError: null,
  assetError: null,
  earnings: [],
  earningsLoading: false,
  socket: null,
  isAssetDataSocketOn: false,
  oandaData: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_ASSET_DATA_START:
      return {
        ...state,
        assetLoading: action.loading
      };
    case actionTypes.FETCH_USER_ASSET_DATA_FAIL:
      return {
        ...state,
        assetLoading: action.loading,
        assetError: action.error
      };
    case actionTypes.FETCH_USER_ASSET_DATA_SUCCESS:
      return {
        ...state,
        assetData: [...action.assetData],
        initialPortfolioData: action.initialPortfolioData,
        oandaData: action.oandaData,
        portfolioTotalAmounts: action.portfolioTotalAmounts,
        assetLoading: action.loading,
        isAssetDataSocketOn: action.isAssetDataSocketOn
      };
    case actionTypes.FETCH_USER_PORTFOLIO_DATA_START:
      return {
        ...state,
        porfolioLoading: action.loading,
        isInitialized: action.isInitialized
      };
    case actionTypes.FETCH_USER_PORTFOLIO_DATA_FAIL:
      return {
        ...state,
        porfolioLoading: action.loading,
        portfolioError: action.error,
        isInitialized: action.isInitialized
      };
    case actionTypes.FETCH_USER_PORTFOLIO_DATA_SUCCESS:
      if (action.id === 5 || action.id === 6) {
        return {
          ...state,
          mgd: {
            ...state.mgd,
            [action.thirdGraphData.exchange]: {
              data: {
                ...state.mgd[action.thirdGraphData.exchange].data,
                [action.thirdGraphData.timeFrame]: [...action.thirdGraphData.data]
              },
              timeStamps: {
                ...state.mgd[action.thirdGraphData.exchange].timeStamps,
                [action.thirdGraphData.timeFrame]: [...action.thirdGraphData.timeStamps]
              },
              portfolioPerformance: {
                ...state.mgd[action.thirdGraphData.exchange].portfolioPerformance,
                [action.thirdGraphData.timeFrame]: action.thirdGraphData.portfolioPerformance
              },
              botPerformance: {
                ...state.mgd[action.thirdGraphData.exchange].botPerformance,
                [action.thirdGraphData.timeFrame]: action.thirdGraphData.botPerformance
              },
              portfolioChange: {
                ...state.mgd[action.thirdGraphData.exchange].portfolioChange,
                [action.thirdGraphData.timeFrame]: action.thirdGraphData.portfolioChange
              },
              botChange: {
                ...state.mgd[action.thirdGraphData.exchange].botChange,
                [action.thirdGraphData.timeFrame]: action.thirdGraphData.botChange
              },
              botChangeText: {
                ...state.mgd[action.thirdGraphData.exchange].botChangeText,
                [action.thirdGraphData.timeFrame]: action.thirdGraphData.botChangeText
              },
              balanceChange: {
                ...state.mgd[action.thirdGraphData.exchange].balanceChange,
                [action.thirdGraphData.timeFrame]: action.thirdGraphData.balanceChange
              },
              balancePerformance: {
                ...state.mgd[action.thirdGraphData.exchange].balancePerformance,
                [action.thirdGraphData.timeFrame]: action.thirdGraphData.balancePerformance
              },
              marginChange: {
                ...state.mgd[action.thirdGraphData.exchange].marginChange,
                [action.thirdGraphData.timeFrame]: action.thirdGraphData.marginChange
              },
              marginPerformance: {
                ...state.mgd[action.thirdGraphData.exchange].marginPerformance,
                [action.thirdGraphData.timeFrame]: action.thirdGraphData.marginPerformance
              },
              // botChangeText: action.graphData.botChangeText,
              portChangeText: action.thirdGraphData.portfolioChangeText
            }
          },
          bgd: {
            ...state.bgd,
            [action.secondGraphData.exchange]: {
              data: {
                ...state.bgd[action.secondGraphData.exchange].data,
                [action.secondGraphData.timeFrame]: [...action.secondGraphData.data]
              },
              timeStamps: {
                ...state.bgd[action.secondGraphData.exchange].timeStamps,
                [action.secondGraphData.timeFrame]: [...action.secondGraphData.timeStamps]
              },
              portfolioPerformance: {
                ...state.bgd[action.secondGraphData.exchange].portfolioPerformance,
                [action.secondGraphData.timeFrame]: action.secondGraphData.portfolioPerformance
              },
              botPerformance: {
                ...state.bgd[action.secondGraphData.exchange].botPerformance,
                [action.secondGraphData.timeFrame]: action.secondGraphData.botPerformance
              },
              portfolioChange: {
                ...state.bgd[action.secondGraphData.exchange].portfolioChange,
                [action.secondGraphData.timeFrame]: action.secondGraphData.portfolioChange
              },
              botChange: {
                ...state.bgd[action.secondGraphData.exchange].botChange,
                [action.secondGraphData.timeFrame]: action.secondGraphData.botChange
              },
              botChangeText: {
                ...state.bgd[action.secondGraphData.exchange].botChangeText,
                [action.secondGraphData.timeFrame]: action.secondGraphData.botChangeText
              },
              balanceChange: {
                ...state.bgd[action.secondGraphData.exchange].balanceChange,
                [action.secondGraphData.timeFrame]: action.secondGraphData.balanceChange
              },
              balancePerformance: {
                ...state.bgd[action.secondGraphData.exchange].balancePerformance,
                [action.secondGraphData.timeFrame]: action.secondGraphData.balancePerformance
              },
              marginChange: {
                ...state.bgd[action.secondGraphData.exchange].marginChange,
                [action.secondGraphData.timeFrame]: action.secondGraphData.marginChange
              },
              marginPerformance: {
                ...state.bgd[action.secondGraphData.exchange].marginPerformance,
                [action.secondGraphData.timeFrame]: action.secondGraphData.marginPerformance
              },
              // botChangeText: action.graphData.botChangeText,
              portChangeText: action.secondGraphData.portfolioChangeText
            }
          },
          gd: {
            ...state.gd,
            [action.graphData.exchange]: {
              data: {
                ...state.gd[action.graphData.exchange].data,
                [action.graphData.timeFrame]: [...action.graphData.data]
              },
              timeStamps: {
                ...state.gd[action.graphData.exchange].timeStamps,
                [action.graphData.timeFrame]: [...action.graphData.timeStamps]
              },
              portfolioPerformance: {
                ...state.gd[action.graphData.exchange].portfolioPerformance,
                [action.graphData.timeFrame]: action.graphData.portfolioPerformance
              },
              botPerformance: {
                ...state.gd[action.graphData.exchange].botPerformance,
                [action.graphData.timeFrame]: action.graphData.botPerformance
              },
              portfolioChange: {
                ...state.gd[action.graphData.exchange].portfolioChange,
                [action.graphData.timeFrame]: action.graphData.portfolioChange
              },
              botChange: {
                ...state.gd[action.graphData.exchange].botChange,
                [action.graphData.timeFrame]: action.graphData.botChange
              },
              botChangeText: {
                ...state.gd[action.graphData.exchange].botChangeText,
                [action.graphData.timeFrame]: action.graphData.botChangeText
              },
              balanceChange: {
                ...state.gd[action.graphData.exchange].balanceChange,
                [action.graphData.timeFrame]: action.graphData.balanceChange
              },
              balancePerformance: {
                ...state.gd[action.graphData.exchange].balancePerformance,
                [action.graphData.timeFrame]: action.graphData.balancePerformance
              },
              marginChange: {
                ...state.gd[action.graphData.exchange].marginChange,
                [action.graphData.timeFrame]: action.graphData.marginChange
              },
              marginPerformance: {
                ...state.gd[action.graphData.exchange].marginPerformance,
                [action.graphData.timeFrame]: action.graphData.marginPerformance
              },
              // botChangeText: action.graphData.botChangeText,
              portChangeText: action.graphData.portfolioChangeText
            }
          },
          initialValues: { ...state.initialValues, ...action.initialValues },
          displayValue: action.displayValue,
          porfolioLoading: action.loading,
          currentGraphData: action.currentGraphData
        };
      } else {
        return {
          ...state,
          isInitialized: action.isInitialized,
          gd: {
            ...state.gd,
            [action.graphData.exchange]: {
              data: {
                ...state.gd[action.graphData.exchange].data,
                [action.graphData.timeFrame]: [...action.graphData.data]
              },
              timeStamps: {
                ...state.gd[action.graphData.exchange].timeStamps,
                [action.graphData.timeFrame]: [...action.graphData.timeStamps]
              },
              portfolioPerformance: {
                ...state.gd[action.graphData.exchange].portfolioPerformance,
                [action.graphData.timeFrame]: action.graphData.portfolioPerformance
              },
              botPerformance: {
                ...state.gd[action.graphData.exchange].botPerformance,
                [action.graphData.timeFrame]: action.graphData.botPerformance
              },
              portfolioChange: {
                ...state.gd[action.graphData.exchange].portfolioChange,
                [action.graphData.timeFrame]: action.graphData.portfolioChange
              },
              botChange: {
                ...state.gd[action.graphData.exchange].botChange,
                [action.graphData.timeFrame]: action.graphData.botChange
              },
              botChangeText: {
                ...state.gd[action.graphData.exchange].botChangeText,
                [action.graphData.timeFrame]: action.graphData.botChangeText
              },
              // botChangeText: action.graphData.botChangeText,
              portChangeText: action.graphData.portfolioChangeText
            }
          },
          initialValues: { ...state.initialValues, ...action.initialValues },
          displayValue: action.displayValue,
          porfolioLoading: action.loading,
          currentGraphData: action.currentGraphData
        };
      }
    // currentGraphData: {
    //   datasets: [
    //     {
    //       ...defaultGraphData.datasets[0],
    //       data: [...action.currentGraphData.data]
    //     }
    //   ],
    //   labels: [...action.currentGraphData.timeStamps]
    // }
    case actionTypes.USER_SET_COLORSET:
      return {
        ...state,
        colorStatus: action.colorStatus
      };
    case actionTypes.FETCH_TOTAL_EARNINGS_START:
      return {
        ...state,
        earningsLoading: action.loading
      };
    case actionTypes.FETCH_TOTAL_EARNINGS_FAIL:
      return {
        ...state,
        earningsLoading: action.loading,
        error: action.error
      };
    case actionTypes.FETCH_TOTAL_EARNINGS_SUCCESS:
      return {
        ...state,
        earningsLoading: action.loading,
        earnings: action.data
      };
    case actionTypes.SET_USER_BASE_CURRENCY:
      return {
        ...state,
        baseCurrency: action.baseCurrency
      };
    default:
      return state;
  }
};

export default reducer;
