import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Image,
  Input,
  Spacer,
  Text,
  useColorMode,
  VStack
} from '@chakra-ui/react';
import { Auth, Cache } from 'aws-amplify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import EFLogo from '../../assets/eflogo.svg';

// import { useNavigate } from 'react-router-dom';

const ForgotPassword = props => {
  const [errorMessage, setErrorMessage] = useState();

  //   const navigate = useNavigate();

  const { colorMode, toggleColorMode } = useColorMode();

  const { handleSubmit, register, errors } = useForm({
    defaultValues: { username: '' }
  });

  const handleForgotPassword = ({ username }) => {
    Auth.forgotPassword(username)
      .then(({ CodeDeliveryDetails }) => {
        const in10Min = Date.now() + 5 * 60 * 1000;
        Cache.setItem('CodeDeliveryDetails', { ...CodeDeliveryDetails, username }, { expires: in10Min });
        // setMode('reset-password');
        props.history.push('/resetpassword');
      })
      .catch(error => setErrorMessage(error.message));
  };

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      pt={[18, 18, 36, 36, 48, 48]}
      w="full"
      bg={colorMode === 'light' ? 'white' : '#000'}
      h="full"
      minH="100vh"
      // py={24}
      // borderRadius={[0, 0, 24, 24, 24, 24]}
      // mt={5}
    >
      <Flex width="100%" fontSize="3xl" justifyContent="center" alignItems="center">
        <Flex align="center" justify="center" width="fit-content">
          <Image src={EFLogo} boxSize={16} objectFit="contain" />
        </Flex>
      </Flex>
      <VStack width="100%" spacing="10px">
        <Center fontWeight="bold" fontSize="xl" mt={6} mb={1}>
          Password Reset
        </Center>
        <Center fontWeight="bold" fontSize="sm" color="gray.600" mb={1}>
          A security code will be sent to your email address.
        </Center>

        <FormControl
          display="flex"
          flexDirection="column"
          align="center"
          w={['90%', '90%', '90%', 'full', 'full', 'full']}
          maxW="450px"
          mb={4}
        >
          <Flex flexDirection="column" justify="center" width="full" my={6}>
            <FormLabel htmlFor="username" fontSize="sm">
              Email
            </FormLabel>

            <Input
              type="email"
              id="username"
              name="username"
              {...register('username', {
                required: 'Enter your email adress'
              })}
            />
            {!!errors?.username && <div className="form-feedback-invalid">{errors?.username?.message}</div>}

            {errorMessage ? (
              <Flex width="100%" justify="center" mt={4}>
                <Text color="red.400" fontSize="sm">
                  {errorMessage}
                </Text>
              </Flex>
            ) : (
              ''
            )}
          </Flex>

          <Box>
            <Button
              title="Get Security Code"
              w="full"
              fontSize="13px"
              // mt={8}
              bg={colorMode === 'light' ? 'black' : 'transparent'}
              // borderColor={epicFlowGradientColors.left}
              mr={4}
              borderColor={props.bg}
              color={colorMode === 'light' ? 'white' : 'black'}
              borderWidth={1}
              _hover={{
                bg: colorMode === 'light' ? 'black' : 'transparent'
              }}
              _focus={{
                outline: 'none'
              }}
              _active={{
                bg: colorMode === 'light' ? 'black' : 'transparent'
              }}
              onClick={handleSubmit(handleForgotPassword)}
            >
              Get Security Code
            </Button>
          </Box>
        </FormControl>
        <Flex align="center">
          <Flex onClick={() => props.history.push('/login')}>
            <Text mr={1} fontWeight="bold" fontSize="sm">
              Back to
            </Text>
            <Text as="u" fontSize="sm" color="blue.600" fontWeight="bold" _hover={{ cursor: 'pointer' }}>
              Sign In
            </Text>
          </Flex>
        </Flex>
      </VStack>
    </Flex>
  );
};

export default ForgotPassword;
