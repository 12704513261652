import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Switch,
  Text,
  useColorMode,
  useDisclosure
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { AiOutlineLineChart } from 'react-icons/ai';
import { v4 as uuidv4 } from 'uuid';

import { epicFlowGradientColors } from '../../../theme';

const TimeBar = React.memo(props => {
  const chartPeriods = ['1D', '1W', '1M', '3M', '1Y', 'ALL'];

  const { colorMode, toggleColorMode } = useColorMode();

  const [selectedPeriod, setSelectedPeriod] = useState('1D');

  const [isBalanceSwitchChecked, setIsBalanceSwitchChecked] = useState(true);

  const [isMASwitchChecked, setIsMASwitchChecked] = useState(false);

  const { isOpen, onToggle, onClose } = useDisclosure();

  const timePeriodsHandler = (period, state) => {
    if (selectedPeriod !== period) {
      setSelectedPeriod(period);
      props.setChartData(period);
    }
  };

  const handleBalanceToggler = e => {
    if (e.target.checked) {
      setIsBalanceSwitchChecked(true);
    } else {
      setIsBalanceSwitchChecked(false);
    }
    props.balance(e.target.checked);
  };

  const handleMAToggler = e => {
    if (e.target.checked) {
      setIsMASwitchChecked(true);
    } else {
      setIsMASwitchChecked(false);
    }
    props.marginAvailable(e.target.checked);
  };

  const chartViews = chartPeriods.map(el => {
    return (
      <Box key={uuidv4()} onClick={() => timePeriodsHandler(el)}>
        <Flex align="center" justify="center" mr={2.5} pt={1.5} pb={1.5} cursor="pointer">
          <Flex mr={2} p={1} borderRadius="md">
            <Text
              fontSize="xs"
              fontWeight="black"
              colorScheme="whiteAlpha"
              color={selectedPeriod === el ? props.color : null}
              m={0}
              userSelect="none"
            >
              {el}
            </Text>
          </Flex>
        </Flex>
      </Box>
    );
  });

  return (
    <Flex
      position="relative"
      align="center"
      justify="flex-start"
      pr={2.5}
      pb={2}
      mb={2}
      w={props.w}
      m={props.m}
      borderBottomWidth={1}
    >
      {chartViews}
      <Flex justifyContent="flex-end" flex={1} position="absolute" right={0}>
        <Popover
        // returnFocusOnClose={false}
        // isOpen={isOpen}
        // autoFocus={false}
        // onClose={onClose}
        // placement="left-start"
        // closeOnBlur={false}
        >
          {(props.exchange === 'Oanda Demo' || props.exchange === 'Oanda Live') && (
            <PopoverTrigger>
              <IconButton
                {...props}
                _active={{ background: 'transparent' }}
                _hover={{ background: 'transparent' }}
                as={AiOutlineLineChart}
                color={colorMode == 'light' ? 'black' : 'white'}
                w={5}
                h={5}
                background="transparent"
                cursor="pointer"
                onClick={onToggle}
              />
            </PopoverTrigger>
          )}
          <PopoverContent
            bg={colorMode === 'light' ? 'white' : '#1f2124'}
            zIndex={401}
            right="324px"
            bottom={12}
            _focus={{ outline: 'none' }}
          >
            <PopoverArrow />
            <PopoverCloseButton _focus={{ outline: 'none' }} />
            <PopoverHeader borderBottomWidth={1} w="90%" margin="auto" pl={0} pt={2} fontWeight="black">
              Lines
            </PopoverHeader>
            <PopoverBody w="90%" margin="auto" px={0}>
              <Flex w="full" justifyContent="space-between" alignItems="center">
                <Flex alignItems="center">
                  <Flex
                    w="6px"
                    h={6}
                    bg="#4361ee"
                    justify="flex-start"
                    align="center"
                    borderLeftRadius="md"
                  ></Flex>
                  <Text ml={2} fontSize="sm" fontWeight="semibold">
                    NAV
                  </Text>
                </Flex>
                <Switch
                  size="sm"
                  isChecked
                  isFocusable={false}
                  colorScheme="whatsapp"
                  disabled
                  // onChange={e => handleBalanceToggler(e)}
                />
              </Flex>
              <Flex w="full" justifyContent="space-between" alignItems="center" mt={4}>
                <Flex alignItems="center">
                  <Flex
                    w="6px"
                    h={6}
                    bg="#48BB78"
                    justify="flex-start"
                    align="center"
                    borderLeftRadius="md"
                  ></Flex>
                  <Text ml={2} fontSize="sm" fontWeight="semibold">
                    Balance
                  </Text>
                </Flex>
                <Switch
                  size="sm"
                  isChecked={isBalanceSwitchChecked}
                  isFocusable={false}
                  colorScheme="whatsapp"
                  onChange={e => handleBalanceToggler(e)}
                />
              </Flex>
              <Flex w="full" justifyContent="space-between" alignItems="center" mt={4}>
                <Flex alignItems="center">
                  <Flex
                    w="6px"
                    h={6}
                    bg="#ffbd00"
                    justify="flex-start"
                    align="center"
                    borderLeftRadius="md"
                  ></Flex>
                  <Text ml={2} fontSize="sm" fontWeight="semibold">
                    Margin Available
                  </Text>
                </Flex>
                <Switch
                  size="sm"
                  isChecked={isMASwitchChecked}
                  isFocusable={false}
                  colorScheme="whatsapp"
                  onChange={e => handleMAToggler(e)}
                />
              </Flex>
              {/* <Flex w="full" justifyContent="space-between" alignItems="center" mt={2}>
                <Flex alignItems="center">
                  <Flex
                    w="6px"
                    h={6}
                    bg="#3d0066"
                    justify="flex-start"
                    align="center"
                    borderLeftRadius="md"
                  ></Flex>
                  <Text ml={2} fontSize="sm" fontWeight="semibold">
                    Margin
                  </Text>
                </Flex>
                <Switch
                  size="sm"
                  isChecked={isSwitchChecked}
                  isFocusable={false}
                  colorScheme="whatsapp"
                  onChange={e => handleToggler(e)}
                />
              </Flex> */}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    </Flex>
  );
});
TimeBar.propTypes = {
  color: PropTypes.string,
  w: PropTypes.string,
  m: PropTypes.string
};

export default TimeBar;
