import { Flex, Heading, Image, ListItem, OrderedList, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

import AddExchange1 from '../../assets/add-exchange1.png';
import AddExchange2 from '../../assets/add-exchange2.png';

// const imageWidth = ['70%', '70%', '35%', '40%', '40%', '40%', '30%'];
const imageFlexJustify = ['center', 'center', 'space-around', 'space-around', 'space-around', 'space-around'];
const imageFlexAlign = ['center', 'center', 'stretch', 'stretch', 'stretch', 'stretch'];

const topHeadingSize = ['2xl', '2xl', '2xl', '3xl', '3xl', '4xl'];
const imageWidths = ['100%', '100%', '80%', '70%', '60%', '60%'];

function HowToIntegrate() {
  return (
    <Flex w="100%" direction="column" justify="center" align="center" mt={4}>
      <Flex w="95%" direction="column" justify="center" align="center" fontWeight="light">
        <Heading mb={[8, 8, 10, 10, 12, 12]} fontWeight="light" textAlign="center" fontSize={topHeadingSize}>
          How to Integrate EpicFlow.io Trading Bots With Your Binance Account?{' '}
        </Heading>
        <Text mb={4} w="full" textAlign="left">
          After creating an API key, there are only a few steps left between you and benefiting from the most
          convenient Binance trading bots:
        </Text>
        <Flex mb={14} direction="column">
          <OrderedList spacing={2} w="full">
            <ListItem>
              <Text>Head to your app.epicflow.io account and login..</Text>
            </ListItem>
            <ListItem>
              <Text>Click on your account on top right corner and then click &quot;Add exchange&quot;.</Text>
            </ListItem>

            <ListItem>
              <Text>Select Binance.us or Binance.com exchange.</Text>
            </ListItem>

            <Flex w="full" justify="center" align="center">
              <Image
                // boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
                alt="Create Binance API Key"
                src={AddExchange1}
                py={4}
                w={imageWidths}
              />
            </Flex>

            <ListItem>
              <Text>Fill in your API and Secret keys via pasting your respective Binance records.</Text>
            </ListItem>
            <ListItem>
              <Text>Click on &quot;Link Account&quot;.</Text>
            </ListItem>

            <Flex w="full" justify="center" align="center">
              <Image
                // boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
                alt="Create Binance API Key"
                src={AddExchange2}
                py={4}
                w={imageWidths}
              />
            </Flex>

            <ListItem>
              <Text>
                Your Binance account is now linked to epicflow.io and you can start creating your first bots.
              </Text>
            </ListItem>

            <ListItem>
              <Text>
                Read our{' '}
                <Text as="span" color="messenger.600" textDecoration="underline">
                  <Link to="/help/createbots">How to Create Regular and Inverse Bots?</Link>
                </Text>{' '}
                article to create your first trading bot. And if you want to learn more about our trading bots, we
                recommend you to check out our{' '}
                <Text as="span" color="messenger.600" textDecoration="underline">
                  <Link to="/help/whatisdca">What Is Dollar Cost Averaging?</Link>
                </Text>{' '}
                article.
              </Text>
            </ListItem>
          </OrderedList>

          {/* <Flex
            display={["none", "none", "flex", "flex", "flex", "flex"]}
            direction={["column", "column", "row", "row", "row", "row"]}
            gap={4}
            w="full"
            justify={imageFlexJustify}
            align={imageFlexAlign}
            mt={8}
            // border="1px"
          >
            <Image
              alt="EpicFlow.io add exchange"
              src="/add-exchange-image.ico"
              py={4}
              w={imageWidth}
            />

            <Image
              alt="EpicFlow.io link your Binance account."
              src="/link-account-image.ico"
              py={4}
              w={imageWidth}
            />
          </Flex> */}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default HowToIntegrate;
