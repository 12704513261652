import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  color,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  useColorMode,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import {
  AiOutlineApi,
  AiOutlineCalendar,
  AiOutlineClockCircle,
  AiOutlineDeploymentUnit,
  AiOutlineGold,
  AiOutlineLineChart,
  AiOutlinePieChart
} from 'react-icons/ai';
import { FiHelpCircle, FiLogOut, FiMoon, FiPlus, FiSettings, FiSun } from 'react-icons/fi';
import { MdChatBubbleOutline } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';

import CustomIconLink from '../../UI/Link/customIconLink';
import { DrawerLogo } from '../../UI/logo';
import DrawerLink from '../Drawer/drawerLink';

// DrawerFooter is fixed position to bottom 0 when scroll

const Hamburger = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const btnRef = React.useRef();

  const displayOptions = ['flex', 'flex', 'flex', 'none', 'none', 'none'];

  const { colorMode, toggleColorMode } = useColorMode();

  const [isMobile] = useMediaQuery('(max-width: 48em)');

  const customThemeToggler = text => {
    if (text === 'Add Exchange') {
      return props.onAddExchangeClick();
    } else if (text === 'Light Theme' || text === 'Dark Theme') {
      return toggleColorMode();
    } else {
      return onClose();
    }
  };

  const linkItems = [
    {
      text: 'Dashboard',
      icon: <CustomIconLink icon={AiOutlinePieChart} />,
      isLink: true
    },
    {
      text: 'My Bots',
      icon: <CustomIconLink icon={AiOutlineApi} />,
      isLink: true
    },
    {
      text: 'Reporting',
      icon: <CustomIconLink icon={AiOutlineCalendar} />,
      isLink: true
    },
    // {
    //   text: 'News',
    //   icon: <CustomIconLink icon={AiOutlineClockCircle} />,
    //   isLink: true
    // },
    // {
    //   text: 'Markets',
    //   icon: <CustomIconLink icon={AiOutlineLineChart} />,
    //   isLink: true
    // },
    {
      text: 'Subscriptions',
      icon: <CustomIconLink icon={AiOutlineGold} />,
      isLink: true
    },
    // {
    //   text: 'Social',
    //   icon: <CustomIconLink icon={AiOutlineDeploymentUnit} />,
    //   isLink: true
    // },
    {
      text: 'Settings',
      icon: <CustomIconLink icon={FiSettings} />,
      isLink: true
    }
  ];

  const menuItems = [
    {
      text: 'Add Exchange',
      icon: <CustomIconLink icon={FiPlus} />,
      isLink: false
    },
    {
      text: colorMode === 'dark' ? 'Light Theme' : 'Dark Theme',
      icon: <CustomIconLink icon={colorMode === 'light' ? FiMoon : FiSun} />,
      isLink: false
    },
    {
      text: 'Support',
      icon: <CustomIconLink icon={MdChatBubbleOutline} />,
      isLink: true
    }
    // {
    //   text: 'Help',
    //   icon: <CustomIconLink icon={FiHelpCircle} />,
    //   isLink: true
    // }
  ];

  useEffect(() => {
    if (!isMobile) onClose();
  }, [isMobile]);

  // console.log('isOpen is ', isOpen);
  return (
    <Flex
      display={displayOptions}
      justify="space-between"
      align="center"
      w="100%"
      // m="auto"
      // mt={6}
      pt={6}
      mr={10}
      // overflow="scroll"
      bg={colorMode === 'light' ? 'white' : '#000'}
    >
      <DrawerLogo />
      <HamburgerIcon ref={btnRef} onClick={onOpen} w={6} h={6} cursor="pointer" mr="2.5%" />
      <Drawer
        size="full"
        isOpen={isOpen}
        // autoFocus={false}
        closeOnEsc
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent overflow="scroll" bg={colorMode === 'light' ? 'white' : '#0f1114'}>
          <DrawerCloseButton
            _focus={{
              outline: 'none'
            }}
          />
          <DrawerBody mt={8} mb={36}>
            <Box>
              {linkItems.map(link => {
                return (
                  <DrawerLink
                    key={uuidv4()}
                    text={link.text}
                    onClick={onClose}
                    to={
                      link.text !== 'Dashboard'
                        ? link.text.toLowerCase().replace(/\s/g, '').split('').join('')
                        : '/'
                    }
                    isLink={link.isLink}
                  >
                    {link.icon}
                  </DrawerLink>
                );
              })}
            </Box>

            <Divider />

            <Box mt={4}>
              {menuItems.map(link => {
                return (
                  <DrawerLink
                    key={uuidv4()}
                    onFlexClick={() => customThemeToggler(link.text)}
                    onClick={onClose}
                    text={link.text}
                    isLink={link.isLink}
                    to={link.isLink ? link.text.toLowerCase().replace(/\s/g, '').split('').join('') : '/'}
                  >
                    {link.icon}
                  </DrawerLink>
                );
              })}
            </Box>

            <Divider />

            <Box mt={4}>
              <DrawerLink text="Log Out" onClick={props.onLogout} isLink>
                <CustomIconLink icon={FiLogOut} />
              </DrawerLink>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

Drawer.propTypes = {};

export default Hamburger;
