import { Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import PersonalNewsCard from '../components/cards/personalNewsCard';

const News = props => {
  const [marketData, setMarketData] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchMarketData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=true&price_change_percentage=7d`
      );
      const data = await response.json();
      setMarketData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchMarketData();
  }, []);

  return (
    <Flex
      w={['full', 'full', '95%', '95%', '95%', '95%']}
      // maxW="7xl"
      justify="space-between"
      overflowY="hidden"
      overflowX="hidden"
      margin="auto"
      pb={24}
      mt={[8, 8, 12, 12, 12, 12]}
    >
      <Flex
        w={['full', 'full', 'full', 'full', 'full', 'full']}
        direction="column"
        justify="flex-start"
        minH="100vh"
        align="flex-start"
      >
        <Heading fontSize="2xl" mt={4} mb={4} ml={2}>
          Latest News
        </Heading>
        <Flex flexDirection="row" alignItems="flex-start" justifyContent="space-between">
          <PersonalNewsCard />
          {!loading && marketData.length > 0 && (
            <Flex
              direction="column"
              w="xs"
              alignItems="flex-start"
              justify="flex-start"
              borderRadius={16}
              borderWidth={1}
              px={6}
              boxShadow="lg"
              display={['none', 'none', 'none', 'none', 'flex', 'flex']}
              pb={6}
            >
              <Heading size="sm" mt={5} mb={12}>
                Top Cryptocurrencies
              </Heading>
              {marketData.slice(0, 15).map((el, idx) => {
                return (
                  <Flex key={idx} w="full" alignItems="center" justifyContent="space-between" mb={12}>
                    <Text fontWeight="bold" fontSize="sm" color="gray.400">
                      {el.symbol.toUpperCase()}
                    </Text>
                    <Flex w={44} justifyContent="space-between">
                      <Text fontWeight="bold" fontSize="sm" color="gray.700">
                        ${el.current_price}
                      </Text>
                      <Text
                        fontWeight="bold"
                        fontSize="sm"
                        color={el.price_change_percentage_24h < 0 ? 'red.400' : 'green.400'}
                      >
                        {el.price_change_percentage_24h.toFixed(2)}%
                      </Text>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default News;
