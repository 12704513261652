import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Spacer,
  Text,
  useColorMode,
  useEditable,
  VStack
} from '@chakra-ui/react';
import { Auth, Cache, Hub } from 'aws-amplify';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import EFLogo from '../../assets/eflogo.svg';
// import { useLocation, useNavigate } from 'react-router-dom';
import SocialLogin from '../../components/UI/Button/socialLoginContainer';
import { login } from '../../store/actions/auth';
import { epicFlowGradientColors } from '../../theme';
import { dispatchSetPairs } from '../../utilities/wizardMenuItemsHelper';

const Login = ({ onSignIn, setAuthState, socialProviders, redirectToVerification, isAuth, history }) => {
  const [errorMessage, setErrorMessage] = useState('');
  // const navigate = useNavigate();
  // const location = useLocation();
  const { colorMode, toggleColorMode } = useColorMode();

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: { username: '', password: '' }
  });

  // const handleSignIn = formData => onSignIn(formData);

  const handleSignIn = async ({ username, password }) => {
    // await Auth.signIn(username)
    //   .then(async user => {
    try {
      const user = await Auth.signIn(username, password);
      console.log('--user:', user);
      onSignIn(user);
      // navigate('/');
      history.push('/');
      console.log('User signed in:', user);
    } catch (error) {
      // )
      if (error.code === 'UserNotConfirmedException') {
        redirectToVerification(username);
        setErrorMessage('Please verify your account');
        history.push(`verify/accountname?=${username}`);
        return null;
      } else if (error.code === 'NotAuthorizedException') {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('Unknown error');
      }
    }
    // );
  };

  const handleGoogleSignin = async () => {
    await Auth.federatedSignIn({ provider: 'Google' }).catch(error => setErrorMessage('error.message'));
    // try {
    //   await Auth.federatedSignIn({ provider: 'Google' });
    // } catch (error) {
    //   setErrorMessage(error.message)
    //   console.log(`Google login error --->`, error);
    // }
  };

  const handleAppleSignin = () => Auth.federatedSignIn({ provider: 'SignInWithApple' });

  useEffect(() => {
    setErrorMessage(null);
  }, []);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'cognitoHostedUI') {
        onSignIn(data);
      }
    });
  }, [onSignIn]);

  const checkAuth = useCallback(() => {
    if (isAuth) {
      history.push('/');
    }
  }, []);

  checkAuth();

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent={['flex-start', 'flex-start', 'center', 'center', 'center', 'center']}
      pt={[20, 20, 0, 0, 0, 0]}
      w="full"
      bg={colorMode === 'light' ? 'white' : '#000'}
      h="full"
      minH="100vh"
      // py={24}
      // borderRadius={[0, 0, 24, 24, 24, 24]}
      // mt={5}
    >
      <Flex width="100%" fontSize="3xl" justifyContent="center" alignItems="center">
        <Flex align="center" justify="center" width="fit-content">
          <Image src={EFLogo} boxSize={16} objectFit="contain" />
        </Flex>
      </Flex>
      <VStack width="100%" spacing="10px">
        <Center fontWeight="bold" mb={1} mt={2}>
          To continue, sign in to EpicFlow
        </Center>

        <SocialLogin
          providers={socialProviders}
          onGoogleSignIn={handleGoogleSignin}
          onAppleSignIn={handleAppleSignin}
        />

        <FormControl display="flex" flexDirection="column" alignItems="center">
          <Flex
            flexDirection="column"
            justify="center"
            w={['90%', '90%', '90%', 'full', 'full', 'full']}
            maxW="450px"
            mb={4}
          >
            <FormLabel htmlFor="username" fontSize="sm" fontWeight="semibold">
              Email
            </FormLabel>
            <Input
              border="2px"
              type="text"
              id="username"
              name="username"
              h={12}
              className={clsx('form-control', !!errors?.username && 'border-red-600')}
              {...register('username', {
                required: 'Enter your email adress'
              })}
            />
            {!!errors?.username && <div className="form-feedback-invalid">{errors?.username?.message}</div>}
          </Flex>
          <Flex
            flexDirection="column"
            justify="center"
            align="flex-start"
            mb={4}
            maxW="450px"
            w={['90%', '90%', '90%', 'full', 'full', 'full']}
          >
            <FormLabel width="full" htmlFor="password" fontSize="sm" fontWeight="semibold">
              Password
            </FormLabel>
            <Input
              border="2px"
              type="password"
              id="password"
              name="password"
              h={12}
              className={clsx('form-control', !!errors?.password && 'border-red-600')}
              {...register('password', {
                required: true,
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                }
              })}
            />
            {!!errors?.password && <Text>{errors?.password?.message}</Text>}

            <Button variant="unstyled" fontSize="sm" onClick={() => history.push('/forgotpassword')}>
              Forgot your password?
            </Button>
          </Flex>

          {errorMessage && (
            <Flex justify="center" mb={4}>
              <Text color="red.500" fontSize="sm" fontWeight="medium">
                {errorMessage}
              </Text>
            </Flex>
          )}

          <Box>
            <Button
              bg={epicFlowGradientColors.left}
              _hover={{
                backgroundColor: epicFlowGradientColors.left
              }}
              px={8}
              color="white"
              fontWeight="semibold"
              fontSize="sm"
              borderRadius="full"
              onClick={handleSubmit(handleSignIn)}
            >
              LOG IN
            </Button>
          </Box>
        </FormControl>

        {/* <Divider mt={8} mb={8} w="100vw" /> */}

        <Flex width="full" alignItems="center" justify="center" pb={4}>
          <Text mr={2} fontWeight="semibold" fontSize="sm">
            Dont have an account?
          </Text>

          <Text
            fontWeight="semibold"
            // color="blue.600"
            as="u"
            fontSize="sm"
            onClick={() => history.push('/signup')}
            _hover={{ cursor: 'pointer' }}
          >
            Request Access
          </Text>
        </Flex>
      </VStack>
    </Flex>
  );
};

export default Login;
