import { Flex, Heading, Image, ListItem, OrderedList, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

import InverseBotGraphDCA from '../../assets/inverse-bot-chart_dca.png';
import InverseBotGraph from '../../assets/inverse-bot-graph.png';
import RegularBotGraphDCA from '../../assets/regular-bot-chart_dca.png';
import RegularBotGraph from '../../assets/regular-bot-graph.png';

const topHeadingSize = ['2xl', '2xl', '2xl', '3xl', '3xl', '4xl'];
const textSizes = ['sm', 'md', 'md', 'md', 'md', 'md'];
const imageWidth = ['100%', '80%', '65%', '60%', '60%', '70%'];
const figureTextSizes = ['10px', 'sm', 'sm', 'sm', 'sm', 'md'];

function WhatIsDCA() {
  return (
    <Flex w="100%" direction="column" justify="center" align="center" mt={4}>
      <Flex w="95%" direction="column" justify="center" align="center" fontWeight="light" mb={14}>
        <Heading mb={[8, 8, 10, 10, 12, 12]} fontWeight="light" textAlign="center" fontSize={topHeadingSize}>
          What is Dollor Cost Averaging (DCA)?
        </Heading>

        <Text mb={4} w="full" fontSize={textSizes}>
          Our bots use a proprietary{' '}
          <Text as="span" fontWeight="bold">
            AI-driven Dollar Cost Averaging (DCA)
          </Text>{' '}
          technique while investing in assets when certain favorable smart conditions are met to maximize the
          crypto pairs&apos; return. EpicFlow.io is the first hyper-convenient and AI-driven DCA strategy that
          automatically works 24/7 for you. If your goal is to get more crypto in the long run, doing DCA beats
          simple buy and hold.
        </Text>

        <Text mb={4} w="full" fontSize={textSizes}>
          Another advantage of EpicFlow.io is the bots can be created to profit from upward or downward trends. We
          will call them{' '}
          <Text as="span" fontWeight="bold">
            Regular
          </Text>{' '}
          and{' '}
          <Text as="span" fontWeight="bold">
            Inverse
          </Text>{' '}
          bots for the sake of simplicity.
        </Text>

        <Text mb={4} w="full" fontSize={textSizes}>
          <Text as="span" fontWeight="bold">
            Regular
          </Text>{' '}
          bots smartly{' '}
          <Text as="span" fontWeight="bold">
            buy
          </Text>{' '}
          at dips while there is a downward pricing trend. This helps to continuously decrease the cost average of
          your bot (green line in the below picture). Then once the price moves up at a min 1% higher than the
          latest cost average, then the bot sells all current assets and exits the run with a min of 1% profit.
          Profit % is typically higher thanks to our proprietary trailing stop techniques that maximize the %
          profit as price ascends.{' '}
          <Text as="span" color="messenger.600" textDecoration="underline">
            <Link to="/help/createbots">Learn how to create regular bots.</Link>
          </Text>
        </Text>

        <Flex direction="column" w="full" justify="center" align="center" mt={8} mb={12}>
          <Image w={imageWidth} rounded="lg" alt="Create Binance API Key" src={RegularBotGraphDCA} />

          <Text as="i" fontSize={figureTextSizes}>
            Figure above provides visual explanaition of{' '}
            <Text as="span" fontWeight="bold">
              regular bots.{' '}
            </Text>
          </Text>
        </Flex>

        <Text mb={4} w="full" fontSize={textSizes}>
          <Text as="span" fontWeight="bold">
            Inverse
          </Text>{' '}
          bots{' '}
          <Text as="span" fontWeight="bold">
            sell
          </Text>{' '}
          at spikes and hikes while there is an upward pricing trend. This helps to continuously increase the cost
          average of your bot. Then once the price moves down at a min 1% lower than the latest cost average, then
          the bot buys all (the sold) assets back and exits the run with a min of 1% profit (more of that asset
          that you had earlier). This is an innovative strategy to apply with crypto-crypto pairs to continue
          “minting” your desired crypto asset.
          <Text as="span" color="messenger.600" textDecoration="underline">
            <Link to="/help/createbots">Learn how to create inverse bots.</Link>
          </Text>
        </Text>

        <Flex direction="column" w="full" justify="center" align="center" mt={8} mb={12}>
          <Image w={imageWidth} rounded="lg" alt="Create Binance API Key" src={InverseBotGraphDCA} />

          <Text as="i" fontSize={figureTextSizes}>
            Figure above provides visual explanaition of{' '}
            <Text as="span" fontWeight="bold">
              inverse bots.
            </Text>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default WhatIsDCA;
