import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const SubInfo = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const [loading, setLoading] = useState(false);

  const authState = useSelector(state => state.auth);

  const handleClosing = () => {
    props.onclose();
  };

  return (
    <Modal
      isCentered
      motionPreset="slideInBottom"
      isOpen={props.isopen}
      scrollBehavior="inside"
      onClose={handleClosing}
      size="3xl"
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius="2xl" bg={colorMode === 'light' ? 'white' : 'gray.900'}>
        <ModalHeader fontWeight="bold" fontSize="xl" mt={10} pb={0} pt={0}>
          {props.title}
        </ModalHeader>{' '}
        <ModalCloseButton
          _focus={{
            outline: 'none'
          }}
        />
        <ModalBody>
          <Flex direction="column" justify="flex-start" align="flex-start">
            <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} w="full">
              *Your current plan is {authState.stripeCurrentPlan.toUpperCase()}
            </Text>
            <Divider my={4} />
            {!props.result && !props.error && (
              <Flex w="full" alignItems="center" flexDir="column">
                <AlertTitle fontWeight="semibold">{props.upgrade ? 'Upgrade Now?' : 'Downgrade Now?'}</AlertTitle>
                {/* <Text my={2}>Are you sure you want to terminate {props.name}?</Text> */}
                <Alert
                  status="info"
                  variant="unstyled"
                  textAlign="center"
                  flexDirection="column"
                  alignItems="center"
                >
                  <AlertIcon boxSize="24px" />
                  {!props.result && (
                    <Text fontSize="sm" w="full" my={4} textAlign="left">
                      {props.upgrade
                        ? `Please note that upgrading your subscription plan will take effect immediately, with prorated charges applied for the remaining time on your current plan. Do you wish to proceed?`
                        : `Please be aware that subscription downgrades will take effect at the end of your billing cycle. To ensure adherence to the new plan's restrictions, our team may take various actions if your maximum margin utilization or bot count exceed the new plan's limitations. These actions could include pausing certain bot functionalities or implementing other necessary adjustments. Do you wish to proceed?`}
                    </Text>
                  )}
                </Alert>
              </Flex>
            )}
            {props.result && (
              <Flex direction="column" alignItems="center" mt={4} w="full">
                <Alert
                  status="success"
                  variant="subtle"
                  bg=""
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  fontSize="13px"
                >
                  <AlertIcon boxSize="28px" mb={4} />
                  {/* <AlertTitle mt={4} mb={1} fontSize="lg">
                    Succesfull!
                  </AlertTitle> */}
                  {`Success! Your request has been processed successfully. You can now navigate to the 'Settings' tab
                  and access the 'Billing' section to manage your subscription preferences. Thank you for choosing
                  our service!`}
                  {/* <AlertDescription maxWidth="sm">
                    {selected === 1
                      ? 'Your bot will continue to run.'
                      : selected === 2
                      ? 'Your bot is successfully terminated.'
                      : 'blal'}
                  </AlertDescription> */}
                </Alert>
              </Flex>
            )}
            {props.error && (
              <Flex direction="column" alignItems="center" mt={4} w="full">
                <Alert
                  status="error"
                  variant="subtle"
                  bg=""
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  fontSize="13px"
                >
                  <AlertIcon boxSize="32px" mr={0} color="red.400" />
                  <AlertTitle mt={2} mb={1} fontSize="md">
                    Oh Snap!
                  </AlertTitle>
                  <AlertDescription maxWidth="sm">{props.error}</AlertDescription>
                </Alert>
              </Flex>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          {props.result || props.error ? (
            <Button
              w={['full', 'full', '60%', '60%', '60%', '60%']}
              fontSize="13px"
              mb={8}
              mt={2}
              mx="auto"
              color={colorMode === 'light' ? 'white' : 'black'}
              bg={colorMode === 'light' ? 'black' : 'white'}
              _hover={{
                bg: colorMode === 'light' ? 'black' : 'transparent'
              }}
              _focus={{
                outline: 'none'
              }}
              _active={{
                bg: colorMode === 'light' ? 'black' : 'transparent'
              }}
              onClick={props.onclose}
              //   _active={{ background: epicFlowGradientColors.right }}
            >
              Close
            </Button>
          ) : (
            <Button
              w={['full', 'full', '60%', '60%', '60%', '60%']}
              fontSize="13px"
              mb={8}
              mt={2}
              mx="auto"
              isDisabled={props.loading}
              isLoading={props.loading}
              color={colorMode === 'light' ? 'white' : 'black'}
              bg={colorMode === 'light' ? 'black' : 'white'}
              _hover={{
                bg: colorMode === 'light' ? 'black' : 'white'
              }}
              _focus={{
                outline: 'none'
              }}
              _active={{
                bg: colorMode === 'light' ? 'black' : 'white'
              }}
              onClick={props.onClick}
              //   _active={{ background: epicFlowGradientColors.right }}
            >
              Confirm
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SubInfo;
