import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  TriangleDownIcon,
  TriangleUpIcon
} from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const menuGroupTextSize = ['md', 'md', 'md', 'md', 'md', 'md'];
const menuItemTextSize = ['sm', 'sm', 'sm', 'sm', 'sm', 'sm'];

function HelpDeskMenu({ helpContent }) {
  return (
    <Menu>
      <MenuButton rightIcon={<ChevronDownIcon />}>
        <Flex justify="center" align="center">
          <Heading w="full" fontSize="2xl" my={4}>
            Help Documentation
          </Heading>

          {/* <Text>Help Documentation</Text> */}
          <ChevronDownIcon boxSize={8} pt={1} />
        </Flex>
      </MenuButton>
      <MenuList minWidth="240px">
        <MenuGroup title="Getting Started" fontSize={menuGroupTextSize}>
          <MenuItem>
            <Text
              ml={8}
              fontSize={menuItemTextSize}
              color={helpContent === 'createbinanceapikey' ? 'blue.600' : ''}
            >
              <Link to="/help/createbinanceapikey">Create Binance API key</Link>
            </Text>
          </MenuItem>
          <MenuItem>
            <Text ml={8} fontSize={menuItemTextSize} color={helpContent === 'howtointegrate' ? 'blue.600' : ''}>
              <Link to="/help/howtointegrate">How To Integrate?</Link>
            </Text>
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title="Trading Bots" fontSize={menuGroupTextSize}>
          <MenuItem>
            <Text ml={8} fontSize={menuItemTextSize} color={helpContent === 'whatisdca' ? 'blue.600' : ''}>
              <Link to="/help/whatisdca">What Is DCA?</Link>
            </Text>
          </MenuItem>
          <MenuItem>
            <Text ml={8} fontSize={menuItemTextSize} color={helpContent === 'createbots' ? 'blue.600' : ''}>
              <Link to="/help/createbots">Create Trading Bot</Link>
            </Text>
          </MenuItem>
        </MenuGroup>
        <MenuGroup title="Videos" fontSize={menuGroupTextSize}>
          <MenuItem>
            <Text ml={8} fontSize={menuItemTextSize} color={helpContent === 'trainingvideos' ? 'blue.600' : ''}>
              <Link to="/help/trainingvideos">Training Videos</Link>
            </Text>
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}

export default HelpDeskMenu;
