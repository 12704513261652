export const convertToInternationalCurrencySystem = labelValue => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
    : Math.abs(Number(labelValue));
};

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 10 // (causes 2500.99 to be printed as $2,501),
});

export const dynamicCurrencyFormatter = currency => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency ? currency : 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 10 // (causes 2500.99 to be printed as $2,501),
  });
};

export const getMoney = A => {
  var a = new Number(A);
  var b = a.toFixed(2); // Get 12345678.90
  a = parseInt(a); // Get 12345678
  b = (b - a).toPrecision(2); // Get 0.90
  b = parseFloat(b).toFixed(2); // In case we get 0.0, we pad it out to 0.00
  a = a.toLocaleString(); // Put in commas - Internet Explorer also puts in .00, so we'll get 12,345,678.00
  // If Internet Explorer (our number ends in .00)
  if (a < 1 && a.lastIndexOf('.00') == a.length - 3) {
    a = a.substr(0, a.length - 3); // Delete the .00
  }
  return a + b.substr(1); // Remove the 0 from b, then return a + b = 12,345,678.90
};
