import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Button,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorMode,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RiInformationLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import { epicFlowGradientColors, statusColors } from '../../theme';
import axios from '../../utilities/axios';
import { usdFormatter } from '../../utilities/currencyConverter';
import { calculateExchangeID } from '../../utilities/exchangeHelpers';
import { automationTypeMenuItems } from '../automationWizard/menuItems';
import EFButton from '../UI/Button/efButton';
import DropDown from '../UI/Dropdown/dropdown';
import AdvancedInput from '../UI/Input/advancedInput';

const BotSettings = props => {
  const [tabIndex, setTabIndex] = useState(0);

  const [basicSettingsConfirmed, setBasicSettingsConfirmed] = useState(false);

  const [advancedSettingsConfirmed, setAdvancedSettingsConfirmed] = useState(false);

  const [error, setError] = useState(false);

  const [status, setStatus] = useState('Basic Settings');

  const { colorMode, toggleColorMode } = useColorMode();

  const [botName, setBotName] = useState();

  const [maxAmount, setMaxAmount] = useState();

  const [minimumProfitMargin, setMinimumProfitMargin] = useState();

  const [errors, setErrors] = useState([]);

  const [stopLossBuffer, setStopLossBuffer] = useState();

  const [stopLossGranularity, setStopLossGranularity] = useState();

  const [extraBuyUpLimit, setExtraBuyUpLimit] = useState();

  const [doubledownMultiplier, setDoubledownMultiplier] = useState();

  const [orderCancelTimer, setOrderCancelTimer] = useState();

  const [slotPrice, setSlotPrice] = useState();

  const [stopLossPriceSensitivity, setStopLossPriceSensitivity] = useState();

  const [firstBoostOrderPercentage, setFirstBoostOrderPercentage] = useState();

  const [boostTimerConstant, setBoostTimerConstant] = useState();

  const [extraBuyTimer, setExtraBuyTimer] = useState();

  const [tradeFrequency, setTradeFrequency] = useState(props.TDPlaceholder);

  const [minAmount, setMinAmount] = useState(props.minAmount);

  const [botStyle, setBotStyle] = useState(props.botStylePlaceholder);

  const [basicLoading, setBasicLoading] = useState(false);

  const [advancedLoading, setAdvancedLoading] = useState(false);

  const userState = useSelector(state => state.user);

  const authState = useSelector(state => state.auth);

  const [basicSettings, setBasicSettings] = useState([
    {
      title: 'Max Investment',
      placeholder: props.maxAmountPlaceholder,
      description: props.maxAmountDesc
    },
    {
      title: 'Bot Name',
      placeholder: props.botNamePlaceholder,
      description: props.botNameDesc
    },
    {
      title: 'Bot Style',
      placeholder: props.botStylePlaceholder,
      description: props.botStyleDesc
    }
  ]);

  const [advancedSettings, setAdvancedSettings] = useState([
    {
      title: 'Min Profit Margin',
      placeholder: String((Number(props.minProfitMarginPlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%'),
      description: props.minProfitDesc,
      min: 0.5,
      max: 20
    },
    {
      title: 'Doubledown Multiplier',
      placeholder: props.doubledownMultiplierPlaceholder,
      description: props.ddmDesc,
      min: 1,
      max: 2
    },
    {
      title: 'Stop Limit Buffer',
      placeholder: String((Number(props.stopLossBufferlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%'),
      description: props.slbDesc,
      min: 0.3,
      max: 1
    },
    {
      title: 'Stop Limit Granularity',
      placeholder: String((Number(props.stopLossGranularityPlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%'),
      description: props.slgDesc,
      min: 0.03,
      max: 0.1
    },
    {
      title: 'Stop Limit Price Sensitivity',
      placeholder: String(
        (Number(props.stopLossPriceSensitivityPlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%'
      ),
      description: props.slpsDesc,
      min: 0.2,
      max: 1
    },
    {
      title: 'Extra Buy Up Limit',
      placeholder: String((Number(props.ebuPlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%'),
      description: props.ebuDesc,
      min: 1,
      max: 10
    },
    {
      title: 'Order Cancel Timer',
      placeholder: props.orderCancelTimerPlaceholder,
      description: props.octDesc,
      min: 60,
      max: 600
    },
    {
      title: 'Extra Buy Timer',
      placeholder: props.extraBuyTimerPlaceholder,
      description: props.ebtDesc,
      min: 60,
      max: 3600
    },
    {
      title: 'Boost Timer Constant',
      placeholder: props.boostTimerConstantPlaceholder,
      description: props.btcDesc,
      min: 3600,
      max: 172800
    },
    {
      title: 'Order Amount',
      placeholder: props.slotPricePlaceholder,
      description: props.spDesc
      // min: 10,
      // max:
    },
    {
      title: 'First Boost Order',
      placeholder: props.firstBoostOrderPercentagePlaceholder,
      description: props.fboDesc,
      min: 1,
      max: 30
    },
    {
      title: 'Trade Frequency',
      placeholder: props.firstBoostOrderPercentagePlaceholder,
      description: props.tfDesc
    }
    // {
    //   title: 'Trade Frequency',
    //   placeholder: props.tradeFrequencyPlaceholder
    // }
  ]);

  const handleDropDownBotStyle = selected => {
    setBotStyle(selected);
  };

  const handleTDDropDownBotStyle = selected => {
    setTradeFrequency(selected);
  };

  const mounted = useRef();
  useEffect(() => {
    // do componentDidUpdate logic
    setBasicSettings([
      {
        title: 'Max Investment',
        placeholder: props.maxAmountPlaceholder,
        description: props.maxAmountDesc
      },
      {
        title: 'Bot Name',
        placeholder: props.botNamePlaceholder,
        description: props.botNameDesc
      },
      {
        title: 'Bot Style',
        placeholder: props.botStylePlaceholder,
        description: props.botStyleDesc
      }
    ]);
    setAdvancedSettings([
      {
        title: 'Min Profit Margin',
        placeholder: String((Number(props.minProfitMarginPlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%'),
        description: props.minProfitDesc,
        min: 0.5,
        max: 20
      },
      {
        title: 'Doubledown Multiplier',
        placeholder: props.doubledownMultiplierPlaceholder,
        description: props.ddmDesc,
        min: 1,
        max: 2
      },
      {
        title: 'Stop Limit Buffer',
        placeholder: String((Number(props.stopLossBufferlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%'),
        description: props.slbDesc,
        min: 0.2,
        max: 0.3
      },
      {
        title: 'Stop Limit Granularity',
        placeholder: String(
          (Number(props.stopLossGranularityPlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%'
        ),
        description: props.slgDesc,
        min: 0.03,
        max: 0.1
      },
      {
        title: 'Stop Limit Price Sensitivity',
        placeholder: String(
          (Number(props.stopLossPriceSensitivityPlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%'
        ),
        description: props.slpsDesc,
        min: 0.2,
        max: 1
      },
      {
        title: 'Extra Buy Up Limit',
        placeholder: String((Number(props.ebuPlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%'),
        description: props.ebuDesc,
        min: 0.5,
        max: 20
      },
      {
        title: 'Order Cancel Timer',
        placeholder: props.orderCancelTimerPlaceholder,
        description: props.octDesc,
        min: 60,
        max: 600
      },
      {
        title: 'Extra Buy Timer',
        placeholder: props.extraBuyTimerPlaceholder,
        description: props.ebtDesc,
        min: 60,
        max: 3600
      },
      {
        title: 'Boost Timer Constant',
        placeholder: props.boostTimerConstantPlaceholder,
        description: props.btcDesc,
        min: 3600,
        max: 172800
      },
      {
        title: 'Order Amount',
        placeholder: props.slotPricePlaceholder,
        description: props.spDesc
      },
      {
        title: 'First Boost Order',
        placeholder: props.firstBoostOrderPercentagePlaceholder,
        description: props.fboDesc,
        min: 1,
        max: 30
      },
      {
        title: 'Trade Frequency',
        placeholder: props.firstBoostOrderPercentagePlaceholder,
        description: props.tfDesc
      }
    ]);
  }, [props]);

  useEffect(() => {
    return () => {
      setBasicSettingsConfirmed(false);
      setAdvancedSettingsConfirmed(false);
      setTabIndex(0);
      setStatus('Basic Settings');
      setBotName();
      setMaxAmount();
      setBotStyle(props.botStylePlaceholder);
      setError(false);
      setMinimumProfitMargin();
      setDoubledownMultiplier();
      setStopLossBuffer();
      setStopLossGranularity();
      setStopLossPriceSensitivity();
      setExtraBuyUpLimit();
      setOrderCancelTimer();
      setExtraBuyTimer();
      setBoostTimerConstant();
      setSlotPrice();
      setFirstBoostOrderPercentage();
      // console.log('model closed');
    };
  }, [props.isopen]);

  const handleValue = (field, placeholder) => {
    if (field === 'Bot Name') {
      if (botName === '') {
        return '';
      } else {
        return botName;
      }
    } else if (field === 'Min Profit Margin') {
      if (minimumProfitMargin === '') {
        return '';
      } else {
        return minimumProfitMargin;
      }
    } else if (field === 'Doubledown Multiplier') {
      if (doubledownMultiplier === '') {
        return '';
      } else {
        return doubledownMultiplier;
      }
    } else if (field === 'Extra Buy Timer') {
      if (extraBuyTimer === '') {
        return '';
      } else {
        return extraBuyTimer;
      }
    } else if (field === 'Stop Limit Buffer') {
      if (stopLossBuffer === '') {
        return '';
      } else {
        return stopLossBuffer;
      }
    } else if (field === 'Stop Limit Granularity') {
      if (stopLossGranularity === '') {
        return '';
      } else {
        return stopLossGranularity;
      }
    } else if (field === 'Extra Buy Up Limit') {
      if (extraBuyUpLimit === '') {
        return '';
      } else {
        return extraBuyUpLimit;
      }
    } else if (field === 'Order Cancel Timer') {
      if (orderCancelTimer === '') {
        return '';
      } else {
        return orderCancelTimer;
      }
    } else if (field === 'Order Amount') {
      if (slotPrice === '') {
        return '';
      } else {
        return slotPrice;
      }
    } else if (field === 'Stop Limit Price Sensitivity') {
      if (stopLossPriceSensitivity === '') {
        return '';
      } else {
        return stopLossPriceSensitivity;
      }
    } else if (field === 'Boost Timer Constant') {
      if (boostTimerConstant === '') {
        return '';
      } else {
        return boostTimerConstant;
      }
    } else if (field === 'First Boost Order') {
      if (firstBoostOrderPercentage === '') {
        return '';
      } else {
        return firstBoostOrderPercentage;
      }
    } else if (field === 'Max Investment') {
      return maxAmount;
    }
  };

  const handleInputNumber = value => {
    value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    return value;
  };

  const handleInput = (event, field, placeholder) => {
    let v = event.target.value;
    if (field === 'Bot Name') {
      setBotName(v);
    } else if (field === 'Min Profit Margin') {
      setMinimumProfitMargin(v);
    } else if (field === 'Doubledown Multiplier') {
      setDoubledownMultiplier(v);
    } else if (field === 'Extra Buy Timer') {
      setExtraBuyTimer(v);
    } else if (field === 'Stop Limit Buffer') {
      setStopLossBuffer(v);
    } else if (field === 'Stop Limit Granularity') {
      setStopLossGranularity(v);
    } else if (field === 'Extra Buy Up Limit') {
      setExtraBuyUpLimit(v);
    } else if (field === 'Order Cancel Timer') {
      setOrderCancelTimer(v);
    } else if (field === 'Order Amount') {
      setSlotPrice(v);
    } else if (field === 'Stop Limit Price Sensitivity') {
      setStopLossPriceSensitivity(v);
    } else if (field === 'Boost Timer Constant') {
      setBoostTimerConstant(v);
    } else if (field === 'First Boost Order') {
      setFirstBoostOrderPercentage(v);
    } else if (field === 'Max Investment') {
      if (v === '') {
        setMaxAmount('');
      } else {
        setMaxAmount(v);
      }
    }
  };

  const handleEditBasicSettingButton = () => {
    setStatus('Basic Settings');
    setBasicSettingsConfirmed(false);
    setError(false);
    setBotName();
    setMaxAmount();
    setBotStyle(props.botStylePlaceholder);
  };

  const handleEditAdvancedettingButton = () => {
    setStatus('Advanced Settings');
    setAdvancedSettingsConfirmed(false);
    setMinimumProfitMargin();
    setDoubledownMultiplier();
    setStopLossBuffer();
    setError(false);
    setStopLossGranularity();
    setStopLossPriceSensitivity();
    setExtraBuyUpLimit();
    setOrderCancelTimer();
    setExtraBuyTimer();
    setBoostTimerConstant();
    setSlotPrice();
    setFirstBoostOrderPercentage();
  };

  const handleBasicPreviewButton = () => {
    setStatus('Basic Preview');
    setBasicSettingsConfirmed(false);
  };

  const handleAdvancedPreviewButton = () => {
    setErrors([]);
    let errorsArr = [];
    if ((Number(minimumProfitMargin) > 20 || Number(minimumProfitMargin) < 0.5) && minimumProfitMargin) {
      errorsArr.push('Min Profit Margin');
    }
    if ((Number(doubledownMultiplier) > 2 || Number(doubledownMultiplier) < 1) && doubledownMultiplier) {
      errorsArr.push('Doubledown Multiplier');
    }
    if ((Number(stopLossBuffer) > 0.3 || Number(stopLossBuffer) < 0.2) && stopLossBuffer) {
      errorsArr.push('Stop Limit Buffer');
    }
    if ((Number(stopLossGranularity) > 0.1 || Number(stopLossGranularity) < 0.03) && stopLossGranularity) {
      errorsArr.push('Stop Limit Granularity');
    }
    if (
      (Number(stopLossPriceSensitivity) > 1 || Number(stopLossPriceSensitivity) < 0.2) &&
      stopLossPriceSensitivity
    ) {
      errorsArr.push('Stop Limit Price Sensitivity');
    }
    if ((Number(extraBuyUpLimit) > 20 || Number(extraBuyUpLimit) < 0.5) && extraBuyUpLimit) {
      errorsArr.push('Extra Buy Up Limit');
    }
    if ((Number(orderCancelTimer) > 600 || Number(orderCancelTimer) < 60) && orderCancelTimer) {
      errorsArr.push('Order Cancel Timer');
    }
    if ((Number(extraBuyTimer) > 86400 || Number(extraBuyTimer) < 60) && extraBuyTimer) {
      errorsArr.push('Extra Buy Timer');
    }
    if ((Number(boostTimerConstant) > 172800 || Number(boostTimerConstant) < 3600) && boostTimerConstant) {
      errorsArr.push('Boost Timer Constant');
    }
    if (
      (Number(firstBoostOrderPercentage) > 30 || Number(firstBoostOrderPercentage) < 1) &&
      firstBoostOrderPercentage
    ) {
      errorsArr.push('First Boost Order');
    }
    if (errorsArr.length > 0) {
      setAdvancedSettingsConfirmed(false);
      return setErrors(errorsArr);
    }
    setStatus('Advanced Preview');
    setErrors([]);
    setAdvancedSettingsConfirmed(false);
  };

  useEffect(() => {
    // console.log(tabIndex);
    if (tabIndex === 1) {
      setStatus('Advanced Settings');
    } else {
      setStatus('Basic Settings');
    }
  }, [tabIndex]);

  const handleConfirmBasicSetting = async () => {
    try {
      setBasicLoading(true);
      // if (maxAmount && Number(maxAmount.slice(1)) < minAmount) {
      //   console.log(Number(maxAmount.slice(1)), minAmount, maxAmount);
      //   setBasicLoading(false);
      //   return console.log('max error');
      // }
      const body = {
        bot_id: props.botID,
        // max_amount: maxAmount && maxAmount !== '' ? Number(maxAmount.slice(1)) : minAmount,
        max_amount: maxAmount ? Number(maxAmount) : Number(props.maxAmountPlaceholder),
        bot_style: botStyle.toLowerCase(),
        bot_name: botName && botName !== '' ? botName : props.botNamePlaceholder,
        stripe_customer_id: authState.stripeCustomerId
        // id_token: authState.stripeCustomerId
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}editbasicbotsettings`, body);
      if (response.data.success) {
        setBasicSettingsConfirmed(true);
      } else if (response.data.error) {
        setBasicSettingsConfirmed(true);
        setError(response.data.error);
      }
      setBasicLoading(false);
    } catch (error) {
      setBasicLoading(false);
    }
  };

  const handleValuesforServer = useCallback(
    (isChanged, type) => {
      let val;
      if (type === 'mpm') {
        if (isChanged) {
          // mpm = String(minimumProfitMargin.substring(0, minimumProfitMargin.length - 1));
          val = Number(minimumProfitMargin) / 100 + 1;
          return val;
        } else {
          val = Number(props.minProfitMarginPlaceholder.substring(0, props.minProfitMarginPlaceholder.length - 1));
        }
      } else if (type === 'slb') {
        if (isChanged) {
          // mpm = String(minimumProfitMargin.substring(0, minimumProfitMargin.length - 1));
          val = Number(stopLossBuffer) / 100 + 1;
          return val;
        } else {
          val = Number(props.stopLossBufferlaceholder.substring(0, props.stopLossBufferlaceholder.length - 1));
        }
      } else if (type === 'slg') {
        if (isChanged) {
          // mpm = String(minimumProfitMargin.substring(0, minimumProfitMargin.length - 1));
          val = Number(stopLossGranularity) / 100 + 1;
          return val;
        } else {
          val = Number(
            props.stopLossGranularityPlaceholder.substring(0, props.stopLossGranularityPlaceholder.length - 1)
          );
        }
      } else if (type === 'slps') {
        if (isChanged) {
          // mpm = String(minimumProfitMargin.substring(0, minimumProfitMargin.length - 1));
          val = Number(stopLossPriceSensitivity) / 100 + 1;
          return val;
        } else {
          val = Number(
            props.stopLossPriceSensitivityPlaceholder.substring(
              0,
              props.stopLossPriceSensitivityPlaceholder.length - 1
            )
          );
        }
      } else if (type === 'ebu') {
        if (isChanged) {
          // mpm = String(minimumProfitMargin.substring(0, minimumProfitMargin.length - 1));
          val = Number(extraBuyUpLimit) / 100 + 1;
          return val;
        } else {
          val = Number(props.ebuPlaceholder.substring(0, props.ebuPlaceholder.length - 1));
        }
      }
      return val;
    },
    [minimumProfitMargin, stopLossBuffer, stopLossGranularity, extraBuyUpLimit, stopLossPriceSensitivity]
  );

  const handleConfirmAdvancedSetting = async () => {
    try {
      setAdvancedLoading(true);
      // if (maxAmount && Number(maxAmount.slice(1)) < minAmount) {
      //   console.log(Number(maxAmount.slice(1)), minAmount, maxAmount);
      //   setBasicLoading(false);
      //   return console.log('max error');
      // }
      const body = {
        bot_id: props.botID,
        uuid: authState.id,
        exchange_id: calculateExchangeID(authState.currentOption),
        minimum_profit_margin_percent: minimumProfitMargin
          ? handleValuesforServer(true, 'mpm')
          : handleValuesforServer(false, 'mpm'),
        stop_loss_buffer: stopLossBuffer
          ? handleValuesforServer(true, 'slb')
          : handleValuesforServer(false, 'slb'),
        stop_loss_granularity: stopLossGranularity
          ? handleValuesforServer(true, 'slg')
          : handleValuesforServer(false, 'slg'),
        extrabuy_up_limit_vs_avg_cost: extraBuyUpLimit
          ? handleValuesforServer(true, 'ebu')
          : handleValuesforServer(false, 'ebu'),
        doubledown_multiplier: doubledownMultiplier
          ? Number(doubledownMultiplier)
          : Number(props.doubledownMultiplierPlaceholder),
        order_cancel_timer: orderCancelTimer
          ? Number(orderCancelTimer)
          : Number(props.orderCancelTimerPlaceholder),
        slot_price: slotPrice ? Number(slotPrice) : Number(props.slotPricePlaceholder.slice(0, -3)),
        stop_loss_price_sensitivity: stopLossPriceSensitivity
          ? handleValuesforServer(true, 'slps')
          : handleValuesforServer(false, 'slps'),
        first_boost_order_percentage: firstBoostOrderPercentage
          ? Number(firstBoostOrderPercentage)
          : Number(props.firstBoostOrderPercentagePlaceholder),
        boost_timer_constant: boostTimerConstant
          ? Number(boostTimerConstant)
          : Number(props.boostTimerConstantPlaceholder),
        extra_buy_timer: extraBuyTimer ? Number(extraBuyTimer) : Number(props.extraBuyTimerPlaceholder),
        trade_frequency: tradeFrequency.toLowerCase(),
        stripe_customer_id: authState.stripeCustomerId
      };
      // console.log(
      //   body.slot_price,
      //   slotPrice,
      //   props.slotPricePlaceholder,
      //   body.doubledown_multiplier,
      //   doubledownMultiplier,
      //   props.doubledownMultiplierPlaceholder
      // );
      const response = await axios.post(`${process.env.REACT_APP_API_URL}editadvancedbotsettings`, body);
      // const response = await axios.post(`http://localhost:5020/editadvancedbotsettings`, body);
      if (response.data) {
        setAdvancedSettingsConfirmed(true);
      } else if (response.data.error) {
        setError(response.data.error);
        setAdvancedSettingsConfirmed(true);
      }
      setAdvancedLoading(false);
    } catch (error) {
      setAdvancedLoading(false);
    }
  };

  const renderChangesScreen = () => {
    let text = `You are about to make below changes. Make sure everything is looking good to you.`;
    if (tabIndex === 0 && status === 'Basic Preview') {
      return (
        <Flex direction="column" w="full">
          {!basicSettingsConfirmed && (
            <>
              <Text mb={6} mt={4} fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                {text}
              </Text>
              <Divider mb={4} />
              <Flex justify="space-between" alignItems="center" mb={4}>
                <Text fontSize="sm">Max Investment: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {maxAmount ? maxAmount : props.maxAmountPlaceholder}
                </Text>
              </Flex>
              <Flex justify="space-between" alignItems="center" mb={4}>
                <Text fontSize="sm">Bot Name: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {botName ? botName : props.botNamePlaceholder}
                </Text>
              </Flex>
              <Flex justify="space-between" alignItems="center" mb={4}>
                <Text fontSize="sm">Bot Type: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {botStyle}
                </Text>
              </Flex>
            </>
          )}
          {/* <Flex direction="column">
            <Button
              title="Edit Basic Settings"
              w="full"
              fontSize="sm"
              mt={8}
              bg={colorMode === 'light' ? 'white' : 'transparent'}
              // borderColor={epicFlowGradientColors.left}
              borderColor={props.bg}
              borderWidth={1}
              _hover={{
                bg: colorMode === 'light' ? 'white' : 'transparent'
              }}
              _focus={{
                outline: 'none'
              }}
              _active={{
                bg: colorMode === 'light' ? 'white' : 'transparent'
              }}
              // color={epicFlowGradientColors.left}
              color={userState.colorStatus === 'up' ? statusColors.up : statusColors.down}
              onClick={() => handleEditBasicSettingButton()}
            >
              Edit Basic Settings
            </Button>
            {!basicSettingsConfirmed && (
              <EFButton
                title="Confirm"
                w="full"
                mt={4}
                mb={4}
                bg={epicFlowGradientColors.left}
                onClick={handleConfirmBasicSetting}
                isLoading={basicLoading}
              />
            )}
          </Flex> */}
          {basicSettingsConfirmed && !error ? (
            <Alert
              w="full"
              textAlign="center"
              px={0}
              status="success"
              variant="unstyled"
              fontSize="13px"
              flexDirection="column"
            >
              <Divider mb={4} w="full" />
              <AlertIcon color="green.400" mb={4} boxSize="28px" />
              Your changes have been saved. It may take some time to see your changes.
            </Alert>
          ) : basicSettingsConfirmed && error ? (
            <Flex mt={10} w="full" justify="flex-start" align="center" direction="column">
              <WarningTwoIcon color="red.400" boxSize={8} mb={4} />
              <Text fontSize="sm" mb={6}>
                {error}.
              </Text>
              <EFButton
                // bg={props.bg}
                w="full"
                color={colorMode === 'light' ? 'white' : 'black'}
                bg={colorMode === 'light' ? 'black' : 'white'}
                mt={2}
                mb={4}
                title="Done"
                onClick={handleEditBasicSettingButton}
              />
            </Flex>
          ) : null}
        </Flex>
      );
    } else if (tabIndex === 1 && status === 'Advanced Preview') {
      return (
        <Flex direction="column" w="full">
          {!advancedSettingsConfirmed && (
            <>
              <Text mb={6} mt={4} fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                {text}
              </Text>
              <Divider mb={4} />
              <Flex justify="space-between" alignItems="center" mb={4} w="full">
                <Text fontSize="sm">Minimum Profit Margin: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {minimumProfitMargin
                    ? String(minimumProfitMargin + '%')
                    : String((Number(props.minProfitMarginPlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%')}
                </Text>
              </Flex>
              <Flex justify="space-between" alignItems="center" mb={4} w="full">
                <Text fontSize="sm">Doubledown Multiplier: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {doubledownMultiplier ? doubledownMultiplier : props.doubledownMultiplierPlaceholder}
                </Text>
              </Flex>
              <Flex justify="space-between" alignItems="center" mb={4} w="full">
                <Text fontSize="sm">Stop Limit Buffer: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {stopLossBuffer
                    ? String(stopLossBuffer + '%')
                    : String((Number(props.stopLossBufferlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%')}
                </Text>
              </Flex>
              <Flex justify="space-between" alignItems="center" mb={4} w="full">
                <Text fontSize="sm">Stop Limit Granularity: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {stopLossGranularity
                    ? String(stopLossGranularity + '%')
                    : String(
                        (Number(props.stopLossGranularityPlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%'
                      )}
                </Text>
              </Flex>
              <Flex justify="space-between" alignItems="center" mb={4} w="full">
                <Text fontSize="sm">Stop Limit Price Sensivity: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {stopLossPriceSensitivity
                    ? String(stopLossPriceSensitivity + '%')
                    : String(
                        (Number(props.stopLossPriceSensitivityPlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%'
                      )}
                </Text>
              </Flex>
              <Flex justify="space-between" alignItems="center" mb={4} w="full">
                <Text fontSize="sm">Extra Buy Up Limit: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {extraBuyUpLimit
                    ? String(extraBuyUpLimit + '%')
                    : String((Number(props.ebuPlaceholder.slice(0, -1) - 1) * 100).toFixed(2) + '%')}
                </Text>
              </Flex>
              <Flex justify="space-between" alignItems="center" mb={4} w="full">
                <Text fontSize="sm">Order Cancel Timer: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {orderCancelTimer ? orderCancelTimer : props.orderCancelTimerPlaceholder}
                </Text>
              </Flex>
              <Flex justify="space-between" alignItems="center" mb={4} w="full">
                <Text fontSize="sm">Extra Buy Timer: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {extraBuyTimer ? extraBuyTimer : props.extraBuyTimerPlaceholder}
                </Text>
              </Flex>
              <Flex justify="space-between" alignItems="center" mb={4} w="full">
                <Text fontSize="sm">Boost Timer Constant: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {boostTimerConstant ? boostTimerConstant : props.boostTimerConstantPlaceholder}
                </Text>
              </Flex>
              <Flex justify="space-between" alignItems="center" mb={4} w="full">
                <Text fontSize="sm">Order Amount: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {slotPrice ? slotPrice : props.slotPricePlaceholder}
                </Text>
              </Flex>
              <Flex justify="space-between" alignItems="center" mb={4} w="full">
                <Text fontSize="sm">First Boost Order: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {firstBoostOrderPercentage
                    ? firstBoostOrderPercentage
                    : props.firstBoostOrderPercentagePlaceholder}
                </Text>
              </Flex>
              <Flex justify="space-between" alignItems="center" mb={4} w="full">
                <Text fontSize="sm">Trade Frequency: </Text>
                <Text fontWeight="semibold" fontSize="sm">
                  {tradeFrequency}
                </Text>
              </Flex>
            </>
          )}

          {advancedSettingsConfirmed && !error ? (
            <Alert
              w="full"
              textAlign="center"
              px={0}
              status="success"
              variant="unstyled"
              fontSize="13px"
              flexDirection="column"
            >
              <Divider mb={4} w="full" />
              <AlertIcon color="green.400" mb={4} boxSize="28px" />
              Your changes have been saved. It may take some time to see your changes.
            </Alert>
          ) : advancedSettingsConfirmed && error ? (
            <Flex mt={10} w="full" justify="flex-start" align="center" direction="column">
              <WarningTwoIcon color="red.400" boxSize={8} mb={4} />
              <Text fontSize="sm" mb={6}>
                {error}.
              </Text>
              <EFButton
                // bg={props.bg}
                w="full"
                color={colorMode === 'light' ? 'white' : 'black'}
                bg={colorMode === 'light' ? 'black' : 'white'}
                mt={2}
                mb={4}
                title="Done"
                onClick={handleEditAdvancedettingButton}
              />
            </Flex>
          ) : null}
        </Flex>
      );
    }
    // else if (tabIndex === 0 && status === 'Basic Confirmed') {
    //   console.log('else if tab 0', basicSettingsConfirmed);
    //   if (basicSettingsConfirmed) {
    //     return (
    //       <Alert status="success" variant="subtle" mb={10} mt={10}>
    //         <AlertIcon />
    //         Your changes have been saved. It may take some time to see your changes.
    //       </Alert>
    //     );
    //   } else {
    //     return null;
    //   }
    // }
  };

  return (
    <Modal
      isOpen={props.isopen}
      onClose={props.onclose}
      isCentered
      returnFocusOnClose={false}
      motionPreset="slideInBottom"
      scrollBehavior="inside"
      allowPinchZoom
    >
      <ModalOverlay />
      <ModalContent borderRadius="2xl" bg={colorMode === 'light' ? 'white' : '#1f2124'}>
        <ModalCloseButton
          _focus={{
            outline: 'none'
          }}
        />
        <ModalHeader fontWeight="bold" fontSize="lg" mt={10} pb={1} pt={0}>
          Settings ( {props.name} )
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={2}>
          <Tabs variant="unstyled" w="full" onChange={index => setTabIndex(index)}>
            {!advancedSettingsConfirmed && !basicSettingsConfirmed ? (
              <TabList
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                w="full"
                borderColor="red.200"
              >
                <Tab
                  _selected={{
                    borderBottom: '2px solid ' + '#dc2f02'
                  }}
                  _focus={{
                    outline: 'none'
                  }}
                  borderBottom="1px solid transparent"
                  fontSize="14px"
                  fontWeight="semibold"
                >
                  Basic
                </Tab>
                <Tab
                  _selected={{
                    borderBottom: '2px solid ' + '#dc2f02'
                  }}
                  _focus={{
                    outline: 'none'
                  }}
                  isDisabled={props.isDisabled}
                  borderBottom="1px solid transparent"
                  fontSize="14px"
                  ml={6}
                  fontWeight="semibold"
                >
                  <Flex justifyContent="center" alignItems="center">
                    <Text mr={1} color={props.isDisabled ? 'gray.400' : colorMode === 'light' ? 'black' : 'white'}>
                      Advanced
                    </Text>
                  </Flex>
                </Tab>
              </TabList>
            ) : null}
            <TabPanels w="full">
              <TabPanel w="full" p={0}>
                <Flex direction="column" justify="flex-start" align="flex-start" w="full">
                  {status === 'Basic Settings' && basicSettings.length > 0
                    ? basicSettings.map((el, idx) => {
                        return (
                          <Flex w="full" key={idx} justify="space-evenly" alignItems="center" mt={4}>
                            {idx !== basicSettings.length - 1 ? (
                              <AdvancedInput
                                description={el.description}
                                title={
                                  el.title === 'Max Investment'
                                    ? `${el.title} ${props.isBase === 1 ? `USD` : props.quote}`
                                    : el.title
                                }
                                width="12rem"
                                value={handleValue(el.title, el.placeholder)}
                                handleChange={e => handleInput(e, el.title, el.placeholder)}
                                placeholder={`${el.placeholder}`}
                                type={el.title === 'Max Investment' ? 'number' : 'text'}
                              />
                            ) : (
                              <DropDown
                                column
                                tooltip
                                description={el.description}
                                menuitems={automationTypeMenuItems}
                                selected={handleDropDownBotStyle}
                                functional
                                value={botStyle}
                                title="Bot Type"
                                // w="12rem"
                                fontSize="0.8125rem"
                                autoHeight
                                py="22px"
                                flexW="full"
                                showTitle
                                _hover={{
                                  backgroundColor: colorMode === 'light' ? 'white' : 'transparent'
                                }}
                              />
                            )}
                          </Flex>
                        );
                      })
                    : renderChangesScreen()}
                </Flex>
                {/* {botChangeStatus ? (
                    <Alert status="success" variant="subtle" mb={4}>
                      <AlertIcon />
                      Your changes have been saved. It may take some time to see your changes.
                    </Alert>
                  ) : null} */}
              </TabPanel>
              ) (
              <TabPanel w="full" p={0}>
                <Flex direction="column" justify="flex-start" align="flex-start" w="full">
                  {status === 'Advanced Settings' && advancedSettings.length > 0
                    ? advancedSettings.map((el, idx) => {
                        return (
                          <Flex w="full" key={idx} justify="space-evenly" alignItems="center" mt={4}>
                            {idx !== advancedSettings.length - 1 ? (
                              <AdvancedInput
                                description={el.description}
                                title={el.title}
                                minmax={el.min}
                                min={el.min}
                                max={el.max}
                                limitError={errors.includes(el.title)}
                                width="12rem"
                                bc={
                                  colorMode === 'light' && !errors.includes(el.title)
                                    ? 'gray.200'
                                    : errors.includes(el.title)
                                    ? 'red.400'
                                    : 'whiteAlpha.300'
                                }
                                tooltip
                                infoTitle={el.title}
                                placeholder={`Current: ${el.placeholder}`}
                                handleChange={e => handleInput(e, el.title, el.placeholder)}
                                value={handleValue(el.title, el.placeholder)}
                                type="number"
                              />
                            ) : (
                              <DropDown
                                functional
                                fontSize="0.8125rem"
                                autoHeight
                                py="22px"
                                column
                                tooltip
                                description={el.description}
                                menuitems={automationTypeMenuItems}
                                selected={handleTDDropDownBotStyle}
                                value={tradeFrequency}
                                title="Trade Frequency"
                                w="12rem"
                                flexW="full"
                                showTitle
                                _hover={{
                                  backgroundColor: colorMode === 'light' ? 'white' : 'transparent'
                                }}
                              />
                            )}
                            {/* <Tooltip
                              hasArrow
                              label={el.description}
                              whiteSpace="break-spaces"
                              bg="gray.200"
                              color="gray.700"
                              pt={1}
                              pb={1}
                              fontSize="xs"
                            >
                              <span
                                style={{
                                  display: 'flex',
                                  marginLeft: '16px',
                                  marginRight: '8px',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '16px',
                                  height: '16px'
                                }}
                              >
                                <Icon
                                  as={RiInformationLine}
                                  w="16px"
                                  h="16px"
                                  variant="unstyled"
                                  _focus={{
                                    outline: 'none'
                                  }}
                                  color={epicFlowGradientColors.left}
                                />
                              </span>
                            </Tooltip> */}
                          </Flex>
                        );
                      })
                    : renderChangesScreen()}
                  {/* {errors.length > 0 &&
                    errors.map((err, idx) => {
                      return (
                        <Text key={idx} fontSize="xs" color="red.400" mt={4}>
                          {err.error}
                        </Text>
                      );
                    })} */}
                  {/* {status === 'Advanced Settings' ? (
                    <EFButton
                      title="Preview"
                      w="full"
                      mt={errors.length > 0 ? 4 : 8}
                      mb={4}
                      bg={epicFlowGradientColors.left}
                      onClick={handleAdvancedPreviewButton}
                    />
                  ) : null} */}
                </Flex>
              </TabPanel>
              )
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          {status === 'Basic Settings' || status === 'Advanced Settings' ? (
            <EFButton
              title="Preview"
              w="full"
              mt={8}
              mb={4}
              color={colorMode === 'light' ? 'white' : 'black'}
              bg={colorMode === 'light' ? 'black' : 'white'}
              onClick={status === 'Basic Settings' ? handleBasicPreviewButton : handleAdvancedPreviewButton}
            />
          ) : (
            // <EFButton
            //   title="Preview"
            //   w="full"
            //   mt={8}
            //   mb={4}
            //   color={colorMode === 'light' ? 'white' : 'black'}
            //   bg={colorMode === 'light' ? 'black' : 'white'}
            //   onClick={handleAdvancedPreviewButton}
            // />
            <Flex alignItems="center" w="full">
              {!advancedSettingsConfirmed &&
              !basicSettingsConfirmed &&
              !error &&
              ((tabIndex === 1 && status === 'Advanced Preview') ||
                (tabIndex === 0 && status === 'Basic Preview')) ? (
                <>
                  <Button
                    w="full"
                    fontSize="13px"
                    // mt={8}
                    bg={colorMode === 'light' ? 'white' : 'transparent'}
                    // borderColor={epicFlowGradientColors.left}
                    mr={4}
                    borderColor={props.bg}
                    borderWidth={1}
                    _hover={{
                      bg: colorMode === 'light' ? 'white' : 'transparent'
                    }}
                    _focus={{
                      outline: 'none'
                    }}
                    _active={{
                      bg: colorMode === 'light' ? 'white' : 'transparent'
                    }}
                    onClick={
                      tabIndex === 0
                        ? () => handleEditBasicSettingButton()
                        : () => handleEditAdvancedettingButton()
                    }
                    // onClick={() => handleEditAdvancedettingButton()}
                  >
                    {tabIndex === 0 ? 'Edit Basic Settings' : 'Edit Advanced Settings'}
                  </Button>
                  <EFButton
                    title="Confirm"
                    w="full"
                    // mt={4}
                    // mb={4}
                    fontSize="13px"
                    color={colorMode === 'light' ? 'white' : 'black'}
                    bg={colorMode === 'light' ? 'black' : 'white'}
                    onClick={
                      tabIndex === 0 ? () => handleConfirmBasicSetting() : () => handleConfirmAdvancedSetting()
                    }
                    isLoading={tabIndex === 0 ? basicLoading : advancedLoading}
                  />
                </>
              ) : null}
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BotSettings;
