import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Spacer,
  Text,
  useColorMode,
  VStack
} from '@chakra-ui/react';
import { Auth, Cache } from 'aws-amplify';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Lottie from 'react-lottie';

import EFLogo from '../../assets/eflogo.svg';

// import { useNavigate } from 'react-router-dom';

const ResetPassword = props => {
  const [errorMessage, setErrorMessage] = useState();
  const [details, setDetails] = useState();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  //   const navigate = useNavigate();

  const { colorMode, toggleColorMode } = useColorMode();

  // const { handleSubmit, register, errors, setValue } = useForm({
  //   defaultValues: { username: '', password: '' }
  // });

  // useEffect(() => {
  //   const details = Cache.getItem('CodeDeliveryDetails');
  //   // console.log('cache details', details);
  //   if (details) {
  //     setDetails(details);
  //     setValue('username', details.username);
  //   } else {
  //     // onChangeMode('forgot-password');
  //     props.history.push('/forgotpassword');
  //   }
  // }, [setDetails, props.history, setValue]);

  useEffect(() => {
    const ad = Cache.getItem('CodeDeliveryDetails');
    // console.log('cache details', details);
    if (ad && !details) {
      setDetails(ad);
      setUsername(ad.username);
    } else if (!ad) {
      // onChangeMode('forgot-password');
      props.history.push('/forgotpassword');
    }
  }, [details]);

  const handleResetPassword = async (username, code, newPassword) => {
    try {
      // console.log('username ', username, code, newPassword);
      // if (!username) {
      //   const details = Cache.getItem('CodeDeliveryDetails');
      //   setUsername(details.username);
      // }
      let response = await Auth.forgotPasswordSubmit(username, code, newPassword);
      // console.log('forgot response ', response);
      if (response === 'SUCCESS') {
        setIsSuccess(true);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }

    // .then(response => {
    //   console.log('rr', response);
    //   Cache.removeItem('CodeDeliveryDetails');
    //   // props.history.push('/login');
    //   // setMode('signin');
    // })
    // .catch(error => setErrorMessage(error.message));
  };

  // const handleResetPassword = formData =>
  //   onResetPassword({ s...formData, username: details.username });

  const handleInput = (event, type) => {
    if (type === 'code') {
      setCode(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };

  // useEffect(() => {
  //   if(isSuccess){
  //     return props.history.push('/login');
  //   }
  // }, [isSuccess])

  if (details) {
    return (
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
        pt={[18, 18, 36, 36, 48, 48]}
        w="full"
        bg={colorMode === 'light' ? 'white' : '#000'}
        h="full"
        minH="100vh"
        // py={24}
        // borderRadius={[0, 0, 24, 24, 24, 24]}
        // mt={5}
      >
        <Flex width="100%" fontSize="3xl" justifyContent="center" alignItems="center">
          <Flex align="center" justify="center" width="fit-content">
            <Image src={EFLogo} boxSize={16} objectFit="contain" />
          </Flex>
        </Flex>

        {!isSuccess && (
          <VStack width="100%" spacing="10px">
            <Center fontWeight="bold" fontSize="xl" mt={4} mb={1}>
              Reset Password
            </Center>
            <Center fontWeight="bold" fontSize="sm" color="gray.600" mb={1}>
              We have sent a verification code to{' '}
              <Text ml={1} color={colorMode === 'light' ? 'black' : 'white'}>
                {details.username}
              </Text>
            </Center>

            <FormControl
              display="flex"
              flexDirection="column"
              align="center"
              w={['90%', '90%', '90%', 'full', 'full', 'full']}
              maxW="450px"
              mb={4}
            >
              <Flex flexDirection="column" justify="center" width="full" my={6}>
                <FormLabel htmlFor="code" fontSize="sm">
                  Verification Code
                </FormLabel>
                <Input
                  type="number"
                  id="code"
                  name="code"
                  fontSize="sm"
                  placeholder="Enter the code"
                  value={code}
                  onChange={e => handleInput(e, 'code')}
                  // className={clsx('form-control', !!errors?.code && 'border-red-600')}
                  // {...register('code', {
                  //   required: 'Required'
                  // })}
                />
                {/* {!!errors?.code && <div className="form-feedback-invalid">{errors?.code?.message}</div>} */}
              </Flex>

              {/* <div className="mb-4">
              <input
                type="text"
                id="code"
                name="code"
                className={clsx(
                  'form-control',
                  !!errors?.code && 'border-red-600'
                )}
                placeholder="Enter the code we emailed to you"
                {...register('code', { required: 'Required' })}
              />
              {!!errors?.code && (
                <div className="form-feedback-invalid">
                  {errors?.code?.message}
                </div>
              )}
            </div> */}

              <Flex flexDirection="column" justify="center" width="full" mb={6}>
                <FormLabel htmlFor="newPassword" fontSize="sm">
                  New Password
                </FormLabel>
                <Input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  fontSize="sm"
                  placeholder="Create password"
                  value={password}
                  onChange={e => handleInput(e, 'password')}
                  // className={clsx('form-control', !!errors?.newPassword && 'border-red-600')}
                  // {...register('newPassword', {
                  //   required: 'Password must be at least 8 characters',
                  //   minLength: {
                  //     value: 8,
                  //     message: 'Password must be at least 8 characters'
                  //   }
                  // })}
                />
                {/* {!!errors?.newPassword && (
                <div className="form-feedback-invalid">{errors?.newPassword?.message}</div>
              )} */}
              </Flex>

              {/* <div className="mb-4">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                className={clsx(
                  'form-control',
                  !!errors?.newPassword && 'border-red-600'
                )}
                placeholder="Create a password at least 8 characters long"
                {...register('newPassword', {
                  required: 'Password must be at least 8 characters',
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters',
                  },
                })}
                ƒ
              />
              {!!errors?.newPassword && (
                <div className="form-feedback-invalid">
                  {errors?.newPassword?.message}
                </div>
              )}
            </div> */}

              {errorMessage ? (
                <Flex width="100%" justify="flex-start" mb={4}>
                  <Text color="red.400" fontSize="sm">
                    {errorMessage}
                  </Text>
                </Flex>
              ) : (
                ''
              )}

              <Box>
                <Button
                  title="Set New Password"
                  w="full"
                  fontSize="13px"
                  // mt={8}
                  bg={colorMode === 'light' ? 'black' : 'transparent'}
                  // borderColor={epicFlowGradientColors.left}
                  mr={4}
                  borderColor={props.bg}
                  color={colorMode === 'light' ? 'white' : 'black'}
                  borderWidth={1}
                  _hover={{
                    bg: colorMode === 'light' ? 'black' : 'transparent'
                  }}
                  _focus={{
                    outline: 'none'
                  }}
                  _active={{
                    bg: colorMode === 'light' ? 'black' : 'transparent'
                  }}
                  onClick={() => handleResetPassword(username, code, password)}
                >
                  Set New Password
                </Button>
              </Box>
            </FormControl>

            <Flex align="center">
              <Flex onClick={() => props.history.push('/login')}>
                <Text mr={1} fontWeight="bold" fontSize="sm">
                  Back to
                </Text>
                <Text as="u" fontSize="sm" color="blue.600" fontWeight="bold" _hover={{ cursor: 'pointer' }}>
                  Sign In
                </Text>
              </Flex>
            </Flex>
          </VStack>
        )}

        {isSuccess && (
          <Flex width="100%" justify="center" alignItems="center" flexDir="column" mb={4}>
            <Text fontSize="sm" color="gray.600" mb={8}>
              Your password has been successfully changed.
            </Text>
            <Lottie
              options={{
                loop: false,
                path: 'https://lottie.host/3804ca79-943f-4566-b481-a2e04811bf3c/tA1X6rDgvp.json'
              }}
              height={50}
              width={50}
            />
          </Flex>
        )}
      </Flex>
    );
  }
  return null;
};

export default ResetPassword;
