import Amplify, { Auth as Av, Cache } from 'aws-amplify';
import axios from 'axios';

// const API_KEY = 'H5gryuqekI2eVd33ygjn69bL1F7Ua0Gw4ZvQhw30';
const API_KEY = process.env.REACT_APP_API_KEY;
const getSession = async () => {
  const session = await Av.currentSession();
  let jwt = session.getAccessToken().getJwtToken();
  return jwt;
};
const getIdToken = async () => {
  const session = await Av.currentSession();
  let token = session.getIdToken();
  let id_token = token.jwtToken;
  return id_token;
};
const instance = axios.create({
  headers: {
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains'
  }
});

instance.interceptors.request.use(
  async config => {
    /** In dev, intercepts request and logs it into console for dev */
    const token = await getSession();
    config.headers.token = token;
    const idToken = await getIdToken();
    config.headers.authorization = idToken;
    config.headers['x-api-key'] = API_KEY;
    // console.info('✉️ ', config);
    return config;
  },
  error => {
    // console.error('✉️ error', error);
    return Promise.reject(error);
  }
);

export default instance;
