import 'react-pulse-dot/dist/index.css';

import { Button, Flex, Image, Link, Text, useColorMode, useDisclosure } from '@chakra-ui/react';
import Amplify, { Auth, Cache } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import EPICFLOWDARK from '../../assets/ef_dark.svg';
import EPICFLOWLIGHT from '../../assets/ef_light.svg';
import User from '../user/user';
import SearchBar from './searchBar/searchBar';

const AnimationsForChaining = ['slideOutToLeft'];

const Navigation = props => {
  // const { colorMode, toggleColorMode } = useColorMode();
  // const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const { colorMode, toggleColorMode } = useColorMode();

  const displayOptions = ['none', 'none', 'none', 'flex', 'flex', 'flex'];

  const [isLoop, setIsLoop] = useState(true);

  const dispatch = useDispatch();

  const userState = useSelector(state => state.user);

  const socketState = useSelector(state => state.socket);

  const authState = useSelector(state => state.auth);
  const profileState = useSelector(state => state.profile);

  // const [animationIndex, setAnimationIndex] = useState(0);
  // const [animationType, setAnimationType] = useState(AnimationsForChaining[0]);

  // const handleChainAnimation = () => {
  //   setAnimationIndex(animationIndex);
  //   setAnimationType(animationIndex);
  // };

  const handleLogout = async () => {
    let keysToRemove = ['token', 'ef_user_id', 'browser_id', 'token_exp', 'tf'];
    // dispatch(stopSocket(authState.id, socketState?.socket, localStorage.getItem('browser_id')));
    // dispatch(stopSocket(handleDecryption(authState.id), socketState?.socket, localStorage.getItem('browser_id')));
    for (let key of keysToRemove) {
      localStorage.removeItem(key);
    }
    await Auth.signOut().then(() => window.location.replace('/login'));
    // window.location.assign('/login');
    // history.push('/login');
  };

  // const handleLoop = () => {
  //   setInterval(() => {
  //     console.log('di didi');
  //     setIsLoop(true);
  //   }, 10000);
  // };

  // useEffect(() => {
  //   handleLoop();
  // }, []);

  return (
    <Flex
      justify="center"
      align="center"
      margin="auto"
      position="fixed"
      top={0}
      left={0}
      bg={colorMode === 'light' ? 'white' : '#000'}
      py={5}
      zIndex={32}
      // mt={6}
      // maxW="7xl"
      mb={8}
      // bg={colorMode === 'light' ? 'white' : 'gray.900'}
      // pl={[null, null, '1.875%', '1.875%', '1.25%', '0.625%']}
      w="full"
      display={displayOptions}
    >
      <Flex w="95%" justify="space-between" align="center">
        <Flex alignItems="center">
          <Link
            as={RouterLink}
            to="/"
            borderRadius="md"
            mr={8}
            textDecoration="none"
            role="group"
            _hover={{
              textDecoration: 'none'
            }}
            _focus={{
              outline: 'none'
            }}
          >
            <Text fontSize="14px">Dashboard</Text>
          </Link>
          <Link
            as={RouterLink}
            to="/mybots"
            borderRadius="md"
            mr={8}
            textDecoration="none"
            role="group"
            _hover={{
              textDecoration: 'none'
            }}
            _focus={{
              outline: 'none'
            }}
          >
            <Text fontSize="14px">My Bots</Text>
          </Link>
          {(authState.currentOption === 'Oanda Live' || authState.currentOption === 'Oanda Demo') && (
            <Link
              as={RouterLink}
              to="/reporting"
              borderRadius="md"
              mr={8}
              textDecoration="none"
              role="group"
              _hover={{
                textDecoration: 'none'
              }}
              _focus={{
                outline: 'none'
              }}
            >
              <Text fontSize="14px">Reporting</Text>
            </Link>
          )}
          <Link
            as={RouterLink}
            to="/subscriptions"
            borderRadius="md"
            mr={8}
            textDecoration="none"
            role="group"
            _hover={{
              textDecoration: 'none'
            }}
            _focus={{
              outline: 'none'
            }}
          >
            <Text fontSize="14px">Subscriptions</Text>
          </Link>
          {/* <Link
            as={RouterLink}
            to="/settings"
            borderRadius="md"
            textDecoration="none"
            role="group"
            mr={8}
            _hover={{
              textDecoration: 'none'
            }}
            _focus={{
              outline: 'none'
            }}
          >
            <Text fontSize="14px">Settings</Text>
          </Link> */}
          <Link
            as={RouterLink}
            to="/support"
            borderRadius="md"
            textDecoration="none"
            role="group"
            _hover={{
              textDecoration: 'none'
            }}
            _focus={{
              outline: 'none'
            }}
          >
            <Text fontSize="14px">Support</Text>
          </Link>
        </Flex>
        <Flex
          left={[0, 0, 0, 0, '50%', '50%']}
          transform={[0, 0, 0, 0, 'translateX(-100%)', 'translateX(-100%)']}
          order={[-1, -1, -1, -1, 0, 0]}
        >
          <Link
            as={RouterLink}
            to="/"
            borderRadius="md"
            textDecoration="none"
            role="group"
            _hover={{
              textDecoration: 'none'
            }}
            _focus={{
              outline: 'none'
            }}
          >
            <Image src={colorMode === 'light' ? EPICFLOWDARK : EPICFLOWLIGHT} w={32} h={12} />
          </Link>
        </Flex>
        <Flex flexDir="row" alignItems="center">
          {authState.exchanges.length > 0 && (
            <Button
              onClick={props.onPlusClick}
              fontSize="xs"
              h="2rem"
              // w="full"
              // borderWidth={1}
              fontWeight="bold"
              _hover={{
                background: colorMode === 'light' ? '#000' : '#fff'
              }}
              _focus={{
                background: colorMode === 'light' ? '#000' : '#fff'
              }}
              _active={{
                background: colorMode === 'light' ? '#000' : '#fff'
              }}
              // w={props.w}
              color={colorMode === 'light' ? '#fff' : '#000'}
              borderColor={props.borderColor}
              bg={colorMode === 'light' ? '#000' : '#fff'}
            >
              Add Exchange
            </Button>
          )}
          <User
            onLogout={handleLogout}
            onAddExClick={props.onUserAddExClick}
            isPicture={props.isPicture}
            userName={props.userName}
            imgLink={props.imgLink}
            portfolioTotal={props.portfolioTotal}
            color={props.color}
            email={props.email}
            faded={props.faded}
            userInitial={props.userInitial}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Navigation;
