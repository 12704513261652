import { Flex, Text, useColorMode } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

import { epicFlowGradientColors } from '../../../theme';
import ConditionalLink from '../../UI/Link/conditionalLink';

const DrawerLink = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <ConditionalLink
      to={props.to}
      isLink={props.isLink}
      w="full"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      // mb={6}
      mb={3}
      // pt={1.5}
      py={1.5}
      borderRadius="md"
      textDecoration="none"
      role="group"
      _hover={{
        textDecoration: 'none',
        backgroundColor: colorMode === 'light' ? 'gray.100' : 'gray.800'
      }}
      _focus={{
        outline: 'none'
      }}
      onClick={props.onClick}
      onFlexClick={props.onFlexClick}
    >
      <Flex
        align="center"
        justify="center"
        ml={[0, 0, 0, 0, 2, 2]}
        w={9}
        h={9}
        borderRadius="full"
        backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.800'}
        mr={4}
      >
        {props.children}
      </Flex>
      <Text
        colorScheme="gray.100"
        fontSize="sm"
        fontWeight="bold"
        _groupHover={{ color: epicFlowGradientColors.left }}
      >
        {props.text}
      </Text>
    </ConditionalLink>
  );
};

DrawerLink.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  isLink: PropTypes.bool,
  onFlexClickL: PropTypes.func
};

export default DrawerLink;
