import './styles.css';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  chakra,
  Flex,
  ListItem,
  OrderedList,
  Text,
  useColorMode
} from '@chakra-ui/react';
import Decimal from 'decimal.js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dynamicCurrencyFormatter } from '../../utilities/currencyConverter';

const MarginCard = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const [value, setValue] = useState();
  const userState = useSelector(state => state.user);

  useEffect(() => {
    if (props.nav) {
      setValue((100 - new Decimal(props.marginAvailable).times(100) / new Decimal(props.nav)).toFixed(1));
    }
  }, [props.marginAvailable, props.nav]);

  const progressStyle = {
    '--progress-value': `${value}`, // Set progress value based on input value
    '--progress-indicator-size': '310px',
    '--progress-size': '150px',
    '--progress-inner-size': 'calc(var(--progress-size) - 5px)',
    '--color-progress': `${value <= 50 ? '#3fa34d' : value > 50 && value <= 90 ? '#fb6107' : '#ba181b'}`, // Set color for progress bar
    '--color-progress-alpha': `${value <= 50 ? '#3fa34d' : value > 50 && value <= 90 ? '#fb6107' : '#ba181b'}`, // Set color for progress bar alpha
    border: `${
      value <= 50 ? '5px solid #3fa34d' : value > 50 && value <= 90 ? '5px solid #fb6107' : '5px solid #ba181b'
    }` // Border color
  };
  return props.nav ? (
    <Flex w="95%">
      <Accordion w="full" allowToggle defaultIndex={0}>
        <AccordionItem borderTopWidth={0} py={4} px={0} w="full">
          <h2>
            <AccordionButton _focus={{ outline: 'none' }} px={0} _hover={{ bg: 'transparent' }}>
              <Flex as="span" justifyContent="space-between" alignItems="center" w="full">
                <Text fontSize="13px" fontWeight="black">
                  Available Margin
                </Text>
              </Flex>
              <Flex alignItems="center">
                <Text fontSize="13px" fontWeight="black" mr={2}>
                  {dynamicCurrencyFormatter(userState.baseCurrency).format(props.marginAvailable)}
                </Text>
                <AccordionIcon />
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel py={6} px={0}>
            <Flex
              flexDirection={['column', 'column', 'column', 'row']}
              alignItems={['center', 'center', 'center', 'flex-start']}
            >
              <div className="progress" style={{ ...progressStyle }} role="progressbar">
                <div className="progress-inner">
                  <div className="progress-indicator"></div>
                  <div className="progress-indicator"></div>
                </div>
                <chakra.span
                  className="progress-label"
                  color={colorMode === 'light' ? 'black' : 'white'}
                  fontSize="14px"
                >
                  <strong>{value}</strong>
                  <span>%</span>
                </chakra.span>
                <div className="watermark" style={{ display: 'none' }}></div>
              </div>
              <Flex mt={[4, 4, 4, 0]}>
                <Flex flexDirection="column" justifyContent="space-between" fontSize="13px" ml={[0, 0, 0, 16]}>
                  <Text mb={3}>
                    You&apos;re currently utilizing <chakra.span fontWeight="black">{value}%</chakra.span> of your
                    accessible margin. When your usage falls below 50%, your margin utilization is within healthy
                    limits. However, if it&apos;s between 50% and 90%, it&apos;s advisable to exercise caution and
                    closely monitor your positions. When your margin usage exceeds 90%, it&apos;s a signal that
                    immediate attention and adjustment may be necessary to ensure the health of your trading
                    account.
                  </Text>
                  {/* <Text mb={[3, 3, 3, 0]}>Suggestions for preventing margin calls:</Text> */}
                  {/* <OrderedList>
                    <ListItem>Regularly monitor your positions.</ListItem>
                    <ListItem>Avoid leveraging your entire account balance.</ListItem>
                    <ListItem>
                      Deposit additional funds into your account when your available margin becomes low.
                    </ListItem>
                  </OrderedList> */}
                  <Text color="gray.500" fontSize="12px">
                    *This communication is for informational purposes only and should not be construed as financial
                    advice. It is provided by Epicflow.io for educational and awareness purposes.
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  ) : null;
};

export default MarginCard;
