import { Box, Button, ButtonGroup, Center, Divider, Flex, HStack, Spacer, VStack } from '@chakra-ui/react';
import React from 'react';

import AppleButton from './appleButton';
import GoogleButton from './googleButton';

const SocialLogin = ({ providers = [], onGoogleSignIn, onAppleSignIn }) => {
  if (!providers || providers.length === 0) return null;

  return (
    <VStack spacing="8px" w={['90%', '90%', '90%', 'full', 'full', 'full']} maxW="450px">
      <Box width="full" mb={2}>
        {providers.includes('Google') && <GoogleButton onClick={onGoogleSignIn} />}
      </Box>
      {/* <Box width="full" mb={2}>
        {providers.includes('SignInWithApple') && <AppleButton onClick={onAppleSignIn} />}
      </Box> */}
      <HStack width="full" align="center" spacing="10px" pt={2} pb={2}>
        <Divider fontWeight="bold" />
        <Center>or</Center>
        <Divider />
      </HStack>
    </VStack>
  );
};

export default SocialLogin;
