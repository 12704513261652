import { Box, Button } from '@chakra-ui/react';
import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import { useLocation } from 'react-router-dom';

const GoogleButton = ({ onClick }) => {
  const location = useLocation();

  return (
    <Box onClick={onClick} display="flex" alignItems="center" justifyContent="center" width="100%">
      <Button
        leftIcon={<FcGoogle />}
        colorScheme="black"
        variant="outline"
        width="100%"
        height="-webkit-fit-content"
        py={3}
        borderRadius="full"
      >
        {/* {location.pathname === '/signup' ? 'Sign up' : 'Sign in'} with Google */}
        {location.pathname === '/signup' ? 'Request access' : 'Sign in'} with Google
      </Button>
    </Box>
  );
};

export default GoogleButton;
