import * as actionTypes from '../actions/actionTypes';

const initialState = {
  data: [],
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MARKET_DATA_START:
      return state;
    case actionTypes.FETCH_MARKET_DATA_FAIL:
      return state;
    case actionTypes.FETCH_MARKET_DATA_SUCCESS:
      return state;
    default:
      return state;
  }
};

export default reducer;
