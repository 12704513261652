import {
  Box,
  Flex,
  Heading,
  Icon,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
  useColorMode
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { MdSettings } from 'react-icons/md';
import { RiInformationLine, RiPercentFill } from 'react-icons/ri';

import { darkThemeBackgroundColor, epicFlowGradientColors } from '../../../theme';

const AdvancedSlider = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const [sliderValue, setSliderValue] = useState(props.default);

  const [showTooltip, setShowTooltip] = useState(false);

  // const [timer, setTimer] = useState(null)

  const handleChange = val => {
    setSliderValue(val);
    setShowTooltip(true);
    props.getSliderValue(val);
  };

  // const handleChange = useCallback(
  //   val => {
  //     setSliderValue(val);
  //     setShowTooltip(true);
  //     props.getSliderValue(val);
  //   },
  //   [props]
  // );

  const handleChangeEnd = () => {
    setShowTooltip(false);
  };

  return (
    <Flex direction="column" w="full" justifyContent="space-between" alignItems="center" mb={props.mb}>
      <Flex direction="row" w="full" justifyContent="space-between" mb={4}>
        <Flex>
          <Text fontSize="0.8125rem">{props.title}</Text>
          <Tooltip
            hasArrow
            // label={
            //   <Flex w={72} h="full" bg="red.400">
            //     <Text>{props.description}</Text>
            //   </Flex>
            // }
            offset={[0, 0]}
            label={
              <Flex backgroundColor="white" flexDir="column" py={4} px={2} justifyContent="center">
                <Flex mb={3}>
                  <Icon as={RiPercentFill} boxSize={4} color="purple.400" />
                  <Heading size="sm" ml={2}>
                    {props.infoTitle}
                  </Heading>
                </Flex>
                <Text>{props.description}</Text>
              </Flex>
            }
            whiteSpace="break-spaces"
            bg="white"
            color="gray.700"
            pt={1}
            pb={1}
            fontSize="xs"
          >
            <span
              style={{
                display: 'flex',
                paddingTop: '4px',
                marginLeft: '8px',
                marginRight: '8px',
                alignItems: 'center',
                justifyContent: 'center',
                width: '16px',
                height: '16px'
              }}
            >
              <Icon
                as={RiInformationLine}
                w="16px"
                h="16px"
                variant="unstyled"
                _focus={{
                  outline: 'none'
                }}
                color={epicFlowGradientColors.left}
              />
            </span>
          </Tooltip>
        </Flex>
        <Text fontSize="0.8125rem" color="gray.500">
          Current: {sliderValue}
        </Text>
      </Flex>
      <Slider
        defaultValue={props.default}
        min={props.min}
        step={props.step}
        max={props.max}
        onChange={v => handleChange(v)}
        onChangeEnd={handleChangeEnd}
        // onMouseEnter={() => setShowTooltip(true)}
        // onMouseLeave={() => setShowTooltip(false)}
        focusThumbOnChange={false}
      >
        <SliderMark value={props.min} mt="2" fontSize="xs" color="gray.400">
          Min.
        </SliderMark>
        {/* <SliderMark value={25} mt="2.5" fontSize="xs" ml="-2.5" color="gray.400">
          25%
        </SliderMark>
        <SliderMark value={50} mt="2.5" ml="-2.5" fontSize="xs" color="gray.400">
          50%
        </SliderMark>
        <SliderMark value={75} mt="2.5" ml="-2.5" fontSize="xs" color="gray.400">
          75%
        </SliderMark> */}
        <SliderMark value={props.max} mt="2" ml="-6" fontSize="xs" color="gray.400">
          Max.
        </SliderMark>
        <SliderTrack bg={props.sliderColor}>
          <SliderFilledTrack bg={props.bg} />
        </SliderTrack>
        <Tooltip
          hasArrow
          // bg={epicFlowGradientColors.left}
          bg={props.bg}
          borderRadius="base"
          color="white"
          fontSize="xs"
          fontWeight="medium"
          placement="top"
          isOpen={showTooltip}
          label={`${sliderValue}`}
        >
          <SliderThumb
            boxSize={4}
            borderRadius="full"
            bg={colorMode === 'light' ? 'white' : darkThemeBackgroundColor.dark}
            borderWidth={1}
            zIndex={0}
            // borderColor={epicFlowGradientColors.left}
            borderColor={props.bg}
          >
            {/* <Box color={epicFlowGradientColors.left} as={MdEqualizer} /> */}
            {/* <Box color={props.bg} as={MdSettings} /> */}
            {/* <Text fontSize="xs">{sliderValue}</Text> */}
          </SliderThumb>
        </Tooltip>
      </Slider>
    </Flex>
  );
};

AdvancedSlider.propTypes = {
  mb: PropTypes.number,
  getSliderValue: PropTypes.func
};

export default AdvancedSlider;
