import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  chakra,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useColorMode
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { epicFlowGradientColors } from '../../theme';
import { convertToInternationalCurrencySystem, getMoney } from '../../utilities/currencyConverter';
import StatsCard from '../cards/statsCard';

const PlayStats = props => {
  const [selected, setSelected] = useState(0);
  const { colorMode, toggleColorMode } = useColorMode();

  const [array, setArray] = useState(new Array(3).fill(undefined));

  const handleSelection = useCallback(option => {
    setSelected(option);
  }, []);

  return (
    <Modal
      isOpen={props.isopen}
      onClose={props.onclose}
      isCentered
      returnFocusOnClose={false}
      motionPreset="slideInBottom"
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent
        borderRadius="2xl"
        maxH="2xl"
        overflowY="scroll"
        bg={colorMode === 'light' ? 'white' : '#1f2124'}
      >
        <ModalCloseButton
          _focus={{
            outline: 'none'
          }}
        />
        <ModalHeader pl={10} fontWeight="bold" fontSize="xl" mt={10} pb={0} pt={0}>
          Run Summary ( {props.name} )
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={2} maxH="2xl">
          <Flex direction="column" pl="16px" mb={6}>
            <Flex>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                {props.pair}
              </Text>
              {/* <Flex justify="center" align="center">
                <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} ml={3} mr={2}>
                  {props.target}
                </Text>
                <Icon as={FiTarget} boxSize={4} color={colorMode === 'light' ? 'gray.500' : 'gray.300'} />
              </Flex> */}
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} ml={3}>
                {props.exchange}
              </Text>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} ml={3}>
                {props.botStyle}
              </Text>
            </Flex>
            <Flex mt={2} justify="flex-start" align="center">
              <Text fontSize="xs" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                {props.since}
              </Text>
              <Text fontSize="xs" ml={3} color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                1 Active Run and {props.playCount - 1} Completed {props.playCount > 1 ? 'Runs' : 'Run'}
              </Text>
              <Text fontSize="xs" ml={3} color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                Profit in: <chakra.span fontWeight="bolder">{props.target}</chakra.span>
              </Text>
            </Flex>
            <Flex alignItems="center" justify="flex-start" mt={2} w="90%">
              <Text fontSize="0.825rem" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                Max Allocated Investment Amount:
              </Text>
              <Text fontSize="0.825rem" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} ml={1}>
                {/* {props.isBase === 1 ? usdFormatter.format(props.maxSpent) : parseFloat(props.maxSpent.toFixed(4))} */}
                <chakra.span fontWeight="medium">{`${
                  props.quote === 'USD' ? getMoney(props.maxSpent) : parseFloat(props.maxSpent)
                } ${props.quote}`}</chakra.span>
              </Text>
            </Flex>
          </Flex>
          <Accordion allowToggle>
            {!props.loading && props.isopen ? (
              <Flex direction="column">
                {props.playStatsData?.map((el, idx) => {
                  return (
                    <StatsCard
                      key={idx}
                      playNumber={el.id}
                      bots={props.bots}
                      status={el.play_status.toUpperCase()}
                      botID={props.botID}
                      exchangeName={props.exchangeName}
                      inverse={props.inverse}
                      createdAt={el.created_at}
                      updatedAt={el.updated_at}
                      tqs={el.total_qty_purchased}
                      gainPercent={Number(el.play_gain_percent).toFixed(2)}
                      ep={Number(el.price_for_profit).toFixed(props.quoteAssetPrecision)}
                      quoteGraph={props.quoteGraph}
                      isMinus={Number(el.current_gain < 0)}
                      baseGraph={props.baseGraph}
                      ra={Number(el.remaining_amount).toFixed(2)}
                      precision={props.precision}
                      gain={
                        props.inverse
                          ? convertToInternationalCurrencySystem(Number(el.current_gain).toFixed(6))
                          : convertToInternationalCurrencySystem(Number(el.current_gain).toFixed(4))
                      }
                      orderCount={el.order_count}
                      baseCurrency={props.baseCurrency}
                      avgCost={Number(el.avg_cost).toFixed(props.quoteAssetPrecision)}
                      quoteSpend={Number(el.quote_spend).toFixed(props.quoteAssetPrecision)}
                      slp={Number(el.stop_loss_price).toFixed(props.quoteAssetPrecision)}
                      slc={el.stop_loss_count}
                    />
                  );
                })}
              </Flex>
            ) : (
              array.map((el, idx) => {
                return (
                  <AccordionItem key={idx} border="none" userSelect="none">
                    <Skeleton h={12} w="full" mt={6} />
                  </AccordionItem>
                );
              })
            )}
          </Accordion>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PlayStats;
