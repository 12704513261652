import * as actionTypes from '../actions/actionTypes';

const initialState = {
  name: null,
  email: null,
  id: false,
  role: null,
  verified: null,
  loading: false,
  error: null,
  addExchangeLoading: false,
  addExchangeStatus: null,
  getExchangeLoading: false,
  currentOption: null,
  isExchangesChecked: false,
  exchanges: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE_DATA_START:
      return {
        ...state,
        loading: action.loading
      };
    case actionTypes.FETCH_PROFILE_DATA_FAIL:
      return {
        ...state,
        loading: action.loading
      };
    case actionTypes.FETCH_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        name: action.name,
        email: action.email,
        id: action.id,
        role: action.role,
        verified: action.verified,
        loading: action.loading,
        error: action.error
      };
    case actionTypes.ADD_EXCHANGE_START:
      return {
        ...state,
        addExchangeStatus: action.addExchangeStatus,
        addExchangeLoading: action.addExchangeLoading,
        error: action.error
      };
    case actionTypes.ADD_EXCHANGE_FAIL:
      return {
        ...state,
        addExchangeLoading: action.addExchangeLoading,
        addExchangeStatus: action.addExchangeStatus,
        error: action.error
      };
    case actionTypes.ADD_EXCHANGE_SUCCESS:
      return {
        ...state,
        addExchangeStatus: action.addExchangeStatus,
        addExchangeLoading: action.addExchangeLoading
      };
    case actionTypes.GET_EXCHANGE_START:
      return {
        ...state,
        getExchangeLoading: action.getExchangeLoading
      };
    case actionTypes.GET_EXCHANGE_FAIL:
      return {
        ...state,
        getExchangeLoading: action.getExchangeLoading,
        error: action.error
      };
    case actionTypes.GET_EXCHANGE_SUCCESS:
      return {
        ...state,
        getExchangeLoading: action.getExchangeLoading,
        exchanges: action.exchanges,
        isExchangesChecked: action.isExchangesChecked,
        currentOption: action.currentOption
      };
    case actionTypes.PROFILE_SET_CURRENT_OPTION:
      return {
        ...state,
        currentOption: action.currentOption
      };

    default:
      return state;
  }
};

export default reducer;
