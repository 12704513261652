import regularAxios from 'axios';

import axios from '../../utilities/axios';
import * as actions from '../actions/actionTypes';

// const axios = require('axios');

const API_URL = process.env.REACT_APP_API_URL;

//const DEV_API_URL = 'http://localhost:5020/';

import { handleDecryption, handleEncryption } from '../../utilities/encryption';

const authSignupStart = () => {
  return {
    type: actions.AUTH_SIGNUP_START,
    loading: true
  };
};

const authSignupError = err => {
  return {
    type: actions.AUTH_SIGNUP_FAIL,
    loading: false,
    error: err
  };
};

const authSignupSuccess = status => {
  return {
    type: actions.AUTH_SIGNUP_SUCCESS,
    // isSignupFlow: true,
    // token: token,
    isSignUpSuccess: status,
    error: null,
    loading: false
  };
};

const getUserStart = () => {
  return {
    type: actions.GET_USER_START,
    loading: true
  };
};

const getUserError = err => {
  return {
    type: actions.GET_USER_FAIL,
    loading: false,
    error: err
  };
};

const getUserSuccess = (status, user = null) => {
  return {
    type: actions.GET_USER_SUCCESS,
    isUserExistInDB: status,
    isUserExistenceChecked: true,
    name: user ? user.full_name : '',
    emailPreferences: user ? user.email_subscription : '',
    tosVersion: user ? user.ToS_version : null,
    loading: false
  };
};

const authStart = () => {
  return {
    type: actions.AUTH_START,
    loading: true
  };
};

const authError = err => {
  return {
    type: actions.AUTH_FAIL,
    loading: false,
    error: err
  };
};

const authSuccess = (user, token, exchanges, plan) => {
  return {
    type: actions.AUTH_SUCCESS,
    name: user.full_name,
    id: user.id,
    refId: user.ref_id,
    usedRefId: user.used_ref_id,
    email: user.email,
    isExchangesChecked: true,
    stripeCustomerId: user.stripe_customer_id,
    stripeCurrentPlan: plan,
    ecxs: exchanges,
    error: null,
    emailPreferences: user.email_subscription,
    picture: user.photo_url,
    currentOption: localStorage.getItem('exchange_default'),
    tosVersion: user.ToS_version,
    approved: user.approved,
    loading: false
  };
};

const setCurrentOption = option => {
  return {
    type: actions.AUTH_SET_CURRENT_OPTION,
    currentOption: option
  };
};

export const getCurrentOption = option => {
  return dispatch => {
    dispatch(setCurrentOption(option));
  };
};

const createStripeCustomer = async (userEmail, userName = '', uuid) => {
  const reqBody = { email: userEmail, name: userName, uuid: uuid };
  const result = await regularAxios.post(`${API_URL}checkout/create-customer`, reqBody);
  const newUser = result.data;
  return new Promise((resolve, reject) => {
    resolve(newUser);
  });
};

export const singup = (name, email, id) => {
  return async dispatch => {
    try {
      dispatch(authSignupStart());
      const body = {
        name: name,
        email: email,
        id: id,
        used_ref_id: localStorage.getItem('ref_id') ? localStorage.getItem('ref_id') : ''
        // password: password
      };
      const response = await regularAxios.post(`${API_URL}register`, body);
      console.log(response);
      // const response = await axios.post(`${DEV_API_URL}register`, body);
      // localStorage.setItem('ef_user_id', response.data.user.id);
      // localStorage.setItem('token', response.data.token);
      if (response.status === 201) {
        await createStripeCustomer(email, name, id);
        localStorage.setItem('isReturning', true);
        dispatch(authSignupSuccess(true));
      }
    } catch (error) {
      console.log(error);
      dispatch(authSignupError(error));
    }
  };
};

export const getUser = user => {
  return async dispatch => {
    try {
      dispatch(getUserStart());
      const body = {
        email: user.email
        // id: id,
        // name: name === undefined ? '' : name,
        // picture: picture === undefined ? '' : picture,
        // isVerified: isVerified
      };
      const response = await axios.post(`${API_URL}getuser`, body);
      if (response.data.email) {
        let user = response.data;
        dispatch(getUserSuccess(true, user));
      } else {
        dispatch(getUserSuccess(false));
      }
      // else if (response.data.message === 'USER_DOES_NOT_EXIST' && isVerified) {
      //   console.log('burda', name, email, id);
      //   await dispatch(singup(body.name, body.email, body.id));
      //   const response = await axios.post(`${DEV_API_URL}getuser`, body);
      //   let user = response.data;
      //   dispatch(getUserSuccess(true, user));
      // }
      // dispatch(getUserSuccess(false));
      // else {
      //   dispatch(getUserSuccess(false));
      // }
    } catch (error) {
      console.log(error);
      // dispatch(getUserSuccess(false));
      dispatch(getUserError(error));
    }
  };
};

export const login = (id, password = '') => {
  return async dispatch => {
    try {
      // Below code works. Time to implement. Keep the secret in AWS
      dispatch(authStart());
      const body = {
        user_id: id,
        password: password
        // decryptedMessage: decryptedMessage
      };
      const response = await axios.post(`${API_URL}login`, body);
      const exchanges = [];
      if (response.data.user) {
        localStorage.setItem('ef_user_id', response.data.user.id);
        // localStorage.setItem('token', response.data.token);
        const excRes = await axios.post(`${API_URL}getexchange`, { userID: response.data.user.id });
        if (excRes.data.length > 0) {
          for (let exchange of excRes.data) {
            if (exchange === 1) {
              exchanges.push('Binance US');
            } else if (exchange === 2) {
              exchanges.push('Coinbase');
            } else if (exchange === 3) {
              exchanges.push('Binance');
            } else if (exchange === 5) {
              exchanges.push('Oanda Demo');
            } else if (exchange === 6) {
              exchanges.push('Oanda Live');
            }
          }
        }
        // createStripeCustomer(response.data.user.email, response.data.user.full_name);
        const reqBody = { customerId: response.data.user.stripe_customer_id };
        // if(response.data.user.stripe_customer_id ){

        // }
        //TODO find an elegant solution for waiting upon below call. When user created for the first time stripe response time is not enought to fetch active subs
        const delayer = delay => new Promise(resolver => setTimeout(resolver, delay));
        await delayer(1000);
        let result = await axios.post(`${API_URL}checkout/customer-subscriptions`, reqBody);
        const subscriptions = result.data.subscriptions.data;
        const currentlyActiveSubscriptionPlan = subscriptions.filter(
          sub => sub.status === 'active' || sub.status === 'trailing'
        )[0];
        // const currentlyActiveSubscriptionPlan = subscriptions[0];
        // console.log('---currentlyActiveSubscriptionPlan', currentlyActiveSubscriptionPlan);
        if (
          !localStorage.getItem('exchange_default') ||
          localStorage.getItem('exchange_default') === 'undefined'
        ) {
          localStorage.setItem('exchange_default', exchanges[0]);
        } else {
          if (!exchanges.includes(localStorage.getItem('exchange_default'))) {
            localStorage.setItem('exchange_default', exchanges[0]);
          }
        }
        // console.log(response.data.user);
        if (!localStorage.getItem('isReturning')) {
          localStorage.setItem('isReturning', 'true');
        }
        dispatch(
          authSuccess(response.data.user, '', exchanges, currentlyActiveSubscriptionPlan.plan.metadata.subName)
        );
      }
      if (response.data.message) {
        dispatch(authError(response.data.message));
      }
      // dispatch(authSuccess(response.data.user, '', exchanges));
    } catch (error) {
      console.log('error ', error);
      dispatch(authError(error));
    }
  };
};

export const verifyEmail = async id => {
  try {
    const body = {
      id: id
    };
    const response = await axios.post(`${API_URL}verify`, body);
    return response;
  } catch (error) {
    console.log(error.response.data.errors.msg);
  }
};
