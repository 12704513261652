import { SettingsIcon } from '@chakra-ui/icons';
import {
  chakra,
  Collapse,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  Switch,
  Text,
  Tooltip,
  useColorMode
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { RiInformationLine, RiPercentFill } from 'react-icons/ri';

import { epicFlowGradientColors } from '../../../theme';

const AdvancedInput = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  const handleToggler = e => {
    if (e.target.checked) {
      setIsSwitchChecked(true);
    } else {
      setIsSwitchChecked(false);
    }
  };

  return (
    <Flex
      direction={props.row ? 'row' : 'column'}
      w="full"
      justify="space-between"
      align={props.start ? 'flex-start' : 'center'}
      mt={props.mt}
      mb={props.mb}
    >
      <Flex w="full" justify="space-between" alignItems="center" mb={1}>
        <Flex alignItems="center">
          <Text fontSize="0.8125rem" w={props.textW ? props.textW : ''}>
            {props.title}
          </Text>
          {props.tooltip && (
            <Tooltip
              hasArrow
              // label={
              //   <Flex w={72} h="full" bg="red.400">
              //     <Text>{props.description}</Text>
              //   </Flex>
              // }
              offset={[0, 0]}
              label={
                <Flex backgroundColor="white" flexDir="column" py={4} px={2} justifyContent="center">
                  <Flex mb={3}>
                    <Icon as={RiPercentFill} boxSize={4} color="purple.400" />
                    <Heading size="sm" ml={2}>
                      {props.infoTitle}
                    </Heading>
                  </Flex>
                  <Text>{props.description}</Text>
                </Flex>
              }
              whiteSpace="break-spaces"
              bg="white"
              color="gray.700"
              pt={1}
              pb={1}
              fontSize="xs"
            >
              <span
                style={{
                  display: 'flex',
                  paddingTop: '4px',
                  marginLeft: '8px',
                  marginRight: '8px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '16px',
                  height: '16px'
                }}
              >
                <Icon
                  as={RiInformationLine}
                  w="16px"
                  h="16px"
                  variant="unstyled"
                  _focus={{
                    outline: 'none'
                  }}
                  color={epicFlowGradientColors.left}
                />
              </span>
            </Tooltip>
          )}
          {props.ot && (
            <Switch
              size="sm"
              ml={2}
              isChecked={isSwitchChecked}
              isFocusable={false}
              colorScheme="whatsapp"
              onChange={e => handleToggler(e)}
            />
          )}
        </Flex>
        {props.minmax && (
          <Flex>
            <Text fontSize="0.8125rem" color={props.limitError ? 'red.400' : ''}>
              Min {props.min} / Max {props.max}
            </Text>
          </Flex>
        )}
      </Flex>
      <InputGroup
        // w={props.width}
        w={props.w ? props.w : 'full'}
        // display={props.ot && !isSwitchChecked ? 'none' : 'flex'}
        flexDir={props.oanda ? 'column' : 'row'}
      >
        <Input
          isRequired
          ml={props.ml}
          mr={props.mr}
          // onInput={props.onInput}
          value={props.value}
          onChange={props.handleChange}
          placeholder={`${props.placeholder}`}
          borderRadius="0.375rem"
          borderColor={props.bc}
          isInvalid={props.isInvalid}
          errorBorderColor="red.300"
          isDisabled={props.isDisabled || (props.ot && !isSwitchChecked)}
          type={props.type}
          _placeholder={{
            color: colorMode === 'light' ? 'gray.400' : 'gray.500'
          }}
          textAlign={props.textAlign}
          fontSize={['16px', '16px', '0.8125rem', '0.8125rem', '0.8125rem', '0.8125rem']}
          _focus={{
            outline: 'none',
            borderColor: colorMode === 'light' ? epicFlowGradientColors.left : epicFlowGradientColors.left
          }}
        />
      </InputGroup>
      {props.error && (
        <chakra.span
          mt={2}
          w="full"
          className="form-feedback-invalid"
          color="red.500"
          fontSize="xs"
          fontWeight="medium"
        >
          {props.error}
        </chakra.span>
      )}
    </Flex>
  );
};

AdvancedInput.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  textAlign: PropTypes.string,
  mt: PropTypes.number,
  onInput: PropTypes.func,
  mb: PropTypes.number,
  value: PropTypes.string
};

export default AdvancedInput;
