import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { CgArrowsExchange } from 'react-icons/cg';
import { RiInformationLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import { setBotTerminationOptions } from '../../store/actions/bot';
import { epicFlowGradientColors } from '../../theme';
import axios from '../../utilities/axios';
import { usdFormatter } from '../../utilities/currencyConverter';
import { calculateExchangeID } from '../../utilities/exchangeHelpers';
import TerminationCard from '../cards/terminationCard';
import EFButton from '../UI/Button/efButton';

const OandaDirections = props => {
  const [isTerminated, setIsTerminated] = useState(false);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  const { colorMode, toggleColorMode } = useColorMode();

  const authState = useSelector(state => state.auth);

  const handleCancel = useCallback(() => {
    setIsTerminated(false);
  }, []);

  const handleChangeDirection = useCallback(async () => {
    setLoading(true);
    let body;
    if (props.terminatedOption === 4) {
      body = {
        bot_id: props.botID,
        terminate_options: 0,
        uuid: authState.id,
        exchange_id: calculateExchangeID(authState.currentOption)
      };
    } else {
      body = {
        bot_id: props.botID,
        terminate_options: 4,
        uuid: authState.id,
        exchange_id: calculateExchangeID(authState.currentOption)
      };
    }
    // console.log(body);
    const API_URL = process.env.REACT_APP_API_URL;
    const response = await axios.post(`${API_URL}editfxbotsettings`, body);
    //TODO check response data here
    // console.log('response ', response);
    if (response.data.error) {
      setLoading(false);
      //   setIsReviewed(true);
      setError(response.data.error);
      return;
    }
    setLoading(false);
    setIsTerminated(true);
    // console.log(response.data);
    // dispatch(setBotTerminationOptions(response.data));
  }, [props.terminatedOption]);

  useEffect(() => {
    setError(false);
    setIsTerminated(false);
  }, [props.isopen]);

  return (
    <Modal
      isOpen={props.isopen}
      onClose={props.onclose}
      isCentered
      returnFocusOnClose={false}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent borderRadius="2xl" bg={colorMode === 'light' ? 'white' : '#1f2124'}>
        <ModalCloseButton
          _focus={{
            outline: 'none'
          }}
        />
        <ModalHeader fontWeight="bold" fontSize="xl" mt={10} pb={0} pt={0}>
          {props.title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={2}>
          <Flex direction="column" justify="flex-start" align="flex-start">
            <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} w="full">
              *Your current direction is {props.direction.toUpperCase()}
            </Text>
            <Divider my={4} />
            {!isTerminated && !error && (
              <Flex w="full" alignItems="center" flexDir="column">
                <AlertTitle fontWeight="semibold">
                  {props.terminatedOption === 4 ? 'Cancel Switch?' : 'Switch Direction?'}
                </AlertTitle>
                {/* <Text my={2}>Are you sure you want to terminate {props.name}?</Text> */}
                <Alert
                  status="info"
                  variant="unstyled"
                  textAlign="center"
                  flexDirection="column"
                  alignItems="center"
                >
                  <AlertIcon boxSize="24px" />
                  {!isTerminated && (
                    <Text fontSize="sm" w="full" my={4}>
                      {props.terminatedOption === 4
                        ? 'Your bot is currently configured to switch direction when the current run ends. Would you like to cancel this switch action?'
                        : 'Do you wish to configure your bot to switch direction when the current run ends?'}
                    </Text>
                  )}
                </Alert>
              </Flex>
            )}
            {isTerminated && (
              <Flex direction="column" alignItems="center" mt={4} w="full">
                <Alert
                  status="success"
                  variant="subtle"
                  bg=""
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  fontSize="13px"
                >
                  <AlertIcon boxSize="28px" mb={4} />
                  {/* <AlertTitle mt={4} mb={1} fontSize="lg">
                    Succesfull!
                  </AlertTitle> */}
                  Direction of the bot will be changed when the current run ends.
                  {/* <AlertDescription maxWidth="sm">
                    {selected === 1
                      ? 'Your bot will continue to run.'
                      : selected === 2
                      ? 'Your bot is successfully terminated.'
                      : 'blal'}
                  </AlertDescription> */}
                </Alert>
              </Flex>
            )}
            {error && (
              <Flex direction="column" alignItems="center" mt={4} w="full">
                <Alert
                  status="error"
                  variant="subtle"
                  bg=""
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  fontSize="13px"
                >
                  <AlertIcon boxSize="32px" mr={0} color="red.400" />
                  <AlertTitle mt={2} mb={1} fontSize="md">
                    Oh Snap!
                  </AlertTitle>
                  <AlertDescription maxWidth="sm">{error}</AlertDescription>
                </Alert>
              </Flex>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          {isTerminated || error ? (
            <Button
              w="full"
              fontSize="13px"
              mb={8}
              mt={2}
              color={colorMode === 'light' ? 'white' : 'black'}
              bg={colorMode === 'light' ? 'black' : 'white'}
              _hover={{
                bg: colorMode === 'light' ? 'black' : 'transparent'
              }}
              _focus={{
                outline: 'none'
              }}
              _active={{
                bg: colorMode === 'light' ? 'black' : 'transparent'
              }}
              onClick={props.onclose}
              //   _active={{ background: epicFlowGradientColors.right }}
            >
              Close
            </Button>
          ) : (
            <Button
              w="full"
              fontSize="13px"
              mb={8}
              mt={2}
              color={colorMode === 'light' ? 'white' : 'black'}
              bg={colorMode === 'light' ? 'black' : 'white'}
              _hover={{
                bg: colorMode === 'light' ? 'black' : 'white'
              }}
              _focus={{
                outline: 'none'
              }}
              _active={{
                bg: colorMode === 'light' ? 'black' : 'white'
              }}
              onClick={handleChangeDirection}
              isLoading={loading}
              //   _active={{ background: epicFlowGradientColors.right }}
            >
              Confirm
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OandaDirections;
