import { Flex, Image, Link, Text, useColorMode } from '@chakra-ui/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import Reel from 'react-reel';
import { Link as RouterLink } from 'react-router-dom';

import { statusColors } from '../../theme';
import { titleCase } from '../../utilities/stringConverters';

const AssetCard = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const CustomLink = useCallback(
    styled(Link)`
      &:hover {
        border-color: transparent;
        text-decoration: none;
        background-color: ${colorMode === 'light' ? '#F7FAFC' : '#171923'};
      }
      &:hover + a > div {
        border-top-color: transparent;
      }
    `,
    [colorMode]
  );

  const displayOptions = ['none', 'none', 'flex', 'flex', 'flex', 'flex'];

  const [data, setData] = useState({
    labels: props.sparklineData,
    datasets: [
      {
        label: '',
        data: props.sparklineData,
        fill: true,
        backgroundColor: 'transparent',
        borderColor: props.weekUp ? '#48BB78' : '#F56565',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        borderWidth: 2
      }
    ]
  });

  const [options, setOptions] = useState({
    layout: {
      padding: {
        right: 6
      }
    },
    legend: {
      display: false
    },
    hover: { mode: null },
    tooltips: {
      enabled: false
    },
    elements: {
      line: {
        tension: 0
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: false //this will remove only the label
          },
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            display: false
          },
          gridLines: {
            display: false
          }
        }
      ]
    },
    maintainAspectRatio: false
  });

  const handleColor = (prevPrice, currentPrice) => {
    if (prevPrice > currentPrice) {
      return statusColors.down;
    } else if (prevPrice < currentPrice) {
      return statusColors.up;
    } else {
      return '';
    }
  };

  const reelTheme = {
    group: {
      transitionDelay: '0ms',
      transitionTimingFunction: 'ease-in-out',
      transform: 'translate(0, 0)',
      height: '1em'
    },
    reel: {
      height: '1em',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      letterSpacing: '-1px',
      overflowY: 'hidden',
      fontSize: '0.875rem',
      // fontWeight: "300",
      lineHeight: '1em'
    },
    number: {
      height: '1em'
    }
  };

  useEffect(() => {
    setData({
      labels: props.sparklineData,
      datasets: [
        {
          label: '',
          data: props.sparklineData,
          fill: true,
          backgroundColor: 'transparent',
          borderColor: props.weekUp ? '#48BB78' : '#F56565',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          borderWidth: 2
        }
      ]
    });
  }, [props.sparklineData]);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      // console.log('mounting asset card');
      mounted.current = true;
    } else {
      // console.log('updating asset card');
      // do componentDidUpdate logic
    }
  });

  return (
    <CustomLink
      w="full"
      cursor="pointer"
      role="group"
      as={RouterLink}
      to={props.to}
      _focus={{
        outline: 'none'
      }}
      pointerEvents="none"
    >
      <Flex
        w="100%"
        m="auto"
        pb={4}
        pt={4}
        // borderTopWidth={props.index !== 0 ? 1 : 0}
        // borderBottomWidth={props.index === props.length - 1 ? 1 : 0}
        _groupHover={{
          borderColor: 'transparent'
        }}
      >
        <Flex justify="flex-start" align="center" fontSize={3.5} w="full" mr={8} order={0}>
          <Image src={props.imgUrl} borderRadius="full" boxSize={8} />
          <Flex direction="column" align="flex-start" lineHeight={5} pl={3}>
            <Text
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              textAlign="left"
              fontSize="sm"
              fontWeight="medium"
            >
              {props.ticker === 'USD' ? 'US Dollar' : titleCase(props.name)}
            </Text>
            <Text fontSize="sm" color="gray.400">
              {props.ticker}
            </Text>
          </Flex>
        </Flex>
        <Text
          w="full"
          display="flex"
          alignItems="center"
          justifyContent={['flex-end', 'flex-end', 'flex-start', 'flex-start', 'flex-start', 'flex-start']}
          fontSize="sm"
          order={[2, 2, 1, 1, 1, 1]}
          fontWeight="medium"
        >
          $<Reel text={String(props.balance)} theme={reelTheme} />
        </Text>
        <Flex
          w="full"
          display={displayOptions}
          direction="column"
          order={2}
          align="flex-start"
          justify="center"
          lineHeight={5}
        >
          <Text
            w="full"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            textAlign="left"
            fontWeight="medium"
            fontSize="sm"
          >
            {props.quantity}
          </Text>
        </Flex>
        <Flex
          w="full"
          // display={displayOptions}
          order={[1, 1, 2, 2, 2, 2]}
          direction="column"
          // order={2}
          align="flex-start"
          justify="center"
          lineHeight={5}
        >
          <Flex
            w="full"
            display="flex"
            alignItems="center"
            justifyContent={['center', 'center', 'flex-start', 'flex-start', 'flex-start', 'flex-start']}
            textAlign="left"
            fontWeight="medium"
            fontSize="sm"
          >
            $<Reel text={String(props.price)} theme={reelTheme} />
          </Flex>
          {/* <Text fontSize="sm" color={props.dailyUp ? '#48BB78' : '#F56565'}>
            {props.change?.toFixed(2)}%
          </Text> */}
        </Flex>
        <Text
          w="full"
          display={displayOptions}
          alignItems="center"
          justifyContent="flex-start"
          textAlign="left"
          fontWeight="medium"
          order={3}
          fontSize="sm"
        >
          {props.allocation?.toFixed(2)}%
        </Text>
        {/* <Flex minW={36} maxW={36} alignItems="center" order={[1, 1, 4, 4, 4, 4]} justifyContent="flex-start">
          <Flex w="full" h="full" align="flex-end" pointerEvents="none">
            {props.sparklineData ? <Line data={data} width={100} height={null} options={options}></Line> : null}
          </Flex>
        </Flex> */}
      </Flex>
    </CustomLink>
  );
};

AssetCard.propTypes = {
  rank: PropTypes.number,
  name: PropTypes.string,
  ticker: PropTypes.string,
  imgUrl: PropTypes.string,
  price: PropTypes.number,
  allocation: PropTypes.number,
  change: PropTypes.number,
  sparklineData: PropTypes.array,
  balance: PropTypes.number,
  index: PropTypes.number,
  length: PropTypes.number
};

export default AssetCard;
