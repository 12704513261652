import axios from '../../utilities/axios';
import * as actions from '../actions/actionTypes';

const API_URL = process.env.REACT_APP_API_URL;

//const DEV_API_URL = 'http://localhost:5020/';

const getSocialStart = page => {
  return {
    type: actions.GET_SOCIAL_BOTS_START,
    loading: true
  };
};

const getSocialFail = error => {
  return {
    type: actions.GET_SOCIAL_BOTS_FAIL,
    loading: false,
    error: error
  };
};

const getSocialSuccess = (bots, pairs, targets, count, page) => {
  return {
    type: actions.GET_SOCIAL_BOTS_SUCCESS,
    loading: false,
    bots: bots,
    pairs: pairs,
    targets: targets,
    page: page,
    count: count
  };
};

export const getSocialBots = data => {
  return async dispatch => {
    try {
      dispatch(getSocialStart(data.page));
      if (
        (data.pair.length === 0 && data.pair !== 'ALL') ||
        (data.symbol_to_profit.length === 0 && data.symbol_to_profit !== 'ALL')
      ) {
        dispatch(getSocialSuccess([], data.pair, data.target, data.count, data.page));
        return;
      }
      const response = await axios.post(`${API_URL}getsocialbots`, { ...data });
      const responseData = response.data;
      let bots = [];
      let pairs = [];
      let targets = [];
      let count;
      if (responseData.success === 'No more data') {
        return dispatch(getSocialFail('No more data'));
      }
      for (let item in responseData) {
        if (item === 'pair') {
          pairs = [...responseData[item]];
          pairs.splice(0, 0, 'ALL');
          break;
        }
      }
      for (let item in responseData) {
        if (item === 'profit') {
          targets = [...responseData[item]];
          targets.splice(0, 0, 'ALL');
          break;
        }
      }
      for (let item in responseData) {
        if (item === 'count') {
          count = responseData[item];
          break;
        }
      }
      for (let item in responseData) {
        if (item === 'count') {
          break;
        }
        bots.push(responseData[item]);
      }
      dispatch(getSocialFail(null));
      dispatch(getSocialSuccess(bots, pairs, targets, count, data.page));
    } catch (error) {
      console.log(error);
      dispatch(getSocialFail(error));
      // dispatch(getSocialSuccess([], [], [], 0));
    }
  };
};
