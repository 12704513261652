import { Flex, Heading, Image, Link, Skeleton, Text, useColorMode } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { time_ago } from '../../utilities/timeFormatter';

const PersonalNewsCard = () => {
  const mounted = useRef();
  const [loading, setLoading] = useState(false);
  const [skeletonArray, setSkeletonArray] = useState(new Array(10).fill(undefined));

  // useEffect(() => {
  //   if (!mounted.current) {
  //     // do componentDidMount logic
  //     mounted.current = true;
  //   } else {
  //     console.log('updating aw');
  //     // do componentDidUpdate logic
  //   }
  // });
  const { colorMode, toggleColorMode } = useColorMode();

  const CustomLink = useCallback(
    styled(Link)`
      &:hover {
        border-color: transparent;
        text-decoration: none;
        background-color: ${colorMode === 'light' ? '#F7FAFC' : '#171923'};
      }
      &:hover + a > div {
        border-top-color: transparent;
      }
    `,
    [colorMode]
  );

  const [data, setData] = useState([]);

  const fetchNews = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(
        'https://min-api.cryptocompare.com/data/v2/news/?lang=EN&api_key=a0f7bf2153ef4062d1eb41d4f5decbd71b4a05d7338e533cf3a06e11c5627271'
      );
      const data = await response.json();
      setData(data.Data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  // const fetchNews = async () => {
  //   const response = await fetch(
  //     'https://min-api.cryptocompare.com/data/v2/news/?lang=EN&api_key=a0f7bf2153ef4062d1eb41d4f5decbd71b4a05d7338e533cf3a06e11c5627271'
  //   );
  //   const data = await response.json();
  //   setData(data.Data);
  // };

  useEffect(() => {
    fetchNews();
  }, []);

  const render = useMemo(() => {
    return (
      <Flex w={['full', 'full', 'full', 'full', '60%', '65%']} flexDirection="column" position="relative">
        {/* <Heading as="h2" size="md" textAlign="left" mt={12} mb={6} ml="2.5%">
          Crypto News
        </Heading> */}
        {loading && data.length <= 0 ? (
          <Flex direction="column">
            {skeletonArray.map((el, idx) => {
              return <Skeleton w="full" h="90px" mb={4} key={idx} />;
            })}
          </Flex>
        ) : (
          data.slice(0, 20).map((post, index) => (
            <CustomLink
              key={uuidv4()}
              // boxShadow="md"
              w="full"
              display="flex"
              // boxShadow='sm'
              // _hover={{
              //     textDecoration: 'none',
              //     backgroundColor: colorMode === 'light' ? 'gray.50' : 'gray.900',
              // }}
              role="group"
              _focus={{
                outline: 'none'
              }}
              isExternal
              justifyContent="space-between"
              alignItems="center"
              overflow="hidden"
              href={post.url}
            >
              <Flex
                w="95%"
                m="auto"
                _groupHover={{
                  borderColor: 'transparent'
                }}
                borderTopWidth={index !== 0 ? 1 : 0}
                borderBottomWidth={index === 4 ? 1 : 0}
                pt={8}
                pb={8}
              >
                <Flex direction="column" h="full" pr={8} justify="space-between" w="full">
                  <Flex direction="column">
                    <Heading fontSize="sm" textAlign="left" w="full">
                      {post.source.charAt(0).toUpperCase() + post.source.slice(1)}
                    </Heading>

                    <Text w="full" fontSize="sm" noOfLines={2} mt={2} mb={2}>
                      {post.body}
                    </Text>
                  </Flex>
                  <Text w="full" fontSize="0.8125rem" color="gray.400">
                    {time_ago(new Date(post.published_on * 1000))}
                  </Text>
                </Flex>
                <Image minW="120px" minH="75px" w="120px" h="75px" src={post.imageurl} borderRadius="lg" />
              </Flex>
            </CustomLink>
          ))
        )}
      </Flex>
    );
  }, [data]);

  return render;
};

PersonalNewsCard.propTypes = {};

export default PersonalNewsCard;
