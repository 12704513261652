import { ArrowDownIcon, ArrowUpIcon, EditIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  chakra,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useColorMode,
  useDisclosure,
  WrapItem
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IoIosSettings } from 'react-icons/io';
import { MdQueryStats } from 'react-icons/md';
import {
  RiBillLine,
  RiCheckboxCircleFill,
  RiDeleteBin6Line,
  RiEqualizerLine,
  RiInformationFill,
  RiInformationLine,
  RiLineChartLine,
  RiPauseCircleLine,
  RiShutDownLine,
  RiStopCircleLine,
  RiSwapLine
} from 'react-icons/ri';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import Reel from 'react-reel';

import { getCompletedBots, getPlayStat } from '../../store/actions/bot';
import { epicFlowGradientColors, statusColors } from '../../theme';
import axios from '../../utilities/axios';
import { dynamicCurrencyFormatter, getMoney, usdFormatter } from '../../utilities/currencyConverter';
import { calculateExchangeID } from '../../utilities/exchangeHelpers';
import BotSettings from '../modals/botSettings';
import OandaBotSettings from '../modals/oandaBotSettings';
import Direction from '../modals/oandaDirections';
import Pause from '../modals/oandaPause';
import OandaPlayStats from '../modals/oandaPlayStats';
import OandaTermination from '../modals/oandaTermination';

const OandaBotCard = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const { isOpen: isTerminationOpen, onOpen: onTerminationOpen, onClose: onTerminationClose } = useDisclosure();

  const { isOpen: isBotSettingsOpen, onOpen: onBotSettingsOpen, onClose: onBotSettingsClose } = useDisclosure();

  const { isOpen: isPlayStatsOpen, onOpen: onPlayStatsOpen, onClose: onPlayStatsClose } = useDisclosure();

  const { isOpen: isDirectionOpen, onOpen: onDirectionOpen, onClose: onDirectionClose } = useDisclosure();

  const { isOpen: isPauseOpen, onOpen: onPauseOpen, onClose: onPauseClose } = useDisclosure();

  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onConfirmationModalOpen,
    onClose: onConfirmationModalClose
  } = useDisclosure();

  // const botState = useSelector(state => state.bot);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();

  const [result, setResult] = useState();

  const [modalContent, setModalContent] = useState();

  const authState = useSelector(state => state.auth);

  const userState = useSelector(state => state.user);

  const dispatch = useDispatch();

  const reelTheme = {
    reel: {
      height: '1.25rem',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      letterSpacing: '0.5px',
      overflowY: 'hidden',
      fontSize: '1.25rem',
      marginTop: '8px',
      // fontWeight: "300",
      lineHeight: '1rem'
      // background: 'linear-gradient(to right, #12c2e9, #c471ed, #f64f59)',
      // WebkitBackgroundClip: 'text',
      // backgroundClip: 'text',
      // WebkitTextFillColor: 'transparent'
    },
    number: {
      height: '1em',
      fontWeight: '600'
      // WebkitBackgroundClip: 'text',
      // backgroundClip: 'text',
      // color: 'transparent',
      // background: 'red'
    }
  };

  const handlePlayStatsOpen = useCallback(
    async id => {
      try {
        onPlayStatsOpen();
        setLoading(true);
        const body = {
          bot_id: id
        };
        const response = await axios.post(`${process.env.REACT_APP_API_URL}fxplaystats`, body);
        const finalData = [];
        for (let item in response.data) {
          finalData.push({
            ...response.data[item],
            id: item
          });
        }
        setData(finalData.slice(0).reverse());
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [props.botID]
  );

  const handleResumeBot = useCallback(async id => {
    try {
      setLoading(true);
      const body = {
        bot_id: id,
        stripeCustomerId: authState.stripeCustomerId,
        max_amount: props.maxSpent,
        uuid: authState.id
      };
      // const response = await axios.post(`http://localhost:5020/resumebot`, body);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}resumebot`, body);
      if (response.data.success) {
        setResult({ success: true });
      } else {
        setResult({ error: response.data });
      }
      setLoading(false);
      // console.log(response.data);
    } catch (error) {
      setResult({ error: error });
      setLoading(false);
    }
  }, []);

  const handleArchiveBot = useCallback(async id => {
    try {
      setLoading(true);
      const body = {
        bot_id: id
      };
      // const response = await axios.post(`http://localhost:5020/archivebot`, body);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}archivebot`, body);
      // console.log(response.data);
      if (response.data.success) {
        setResult({ success: true });
      } else {
        setResult({ error: 'error' });
      }
      setLoading(false);
    } catch (error) {
      setResult({ error: error });
      setLoading(false);
    }
  }, []);

  const resumeOrArchiveBot = useCallback(type => {
    onConfirmationModalOpen();
    if (type === 'resume') {
      setModalContent({
        content: `Do you want to resume ${props.name}?`,
        action: 'resume',
        confirm: () => handleResumeBot(props.botID),
        buttonColor: 'blue.500'
      });
    } else {
      setModalContent({
        content: `Do you want to delete ${props.name} permanently?`,
        action: 'archive',
        confirm: () => handleArchiveBot(props.botID),
        buttonColor: 'red.500'
      });
    }
  }, []);

  const calculateNextOrderDistance = () => {
    if (props.botStyle === 'Long') {
      if (props.pair.includes('JPY')) {
        return {
          pips: (
            (Number(String(props.currentPrice.toFixed(5)).split('.').join('')) -
              Number(String(Number(props.nop).toFixed(5)).split('.').join(''))) /
            1000
          ).toFixed(1)
        };
      }
      return {
        pips: (
          (Number(String(props.currentPrice.toFixed(5)).split('.').join('')) -
            Number(String(Number(props.nop).toFixed(5)).split('.').join(''))) /
          10
        ).toFixed(1)
      };
    } else {
      if (props.pair.includes('JPY')) {
        return {
          pips: (
            (Number(String(Number(props.nop).toFixed(5)).split('.').join('')) -
              Number(String(props.currentPrice.toFixed(5)).split('.').join(''))) /
            1000
          ).toFixed(1)
        };
      }
      return {
        pips: (
          (Number(String(Number(props.nop).toFixed(5)).split('.').join('')) -
            Number(String(props.currentPrice.toFixed(5)).split('.').join(''))) /
          10
        ).toFixed(1)
      };
    }
  };

  const calculateCurrentPips = () => {
    if (props.avgCost === 0) {
      return {
        pips: 0,
        color: '#48BB78'
      };
    }
    if (props.botStyle === 'Long') {
      if (props.pair.includes('JPY')) {
        return {
          pips: (
            (Number(String(props.currentPrice.toFixed(5)).split('.').join('')) -
              Number(String(props.avgCost.toFixed(5)).split('.').join(''))) /
            1000
          ).toFixed(1),
          color:
            (Number(String(props.currentPrice.toFixed(5)).split('.').join('')) -
              Number(String(props.avgCost.toFixed(5)).split('.').join(''))) /
              1000 >=
            0
              ? '#48BB78'
              : '#ef233c'
        };
      }
      return {
        pips: (
          (Number(String(props.currentPrice.toFixed(5)).split('.').join('')) -
            Number(String(props.avgCost.toFixed(5)).split('.').join(''))) /
          10
        ).toFixed(1),
        color:
          (Number(String(props.currentPrice.toFixed(5)).split('.').join('')) -
            Number(String(props.avgCost.toFixed(5)).split('.').join(''))) /
            10 >=
          0
            ? '#48BB78'
            : '#ef233c'
      };
    } else {
      if (props.pair.includes('JPY')) {
        return {
          pips: (
            (Number(String(props.avgCost.toFixed(5)).split('.').join('')) -
              Number(String(props.currentPrice.toFixed(5)).split('.').join(''))) /
            1000
          ).toFixed(1),
          color:
            (Number(String(props.avgCost.toFixed(5)).split('.').join('')) -
              Number(String(props.currentPrice.toFixed(5)).split('.').join(''))) /
              1000 >=
            0
              ? '#48BB78'
              : '#ef233c'
        };
      }
      return {
        pips: (
          (Number(String(props.avgCost.toFixed(5)).split('.').join('')) -
            Number(String(props.currentPrice.toFixed(5)).split('.').join(''))) /
          10
        ).toFixed(1),
        color:
          (Number(String(props.avgCost.toFixed(5)).split('.').join('')) -
            Number(String(props.currentPrice.toFixed(5)).split('.').join(''))) /
            10 >=
          0
            ? '#48BB78'
            : '#ef233c'
      };
    }
  };

  const handleConfirmationModalClose = () => {
    if (result) {
      dispatch(getCompletedBots(authState.id, calculateExchangeID(authState.currentOption)));
    }
    setModalContent();
    setResult();
    onConfirmationModalClose();
  };

  const handleEditNameButton = () => {
    onBotSettingsOpen();
  };

  const calculateMinExpectedProfit = () => {
    let currentPip;
    if (
      Number(calculateCurrentPips().pips) === 0 ||
      calculateCurrentPips.pips === '0.0' ||
      calculateCurrentPips === '0'
    ) {
      currentPip == 0.005;
    } else {
      currentPip = calculateCurrentPips().pips;
      let settings = props.pair.includes('JPY')
        ? Number(props.profitPriceConstant) * 100
        : Number(props.profitPriceConstant) * 10000;
      const gain = (Math.abs(Number(props.currentPlayGain)) / Math.abs(Number(currentPip))) * settings;
      return dynamicCurrencyFormatter(userState.baseCurrency).format(gain.toFixed(2));
    }
  };

  // const [playAnimation, setPlayAnimation] = useState(true);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log('intervaling', playAnimation);
  //     setPlayAnimation(false);
  //   }, 5000); // Set the delay between animations in milliseconds (e.g., 5000 milliseconds = 5 seconds)

  //   return () => clearInterval(interval); // Clean up the interval on component unmount
  // }, []);

  // useEffect(() => {
  //   console.log(playAnimation);
  // }, [playAnimation]);

  // const handleCompleteEvent = () => {
  //   console.log(playAnimation);
  //   setPlayAnimation(true);
  // };

  // const renderLottie = useMemo(() => {
  //   return (
  //     <Flex
  //       pointerEvents="none"
  //       display={props.slp ? 'flex' : 'none'}
  //       position="absolute"
  //       bottom={55}
  //       right={-12}
  //       zIndex={43}
  //     >
  //       <Lottie
  //         speed={0.45}
  //         options={{
  //           path: 'https://lottie.host/f6ec4d08-95fc-4166-b129-646106b970c9/hSnUcw3XFY.json'
  //         }}
  //         height={200}
  //         width={200}
  //       />
  //     </Flex>
  //   );
  // }, []);

  return (
    <WrapItem w={['full', 'full', '385px', '385px', '385px', '385px']} position="relative">
      <Flex
        w="full"
        // h={props.active ? '400px' : '240px'}
        rounded="xl"
        justify="flex-start"
        align="center"
        direction="column"
        borderWidth={colorMode === 'dark' ? 1 : 0}
        // borderColor={colorMode === 'light' ? 'gray.200' : 'whiteAlpha.300'}
        borderColor={colorMode === 'light' ? 'gray.200' : 'whiteAlpha.300'}
        // boxShadow="rgba(0, 0, 0, 0.10) 0px 3px 15px"
        // boxShadow={
        //   props.terminatedOption !== 1 && props.active
        //     ? 'rgba(229, 62, 62, 0.4) 0px 3px 15px'
        //     : 'rgba(0, 0, 0, 0.10) 0px 3px 15px'
        // }
        // boxShadow="rgba(0, 0, 0, 0.10) 0px 3px 15px"
        pb={4}
        css={`
          ${props.slp
            ? ` animation: pulsegreen 2s infinite;
        @keyframes pulsegreen {
          0% {
            -webkit-box-shadow: 0 0 0 0 rgba(19, 221, 56, 0.4);
            border: 1px solid rgba(19, 221, 56, 0.2);
          }
          70% {
            -webkit-box-shadow: 0 0 0 12px rgba(19, 221, 56, 0);
            border: 1px solid rgba(19, 221, 56, 0.2);
          }
          100% {
            -webkit-box-shadow: 0 0 0 0 rgba(19, 221, 56, 0);
            border: 1px solid rgba(19, 221, 56, 0.2);
          }
        }`
            : props.marginUtilized >= props.minAmount * 0.9 && !props.slp
            ? `
            animation: pulsered 2s infinite;
        @keyframes pulsered {
          0% {
            -webkit-box-shadow: 0 0 0 0 rgba(239, 35, 60, 0.4);
            border: 1px solid rgba(239, 35, 60, 0.2);
          }
          70% {
            -webkit-box-shadow: 0 0 0 12px rgba(239, 35, 60, 0);
            border: 1px solid rgba(239, 35, 60, 0.2);
          }
          100% {
            -webkit-box-shadow: 0 0 0 0 rgba(239, 35, 60, 0);
            border: 1px solid rgba(239, 35, 60, 0.2);
          }
        }
            `
            : `
            -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.10);
            `}
        `}
        // css={`
        //   animation: pulse 2s infinite;
        //   @keyframes pulse {
        //     0% {
        //       -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
        //     }
        //     70% {
        //       -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
        //     }
        //     100% {
        //       -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
        //     }
        //   }
        // `}
      >
        {/* {renderLottie} */}
        <Flex w="90%" justify="space-between" py="1rem" alignItems="center">
          <Flex direction="column" w="full">
            <Flex justifyContent="space-between" alignItems="center" w="full">
              <Flex alignItems="center">
                <Text
                  fontSize="xl"
                  fontWeight="semibold"
                  whiteSpace="nowrap"
                  letterSpacing="0.05rem"
                  maxW="20ch"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {props.name}
                </Text>
                {/* {props.active ? (
                  <IconButton
                    bg={colorMode === 'light' ? 'white' : '#0f1114'}
                    aria-label="Edit Bot Name"
                    onClick={handleEditNameButton}
                    w="16px"
                    h="16px"
                    icon={<EditIcon w="16px" h="16px" />}
                    _active={{
                      bg: colorMode === 'light' ? 'white' : '#0f1114'
                    }}
                    _hover={{
                      bg: colorMode === 'light' ? 'white' : '#0f1114'
                    }}
                  />
                ) : null} */}
              </Flex>
              {props.active && (
                <Flex>
                  <Tooltip
                    // hasArrow
                    label="Pause or Continue Bot"
                    whiteSpace="break-spaces"
                    bg={colorMode === 'light' ? 'white' : '#1f2124'}
                    // py={3}
                    // px={3}
                    pt={0}
                    mt={0}
                    borderRadius="lg"
                    p={3}
                    borderWidth={1}
                    color={colorMode === 'light' ? 'black' : 'white'}
                    fontSize="12px"
                  >
                    <Button
                      variant="unstyled"
                      // onClick={onTerminationOpen}
                      _focus={{ outline: 'none' }}
                      display="flex"
                      minWidth={5}
                      maxH={5}
                      mr={2}
                      // h={7}
                    >
                      <Icon
                        as={RiPauseCircleLine}
                        boxSize={5}
                        onClick={onPauseOpen}
                        color={
                          props.pause_options !== 0
                            ? epicFlowGradientColors.left
                            : colorMode === 'light'
                            ? 'black'
                            : 'white'
                        }
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    // hasArrow
                    label="Switch Bot Direction"
                    whiteSpace="break-spaces"
                    bg={colorMode === 'light' ? 'white' : '#1f2124'}
                    // py={3}
                    // px={3}
                    pt={0}
                    mt={0}
                    borderRadius="lg"
                    p={3}
                    borderWidth={1}
                    color={colorMode === 'light' ? 'black' : 'white'}
                    fontSize="12px"
                  >
                    <Button
                      variant="unstyled"
                      // onClick={onTerminationOpen}
                      _focus={{ outline: 'none' }}
                      display="flex"
                      minWidth={5}
                      maxH={5}
                      mr={2}
                      // h={7}
                    >
                      <Icon
                        as={RiSwapLine}
                        boxSize={5}
                        onClick={onDirectionOpen}
                        color={
                          props.terminatedOption === 4 ? 'green.400' : colorMode === 'light' ? 'black' : 'white'
                        }
                      />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    // hasArrow
                    label="Terminate Bot Options"
                    whiteSpace="break-spaces"
                    bg={colorMode === 'light' ? 'white' : '#1f2124'}
                    // py={3}
                    // px={3}
                    pt={0}
                    mt={0}
                    borderRadius="lg"
                    p={3}
                    borderWidth={1}
                    color={colorMode === 'light' ? 'black' : 'white'}
                    fontSize="12px"
                  >
                    <Button
                      variant="unstyled"
                      onClick={onTerminationOpen}
                      _focus={{ outline: 'none' }}
                      display="flex"
                      minWidth={5}
                      maxH={5}
                    >
                      <Icon
                        as={RiShutDownLine}
                        boxSize={5}
                        color={
                          props.terminatedOption === 3 || props.maxLossAmount !== 0
                            ? 'red.400'
                            : colorMode === 'light'
                            ? 'black'
                            : 'white'
                        }
                      />
                    </Button>
                  </Tooltip>
                </Flex>
              )}
            </Flex>
            <Flex mt={1}>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                {props.pair}
              </Text>
              {/* <Flex justify="center" align="center">
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} ml={3} mr={2}>
                {props.target}
              </Text>
              <Icon as={FiTarget} boxSize={4} color={colorMode === 'light' ? 'gray.500' : 'gray.300'} />
            </Flex> */}
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} ml={3}>
                {authState.currentOption}
              </Text>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} ml={3}>
                {props.botStyle}
              </Text>
              {props.leverage && (
                <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} ml={3}>
                  Leverage {props.leverage}:1
                </Text>
              )}
            </Flex>
            {/* <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
            Profit in {props.target}
          </Text> */}
            <Flex mt={2} justify="flex-start" align="center" w="full">
              <Text
                fontSize="xs"
                mr={props.active ? 3 : 0}
                color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
              >
                {props.since}
              </Text>
              <Text fontSize="xs" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                {props.playCount} {props.playCount > 1 ? 'Runs' : 'Run'} in Total
              </Text>
              {/* <Text fontSize="xs" ml={3} color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                Profit in: {props.target}
              </Text> */}
              {/* {props.active ? (
                <Tooltip
                  hasArrow
                  label={`${props.botState}`}
                  whiteSpace="break-spaces"
                  bg="gray.200"
                  color="gray.700"
                  pt={1}
                  pb={1}
                  fontSize="xs"
                >
                  <span
                    style={{
                      marginLeft: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '16px',
                      height: '16px'
                    }}
                  >
                    <Icon
                      as={RiInformationLine}
                      w={props.botState.includes('WARNING:') ? '20px' : '16px'}
                      h={props.botState.includes('WARNING:') ? '20px' : '16px'}
                      variant="unstyled"
                      _focus={{
                        outline: 'none'
                      }}
                      color={
                        props.botState.includes('WARNING:')
                          ? statusColors.down
                          : props.botState.includes('Completed')
                          ? statusColors.up
                          : epicFlowGradientColors.left
                      }
                    />
                  </span>
                </Tooltip>
              ) : (
                <Icon as={RiCheckboxCircleFill} ml={2} w="18px" h="18px" color={statusColors.up} />
              )} */}
            </Flex>
          </Flex>
          {props.active && (
            <OandaBotSettings
              maxMarginUtilizationPlaceHolder={props.maxMarginUtilization}
              initialUnitsPlaceholder={props.initialUnits}
              unitAdditionPlaceholder={props.unitAddition}
              unitMultiplierPlaceholder={props.unitMultiplier}
              additionalOrderStepPlaceholder={props.additionalOrderStep}
              profitPriceConstantPlaceholder={props.profitPriceConstant}
              orderTimerPlaceholder={props.orderTimer}
              onclose={onBotSettingsClose}
              isopen={isBotSettingsOpen}
              name={props.name}
              // maxAmountPlaceholder={props.maxSpent}
              botID={props.botID}
              pair={props.pair}
              stopLimitGranularityPlaceholder={`${props.stopLimitGranularity}`}
              stopLimitBufferPlaceholder={`${props.stopLimitBuffer}`}
              botNamePlaceholder={props.name}
            />
          )}
          {props.active && (
            <Direction
              onclose={onDirectionClose}
              isopen={isDirectionOpen}
              title="Switch Direction"
              botID={props.botID}
              terminatedOption={props.terminatedOption}
              direction={props.botStyle}
            />
          )}
          {props.active && (
            <Pause
              onclose={onPauseClose}
              name={props.name}
              isopen={isPauseOpen}
              pause_options={props.pause_options}
              title="Pause or Continue Bot"
              botID={props.botID}
              direction={props.botStyle}
            />
          )}
          <OandaPlayStats
            name={props.name}
            playStatsData={data}
            precision={props.precision}
            exchange={props.exchange}
            botStyle={props.botStyle}
            since={props.since}
            quoteGraph={props.quoteGraph}
            baseGraph={props.baseGraph}
            playCount={props.playCount + 1}
            botID={props.botID}
            bots
            maxSpent={props.maxSpent}
            quote={props.quote}
            target={props.target}
            exchangeName={props.exchangeName}
            quoteAssetPrecision={props.quoteAssetPrecision}
            pair={props.pair}
            isopen={isPlayStatsOpen}
            inverse={props.inverse}
            onclose={onPlayStatsClose}
            // data={botState.playStats}
            loading={loading}
            baseCurrency={props.baseCurrency}
          />
          <OandaTermination
            onclose={onTerminationClose}
            isopen={isTerminationOpen}
            title={`Stop Bot ( ${props.name} )`}
            name={props.name}
            maxLossAmount={props.maxLossAmount}
            mlaPlaceholder={props.mlaPlaceholder}
            units={props.units}
            currentPlayGain={props.currentPlayGain}
            terminatedOption={props.terminatedOption}
            botID={props.botID}
            inverse={props.inverse}
            total_qty_purchased={props.qtyPurchased}
            quoteSpend={props.quoteSpend}
            marginUtilized={props.marginUtilized}
            symbol_to_profit={props.target}
            symbol_pair={props.pair}
          />
        </Flex>
        <Accordion w="full" textAlign="center" index={props.index}>
          <AccordionItem
            css={{
              borderBottom: 'none !important'
            }}
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  _focus={{
                    outline: 'none'
                  }}
                  p={0}
                  display="flex"
                  _hover={{
                    cursor: 'default'
                  }}
                  pointerEvents="none"
                  justifyContent="center"
                  w="full"
                >
                  <Flex w="90%" justify="space-between" py="1rem" align="center">
                    <Flex justify="space-between" alignItems="center" w="full" direction="column">
                      <Text fontSize="sm" fontWeight="semibold" w="90%" textAlign="center">
                        Profits
                      </Text>
                      <Flex w="full" justify={props.active ? 'space-between' : 'center'} align="flex-start" mt={2}>
                        {props.active && (
                          <Flex direction="column" align="flex-start">
                            <Flex align="center">
                              <Text fontSize="0.825rem">
                                Current Run{' '}
                                <chakra.span fontSize="xs" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                                  ( {props.currentSince} )
                                </chakra.span>
                              </Text>
                            </Flex>
                            <Flex alignItems="center">
                              <Flex
                                alignItems="flex-start"
                                direction="column"
                                color={props.currentPlayGain < 0 ? '#ef233c' : '#48BB78'}
                              >
                                <Reel
                                  text={String(
                                    dynamicCurrencyFormatter(userState.baseCurrency).format(props.currentPlayGain)
                                  )}
                                  theme={reelTheme}
                                ></Reel>

                                {/* <Text
                                  fontWeight="semibold"
                                  fontSize="xl"
                                  color={props.currentPlayGain < 0 ? '#ef233c' : '#48BB78'}
                                >
                                  {usdFormatter.format(props.currentPlayGain)}
                                </Text> */}
                              </Flex>
                            </Flex>
                          </Flex>
                        )}
                        {props.active && (
                          <Flex align="center">
                            <Flex direction="column" alignItems="flex-end" pr={1}>
                              {props.active && (
                                <Flex align="center">
                                  <Text fontSize="0.825rem">Current Run Pips</Text>
                                </Flex>
                              )}
                              <Flex
                                align={props.active ? 'center' : 'center'}
                                direction="row"
                                justifyContent="flex-end"
                                color={calculateCurrentPips().color}
                              >
                                <Reel text={String(calculateCurrentPips().pips)} theme={reelTheme}></Reel>
                                {/* <Text fontWeight="semibold" fontSize="xl" >
                                  {calculateCurrentPips().pips}
                                </Text> */}
                                {calculateCurrentPips().color === '#ef233c' ? (
                                  <ArrowDownIcon color="#ef233c" boxSize={4} ml={1} mt={1} />
                                ) : (
                                  <ArrowUpIcon color="#48BB78" boxSize={4} ml={1} mt={1} />
                                )}
                              </Flex>
                            </Flex>
                            {/* <AccordionIcon /> */}
                          </Flex>
                        )}
                      </Flex>
                      <Flex mt={props.active ? 5 : 2} w="full" justifyContent="space-between" align="flex-start">
                        <Flex direction="column" flex={1} alignItems="flex-start">
                          <Text fontSize="12px">Completed Runs</Text>
                          <Text
                            fontSize="13px"
                            fontWeight="black"
                            color={Number(props.overallGain) < 0 ? '#ef233c' : '#48BB78'}
                          >
                            {dynamicCurrencyFormatter(userState.baseCurrency).format(Number(props.overallGain))}
                          </Text>
                        </Flex>
                        <Flex direction="column" justify="center" alignItems="center" flex={1}>
                          <Text fontSize="12px">Financing</Text>
                          <Text
                            fontSize="13px"
                            fontWeight="black"
                            color={Number(props.totalFinance) < 0 ? '#ef233c' : '#48BB78'}
                          >
                            {dynamicCurrencyFormatter(userState.baseCurrency).format(Number(props.totalFinance))}
                          </Text>
                        </Flex>
                        <Flex direction="column" flex={1} alignItems="flex-end">
                          <Text fontSize="12px" fontWeight="bold">
                            Net Profit
                          </Text>
                          <Text
                            fontSize="13px"
                            fontWeight="black"
                            color={
                              Number(props.overallGain) + Number(props.totalFinance) < 0 ? '#ef233c' : '#48BB78'
                            }
                          >
                            {dynamicCurrencyFormatter(userState.baseCurrency).format(
                              Number(props.overallGain) + Number(props.totalFinance)
                            )}
                          </Text>
                        </Flex>
                      </Flex>
                      {props.active && (
                        <Flex alignItems="center" justify="center" mt={6} w="full">
                          <Text
                            fontSize="0.825rem"
                            color={props.marginUtilized >= props.minAmount * 0.9 ? '#ef233c' : ''}
                          >
                            Max Margin Utilization:
                          </Text>
                          <Text
                            fontSize="0.825rem"
                            color={props.marginUtilized >= props.minAmount * 0.9 ? '#ef233c' : ''}
                            ml={1}
                          >
                            {dynamicCurrencyFormatter(userState.baseCurrency).format(props.maxSpent)}
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </AccordionButton>
                <AccordionPanel p={0} w="full" display="flex" justifyContent="center">
                  <Flex direction="column" w="90%">
                    {props.active && (
                      <Flex w="full" justifyContent="center" alignItems="center" mb={2}>
                        <Text fontSize="xs" fontWeight="bold">
                          Pending Order
                        </Text>
                      </Flex>
                    )}
                    {props.active && (
                      <Flex w="full" justify="">
                        <Flex w="full" justify="space-between" align="flex-start" my={2}>
                          <Flex direction="column" align="flex-start" flex={1}>
                            <Text fontSize="xs">Units</Text>
                            <Text fontSize="xs" fontWeight="semibold">
                              {Math.abs(props.nou) !== 0 ? Math.abs(props.nou) : 'N/A'}
                            </Text>
                          </Flex>
                          <Flex direction="column" align="center" flex={1}>
                            <Text fontSize="xs">Price</Text>
                            <Text fontSize="xs" fontWeight="semibold">
                              {props.nop !== 0 ? props.nop : 'N/A'}
                            </Text>
                          </Flex>
                          <Flex direction="column" align="flex-end" flex={1}>
                            <Text fontSize="xs">Distance</Text>
                            <Text fontSize="xs" fontWeight="semibold" color={props.slc > 0 && '#48BB78'}>
                              {props.poc === 0 ? 'N/A' : Math.abs(calculateNextOrderDistance().pips) + ' Pips'}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    )}
                    {props.active && <Divider my={1} />}
                    <Flex w="full" justify="">
                      <Flex w="full" justify="space-between" align="flex-start" mt={3}>
                        <Flex direction="column" align="flex-start" flex={1}>
                          <Text fontSize="xs">Units Purchased</Text>
                          <Text fontSize="xs" fontWeight="semibold">
                            {props.units}
                          </Text>
                        </Flex>
                        <Flex direction="column" align="center" flex={1}>
                          <Text fontSize="xs" textAlign="left">
                            Margin Used
                          </Text>
                          <Text fontSize="xs" fontWeight="semibold">
                            {props.quoteSpend}
                          </Text>
                        </Flex>
                        {/* <Flex direction="column" align="flex-end" flex={1}>
                          <Text fontSize="xs" color={props.slc > 0 && '#48BB78'}>
                            Stop Limit Count
                          </Text>
                          <Text fontSize="xs" fontWeight="semibold" color={props.slc > 0 && '#48BB78'}>
                            {props.slc > 0 ? props.slc : props.slc}
                          </Text>
                        </Flex> */}
                        {props.active && (
                          <Flex direction="column" align="flex-end" flex={1}>
                            <Flex alignItems="center">
                              <Text fontSize="xs" color={props.slc > 0 && '#48BB78'}>
                                MEP
                              </Text>
                              <Tooltip
                                // hasArrow
                                label="Minimum Expected Profit"
                                whiteSpace="break-spaces"
                                bg={colorMode === 'light' ? 'white' : '#1f2124'}
                                // py={3}
                                // px={3}
                                pt={0}
                                mt={0}
                                borderRadius="lg"
                                p={3}
                                borderWidth={1}
                                color={colorMode === 'light' ? 'black' : 'white'}
                                fontSize="12px"
                              >
                                <Button
                                  variant="unstyled"
                                  _hover={{
                                    userSelect: 'none',
                                    cursor: 'pointer'
                                  }}
                                  _focus={{ outline: 'none' }}
                                  display="flex"
                                  minWidth={3}
                                  ml={1}
                                  maxH={3}
                                >
                                  <Icon
                                    as={RiInformationLine}
                                    boxSize={4}
                                    mb="2px"
                                    color={props.slc > 0 && '#48BB78'}
                                  />
                                </Button>
                              </Tooltip>
                            </Flex>
                            <Text fontSize="xs" fontWeight="semibold" color={props.slc > 0 && '#48BB78'}>
                              {calculateMinExpectedProfit()}
                              {props.slc > 0 ? '++' : ''}
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                    <Flex w="full" justify="">
                      <Flex w="full" justify="space-between" align="flex-start" mt={6}>
                        <Flex direction="column" align="flex-start" flex={1}>
                          <Text fontSize="xs">Current Price</Text>
                          <Text fontSize="xs" fontWeight="semibold">
                            {props.currentPrice}
                          </Text>
                        </Flex>
                        <Flex direction="column" align="center" flex={1}>
                          <Text fontSize="xs">Price for Exit</Text>
                          <Text fontSize="xs" fontWeight="semibold">
                            {props.prp}
                          </Text>
                        </Flex>
                        <Flex direction="column" align="flex-end" flex={1}>
                          <Text fontSize="xs">Average Price</Text>
                          <Text fontSize="xs" fontWeight="semibold">
                            {props.avgCost}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
        <Flex w="20%" justify="space-around" align="flex-start" mt={6}>
          {/* <Button variant="unstyled">
            <Icon as={RiLineChartLine} boxSize={6} color={colorMode === 'light' ? 'gray.500' : 'gray.300'} />
          </Button> */}
          {props.active ? (
            <Tooltip
              // hasArrow
              label="Settings"
              whiteSpace="break-spaces"
              bg={colorMode === 'light' ? 'white' : '#1f2124'}
              // py={3}
              // px={3}
              pt={0}
              mt={0}
              borderRadius="lg"
              p={3}
              borderWidth={1}
              color={colorMode === 'light' ? 'black' : 'white'}
              fontSize="12px"
            >
              <Button
                variant="unstyled"
                onClick={onBotSettingsOpen}
                minW={5}
                maxH={5}
                _focus={{ outline: 'none' }}
              >
                <Icon as={IoIosSettings} boxSize={5} color={colorMode === 'light' ? 'black' : 'white'} />
              </Button>
            </Tooltip>
          ) : null}
          <Tooltip
            // hasArrow
            label="Run Summary"
            whiteSpace="break-spaces"
            bg={colorMode === 'light' ? 'white' : '#1f2124'}
            // py={3}
            // px={3}
            pt={0}
            mt={0}
            borderRadius="lg"
            p={3}
            borderWidth={1}
            color={colorMode === 'light' ? 'black' : 'white'}
            fontSize="12px"
          >
            <Button
              _focus={{ outline: 'none' }}
              variant="unstyled"
              onClick={() => handlePlayStatsOpen(props.botID)}
              minW={5}
              maxH={5}
            >
              <Icon as={MdQueryStats} boxSize={5} color={colorMode === 'light' ? 'black' : 'white'} />
            </Button>
          </Tooltip>
        </Flex>
        {/* {!props.active ? (
          <Flex mt={0}>
            <Button variant="unstyled" onClick={() => handlePlayStatsOpen(props.botID)}>
              <Icon as={RiBillLine} boxSize={6} color={colorMode === 'light' ? 'gray.500' : 'gray.300'} />
            </Button>
          </Flex>
        ) : null} */}
      </Flex>
      {props.terminatedOption === 3 && props.active && (
        <Tooltip
          hasArrow
          label="This bot will be terminated after current run is completed."
          whiteSpace="break-spaces"
          bg="gray.200"
          color="gray.700"
          pt={1}
          pb={1}
          fontSize="xs"
        >
          <span
            style={{
              position: 'absolute',
              right: -11,
              zIndex: 3,
              top: -11,
              marginLeft: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '22px',
              height: '22px'
            }}
          >
            <Icon
              as={RiInformationFill}
              w="22px"
              h="22px"
              variant="unstyled"
              _focus={{
                outline: 'none'
              }}
              color="red.500"
            />
          </span>
        </Tooltip>
      )}
      {props.maxLossAmount !== 0 && props.active && (
        <Tooltip
          hasArrow
          label={`This bot will be terminated once your max loss limits is reached. ( $${props.maxLossAmount} )`}
          whiteSpace="break-spaces"
          bg="gray.200"
          color="gray.700"
          pt={1}
          pb={1}
          fontSize="xs"
        >
          <span
            style={{
              position: 'absolute',
              right: -11,
              zIndex: 3,
              top: -11,
              marginLeft: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '22px',
              height: '22px'
            }}
          >
            <Icon
              as={RiInformationFill}
              w="22px"
              h="22px"
              variant="unstyled"
              _focus={{
                outline: 'none'
              }}
              color="red.500"
            />
          </span>
        </Tooltip>
      )}
    </WrapItem>
  );
};

OandaBotCard.propTypes = {
  name: PropTypes.string
};

export default OandaBotCard;
