import Amplify, { Auth as Av, Cache } from 'aws-amplify';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { decodeToken, isExpired } from 'react-jwt';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import PageNotFound from '../../components/404/404';
import { getUser, login, singup } from '../../store/actions/auth';
// import { getUser } from '../../store/actions/profile';
import Dashboard from '../App/dashboard';
import Auth from '../Auth/auth';
import AuthVerify from '../Auth/authVerify';
import ForgotPassword from '../Auth/forgotPassword';
import Login from '../Auth/login';
import ResetPassword from '../Auth/resetPassword';
import Signup from '../Auth/signup';
import SignupEmail from '../Auth/signupEmail';
import Verify from '../Auth/verify';
import WaitingList from '../Auth/waitingList';
import Maintainance from '../maintainance';

const Layout = props => {
  // dashboard panel links could look like coinbase
  // avatar hover effect like oog

  const [isAuth, setIsAuth] = useState(null);

  const [authState, setAuthState] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const [routes, setRoutes] = useState();

  const token = localStorage.getItem('token');

  const dispatch = useDispatch();

  const profileState = useSelector(state => state.profile);

  const auth_state = useSelector(state => state.auth);

  const decodedJWT = decodeToken(token);

  const handleSignIn = useCallback(cognitoUser => {
    // console.log('doing this handlesignin');
    const idToken = cognitoUser?.signInUserSession?.idToken;
    // console.log('--idtoken:', idToken);
    const accessToken = cognitoUser?.signInUserSession?.accessToken;
    if (!idToken) return;
    // localStorage.setItem('token_exp', `${idToken.payload.exp * 1000}`);
    const oneHourFromNow = Date.now() + 1 * 60 * 60 * 1000;
    localStorage.setItem('token_exp', `${oneHourFromNow}`);
    const user = {
      jwt: accessToken.jwtToken,
      sub: idToken.payload['custom:user_id'] ? idToken.payload['custom:user_id'] : idToken.payload.sub,
      name: idToken.payload.name,
      isVerified: idToken.payload.email_verified,
      email: idToken.payload.email,
      picture: idToken.payload.picture
    };
    // console.log(idToken, cognitoUser?.signInUserSession);
    dispatch(getUser(user));
    // const user = await dispatch(getUser(id, token));
    // dispatch(login(user.sub));
    setIsAuth(true);
    setAuthState({ authenticated: true, user });
  }, []);

  useEffect(() => {
    // console.log(auth_state, authState?.user);
    // console.log(
    //   auth_state.isUserExistenceChecked,
    //   authState?.user,
    //   auth_state.isSignUpSuccess,
    //   auth_state.isUserExistInDB
    // );
    if (
      auth_state.isUserExistenceChecked &&
      !auth_state.isUserExistInDB &&
      authState.user &&
      !auth_state.isSignUpSuccess
    ) {
      // console.log('doing this singup in if in layout');
      dispatch(
        //TODO nanoid could be reinstalled here
        singup(
          authState.user.name !== undefined
            ? authState.user.name
            : authState.user.email.slice(0, authState.user.email.indexOf('@')),
          authState.user.email,
          authState.user.sub
        )
      );
    }
    if (auth_state.isUserExistenceChecked && authState.user && auth_state.isUserExistInDB) {
      dispatch(login(authState.user.sub));
    }
  }, [auth_state.isUserExistenceChecked, authState?.user, auth_state.isUserExistInDB]);

  const redirectToVerification = async username => {
    const in10Day = Date.now() + 10 * 24 * 60 * 60 * 1000;

    await Cache.setItem('pendingVerificationUser', username, { expires: in10Day });
    // await Cache.setItem('pendingVerificationUserEmail', username);
    // setMode("verification");
  };

  const config = {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
      scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_CLIENT_URL,
      redirectSignOut: process.env.REACT_APP_CLIENT_SIGNOUT_URL,
      // responseType: 'code'
      responseType: 'token'
    },
    federationTarget: 'COGNITO_USER_POOLS'
  };

  Amplify.configure(config);

  const signUpFields = [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'Enter your full name',
      required: true
    }
  ];

  // useEffect(() => {
  //   Av.currentAuthenticatedUser()
  //     .then(user => handleSignIn(user))
  //     .catch(() => setAuthState({ authenticated: false }));
  // }, [handleSignIn]);

  // useEffect(() => {
  //   console.log('isAuth ', isAuth);
  // }, [isAuth]);

  // if (!authState) return null;

  useEffect(() => {
    Av.currentAuthenticatedUser()
      .then(user => {
        // user.refreshSession();
        handleSignIn(user);
        setIsLoading(false);
      })
      .catch(err => {
        // console.log('err ', err);
        setAuthState({ authenticated: false });
        setIsLoading(false);
      });
  }, [auth_state.isSignUpSuccess]);
  //Above isAuth removed to unneccesary rerendering... it could be added again if needed

  const socialProviders = ['Google', 'SignInWithApple'];

  const renderApp = useCallback(() => {
    // console.log(
    //   'rendering app',
    //   profileState,
    //   props.history.location.pathname,
    //   isAuth,
    //   Cache.getItem('pendingVerificationUser')
    // );

    // if (!isLoading && isAuth && !auth_state.isApproved) {
    //   <Route path="/"><Redirect to="/waitinglist" /></Route>;
    // }
    if (process.env.REACT_APP_UNDER_MAINTENANCE === 'TRUE') {
      return <Maintainance />;
    }

    if (!isLoading) {
      return (
        <Switch>
          {/* <Route path="/">{!auth_state.approved && isAuth && <Redirect to="/waitinglist" />}</Route> */}
          {isAuth && !auth_state.approved && auth_state.id && (
            <Route path="/" render={props => <WaitingList {...props} isAuth={isAuth} />} />
          )}
          <Route
            path="/signup"
            exact
            render={props => (
              <Signup
                {...props}
                socialProviders={socialProviders}
                isAuth={isAuth}
                signUpFields={signUpFields}
                redirectToVerification={redirectToVerification}
              />
            )}
          />
          {/* <Route path="/login" exact render={props => <Login {...props} isAuth={isAuth} />} /> */}
          <Route
            path="/login"
            exact
            render={props => (
              <Login
                {...props}
                onSignIn={handleSignIn}
                setAuthState={setAuthState}
                isAuth={isAuth}
                socialProviders={socialProviders}
                redirectToVerification={redirectToVerification}
              />
            )}
          />
          <Route path="/verify/:id" exact render={props => <AuthVerify {...props} />} />
          <Route path="/signupsuccess" exact component={SignupEmail} />
          <Route path="/" exact render={props => <Dashboard {...props} isAuth={isAuth} />} />
          <Route path="/forgotpassword" exact render={props => <ForgotPassword {...props} />} />
          <Route path="/resetPassword" exact render={props => <ResetPassword {...props} />} />
          {auth_state.id && (
            <Route path="/reporting" exact render={props => <Dashboard {...props} isAuth={isAuth} />} />
          )}
          {auth_state.id && (
            <Route path="/mybots" exact render={props => <Dashboard {...props} isAuth={isAuth} />} />
          )}
          {auth_state.id && (
            <Route path="/rewards" exact render={props => <Dashboard {...props} isAuth={isAuth} />} />
          )}
          {auth_state.id && (
            <Route path="/news" exact render={props => <Dashboard {...props} isAuth={isAuth} />} />
          )}
          {auth_state.id && (
            <Route path="/markets" exact render={props => <Dashboard {...props} isAuth={isAuth} />} />
          )}
          {auth_state.id && (
            <Route path="/settings" exact render={props => <Dashboard {...props} isAuth={isAuth} />} />
          )}
          {auth_state.id && (
            <Route path="/social" exact render={props => <Dashboard {...props} isAuth={isAuth} />} />
          )}
          {auth_state.id && (
            <Route path="/subscriptions" exact render={props => <Dashboard {...props} isAuth={isAuth} />} />
          )}
          {/* <Route path="/cryptocurrencies/:id" render={props => <Dashboard {...props} isAuth={isAuth} />} /> */}
          {auth_state.id && (
            <Route path="/help" render={props => <Dashboard {...props} isAuth={isAuth} />}>
              {/* <Route path="/:id" render={props => <Dashboard {...props} isAuth={isAuth} />} /> */}
            </Route>
          )}
          {auth_state.id && <Route path="/support" render={props => <Dashboard {...props} isAuth={isAuth} />} />}
          {auth_state.id && <Route component={PageNotFound} />}
        </Switch>
      );
    } else {
      return null;
    }
  }, [isLoading, isAuth, auth_state.approved, process.env.REACT_APP_UNDER_MAINTAINANCE]);

  // const renderApp = useCallback(() => {
  //   console.log('rendering App', profileState);
  //   if (!profileState.loading) {
  //     return (
  //       <Switch>
  //         <Route path="/signup" exact render={props => <Signup isAuth={isAuth} {...props} />} />
  //         <Route path="/login" exact render={props => <Login isAuth={isAuth} {...props} />} />
  //         <Route path="/verify/:id" exact component={Verify} />
  //         <Route path="/signupsuccess" exact component={SignupEmail} />
  //         <Route path="/" exact render={props => <Dashboard {...props} isAuth={isAuth} />} />
  //         <Route path="/mybots" exact render={props => <Dashboard isAuth={isAuth} {...props} />} />
  //         <Route path="/markets" exact render={props => <Dashboard isAuth={isAuth} {...props} />} />
  //         <Route path="/social" exact render={props => <Dashboard isAuth={isAuth} {...props} />} />
  //         <Route path="/cryptocurrencies/:id" render={props => <Dashboard isAuth={isAuth} {...props} />} />
  //         <Route component={PageNotFound} />
  //       </Switch>
  //     );
  //   } else {
  //     return null;
  //   }
  // }, [profileState])

  return renderApp();
};

export default withRouter(Layout);
