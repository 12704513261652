import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Tooltip,
  useColorMode
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { MdEqualizer } from 'react-icons/md';

import { darkThemeBackgroundColor, epicFlowGradientColors } from '../../../theme';

const PriceSlider = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const [sliderValue, setSliderValue] = useState(0);

  const [showTooltip, setShowTooltip] = useState(false);

  // const [timer, setTimer] = useState(null)

  const handleChange = val => {
    setSliderValue(val);
    setShowTooltip(true);
    props.getSliderValue(val);
  };

  const handleChangeEnd = () => {
    setShowTooltip(false);
  };

  return (
    <Slider
      defaultValue={0}
      min={0}
      max={100}
      onChange={v => handleChange(v)}
      onChangeEnd={handleChangeEnd}
      // onMouseEnter={() => setShowTooltip(true)}
      // onMouseLeave={() => setShowTooltip(false)}
      focusThumbOnChange={false}
      mb={props.mb}
    >
      <SliderMark value={0} mt="2.5" fontSize="xs" color="gray.400">
        Min.
      </SliderMark>
      <SliderMark value={25} mt="2.5" fontSize="xs" ml="-2.5" color="gray.400">
        25%
      </SliderMark>
      <SliderMark value={50} mt="2.5" ml="-2.5" fontSize="xs" color="gray.400">
        50%
      </SliderMark>
      <SliderMark value={75} mt="2.5" ml="-2.5" fontSize="xs" color="gray.400">
        75%
      </SliderMark>
      <SliderMark value={100} mt="2.5" ml="-4" fontSize="xs" color="gray.400">
        100%
      </SliderMark>
      <SliderTrack bg={props.sliderColor}>
        <SliderFilledTrack bg={props.bg} />
      </SliderTrack>
      <Tooltip
        hasArrow
        // bg={epicFlowGradientColors.left}
        bg={props.bg}
        borderRadius="base"
        color="white"
        fontSize="xs"
        fontWeight="medium"
        placement="top"
        isOpen={showTooltip}
        label={`${sliderValue}%`}
      >
        <SliderThumb
          boxSize={4}
          borderRadius="base"
          bg={colorMode === 'light' ? 'white' : darkThemeBackgroundColor.dark}
          borderWidth={1}
          zIndex={0}
          // borderColor={epicFlowGradientColors.left}
          borderColor={props.bg}
        >
          {/* <Box color={epicFlowGradientColors.left} as={MdEqualizer} /> */}
          <Box color={props.bg} as={MdEqualizer} />
        </SliderThumb>
      </Tooltip>
    </Slider>
  );
};

PriceSlider.propTypes = {
  mb: PropTypes.number,
  getSliderValue: PropTypes.func
};

export default PriceSlider;
