import { Flex, Input, InputGroup, Text, useColorMode } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

import { epicFlowGradientColors } from '../../theme';

const AutoInput = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex direction="column" w="full" justify="flex-start" align="center" mt={props.mt} mb={props.mb}>
      <Flex w="full" justify="space-between" align="center">
        <Flex direction="column">
          <Text fontSize="0.8125rem">{props.title}</Text>
        </Flex>
        <InputGroup w={props.width}>
          <Input
            isRequired
            type={props.type}
            // onInput={props.onInput}
            value={props.value}
            pattern={props.pattern}
            onBlur={props.onBlur}
            onChange={props.handleChange}
            onPaste={props.onPaste}
            placeholder={props.placeholder}
            min={props.min}
            max={props.max}
            borderColor={props.bc}
            onKeyPress={props.onKeyPress}
            _placeholder={{
              color: colorMode === 'light' ? 'gray.400' : 'gray.500',
              fontSize: '12px'
            }}
            textAlign={props.textAlign}
            fontSize="0.8125rem"
            _focus={{
              // outline: 'none'
              borderColor: props.bc
            }}
          />
        </InputGroup>
      </Flex>
      {props.maxMargin && (
        <Flex justifyContent="flex-end" alignItems="center" w="full">
          <Text fontSize="11px" fontWeight="medium" mt={1} color="gray.500">
            {props.maxMargin}
          </Text>
        </Flex>
      )}
      {props.target !== 'USD' && props.target !== 'USDT' && props.isAmount ? (
        <Flex justifyContent="flex-end" alignItems="center" w="full">
          <Text fontSize="xs" fontWeight="medium" mt={1} color="gray.400">
            {props.amount} {props.base}
          </Text>
        </Flex>
      ) : null}
    </Flex>
  );
};

AutoInput.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  textAlign: PropTypes.string,
  mt: PropTypes.number,
  onInput: PropTypes.func,
  mb: PropTypes.number,
  value: PropTypes.string
};

export default AutoInput;
