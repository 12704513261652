import { Flex, Heading, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import AssetCard from './assetCard';

const CardContainer = props => {
  const displayOptions = ['none', 'none', 'flex', 'flex', 'flex', 'flex'];

  const handleRouting = useCallback(id => {
    if (id === 'usdollar') {
      return '';
    } else {
      return `/cryptocurrencies/${id.toLowerCase().replace(/\s/g, '').split('').join('')}`;
    }
  }, []);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      // console.log('mounting card container');
      mounted.current = true;
    } else {
      // console.log('updating card container');
      // do componentDidUpdate logic
    }
  });

  return (
    <Flex justify="flex-start" align="center" direction="column" w="full">
      <Flex justify="flex-start" align="center" w="100%" mt={12} mb={4}>
        <Heading as="h2" size="md" textAlign="left">
          My Assets
        </Heading>
        {/* <Text fontSize="sm" ml={2}>
          {props.exchange}
        </Text> */}
      </Flex>
      <Flex w="100%" m="auto" pt={5} pb={5} justify="space-between" alignItems="flex-start">
        <Heading fontSize="sm" fontWeight="semibold" textAlign="left" w="full" order={0} mr={8}>
          Name
        </Heading>
        <Heading
          fontSize="sm"
          fontWeight="semibold"
          w="full"
          textAlign={['right', 'right', 'left', 'left', 'left', 'left']}
          order={[2, 2, 1, 1, 1, 1]}
        >
          Balance
        </Heading>
        <Heading display={displayOptions} fontSize="sm" fontWeight="semibold" w="full" textAlign="left" order={2}>
          Quantity
        </Heading>
        <Heading
          order={[1, 1, 2, 2, 2, 2]}
          fontSize="sm"
          fontWeight="semibold"
          w="full"
          textAlign={['center', 'center', 'left', 'left', 'left', 'left']}
          // order={2}
        >
          Price
        </Heading>
        <Heading display={displayOptions} fontSize="sm" fontWeight="semibold" w="full" textAlign="left" order={3}>
          Allocation
        </Heading>
        {/* <Heading
          minW={36}
          maxW={36}
          order={[1, 1, 4, 4, 4, 4]}
          fontSize="sm"
          fontWeight="semibold"
          // w='full'
          textAlign={['center', 'center', 'right', 'right', 'right', 'right']}
        >
          Last 7 Days
        </Heading> */}
      </Flex>
      <Flex w="full" direction="column">
        {props.data?.map((asset, idx) => {
          return (
            <AssetCard
              key={idx}
              top
              to="/"
              length={props.data.length}
              index={idx}
              name={asset.name}
              imgUrl={asset.image}
              quantity={Number(asset.total).toFixed(3)}
              ticker={asset.symbol.toUpperCase()}
              balance={asset.balance}
              // dailyUp={asset.price_change_percentage_24h >= 0}
              // to={handleRouting(asset.id)}
              price={asset.current_price}
              change={asset.price_change_percentage_24h}
              allocation={asset.allocation}
              // sparklineData={asset.sparkline_in_7d.price}
              // weekUp={
              //   asset.sparkline_in_7d.price[asset.sparkline_in_7d.price.length - 1] >=
              //   asset.sparkline_in_7d.price[0]
              // }
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

CardContainer.propTypes = {
  data: PropTypes.array
};

export default CardContainer;
