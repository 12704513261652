import 'chartjs-plugin-annotation';

import { TimeIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, Text, useColorMode } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Apex from 'react-apexcharts';
import { Chart, Line } from 'react-chartjs-2';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';

import USD from '../assets/usd.svg';
import AutomationWizard from '../components/automationWizard/automationWizard';
import FxAutomationWizard from '../components/automationWizard/fxAutomationWizard';
import CardContainer from '../components/cards/cardContainer';
import FxCalendarCard from '../components/cards/fxCalendarContainer';
import MarginCard from '../components/cards/marginCard';
import OandaCardContainer from '../components/cards/oandaCardContainer';
import PortfolioTitle from '../components/UI/portfolioTitle';
import TimeBar from '../components/UI/TimeBar/timeBar';
import { getCurrentOption } from '../store/actions/auth';
import { getExchange } from '../store/actions/profile';
import { startExchangeData, stopExchangeData } from '../store/actions/socket';
import { fetchUserPortfolioData, getEarnings } from '../store/actions/user';
import { statusColors } from '../theme';
import { dynamicCurrencyFormatter, usdFormatter } from '../utilities/currencyConverter';
import { calculateExchangeID, getExchangeNames } from '../utilities/exchangeHelpers';
import { convertTimeFrameToReadable } from '../utilities/timeFormatter';

// TODO Change component name to ===> App once Layout hoc added.

// TODO This file needs to be refactored

const Home = props => {
  // eslint-disable-next-line no-unused-vars
  const { colorMode, toggleColorMode } = useColorMode();

  const [portfolioData, setPortfolioData] = useState([]);

  const [currentPerformance, setCurrentPerformance] = useState();

  const _chartRef = useRef(null);

  const [currentOption, setCurrentOption] = useState();

  const [currentGraphData, setCurrentGraphData] = useState();

  const [value, setValue] = useState({});

  const [displayValue, setDisplayValue] = useState();

  const [exchanges, setExchanges] = useState([]);

  const [treeData, setTreeData] = useState([]);

  const [pieData, setPieData] = useState([]);

  const [isClicked, setIsClicked] = useState(false);

  const dispatch = useDispatch();

  const [allData, setAllData] = useState([]);

  const userState = useSelector(state => state.user);

  const socketState = useSelector(state => state.socket);

  const [isBalanceLine, setIsBalanceLine] = useState(true);

  const [isMarginLine, setIsMarginLine] = useState(false);

  const [timeOption, setTimeOption] = useState('1D');

  const [spData, setSpData] = useState([]);

  // const mounted = useRef();
  // useEffect(() => {
  //   if (!mounted.current) {
  //     // do componentDidMount logic
  //     mounted.current = true;
  //   } else {
  //     // console.log('updating home');
  //     // do componentDidUpdate logic
  //   }
  // });

  // const fetchMarketData = async () => {
  //   try {
  //     setMarketDataLoading(true);
  //     const response = await fetch(
  //       `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=true&price_change_percentage=7d`
  //     );
  //     const data = await response.json();
  //     setMarketData(data);
  //     setMarketDataLoading(false);
  //   } catch (error) {
  //     setMarketDataLoading(false);
  //     throw new Error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchMarketData();
  // }, []);

  // useEffect(() => {
  //   if (!userState?.porfolioLoading) {
  //     if (userState?.currentGraphData && !userState?.porfolioLoading) {
  //       if (authState.currentOption === 'Oanda Demo' && timeOption === '1D') {
  //         setCurrentGraphData(
  //           calculateCurrentGraphData(
  //             userState?.gd.oandademo?.data?.day,
  //             userState?.bgd.oandademo?.data?.day,
  //             userState?.mgd.oandademo?.data?.day,
  //             userState?.bgd.oandademo?.timeStamps?.day,
  //             isClicked
  //           )
  //         );
  //         setCurrentPerformance({
  //           portfolio: userState?.gd.oandademo.portfolioPerformance?.day,
  //           bot: userState?.gd.oandademo.botPerformance?.day,
  //           portfolioChange: userState?.gd.oandademo.portfolioChange.day,
  //           botChange: userState?.gd.oandademo.botChange?.day,
  //           botChangeText: userState?.gd.oandademo.botChangeText
  //         });
  //       } else if (authState.currentOption === 'Oanda Live' && timeOption === '1D') {
  //         setCurrentGraphData(
  //           calculateCurrentGraphData(
  //             userState?.gd.oandalive?.data?.day,
  //             userState?.bgd.oandalive?.data?.day,
  //             userState?.mgd.oandalive?.data?.day,
  //             userState?.bgd.oandalive?.timeStamps?.day,
  //             isClicked
  //           )
  //         );
  //         setCurrentPerformance({
  //           portfolio: userState?.gd.oandalive.portfolioPerformance?.day,
  //           bot: userState?.gd.oandalive.botPerformance?.day,
  //           portfolioChange: userState?.gd.oandalive.portfolioChange.day,
  //           botChange: userState?.gd.oandalive.botChange?.day,
  //           botChangeText: userState?.gd.oandalive.botChangeText
  //         });
  //       }
  //     }
  //   }
  // }, [isClicked]);

  // const [exchangeIDs, setExchangeIDs] = useState({
  //   binanceus: 1,
  //   coinbase: 2,
  //   binancecom: 3
  // });
  const authState = useSelector(state => state.auth);
  // console.log('authState', authState);
  // console.log('home userState', userState);
  const profileState = useSelector(state => state.profile);

  // const onFetchUserAssetData = () => dispatch(fetchUserAssetData());

  // const onFetchUserPortfolioData = () => dispatch(fetchUserPortfolioData());

  const handleHover = useCallback((e, item) => {
    if (item.length && displayValue !== item[0]._chart.config?.data?.datasets[0].data[item[0]._index]) {
      const v = item[0]._chart.config?.data?.datasets[0].data[item[0]._index];
      handleValue(v);
    }
    // else {
    //   if (currentOption === 'Total') {
    //     console.log(value);
    //     handleValue(value.binance);
    //   } else if (currentOption === 'Binance US') {
    //     handleValue(value.binanceus);
    //   } else if (currentOption === 'Coinbase') {
    //     handleValue(value.coinbasepro);
    //   } else if (currentOption === 'Binance') {
    //     handleValue(value.binance);
    //   }
    // }
  }, []);

  // useEffect(() => {
  //   // TODO needing a control mechanism to make sure get new timestamps before executing calculate current graph data
  //   if (!userState?.porfolioLoading) {
  //     if (userState?.currentGraphData && !userState?.porfolioLoading) {
  //       setDisplayValue(userState?.displayValue);
  //       if (authState.currentOption === 'Binance US') {
  //         setCurrentGraphData(
  //           calculateCurrentGraphData(userState?.gd.binanceus?.data?.day, userState?.gd.binanceus?.timeStamps?.day)
  //         );
  //         setCurrentPerformance({
  //           portfolio: userState?.gd.binanceus.portfolioPerformance?.day,
  //           bot: userState?.gd.binanceus.botPerformance?.day,
  //           portfolioChange: userState?.gd.binanceus.portfolioChange.day,
  //           botChange: userState?.gd.binanceus.botChange?.day,
  //           botChangeText: userState?.gd.binanceus.botChangeText?.day
  //         });
  //       } else if (authState.currentOption === 'Binance') {
  //         setCurrentGraphData(
  //           calculateCurrentGraphData(userState?.gd.binance?.data?.day, userState?.gd.binance?.timeStamps?.day)
  //         );
  //         setCurrentPerformance({
  //           portfolio: userState?.gd.binance.portfolioPerformance?.day,
  //           bot: userState?.gd.binance.botPerformance?.day,
  //           portfolioChange: userState?.gd.binance.portfolioChange.day,
  //           botChange: userState?.gd.binance.botChange?.day,
  //           botChangeText: userState?.gd.binance.botChangeText?.day
  //         });
  //       } else if (authState.currentOption === 'Coinbase') {
  //         setCurrentGraphData(userState?.gd.coinbasepro?.data?.day);
  //         setCurrentPerformance({
  //           portfolio: userState?.gd.coinbasepro.portfolioPerformance?.day,
  //           bot: userState?.gd.coinbasepro.botPerformance?.day,
  //           portfolioChange: userState?.gd.coinbasepro.portfolioChange.day,
  //           botChange: userState?.gd.coinbasepro.botChange?.day,
  //           botChangeText: userState?.gd.coinbasepro.botChangeText
  //         });
  //       } else if (authState.currentOption === 'Oanda Demo') {
  //         console.log('ddd', isClicked);
  //         setCurrentGraphData(
  //           calculateCurrentGraphData(
  //             userState?.gd.oandademo?.data?.day,
  //             userState?.bgd.oandademo?.data?.day,
  //             userState?.mgd.oandademo?.data?.day,
  //             userState?.bgd.oandademo?.timeStamps?.day,
  //             isClicked
  //           )
  //         );
  //         setCurrentPerformance({
  //           portfolio: userState?.gd.oandademo.portfolioPerformance?.day,
  //           bot: userState?.gd.oandademo.botPerformance?.day,
  //           portfolioChange: userState?.gd.oandademo.portfolioChange.day,
  //           botChange: userState?.gd.oandademo.botChange?.day,
  //           botChangeText: userState?.gd.oandademo.botChangeText
  //         });
  //       } else if (authState.currentOption === 'Oanda Live') {
  //         setCurrentGraphData(
  //           calculateCurrentGraphData(
  //             userState?.gd.oandalive?.data?.day,
  //             userState?.bgd.oandalive?.data?.day,
  //             userState?.mgd.oandalive?.data?.day,
  //             userState?.bgd.oandalive?.timeStamps?.day,
  //             isClicked
  //           )
  //         );
  //         setCurrentPerformance({
  //           portfolio: userState?.gd.oandalive.portfolioPerformance?.day,
  //           bot: userState?.gd.oandalive.botPerformance?.day,
  //           portfolioChange: userState?.gd.oandalive.portfolioChange.day,
  //           botChange: userState?.gd.oandalive.botChange?.day,
  //           botChangeText: userState?.gd.oandalive.botChangeText
  //         });
  //       }
  //     }
  //   }
  // }, [userState?.currentGraphData, userState?.porfolioLoading]);

  const calculateMinMax = (data = [], secondata = [], thirdData = []) => {
    let allData = [];
    let graphData = [];
    graphData.push(
      {
        nav: data
      },
      {
        balance: secondata
      },
      {
        margin: thirdData
      }
    );
    for (let item of data) {
      allData.push({ nav: item });
    }
    for (let item of secondata) {
      allData.push({ balance: item });
    }
    for (let item of thirdData) {
      allData.push({ margin: item });
    }
    setAllData(allData);
    setSpData(graphData);
  };

  const calculateCurrentGraphData = (
    data,
    secondata = null,
    thirdGraphData = null,
    timeStamps,
    isBalanceLine,
    isMarginLine
  ) => {
    if (!data || !timeStamps) {
      return;
    }
    let graphData;
    let datasets = [
      {
        label: 'nav',
        data: [...data],
        fill: true,
        backgroundColor: 'transparent',
        // borderColor: data[0] < data[data.length - 1] ? '#48BB78' : '#ff5400',
        borderColor: '#4361ee',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        // pointHoverBackgroundColor: data[0] < data[data.length - 1] ? '#48BB78' : '#ff5400',
        pointHoverBackgroundColor: '#4361ee',
        pointHoverBorderColor: '#fff',
        pointHoverRadius: 6,
        pointHoverBorderWidth: 1,
        pointRadius: 10,
        borderWidth: 2,
        yAxisID: 'y1'
      }
    ];
    if (isBalanceLine && (authState.currentOption === 'Oanda Demo' || authState.currentOption === 'Oanda Live')) {
      datasets.push({
        label: 'balance',
        data: [...secondata],
        fill: true,
        backgroundColor: 'transparent',
        // borderColor: data[0] < data[data.length - 1] ? '#48BB78' : '#ff5400',
        borderColor: '#48BB78',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        // pointHoverBackgroundColor: data[0] < data[data.length - 1] ? '#48BB78' : '#ff5400',
        pointHoverBackgroundColor: '#48BB78',
        pointHoverBorderColor: '#fff',
        pointHoverRadius: 6,
        pointHoverBorderWidth: 1,
        pointRadius: 10,
        borderWidth: 1.5,
        yAxisID: 'y2'
      });
    }
    if (isMarginLine) {
      datasets.push({
        label: 'margin',
        data: [...thirdGraphData],
        fill: true,
        backgroundColor: 'transparent',
        // borderColor: data[0] < data[data.length - 1] ? '#48BB78' : '#ff5400',
        borderColor: '#ffbd00',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        // pointHoverBackgroundColor: data[0] < data[data.length - 1] ? '#48BB78' : '#ff5400',
        pointHoverBackgroundColor: '#ffbd00',
        pointHoverBorderColor: '#fff',
        pointHoverRadius: 6,
        pointHoverBorderWidth: 1,
        pointRadius: 10,
        borderWidth: 1.5,
        yAxisID: 'y3'
      });
    }
    if (authState.currentOption === 'Oanda Demo' || authState.currentOption === 'Oanda Live') {
      graphData = {
        labels: [...timeStamps],
        datasets: datasets
      };
    } else {
      graphData = {
        labels: [...timeStamps],
        datasets: [
          {
            label: 'nav',
            data: [...data],
            fill: true,
            backgroundColor: 'transparent',
            // borderColor: data[0] < data[data.length - 1] ? '#48BB78' : '#ff5400',
            borderColor: '#4361ee',
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
            // pointHoverBackgroundColor: data[0] < data[data.length - 1] ? '#48BB78' : '#ff5400',
            pointHoverBackgroundColor: '#4361ee',
            pointHoverBorderColor: '#fff',
            pointHoverRadius: 6,
            pointHoverBorderWidth: 1,
            pointRadius: 10,
            borderWidth: 2,
            yAxisID: 'y1'
          }
        ]
      };
    }
    return graphData;
  };

  const [options, setOptions] = useState({
    legend: {
      display: false
    },
    layout: {
      padding: {
        top: 4,
        right: 6
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      backgroundColor: colorMode === 'light' ? 'rgba(256,256,256,0.85)' : 'rgba(0,0,0,0.85)',
      bodyFontColor: colorMode === 'light' ? '#2D3748' : '#CBD5E0',
      borderColor: colorMode === 'light' ? '#CBD5E0' : '#4A5568',
      titleFontColor: colorMode === 'light' ? '#000' : '#fff',
      footerFontColor: colorMode === 'light' ? '#000' : '#fff',
      bodyFontSize: 13,
      bodyFontStyle: 'normal',
      titleMarginBottom: 12,
      titleFontSize: 17,
      borderWidth: 0.5,
      xPadding: 24,
      yPadding: 18,
      footerFontSize: 15,
      footerMarginTop: 10,
      // padding: {
      //   top: 24,
      //   left: 24,
      //   right: 20
      // },
      // multiKeyBackground: 'red',
      displayColors: false,
      callbacks: {
        label: function (t) {
          // let xLabel = t.xLabel;
          // let yLabel = t.yLabel;
          if (t.datasetIndex === 0) {
            return `${new Date(t.xLabel).toLocaleString()}`;
          }
          // console.log(yLabel)
        },
        // labelTextColor: function (tooltipItem, chart) {
        //   return '#543453';
        // },
        // afterFooter: function (t) {
        //   // let xLabel = t.xLabel;
        //   // let yLabel = t.yLabel;
        //   console.log(t);
        //   return `Heyya`;
        //   // console.log(yLabel)
        // },
        title: function (context) {
          // return `${new Date(Number(context[0].label)).toLocaleString()}`;
          return 'Account Value';
        },
        footer: function (context, tt) {
          if (context[1] && context[2]) {
            return `NAV:  ${dynamicCurrencyFormatter(userState?.baseCurrency).format(
              context[0].yLabel
            )}\n\nBalance:  ${dynamicCurrencyFormatter(userState?.baseCurrency).format(
              context[1].yLabel
            )}\n\nMargin Available:  ${usdFormatter.format(context[2].yLabel)}`;
          } else if (context[1] && !context[2] && tt.datasets[1].label === 'balance') {
            return `NAV:  ${dynamicCurrencyFormatter(userState?.baseCurrency).format(
              context[0].yLabel
            )}\n\nBalance:  ${dynamicCurrencyFormatter(userState?.baseCurrency).format(context[1].yLabel)}`;
          } else if (context[1] && !context[2] && tt.datasets[1].label === 'margin') {
            return `NAV:  ${dynamicCurrencyFormatter(userState?.baseCurrency).format(
              context[0].yLabel
            )}\n\nMargin Available:  ${dynamicCurrencyFormatter(userState?.baseCurrency).format(
              context[1].yLabel
            )}`;
          } else {
            return `NAV:  ${dynamicCurrencyFormatter(userState?.baseCurrency).format(context[0].yLabel)}`;
          }
          // return `NAV: ${usdFormatter.format(context[0].yLabel)}`;
          // return (
          //   <Flex>
          //     <Text>NAV: ${context[0].yLabel}</Text>
          //   </Flex>
          // );
        }
      }
    },
    // animation: {
    //   duration: 0
    // },
    // responsiveAnimationDuration: 0,
    hover: {
      mode: 'index',
      intersect: false,
      animationDuration: 0,
      onHover: handleHover
    },
    elements: {
      line: {
        tension: 0.4
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: false //this will remove only the label
          },
          gridLines: {
            display: false
          }
        }
      ]
      // yAxes: [
      //   {
      //     ticks: {
      //       display: false,
      //       // beginAtZero: true,
      //       suggestedMax: 8200
      //     },
      //     gridLines: {
      //       display: false
      //     },
      //     id: 'y1',
      //     position: 'left'
      //     // min: 4000
      //     // max: 9000
      //   },
      //   {
      //     ticks: {
      //       display: false,
      //       suggestedMax: 8200
      //       // min: 0
      //       // maxTicksLimit: 4
      //     },
      //     gridLines: {
      //       display: false
      //     },
      //     id: 'y2',
      //     position: 'right',
      //     grid: {
      //       drawOnChartArea: false
      //     },
      //     // min: 0,
      //     max: 8200
      //   },
      //   {
      //     ticks: {
      //       display: false,
      //       // beginAtZero: true,
      //       suggestedMax: 8200
      //     },
      //     gridLines: {
      //       display: false
      //     },
      //     id: 'y3',
      //     position: 'right',

      //     grid: {
      //       drawOnChartArea: false
      //     },
      //     // min: 4000
      //     max: 8200
      //   }
      // ]
    },
    maintainAspectRatio: false
  });

  // useEffect(() => {
  //   setOptions({
  //     ...options,
  //     tooltips: {
  //       ...options.tooltips,
  //       backgroundColor: colorMode === 'light' ? '#fff' : '#1E2025',
  //       bodyFontColor: colorMode === 'light' ? '#2D3748' : '#E2E8F0',
  //       borderColor: colorMode === 'light' ? '#CBD5E0' : '#4A5568',
  //       titleFontColor: colorMode === 'light' ? '#000' : '#fff',
  //       footerFontColor: colorMode === 'light' ? '#000' : '#fff',
  //       callbacks: {
  //         ...options.tooltips.callbacks,
  //         footer: function (context) {
  //           console.log(context);
  //           // if (isBalanceLine && isMarginLine) {
  //           //   if (context[1].yLabel && context[2].yLabel) {
  //           //     return `NAV: $${context[0].yLabel}\n\nBalance: $${context[1].yLabel}\n\nMargin Available: $${context[2].yLabel}`;
  //           //   }
  //           // } else if (isBalanceLine && !isMarginLine) {
  //           //   if (context[1].yLabel) {
  //           //     return `NAV: $${context[0].yLabel}\n\nBalance: $${context[1].yLabel}`;
  //           //   }
  //           // } else if (isMarginLine && !isBalanceLine) {
  //           //   if (context[1].yLabel) {
  //           //     return `NAV: $${context[0].yLabel}\n\nMargin Available: $${context[1].yLabel}`;
  //           //   }
  //           // } else {
  //           //   return `NAV: $${context[0].yLabel}`;
  //           // }
  //         }
  //       }
  //     }
  //   });
  // }, [isBalanceLine, isMarginLine, colorMode]);

  // const selectUserData = useCallback(
  //   selected => {
  //     if (selected === 'Total') {
  //       setPortfolioData(userState?.assetData[2]?.combined);
  //     } else if (selected === 'Binance US') {
  //       setPortfolioData(userState?.assetData[0]?.binanceus);
  //     } else if (selected === 'Coinbase') {
  //       setPortfolioData(userState?.assetData[1]?.coinbasepro);
  //     }
  //   },
  //   [portfolioData]
  // );

  useEffect(() => {
    // Below code when combined graph data implemented
    // let exchanges = ['Total'];
    // console.log('doing this', localStorage.getItem('exchange_default'));
    let exchanges = [];
    if (authState.id) {
      exchanges.push(...authState.exchanges);
      if (exchanges.length === 0) {
        return;
      }
      if (localStorage.getItem('exchange_default')) {
        // console.log('if', localStorage.getItem('exchange_default'));
        setCurrentOption(localStorage.getItem('exchange_default'));
        dispatch(getCurrentOption(localStorage.getItem('exchange_default')));
        return setExchanges(exchanges);
      } else {
        localStorage.setItem('exchange_default', authState.currentOption);
        // console.log('else', localStorage.getItem('exchange_default'));
        setCurrentOption(localStorage.getItem('exchange_default'));
        dispatch(getCurrentOption(localStorage.getItem('exchange_default')));
        return setExchanges(exchanges);
      }
    }
  }, [authState.id]);

  useEffect(() => {
    setOptions({
      ...options,
      tooltips: {
        ...options.tooltips,
        backgroundColor: colorMode === 'light' ? 'rgba(256,256,256,0.85)' : 'rgba(0,0,0,0.85)',
        bodyFontColor: colorMode === 'light' ? '#2D3748' : '#CBD5E0',
        borderColor: colorMode === 'light' ? '#CBD5E0' : '#4A5568',
        titleFontColor: colorMode === 'light' ? '#000' : '#fff',
        footerFontColor: colorMode === 'light' ? '#000' : '#fff'
      }
    });
  }, [colorMode]);

  useEffect(() => {
    // Adding Vertical line to chart
    Chart.pluginService?.register({
      beforeDraw: function (chart, easing) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const activePoint = chart.controller.tooltip._active[0];
          const ctx = chart.ctx;
          const x = activePoint.tooltipPosition().x;
          const topY = chart.scales['y1'].top;
          const bottomY = chart.scales['y1'].bottom;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = colorMode === 'light' ? '#525763' : '#718096';
          ctx.stroke();
          ctx.restore();
          // ctx.shadowColor = '#4b4b4b8e';
          // ctx.shadowBlur = 20;
          // ctx.shadowOffsetX = 1;
          // ctx.shadowOffsetY = 2;
          // ctx.restore();
        }
      }
    });
  }, [colorMode]);

  useEffect(() => {
    let min;
    let max;
    let arr = [];
    if (isBalanceLine && !isMarginLine) {
      for (let item of allData) {
        if (item.nav) {
          arr.push(item.nav);
        }
        if (item.balance) {
          arr.push(item.balance);
        }
      }
    } else if (!isBalanceLine && isMarginLine) {
      for (let item of allData) {
        if (item.nav) {
          arr.push(item.nav);
        }
        if (item.margin) {
          arr.push(item.margin);
        }
      }
    } else if (isBalanceLine && isMarginLine) {
      for (let item of allData) {
        if (item.nav) {
          arr.push(item.nav);
        }
        if (item.balance) {
          arr.push(item.balance);
        }
        if (item.margin) {
          arr.push(item.margin);
        }
      }
    }
    min = arr.sort((a, b) => a - b)[0];
    max = arr.sort((a, b) => b - a)[0];
    let tempMax;
    let tempMin;
    for (let item of spData) {
      if (item.nav) {
        tempMin = [...item.nav].sort((a, b) => a - b)[0];
        tempMax = [...item.nav].sort((a, b) => b - a)[0];
      }
    }
    setOptions({
      ...options,
      scales: {
        ...options.scales,
        yAxes: [
          {
            ticks: {
              display: true,
              // beginAtZero: true,
              suggestedMax: isMarginLine || isBalanceLine ? max : tempMax,
              suggestedMin: isMarginLine || isBalanceLine ? min : tempMin
            },
            gridLines: {
              display: false
            },
            id: 'y1',
            position: 'left'
            // max: 9000
          },
          {
            ticks: {
              display: false,
              suggestedMax: max,
              suggestedMin: min
              // min: 0
              // maxTicksLimit: 4
            },
            gridLines: {
              display: false
            },
            id: 'y2',
            position: 'right',
            grid: {
              drawOnChartArea: false
            },
            min: min
          },
          {
            ticks: {
              display: false,
              // beginAtZero: true,
              suggestedMax: max,
              suggestedMin: min
            },
            gridLines: {
              display: false
            },
            id: 'y3',
            position: 'right',

            grid: {
              drawOnChartArea: false
            },
            // min: 4000
            min: min
          }
        ]
      },
      hover: {
        ...options.hover,
        onHover: (e, item) => {
          // console.log('hovering', displayValue);
          // console.log(displayValue, item[0]._chart.config?.data?.datasets[0].data[item[0]._index])
          if (item.length && displayValue !== item[0]._chart.config?.data?.datasets[0].data[item[0]._index]) {
            const v = item[0]._chart.config?.data?.datasets[0].data[item[0]._index];
            handleValue(v);
          } else {
            if (currentOption === 'Total') {
              handleValue(userState?.initialValues.binanceus);
            } else if (currentOption === 'Binance US') {
              handleValue(userState?.initialValues.binanceus);
            } else if (currentOption === 'Coinbase') {
              handleValue(userState?.initialValues.coinbasepro);
            } else if (currentOption === 'Binance') {
              handleValue(userState?.initialValues.binance);
            }
          }
        }
      }
    });
  }, [currentGraphData, value, allData, isBalanceLine, isMarginLine]);

  const calculateTreeData = useCallback(data => {
    let tree = [];
    for (let item of data) {
      tree.push({
        x: item.name,
        y: `${String(item.allocation)}`
      });
    }
    return tree;
  }, []);

  const calculatePieData = useCallback(data => {
    let labels = [];
    let series = [];
    let amounts = [];
    let total;
    for (let item of data) {
      if (item.symbol === 'ALL') {
        continue;
      }
      labels.push(item.symbol);
    }
    for (let item of data) {
      if (item.symbol === 'ALL') {
        continue;
      }
      amounts.push(item.amount);
    }
    for (let item of data) {
      if (item.symbol === 'ALL') {
        total = item.base_value;
        continue;
      }
      series.push(item.base_value);
    }
    return {
      labels: labels,
      series: series,
      amounts,
      total: total
    };
  }, []);

  useEffect(() => {
    if (userState?.assetData?.length > 0 && currentOption) {
      setTreeData(calculateTreeData(userState?.assetData[0][calculateExchangeKey(currentOption).exc]));
    }
    if (userState?.earnings?.length > 0 && currentOption) {
      setPieData(calculatePieData(userState?.earnings));
    }
  }, [userState?.assetData, userState?.earnings]);

  // useEffect(() => {
  //   if (currentOption && !userState?.isAssetDataSocketOn) {
  //     // dispatch(fetchUserAssetData(authState.id || profileState.id));
  //     // dispatch(
  //     //   fetchUserAssetData(
  //     //     authState.id || profileState.id,
  //     //     getExchangeNames(currentOption),
  //     //     calculateExchangeID(currentOption)
  //     //   )
  //     // );
  //     // dispatch(fetchUserAssetData('0b00fa3e-fb0b-4424-bf93-ed63b0cdf82a'));
  //   }
  // }, [currentOption, userState?.isAssetDataSocketOn]);

  useEffect(() => {
    if (currentOption) {
      dispatch(getEarnings(authState.id || profileState.id, calculateExchangeID(currentOption)));
      // dispatch(getEarnings('0b00fa3e-fb0b-4424-bf93-ed63b0cdf82a', calculateExchangeID(currentOption)));
    }
  }, [authState.id, profileState.id, currentOption]);

  // useEffect(() => {
  //   startSocket(authState.id || profileState.id, 'binanceus');
  // }, [authState.id, profileState.id]);

  // useEffect(() => {
  //   if (currentOption && !userState?.currentGraphData) {
  //     dispatch(fetchUserPortfolioData(authState.id || profileState.id, calculateExchangeID(currentOption), 'day'));
  //     // dispatch(
  //     //   fetchUserPortfolioData('0b00fa3e-fb0b-4424-bf93-ed63b0cdf82a', calculateExchangeID(currentOption), 'day')
  //     // );
  //   }
  // }, [authState.id, profileState.id, currentOption, userState?.currentGraphData]);

  // check rerenders

  useEffect(() => {
    if (!profileState.isExchangesChecked && authState.id) {
      dispatch(getExchange(authState.id));
      // dispatch(getExchange('0b00fa3e-fb0b-4424-bf93-ed63b0cdf82a'));
    }
  }, [authState.id]);

  const calculateExchangeKey = useCallback(
    option => {
      let combinedKey;
      let binanceusKey;
      let binancecomKey;
      let coinbaseproKey;
      for (let i = 0; i < userState?.assetData.length; i++) {
        if (userState?.assetData[i]['combined']) {
          combinedKey = i;
        } else if (userState?.assetData[i]['binanceus']) {
          binanceusKey = i;
        } else if (userState?.assetData[i]['binancecom']) {
          binancecomKey = i;
        }
        if (userState?.assetData[i]['coinbasepro']) {
          coinbaseproKey = i;
        }
      }
      // investigate below console log
      // console.log('option ', option);
      if (option === 'Binance US') {
        return {
          exc: 'binanceus',
          key: binanceusKey
        };
      } else if (option === 'Coinbase') {
        return {
          exc: 'coinbasepro',
          key: coinbaseproKey
        };
      } else if (option === 'Binance') {
        return {
          exc: 'binancecom',
          key: binancecomKey
        };
      } else if (option === 'Total') {
        return {
          exc: 'combined',
          key: combinedKey
        };
      }
    },
    [currentOption, userState?.assetData]
  );

  // useEffect(() => {
  //   if (userState?.assetData && currentOption) {
  //     console.log(
  //       'home ',
  //       userState?.assetData[calculateExchangeKey(currentOption).key][calculateExchangeKey(currentOption).exc]
  //     );
  //   }
  // }, [userState?.assetData, currentOption]);

  const handleCurrentGraphData = async selected => {
    //T0D0 Need a generic solution
    console.log('---selected', selected);
    // console.log(selected, currentOption);
    if (selected === localStorage.getItem('exchange_default')) {
      return;
    }
    localStorage.setItem('exchange_default', selected);
    let key;
    let exchange_name = '';
    if (selected === 'Binance US') {
      key = 1;
    } else if (selected === 'Binance') {
      key = 3;
    } else if (selected === 'Oanda Live') {
      key = 6;
      exchange_name = 'oanda_live';
    } else if (selected === 'Oanda Demo') {
      key = 5;
      exchange_name = 'oanda_demo';
    }
    // dispatch(getUser(authState));
    // dispatch(setOption(selected));
    // console.log('socket ', socketState);
    // dispatch(stopSocket(socketState.socket));
    console.log('userState : ', userState);
    let currentExchangeId = calculateExchangeID(authState.currentOption);
    console.log('currentExchangeId : ', currentExchangeId);
    await stopExchangeData(socketState.socket, currentExchangeId, authState.id);
    console.log('stop socket done successfully.');
    console.log('starting socket for:', key);
    await startExchangeData(socketState.socket, key, authState.id, exchange_name);
    setCurrentOption(selected);
    console.log('after setCurrentOption');
    dispatch(getCurrentOption(selected));
    let to = localStorage.getItem('tf');
    console.log('to : ', to);
    let time =
      to === '1D'
        ? 'day'
        : to === '1W'
        ? 'week'
        : to === '1M'
        ? 'month'
        : to === '3M'
        ? '3month'
        : to === '1Y'
        ? 'year'
        : to === 'ALL'
        ? 'all'
        : '';
    console.log('time : ', time);
    dispatch(fetchUserPortfolioData(authState.id, key, 'day'));
    // dispatch(fetchUserAssetData(authState.id, selected.toLowerCase().replace(/\s+/g, ''), null));
    // console.log('handling current graph data for ', userState?.graphData.combined.datasets[0].data[0]);
    // if (selected === 'Total') {
    //   setCurrentOption('Total');
    //   setPortfolioData(userState?.assetData[calculateExchangeKey().combinedKey]?.combined);
    //   setCurrentGraphData(userState?.graphData.combined);
    //   setDisplayValue(userState?.initialValues?.combined);
    //   setPlaceholder(userState?.initialValues?.combined);
    // }
    // console.log('ssss ', selected, calculateExchangeKey(selected));
    // const key = String(calculateExchangeKey(selected).key);
    // localStorage.setItem('exchange_default', '0');
    // const key = String(calculateExchangeKey(selected).key);
    // console.log(calculateExchangeKey(selected));
    // console.log(key);
    // dispatch(fetchUserPortfolioData())
    // window.location.reload();
    // if (selected === 'Binance US') {
    //   setCurrentOption('Binance US');
    //   dispatch(setOption('Binance US'));
    //   setPortfolioData(userState?.assetData[calculateExchangeKey(selected).key]?.binanceus);
    //   setCurrentGraphData(
    //     calculateCurrentGraphData(userState?.gd.binanceus?.data?.day, userState?.gd.binanceus?.timeStamps?.day)
    //   );
    //   setDisplayValue(userState?.initialValues?.binanceus);
    //   setPlaceholder(userState?.initialValues?.binanceus);
    // } else if (selected === 'Binance') {
    //   console.log('buraya geliyor');
    //   setCurrentOption('Binance');
    //   dispatch(setOption('Binance'));
    //   setPortfolioData(userState?.assetData[calculateExchangeKey(selected).key]?.binancecom);
    //   setCurrentGraphData(
    //     calculateCurrentGraphData(userState?.gd.binance?.data?.day, userState?.gd.binance?.timeStamps?.day)
    //   );
    //   setDisplayValue(userState?.initialValues?.binance);
    //   setPlaceholder(userState?.initialValues?.binance);
    // } else if (selected === 'Coinbase') {
    //   setCurrentOption('Coinbase');
    //   dispatch(setOption('Coinbase'));
    //   setPortfolioData(userState?.assetData[calculateExchangeKey(selected).key]?.coinbasepro);
    //   setCurrentGraphData(userState?.gd.coinbasepro?.data?.day);
    //   setDisplayValue(userState?.initialValues?.coinbasepro);
    //   setPlaceholder(userState?.initialValues?.coinbasepro);
    // }
  };

  const handleValue = value => {
    setDisplayValue(value);
  };

  const calculateOandaPositionValue = (item, isRpl) => {
    if (item.long.financing !== 0 && item.short.financing !== 0) {
      if (isRpl) {
        return item.long.financing + item.long.resettablePL + item.short.financing + item.short.resettablePL;
      }
    } else if (item.long.units > 0) {
      return item.long.financing + item.long.resettablePL;
    } else if (item.short.units < 0) {
      return item.short.financing + item.short.resettablePL;
    } else {
      return item.long.financing + item.long.resettablePL + item.short.financing + item.short.resettablePL;
    }
  };

  function isBetweenFriday5pmAndSunday5pm() {
    // Get current date and time
    const currentDate = new Date();
    // Adjust the current time to Eastern Standard Time (EST)
    const currentDateTimeInEST = new Date(currentDate.toLocaleString('en-US', { timeZone: 'America/New_York' }));

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = currentDateTimeInEST.getDay();
    // Get the current hour in 24-hour format
    const hour = currentDateTimeInEST.getHours();

    // Check if it's between Friday 4:59:59pm and Sunday 4:59:59pm in EST
    if ((dayOfWeek === 5 && hour >= 17) || dayOfWeek === 6 || (dayOfWeek === 0 && hour < 17)) {
      // Friday after 4:59:59pm, Saturday, or Sunday before 5pm
      return true;
    } else {
      return false;
    }
  }

  const handleOandaData = useCallback(
    data => {
      const structuredArr = [];
      for (let item in data) {
        structuredArr.push({
          market: item,
          isSwitched: data[item].long.financing !== 0 && data[item].short.financing !== 0,
          type: data[item].long.units > 0 ? 'LONG' : data[item].short.units < 0 ? 'SHORT' : 'INACTIVE',
          units:
            data[item].long.units > 0
              ? data[item].long.units
              : data[item].short.units < 0
              ? Math.abs(data[item].short.units)
              : 0,
          resettablePL: calculateOandaPositionValue(data[item], true),
          unrealizedPL:
            data[item].long.units > 0
              ? data[item].long.unrealizedPL
              : data[item].short.units < 0
              ? data[item].short.unrealizedPL
              : ''
        });
      }
      return structuredArr;
    },
    [userState?.oandaData]
  );

  useEffect(() => {
    setPortfolioData(userState?.initialPortfolioData);
  }, [userState?.initialPortfolioData]);

  useEffect(() => {
    // console.log('-----in portfolio data set useeffect', currentOption, timeOption, userState);
    if (!userState?.porfolioLoading && currentOption) {
      if (timeOption === '1D') {
        setCurrentGraphData(
          calculateCurrentGraphData(
            userState?.gd[getExchangeNames(currentOption, true)]?.data?.day,
            userState?.bgd[getExchangeNames(currentOption, true)]?.data?.day,
            userState?.mgd[getExchangeNames(currentOption, true)]?.data?.day,
            userState?.gd[getExchangeNames(currentOption, true)]?.timeStamps?.day,
            isBalanceLine,
            isMarginLine
          )
        );
        calculateMinMax(
          userState?.gd[getExchangeNames(currentOption, true)]?.data?.day,
          userState?.bgd[getExchangeNames(currentOption, true)]?.data?.day,
          userState?.mgd[getExchangeNames(currentOption, true)]?.data?.day
        );
        setCurrentPerformance({
          margin:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.marginPerformance?.day,
          marginChange:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.marginChange?.day,
          balance:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.balancePerformance?.day,
          balanceChange:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.balanceChange?.day,
          portfolio: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioPerformance?.day,
          bot: userState?.gd[getExchangeNames(currentOption, true)]?.botPerformance?.day,
          portfolioChange: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioChange?.day,
          botChange: userState?.gd[getExchangeNames(currentOption, true)]?.botChange?.day,
          botChangeText: userState?.gd[getExchangeNames(currentOption, true)]?.botChangeText?.day
        });
      }
      if (timeOption === '1W') {
        setCurrentGraphData(
          calculateCurrentGraphData(
            userState?.gd[getExchangeNames(currentOption, true)]?.data?.week,
            userState?.bgd[getExchangeNames(currentOption, true)]?.data?.week,
            userState?.mgd[getExchangeNames(currentOption, true)]?.data?.week,
            userState?.gd[getExchangeNames(currentOption, true)]?.timeStamps?.week,
            isBalanceLine,
            isMarginLine
          )
        );
        calculateMinMax(
          userState?.gd[getExchangeNames(currentOption, true)]?.data?.week,
          userState?.bgd[getExchangeNames(currentOption, true)]?.data?.week,
          userState?.mgd[getExchangeNames(currentOption, true)]?.data?.week
        );
        setCurrentPerformance({
          balance:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.balancePerformance?.week,
          balanceChange:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.balanceChange?.week,
          margin:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.marginPerformance?.week,
          marginChange:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.marginChange?.week,
          portfolio: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioPerformance?.week,
          bot: userState?.gd[getExchangeNames(currentOption, true)]?.botPerformance?.week,
          portfolioChange: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioChange?.week,
          botChange: userState?.gd[getExchangeNames(currentOption, true)]?.botChange?.week,
          botChangeText: userState?.gd[getExchangeNames(currentOption, true)]?.botChangeText?.week
        });
      } else if (timeOption === '1M') {
        setCurrentGraphData(
          calculateCurrentGraphData(
            userState?.gd[getExchangeNames(currentOption, true)]?.data?.month,
            userState?.bgd[getExchangeNames(currentOption, true)]?.data?.month,
            userState?.mgd[getExchangeNames(currentOption, true)]?.data?.month,
            userState?.gd[getExchangeNames(currentOption, true)]?.timeStamps?.month,
            isBalanceLine,
            isMarginLine
          )
        );
        calculateMinMax(
          userState?.gd[getExchangeNames(currentOption, true)]?.data?.month,
          userState?.bgd[getExchangeNames(currentOption, true)]?.data?.month,
          userState?.mgd[getExchangeNames(currentOption, true)]?.data?.month
        );
        setCurrentPerformance({
          balance:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.balancePerformance.month,
          balanceChange:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.balanceChange?.month,
          margin:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.marginPerformance?.month,
          marginChange:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.marginChange?.month,
          portfolio: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioPerformance?.month,
          bot: userState?.gd[getExchangeNames(currentOption, true)]?.botPerformance?.month,
          portfolioChange: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioChange.month,
          botChange: userState?.gd[getExchangeNames(currentOption, true)]?.botChange?.month,
          botChangeText: userState?.gd[getExchangeNames(currentOption, true)]?.botChangeText?.month
        });
      } else if (timeOption === '3M') {
        setCurrentGraphData(
          calculateCurrentGraphData(
            userState?.gd[getExchangeNames(currentOption, true)]?.data['3month'],
            userState?.bgd[getExchangeNames(currentOption, true)]?.data['3month'],
            userState?.mgd[getExchangeNames(currentOption, true)]?.data['3month'],
            userState?.gd[getExchangeNames(currentOption, true)]?.timeStamps['3month'],
            isBalanceLine,
            isMarginLine
          )
        );
        calculateMinMax(
          userState?.gd[getExchangeNames(currentOption, true)]?.data['3month'],
          userState?.bgd[getExchangeNames(currentOption, true)]?.data['3month'],
          userState?.mgd[getExchangeNames(currentOption, true)]?.data['3month']
        );
        setCurrentPerformance({
          balance:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.balancePerformance['3month'],
          balanceChange:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.balanceChange['3month'],
          margin:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.marginPerformance['3month'],
          marginChange:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.marginChange['3month'],
          portfolio: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioPerformance['3month'],
          bot: userState?.gd[getExchangeNames(currentOption, true)]?.botPerformance['3month'],
          portfolioChange: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioChange['3month'],
          botChange: userState?.gd[getExchangeNames(currentOption, true)]?.botChange['3month'],
          botChangeText: userState?.gd[getExchangeNames(currentOption, true)]?.botChangeText['3month']
        });
      } else if (timeOption === '1Y') {
        setCurrentGraphData(
          calculateCurrentGraphData(
            userState?.gd[getExchangeNames(currentOption, true)]?.data?.year,
            userState?.bgd[getExchangeNames(currentOption, true)]?.data?.year,
            userState?.mgd[getExchangeNames(currentOption, true)]?.data?.year,
            userState?.gd[getExchangeNames(currentOption, true)]?.timeStamps?.year,
            isBalanceLine,
            isMarginLine
          )
        );
        calculateMinMax(
          userState?.gd[getExchangeNames(currentOption, true)]?.data?.year,
          userState?.bgd[getExchangeNames(currentOption, true)]?.data?.year,
          userState?.mgd[getExchangeNames(currentOption, true)]?.data?.year
        );
        setCurrentPerformance({
          balance:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.balancePerformance.year,
          balanceChange:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.balanceChange?.year,
          margin:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.marginPerformance?.year,
          marginChange:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.marginChange?.year,
          portfolio: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioPerformance?.year,
          bot: userState?.gd[getExchangeNames(currentOption, true)]?.botPerformance?.year,
          portfolioChange: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioChange.year,
          botChange: userState?.gd[getExchangeNames(currentOption, true)]?.botChange?.year,
          botChangeText: userState?.gd[getExchangeNames(currentOption, true)]?.botChangeText?.year
        });
      } else if (timeOption === 'ALL') {
        setCurrentGraphData(
          calculateCurrentGraphData(
            userState?.gd[getExchangeNames(currentOption, true)]?.data?.all,
            userState?.bgd[getExchangeNames(currentOption, true)]?.data?.all,
            userState?.mgd[getExchangeNames(currentOption, true)]?.data?.all,
            userState?.gd[getExchangeNames(currentOption, true)]?.timeStamps?.all,
            isBalanceLine,
            isMarginLine
          )
        );
        calculateMinMax(
          userState?.gd[getExchangeNames(currentOption, true)]?.data?.all,
          userState?.bgd[getExchangeNames(currentOption, true)]?.data?.all,
          userState?.mgd[getExchangeNames(currentOption, true)]?.data?.all
        );
        setCurrentPerformance({
          balance:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.balancePerformance.all,
          balanceChange:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.balanceChange?.all,
          margin:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.marginPerformance?.all,
          marginChange:
            (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
            userState?.gd[getExchangeNames(currentOption, true)]?.marginChange?.all,
          portfolio: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioPerformance?.all,
          bot: userState?.gd[getExchangeNames(currentOption, true)]?.botPerformance?.all,
          portfolioChange: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioChange.all,
          botChange: userState?.gd[getExchangeNames(currentOption, true)]?.botChange?.all,
          botChangeText: userState?.gd[getExchangeNames(currentOption, true)]?.botChangeText?.all
        });
      }
    }
  }, [
    userState?.gd,
    isClicked,
    userState?.porfolioLoading,
    currentOption,
    timeOption,
    isBalanceLine,
    isMarginLine
  ]);

  // useEffect(() => {
  //   let status =
  //     currentGraphData?.datasets[0].data[0] <
  //     currentGraphData?.datasets[0].data[currentGraphData.datasets[0]?.data?.length - 1]
  //       ? 'up'
  //       : 'down';
  //   dispatch(setUserColorSet(status));
  // }, [currentGraphData]);

  // useEffect(() => {
  //   console.log('color status is ===> ', currentOption);
  // }, [currentOption]);
  // console.log('home authState', authState);

  const setBalanceLine = useCallback(isBalance => {
    setIsBalanceLine(isBalance);
  }, []);

  const setMarginLine = useCallback(isMargin => {
    setIsMarginLine(isMargin);
  }, []);

  const setChartData = useCallback(
    timeFrame => {
      setTimeOption(timeFrame);
      localStorage.setItem('tf', timeFrame);
      switch (timeFrame) {
        case '1D':
          if (!userState?.gd[getExchangeNames(currentOption, true)]?.data?.day) {
            dispatch(
              fetchUserPortfolioData(authState.id || profileState.id, calculateExchangeID(currentOption), 'day')
            );
          } else {
            setCurrentGraphData(
              calculateCurrentGraphData(
                userState?.gd[getExchangeNames(currentOption, true)]?.data?.day,
                userState?.bgd[getExchangeNames(currentOption, true)]?.data?.day,
                userState?.mgd[getExchangeNames(currentOption, true)]?.data?.day,
                userState?.gd[getExchangeNames(currentOption, true)]?.timeStamps?.day,
                isBalanceLine,
                isMarginLine
              )
            );
          }
          calculateMinMax(
            userState?.gd[getExchangeNames(currentOption, true)]?.data?.day,
            userState?.bgd[getExchangeNames(currentOption, true)]?.data?.day,
            userState?.mgd[getExchangeNames(currentOption, true)]?.data?.day
          );
          setCurrentPerformance({
            balance:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.balancePerformance.day,
            balanceChange:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.balanceChange?.day,
            margin:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.marginPerformance?.day,
            marginChange:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.marginChange?.day,
            portfolio: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioPerformance?.day,
            bot: userState?.gd[getExchangeNames(currentOption, true)]?.botPerformance?.day,
            portfolioChange: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioChange.day,
            botChange: userState?.gd[getExchangeNames(currentOption, true)]?.botChange?.day,
            botChangeText: userState?.gd[getExchangeNames(currentOption, true)]?.botChangeText?.day
          });
          break;
        case '1W':
          if (!userState?.gd[getExchangeNames(currentOption, true)]?.data?.week) {
            dispatch(
              fetchUserPortfolioData(authState.id || profileState.id, calculateExchangeID(currentOption), 'week')
            );
          } else {
            setCurrentGraphData(
              calculateCurrentGraphData(
                userState?.gd[getExchangeNames(currentOption, true)]?.data?.week,
                userState?.bgd[getExchangeNames(currentOption, true)]?.data?.week,
                userState?.mgd[getExchangeNames(currentOption, true)]?.data?.week,
                userState?.gd[getExchangeNames(currentOption, true)]?.timeStamps?.week,
                isBalanceLine,
                isMarginLine
              )
            );
          }
          calculateMinMax(
            userState?.gd[getExchangeNames(currentOption, true)]?.data?.week,
            userState?.bgd[getExchangeNames(currentOption, true)]?.data?.week,
            userState?.mgd[getExchangeNames(currentOption, true)]?.data?.week
          );
          setCurrentPerformance({
            balance:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.balancePerformance.week,
            balanceChange:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.balanceChange?.week,
            margin:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.marginPerformance?.week,
            marginChange:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.marginChange?.week,
            portfolio: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioPerformance?.week,
            bot: userState?.gd[getExchangeNames(currentOption, true)]?.botPerformance?.week,
            portfolioChange: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioChange.week,
            botChange: userState?.gd[getExchangeNames(currentOption, true)]?.botChange?.week,
            botChangeText: userState?.gd[getExchangeNames(currentOption, true)]?.botChangeText?.week
          });
          break;
        case '1M':
          if (!userState?.gd[getExchangeNames(currentOption, true)]?.data?.month) {
            dispatch(
              fetchUserPortfolioData(authState.id || profileState.id, calculateExchangeID(currentOption), 'month')
            );
          } else {
            setCurrentGraphData(
              calculateCurrentGraphData(
                userState?.gd[getExchangeNames(currentOption, true)]?.data?.month,
                userState?.bgd[getExchangeNames(currentOption, true)]?.data?.month,
                userState?.mgd[getExchangeNames(currentOption, true)]?.data?.month,
                userState?.gd[getExchangeNames(currentOption, true)]?.timeStamps?.month,
                isBalanceLine,
                isMarginLine
              )
            );
          }
          calculateMinMax(
            userState?.gd[getExchangeNames(currentOption, true)]?.data?.month,
            userState?.bgd[getExchangeNames(currentOption, true)]?.data?.month,
            userState?.mgd[getExchangeNames(currentOption, true)]?.data?.month
          );
          setCurrentPerformance({
            balance:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.balancePerformance.month,
            balanceChange:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.balanceChange?.month,
            margin:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.marginPerformance?.month,
            marginChange:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.marginChange?.month,
            portfolio: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioPerformance?.month,
            bot: userState?.gd[getExchangeNames(currentOption, true)]?.botPerformance?.month,
            portfolioChange: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioChange.month,
            botChange: userState?.gd[getExchangeNames(currentOption, true)]?.botChange?.month,
            botChangeText: userState?.gd[getExchangeNames(currentOption, true)]?.botChangeText?.month
          });
          break;
        case '3M':
          if (!userState?.gd[getExchangeNames(currentOption, true)]?.data['3month']) {
            dispatch(
              fetchUserPortfolioData(authState.id || profileState.id, calculateExchangeID(currentOption), '3month')
            );
          } else {
            setCurrentGraphData(
              calculateCurrentGraphData(
                userState?.gd[getExchangeNames(currentOption, true)]?.data['3month'],
                userState?.bgd[getExchangeNames(currentOption, true)]?.data['3month'],
                userState?.mgd[getExchangeNames(currentOption, true)]?.data['3month'],
                userState?.gd[getExchangeNames(currentOption, true)]?.timeStamps['3month'],
                isBalanceLine,
                isMarginLine
              )
            );
          }
          calculateMinMax(
            userState?.gd[getExchangeNames(currentOption, true)]?.data['3month'],
            userState?.bgd[getExchangeNames(currentOption, true)]?.data['3month'],
            userState?.mgd[getExchangeNames(currentOption, true)]?.data['3month']
          );
          setCurrentPerformance({
            balance:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.balancePerformance['3month'],
            balanceChange:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.balanceChange['3month'],
            margin:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.marginPerformance['3month'],
            marginChange:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.marginChange['3month'],
            portfolio: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioPerformance['3month'],
            bot: userState?.gd[getExchangeNames(currentOption, true)]?.botPerformance['3month'],
            portfolioChange: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioChange['3month'],
            botChange: userState?.gd[getExchangeNames(currentOption, true)]?.botChange['3month'],
            botChangeText: userState?.gd[getExchangeNames(currentOption, true)]?.botChangeText['3month']
          });
          break;
        case '1Y':
          if (!userState?.gd[getExchangeNames(currentOption, true)]?.data?.year) {
            dispatch(
              fetchUserPortfolioData(authState.id || profileState.id, calculateExchangeID(currentOption), 'year')
            );
          } else {
            setCurrentGraphData(
              calculateCurrentGraphData(
                userState?.gd[getExchangeNames(currentOption, true)]?.data?.year,
                userState?.bgd[getExchangeNames(currentOption, true)]?.data?.year,
                userState?.mgd[getExchangeNames(currentOption, true)]?.data?.year,
                userState?.gd[getExchangeNames(currentOption, true)]?.timeStamps?.year,
                isBalanceLine,
                isMarginLine
              )
            );
            calculateMinMax(
              userState?.gd[getExchangeNames(currentOption, true)]?.data?.year,
              userState?.bgd[getExchangeNames(currentOption, true)]?.data?.year,
              userState?.mgd[getExchangeNames(currentOption, true)]?.data?.year
            );
          }
          setCurrentPerformance({
            balance:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.balancePerformance.year,
            balanceChange:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.balanceChange?.year,
            margin:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.marginPerformance?.year,
            marginChange:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.marginChange?.year,
            portfolio: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioPerformance?.year,
            bot: userState?.gd[getExchangeNames(currentOption, true)]?.botPerformance?.year,
            portfolioChange: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioChange.year,
            botChange: userState?.gd[getExchangeNames(currentOption, true)]?.botChange?.year,
            botChangeText: userState?.gd[getExchangeNames(currentOption, true)]?.botChangeText?.year
          });
          break;
        case 'ALL':
          if (!userState?.gd[getExchangeNames(currentOption, true)]?.data?.all) {
            dispatch(
              fetchUserPortfolioData(authState.id || profileState.id, calculateExchangeID(currentOption), 'all')
            );
          } else {
            setCurrentGraphData(
              calculateCurrentGraphData(
                userState?.gd[getExchangeNames(currentOption, true)]?.data?.all,
                userState?.bgd[getExchangeNames(currentOption, true)]?.data?.all,
                userState?.mgd[getExchangeNames(currentOption, true)]?.data?.all,
                userState?.gd[getExchangeNames(currentOption, true)]?.timeStamps?.all,
                isBalanceLine,
                isMarginLine
              )
            );
          }
          calculateMinMax(
            userState?.gd[getExchangeNames(currentOption, true)]?.data?.all,
            userState?.bgd[getExchangeNames(currentOption, true)]?.data?.all,
            userState?.mgd[getExchangeNames(currentOption, true)]?.data?.all
          );
          setCurrentPerformance({
            balance:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.balancePerformance.all,
            balanceChange:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.balanceChange?.all,
            margin:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.marginPerformance?.all,
            marginChange:
              (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
              userState?.gd[getExchangeNames(currentOption, true)]?.marginChange?.all,
            portfolio: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioPerformance?.all,
            bot: userState?.gd[getExchangeNames(currentOption, true)]?.botPerformance?.all,
            portfolioChange: userState?.gd[getExchangeNames(currentOption, true)]?.portfolioChange.all,
            botChange: userState?.gd[getExchangeNames(currentOption, true)]?.botChange?.all,
            botChangeText: userState?.gd[getExchangeNames(currentOption, true)]?.botChangeText?.all
          });
          break;
        default:
          break;
      }
    },
    [currentOption, currentGraphData, userState?.gd, isBalanceLine, isMarginLine]
  );

  return (!userState?.assetLoading && !userState?.porfolioLoading) || userState?.currentGraphData ? (
    <Flex
      // w={['full', 'full', '90%', '90%', '95%', '95%']}
      // maxW="7xl"
      w="full"
      justify="space-between"
      position="relative"
      minH="100vh"
      // overflowY="hidden"
      margin="auto"
      mt={[10, 10, 20]}
    >
      <Flex
        w={['full', 'full', 'full', 'full', '70%', '70%']}
        direction="column"
        justify="flex-start"
        align={['center', 'center', 'center', 'flex-start']}
      >
        {/* {(authState.currentOption === 'Oanda Live' || authState.currentOption === 'Oanda Demo') && (
          <Flex
            w="95%"
            py={5}
            borderRadius="lg"
            bg={colorMode === 'dark' ? '#262626' : 'gray.50'}
            pl={4}
            mb={6}
            alignItems="center"
          >
            <WarningTwoIcon boxSize={4} mr={2} />
            <Text fontSize="13px">
              We&apos;re upgrading our system. Run summary charts might now work right now. They will be back soon.
            </Text>
          </Flex>
        )} */}

        {(authState.currentOption === 'Oanda Live' || authState.currentOption === 'Oanda Demo') &&
          isBetweenFriday5pmAndSunday5pm() && (
            <Flex
              w="95%"
              py={5}
              borderRadius="lg"
              bg={colorMode === 'dark' ? '#262626' : 'gray.50'}
              pl={4}
              mb={6}
              alignItems="center"
            >
              <TimeIcon boxSize={4} mr={2} />
              <Text fontSize="13px">Forex markets are currently closed and will reopen on Sunday at 5pm EST.</Text>
            </Flex>
          )}

        {/* No Exchange Display was here */}

        {/* <Flex w="full" mt={12} justifyContent="space-between">
          <FeatureCard
            gradient="linear(to-r, red.600, gray.900)"
            title="Automated Trades"
            details="Epicflow.io allows you to create bots within seconds. Easily adjusted advanced features lets you generate
        various trading bots"
          />
          <FeatureCard
            gradient="linear(to-r, purple.200, red.600)"
            title="Portfolio Tracking"
            details="See everything in your portfolio at a glance. Follow up performance of your bots in detail."
          />
          <FeatureCard
            gradient="linear(to-r, yellow.500, red.500)"
            title="Social Trading"
            details="Social trading lets you see other traders' strategies. Look at the top crypto traders' bots then imitate their trading strategies."
          />
        </Flex> */}
        {authState.exchanges.length > 0 ? (
          <Flex w="95%" ml={0} justify="space-between" align="center" my={0}>
            <Flex
              // mb={10}
              // pb={6}
              w="full"
              justify="space-between"
              align="flex-start"
            >
              {currentPerformance && (
                <PortfolioTitle
                  oanda={currentOption === 'Oanda Live' || currentOption === 'Oanda Demo'}
                  // usd={displayValue ? `$${convertToInternationalCurrencySystem(displayValue)}` : '  '}
                  margin={isMarginLine}
                  balance={isBalanceLine}
                  marginchange={
                    (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
                    currentPerformance?.margin
                  }
                  portmargin={
                    (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
                    currentPerformance?.marginChange
                  }
                  marginAvailable={
                    (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
                    userState?.oandaData &&
                    userState?.oandaData.marginAvailable
                  }
                  marginperfcolor={
                    (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
                    currentPerformance?.marginChange >= 0
                      ? statusColors.up
                      : statusColors.down
                  }
                  balancechange={
                    (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') &&
                    currentPerformance?.balance
                  }
                  portbalancecolor={currentPerformance?.balanceChange >= 0 ? statusColors.up : statusColors.down}
                  portbalance={currentPerformance?.balanceChange}
                  dynamicCurrencyFormatter
                  crypto={
                    userState?.portfolioTotalAmounts &&
                    currentOption &&
                    `${usdFormatter.format(userState?.portfolioTotalAmounts[currentOption])}` !== '$NaN'
                      ? `${usdFormatter.format(userState?.portfolioTotalAmounts[currentOption])}`
                      : ' '
                  }
                  currency={
                    userState?.portfolioTotalAmounts &&
                    currentOption &&
                    !`${dynamicCurrencyFormatter(userState?.baseCurrency).format(
                      userState?.portfolioTotalAmounts[currentOption]
                    )}`.includes('NaN')
                      ? `${dynamicCurrencyFormatter(userState?.baseCurrency).format(
                          userState?.portfolioTotalAmounts[currentOption]
                        )}`
                      : ' '
                  }
                  title="Account value"
                  ma={
                    (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') && userState?.oandaData
                      ? userState?.oandaData.marginAvailable
                      : ''
                  }
                  mu={
                    (currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') && userState?.oandaData
                      ? userState?.oandaData.marginUsed
                      : ''
                  }
                  portchange={currentPerformance?.portfolioChange}
                  botchangedescription={currentPerformance?.botChangeText}
                  // portChangeDescription={userState?.gd[getExchangeNames(currentOption)]?.portChangeText}
                  botchange={currentPerformance?.botChange}
                  portperf={currentPerformance?.portfolio}
                  botperf={currentPerformance?.bot}
                  portperfcolor={currentPerformance?.portfolioChange >= 0 ? statusColors.up : statusColors.down}
                  botperfcolor={currentPerformance?.botChange >= 0 ? statusColors.up : statusColors.down}
                  // portperfcolor={epicFlowGradientColors.left}
                  // botperfcolor={epicFlowGradientColors.left}
                  // portPerf={userState?.gd[getExchangeNames(currentOption, true)]?.portfolioPerformance}
                  // portPerfColor={
                  //   userState?.gd[getExchangeNames(currentOption, true)]?.portfolioPerformance >= 0
                  //     ? statusColors.up
                  //     : statusColors.down
                  // }
                  timerange={convertTimeFrameToReadable(timeOption)}
                  isExchangeArray={authState.exchanges.length > 1}
                  menuitems={exchanges}
                  autoHeight={exchanges.length <= 7}
                  value={currentOption}
                  selected={handleCurrentGraphData}
                />
              )}
              {/* {authState.exchanges.length > 1 && (
                <Dropdown
                  menuitems={exchanges}
                  fontSize="xs"
                  functional
                  flexW={32}
                  py="18px"
                  autoHeight={exchanges.length <= 7}
                  selected={handleCurrentGraphData}
                  value={currentOption}
                  radius="full"
                  shadow="sm"
                  fontWeight="medium"
                />
              )} */}
            </Flex>
          </Flex>
        ) : null}
        <Box w="95%" ml={0} my={0} mt={4} minHeight="xs" overflowX="hidden" pb={6}>
          {currentGraphData?.labels.length > 0 ? (
            <Line data={currentGraphData} options={options} ref={_chartRef}></Line>
          ) : null}
        </Box>
        {authState.exchanges.length > 0 ? (
          <TimeBar
            w="95%"
            // m="auto"
            balance={setBalanceLine}
            marginAvailable={setMarginLine}
            setChartData={setChartData}
            exchange={authState.currentOption}
            // color={
            //   currentGraphData?.datasets[0].data[0] <
            //   currentGraphData?.datasets[0].data[currentGraphData.datasets[0]?.data?.length - 1]
            //     ? '#48BB78'
            //     : '#ff5400'
            // }
            color="#4361ee"
          />
        ) : null}
        {(authState.currentOption === 'Oanda Live' || authState.currentOption === 'Oanda Demo') &&
          userState?.baseCurrency && (
            <MarginCard
              marginAvailable={userState?.oandaData?.marginAvailable}
              nav={userState?.oandaData?.TOTAL}
            />
          )}
        {/* <Banner /> */}
        {authState.exchanges.length > 0 &&
        portfolioData &&
        authState.currentOption !== 'Oanda Demo' &&
        authState.currentOption !== 'Oanda Live' ? (
          <CardContainer
            exchange={`( ${currentOption} )`}
            data={portfolioData.sort((a, b) => b.balance - a.balance)}
          />
        ) : null}
        {authState.exchanges.length > 0 &&
        portfolioData &&
        (authState.currentOption === 'Oanda Demo' || authState.currentOption === 'Oanda Live') &&
        userState?.baseCurrency ? (
          <OandaCardContainer data={handleOandaData(userState?.oandaData?.positions)} />
        ) : null}
        {(currentOption === 'Oanda Live' || currentOption === 'Oanda Demo') && <FxCalendarCard />}
      </Flex>
      {/* {authState.isExchangesChecked && authState.exchanges.length === 0 && <WelcomeNewsCard />} */}
      {(authState.currentOption === 'Oanda Demo' || authState.currentOption === 'Oanda Live') &&
        socketState.isSocketOn &&
        userState?.oandaData &&
        userState?.baseCurrency && (
          <FxAutomationWizard
            fixed
            // placeholder={
            //   userState?.assetData[calculateExchangeKey(currentOption).key][
            //     calculateExchangeKey(currentOption).exc
            //   ][1].free
            // }
            // placeholder={placeholder}
            // maxInvestmentAmount={placeholder}
            // placeholder={placeholder}
            // maxInvestmentAmount={placeholder}
            // sliderColor={userState?.colorStatus === 'up' ? statusColors.upFaded : statusColors.downFaded}
            // bg={userState?.colorStatus === 'up' ? statusColors.up : statusColors.down}
            // bg={epicFlowGradientColors.left}
            // sliderColor={epicFlowGradientColors.left}
            bg={colorMode === 'light' ? 'black' : 'white'}
            sliderColor="black"
          />
        )}
      {userState?.assetData?.length > 0 && userState?.isInitialized && currentOption && socketState.isSocketOn ? (
        <Flex direction="column" alignItems="flex-end" h="full">
          {/* {userState?.assetData?.length > 0 && currentOption && socketState.isSocketOn ? ( */}
          <Flex pos="relative">
            <AutomationWizard
              fixed
              // placeholder={
              //   userState?.assetData[calculateExchangeKey(currentOption).key][
              //     calculateExchangeKey(currentOption).exc
              //   ][1].free
              // }
              // placeholder={placeholder}
              // maxInvestmentAmount={placeholder}
              // placeholder={placeholder}
              // maxInvestmentAmount={placeholder}
              // sliderColor={userState?.colorStatus === 'up' ? statusColors.upFaded : statusColors.downFaded}
              // bg={userState?.colorStatus === 'up' ? statusColors.up : statusColors.down}
              // bg={epicFlowGradientColors.left}
              // sliderColor={epicFlowGradientColors.left}
              bg={colorMode === 'light' ? 'black' : 'white'}
              sliderColor="black"
            />
          </Flex>
          {treeData.length > 0 && (
            <Flex
              direction="column"
              w="xs"
              alignItems="center"
              mt={12}
              justify="flex-start"
              borderRadius={16}
              borderWidth={1}
              boxShadow="lg"
              display={['none', 'none', 'none', 'none', 'flex', 'flex']}
              pb={6}
            >
              <Heading size="sm" w="85%" textAlign="left" mt={5} mb={5}>
                Asset Distribution
              </Heading>
              {/* <Divider mb={2} /> */}
              <Apex
                height={320}
                width={300}
                type="treemap"
                series={[
                  {
                    data: treeData
                  }
                ]}
                options={{
                  tooltip: {
                    style: {
                      color: 'red'
                    },
                    y: {
                      formatter: v => v + '%'
                    }
                  },
                  theme: {
                    mode: colorMode === 'light' ? 'light' : 'dark'
                  },
                  // colors: [userState?.colorStatus === 'up' ? statusColors.up : statusColors.down, '#ffa200'],
                  colors: ['#FEA62F', '#ffa200'],
                  dataLabels: {
                    style: {
                      fontSize: '14px'
                    }
                  },
                  fill: {
                    opacity: 1
                  },
                  plotOptions: {
                    treemap: {
                      useFillColorAsStroke: false,
                      shadeIntensity: 0.3
                    }
                  },
                  states: {
                    hover: {
                      filter: {
                        type: 'none'
                      }
                    }
                  },
                  chart: {
                    height: 320,
                    offsetX: treeData.length > 5 ? 0 : 12.5,
                    background: 'transparent',
                    toolbar: {
                      show: true,
                      tools: {
                        download: false
                      }
                    }
                  }
                }}
              />
            </Flex>
          )}
          {pieData.series?.length > 0 && currentOption && (
            <Flex
              direction="column"
              w="xs"
              alignItems="center"
              mt={12}
              justify="flex-start"
              borderRadius={16}
              borderWidth={1}
              boxShadow="lg"
              display={['none', 'none', 'none', 'none', 'flex', 'flex']}
              pb={6}
            >
              <Heading size="sm" w="85%" textAlign="left" mt={5} mb={5}>
                Epicflow Earnings
              </Heading>
              <Text mb={2} mt={2} fontSize="0.825rem" textAlign="left" w="85%">
                Total Earnings: {`${pieData.total} ${currentOption === 'Binance US' ? 'USD' : 'USDT'}`}
              </Text>
              <Apex
                type="pie"
                height={320}
                width={300}
                series={pieData?.series}
                options={{
                  dataLabels: {
                    enabled: true,
                    formatter: function (val, opt) {
                      return String(Number(val).toFixed(2)) + '%' + ' ' + pieData?.labels[opt.seriesIndex];
                    }
                  },
                  fill: {
                    // colors: [userState?.colorStatus === 'up' ? statusColors.up : statusColors.down]
                  },
                  legend: {
                    show: false
                  },
                  tooltip: {
                    y: {
                      // formatter: v => `${v} ${currentOption === 'Binance US' ? 'USD' : 'USDT'}`
                      formatter: (v, y) => {
                        return `${
                          pieData?.labels[y.dataPointIndex] === 'USD' ||
                          pieData?.labels[y.dataPointIndex] === 'USDT'
                            ? ''
                            : pieData?.amounts[y.dataPointIndex]
                        } 
                        ${
                          pieData?.labels[y.dataPointIndex] === 'USD' ||
                          pieData?.labels[y.dataPointIndex] === 'USDT'
                            ? ''
                            : currentOption === 'Binance US'
                            ? ' - USD:'
                            : ' - USDT:'
                        } ${pieData?.series[y.dataPointIndex]}`;
                      }
                    }
                  },
                  plotOptions: {
                    useFillColorAsStroke: true,
                    pie: {
                      donut: {
                        size: '100%',
                        label: {
                          show: true
                        }
                      }
                    }
                  },
                  labels: pieData?.labels
                }}
              />
            </Flex>
          )}
        </Flex>
      ) : null}
    </Flex>
  ) : (
    <Flex w="full" minH="100vh" justifyContent="center" alignItems="center">
      <Lottie
        options={{
          loop: true,
          path: 'https://lottie.host/81c5c7b2-2ef6-4dff-a1b3-8430ac3a60e1/6xx96QsW3x.json'
        }}
        height={40}
        width={40}
      />
    </Flex>
  );
};

export default Home;
