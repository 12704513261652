import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Chart, Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';

const GraphDemo = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const authState = useSelector(state => state.auth);

  const [avgCost, setAvgCost] = useState('');

  const [price, setPrice] = useState('');

  const [exit, setExit] = useState('');

  const handleHover = useCallback((e, item) => {
    if (item.length) {
      const v = item[0]._chart.config.data.datasets[0].data[item[0]._index];
      const y = item[0]._chart.config.data.datasets[1].data[item[0]._index];
      const z = item[0]._chart.config.data.datasets[2].data[item[0]._index];
      //   const z = item[0]._chart.config.data.datasets[2].data[item[0]._index];
      setAvgCost(v);
      if (y) {
        setPrice(y);
      } else if (z) {
        setExit(z);
      } else {
        setPrice('');
        setExit('');
      }
      //   setActualPrice(z);
      //   console.log(v, y, z);
      return v;
    }
  }, []);

  const [options, setOptions] = useState({
    legend: {
      display: false
    },
    layout: {
      padding: {
        top: 12,
        right: 6
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      bodyFontSize: 14,
      titleMarginBottom: 4,
      titleFontSize: 12,
      backgroundColor: colorMode === 'light' ? '#fff' : '#1E2025',
      bodyFontColor: colorMode === 'light' ? '#2D3748' : '#CBD5E0',
      borderColor: colorMode === 'light' ? '#CBD5E0' : '#4A5568',
      titleFontColor: colorMode === 'light' ? '#000' : '#fff',
      footerFontColor: colorMode === 'light' ? '#000' : '#fff',
      borderWidth: 0.5,
      xPadding: 24,
      yPadding: 12,
      padding: {
        top: 18,
        left: 20,
        right: 20
      },
      displayColors: false,
      callbacks: {
        label: function (t) {
          // let xLabel = t.xLabel;
          // let yLabel = t.yLabel;
          return;
          // console.log(yLabel)
        },
        title: function (context) {
          return `Avg Cost: ${context[0].value}\n\nPrice: ${context[1].value}\n\n${new Date(
            Number(context[0].label)
          ).toLocaleDateString()}`;
        },
        footer: function (context) {
          return new Date(Number(context[0].label)).toLocaleTimeString();
        }
      }
    },
    // animation: {
    //   duration: 0
    // },
    // responsiveAnimationDuration: 0,
    hover: {
      mode: 'index',
      intersect: false,
      animationDuration: 0,
      onHover: handleHover
    },
    // elements: {
    //   line: {
    //     tension: 0
    //   }
    // },
    scales: {
      xAxes: [
        {
          ticks: {
            display: false //this will remove only the label
          },
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            display: true
          },
          gridLines: {
            display: true
          },
          id: 'y1'
        }
      ]
    },
    maintainAspectRatio: false
  });

  const calculateCurrentGraphData = (avgData, priceData, actualPriceData, exitData, timeStamps) => {
    const graphdata = {
      labels: [...timeStamps],
      datasets: [
        {
          label: '',
          data: [...avgData],
          fill: true,
          backgroundColor: 'transparent',
          borderColor: '#48BB78',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: '#48BB78',
          pointHoverBorderColor: '#fff',
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          borderWidth: 2,
          yAxisID: 'y1'
        },
        {
          type: 'scatter',
          label: '',
          data: [...priceData],
          fill: true,
          backgroundColor: 'transparent',
          borderColor: '#ff5400',
          pointBackgroundColor: '#ff5400',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#ff5400',
          pointHoverBorderColor: '#fff',
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          borderWidth: 1
        },
        {
          type: 'scatter',
          label: '',
          data: [...exitData],
          fill: true,
          backgroundColor: 'transparent',
          borderColor: '#3d0066',
          pointBackgroundColor: '#3d0066',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#3d0066',
          pointHoverBorderColor: '#fff',
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          borderWidth: 1
        },
        {
          label: '',
          data: [...actualPriceData],
          fill: true,
          backgroundColor: 'transparent',
          borderColor: '#00a8e8',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          //   pointHoverBackgroundColor: 'blue',
          //   pointHoverBorderColor: '#fff',
          //   pointHoverRadius: 6,
          //   pointHoverBorderWidth: 1,
          //   pointRadius: 10,
          borderWidth: 2
        }
      ]
    };
    return graphdata;
  };

  useEffect(() => {
    setOptions({
      ...options,
      tooltips: {
        ...options.tooltips,
        backgroundColor: colorMode === 'light' ? '#fff' : '#1E2025',
        bodyFontColor: colorMode === 'light' ? '#2D3748' : '#CBD5E0',
        borderColor: colorMode === 'light' ? '#CBD5E0' : '#4A5568',
        titleFontColor: colorMode === 'light' ? '#000' : '#fff',
        footerFontColor: colorMode === 'light' ? '#000' : '#fff'
      }
    });
  }, [colorMode]);

  return (
    <Modal
      isOpen={props.isopen}
      onClose={props.onclose}
      isCentered
      returnFocusOnClose={false}
      motionPreset="slideInBottom"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent borderRadius="2xl" bg={colorMode === 'light' ? 'white' : '#1f2124'}>
        <ModalCloseButton
          _focus={{
            outline: 'none'
          }}
        />
        <ModalHeader fontWeight="bold" fontSize="xl" mt={10} pb={0} pt={0}>
          Graph Demo
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={2}>
          {/* <Box w="95%" ml={0} my={0} mt={4} minHeight="xs" overflowX="hidden" pb={6}>
              <Line data={currentGraphData} options={options}></Line>
            </Box> */}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GraphDemo;
