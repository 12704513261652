import CryptoJS from 'crypto-js';

const secretKeyValue = process.env.REACT_APP_UUID_SECRET;
const secretKey = CryptoJS.enc.Utf8.parse(secretKeyValue);

export const handleEncryption = message => {
  const encryptedMessage = CryptoJS.AES.encrypt(message, `${secretKey}`).toString();
  return encryptedMessage;
};

export const handleDecryption = message => {
  // URL decode the encrypted message
  let decodedMessage = '';
  try {
    decodedMessage = decodeURIComponent(message);
  } catch (error) {
    console.error('handleDecryption - Error decoding message:', error);
    decodedMessage = message;
  }

  // Base64 decode the message
  let rawData;
  try {
    rawData = CryptoJS.enc.Base64.parse(decodedMessage);
  } catch (error) {
    console.error('handleDecryption - Error Base64 decoding message:', error);
    return null; // Return null if Base64 decoding fails
  }
  // Extract IV and ciphertext
  let iv, ciphertext;
  try {
    iv = CryptoJS.lib.WordArray.create(rawData.words.slice(0, 4)); // First 16 bytes (4 words)
    ciphertext = CryptoJS.lib.WordArray.create(rawData.words.slice(4));
  } catch (error) {
    console.error('handleDecryption - Error extracting IV and ciphertext:', error);
    return null; // Return null if IV/ciphertext extraction fails
  }
  // Decrypt the message
  let decryptedBytes;
  try {
    decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, secretKey, { iv, mode: CryptoJS.mode.CBC });
  } catch (error) {
    console.error('handleDecryption - Error during decryption:', error);
    return null; // Return null if decryption fails
  }

  let decryptedMessage;
  try {
    decryptedMessage = decryptedBytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error('handleDecryption - Error converting bytes to string:', error);
    return null; // Return null if conversion fails
  }
  // console.log('handleDecryption - decrypted message:', decryptedMessage);
  return decryptedMessage;
};
