import Amplify, { Auth, Cache } from 'aws-amplify';
import { io } from 'socket.io-client';

import ALL from '../../assets/all.png';
import EURO from '../../assets/euro.png';
import USD from '../../assets/usd.svg';
import list from '../../constants/binance.json';
//import coinData from '../../constants/data.json';
import imageData from '../../constants/images.json';
import { store } from '../../index';
import axios from '../../utilities/axios';
import { handleDecryption } from '../../utilities/encryption';
import eventEmitter from '../../utilities/eventEmitter';
import * as actions from '../actions/actionTypes';

const API_URL = process.env.REACT_APP_API_URL;

//const DEV_API_URL = 'http://localhost:5020/';

const socketUrl = 'ws://ec2-3-93-220-251.compute-1.amazonaws.com:8080';

const handleLogout = async () => {
  let keysToRemove = ['token', 'ef_user_id', 'browser_id', 'token_exp'];
  // dispatch(stopSocket(authState.id, socketState?.socket, localStorage.getItem('browser_id')));
  // dispatch(stopSocket(handleDecryption(authState.id), socketState?.socket, localStorage.getItem('browser_id')));
  for (let key of keysToRemove) {
    localStorage.removeItem(key);
  }
  await Auth.signOut().then(() => window.location.replace('/login'));
  // window.location.assign('/login');
  // history.push('/login');
};

const fetchUserAssetDataStart = () => {
  return {
    type: actions.FETCH_USER_ASSET_DATA_START,
    loading: true
  };
};

const fetchUserAssetDataError = error => {
  return {
    type: actions.FETCH_USER_ASSET_DATA_FAIL,
    error: error,
    loading: false
  };
};

const fetchUserAssetDataSuccess = (assetData, portfolioData, total, oandaData = null) => {
  return {
    type: actions.FETCH_USER_ASSET_DATA_SUCCESS,
    assetData: assetData,
    portfolioTotalAmounts: total,
    initialPortfolioData: portfolioData,
    isAssetDataSocketOn: true,
    oandaData: oandaData,
    loading: false
  };
};

const fetchUserPortfolioDataStart = () => {
  return {
    type: actions.FETCH_USER_PORTFOLIO_DATA_START,
    isInitialized: false,
    loading: true
  };
};

const fetchUserPortfolioDataError = error => {
  return {
    type: actions.FETCH_USER_PORTFOLIO_DATA_FAIL,
    error: error,
    loading: false,
    isInitialized: false
  };
};

const fetchUserPortfolioDataSuccess = (graphData, secondGraphData = null, thirdGraphData = null, id) => {
  return {
    type: actions.FETCH_USER_PORTFOLIO_DATA_SUCCESS,
    graphData: graphData,
    secondGraphData: secondGraphData,
    thirdGraphData: thirdGraphData,
    isInitialized: true,
    currentGraphData: true,
    initialValues:
      graphData?.data?.length > 0
        ? {
            [graphData.exchange]: [...graphData?.data]?.pop()
          }
        : {},
    gd: graphData,
    id: id,
    // displayValue: [...graphData.combined.data].pop(),
    loading: false
  };
};
const setUserBaseCurrency = baseCurrency => {
  return {
    type: actions.SET_USER_BASE_CURRENCY,
    baseCurrency: baseCurrency
  };
};

const getTotalEarningsStart = () => {
  return {
    type: actions.FETCH_TOTAL_EARNINGS_START,
    loading: true
  };
};

const getTotalEarningsFail = err => {
  return {
    type: actions.FETCH_TOTAL_EARNINGS_FAIL,
    loading: false,
    error: err
  };
};

const getTotalEarningsSuccess = data => {
  return {
    type: actions.FETCH_TOTAL_EARNINGS_SUCCESS,
    data: data,
    loading: false
  };
};

export const fetchUserAssetData = (uuid, exchangeName, exchangeID) => {
  // console.log('in user.js :: fetchUserAssetData method');
  return async dispatch => {
    const body = {
      id: uuid,
      exchange_id: exchangeID
    };
    const sio = store.getState().socket.socket;
    const baseCurrency = store.getState().user.baseCurrency;
    // console.log('in user.js :: sio : ', sio);
    const exp = Number(localStorage.getItem('token_exp'));
    if (exchangeID === 5 || exchangeID === 6) {
      dispatch(fetchUserAssetDataStart());
      const assetResponse = await axios.post(`${API_URL}assets`, body);
      const assetData = assetResponse.data;
      let oandaData = {};
      for (let exc in assetData) {
        if (exc == exchangeID) {
          oandaData = assetData[exc];
        }
      }
      let ft = {};
      let exc = '';
      if (exchangeID === 5) {
        exc = 'oanda_demo';
        ft = {
          ['Oanda Demo']: oandaData.TOTAL
        };
      } else {
        exc = 'oanda_live';
        ft = {
          ['Oanda Live']: oandaData.TOTAL
        };
      }
      dispatch(fetchUserAssetDataSuccess([], [], ft, oandaData));

      // console.log('in user.js :: fetchUserAssetData :: uuid : ', uuid);
      const channel = `user_specific_data_${exchangeID}_${handleDecryption(uuid)}`;

      sio.onmessage = event => {
        const message = JSON.parse(event.data);
        console.log('in user.js :: fetchUserAssetData method :: user_specific_data_ :: message', message);

        if ('channel_data' in message) {
          const fetched_channel = `${message.channel_data.event}${
            message.channel_data.exchange_id
          }_${handleDecryption(message.channel_data.uuid)}`;
          console.log('in user.js :: fetched_channel', fetched_channel);
          if (fetched_channel === channel) {
            eventEmitter.emit('userData', message);
            if (!baseCurrency) {
              dispatch(setUserBaseCurrency(message.user_history_data.baseCurrency));
            }
            if (new Date().getTime() >= exp || !exp) {
              return handleLogout();
            }
            let ft = {};
            if (exchangeID === 5) {
              ft = {
                ['Oanda Demo']: message.user_history_data.TOTAL
              };
            } else {
              ft = {
                ['Oanda Live']: message.user_history_data.TOTAL
              };
            }
            dispatch(fetchUserAssetDataSuccess([], [], ft, message.user_history_data));
          }
        }
      };
    } else {
      dispatch(fetchUserAssetDataStart());
      const mediaResponse = await axios.get(`${API_URL}getMediaDetails`);
      // console.log('media ', mediaResponse.data);
      const assetResponse = await axios.post(`${API_URL}assets`, body);
      const assetData = assetResponse.data;
      if (!assetData) {
        console.log('ad');
        return dispatch(fetchUserAssetDataError('No exchange found in user account'));
      }
      if (assetData?.combined?.TOTAL === 0) {
        console.log('bd');
        return dispatch(fetchUserAssetDataError('No asset found in user account'));
      }
      if (assetData?.error === 'Exchange needs to be added') {
        return dispatch(fetchUserAssetDataError(assetData.error));
      }
      let symbols = [];
      for (let exchange in assetData) {
        for (let coin in assetData[exchange]) {
          if (coin !== 'TOTAL' && coin !== 'Exchange_id' && !symbols.includes(coin)) {
            symbols.push(coin);
          }
        }
      }
      let ids = [];
      for (let ticker of symbols) {
        for (let asset in mediaResponse.data) {
          if (ticker.toLowerCase() === asset.toLowerCase()) {
            ids.push(asset);
          }
        }
      }
      let images = [];
      for (let id of ids) {
        // if (id === 'eur') continue;
        // console.log(mediaResponse.data[id]);
        images.push(mediaResponse.data[id]);
        // images.push(imageData.find(image => image.id === id));
      }

      // ids = ids.join('%2C');
      // let url = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${ids}&order=market_cap_desc&per_page=100&page=1&sparkline=true`;
      // const response = await axios.get(url);
      // const data = response.data;
      const geckoData = [];
      const finalData = [];
      for (let exchange in assetData) {
        let combinedData = {};
        let exchangeArray = [];
        for (let symbol of symbols) {
          let upSymbol = symbol.toUpperCase();
          // if (asset.market_cap === 0) {
          //   continue;
          // }
          //TODO

          // console.log(mediaResponse.data[symbol.toUpperCase()]);
          // if (mediaResponse.data[symbol.toUpperCase()] !== {}) {
          //   console.log(symbol.toUpperCase());
          //   continue;
          // }
          if (assetData[exchange][upSymbol] && upSymbol !== 'USD' && upSymbol !== 'EUR') {
            // console.log('heyya', assetData[exchange][upSymbol]);
            exchangeArray.push({
              allocation: assetData[exchange][upSymbol].base_percentage,
              free: assetData[exchange][upSymbol].free,
              balance: assetData[exchange][upSymbol].base_value,
              total: assetData[exchange][upSymbol].total,
              current_price: assetData[exchange][upSymbol].base_price,
              image: mediaResponse.data[symbol.toUpperCase()]?.image
                ? mediaResponse.data[symbol.toUpperCase()]?.image
                : '',
              // image: images.find(img => img.id === list.find(item => item.symbol === symbol.toLowerCase()).id).image,
              symbol: upSymbol,
              // sparkline_in_7d: asset.sparkline_in_7d,
              name: mediaResponse.data[symbol.toUpperCase()]?.name
                ? mediaResponse.data[symbol.toUpperCase()]?.name
                : symbol.toUpperCase()
              // name: list.find(item => item.symbol === symbol.toLowerCase()).name
              // id: asset.id
            });
          }
        }

        if (assetData[exchange]['USD']) {
          exchangeArray.push({
            // allocation: Number(assetData[exchange]['USD'].base_percentage.toFixed(2)),
            // balance: Number(assetData[exchange]['USD'].base_value.toFixed(2)),
            // total: assetData[exchange]['USD'].total,
            // free: assetData[exchange]['USD'].free,
            // locked: assetData[exchange]['USD'].locked,
            allocation: assetData[exchange]['USD'].base_percentage,
            free: assetData[exchange]['USD'].free,
            balance: assetData[exchange]['USD'].base_value,
            total: assetData[exchange]['USD'].total,
            current_price: 1,
            price_change_percentage_24h: 0,
            name: 'US Dollar',
            image: USD,
            symbol: 'usd',
            id: 'usdollar',
            sparkline_in_7d: {
              price: new Array(169).fill(1, 0, 169)
            }
          });
        } else if (assetData[exchange]['EUR']) {
          exchangeArray.push({
            // allocation: Number(assetData[exchange]['USD'].base_percentage.toFixed(2)),
            // balance: Number(assetData[exchange]['USD'].base_value.toFixed(2)),
            // total: assetData[exchange]['USD'].total,
            // free: assetData[exchange]['USD'].free,
            // locked: assetData[exchange]['USD'].locked,
            allocation: assetData[exchange]['EUR'].base_percentage,
            free: assetData[exchange]['EUR'].free,
            balance: assetData[exchange]['EUR'].base_value,
            total: assetData[exchange]['EUR'].base_price,
            current_price: 1,
            price_change_percentage_24h: 0,
            name: 'Euro',
            image: EURO,
            symbol: 'eur',
            id: 'eur',
            sparkline_in_7d: {
              price: new Array(169).fill(1, 0, 169)
            }
          });
        }
        if (exchange === '1') {
          exchange = 'binanceus';
        } else if (exchange === '3') {
          exchange = 'binancecom';
        }
        combinedData = {
          ...combinedData,
          [exchange]: [...exchangeArray]
        };
        finalData.push(combinedData);
      }
      let combinedKey;
      for (let i = 0; i < finalData.length; i++) {
        if (finalData[i]['combined']) {
          combinedKey = i;
        }
      }
      let keys = {
        1: 'Binance US',
        2: 'Coinbase',
        3: 'Binance',
        combined: 'Total'
      };
      let ft = {};
      for (let key in keys) {
        if (assetData[key]) {
          ft = {
            ...ft,
            [keys[key]]: assetData[key].TOTAL.toFixed(2)
          };
        }
      }
      if (exchangeName === 'binance') {
        exchangeName = 'binancecom';
      }
      let key;
      for (let exchange of finalData) {
        if (Object.keys(exchange).includes(exchangeName)) {
          key = finalData.indexOf(exchange);
        }
      }
      // delete finalData
      let tempFd = [];
      for (let item of finalData) {
        if (Object.keys(item).includes(exchangeName) || Object.keys(item).includes('combined')) tempFd.push(item);
      }
      dispatch(fetchUserAssetDataSuccess(tempFd, tempFd[key][exchangeName], ft));

      // dispatch(initilizeSocket(sio));

      let currentPrices = [];
      const channel = `user_specific_data_${exchangeID}_${handleDecryption(uuid)}`;

      sio.onmessage = event => {
        const message = JSON.parse(event.data);

        currentPrices = [];

        if ('channel_data' in message) {
          eventEmitter.emit('userData', message);
          let fetched_channel = `${message.channel_data.event}${
            message.channel_data.exchange_id
          }_${handleDecryption(message.channel_data.uuid)}`;

          if (fetched_channel === channel) {
            if (new Date().getTime() >= exp || !exp) {
              return handleLogout();
            }
            let combinedIdx = 0;
            for (let key of finalData) {
              if (Object.keys(key).includes('combined')) {
                combinedIdx = finalData.indexOf(key);
              }
            }
            let tempData = [
              {
                [exchangeName]: []
              },
              {
                combined: finalData[combinedIdx].combined
              }
            ];
            let total = {};
            for (let key in keys) {
              if (assetData[key]) {
                total = {
                  ...total,
                  [keys[key]]: message.user_history_data.TOTAL.toFixed(2)
                };
              }
            }
            let info = {};
            for (let key of finalData) {
              if (Object.keys(key).includes(exchangeName)) {
                for (let item of key[exchangeName]) {
                  for (let coin in message.user_history_data) {
                    if (coin.toLowerCase() === item.symbol.toLowerCase()) {
                      for (let price of currentPrices) {
                        if (Object.keys(price).includes(coin)) {
                          info = {
                            ...item,
                            allocation: message.user_history_data[coin].base_percentage,
                            free: message.user_history_data[coin].free,
                            balance: message.user_history_data[coin].base_value,
                            total: message.user_history_data[coin].total,
                            current_price: price[coin]
                          };
                        }
                      }
                    }
                  }
                  if (!tempData[0][exchangeName].includes(info)) {
                    tempData[0][exchangeName].push(info);
                  }
                }
              }
            }
            if (Object.keys(tempData[0][exchangeName][0]).length !== 0) {
              dispatch(fetchUserAssetDataSuccess(tempData, tempData[0][exchangeName], total));
            }
          }
        } else {
          eventEmitter.emit('exchangeData', message);
          for (let key of finalData) {
            if (Object.keys(key).includes(exchangeName)) {
              for (let item of key[exchangeName]) {
                for (let coin in message.base_price_data) {
                  if (coin.toLowerCase() === item.symbol.toLowerCase()) {
                    // cp = message.base_price_data[coin];
                    currentPrices.push({ [coin]: message.base_price_data[coin] });
                  }
                }
              }
            }
          }
        }
      };
    }
  };
};

const setColorSetSuccess = status => {
  return {
    type: actions.USER_SET_COLORSET,
    colorStatus: status
  };
};

export const setUserColorSet = portfolioStatus => {
  return dispatch => {
    dispatch(setColorSetSuccess(portfolioStatus));
  };
};

export const fetchUserPortfolioData = (userID, exchangeID, timeFrame) => {
  return async dispatch => {
    try {
      // console.log(
      //   'in user.js::fetchUserPortfolioData::userID,exchangeID,timeFrame',
      //   userID,
      //   exchangeID,
      //   timeFrame
      // );
      dispatch(fetchUserPortfolioDataStart());
      const body = {
        uuid: userID,
        // id: 'f7abed40-b6d3-424c-b94a-a551e851f67c',
        exchange_id: exchangeID,
        time_frame: timeFrame
      };
      const response = await axios.post(`${API_URL}gethistory`, body);
      const responseData = response.data;
      if (response.data === 'invalid exchange id') {
        dispatch(fetchUserPortfolioDataError(response.data));
      }
      const ttotalArray = [];
      let graphData = {};
      let secondGraphData = {};
      let thirdGraphData = {};
      const timeStamps = [];
      const data = [];
      const secondTimeStamps = [];
      const secondData = [];
      const thirdData = [];
      const thirdTimeStamps = [];
      for (let item in responseData.history) {
        timeStamps.push(Number(item) * 1000);
        data.push(responseData.history[item]);
      }
      for (let item in responseData.balance) {
        secondTimeStamps.push(Number(item) * 1000);
        secondData.push(responseData.balance[item]);
      }
      for (let item in responseData.marginAvailable) {
        thirdTimeStamps.push(Number(item) * 1000);
        thirdData.push(responseData.marginAvailable[item]);
      }
      if (exchangeID === 1) {
        graphData = {
          exchange: 'binanceus',
          timeFrame: timeFrame,
          data: data,
          timeStamps: timeStamps
        };
      } else if (exchangeID === 2) {
        graphData = {
          exchange: 'coinbasepro',
          timeFrame: timeFrame,
          data: data,
          timeStamps: timeStamps
        };
      } else if (exchangeID === 3) {
        graphData = {
          exchange: 'binance',
          timeFrame: timeFrame,
          data: data,
          timeStamps: timeStamps
        };
      } else if (exchangeID === 5) {
        graphData = {
          exchange: 'oandademo',
          timeFrame: timeFrame,
          data: data,
          timeStamps: timeStamps
        };
        secondGraphData = {
          exchange: 'oandademo',
          timeFrame: timeFrame,
          data: secondData,
          timeStamps: secondTimeStamps
        };
        thirdGraphData = {
          exchange: 'oandademo',
          timeFrame: timeFrame,
          data: thirdData,
          timeStamps: thirdTimeStamps
        };
      } else if (exchangeID === 6) {
        graphData = {
          exchange: 'oandalive',
          timeFrame: timeFrame,
          data: data,
          timeStamps: timeStamps
        };
        secondGraphData = {
          exchange: 'oandalive',
          timeFrame: timeFrame,
          data: secondData,
          timeStamps: secondTimeStamps
        };
        thirdGraphData = {
          exchange: 'oandalive',
          timeFrame: timeFrame,
          data: thirdData,
          timeStamps: thirdTimeStamps
        };
      } else if (exchangeID === 'combined') {
        graphData = {
          exchange: 'combined',
          timeFrame: timeFrame,
          data: data,
          timeStamps: timeStamps
        };
      }
      graphData = {
        ...graphData,
        portfolioPerformance: responseData.portfolio_perf,
        botPerformance: responseData.bot_perf,
        portfolioChange: responseData.portfolio_change,
        botChange: responseData.bot_change,
        botChangeText: responseData.bot_change_amount_explanation,
        portfolioChangeText: responseData.portfolio_increase
      };
      secondGraphData = {
        ...secondGraphData,
        portfolioPerformance: responseData.portfolio_perf,
        botPerformance: responseData.bot_perf,
        portfolioChange: responseData.portfolio_change,
        botChange: responseData.bot_change,
        botChangeText: responseData.bot_change_amount_explanation,
        portfolioChangeText: responseData.portfolio_increase
      };
      thirdGraphData = {
        ...thirdGraphData,
        portfolioPerformance: responseData.portfolio_perf,
        botPerformance: responseData.bot_perf,
        portfolioChange: responseData.portfolio_change,
        botChange: responseData.bot_change,
        botChangeText: responseData.bot_change_amount_explanation,
        portfolioChangeText: responseData.portfolio_increase
      };
      if (exchangeID === 5 || exchangeID === 6) {
        graphData = {
          ...graphData,
          balancePerformance: responseData.balance_change_amount,
          balanceChange: responseData.balance_change,
          marginPerformance: responseData.margin_change_amount,
          marginChange: responseData.margin_change
        };
        secondGraphData = {
          ...secondGraphData,
          balancePerformance: responseData.balance_change_amount,
          balanceChange: responseData.balance_change,
          marginPerformance: responseData.margin_change_amount,
          marginChange: responseData.margin_change
        };
        thirdGraphData = {
          ...thirdGraphData,
          balancePerformance: responseData.balance_change_amount,
          balanceChange: responseData.balance_change,
          marginPerformance: responseData.margin_change_amount,
          marginChange: responseData.margin_change
        };
      }
      dispatch(fetchUserPortfolioDataSuccess(graphData, secondGraphData, thirdGraphData, exchangeID));
    } catch (error) {
      console.log('error ', error);
      dispatch(fetchUserPortfolioDataError(error));
    }
  };
};

export const getEarnings = (uuid, exchangeID) => {
  return async dispatch => {
    try {
      dispatch(getTotalEarningsStart());
      const body = { uuid: uuid, exchange_id: exchangeID };
      const response = await axios.post(`${API_URL}gettotalearnings`, body);
      const data = response.data;
      let symbols = [];
      for (let key in data) {
        if (key !== 'aggregate') {
          symbols.push(key.toLowerCase());
        }
      }
      let ids = [];
      for (let ticker of symbols) {
        for (let asset of list) {
          if (ticker.toLowerCase() === asset.symbol) {
            ids.push(asset.id);
          }
        }
      }
      ids = ids.join('%2C');
      // let url = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${ids}&order=market_cap_desc&per_page=100&page=1`;
      // const cgResponse = await axios.get(url);
      // const cgData = cgResponse.data;
      let finalData = [];
      for (let symbol of symbols) {
        for (let coin in data) {
          if (coin.toLowerCase() !== 'usd') {
            if (coin.toLowerCase() === symbol) {
              finalData.push({
                ...data[coin],
                symbol: coin
              });
            }
          }
        }
      }
      // for (let coin in data) {
      //   for (let item of cgData) {
      //     if (coin.toLowerCase() !== 'usd') {
      //       if (coin.toLowerCase() === item.symbol) {
      //         finalData.push({
      //           ...data[coin],
      //           img: item.image,
      //           symbol: coin
      //         });
      //       }
      //     }
      //   }
      // }
      if (Object.keys(data).includes('USD')) {
        finalData.push({
          ...data['USD'],
          // img: USD,
          symbol: 'USD'
        });
      }
      if (Object.keys(data).includes('aggregate')) {
        finalData.push({
          base_value: data['aggregate'],
          // img: ALL,
          symbol: 'ALL'
        });
      }
      dispatch(getTotalEarningsSuccess(finalData));
    } catch (error) {
      dispatch(getTotalEarningsFail(error));
    }
  };
};
