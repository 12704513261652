import { Button, Flex, Heading, Image, Input, Link, Text, useClipboard, useColorMode } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Reward from '../assets/reward.png';

const Rewards = props => {
  const [loading, setLoading] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();

  const authState = useSelector(state => state.auth);

  const [refLink, setRefLink] = useState('');

  const text = 'Sign up for Epicflow.io\n';

  // const url = 'https://join.robinhood.com/mertu4/';

  const { onCopy, value, setValue, hasCopied } = useClipboard(refLink);

  useEffect(() => {
    if (authState.refId) {
      setRefLink(`rewards.epicflow.io/${authState.refId}`);
    }
  }, [authState.refId]);

  const share = async () => {
    try {
      let res = await navigator.share({ text: 'content' });
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Flex
      w={['full', 'full', '95%', '95%', '95%', '95%']}
      // maxW="7xl"
      justify="space-between"
      overflowY="hidden"
      overflowX="hidden"
      margin="auto"
      pb={24}
      mt={[8, 8, 12, 12, 12, 12]}
    >
      <Flex
        w={['full', 'full', 'full', 'full', 'full', 'full']}
        direction="column"
        justify="flex-start"
        minH="100vh"
        align="flex-start"
      >
        <Heading fontSize="2xl" mt={4} mb={4}>
          Rewards
        </Heading>
        <Heading fontSize="xl" mt={4} mb={4}>
          Invite friends. Earn money.
        </Heading>
        <Flex w="full" justifyContent="space-between" alignItems="flex-start">
          <Flex flexDirection="column" w={['full', 'full', 'full', '60%', '60%', '60%']}>
            <Image src={Reward} w="full" mt={4} />
            <Button
              onClick={share}
              backgroundColor="whatsapp.500"
              mt={8}
              display={['block', 'block', 'block', 'none', 'none', 'none']}
              w="full"
              color="white"
              borderRadius="full"
              _focus={{
                outline: 'none'
              }}
              _hover={{
                backgroundColor: 'whatsapp.300'
              }}
              fontSize="sm"
            >
              Share
            </Button>
            <Text fontSize="sm" fontWeight="semibold" mt={8} lineHeight="taller">
              {`•  When you refer a friend or family member to our app and they sign up and subscribe to one of our plans,
          you'll receive a fantastic 50% of the subscription fees as a reward. It's our way of saying thank you for
          helping us grow, and for spreading the word about our amazing services!`}
            </Text>
            <Text fontSize="xs" fontWeight="semibold" mt={8} lineHeight="tall" color="gray.400">
              * Please note that referral rewards and the 50% subscription discount are subject to certain terms
              and conditions. When someone uses your referral link and signs up, you both benefit: referral
              earnings are credited to your account after the referred user successfully subscribes to one of our
              plans and remains a paying subscriber for at least 30 days. The reward will be issued in accordance
              with our standard payout schedule, which may vary depending on your location or the payment method
              used. The 50% subscription discount for the person you refer is automatically applied upon sign-up.
              These benefits are subject to change at our discretion, and we reserve the right to modify or
              terminate the referral program at any time.
            </Text>
          </Flex>
          <Flex
            direction="column"
            w="xs"
            alignItems="center"
            justify="flex-start"
            borderRadius={16}
            ml={6}
            mt={4}
            borderWidth={1}
            boxShadow="lg"
            display={['none', 'none', 'none', 'flex', 'flex', 'flex']}
            pb={6}
          >
            <Heading fontSize="lg" my={4}>
              Your Referral Link
            </Heading>
            <Input value={refLink} isReadOnly fontSize="sm" w="90%" />
            <Button
              onClick={onCopy}
              backgroundColor={colorMode === 'light' ? 'black' : 'white'}
              mt={4}
              borderWidth={1}
              color={colorMode === 'light' ? 'white' : 'black'}
              borderColor={colorMode === 'light' ? 'transparent' : 'black'}
              borderRadius="full"
              w="90%"
              _focus={{
                outline: 'none'
              }}
              _hover={{
                borderColor: colorMode === 'light' ? 'black' : 'transparent',
                backgroundColor: colorMode === 'light' ? 'white' : 'black',
                color: colorMode === 'light' ? 'black' : 'white'
              }}
              fontSize="sm"
            >
              {hasCopied ? 'Copied!' : 'Copy'}
            </Button>
            <Link
              isExternal
              href={`https://twitter.com/share?text=${text}&url=${refLink}`}
              target="_blank"
              w="90%"
            >
              <Button
                backgroundColor="twitter.400"
                mt={4}
                borderWidth={1}
                w="full"
                color="white"
                borderRadius="full"
                _focus={{
                  outline: 'none'
                }}
                _hover={{
                  backgroundColor: 'twitter.500'
                }}
                fontSize="sm"
              >
                Share on Twitter
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Rewards;
