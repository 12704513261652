import { Icon, useColorMode } from '@chakra-ui/react';

import { epicFlowGradientColors } from '../../../theme';

const CustomIconLink = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Icon
      as={props.icon}
      _groupHover={{
        color: epicFlowGradientColors.left
      }}
      color={colorMode === 'light' ? 'gray.900' : 'gray.50'}
      boxSize={5}
    />
  );
};

export default CustomIconLink;
