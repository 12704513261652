import 'chartjs-plugin-annotation';

import { Box, Flex, Text, useColorMode } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Chart, Line } from 'react-chartjs-2';

import PageNotFound from '../../components/404/404';
import AutomationWizard from '../../components/automationWizard/automationWizard';
import { exchangeMenuItems } from '../../components/automationWizard/menuItems';
import CardContainer from '../../components/cards/cardContainer';
import PersonalNewsCard from '../../components/cards/personalNewsCard';
import Dropdown from '../../components/UI/Dropdown/dropdown';
import PortfolioTitle from '../../components/UI/portfolioTitle';
import TimeBar from '../../components/UI/TimeBar/timeBar';
//import coinData from '../../constants/data.json';
import { epicFlowGradientColors } from '../../theme';
import { convertToInternationalCurrencySystem } from '../../utilities/currencyConverter';

const Coin = props => {
  // eslint-disable-next-line no-unused-vars
  const { colorMode, toggleColorMode } = useColorMode();

  const _chartRef = useRef(null);

  const [value, setValue] = useState();

  const [currentGraphData, setCurrentGraphData] = useState();

  const [dataError, setDataError] = useState(false);

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        fill: true,
        backgroundColor: 'transparent',
        borderColor: epicFlowGradientColors.left,
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        pointHoverBackgroundColor: epicFlowGradientColors.left,
        pointHoverBorderColor: '#fff',
        pointHoverRadius: 6,
        pointHoverBorderWidth: 1,
        pointRadius: 10,
        borderWidth: 2
      }
    ]
  });

  const handleHover = useCallback((e, item) => {
    if (item.length && value !== item[0]._chart.config.data.datasets[0].data[item[0]._index]) {
      const v = item[0]._chart.config.data.datasets[0].data[item[0]._index];
      setValue(v);
    }
  }, []);

  const [options, setOptions] = useState({
    legend: {
      display: false
    },
    layout: {
      padding: {
        top: 4,
        right: 6
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      backgroundColor: 'rgba(0,0,0,0.8)',
      bodyFontColor: '#fff',
      bodyFontSize: 14,
      titleFontColor: '#fff',
      titleFontSize: 12,
      padding: {
        top: 18,
        left: 20,
        right: 20
      },
      displayColors: false,
      callbacks: {
        label: function (t) {
          // let xLabel = t.xLabel;
          // let yLabel = t.yLabel;
          return;
          // console.log(yLabel)
        },
        title: function (context) {
          return new Date(Number(context[0].label)).toLocaleTimeString();
        }
      }
    },
    hover: {
      mode: 'index',
      intersect: false,
      animationDuration: 0,
      onHover: handleHover
    },
    elements: {
      line: {
        tension: 0
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: false //this will remove only the label
          },
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            display: false
          },
          gridLines: {
            display: false
          }
        }
      ]
    },
    maintainAspectRatio: false
  });

  useEffect(() => {
    // Adding Vertical line to chart
    Chart.pluginService.register({
      afterDraw: function (chart, easing) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const activePoint = chart.controller.tooltip._active[0];
          const ctx = chart.ctx;
          const x = activePoint.tooltipPosition().x;
          const topY = chart.scales['y-axis-0'].top;
          const bottomY = chart.scales['y-axis-0'].bottom;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = colorMode === 'light' ? '#636b75' : '#718096';
          ctx.stroke();
          ctx.restore();
        }
      }
    });
  }, [colorMode]);

  const getHistoricalData = async coin => {
    try {
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/${coin}/market_chart?vs_currency=usd&days=360&interval=daily`
      );
      const responseData = await response.json();
      if (!responseData.error) {
        const priceArray = [];
        const timeStampArray = [];
        for (let item of responseData.prices) {
          priceArray.push(item[1]);
          timeStampArray.push(item[0]);
        }
        setCurrentGraphData({
          datasets: [
            {
              ...data.datasets[0],
              data: priceArray
            }
          ],
          labels: timeStampArray
        });
        setDataError(false);
      } else {
        setDataError(true);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const renderDynamicContent = useMemo(() => {
    if (dataError) {
      return <PageNotFound />;
    } else {
      return (
        <Flex
          w={['full', 'full', '95%', '95%', '95%', '95%']}
          maxW="7xl"
          justify="space-between"
          overflowY="hidden"
          margin="auto"
          mt={[8, 8, 12, 12, 12, 12]}
        >
          <Flex
            w={['full', 'full', 'full', 'full', '65%', '65%']}
            direction="column"
            justify="flex-start"
            align="flex-start"
          >
            <Flex w="95%" m="auto" justify="space-between" align="center">
              <Flex
                // mb={10}
                pb={6}
                w="full"
                justify="space-between"
                align="flex-start"
              >
                <PortfolioTitle
                  usd={value ? `$${convertToInternationalCurrencySystem(value)}` : '  '}
                  title={props.title}
                  change="-$3024"
                  changepercentage="-(%8.7)"
                  timerange="Today"
                />
              </Flex>
            </Flex>
            <Box w="95%" m="auto" mb={2} minHeight="2xs" overflowX="hidden" pb={6}>
              {currentGraphData ? <Line data={currentGraphData} options={options} ref={_chartRef}></Line> : null}
            </Box>
            <TimeBar w="95%" m="auto" />
            <PersonalNewsCard />
          </Flex>
        </Flex>
      );
    }
  }, [dataError, currentGraphData]);

  useEffect(() => {
    getHistoricalData(props.id);
  }, []);

  return renderDynamicContent;
};

Coin.proptypes = {
  title: PropTypes.string
};

export default Coin;
