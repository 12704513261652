import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { epicFlowGradientColors } from '../../theme';
import axios from '../../utilities/axios';
import { usdFormatter } from '../../utilities/currencyConverter';
import { calculateExchangeID } from '../../utilities/exchangeHelpers';
import TerminationCard from '../cards/terminationCard';
import EFButton from '../UI/Button/efButton';

const OandaPause = props => {
  const [selected, setSelected] = useState();

  const { colorMode, toggleColorMode } = useColorMode();

  const [loading, setLoading] = useState(false);

  const authState = useSelector(state => state.auth);

  const [isReviewed, setIsReviewed] = useState(false);

  const [isTerminated, setIsTerminated] = useState(false);

  const [isTerminationError, setIsTerminationError] = useState(false);

  const [terminationOptions, setTerminationOptions] = useState([
    `Enable the bot to continue buying/selling.`,
    `Pause the bot to buy anything further`,
    `Pause the bot to buy/sell anything further`
  ]);

  useEffect(() => {
    setSelected(props.pause_options + 2);
    setIsReviewed(false);
    setIsTerminationError(false);
    setIsTerminated(false);
  }, [props.isopen]);

  const handleTermination = useCallback(
    async option => {
      setLoading(true);
      let body = {
        bot_id: props.botID,
        pause_options: option - 2,
        uuid: authState.id,
        exchange_id: calculateExchangeID(authState.currentOption)
      };
      const API_URL = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${API_URL}editfxbotsettings`, body);
      //TODO check response data here
      if (response.data.error) {
        setLoading(false);
        setIsReviewed(true);
        setIsTerminationError(response.data.error);
        return;
      }
      setLoading(false);
      setIsTerminated(true);
      setIsReviewed(true);
      // console.log(response.data);
      // dispatch(setBotTerminationOptions(response.data));
    },
    [selected]
  );

  const handleCancel = () => {
    setIsTerminated(false);
    setIsReviewed(false);
    setIsTerminationError(false);
    setSelected(props.pause_options + 2);
  };

  const handleReview = useCallback(() => {
    setIsReviewed(true);
  }, []);

  const setBotMessage = sel => {
    switch (sel) {
      case 2:
        return `Your ${props.name} bot will continue to buy/sell.`;
      case 3:
        return `Your ${props.name} bot will be paused to buy any additional units.`;
      case 4:
        return `Your ${props.name} bot will be paused from buying or selling units.`;
      default:
        return `Your ${props.name} Opps!!! this should have not happened, please reach out to our support`;
    }
  };

  const handleSelection = useCallback(option => {
    setSelected(option);
  }, []);

  return (
    <Modal
      isOpen={props.isopen}
      onClose={props.onclose}
      isCentered
      returnFocusOnClose={false}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent borderRadius="2xl" bg={colorMode === 'light' ? 'white' : '#1f2124'}>
        <ModalCloseButton
          _focus={{
            outline: 'none'
          }}
        />
        <ModalHeader fontWeight="bold" fontSize="xl" mt={10} pb={0} pt={0}>
          {props.title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={2}>
          <Flex direction="column" justify="flex-start" align="flex-start" w="full">
            <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} w="full">
              {props.pause_options === 0
                ? '*Your bot is currently engaged in buying/selling units.'
                : props.pause_options === 1
                ? '*Your bot is currently configured not to purchase any additional units.'
                : '*Your bot is currently set not to buy or sell any units.'}
            </Text>
            <Divider my={4} />
            {!isReviewed && (
              <Flex mt={4} direction="column" w="full">
                {terminationOptions.map((el, idx) => {
                  return (
                    <TerminationCard
                      selected={idx + 2 === selected}
                      onClick={() => handleSelection(idx + 2)}
                      option={el}
                      oanda
                      index={idx}
                      key={idx}
                      //   isTerminated={props.terminatedOption === 5}
                      //   onLetItRunClick={() => handleTermination(1)}
                      //   setMarketRadioValue={() => setRadioValue('market')}
                      //   setLimitRadioValue={() => setRadioValue('limit')}
                      //   error={mlaError}
                      //   handleChange={handleChange}
                      //   radioValue={radioValue}
                    />
                  );
                })}
              </Flex>
            )}
            {isReviewed && !isTerminated && !isTerminationError && (
              <Flex w="full" direction="column" alignItems="center" mt={4} mb={2}>
                <AlertTitle fontWeight="semibold">Are you sure you want to do this?</AlertTitle>
                <Alert
                  status="info"
                  variant="unstyled"
                  textAlign="center"
                  mb={4}
                  flexDirection="column"
                  alignItems="center"
                >
                  {' '}
                  <AlertIcon boxSize="24px" mb={4} />
                  <AlertDescription fontSize="sm">{setBotMessage(selected)}</AlertDescription>
                </Alert>
                <Flex
                  px={2}
                  py={4}
                  w="full"
                  borderRadius="xl"
                  mt={2}
                  mb={4}
                  bg={colorMode === 'light' ? 'white' : '#1f2124'}
                  borderWidth={1}
                >
                  <Text
                    fontSize="13px"
                    w="full"
                    textAlign="center"
                    color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
                  >
                    {/* Termination Option: {terminationOptions[selected - 2]} */}
                    {terminationOptions[selected - 2]}
                  </Text>
                </Flex>
              </Flex>
            )}
            {isReviewed && isTerminated && (
              <Flex direction="column" alignItems="center" mt={8} w="full">
                <Alert
                  w="full"
                  textAlign="center"
                  px={0}
                  status="success"
                  variant="unstyled"
                  fontSize="13px"
                  flexDirection="column"
                  alignItems="center"
                >
                  <AlertIcon color="green.400" mb={4} boxSize="28px" />
                  <AlertTitle mt={2} mb={1} fontSize="md">
                    Succesful!
                  </AlertTitle>
                  {/* <AlertDescription maxWidth="sm">
                    {selected === 1
                      ? 'Your bot will continue to run.'
                      : selected === 2
                      ? 'Your bot is successfully terminated.'
                      : 'blal'}
                  </AlertDescription> */}
                </Alert>
              </Flex>
            )}
            {isReviewed && isTerminationError && (
              <Flex direction="column" alignItems="center" mt={8} w="full">
                <Alert
                  status="error"
                  variant="unstyled"
                  flexDirection="column"
                  alignItems="center"
                  px={0}
                  fontSize="13px"
                  justifyContent="center"
                  textAlign="center"
                >
                  <AlertIcon boxSize="32px" mr={0} color="red.400" />
                  <AlertTitle mt={2} mb={1} fontSize="md">
                    Oh Snap!
                  </AlertTitle>
                  <AlertDescription maxWidth="sm">{isTerminationError}</AlertDescription>
                </Alert>
                {/* <Button
                  w={36}
                  my={6}
                  fontSize="xs"
                  colorScheme="gray"
                  _focus={{ outline: 'none' }}
                  onClick={props.onclose}
                  //   _active={{ background: epicFlowGradientColors.right }}
                >
                  Close
                </Button> */}
              </Flex>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          {!isReviewed && (
            <EFButton
              title="Review"
              isDisabled={!selected || props.pause_options + 2 === selected}
              w="full"
              mt={2}
              mb={4}
              isLoading={loading}
              color={colorMode === 'light' ? 'white' : 'black'}
              bg={colorMode === 'light' ? 'black' : 'white'}
              onClick={() => handleReview(selected)}
            />
          )}
          {isReviewed && !isTerminated && !isTerminationError && (
            <Flex alignItems="center" justifyContent="space-evenly" mt={2} w="full" pb={8}>
              <Button
                // maxW="fit-content"
                // size="lg"
                onClick={handleCancel}
                w="full"
                fontSize="13px"
                bg={colorMode === 'light' ? 'white' : 'transparent'}
                // borderColor={epicFlowGradientColors.left}
                mr={4}
                borderColor={props.bg}
                borderWidth={1}
                _hover={{
                  bg: colorMode === 'light' ? 'white' : 'transparent'
                }}
                _focus={{
                  outline: 'none'
                }}
                _active={{
                  bg: colorMode === 'light' ? 'white' : 'transparent'
                }}
              >
                Cancel
              </Button>
              <Button
                w="full"
                fontSize="13px"
                isLoading={loading}
                color={colorMode === 'light' ? 'white' : 'black'}
                bg={colorMode === 'light' ? 'black' : 'white'}
                _hover={{
                  bg: colorMode === 'light' ? 'black' : 'white'
                }}
                _focus={{
                  outline: 'none'
                }}
                _active={{
                  bg: colorMode === 'light' ? 'black' : 'white'
                }}
                onClick={() => handleTermination(selected)}
                //   _active={{ background: epicFlowGradientColors.right }}
              >
                Confirm
              </Button>
            </Flex>
          )}
          {(isReviewed && isTerminated) || (isReviewed && isTerminationError) ? (
            <Button
              w="full"
              my={6}
              fontSize="13px"
              color={colorMode === 'light' ? 'white' : 'black'}
              bg={colorMode === 'light' ? 'black' : 'white'}
              _hover={{
                bg: colorMode === 'light' ? 'black' : 'transparent'
              }}
              _focus={{
                outline: 'none'
              }}
              _active={{
                bg: colorMode === 'light' ? 'black' : 'transparent'
              }}
              onClick={props.onclose}
              //   _active={{ background: epicFlowGradientColors.right }}
            >
              Close
            </Button>
          ) : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OandaPause;
