import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Avatar,
  Button,
  Divider,
  Flex,
  Input,
  Text,
  useColorMode
} from '@chakra-ui/react';
import React, { useState } from 'react';

const Setting = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex pl={0} pr={0} pb={4} w="full" direction="column">
      {props.editable ? (
        <Flex direction="column" justify="flex-start">
          <Flex direction="column" alignItems="flex-start" justify="flex-start" mb={3}>
            <Text fontSize="16px" mb={2}>
              {props.title}
            </Text>
            <Input
              onChange={props.onChange}
              value={props.value}
              placeholder={props.current}
              disabled={props.isDisabled}
              size="lg"
              w="full"
              fontSize={['16px', '16px', 'sm', 'sm', 'sm', 'sm']}
            />
          </Flex>
          {props.isApiSettings && (
            <Flex direction="column" alignItems="flex-start" justify="flex-start">
              <Text fontSize="sm" mb={1}>
                API Secret
              </Text>
              <Input
                onChange={props.onSecretChange}
                mt={2}
                value={props.secretValue}
                disabled={props.isDisabled}
                placeholder={props.secretCurrent}
                size="lg"
                w="full"
                fontSize={['16px', '16px', 'sm', 'sm', 'sm', 'sm']}
              />
            </Flex>
          )}
        </Flex>
      ) : null}
      {/* <Flex alignItems="center" justifyContent={props.isSuccessful ? 'flex-end' : 'flex-end'} mt={2}>
        <Flex alignItems="center">
          <Button
            onClick={props.onClick}
            w={32}
            fontSize="sm"
            isLoading={props.isLoading}
            bg={props.dangerous ? 'red.500' : colorMode === 'light' ? 'black' : 'white'}
            color={props.dangerous ? 'white' : colorMode === 'light' ? 'white' : 'black'}
            _hover={{ background: props.dangerous ? 'red' : '' }}
            _focus={{ outline: 'none' }}
            //   _active={{ background: epicFlowGradientColors.right }}
          >
            {props.dangerous ? 'Delete' : 'Save'}
          </Button>
        </Flex>
      </Flex> */}
    </Flex>
  );
};

Setting.propTypes = {};

export default Setting;
