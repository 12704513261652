import 'react-pulse-dot/dist/index.css';

import { LockIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  chakra,
  color,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useColorMode,
  useDisclosure,
  WrapItem
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import PulseDot from 'react-pulse-dot';
import { useDispatch, useSelector } from 'react-redux';

import { getSocialBots } from '../../store/actions/social';
import axios from '../../utilities/axios';
import PlayStats from '../modals/playStats';

const SocialCard = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();

  const handlePlayStatsOpen = useCallback(
    async id => {
      try {
        onPlayStatsOpen();
        setLoading(true);
        const body = {
          bot_id: id
        };
        const response = await axios.post(`${process.env.REACT_APP_API_URL}playstats`, body);
        const finalData = [];
        for (let item in response.data) {
          finalData.push({
            ...response.data[item],
            id: item
          });
        }
        setData(finalData.slice(0).reverse());
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [props.botID]
  );

  const { isOpen: isPlayStatsOpen, onOpen: onPlayStatsOpen, onClose: onPlayStatsClose } = useDisclosure();

  return (
    <WrapItem
      w={['full', 'full', 'full', 'full', 'full', 'full']}
      position="relative"
      // borderWidth={1}
      // borderColor="gold"
      // bg="cyan.400"
    >
      <Flex
        w="full"
        // h={props.active ? '400px' : '240px'}
        rounded="md"
        justify="flex-start"
        align="center"
        direction="column"
        borderWidth={colorMode === 'light' ? 0 : 1}
        // borderColor={
        //   colorMode === 'dark' && props.best
        //     ? 'gold'
        //     : colorMode === 'dark' && props.second
        //     ? 'rgb(221, 107, 32)'
        //     : colorMode === 'dark' && props.third
        //     ? 'rgb(11, 197, 234)'
        //     : ''
        // }
        // borderColor={colorMode === 'light' ? 'gray.200' : 'whiteAlpha.300'}
        // borderColor={colorMode === 'light' ? 'gray.200' : 'whiteAlpha.300'}
        // boxShadow="rgba(255, 215, 1, 0.40) 0px 3px 15px"
        // boxShadow="rgba(221, 107, 32, 0.40) 0px 3px 15px"
        boxShadow={
          props.best && colorMode === 'light'
            ? 'rgba(255, 215, 1, 0.80) 0px 3px 15px'
            : props.second && colorMode === 'light'
            ? 'rgba(221, 107, 32, 0.40) 0px 3px 15px'
            : props.third && colorMode === 'light'
            ? 'rgba(11, 197, 234, 0.40) 0px 3px 15px'
            : 'rgba(0, 0, 0, 0.10) 0px 3px 15px'
        }
        // boxShadow="rgba(0, 0, 0, 0.10) 0px 3px 15px"
      >
        <Flex w="90%" justify="space-between" py="1rem" align="flex-start">
          <Flex direction="column" w="full">
            <Flex w="full" justify="space-between" alignItems="center">
              <Flex alignItems="center">
                <Flex
                  // w={8}
                  // h={8}
                  // justify="center"
                  // align="center"
                  // borderWidth={2}
                  // borderRadius="full"
                  // borderColor={props.color}
                  // bg={colorMode === 'light' ? 'gray.200' : 'gray.700'}
                  // bg="#e2fcde"
                  bg={props.faded}
                >
                  <Flex alignItems="center">
                    <Text
                      fontSize="2xl"
                      fontWeight="semibold"
                      mr={3}
                      color={props.best ? 'gold' : props.second ? 'orange.500' : props.third ? 'cyan.400' : ''}
                    >
                      #{props.rank}
                    </Text>
                    <Text
                      fontSize="sm"
                      // color={props.color}
                      mr={4}
                      fontWeight="semibold"
                      color={props.best ? 'gold' : props.second ? 'orange.500' : props.third ? 'cyan.400' : ''}
                    >
                      {props.name}
                    </Text>
                  </Flex>
                </Flex>
                {/* <Text ml={3} mr={2} fontSize="sm">
                  {props.email}
                </Text> */}
                {/* {props.best && (
                  <Tooltip
                    hasArrow
                    label="EpicFlow All Time Best Performing Bot"
                    whiteSpace="break-spaces"
                    bg="gray.200"
                    color="gray.700"
                    pt={1}
                    pb={1}
                    fontSize="xs"
                  >
                    <Text ml={1} fontSize="sm" cursor="pointer">
                      🧿 🇹🇷 🇹🇷
                    </Text>
                  </Tooltip>
                )} */}
                {props.best && (
                  <>
                    <Lottie
                      options={{
                        loop: true,
                        path: 'https://assets10.lottiefiles.com/packages/lf20_5py5ru1u.json'
                      }}
                      height={50}
                      width={50}
                    />
                    {/* <Flex
                      w={8}
                      h={8}
                      borderRadius="full"
                      alignItems="center"
                      justify="center"
                      ml={4}
                      borderWidth={0}
                      bg="gold"
                    > */}
                    {/* <Text fontWeight="bold" color="gold" fontSize="sm" ml={4}>
                      Best Performing
                    </Text> */}
                    {/* </Flex> */}
                  </>
                )}
                {props.second && (
                  <>
                    <Lottie
                      options={{
                        loop: true,
                        path: 'https://assets1.lottiefiles.com/packages/lf20_vjdrdcyh.json'
                      }}
                      height={50}
                      width={50}
                    />
                    {/* <Flex
                      w={8}
                      h={8}
                      borderRadius="lg"
                      alignItems="center"
                      justify="center"
                      ml={4}
                      borderWidth={0}
                      bg="orange.500"
                    >
                      <Text fontWeight="semibold" color="white" fontSize="sm">
                        2
                      </Text>
                    </Flex> */}
                  </>
                )}
                {props.third && (
                  <>
                    <Lottie
                      options={{
                        loop: true,
                        path: 'https://assets4.lottiefiles.com/packages/lf20_7DfT1jId32.json'
                      }}
                      height={50}
                      width={50}
                    />
                    {/* <Flex
                      w={8}
                      h={8}
                      borderRadius="full"
                      alignItems="center"
                      justify="center"
                      ml={4}
                      borderWidth={0}
                      bg="cyan.400"
                    >
                      <Text fontWeight="semibold" color="white" fontSize="sm">
                        3
                      </Text>
                    </Flex> */}
                  </>
                )}
              </Flex>
              <Flex>
                {props.isDisabled && (
                  <Tooltip
                    hasArrow
                    label="Upgrade your subscription plan to clone best performing bots."
                    whiteSpace="break-spaces"
                    bg="gray.200"
                    color="gray.700"
                    pt={1}
                    w="100%"
                    pb={1}
                    fontSize="xs"
                  >
                    <span
                      style={{
                        marginTop: '24px',
                        marginBottom: '24px',
                        marginLeft: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '16px'
                      }}
                    >
                      <Button
                        w="6rem"
                        display={['none', 'none', 'none', 'none', 'flex', 'flex']}
                        alignItems="center"
                        variant="outline"
                        bg={
                          props.best
                            ? 'gold'
                            : props.second
                            ? 'rgb(221, 107, 32)'
                            : props.third
                            ? 'rgb(11, 197, 234)'
                            : '#48BB78'
                        }
                        borderRadius="full"
                        borderWidth={0}
                        color="white"
                        fontWeight="bold"
                        fontSize="xs"
                        onClick={props.onCopy}
                        isDisabled={props.isDisabled}
                        rightIcon={<LockIcon w={3} h={3} color="gray.400" />}
                        _hover={{
                          bg: props.best
                            ? '#ddb800'
                            : props.second
                            ? '#aa4f16'
                            : props.third
                            ? '#0da0ba'
                            : '#368757'
                        }}
                      >
                        Clone
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </Flex>
              {!props.isDisabled && (
                <Button
                  w="6rem"
                  display={['none', 'none', 'none', 'none', 'flex', 'flex']}
                  alignItems="center"
                  variant="outline"
                  bg={
                    props.best
                      ? 'gold'
                      : props.second
                      ? 'rgb(221, 107, 32)'
                      : props.third
                      ? 'rgb(11, 197, 234)'
                      : '#48BB78'
                  }
                  borderRadius="full"
                  borderWidth={0}
                  color="white"
                  fontWeight="bold"
                  fontSize="xs"
                  onClick={props.onCopy}
                  isDisabled={props.isDisabled}
                  // rightIcon={<LockIcon w={3} h={3} color="gray.400" />}
                  _hover={{
                    bg: props.best ? '#ddb800' : props.second ? '#aa4f16' : props.third ? '#0da0ba' : '#368757'
                  }}
                >
                  Clone
                </Button>
              )}
            </Flex>
            <Flex justify="flex-start" direction={['column', 'column', 'column', 'column', 'row', 'row']}>
              <Flex mt={4} justify="flex-start" alignItems="center">
                <Text fontSize="xs" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} mr={4}>
                  Created on {props.createdAt}
                </Text>
              </Flex>
              <Flex mt={4} justify="flex-start" alignItems="center">
                <Text fontSize="xs" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                  {props.lifeTime}
                </Text>
                <Text
                  display={['none', 'none', 'block']}
                  fontSize="xs"
                  ml={3}
                  color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
                >
                  {props.botStyle}
                </Text>
                <Text fontSize="xs" ml={3} color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                  {props.exchange}
                </Text>
                <Flex alignItems="center">
                  <Text fontSize="xs" ml={3} color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                    {props.status}
                  </Text>
                  {props.active ? (
                    <PulseDot style={{ fontSize: '0.625rem' }} />
                  ) : (
                    <Flex w="10px" h="10px" ml={1} borderRadius="full" bg="#00ccff" />
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {/* <Divider borderColor={colorMode === 'dark' ? 'whiteAlpha.300' : 'gray.200'} /> */}
        <Accordion allowToggle w="full" textAlign="center">
          <AccordionItem
            css={{
              borderBottom: 'none !important'
            }}
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  _focus={{
                    outline: 'none'
                  }}
                  p={0}
                  display="flex"
                  justifyContent="center"
                  // onClick={() =>
                  //   handleTransactionData(props.botID, props.playNumber, props.exchangeName, isExpanded)
                  // }
                >
                  <Flex w="90%" justify="space-between" py="1rem" align="flex-start">
                    <Flex justify="space-between" alignItems="center" w="full">
                      <Flex
                        direction="column"
                        alignItems="flex-start"
                        w="33.3%"
                        display={['none', 'none', 'flex']}
                      >
                        <Text fontSize="sm" fontWeight="semibold">
                          Profit In
                        </Text>
                        <Text fontSize="sm">{props.target}</Text>
                      </Flex>
                      <Flex
                        direction="column"
                        alignItems={['flex-start', 'flex-start', 'center']}
                        w={['45%', '45%', '33.3%']}
                        pr={10}
                      >
                        <Text fontSize="sm" fontWeight="semibold">
                          Pair
                        </Text>
                        <Text fontSize="sm">{props.pair}</Text>
                      </Flex>
                      <Flex direction="column" alignItems="center" w="33.3%">
                        <Text fontSize="sm" fontWeight="semibold">
                          APR
                        </Text>
                        <Text fontSize="sm" color={props.apr >= 0 ? '#48BB78' : '#ff5400'} fontWeight="bold">
                          {props.apr}%
                        </Text>
                      </Flex>
                      <AccordionIcon />
                    </Flex>
                  </Flex>
                </AccordionButton>
                <AccordionPanel p={0} pb={4} w="full" display="flex" justifyContent="center">
                  <PlayStats
                    name={props.name}
                    playStatsData={data}
                    precision={props.precision}
                    exchange={props.exc}
                    botStyle={props.botStyle}
                    since={props.lifeTime}
                    quoteGraph={props.target}
                    // quoteGraph={props.quoteGraph}
                    baseGraph={props.baseGraph}
                    playCount={props.runCount + 1}
                    botID={props.botID}
                    maxSpent={props.investment}
                    quote={props.target}
                    target={props.target}
                    exchangeName={props.exchange}
                    // quoteAssetPrecision={props.quoteAssetPrecision}
                    quoteAssetPrecision={4}
                    pair={props.pair}
                    isopen={isPlayStatsOpen}
                    inverse={props.inverse}
                    onclose={onPlayStatsClose}
                    // data={botState.playStats}
                    loading={loading}
                    baseCurrency={props.baseCurrency}
                  />
                  <Flex w="90%" justify="space-between" py="1rem" align="flex-start">
                    <Flex justify="space-between" alignItems="flex-start" w="full">
                      <Flex
                        direction="column"
                        alignItems="flex-start"
                        w="33.3%"
                        display={['none', 'none', 'flex']}
                      >
                        <Text fontSize="sm" fontWeight="semibold">
                          Investment
                        </Text>
                        <Text fontSize="sm">
                          {props.investment} {props.target}
                        </Text>
                      </Flex>
                      <Flex
                        direction="column"
                        alignItems={['flex-start', 'flex-start', 'center']}
                        w={['45%', '45%', '33.3%']}
                        pr={10}
                      >
                        <Text fontSize="sm" fontWeight="semibold">
                          Profit
                        </Text>
                        <Text fontSize="sm">
                          {props.profit} {props.target}
                        </Text>
                      </Flex>
                      <Flex direction="column" alignItems="center" w="33.3%" mr={['12.5%', '12.5%', 5]}>
                        <Button
                          display="flex"
                          flexDir="column"
                          variant="outline"
                          w={['full', 'full', '70%']}
                          onClick={() => handlePlayStatsOpen(props.botID)}
                          // bg="#48BB78"
                          // borderWidth={0}
                          _hover={{
                            color: 'white',
                            bg: '#48BB78'
                          }}
                          borderColor="#48BB78"
                          borderRadius="full"
                        >
                          <Text fontSize="xs" fontWeight="semibold">
                            Run Count
                          </Text>
                          <Text
                            fontSize="xs"
                            // color="white"
                            fontWeight="bold"
                            // ml={[8, 8, 0]}
                          >
                            {props.runCount}
                          </Text>
                        </Button>
                      </Flex>
                    </Flex>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
        <Flex w="100%" justify="center" display={['flex', 'flex', 'flex', 'flex', 'none', 'none']}>
          {props.isDisabled && (
            <Tooltip
              hasArrow
              label="Upgrade your subscription plan to clone best performing bots."
              whiteSpace="break-spaces"
              bg="gray.200"
              color="gray.700"
              pt={1}
              w="100%"
              pb={1}
              fontSize="xs"
            >
              <span
                style={{
                  marginTop: '24px',
                  marginBottom: '24px',
                  marginLeft: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '16px'
                }}
              >
                <Button
                  my={6}
                  w="90%"
                  bg={
                    props.best
                      ? 'gold'
                      : props.second
                      ? 'rgb(221, 107, 32)'
                      : props.third
                      ? 'rgb(11, 197, 234)'
                      : '#48BB78'
                  }
                  _hover={
                    props.best
                      ? 'gold'
                      : props.second
                      ? 'rgb(221, 107, 32)'
                      : props.third
                      ? 'rgb(11, 197, 234)'
                      : '#48BB78'
                  }
                  color="white"
                  fontWeight="medium"
                  display={['block', 'block', 'block', 'block', 'none', 'none']}
                  variant="outline"
                  fontSize="sm"
                  onClick={props.onCopy}
                  borderWidth={0}
                  rightIcon={<LockIcon w={3} h={3} color="gray.400" />}
                  isDisabled={props.isDisabled}
                >
                  Clone
                </Button>
              </span>
            </Tooltip>
          )}
          {!props.isDisabled && (
            <Button
              my={6}
              w="90%"
              bg={
                props.best
                  ? 'gold'
                  : props.second
                  ? 'rgb(221, 107, 32)'
                  : props.third
                  ? 'rgb(11, 197, 234)'
                  : '#48BB78'
              }
              color="white"
              fontWeight="medium"
              display={['block', 'block', 'block', 'block', 'none', 'none']}
              variant="outline"
              fontSize="sm"
              onClick={props.onCopy}
              borderWidth={0}
              // rightIcon={<LockIcon w={3} h={3} color="gray.400" />}
              isDisabled={props.isDisabled}
            >
              Clone
            </Button>
          )}
        </Flex>
      </Flex>
    </WrapItem>
  );
};

SocialCard.propTypes = {};

export default SocialCard;
