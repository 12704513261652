import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorMode,
  useDisclosure
} from '@chakra-ui/react';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FuzzySearch from 'react-fuzzy';
import { RiInformationLine } from 'react-icons/ri';
import { List, WindowScroller } from 'react-virtualized';
import { v4 as uuidv4 } from 'uuid';

import { darkThemeBackgroundColor, epicFlowGradientColors } from '../../../theme';
import MenuSearch from '../Input/menuSearch';

const Dropdown = React.memo(props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selected, setSelected] = useState(props.value);

  const [selectedItem, setSelectedItem] = useState('');

  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false);

  const _fuzzRef = useRef();

  const [value, setValue] = useState('');

  const [array, setArray] = useState([]);

  const _inputRef = useRef();

  const fuse = new Fuse(props.menuitems, {
    keys: ['pair'],
    threshold: 0.3
  });

  const [query, setQuery] = useState('');

  const [result, setResult] = useState(fuse.search(query));

  const handleChange = event => {
    setQuery(event.target.value);
    setResult(fuse.search(event.target.value));
    findPairs(fuse.search(event.target.value), event.target.value);
  };

  // useEffect(() => {
  //   console.log(result);
  //   console.log(props.menuitems.length);
  // }, [result]);

  const handleItemPress = choice => {
    setSelected(choice);
    // TODO not if only. should be implemented everywhere this data is needed
    // functional prop will be removed
    if (props.functional) {
      props.selected(choice);
      // props.setMainGraphData(selected);
    }
  };

  const findPairs = useCallback(
    (res, q) => {
      let arr = [];
      if (q !== '') {
        for (let item of res) {
          for (let pair of props.menuitems) {
            if (pair.pair === item.item.pair) {
              arr.push(pair);
            }
          }
        }
      } else {
        // console.log('else');
        for (let pair of props.menuitems) {
          arr.push(pair);
        }
      }
      return arr;
    },
    [query, result, props.menuitems]
  );

  useEffect(() => {
    findPairs(result, query);
  }, [props.menuitems, result]);

  const rowRenderer = useCallback(
    ({
      key, // Unique key within array of rows
      index, // Index of row within collection
      isScrolling, // The List is currently being scrolled
      isVisible, // This row is visible within the List (eg it is not an overscanned row)
      style // Style object to be applied to row (to position it)) => {
    }) => {
      // console.log('list menu items ', array);
      return (
        <MenuItem
          key={key}
          style={style}
          onClick={
            props.searchable && findPairs(result, query).length > 0
              ? () => handleItemPress(findPairs(result, query)[index].pair)
              : props.isPair
              ? () => handleItemPress(props.menuitems[index].pair)
              : () => handleItemPress(props.menuitems[index])
          }
          fontSize={props.fontSize}
          fontWeight="medium"
        >
          {/* {props.searchable && array.length > 0 ? console.log('result pair ') : console.log('prop pair ')} */}
          {props.searchable && findPairs(result, query).length > 0
            ? findPairs(result, query)[index].pair
            : props.isPair
            ? props.menuitems[index].pair
            : props.menuitems[index]}
        </MenuItem>
      );
    },
    [props.menuitems, query]
  );

  // function rowRenderer({
  //   key, // Unique key within array of rows
  //   index, // Index of row within collection
  //   isScrolling, // The List is currently being scrolled
  //   isVisible, // This row is visible within the List (eg it is not an overscanned row)
  //   style // Style object to be applied to row (to position it)
  // }) {
  //   return (
  //     <MenuItem
  //       key={key}
  //       style={style}
  //       onClick={
  //         props.searchable && array.length > 0
  //           ? () => handleItemPress(array[index].pair)
  //           : props.isPair
  //           ? () => handleItemPress(props.menuitems[index].pair)
  //           : () => handleItemPress(props.menuitems[index])
  //       }
  //       fontSize={props.fontSize}
  //       fontWeight="medium"
  //     >
  //       {props.searchable && array.length > 0 ? console.log('result pair ') : console.log('prop pair ')}
  //       {props.searchable && array.length > 0
  //         ? array[index].pair
  //         : props.isPair
  //         ? props.menuitems[index].pair
  //         : props.menuitems[index]}
  //     </MenuItem>
  //   );
  // }

  return (
    <Flex
      direction={props.column ? 'column' : 'row'}
      justify="space-between"
      align="center"
      mt={props.mtop}
      w={props.flexW}
    >
      {props.showTitle ? (
        <Flex w="full" justify="flex-start" align="center" mb={props.column ? 2 : 0}>
          <Text
            fontSize={props.fontSize}
            // fontWeight='semibold'
          >
            {props.title}
          </Text>
          {props.tooltip ? (
            <Tooltip
              hasArrow
              label={props.description}
              whiteSpace="break-spaces"
              bg="gray.200"
              color="gray.700"
              pt={1}
              pb={1}
              fontSize="xs"
            >
              <span
                style={{
                  display: 'flex',
                  marginLeft: '16px',
                  marginRight: '8px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '16px',
                  height: '16px'
                }}
              >
                <Icon
                  as={RiInformationLine}
                  w="16px"
                  h="16px"
                  variant="unstyled"
                  _focus={{
                    outline: 'none'
                  }}
                  color={epicFlowGradientColors.left}
                />
              </span>
            </Tooltip>
          ) : (
            ' '
          )}
        </Flex>
      ) : null}
      <Menu autoSelect={false}>
        {({ isOpen }) => (
          <>
            <MenuButton
              //needs to be fixed
              disabled={props.disabled}
              boxShadow={props.shadow}
              _active={{
                backgroundColor: colorMode === 'light' ? 'white' : 'transparent'
              }}
              _hover={props._hover}
              _focus={{
                outline: 'none'
              }}
              fontSize={props.fontSize}
              py={props.py}
              fontWeight={props.fontWeight}
              h="2rem"
              w="full"
              // w={props.w}
              color={props.color}
              borderColor={props.borderColor}
              bg={colorMode === 'light' ? 'white' : 'transparent'}
              borderWidth={1}
              onClick={onOpen}
              as={Button}
              borderRadius={props.radius}
              rightIcon={<ChevronDownIcon boxSize={props.iconSize} />}
            >
              <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" maxW="20ch">
                {props.value}
              </Text>
            </MenuButton>
            <MenuList
              maxH="xs"
              overflowY="none"
              css={{
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }}
              bg={colorMode === 'light' ? 'white' : darkThemeBackgroundColor.dark}
            >
              {/* <ReactSearchBox
              placeholder="Placeholder"
              value="Doe"
              inputBackgroundColor="white"
              data={data}
              callback={record => console.log(record)}
            /> */}
              {props.searchable ? (
                <MenuSearch
                  bbc={colorMode === 'light' ? '#000' : 'gray.500'}
                  onChange={handleChange}
                  value={query}
                  // ref={_inputRef}
                  isOpen={isOpen}
                  menuitems={result}
                  iconcolor={colorMode === 'light' ? 'gray.400' : 'gray.500'}
                />
              ) : null}
              {/* {isOpen
                ? props.menuitems.map((item, idx) => {
                    return (
                      <MenuItem
                        onClick={() => handleItemPress(item)}
                        key={idx}
                        fontSize={props.fontSize}
                        fontWeight="medium"
                      >
                        {item}
                      </MenuItem>
                    );
                  })
                : null} */}
              <Flex mt={props.searchable ? 16 : 0}>
                {/* <WindowScroller>
                  {({ height, isScrolling, onChildScroll, scrollTop }) => (
                    <List
                      // autoHeight
                      height={225}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      rowCount={findPairs(result, query).length}
                      rowHeight={40}
                      rowRenderer={rowRenderer}
                      scrollTop={scrollTop}
                      width={225}
                    />
                  )}
                </WindowScroller> */}
                <List
                  width={225}
                  height={250}
                  autoHeight={props.autoHeight ? true : false}
                  // height="fit-content"
                  rowCount={findPairs(result, query).length}
                  rowHeight={40}
                  rowRenderer={rowRenderer}
                />
              </Flex>
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  );
});

Dropdown.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  menuitems: PropTypes.array,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  mtop: PropTypes.number,
  showTitle: PropTypes.bool,
  fontSize: PropTypes.string,
  iconSize: PropTypes.string,
  selected: PropTypes.func
};

export default Dropdown;
