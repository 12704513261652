import 'chartjs-plugin-annotation';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  chakra,
  Divider,
  Flex,
  Icon,
  Text,
  Tooltip,
  useColorMode
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Chart, Line } from 'react-chartjs-2';
import { RiInformationLine, RiPulseLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import { epicFlowGradientColors, statusColors } from '../../theme';
import axios from '../../utilities/axios';
import { usdFormatter } from '../../utilities/currencyConverter';

const StatsCard = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const [transactionData, setTransactionData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [avgCost, setAvgCost] = useState('');

  const [price, setPrice] = useState('');

  const [exit, setExit] = useState('');

  const [actualPrice, setActualPrice] = useState('');

  const authState = useSelector(state => state.auth);

  const handleHover = useCallback((e, item) => {
    if (item.length) {
      const v = item[0]._chart.config.data.datasets[0].data[item[0]._index];
      const y = item[0]._chart.config.data.datasets[1].data[item[0]._index];
      const z = item[0]._chart.config.data.datasets[2].data[item[0]._index];
      //   const z = item[0]._chart.config.data.datasets[2].data[item[0]._index];
      setAvgCost(v);
      if (y) {
        setPrice(y);
      } else if (z) {
        setExit(z);
      } else {
        setPrice('');
        setExit('');
      }
      //   setActualPrice(z);
      //   console.log(v, y, z);
      return v;
    }
  }, []);

  const [options, setOptions] = useState({
    legend: {
      display: false
    },
    layout: {
      padding: {
        top: 12,
        right: 6
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      bodyFontSize: 14,
      titleMarginBottom: 4,
      titleFontSize: 12,
      backgroundColor: colorMode === 'light' ? '#fff' : '#1E2025',
      bodyFontColor: colorMode === 'light' ? '#2D3748' : '#CBD5E0',
      borderColor: colorMode === 'light' ? '#CBD5E0' : '#4A5568',
      titleFontColor: colorMode === 'light' ? '#000' : '#fff',
      footerFontColor: colorMode === 'light' ? '#000' : '#fff',
      borderWidth: 0.5,
      xPadding: 24,
      yPadding: 12,
      padding: {
        top: 18,
        left: 20,
        right: 20
      },
      displayColors: false,
      callbacks: {
        label: function (t) {
          // let xLabel = t.xLabel;
          // let yLabel = t.yLabel;
          return;
          // console.log(yLabel)
        },
        title: function (context) {
          return `Avg Cost: ${context[0].value}\n\nPrice: ${context[1].value}\n\n${new Date(
            Number(context[0].label)
          ).toLocaleDateString()}`;
        },
        footer: function (context) {
          return new Date(Number(context[0].label)).toLocaleTimeString();
        }
      }
    },
    // animation: {
    //   duration: 0
    // },
    // responsiveAnimationDuration: 0,
    hover: {
      mode: 'index',
      intersect: false,
      animationDuration: 0,
      onHover: handleHover
    },
    // elements: {
    //   line: {
    //     tension: 0
    //   }
    // },
    scales: {
      xAxes: [
        {
          ticks: {
            display: false //this will remove only the label
          },
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            display: true
          },
          gridLines: {
            display: true
          },
          id: 'y1'
        }
      ]
    },
    maintainAspectRatio: false
  });

  //   useEffect(() => {
  //     // Adding Vertical line to chart
  //     Chart.pluginService.register({
  //       afterDraw: function (chart, easing) {
  //         if (chart.tooltip._active && chart.tooltip._active.length) {
  //           const activePoint = chart.controller.tooltip._active[0];
  //           const ctx = chart.ctx;
  //           const x = activePoint.tooltipPosition().x;
  //           const topY = chart.scales['y-axis-0'].top;
  //           const bottomY = chart.scales['y-axis-0'].bottom;
  //           ctx.save();
  //           ctx.beginPath();
  //           ctx.moveTo(x, topY);
  //           ctx.lineTo(x, bottomY);
  //           ctx.lineWidth = 1;
  //           ctx.strokeStyle = colorMode === 'light' ? '#636b75' : '#718096';
  //           ctx.stroke();
  //           ctx.restore();
  //         }
  //       }
  //     });
  //   }, [colorMode]);

  const datasetKeyProvider = () => {
    return btoa(Math.random()).substring(0, 12);
  };

  const calculateCurrentGraphData = (avgData, priceData, actualPriceData, exitData, timeStamps) => {
    const graphdata = {
      labels: [...timeStamps],
      datasets: [
        {
          label: '',
          data: [...avgData],
          fill: true,
          backgroundColor: 'transparent',
          borderColor: '#48BB78',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: '#48BB78',
          pointHoverBorderColor: '#fff',
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          borderWidth: 2,
          yAxisID: 'y1'
        },
        {
          type: 'scatter',
          label: '',
          data: [...priceData],
          fill: true,
          backgroundColor: 'transparent',
          borderColor: '#ff5400',
          pointBackgroundColor: '#ff5400',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#ff5400',
          pointHoverBorderColor: '#fff',
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          borderWidth: 1
        },
        {
          type: 'scatter',
          label: '',
          data: [...exitData],
          fill: true,
          backgroundColor: 'transparent',
          borderColor: '#3d0066',
          pointBackgroundColor: '#3d0066',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#3d0066',
          pointHoverBorderColor: '#fff',
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          borderWidth: 1
        },
        {
          label: '',
          data: [...actualPriceData],
          fill: true,
          backgroundColor: 'transparent',
          borderColor: '#00a8e8',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          //   pointHoverBackgroundColor: 'blue',
          //   pointHoverBorderColor: '#fff',
          //   pointHoverRadius: 6,
          //   pointHoverBorderWidth: 1,
          //   pointRadius: 10,
          borderWidth: 2
        }
      ]
    };
    return graphdata;
  };

  useEffect(() => {
    setOptions({
      ...options,
      tooltips: {
        ...options.tooltips,
        backgroundColor: colorMode === 'light' ? '#fff' : '#1E2025',
        bodyFontColor: colorMode === 'light' ? '#2D3748' : '#CBD5E0',
        borderColor: colorMode === 'light' ? '#CBD5E0' : '#4A5568',
        titleFontColor: colorMode === 'light' ? '#000' : '#fff',
        footerFontColor: colorMode === 'light' ? '#000' : '#fff'
      }
    });
  }, [colorMode]);

  useEffect(() => {
    // Adding Vertical line to chart
    Chart.pluginService.register({
      beforeDraw: function (chart, easing) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const activePoint = chart.controller.tooltip._active[0];
          const ctx = chart.ctx;
          const x = activePoint.tooltipPosition().x;
          const topY = chart.scales['y1'].top;
          const bottomY = chart.scales['y1'].bottom;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = colorMode === 'light' ? '#636b75' : '#718096';
          ctx.stroke();
          ctx.restore();
        }
      }
    });
  }, [colorMode]);

  const handleTransactionData = async (botID, playCount, exchangeName, isExpanded) => {
    try {
      if (isExpanded) {
        setPrice('');
        setAvgCost('');
      } else {
        setLoading(true);
        const body = {
          bot_id: botID,
          play_count: playCount,
          exchange_name: exchangeName,
          uuid: authState.id
        };
        const response = await axios.post(`${process.env.REACT_APP_API_URL}playtransactions`, body);
        const avgData = [];
        const priceData = [];
        const exitData = [];
        const actualPriceData = [];
        const timeStamps = [];
        for (let item in response.data.history) {
          if (response.data.history[item].run_complete !== 1) {
            if (response.data.history[item].avg_cost) {
              avgData.push(response.data.history[item].avg_cost);
              priceData.push(response.data.history[item].price);
              actualPriceData.push(response.data.history[item].price);
            } else {
              actualPriceData.push(response.data.history[item].actual_close);
              avgData.push(null);
              priceData.push(null);
            }
            exitData.push(null);
          } else {
            exitData.push(response.data.history[item].price);
            actualPriceData.push(response.data.history[item].price);
            avgData.push(null);
            priceData.push(null);
          }
          timeStamps.push(item);

          // TODO updated_at
          // finalData.push({
          //     id: item,
          //     timeStamps: new Date(response.data.created_at).toLocaleString(),
          //     avgCost: response.data.avg_cost,
          //     price: response.data.price
          // })
        }
        for (let point of avgData) {
          if (point) {
            let index = avgData.indexOf(point);
            let firstIndex = avgData.findIndex(e => !e);
            avgData.fill(point, firstIndex, index);
            break;
          }
        }
        for (let point of avgData) {
          if (point) {
            let index = avgData.indexOf(point);
            let firstIndex = avgData.findIndex(e => !e);
            let tempPoint = avgData[firstIndex - 1];
            avgData.fill(tempPoint, firstIndex, index);
          }
        }
        for (let point of avgData) {
          if (!point) {
            let index = avgData.indexOf(point);
            avgData.fill(avgData[index - 1], index, avgData.length);
          }
        }
        // for (let point of priceData) {
        //   if (point) {
        //     let index = priceData.indexOf(point);
        //     let firstIndex = priceData.findIndex(e => !e);
        //     priceData.fill(point, firstIndex, index);
        //   }
        // }
        // for (let point of priceData) {
        //   if (!point) {
        //     let index = priceData.indexOf(point);
        //     priceData.fill(priceData[index - 1], index, priceData.length);
        //   }
        // }
        setTransactionData(calculateCurrentGraphData(avgData, priceData, actualPriceData, exitData, timeStamps));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const calculateSince = (u, c) => {
    let delta = Math.abs(u - c) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    return `${days}D ${hours}H ${minutes}M`;
  };

  return (
    <AccordionItem>
      {({ isExpanded }) => (
        <>
          <AccordionButton
            _focus={{
              outline: 'none'
            }}
            pb={4}
            pt={4}
            onClick={() => handleTransactionData(props.botID, props.playNumber, props.exchangeName, isExpanded)}
          >
            <Flex w="full" justify="space-between" align="flex-start" pb={1} pt={1}>
              <Flex
                direction="column"
                align="flex-start"
                w={['33.3%', '33.3%', '33.3%', '16.66%', '16.66%', '16.66%']}
              >
                <Text fontSize="xs" textAlign="left">
                  Run No
                </Text>
                <Text fontSize="xs" fontWeight="semibold" textAlign="left" pl="10%">
                  #{props.playNumber}
                </Text>
              </Flex>
              <Flex
                display={['none', 'none', 'none', 'flex', 'flex', 'flex', 'flex']}
                direction="column"
                align="flex-start"
                w={['33.3%', '33.3%', '33.3%', '16.66%', '16.66%', '16.66%']}
              >
                <Text fontSize="xs" textAlign="left">
                  Run Time
                </Text>
                <Text fontSize="xs" fontWeight="semibold" textAlign="left">
                  {calculateSince(new Date(props.updatedAt).getTime(), new Date(props.createdAt).getTime())}
                </Text>
              </Flex>
              <Flex
                direction="column"
                align="center"
                w={['33.3%', '33.3%', '33.3%', '16.66%', '16.66%', '16.66%']}
              >
                <Text fontSize="xs">Status</Text>
                <Text
                  fontSize="xs"
                  fontWeight="semibold"
                  color={props.status === 'COMPLETED' ? epicFlowGradientColors.left : '#48BB78'}
                >
                  {props.status}
                </Text>
              </Flex>
              <Flex
                direction="column"
                align="center"
                w={['33.3%', '33.3%', '33.3%', '16.66%', '16.66%', '16.66%']}
              >
                <Text fontSize="xs">Net Profit</Text>
                <Text fontSize="xs" fontWeight="semibold">
                  {props.isMinus ? '-' : ''} {props.gain} {props.inverse ? '' : props.baseCurrency}
                </Text>
              </Flex>
              <Flex
                direction="column"
                align="center"
                w={['33.3%', '33.3%', '33.3%', '16.66%', '16.66%', '16.66%']}
                display={['none', 'none', 'none', 'flex', 'flex', 'flex', 'flex']}
                pl={6}
              >
                <Text fontSize="xs">Gain Percent</Text>
                <Text
                  fontSize="xs"
                  color={props.gainPercent >= 0 ? statusColors.up : statusColors.down}
                  fontWeight="bold"
                >
                  {props.gainPercent}%
                </Text>
              </Flex>
              <Flex
                display={['none', 'none', 'none', 'flex', 'flex', 'flex', 'flex']}
                direction="column"
                align="flex-end"
                w={['25%', '25%', '25%', '16.66%', '16.66%', '16.66%']}
              >
                <Text fontSize="xs">Orders</Text>
                <Text fontSize="xs" fontWeight="semibold">
                  {props.orderCount}
                </Text>
              </Flex>
            </Flex>
            <AccordionIcon ml={4} />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Flex direction="column" justify="flex-start">
              {props.bots ? (
                <Flex w="full" justify="space-between" align="flex-start" pl={0}>
                  <Flex
                    direction="column"
                    align="flex-start"
                    w={['33.3%', '33.3%', '33.3%', '16.66%', '16.66%', '16.66%']}
                  >
                    <Text fontSize="xs" textAlign="left">
                      Avg. Cost
                    </Text>
                    <Text fontSize="xs" fontWeight="semibold">
                      {`${props.avgCost} ${props.quoteGraph}`}
                    </Text>
                  </Flex>
                  <Flex
                    direction="column"
                    align="flex-start"
                    w={['33.3%', '33.3%', '33.3%', '16.66%', '16.66%', '16.66%']}
                    display={['none', 'none', 'none', 'flex', 'flex', 'flex', 'flex']}
                  >
                    <Flex align="center">
                      <Text fontSize="xs">TQS</Text>
                      <Tooltip
                        hasArrow
                        label="Total Quantity Purchased"
                        whiteSpace="break-spaces"
                        bg="gray.200"
                        color="gray.700"
                        pt={1}
                        pb={1}
                        fontSize="xs"
                      >
                        <span
                          style={{
                            marginLeft: '6px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '12px',
                            height: '12px'
                          }}
                        >
                          <Icon
                            as={RiInformationLine}
                            w="12px"
                            h="12px"
                            variant="unstyled"
                            _focus={{
                              outline: 'none'
                            }}
                            color={epicFlowGradientColors.left}
                          />
                        </span>
                      </Tooltip>
                    </Flex>
                    <Text fontSize="xs" fontWeight="semibold">
                      {`${props.tqs} ${props.baseGraph}`}
                    </Text>
                  </Flex>
                  <Flex
                    direction="column"
                    align="center"
                    w={['33.3%', '33.3%', '33.3%', '16.66%', '16.66%', '16.66%']}
                    display={['none', 'none', 'none', 'flex', 'flex', 'flex', 'flex']}
                  >
                    <Flex align="center">
                      <Text fontSize="xs">RA</Text>
                      <Tooltip
                        hasArrow
                        label="Remaining Amount"
                        whiteSpace="break-spaces"
                        bg="gray.200"
                        color="gray.700"
                        pt={1}
                        pb={1}
                        fontSize="xs"
                      >
                        <span
                          style={{
                            marginLeft: '6px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '12px',
                            height: '12px'
                          }}
                        >
                          <Icon
                            as={RiInformationLine}
                            w="12px"
                            h="12px"
                            variant="unstyled"
                            _focus={{
                              outline: 'none'
                            }}
                            color={epicFlowGradientColors.left}
                          />
                        </span>
                      </Tooltip>
                    </Flex>
                    <Text fontSize="xs" fontWeight="semibold">
                      {`${props.ra} ${props.quoteGraph}`}
                    </Text>
                  </Flex>
                  <Flex
                    direction="column"
                    align="center"
                    w={['33.3%', '33.3%', '33.3%', '16.66%', '16.66%', '16.66%']}
                  >
                    <Flex align="center">
                      <Text fontSize="xs">QS</Text>
                      <Tooltip
                        hasArrow
                        label="Quote Spend"
                        whiteSpace="break-spaces"
                        bg="gray.200"
                        color="gray.700"
                        pt={1}
                        pb={1}
                        fontSize="xs"
                      >
                        <span
                          style={{
                            marginLeft: '6px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '12px',
                            height: '12px'
                          }}
                        >
                          <Icon
                            as={RiInformationLine}
                            w="12px"
                            h="12px"
                            variant="unstyled"
                            _focus={{
                              outline: 'none'
                            }}
                            color={epicFlowGradientColors.left}
                          />
                        </span>
                      </Tooltip>
                    </Flex>
                    <Text fontSize="xs" fontWeight="semibold">
                      {`${props.quoteSpend} ${props.quoteGraph}`}
                    </Text>
                  </Flex>
                  <Flex
                    display={['none', 'none', 'none', 'flex', 'flex', 'flex', 'flex']}
                    direction="column"
                    align="center"
                    w={['33.3%', '33.3%', '33.3%', '16.66%', '16.66%', '16.66%']}
                    pl={6}
                  >
                    <Flex align="center">
                      <Text fontSize="xs">SLP</Text>
                      <Tooltip
                        hasArrow
                        label="Stop Loss Price"
                        whiteSpace="break-spaces"
                        bg="gray.200"
                        color="gray.700"
                        pt={1}
                        pb={1}
                        fontSize="xs"
                      >
                        <span
                          style={{
                            marginLeft: '6px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '12px',
                            height: '12px'
                          }}
                        >
                          <Icon
                            as={RiInformationLine}
                            w="12px"
                            h="12px"
                            variant="unstyled"
                            _focus={{
                              outline: 'none'
                            }}
                            color={epicFlowGradientColors.left}
                          />
                        </span>
                      </Tooltip>
                    </Flex>
                    <Text fontSize="xs" fontWeight="semibold">
                      {`${props.slp} ${props.quoteGraph}`}
                    </Text>
                  </Flex>
                  <Flex
                    direction="column"
                    align={['center', 'center', 'center', 'flex-end', 'flex-end', 'flex-end', 'flex-end']}
                    w={['33.3%', '33.3%', '33.3%', '16.66%', '16.66%', '16.66%']}
                  >
                    <Flex align="center">
                      <Text fontSize="xs">Exit Price</Text>
                      {/* <Tooltip
                      hasArrow
                      label="Exit Price"
                      whiteSpace="break-spaces"
                      bg="gray.200"
                      color="gray.700"
                      pt={1}
                      pb={1}
                      fontSize="xs"
                    >
                      <span
                        style={{
                          marginLeft: '6px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '12px',
                          height: '12px'
                        }}
                      >
                        <Icon
                          as={RiInformationLine}
                          w="12px"
                          h="12px"
                          variant="unstyled"
                          _focus={{
                            outline: 'none'
                          }}
                          color={epicFlowGradientColors.left}
                        />
                      </span>
                    </Tooltip> */}
                    </Flex>
                    <Text fontSize="xs" fontWeight="semibold">
                      {`${props.ep} ${props.quoteGraph}`}
                    </Text>
                  </Flex>
                  <Flex ml={9} />
                </Flex>
              ) : null}
              <Flex direction="column" align="flex-start" justify="flex-start" mt={6}>
                <Text fontSize="sm" fontWeight="semibold" mb={4}>
                  Performance Over Time
                </Text>
                <Flex justify="flex-start" align="center" w="full" mb={4}>
                  <Flex h={6} flex={1}>
                    <Flex
                      w="6px"
                      h="full"
                      bg="#48BB78"
                      justify="flex-start"
                      align="center"
                      borderLeftRadius="md"
                      mr={2}
                    ></Flex>
                    <Flex h="full" justify="flex-start" align="center">
                      <Text fontSize="sm" fontWeight="bold">
                        Avg. Cost{' '}
                        <chakra.span fontSize="sm" fontWeight="light">
                          {`${avgCost}` !== ''
                            ? `${Number(avgCost).toFixed(props.precision)} ${props.quoteGraph}`
                            : ''}
                        </chakra.span>
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex h={6} flex={1}>
                    <Flex
                      w="6px"
                      h="full"
                      bg="#ff5400"
                      justify="flex-start"
                      align="center"
                      borderLeftRadius="md"
                      mr={2}
                    ></Flex>
                    <Flex h="full" justify="flex-start" align="center">
                      <Text fontSize="sm" fontWeight="bold">
                        Filled Order{' '}
                        <chakra.span fontSize="sm" fontWeight="light">
                          {`${price}` !== ''
                            ? `${Number(price).toFixed(props.precision)} ${props.quoteGraph}`
                            : ''}
                        </chakra.span>
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex h={6} flex={1}>
                    <Flex
                      w="6px"
                      h="full"
                      bg="#3d0066"
                      justify="flex-start"
                      align="center"
                      borderLeftRadius="md"
                      mr={2}
                    ></Flex>
                    <Flex h="full" justify="flex-start" align="center">
                      <Text fontSize="sm" fontWeight="bold">
                        Run Exit Order{' '}
                        <chakra.span fontSize="sm" fontWeight="light">
                          {`${exit}` !== '' ? `${Number(exit).toFixed(props.precision)} ${props.quoteGraph}` : ''}
                        </chakra.span>
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Box w="full" h="280px" minH="280px" mt={2}>
                  {!loading ? (
                    <Line data={transactionData} options={options} datasetKeyProvider={datasetKeyProvider}></Line>
                  ) : null}
                </Box>
              </Flex>
              {/* <Divider color={colorMode === 'light' ? 'gray.200' : 'gray.600'} mt={6} /> */}
            </Flex>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

StatsCard.propTypes = {};

export default StatsCard;
