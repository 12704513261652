import { Button, Flex, Icon, Input, Radio, RadioGroup, Text, useColorMode } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { RiCheckboxCircleFill } from 'react-icons/ri';

import { epicFlowGradientColors } from '../../theme';
import { usdFormatter } from '../../utilities/currencyConverter';
import AdvancedInput from '../UI/Input/advancedInput';

const TerminationCard = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex
      w="full"
      onClick={props.onClick}
      borderWidth={props.selected ? 2 : 2}
      bg={colorMode === 'light' ? 'white' : '#1f2124'}
      mb={3}
      p={4}
      borderRadius="2xl"
      borderColor={props.selected ? '#48BB78' : ''}
      _hover={{
        cursor: 'pointer'
      }}
    >
      <Flex w="full" justify="space-between" direction={props.oanda ? 'column' : 'row'} alignItems="flex-start">
        <Flex direction="column" w="full" pr={4}>
          <Text fontSize="sm" w={props.index === 3 && props.oanda ? '70%' : 'full'}>
            {props.option}
          </Text>
          {props.index === 1 && !props.oanda ? (
            <Text color="gray.500" fontSize="0.65rem" w="80%">
              The returned amount is subject to your exchanges actual execution.
            </Text>
          ) : null}
          {props.index === 3 && props.isTerminated && !props.oanda ? (
            <Button
              color="#1874ff"
              fontSize="xs"
              w="80%"
              variant="outline"
              textAlign="left"
              mt={3}
              onClick={props.onLetItRunClick}
            >
              I&apos;ve changed my mind. Let the bot run!
            </Button>
          ) : null}
          {props.oanda && props.index === 2 && props.isTerminated ? (
            <Button
              color="#1874ff"
              fontSize="xs"
              w="80%"
              variant="outline"
              textAlign="left"
              mt={3}
              onClick={props.onLetItRunClick}
            >
              I&apos;ve changed my mind. Let the bot run!
            </Button>
          ) : null}
        </Flex>
        {props.index === 3 && props.oanda && (
          <AdvancedInput
            placeholder={props.mla === 0 ? 'Current: Not Set' : 'Current: ' + props.mla}
            fontSize="xs"
            title="Max Loss Amount:"
            start
            value={props.value}
            error={props.error}
            handleChange={props.handleChange}
            h={8}
            mt={4}
            w={48}
            oanda={props.oanda}
            // disabled={props.radioValue !== 'limit'}
            bg={colorMode === 'light' ? 'gray.50' : 'blackAlpha.400'}
          />
        )}
        {props.oanda && props.index === 3 && props.isMla ? (
          <Button
            color="#1874ff"
            fontSize="xs"
            w="80%"
            variant="outline"
            textAlign="left"
            mt={3}
            onClick={props.liftMlaRestriction}
          >
            Remove Max Loss Limit
          </Button>
        ) : null}
        {/* {props.index === 1 ? (
          <Flex direction="column" pr={2}>
            <RadioGroup>
              <Flex mb={2}>
                <Radio size="sm" value="market" onChange={props.setMarketRadioValue} />
                <Text ml={2} fontSize="xs">
                  Market Price
                </Text>
              </Flex>
              <Flex>
                <Radio size="sm" value="limit" onChange={props.setLimitRadioValue} />
                <Input
                  placeholder="Limit Order"
                  fontSize="xs"
                  value={props.limitInput}
                  ml={2}
                  onChange={props.handleChange}
                  h={8}
                  disabled={props.radioValue !== 'limit'}
                  bg={colorMode === 'light' ? 'gray.50' : 'blackAlpha.400'}
                />
              </Flex>
            </RadioGroup>
          </Flex>
        ) : null} */}
      </Flex>
      {props.selected ? (
        <Flex h="full" justify="flex-start" align="center" m="auto">
          <Icon as={RiCheckboxCircleFill} w="20px" h="20px" color="#48BB78" />
        </Flex>
      ) : null}
    </Flex>
  );
};

TerminationCard.propTypes = {};

export default TerminationCard;
