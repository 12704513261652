import * as actionTypes from '../actions/actionTypes';

const initialState = {
  socket: null,
  isSocketOn: false,
  socketError: false,
  currentOption: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INITIALIZE_SOCKET:
      return {
        ...state,
        socket: action.socket,
        isSocketOn: action.isSocketOn
      };
    case actionTypes.KILL_SOCKET:
      return {
        ...state,
        socket: action.socket,
        isSocketOn: action.isSocketOn
      };
    case actionTypes.SET_CURRENT_OPTION:
      return {
        ...state,
        currentOption: action.option
      };
    default:
      return state;
  }
};

export default reducer;
