import {
  Alert,
  AlertIcon,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  Text,
  Textarea,
  useColorMode
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import EFButton from '../components/UI/Button/efButton';
import axios from '../utilities/axios';

const Support = props => {
  // console.log('htiSelected is in general help', htiSelected);

  const { colorMode, toggleColorMode } = useColorMode();

  const authState = useSelector(state => state.auth);

  const [email, setEmail] = useState('');
  const [defaultEmail, setDefaultEmail] = useState(authState.email);
  const [summary, setSummary] = useState('');
  const [description, setDescription] = useState('');
  const [isEmailError, setIsEmailError] = useState(false);
  const [isSummaryError, setIsSummaryError] = useState(false);
  const [isDescriptionError, setIsDescriptionError] = useState(false);
  const [isSuccess, setIsSuccess] = useState();

  const handleInputChange = (e, field) => {
    if (field === 'email') {
      setEmail(e.target.value);
    } else if (field === 'summary') {
      setSummary(e.target.value);
    } else if (field === 'description') {
      setDescription(e.target.value);
    }
  };

  const handleValidation = () => {
    // if (!validator.isEmail(email)) {
    //   setIsEmailError(true);
    // }
    if (validator.isEmpty(summary)) {
      setIsSummaryError(true);
    }
    if (validator.isEmpty(description)) {
      setIsDescriptionError(true);
    }
    if (!validator.isEmail(defaultEmail) || validator.isEmpty(summary) || validator.isEmpty(description)) {
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    // setIsEmailError(false);
    setIsSummaryError(false);
    setIsDescriptionError(false);
    const isError = handleValidation();
    if (isError) {
      return;
    }
    const body = {
      uuid: authState.id,
      summary: summary,
      description: description
    };
    const response = await axios.post(`${process.env.REACT_APP_API_URL}createticket`, body);
    const data = response.data;
    if (data.success) {
      setIsSuccess(data.success);
    }
  };

  return (
    <Flex
      w={['90%', '90%', '90%', '90%', '45%', '45%']}
      // maxW="7xl"
      direction="column"
      alignItems="center"
      justify="flex-start"
      overflowX="hidden"
      margin="auto"
      minH="100vh"
      pb={24}
      // h="full"
      mt={[10, 10, 16]}
      pt={[4, 4, 8]} // border="1px"
      // borderColor="red.500"
    >
      <Text fontSize="16px" fontWeight="black" color="red.400">
        ASK US
      </Text>
      <Heading fontSize="32px" mt={6}>
        We&apos;d love to help
      </Heading>
      <Heading as="h2" size="md" fontWeight="normal" mt={4}>
        How can we help you today?
      </Heading>
      {/* <FormControl isInvalid={isEmailError} mt={12}>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          placeHolder={`Ex. ${authState.email}`}
          value={email}
          onChange={e => handleInputChange(e, 'email')}
        />
        {!isEmailError ? (
          <FormHelperText>Enter the email you&apos;d like to receive the help on.</FormHelperText>
        ) : (
          <FormErrorMessage>Please enter a valid email.</FormErrorMessage>
        )}
      </FormControl> */}
      <FormControl isInvalid={isSummaryError}>
        <FormLabel mt={12}>Summary</FormLabel>
        <Input
          placeHolder="Briefly describe the issue"
          value={summary}
          // bg={colorMode === 'light' ? 'white' : 'gray.900'}
          bg="transparent"
          onChange={e => handleInputChange(e, 'summary')}
        />
        {!isSummaryError ? (
          <FormHelperText lineHeight="6">
            Provide a concise summary of the problem you are experiencing. This will help us understand and address
            your concern more efficiently.
          </FormHelperText>
        ) : (
          <FormErrorMessage>Summary cannot be empty.</FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={isDescriptionError}>
        <FormLabel mt={12}>Description</FormLabel>
        <Textarea
          rows={7}
          placeHolder="Provide detailed information about the problem"
          value={description}
          // bg={colorMode === 'light' ? 'white' : 'gray.900'}
          bg="transparent"
          onChange={e => handleInputChange(e, 'description')}
        />
        {!isDescriptionError ? (
          <FormHelperText lineHeight="6">
            Please provide a detailed description of the issue you&apos;re facing. Include any relevant information
            such as error messages, steps to reproduce the problem, or any additional context that might assist us
            in resolving the issue effectively.
          </FormHelperText>
        ) : (
          <FormErrorMessage>Description cannot be empty.</FormErrorMessage>
        )}
      </FormControl>
      <EFButton
        bg={colorMode === 'light' ? 'black' : 'white'}
        color={colorMode === 'light' ? 'white' : 'black'}
        w="full"
        mt={12}
        title="Submit"
        onClick={handleSubmit}
      />
      {isSuccess ? (
        <Alert status="success" variant="subtle" mb={4} mt={4}>
          <AlertIcon />
          {`Thank you ${authState.email}. Your ticket has been issued.\n Ticket Number: ${isSuccess}. You will be receiving an email regarding on your issue.`}
        </Alert>
      ) : null}
    </Flex>
  );
};

export default Support;
