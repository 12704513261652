// import { io } from 'socket.io-client';
import * as actions from '../actions/actionTypes';
// const socketUrl = 'wss://websocket.stage.epicflow.io';
const socketUrl = process.env.REACT_APP_WEBSOCKET_URL;

const initilizeSocket = socket => {
  return {
    type: actions.INITIALIZE_SOCKET,
    socket: socket,
    isSocketOn: true
  };
};

const killSocket = socket => {
  return {
    type: actions.KILL_SOCKET,
    socket: socket,
    isSocketOn: false
  };
};

const getCurrentOption = option => {
  return {
    type: actions.SET_CURRENT_OPTION,
    option: option
  };
};

export const setOption = option => {
  return dispatch => {
    dispatch(getCurrentOption(option));
  };
};

export const startSocket = (uuid, exchangeID, browserID, exchange, onOpenCallback) => {
  return async dispatch => {
    // console.log('socket.js :: startSocket :: uuid: ', uuid);
    const encoded = encodeURIComponent(uuid);
    // console.log('socket.js :: startSocket :: encoded: ', encoded);
    const socketWithUserIdUrl = `${socketUrl}?user_id=${encoded}`;
    const socket = new WebSocket(socketWithUserIdUrl);

    // socket.onopen = () => {
    //   socket.send(JSON.stringify({ type: 'cors', origin: '*', data: { user_id: encoded } }));
    // };
    // console.log('in socket.js :: startSocket :: socket : ', socket);

    // dispatch(initilizeSocket(socket));

    if (exchange === 'oandademo') {
      exchange = 'oanda_demo';
    } else if (exchange === 'oandalive') {
      exchange = 'oanda_live';
    }

    socket.onopen = () => {
      socket.send(
        JSON.stringify({
          action: 'start_exchange_socket_data',
          data: {
            exchange_name: exchange,
            exchange_id: exchangeID,
            browser_id: browserID,
            uuid: encoded
          }
        })
      );

      // socket.send(
      //   JSON.stringify({
      //     action: 'start_user_socket_data',
      //     data: {
      //       exchange_name: exchange,
      //       exchange_id: exchangeID,
      //       browser_id: browserID,
      //       uuid: encoded
      //     }
      //   })
      // );
      if (onOpenCallback) onOpenCallback(socket);
    };

    socket.onclose = event => {
      console.log('WebSocket connection closed:', event);
    };
    dispatch(initilizeSocket(socket));
  };
};

export const stopSocket = sio => {
  // const encoded = encodeURIComponent(user_id);
  // console.log('in socket.js :: stopSocket method');
  // console.log('user_id : ', user_id);
  // console.log('encoded : ', encoded);

  // if (exchange === 'oandademo') {
  //   exchange = 'oanda_demo';
  // } else if (exchange === 'oandalive') {
  //   exchange = 'oanda_live';
  // }

  // return dispatch => {
  // let exchange;
  // if (exchangeName === 'Binance US') {
  //   exchange = 'binanceus';
  // } else if (exchangeName === 'Binance') {
  //   exchange = 'binancecom';
  // }

  // console.log(uuid, exchangeName);
  // const sio = io(socketUrl);
  // console.log('sio ', socket);
  // socket.on('connect', () => {
  //   socket.emit(
  //     'stop_exchange_socket_data',
  //     JSON.stringify({
  //       exchange_name: exchange,
  //       exchange_id: exchangeID,
  //       browser_id: localStorage.getItem('browser_id'),
  //       uuid: uuid
  //     }),
  //     () => {}
  //   );
  // });
  // console.log('socket.js :: stopSocket :: socket: ', socket);
  // const sio = store.getState().socket.socket;
  // sio.onclose = () => {
  //   sio.send(JSON.stringify({ type: 'cors', origin: '*' }));
  // };
  console.log('sio : ', sio);
  sio?.close();
  console.log('socket closed..');

  // sio.send(
  //   JSON.stringify({
  //     action: 'disconnect',
  //     data: {
  //       user_id: encoded,
  //       exchange_name: exchange
  //     }
  //   })
  // );

  // sio.onclose = event => {
  //   console.log('WebSocket connection closed:', event);
  // };

  // sio.on('disconnect', () => {
  //   // console.log('sio ', sio);
  //   // sio.disconnect();
  // });
  // sio.disconnect();
  // socket.disconnect();
  // dispatch(killSocket(socket.id));
  // socket.emit(
  //   "stop_user_socket_data",
  //   JSON.stringify({
  //     exchange_name: "binanceus",
  //     exchange_id: 1,
  //     uuid: uuid
  //   })
  // );
  // socket.emit("stop_data", {});
  // sio.disconnect();
  // };
};

export const stopExchangeData = (sio, exchangeId, uuid) => {
  console.log('socket.js :: stopExchangeData :: uuid: ', uuid);
  const encoded = encodeURIComponent(uuid);
  console.log('socket.js :: stopExchangeData :: encoded: ', encoded);

  sio.send(
    JSON.stringify({
      action: 'stop_user_socket_data',
      data: {
        exchange_id: exchangeId,
        uuid: encoded
      }
    })
  );
};

export const startExchangeData = (sio, exchangeId, uuid, exchange_name) => {
  console.log('socket.js :: stopExchangeData :: uuid: ', uuid);
  const encoded = encodeURIComponent(uuid);
  console.log('socket.js :: stopExchangeData :: encoded: ', encoded);

  sio.send(
    JSON.stringify({
      action: 'start_user_socket_data',
      data: {
        exchange_id: exchangeId,
        uuid: encoded,
        exchange_name: exchange_name
      }
    })
  );
};
