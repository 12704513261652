import axios from '../../utilities/axios';
import * as actions from '../actions/actionTypes';

const API_URL = process.env.REACT_APP_API_URL;

const fetchReportsStart = () => {
  return {
    type: actions.FETCH_REPORTS_START,
    loading: true
  };
};

const fetchReportsFail = error => {
  return {
    type: actions.FETCH_REPORTS_FAIL,
    loading: false,
    error: error
  };
};

const fetchReportsSuccess = data => {
  return {
    type: actions.FETCH_REPORTS_SUCCESS,
    loading: false,
    reports: data
  };
};

const handleDateFormat = date => {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split('T')[0];
};

const calculateSince = (ua, ca) => {
  const now = new Date().getTime();
  let delta = (new Date(ua).getTime() - new Date(ca).getTime()) / 1000;

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  return `${days}D ${hours}H ${minutes}M`;
};

export const fetchReports = (id, startDate, endDate, bot_name) => {
  return async dispatch => {
    try {
      dispatch(fetchReportsStart());
      let body = {
        bot_id: Number(id),
        start_date: handleDateFormat(startDate),
        end_date: handleDateFormat(endDate)
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}fxanalyticsdatetransactionsdetail`, body);
      const data = [];
      let key = `${handleDateFormat(startDate) + '-' + handleDateFormat(endDate)}`;
      const obj = { [key]: [] };
      for (let point of response.data) {
        obj[Object.keys(obj)[0]].push({
          ...point,
          start_date: handleDateFormat(startDate),
          end_date: handleDateFormat(endDate),
          bot_name: bot_name,
          run_duration: calculateSince(point.updated_at, point.created_at)
        });
      }
      data.push(obj);
      dispatch(fetchReportsSuccess(data));
    } catch (error) {
      dispatch(fetchReportsFail(error));
    }
  };
};
