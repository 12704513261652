import { store } from '../index';

export const handleRestriction = (exchange, sub, mmtoUse, isSettings = false, botId = null) => {
  const activeBots = store.getState().bot.activeBotsConfig;
  let maxMarginAggregated = 0;
  if (sub === undefined) {
    return true;
  }
  if (exchange === 5 || exchange === 6) {
    if (exchange === 5) {
      return false;
    }
    if (exchange === 6) {
      if (sub.toUpperCase() === 'FREE') {
        return true;
      } else if (activeBots.length > 0) {
        for (let bot of activeBots) {
          maxMarginAggregated += bot.max_margin_utilization;
        }
        if (isSettings && botId) {
          maxMarginAggregated =
            maxMarginAggregated - activeBots.find(el => el.bot_id === botId).max_margin_utilization;
        }
        if (sub.toUpperCase() === 'BASIC' && mmtoUse + maxMarginAggregated > 10000) {
          return true;
        } else if (sub.toUpperCase() === 'PREMIUM' && mmtoUse + maxMarginAggregated > 100000) {
          return true;
        }
        if (sub.toUpperCase() === 'PRO' && mmtoUse + maxMarginAggregated > 300000) {
          return true;
        }
        if (sub.toUpperCase() === 'PLATINUM' && mmtoUse + maxMarginAggregated > 700000) {
          return true;
        }
        if (sub.toUpperCase() === 'EXECUTIVE' && mmtoUse + maxMarginAggregated > 10000000) {
          return true;
        }
      }
    }
  } else if (exchange === 1 || exchange === 2 || exchange === 3) {
    if (sub.toUpperCase() === 'FREE') {
      if (mmtoUse > 1000 || activeBots.length >= 1) {
        return true;
      } else {
        return false;
      }
    }
    if (sub.toUpperCase() === 'BASIC') {
      if (mmtoUse > 2000 || activeBots.length >= 3) {
        return true;
      } else {
        return false;
      }
    }
    if (sub.toUpperCase() === 'PREMIUM') {
      if (mmtoUse > 5000 || activeBots.length >= 5) {
        return true;
      } else {
        return false;
      }
    }
    if (sub.toUpperCase() === 'PRO') {
      if (mmtoUse > 25000 || activeBots.length >= 100000000) {
        return true;
      } else {
        return false;
      }
    }
  }
};
