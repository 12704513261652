import './index.css';
import '@fontsource/noto-sans/400.css';

import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import App from './App';
import reportWebVitals from './reportWebVitals';
import authReducer from './store/reducers/auth';
import botReducer from './store/reducers/bot';
import exchangeReducer from './store/reducers/exchange.js';
import marketReducer from './store/reducers/market';
import profileReducer from './store/reducers/profile';
import reportsReducer from './store/reducers/reports';
import socialReducer from './store/reducers/social';
import socketReducer from './store/reducers/socket';
import userReducer from './store/reducers/user';
import theme from './theme.js';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true }) || compose;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  bot: botReducer,
  exchange: exchangeReducer,
  market: marketReducer,
  profile: profileReducer,
  user: userReducer,
  social: socialReducer,
  socket: socketReducer,
  reports: reportsReducer
});

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme} cssVarsRoot="body">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
