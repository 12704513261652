import { SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Input, MenuItem, useMenuItem } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

const MenuSearch = props => {
  const { role, ...rest } = useMenuItem(props);

  return (
    <Flex
      borderWidth={0}
      borderBottomWidth={1}
      borderRadius={0}
      borderBottomColor={props.bbc}
      outline="none"
      role={role}
      width="80%"
      ml={3}
      position="fixed"
      mt={2}
      mb={4}
      justifyContent="space-between"
      alignItems="center"
    >
      <Input
        placeholder="Search Pairs"
        borderWidth={0}
        // ref={props.ref}
        size="sm"
        {...rest}
        // _focus={{
        //   ouline: 'none'
        // }}
        // _hover={{
        //   ouline: 'none'
        // }}
      />
      {/* <Box>
        {props.menuitems.map((item, idx) => {
          return (
            <MenuItem
              onClick={props.onClick}
              key={uuidv4()}
              fontSize={props.fontSize}
              fontWeight="medium"
              mt={idx === 0 ? 2 : 1}
            >
              {item.item.pair}
            </MenuItem>
          );
        })}
      </Box> */}
      <SearchIcon w={4} h={4} color={props.iconColor} />
    </Flex>
  );
};

export default MenuSearch;
