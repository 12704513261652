// import { Flex } from '@chakra-ui/react';
// import React from 'react';

// const AuthVerify = props => {
//   return <Flex></Flex>;
// };

// export default AuthVerify;

import {
  Box,
  Button,
  Center,
  chakra,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Image,
  Input,
  PinInput,
  PinInputField,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react';
import { Auth, Cache } from 'aws-amplify';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import EFLogo from '../../assets/eflogo.svg';
import { singup } from '../../store/actions/auth';

const AuthVerify = props => {
  const [errorMessage, setErrorMessage] = useState();

  const [verificationMessage, setVerificationMessage] = useState(null);

  const [isResendCode, setIsResendCode] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory(); // <-- get history from hook

  // const navigate = useNavigate();

  // const {
  //   handleSubmit,
  //   register,
  //   getFieldState,
  //   formState: { errors },
  //   setValue
  // } = useForm({
  //   defaultValues: { username: '', code: '' }
  // });

  // useEffect(() => {
  //   console.log('get cached item start')
  //   const username = Cache.getItem('pendingVerificationUser');
  //   console.log('username is', username)
  //   if (username) {
  //     setValue('username', username);
  //   }
  // }, [setValue]);

  const [code, setCode] = useState();

  // const handleVerify = formData => onVerify(formData);

  const handleResendCode = async () => {
    try {
      setIsResendCode(false);
      let username = Cache.getItem('pendingVerificationUser');
      if (username) {
        const response = await Auth.resendSignUp(username);
        setIsResendCode(true);
      }
    } catch (error) {
      console.log('Error resending code: ', error);
    }
  };

  const handleVerify = (username, code) => {
    try {
      setIsLoading(true);
      if (code.length !== 6) {
        return setErrorMessage('Code should be 6 digits.');
      }
      username = Cache.getItem('pendingVerificationUser');
      if (username) {
        // setValue('username', username);
        Auth.confirmSignUp(username, code, {})
          .then(res => {
            setVerificationMessage('Your account has been verified successfully!');
            Cache.removeItem('pendingVerificationUser');
            setTimeout(() => {
              window.location.assign('/login');
            }, 3000);
            // dispatch(singup())
            //TODO add feedback for verificatin process. ie, succesful, and login button
            // props.history.push('/login');
          })
          .catch(error => {
            setIsLoading(false);
            setErrorMessage(error.message);
          });
      } else {
        setIsLoading(false);
        setErrorMessage('Your verification code is expired.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(Cache.getItem('pendingVerificationUser'), history.location.search.slice(2));

  if (Cache.getItem('pendingVerificationUser') !== history.location.search.slice(2)) {
    history.replace('/login');
    return null;
  } else {
    return (
      <Box
        display="flex"
        h="100vh"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        overflow="hidden"
        bg="white"
        pt={[48, 48, 48, 64, 64, 64]}
        width="100%"
      >
        <Flex width="100%" fontSize="3xl" justifyContent="center" alignItems="center">
          <Flex align="center" justify="center" width="fit-content">
            <Image src={EFLogo} boxSize={16} objectFit="contain" />
          </Flex>
        </Flex>

        {/* <Divider m={4} size="3xl" /> */}

        <VStack width={{ base: '80%', md: '60%', lg: '30%' }} spacing="10px" mt={8}>
          <Center fontWeight="bold" mb={1}>
            {' '}
            Verify Your Account
          </Center>

          <FormControl display="flex" flexDirection="column" align="center">
            {/* <div className="mb-4">
              <label htmlFor="username">Email</label>
              <input
                type="text"
                id="username"
                name="username"
                className="form-control"
                {...register("username")}
                // ref={register}
              />
            </div> */}
            <Flex flexDirection="column" justify="center" width="full" mb={4} alignItems="center">
              <FormLabel htmlFor="code" mb={8}>
                Enter the code.
              </FormLabel>
              <HStack w="full" justifyContent="center" mb={6} spacing={4}>
                <PinInput
                  // type="number"
                  // size="lg"
                  // id="code"
                  // name="code"
                  // className={clsx('form-control', !!errors?.code && 'border-red-600')}
                  // {...register('code', {
                  //   required: 'Required',
                  //   pattern: {
                  //     value: /^\d{6}$/,
                  //     message: 'Enter 6 digits with no other characters'
                  //   }
                  // })}
                  // ref={register({
                  //   required: "Required",
                  //   pattern: {
                  //     value: /^\d{6}$/,
                  //     message: "Enter 6 digits with no other characters",
                  //   },
                  // })}
                  onChange={value => setCode(value)}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
              {/* {errors.code?.message && (
                <chakra.span className="form-feedback-invalid" color="red.500" fontSize="sm" fontWeight="medium">
                  {errors.code?.message}
                </chakra.span>
              )}{' '} */}
              <Flex alignItems="center">
                <Text>Did not get a code?</Text>
                <Button
                  variant="unstyled"
                  ml={2}
                  fontWeight="normal"
                  textDecoration="underline"
                  color="blue.400"
                  onClick={handleResendCode}
                >
                  Click to resend
                </Button>
              </Flex>
              {isResendCode && (
                <Text fontSize="sm" fontWeight="medium">
                  A new code has been resent.
                </Text>
              )}
            </Flex>
            {errorMessage && (
              <Flex justify="center" mb={4}>
                <Text color="red.500" fontSize="sm" fontWeight="medium">
                  {errorMessage}
                </Text>
              </Flex>
            )}
            {verificationMessage && (
              <Flex justify="center" mb={4} flexDir="column">
                <Text color="green.500" fontSize="sm" fontWeight="medium">
                  {verificationMessage}
                </Text>
                <Text mt={4} fontSize="sm" fontWeight="medium">
                  You will be redirecting in seconds...
                </Text>
              </Flex>
            )}
            <Box>
              <Button
                backgroundColor="whatsapp.400"
                _hover={{
                  backgroundColor: 'whatsapp.700'
                }}
                disabled={isLoading}
                px={8}
                color="white"
                fontWeight="semibold"
                fontSize="sm"
                borderRadius="full"
                onClick={() => handleVerify('', code)}
              >
                Confirm
              </Button>
            </Box>
          </FormControl>
        </VStack>
      </Box>
    );
  }

  // useEffect(() => {
  //   if (!props.pendingVerification) {
  //     props.history.push('/signup');
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log('code ', code);
  // }, [code]);

  // useEffect(() => {
  //   console.log(errorMessage);
  // }, [errorMessage]);
};

export default AuthVerify;
