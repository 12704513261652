import { Box, Button, chakra, Divider, Flex, Heading, Image, Switch, Text, useColorMode } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Arrow from '../assets/arrow_price.png';
import SubscriptionCard from '../components/cards/subscriptionCard';
import { epicFlowGradientColors } from '../theme';
import axios from '../utilities/axios';

const API_URL = process.env.REACT_APP_API_URL;

//const DEV_API_URL = 'http://localhost:5020/';

function Subscriptions() {
  const { colorMode, toggleColorMode } = useColorMode();
  const authState = useSelector(state => state.auth);

  const [prices, setPrices] = useState([]);
  const [stripeCustomerId, setStripeCustomerId] = useState();
  const [isIntervalMonth, setIsIntervalMonth] = useState(false);
  const [isActiveSchedule, setIsActiveSchedule] = useState(false);
  const [isDowngraded, setIsDowngraded] = useState(false);
  const [customerCurrentSubscription, setCustomerCurrentSubscription] = useState(null);
  let [paymentIntentId, setPaymentIntentId] = useState('');

  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  const sortArray = (arr, prop) => {
    arr.sort((a, b) => {
      if (typeof a[prop] === 'string') return b[prop].localeCompare(a[prop]);
      return a[prop] - b[prop];
    });
  };

  const fetchPrices = useCallback(async () => {
    const result = await axios.get(`${API_URL}checkout/prices`);
    let allPricesArray = result.data.data;
    allPricesArray = allPricesArray.filter(price => price.product.description !== '(created by Stripe CLI)');
    sortArray(allPricesArray, 'unit_amount');
    setPrices(allPricesArray);
  }, []);

  const getExistCustomerId = useCallback(() => {
    setStripeCustomerId(authState.stripeCustomerId);
  }, [authState.stripeCustomerId]);

  const customerSubscriptions = async customerId => {
    const reqBody = { customerId: customerId };
    const result = await axios
      .post(`${API_URL}checkout/customer-subscriptions`, reqBody)
      .catch(function (error) {});
    const subscriptions = result.data.subscriptions.data;
    const currentlyActiveSubscriptionPlan = subscriptions.filter(
      sub => sub.status === 'active' || sub.status === 'trailing'
    )[0];
    // console.log(subscriptions);
    setCustomerCurrentSubscription(currentlyActiveSubscriptionPlan);
  };

  const handleBillingInformation = async customerId => {
    try {
      // console.log(customerId);
      const reqBody = { customerId: customerId };
      const portalSession = await axios
        .post(`${API_URL}checkout/create-portal-session`, reqBody)
        .then(response => response.data);
      if (portalSession.url) {
        window.location.replace(`${portalSession.url}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPrices();
    getExistCustomerId();
    if (authState.stripeCustomerId) {
      customerSubscriptions(authState.stripeCustomerId);
    }
  }, [authState.stripeCustomerId]);

  useEffect(async () => {
    if (customerCurrentSubscription && customerCurrentSubscription.schedule && prices) {
      const getSchedule = await axios.post(`${API_URL}checkout/retrive-schedule`, {
        scheduleId: customerCurrentSubscription.schedule
      });
      // console.log(getSchedule);
      if (!getSchedule.data.error) {
        for (let price of prices) {
          if (price.id === getSchedule.data.phases[1].items[0].price) {
            // console.log(price.id, getSchedule.data.phases[1].items[0].price);
            return setIsDowngraded(price.id);
          }
        }
      }
    }
  }, [customerCurrentSubscription, prices]);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [sessionId]);

  const SuccessDisplay = ({ sessionId }) => {
    return (
      <Flex justify="center" align="center" m={6}>
        <Text as="b" fontSize="xl" mx={4} color="green.600">
          Subscription successful!
        </Text>

        <Button colorScheme="teal" onClick={() => handleBillingInformation(stripeCustomerId)}>
          Manage your billing information
        </Button>
      </Flex>
    );
  };

  const Message = ({ message }) => (
    <Box>
      <Text>{message}</Text>
    </Box>
  );

  // console.log('first product is', prices[0]);
  return authState.id && authState.exchanges.length > 0 && customerCurrentSubscription ? (
    <Flex
      // w={['full', 'full', '95%', '95%', '95%', '95%']}
      w="full"
      // maxW="7xl"
      flexDirection="column"
      margin="auto"
      align="center"
      justify="center"
      pb={24}
      overflowX="hidden"
      mt={[10, 10, 16]}
      pt={[4, 4, 8]}
    >
      {/* <Heading as="h2" size="lg" noOfLines={1} mb="50px" mt={20}>
        Subscription Plans
      </Heading> */}
      {/* <Heading fontSize="2xl" w="full" textAlign="left" my={4}>
        Subscription Plans
      </Heading> */}
      {/* <Flex
        userSelect="none"
        justifyContent="center"
        alignItems="center"
        px={6}
        py={2}
        borderRadius="full"
        borderWidth={1}
      >
        <Text fontSize="13px" fontWeight="bold">
          Pricing
        </Text>
      </Flex> */}
      <Text fontSize="16px" fontWeight="black" color="purple.400">
        PRICING
      </Text>
      <Heading
        // background={colorMode === 'light' ? 'black' : 'linear-gradient(to right, #12c2e9, #c471ed, #f64f59)'}
        // backgroundClip="text"
        fontSize="5xl"
        fontWeight="bold"
        mt={6}
        w={['full', 'full', 'xl']}
        textAlign="center"
        lineHeight="3.7rem"
      >
        Select the plan that suits your needs.
      </Heading>
      <Heading
        // background={colorMode === 'light' ? 'black' : 'linear-gradient(to right, #12c2e9, #c471ed, #f64f59)'}
        // backgroundClip="text"
        as="h2"
        fontSize="md"
        fontWeight="normal"
        mt={6}
        w={['full', 'full', '3xl', '3xl', '3xl', '3xl']}
        textAlign="center"
        lineHeight="1.7rem"
      >
        Discover the perfect plan tailored to your needs and unlock exclusive features with{' '}
        <chakra.span bgClip="text" bgGradient="linear(to-r, #1874ff, #00ccff)" fontWeight="black">
          Epicflow.io
        </chakra.span>
        . Choose from a range of options designed to optimize your workflow and maximize productivity.
      </Heading>
      <Flex direction="column" justify="flex-start" alignItems="center" position="relative" w="full">
        {/* <Flex
          py={2}
          px={4}
          justify="center"
          mr={8}
          alignItems="center"
          bg="rgba(72, 187, 120, 0.1)"
          borderRadius="md"
          mb={8}
        >
          <Text fontSize="xs" color="#48BB78" fontWeight="semibold">
            Save 20%
          </Text>
        </Flex>
        <Image src={Arrow} w={36} h={36} fit="fill" position="absolute" top={-8} right="-48px" /> */}
        <Flex align="center" justify="center" mr={10} mt={8}>
          <Text
            fontSize="16px"
            fontWeight="bold"
            noOfLines={1}
            ml={10}
            color={isIntervalMonth ? '' : colorMode === 'dark' ? 'gray.600' : 'gray.400'}
          >
            Monthly
          </Text>
          <Switch
            //background: ${!isIntervalMonth ? '#48bb78;' : ';'};

            isChecked={!isIntervalMonth}
            size="lg"
            css={`
              > span:first-of-type {
                box-shadow: unset;
                background: transparent;
                border: ${colorMode === 'light' ? '1px solid #cbd5e0;' : '1px solid #4a5568;'};
              }
              > span > span {
                background: ${colorMode === 'light' ? '#000;' : '#fff;'};
              }
            `}
            mx={2}
            onChange={() => setIsIntervalMonth(!isIntervalMonth)}
          />
          <Text
            fontSize="16px"
            fontWeight="bold"
            noOfLines={1}
            color={!isIntervalMonth ? '' : colorMode === 'dark' ? 'gray.600' : 'gray.400'}
          >
            Yearly
          </Text>
        </Flex>
      </Flex>
      {/* <SuccessDisplay sessionId={sessionId} /> */}

      {message ? <Message message={message} /> : ''}

      <Flex
        width="100%"
        // wrap={['', '', '', 'wrap', 'nowrap', 'nowrap']}
        direction={['column', 'column', 'column', 'row']}
        align={['center', 'center', 'center', 'flex-start']}
        flexWrap="wrap"
        justify="space-around"
        m={6}
      >
        {prices.map(price => {
          if (price.active) {
            if (isIntervalMonth && (price.recurring.interval === 'month' || price.product.name === 'Free')) {
              return (
                <SubscriptionCard
                  key={price.id}
                  price={price}
                  stripeCustomerId={stripeCustomerId}
                  isDowngraded={price.id === isDowngraded}
                  isSchedule={isDowngraded}
                  customerCurrentSubscription={customerCurrentSubscription}
                  interval="month"
                />
              );
            } else if (
              !isIntervalMonth &&
              (price.recurring.interval === 'year' || price.product.name === 'Free')
            ) {
              return (
                <SubscriptionCard
                  key={price.id}
                  price={price}
                  stripeCustomerId={stripeCustomerId}
                  isDowngraded={price.id === isDowngraded}
                  isSchedule={isDowngraded}
                  customerCurrentSubscription={customerCurrentSubscription}
                  interval="year"
                />
              );
            }
          }
        })}
      </Flex>
    </Flex>
  ) : (
    <Flex w="full" minH="100vh" justifyContent="center" alignItems="center">
      <Lottie
        options={{
          loop: true,
          path: 'https://lottie.host/81c5c7b2-2ef6-4dff-a1b3-8430ac3a60e1/6xx96QsW3x.json'
        }}
        height={40}
        width={40}
      />
    </Flex>
  );
}

export default Subscriptions;
