import * as actionTypes from '../actions/actionTypes';

const initialState = {
  bots: null,
  pairs: null,
  targets: null,
  count: 0,
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SOCIAL_BOTS_START:
      return {
        ...state,
        loading: action.loading
      };
    case actionTypes.GET_SOCIAL_BOTS_FAIL:
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    case actionTypes.GET_SOCIAL_BOTS_SUCCESS:
      return {
        ...state,
        bots: action.page === 0 ? action.bots : [...state.bots, ...action.bots],
        pairs: action.pairs,
        targets: action.targets,
        count: action.count,
        loading: action.loading
      };
    default:
      return state;
  }
};

export default reducer;
