// import { Button, useColorMode } from '@chakra-ui/react';
// import PropTypes from 'prop-types';
// import React from 'react';

// import { epicFlowGradientColors } from '../../../theme';

// const EFButton = props => {
//   const { colorMode, toggleColorMode } = useColorMode();

//   return (
//     <Button
//       {...props}
//       bg={epicFlowGradientColors.left}
//       borderRadius={props.br}
//       color={colorMode === 'light' ? 'white' : 'black'}
//       _hover={{
//         backgroundColor: 'rgba(24, 116, 255, 0.9)'
//       }}
//       _focus={{
//         outline: 'none'
//       }}
//       _active={{
//         backgroundColor: 'rgba(24, 116, 255, 0.9)'
//       }}
//       fontSize="sm"
//     >
//       {props.title}
//     </Button>
//   );
// };

// EFButton.propTypes = {
//   title: PropTypes.string
// };

// export default EFButton;

import { Button, useColorMode } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

import { epicFlowGradientColors } from '../../../theme';

const EFButton = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Button
      {...props}
      bg={props.bg}
      borderRadius={props.br}
      // color={colorMode === 'light' ? 'white' : 'black'}
      _focus={{
        outline: 'none'
      }}
      _hover={{
        backgroundColor: props.bg
      }}
      _active={{
        backgroundColor: props.bg
      }}
      fontSize={props.fontSize ? props.fontSize : 'sm'}
    >
      {props.title}
    </Button>
  );
};

EFButton.propTypes = {
  title: PropTypes.string
};

export default EFButton;
