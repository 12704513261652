import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useColorMode
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import UpgradeDarkIcon from '../../assets/upgradeb.png';
import UpgradeLightIcon from '../../assets/upgradew.png';
import axios from '../../utilities/axios';
import SubscriptionCardSmall from '../cards/subscriptionCardSmall';

const API_URL = process.env.REACT_APP_API_URL;

const UpgradeSubs = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const authState = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const [selection, setSelection] = useState(false);
  const [prices, setPrices] = useState([]);
  const [stripeCustomerId, setStripeCustomerId] = useState();
  const [isIntervalMonth, setIsIntervalMonth] = useState(false);
  const [isActiveSchedule, setIsActiveSchedule] = useState(false);
  const [isDowngraded, setIsDowngraded] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isModalError, setIsModalError] = useState(false);
  const [isModalResult, setIsModalResult] = useState();
  const [customerCurrentSubscription, setCustomerCurrentSubscription] = useState(null);
  let [paymentIntentId, setPaymentIntentId] = useState('');

  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  const sortArray = (arr, prop) => {
    arr.sort((a, b) => {
      if (typeof a[prop] === 'string') return b[prop].localeCompare(a[prop]);
      return a[prop] - b[prop];
    });
  };

  const handleModalClose = () => {
    if (isModalResult) {
      props.onclose();
      return window.location.reload();
    }
    setIsModalError(false);
    setSelection(false);
    setIsModalLoading(false);
    setIsModalResult();
    setIsIntervalMonth(false);
    props.onclose();
  };

  const handleSelection = plan => {
    setSelection(plan);
  };

  const handleModalConfirm = async (priceId, stripeCustomerId) => {
    try {
      if (
        customerCurrentSubscription?.plan.amount !== 0 &&
        selection.product.name.toUpperCase() !== 'FREE' &&
        selection.unit_amount > customerCurrentSubscription?.plan.amount
      ) {
        setIsModalLoading(true);
        const reqBody = {
          customerId: stripeCustomerId,
          priceId: priceId,
          action: 'upgrade',
          subscriptionId: customerCurrentSubscription.id
        };
        const response = await axios.post(`${API_URL}checkout/update-subscription`, reqBody);
        // console.log('Upgrade response ', response.data);
        setIsModalLoading(false);
        if (response.data.error) {
          setIsModalError(response.data.error.message);
        } else {
          setIsModalResult(response.data);
        }
      } else {
        await createCheckoutSession(priceId, stripeCustomerId);
      }
    } catch (error) {
      setIsModalError(error.message);
    }
  };

  const createCheckoutSession = async (priceId, stripeCustomerId) => {
    try {
      const reqBody = { customerId: stripeCustomerId, priceId: priceId };
      // console.log(priceId, stripeCustomerId);
      const createdCheckoutSession = await axios
        .post(`${API_URL}checkout/create-checkout-session`, reqBody)
        .then(response => response.data);
      if (createdCheckoutSession.url) {
        window.location.replace(`${createdCheckoutSession.url}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPrices = useCallback(async () => {
    const result = await axios.get(`${API_URL}checkout/prices`);
    let allPricesArray = result.data.data;
    allPricesArray = allPricesArray.filter(price => price.product.description !== '(created by Stripe CLI)');
    sortArray(allPricesArray, 'unit_amount');
    setPrices(allPricesArray);
  }, []);

  const getExistCustomerId = useCallback(() => {
    setStripeCustomerId(authState.stripeCustomerId);
  }, [authState.stripeCustomerId]);

  const customerSubscriptions = async customerId => {
    const reqBody = { customerId: customerId };
    const result = await axios
      .post(`${API_URL}checkout/customer-subscriptions`, reqBody)
      .catch(function (error) {});
    const subscriptions = result.data.subscriptions.data;
    const currentlyActiveSubscriptionPlan = subscriptions.filter(
      sub => sub.status === 'active' || sub.status === 'trailing'
    )[0];
    // console.log(subscriptions);
    setCustomerCurrentSubscription(currentlyActiveSubscriptionPlan);
  };

  const handleBillingInformation = async customerId => {
    try {
      // console.log(customerId);
      const reqBody = { customerId: customerId };
      const portalSession = await axios
        .post(`${API_URL}checkout/create-portal-session`, reqBody)
        .then(response => response.data);
      if (portalSession.url) {
        window.location.replace(`${portalSession.url}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPrices();
    getExistCustomerId();
    if (authState.stripeCustomerId) {
      customerSubscriptions(authState.stripeCustomerId);
    }
  }, [authState.stripeCustomerId]);

  useEffect(async () => {
    if (customerCurrentSubscription && customerCurrentSubscription.schedule && prices) {
      const getSchedule = await axios.post(`${API_URL}checkout/retrive-schedule`, {
        scheduleId: customerCurrentSubscription.schedule
      });
      // console.log(getSchedule);
      if (!getSchedule.data.error) {
        for (let price of prices) {
          if (price.id === getSchedule.data.phases[1].items[0].price) {
            // console.log(price.id, getSchedule.data.phases[1].items[0].price);
            return setIsDowngraded(price.id);
          }
        }
      }
    }
  }, [customerCurrentSubscription, prices]);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [sessionId]);

  // const renderDynamicContent = key => {
  //   switch (key) {
  //     case 'result':
  //       return (

  //       );
  //       break;

  //     default:
  //       break;
  //   }
  // };

  return (
    // TODO handle responsive sizing

    <Modal
      size="3xl"
      motionPreset="slideInBottom"
      isOpen={props.isopen}
      onClose={handleModalClose}
      returnFocusOnClose={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent pt={12} bg={colorMode === 'light' ? 'white' : '#1f2124'} borderRadius="xl">
        <Flex w="fit-content" position="absolute" top={6} left={6}>
          {/* <Lottie
            options={{
              loop: true,
              path: 'https://lottie.host/81738f2d-8815-4ca8-8474-2f9d367911e6/QOZFD9hhBC.json'
            }}
            height={65}
            width={65}
          /> */}
          <Image w="45px" h="45px" src={colorMode === 'light' ? UpgradeDarkIcon : UpgradeLightIcon} />
          {/* <Image boxSize={8} src={EFLogo} />
              <Icon as={CgArrowsExchange} boxSize={8} />
              <Image boxSize={8} src={imageSelector(exchange)} borderRadius="lg" /> */}
        </Flex>
        <ModalHeader fontSize="lg" pt={0} pb={1} fontWeight="bold" mt={12} mb={4}>
          Upgrade to access.
        </ModalHeader>
        <ModalCloseButton
          _focus={{
            outline: 'none'
          }}
        />
        {!isModalResult ? (
          <ModalBody pb={6}>
            {/* {authState.currentOption === 'Oanda Live' || authState.currentOption === 'Oanda Live' ? (
              <Flex align="center" justify="space-between" w="80%">
                {!isDowngraded ? (
                  <>
                    <Flex align="center" justify="flex-start">
                      <Flex align="center" justify="flex-start">
                        <Icon as={FiCheck} w="18px" h="18px" color={colorMode === 'light' ? 'black' : 'white'} />
                        <Text ml={2} fontSize="13px" fontWeight="bold">
                          Unlimited bots
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex align="center" justify="flex-start">
                      <Flex align="center" justify="flex-start">
                        <Icon as={FiCheck} w="18px" h="18px" color={colorMode === 'light' ? 'black' : 'white'} />
                        <Text ml={2} fontSize="13px" fontWeight="bold">
                          $1,000,000 Max Margin Utilization
                        </Text>
                      </Flex>
                    </Flex>
                  </>
                ) : (
                  <Text fontSize="13px" colorScheme="gray">
                    *Your plan is scheduled to be downgraded. Please contact us for further assistant.
                  </Text>
                )}
              </Flex>
            ) : (
              <>
                <Flex align="center" justify="flex-start">
                  <Flex align="center" justify="flex-start">
                    <Icon as={FiCheck} w="18px" h="18px" color={colorMode === 'light' ? 'black' : 'white'} />
                    <Text ml={2} fontSize="14px" fontWeight="normal">
                      Unlimited bots
                    </Text>
                  </Flex>
                </Flex>
                <Flex align="center" justify="flex-start" mt={4}>
                  <Flex align="center" justify="flex-start">
                    <Icon as={FiCheck} w="18px" h="18px" color={colorMode === 'light' ? 'black' : 'white'} />
                    <Text ml={2} fontSize="14px" fontWeight="normal">
                      Unlimited max investment amount per bot
                    </Text>
                  </Flex>
                </Flex>
                <Flex align="center" justify="flex-start" mt={4}>
                  <Icon as={FiCheck} w="18px" h="18px" color={colorMode === 'light' ? 'black' : 'white'} />
                  <Text ml={2} fontSize="14px" fontWeight="normal">
                    Customize bots with advanced settings{' '}
                  </Text>
                </Flex>
                <Flex align="center" justify="flex-start" mt={4}>
                  <Icon as={FiCheck} w="18px" h="18px" color={colorMode === 'light' ? 'black' : 'white'} />
                  <Text ml={2} fontSize="14px" fontWeight="normal">
                    Unlimited exchange accounts
                  </Text>
                </Flex>
                <Flex align="center" justify="flex-start" mt={4}>
                  <Icon as={FiCheck} w="18px" h="18px" color={colorMode === 'light' ? 'black' : 'white'} />
                  <Text ml={2} fontSize="14px" fontWeight="normal">
                    Social trading
                  </Text>
                </Flex>
              </>
            )} */}
            <Divider mt={4} mb={6} />
            {!isDowngraded && (
              <Flex align="center" justify="center" w="full" justifyContent="flex-end">
                <Text
                  fontSize="14px"
                  fontWeight="bold"
                  noOfLines={1}
                  ml={10}
                  color={isIntervalMonth ? '' : colorMode === 'dark' ? 'gray.600' : 'gray.400'}
                >
                  Monthly
                </Text>
                <Switch
                  //background: ${!isIntervalMonth ? '#48bb78;' : ';'};

                  isChecked={!isIntervalMonth}
                  disabled={
                    customerCurrentSubscription?.plan.interval === 'year' &&
                    customerCurrentSubscription?.plan.amount !== 0
                  }
                  size="sm"
                  css={`
                    > span:first-of-type {
                      box-shadow: unset;
                      background: transparent;
                      border: ${colorMode === 'light' ? '1px solid #cbd5e0;' : '1px solid #4a5568;'};
                    }
                    > span > span {
                      background: ${colorMode === 'light' ? '#000;' : '#fff;'};
                    }
                  `}
                  mx={2}
                  onChange={() => setIsIntervalMonth(!isIntervalMonth)}
                />
                <Text
                  fontSize="14px"
                  fontWeight="bold"
                  noOfLines={1}
                  color={!isIntervalMonth ? '' : colorMode === 'dark' ? 'gray.600' : 'gray.400'}
                >
                  Yearly
                </Text>
              </Flex>
            )}
            <Flex
              width="100%"
              wrap={['wrap', 'nowrap', 'nowrap']}
              alignItems="center"
              justify={['center', 'center', 'space-around']}
              flexDir={['column', 'column', 'row']}
            >
              {prices.map(price => {
                if (price.active) {
                  if (isDowngraded && customerCurrentSubscription?.plan.amount === price.unit_amount) {
                    return (
                      <SubscriptionCardSmall
                        key={price.id}
                        price={price}
                        stripeCustomerId={stripeCustomerId}
                        isDowngraded={price.id === isDowngraded}
                        isSchedule={isDowngraded}
                        customerCurrentSubscription={customerCurrentSubscription}
                        interval="month"
                        handleSelection={() => handleSelection(price)}
                        isSelected={selection === price}
                      />
                    );
                  } else if (
                    !isDowngraded &&
                    isIntervalMonth &&
                    price.recurring.interval === 'month' &&
                    price.product.name !== 'Free' &&
                    customerCurrentSubscription?.plan.amount < price.unit_amount
                  ) {
                    return (
                      <SubscriptionCardSmall
                        key={price.id}
                        price={price}
                        stripeCustomerId={stripeCustomerId}
                        isDowngraded={price.id === isDowngraded}
                        isSchedule={isDowngraded}
                        customerCurrentSubscription={customerCurrentSubscription}
                        interval="month"
                        handleSelection={() => handleSelection(price)}
                        isSelected={selection === price}
                      />
                    );
                  } else if (
                    !isDowngraded &&
                    !isIntervalMonth &&
                    price.recurring.interval === 'year' &&
                    price.product.name !== 'Free'
                  ) {
                    if (
                      customerCurrentSubscription?.plan.interval === 'month' &&
                      price.unit_amount / 12 > customerCurrentSubscription?.plan.amount
                    ) {
                      return (
                        <SubscriptionCardSmall
                          key={price.id}
                          price={price}
                          stripeCustomerId={stripeCustomerId}
                          isDowngraded={price.id === isDowngraded}
                          isSchedule={isDowngraded}
                          customerCurrentSubscription={customerCurrentSubscription}
                          interval="year"
                          handleSelection={() => handleSelection(price)}
                          isSelected={selection === price}
                        />
                      );
                    } else if (
                      customerCurrentSubscription?.plan.interval === 'year' &&
                      customerCurrentSubscription?.plan.amount < price.unit_amount
                    )
                      return (
                        <SubscriptionCardSmall
                          key={price.id}
                          price={price}
                          stripeCustomerId={stripeCustomerId}
                          isDowngraded={price.id === isDowngraded}
                          isSchedule={isDowngraded}
                          customerCurrentSubscription={customerCurrentSubscription}
                          interval="year"
                          handleSelection={() => handleSelection(price)}
                          isSelected={selection === price}
                        />
                      );
                  }
                }
              })}
            </Flex>
            <Divider my={4} />
            <Link
              as={ReactRouterLink}
              to="/subscriptions"
              color={colorMode === 'light' ? 'black' : 'white'}
              textDecoration="underline"
              display="flex"
              w="fit-content"
            >
              <Flex alignItems="center" w="fit-content">
                <Text fontWeight="bold" fontSize="13px">
                  Compare plan options
                </Text>
                <ChevronRightIcon mr={2} color={colorMode === 'light' ? 'black' : 'white'} />
              </Flex>
            </Link>
          </ModalBody>
        ) : (
          <ModalBody>
            <Flex direction="column" justify="flex-start" align="flex-start">
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} w="full">
                *Your current plan is {authState.stripeCurrentPlan.toUpperCase()}
              </Text>
              <Divider my={4} />
              {isModalResult && (
                <Flex direction="column" alignItems="center" mt={4} w="full">
                  <Alert
                    status="success"
                    variant="subtle"
                    bg=""
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    fontSize="13px"
                  >
                    <AlertIcon boxSize="28px" mb={4} />
                    {/* <AlertTitle mt={4} mb={1} fontSize="lg">
                    Succesfull!
                  </AlertTitle> */}
                    {`Success! Your request has been processed successfully. You can now navigate to the 'Settings' tab
                  and access the 'Billing' section to manage your subscription preferences. Thank you for choosing
                  our service!`}
                    {/* <AlertDescription maxWidth="sm">
                    {selected === 1
                      ? 'Your bot will continue to run.'
                      : selected === 2
                      ? 'Your bot is successfully terminated.'
                      : 'blal'}
                  </AlertDescription> */}
                  </Alert>
                </Flex>
              )}
              {isModalError && (
                <Flex direction="column" alignItems="center" mt={4} w="full">
                  <Alert
                    status="error"
                    variant="subtle"
                    bg=""
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    fontSize="13px"
                  >
                    <AlertIcon boxSize="32px" mr={0} color="red.400" />
                    <AlertTitle mt={2} mb={1} fontSize="md">
                      Oh Snap!
                    </AlertTitle>
                    <AlertDescription maxWidth="sm">{isModalError}</AlertDescription>
                  </Alert>
                </Flex>
              )}
            </Flex>
          </ModalBody>
        )}
        <ModalFooter>
          <Button
            title="Back"
            w="full"
            fontSize="13px"
            mb={2}
            // mt={8}
            bg={colorMode === 'light' ? 'black' : 'white'}
            // borderColor={epicFlowGradientColors.left}
            borderColor={props.bg}
            borderWidth={1}
            _hover={{
              bg: colorMode === 'light' ? 'black' : 'white'
            }}
            _focus={{
              outline: 'none'
            }}
            _active={{
              bg: colorMode === 'light' ? 'black' : 'white'
            }}
            // color={epicFlowGradientColors.left}
            color={colorMode === 'light' ? 'white' : 'black'}
            isLoading={isModalLoading}
            disabled={!selection || isDowngraded || isModalLoading}
            onClick={
              !isModalResult
                ? () => handleModalConfirm(selection?.id, authState.stripeCustomerId)
                : handleModalClose
            }
            // color={userState.colorStatus === 'up' ? statusColors.up : statusColors.down}
            // onClick={handleBack}
          >
            {!isModalResult ? 'Upgrade' : 'Close'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpgradeSubs;
