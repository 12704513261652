import axios from '../utilities/axios';
import { getExchangeNames } from './exchangeHelpers';

const API_URL = process.env.REACT_APP_API_URL;

//const DEV_API_URL = 'http://localhost:5020/';

export const setExchanges = exchanges => {
  const availableExchanges = [];
  for (let exchange of exchanges) {
    availableExchanges.push(exchange);
    // if(exchange === 1){
    //     availableExchanges.push('Binance US');
    // }
    // else if(exchange === 2){
    //     availableExchanges.push('Coinbase');
    // }
    // else if(exchange === 3){
    //     availableExchanges.push('Binance');
    // }
  }
  return availableExchanges;
};

const arraymove = (arr, fromIndex, toIndex) => {
  let element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};

export const setTargets = (exchange, assets) => {
  let exc;
  // console.log('helper ', exchange, assets);
  if (exchange === 'Binance US') {
    exc = 'binanceus';
  } else if (exchange === 'Binance') {
    exc = 'binancecom';
  } else if (exchange === 'Coinbase') {
    exc = 'coinbase';
  }

  const targets = [];
  for (let item of assets) {
    if (item[exc]) {
      for (let coin of item[exc]) {
        targets.push(coin.symbol.toUpperCase());
      }
    }
  }
  if (exchange === 'Binance US' || exchange === 'Coinbase') {
    // if (!targets.includes('USD')) {
    //   targets.push('USD');
    // }
    if (targets.includes('USD')) {
      arraymove(targets, targets.indexOf('USD'), 0);
    }
  } else if (exchange === 'Binance') {
    if (targets.includes('USDT')) {
      arraymove(targets, targets.indexOf('USDT'), 0);
    }
  }
  // console.log('targets ==> ', targets);
  return targets;
};

// export const setPairs = (exchange, assets, target) => {
//   let exc;
//   if (exchange === 'Binance US') {
//     exc = 'binanceus';
//   } else if (exchange === 'Binance') {
//     exc = 'binancecom';
//   } else if (exchange === 'Coinbase') {
//     exc = 'coinbase';
//   }

//   const targets = [];
//   for (let item of assets) {
//     if (item[exc]) {
//       for (let coin of item[exc]) {
//         targets.push(coin.symbol.toUpperCase());
//       }
//     }
//   }
//   const pairs = [];
//   for (let asset of targets) {
//     if (asset !== target) {
//       pairs.push(`${asset}/${target.toUpperCase()}`);
//     }
//   }
//   return pairs;
// };

// export const setPlaceholder = (target, exchange, assets) => {
//   let exc;
//   if (exchange === 'Binance US') {
//     exc = 'binanceus';
//   } else if (exchange === 'Binance') {
//     exc = 'binancecom';
//   } else if (exchange === 'Coinbase') {
//     exc = 'coinbase';
//   }

//   for (let item of assets) {
//     if (item[exc]) {
//       for (let coin of item[exc]) {
//         if (coin.symbol === 'usdt') {
//           return coin.free;
//         } else {
//           const response = axios.get(
//             `https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=${target}`
//           );
//         }
//       }
//     }
//   }
// };

const calculateExchangeKey = (option, assetData) => {
  let combinedKey;
  let binanceusKey;
  let binancecomKey;
  let coinbaseproKey;
  for (let i = 0; i < assetData.length; i++) {
    if (assetData[i]['combined']) {
      combinedKey = i;
    } else if (assetData[i]['binanceus']) {
      binanceusKey = i;
    } else if (assetData[i]['binancecom']) {
      binancecomKey = i;
    }
    if (assetData[i]['coinbasepro']) {
      coinbaseproKey = i;
    }
  }
  // investigate below console log
  // console.log('option ', option);
  if (option === 'Binance US') {
    return {
      exc: 'binanceus',
      key: binanceusKey
    };
  } else if (option === 'Coinbase') {
    return {
      exc: 'coinbasepro',
      key: coinbaseproKey
    };
  } else if (option === 'Binance') {
    return {
      exc: 'binancecom',
      key: binancecomKey
    };
  } else if (option === 'Total') {
    return {
      exc: 'combined',
      key: combinedKey
    };
  }
};

export const dispatchSetPairs = async (exchangeID, userID, assets, target, exchange) => {
  const body = {
    exchange_id: exchangeID,
    uuid: userID
  };
  const response = await axios.post(`${API_URL}getpairs`, body);
  const pairs = response.data;
  const arr = [];
  let exc;
  if (exchange === 'Binance US') {
    exc = 'binanceus';
  } else if (exchange === 'Binance') {
    exc = 'binancecom';
  } else if (exchange === 'Coinbase') {
    exc = 'coinbase';
  }
  // for (let item of assets) {
  //   for (let key in item) {
  //     if (key === exc) {
  //       for (let coin of item[key]) {
  //         if (coin.symbol.toUpperCase() !== target) {
  //           arr.push(`${coin.symbol.toUpperCase()}/${target}`);
  //         }
  //         // for (let pair of pairs[target]) {
  //         //   console.log(coin.symbol.toUpperCase(), pair);
  //         //   if (coin.symbol.toUpperCase() === pair.slice(-Number(coin.symbol.length))) {
  //         //     arr.push(pair);
  //         //   }
  //         // }
  //       }
  //     }
  //   }
  // }
  for (let symbol in pairs) {
    if (symbol === target) {
      for (let pair of pairs[symbol]) {
        arr.push({ pair: pair });
        // console.log('p[p[pp ', symbol, pair, `${symbol}${target}`);
        // if (pair === `${symbol}${target}`) {
        //   arr.push(`${symbol}/${target}`);
        // } else if (pair === `${target}${symbol}`) {
        //   arr.push(`${target}/${symbol}`);
        // }
      }
    }
  }
  let pairsInfo = [];
  for (let i of arr) {
    for (let j in pairs.summary) {
      if (i.pair === j) {
        pairsInfo.push({
          [j]: { ...pairs.summary[j] }
        });
      }
    }
  }

  let socialPairs = [];
  for (let item in pairs.summary) {
    socialPairs.push(item);
  }
  return {
    arr: arr,
    pairsInfo: pairsInfo,
    socialPairs: socialPairs
  };
};

export const getPairsDetails = async exchange => {
  let exc;
  if (exchange === 'Binance US') {
    exc = 'binanceus';
  } else if (exchange === 'Binance') {
    exc = 'binancecom';
  } else if (exchange === 'Coinbase') {
    exc = 'coinbase';
  }
  const body = {
    exchange_name: exc
  };
  const response = await axios.post(`${API_URL}getpairsdetails`, body);
  const pairs = response.data;
  return pairs;
};
