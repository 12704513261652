import { LockIcon, WarningTwoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Button,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorMode,
  useDisclosure,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import Decimal from 'decimal.js';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { set } from 'react-hook-form';
import { RiInformationLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import { epicFlowGradientColors, statusColors } from '../../theme';
import axios from '../../utilities/axios';
import { dynamicCurrencyFormatter, usdFormatter } from '../../utilities/currencyConverter';
import { calculateExchangeID } from '../../utilities/exchangeHelpers';
import { handleRestriction } from '../../utilities/rules';
import { automationTypeMenuItems } from '../automationWizard/menuItems';
import UpgradeSub from '../modals/upgradeSub';
import EFButton from '../UI/Button/efButton';
import DropDown from '../UI/Dropdown/dropdown';
import AdvancedInput from '../UI/Input/advancedInput';
import FormattedInput from '../UI/Input/formattedInput';

const OandaBotSettings = props => {
  const [tabIndex, setTabIndex] = useState(0);

  const [settingsConfirmed, setSettingsConfirmed] = useState(false);

  const [advancedSettingsConfirmed, setAdvancedSettingsConfirmed] = useState(false);

  const [status, setStatus] = useState('Preview');

  const { colorMode, toggleColorMode } = useColorMode();

  const [botName, setBotName] = useState();

  const [maxMarginUtilization, setMaxMarginUtilization] = useState();

  const [marginError, setMarginError] = useState(false);

  const [stopLimitBufferError, setStopLimitBufferError] = useState(false);

  const [errors, setErrors] = useState([]);

  const [serverError, setServerError] = useState(false);

  const [unitAdditionError, setUnitAdditionError] = useState(false);

  const [stopLimitBuffer, setStopLimitBuffer] = useState();

  const [stopLimitGranularity, setStopLimitGranularity] = useState();

  const [profitPriceConstant, setProfitPriceConstant] = useState();

  const [orderTimer, setOrderTimer] = useState();

  const [initialUnits, setInitialUnits] = useState();

  const [unitAddition, setUnitAddition] = useState();

  const [unitMultiplier, setUnitMultiplier] = useState();

  const [additionalOrderStep, setAdditionalOrderStep] = useState();

  // const [botStyle, setBotStyle] = useState(props.botStylePlaceholder);

  const [loading, setLoading] = useState(false);

  const [advancedLoading, setAdvancedLoading] = useState(false);

  const userState = useSelector(state => state.user);

  const authState = useSelector(state => state.auth);

  const botState = useSelector(state => state.bot);

  const { isOpen: isUpgradeSubOpen, onOpen: onUpgradeSubOpen, onClose: onUpgradeSubClose } = useDisclosure();

  const handleUpgradeSubModalClose = () => {
    onUpgradeSubClose();
  };

  const [settings, setSettings] = useState([
    {
      title: 'Bot Name',
      placeholder: props.botNamePlaceholder,
      description: props.botNameDesc
    },
    {
      title: 'Max Margin to Use',
      placeholder: props.maxMarginUtilizationPlaceHolder,
      description: props.maxAmountDesc,
      prefix: ``
    },
    {
      title: 'Base Order',
      placeholder: props.initialUnitsPlaceholder,
      description: props.botStyleDesc,
      suffix: ' units'
    },
    {
      title: 'Next Order Trigger',
      placeholder: props.additionalOrderStepPlaceholder,
      description: props.botStyleDesc,
      suffix: ' pips'
    },
    {
      title: 'Order Increment',
      placeholder: props.unitAdditionPlaceholder,
      description: props.botStyleDesc,
      suffix: ' units'
    },
    // {
    //   title: 'Unit Multiplier',
    //   placeholder: props.unitMultiplierPlaceholder,
    //   description: props.botStyleDesc
    // },
    {
      title: 'Min Profit Exit',
      placeholder: props.profitPriceConstantPlaceholder,
      description: props.botStyleDesc,
      suffix: ' pips'
    },
    {
      title: 'Profit Buffer',
      placeholder: props.stopLimitBufferPlaceholder,
      description: props.botStyleDesc,
      suffix: ' pips'
    },
    // {
    //   title: 'Profit Exit Step',
    //   placeholder: props.stopLimitGranularityPlaceholder,
    //   description: props.botStyleDesc,
    //   suffix: ' pips'
    // },
    {
      title: 'Force Order Timer',
      placeholder: props.orderTimerPlaceholder,
      description: props.orderTimerDesc,
      suffix: ' hours'
    }
    // {
    //   title: 'Bot Style',
    //   placeholder: props.botStylePlaceholder,
    //   description: props.botStyleDesc
    // }
  ]);

  // const handleDropDownBotStyle = selected => {
  //   setBotStyle(selected);
  // };

  useEffect(() => {
    // do componentDidUpdate logic
    setSettings([
      {
        title: 'Bot Name',
        placeholder: props.botNamePlaceholder,
        description: props.botNameDesc
      },
      {
        title: 'Max Margin to Use',
        placeholder: props.maxMarginUtilizationPlaceHolder,
        description: props.maxAmountDesc,
        prefix: ``
      },
      {
        title: 'Base Order',
        placeholder: props.initialUnitsPlaceholder,
        description: props.botStyleDesc,
        suffix: ' units'
      },
      {
        title: 'Next Order Trigger',
        placeholder: props.additionalOrderStepPlaceholder,
        description: props.botStyleDesc,
        suffix: ' pips'
      },
      {
        title: 'Order Increment',
        placeholder: props.unitAdditionPlaceholder,
        description: props.botStyleDesc,
        suffix: ' units'
      },
      // {
      //   title: 'Unit Multiplier',
      //   placeholder: props.unitMultiplierPlaceholder,
      //   description: props.botStyleDesc
      // },
      {
        title: 'Min Profit Exit',
        placeholder: props.profitPriceConstantPlaceholder,
        description: props.botStyleDesc,
        suffix: ' pips'
      },
      {
        title: 'Profit Buffer',
        placeholder: props.stopLimitBufferPlaceholder,
        description: props.botStyleDesc,
        suffix: ' pips'
      },
      // {
      //   title: 'Profit Exit Step',
      //   placeholder: props.stopLimitGranularityPlaceholder,
      //   description: props.botStyleDesc,
      //   suffix: ' pips'
      // },
      {
        title: 'Force Order Timer',
        placeholder: props.orderTimerPlaceholder,
        description: props.orderTimerDesc,
        suffix: ' hours'
      }
      // {
      //   title: 'Bot Style',
      //   placeholder: props.botStylePlaceholder,
      //   description: props.botStyleDesc
      // }
    ]);
  }, [props]);

  useEffect(() => {
    return () => {
      setSettingsConfirmed(false);
      setTabIndex(0);
      setStatus('Settings');
      setMarginError(false);
      setStopLimitBufferError(false);
      setBotName();
      setMaxMarginUtilization();
      // setBotStyle(props.botStylePlaceholder);
      setStopLimitBuffer();
      setOrderTimer();
      setServerError(false);
      setStopLimitGranularity();
      setProfitPriceConstant();
      setInitialUnits();
      setUnitAddition();
      setUnitMultiplier();
      setAdditionalOrderStep();
    };
  }, [props.isopen]);

  const handleInput = (event, field, placeholder) => {
    let v = event.target.value;
    if (field === 'Bot Name') {
      setBotName(v);
    }
  };

  const handleEditSettingButton = () => {
    setStatus('Settings');
    setServerError(false);
    setSettingsConfirmed(false);
    // setBotName();
    // setMaxMarginUtilization();
    // setAdditionalOrderStep();
    // setInitialUnits();
    // setOrderTimer();
    // setUnitAddition();
    // setUnitMultiplier();
    // setStopLimitBuffer();
    // setStopLimitGranularity();
    // setProfitPriceConstant();
    // setBotStyle(props.botStylePlaceholder);
  };

  // const handleBasicPreviewButton = () => {
  //   setStatus('Preview');
  //   setBasicSettingsConfirmed(false);
  // };

  const handlePreviewButton = () => {
    // setStatus('Preview');
    setErrors([]);
    setServerError(false);
    let maxerror = false;
    let uniterror = false;
    let bufferror = false;
    if (
      Number(maxMarginUtilization) >
      userState.oandaData.marginAvailable + Number(props.maxMarginUtilizationPlaceHolder)
    ) {
      maxerror = true;
    }
    if (unitAddition && unitAddition <= 0) {
      uniterror = true;
    }
    if (stopLimitBuffer < 5) {
      bufferror = true;
    }
    setUnitAdditionError(uniterror);
    setMarginError(maxerror);
    setStopLimitBufferError(bufferror);
    // if ((Number(maxMarginUtilization) > 2 || Number(maxMarginUtilization) < 0) && maxMarginUtilization) {
    //   errorsArr.push('Max Margin Utilization');
    // }
    // if ((Number(stopLimitBuffer) > 0.3 || Number(stopLimitBuffer) < 0.2) && stopLimitBuffer) {
    //   errorsArr.push('Stop Limit Buffer');
    // }
    // if ((Number(stopLimitGranularity) > 0.1 || Number(stopLimitGranularity) < 0.03) && stopLimitGranularity) {
    //   errorsArr.push('Stop Limit Granularity');
    // }
    // if (
    //   (Number(profitPriceConstant) > 0.001 || Number(profitPriceConstant) < 0.01) &&
    //   profitPriceConstant
    // ) {
    //   errorsArr.push('Profit Price Constant');
    // }
    // if ((Number(additionalOrderStep) > 20 || Number(additionalOrderStep) < 0.5) && additionalOrderStep) {
    //   errorsArr.push('Additional Order Step');
    // }
    // if ((Number(orderCancelTimer) > 600 || Number(orderCancelTimer) < 60) && orderCancelTimer) {
    //   errorsArr.push('Order Cancel Timer');
    // }
    // if ((Number(extraBuyTimer) > 10 || Number(extraBuyTimer) < 1) && extraBuyTimer) {
    //   errorsArr.push('Extra Buy Timer');
    // }
    // if ((Number(boostTimerConstant) > 172800 || Number(boostTimerConstant) < 3600) && boostTimerConstant) {
    //   errorsArr.push('Boost Timer Constant');
    // }
    // if (
    //   (Number(firstBoostOrderPercentage) > 30 || Number(firstBoostOrderPercentage) < 1) &&
    //   firstBoostOrderPercentage
    // ) {
    //   errorsArr.push('First Boost Order');
    // }
    if (uniterror || bufferror) {
      return;
    }
    if (!maxerror) {
      if (
        !handleRestriction(
          calculateExchangeID(authState.currentOption),
          authState.stripeCurrentPlan,
          Number(maxMarginUtilization)
            ? Number(maxMarginUtilization)
            : Number(Math.floor(userState.oandaData.marginAvailable / 10)),
          true,
          props.botID
        )
      ) {
        setStatus('Preview');
        return setSettingsConfirmed(false);
      } else {
        return onUpgradeSubOpen();
      }
    }
  };

  const _handleValue = field => {
    if (field === 'Base Order') {
      return initialUnits;
    } else if (field === 'Next Order Trigger') {
      return additionalOrderStep;
    } else if (field === 'Order Increment') {
      return unitAddition;
    } else if (field === 'Min Profit Exit') {
      return profitPriceConstant;
    } else if (field === 'Profit Buffer') {
      return stopLimitBuffer;
    } else if (field === 'Profit Exit Step') {
      return stopLimitGranularity;
    } else if (field === 'Force Order Timer') {
      return orderTimer;
    } else if (field === 'Max Margin to Use') {
      return maxMarginUtilization;
    } else if (field === 'Bot Name') {
      return botName;
    }
  };

  // useEffect(() => {
  //   // console.log(tabIndex);
  //   if (tabIndex === 1) {
  //     setStatus('Advanced Settings');
  //   } else {
  //     setStatus('Basic Settings');
  //   }
  // }, [tabIndex]);

  const handleConfirmSettings = async () => {
    try {
      setLoading(true);
      // if (maxAmount && Number(maxAmount.slice(1)) < minAmount) {
      //   console.log(Number(maxAmount.slice(1)), minAmount, maxAmount);
      //   setBasicLoading(false);
      //   return console.log('max error');
      // }
      // const body = {
      //   bot_id: props.botID,
      //   // max_amount: maxAmount && maxAmount !== '' ? Number(maxAmount.slice(1)) : minAmount,
      //   max_margin_utilization: maxMarginUtilization
      //     ? Number(maxMarginUtilization)
      //     : Number(props.maxMarginUtilizationPlaceHolder),
      //   // bot_style: botStyle.toLowerCase(),
      //   bot_name: botName && botName !== '' ? botName : props.botNamePlaceholder,
      //   initial_units: initialUnits ? Number(initialUnits) : Number(props.initialUnitsPlaceholder),
      //   unit_addition: unitAddition ? Number(unitAddition) : Number(props.unitAdditionPlaceholder),
      //   unit_multiplier: unitMultiplier ? Number(unitMultiplier) : Number(props.unitMultiplierPlaceholder),
      //   order_timer: orderTimer ? Number(orderTimer) : Number(props.orderTimerPlaceholder),
      //   additional_order_step: additionalOrderStep
      //     ? Number(additionalOrderStep)
      //     : Number(props.additionalOrderStepPlaceholder),
      //   profit_price_constant: profitPriceConstant
      //     ? Number(profitPriceConstant)
      //     : Number(props.profitPriceConstantPlaceholder),
      //   stop_limit_granularity: stopLimitGranularity
      //     ? Number(stopLimitGranularity)
      //     : Number(props.stopLimitGranularityPlaceholder),
      //   stop_limit_buffer: stopLimitBuffer ? Number(stopLimitBuffer) : Number(props.stopLimitBufferPlaceholder)
      // };
      const handlePips = type => {
        let number = props.pair?.includes('JPY') ? type / 100 : type / 10000;
        return Number(number);
      };

      let body = {
        bot_name: botName && botName !== '' ? botName : props.botNamePlaceholder,
        uuid: authState.id,
        stripeCustomerId: authState.stripeCustomerId,
        exchange_id: calculateExchangeID(authState.currentOption),
        bot_id: props.botID,
        max_margin_utilization: maxMarginUtilization
          ? Number(maxMarginUtilization)
          : Number(props.maxMarginUtilizationPlaceHolder),
        initial_units: initialUnits ? Number(initialUnits) : Number(props.initialUnitsPlaceholder),
        unit_addition: unitAddition ? Number(unitAddition) : Number(props.unitAdditionPlaceholder),
        unit_multiplier: 1,
        profit_price_constant: profitPriceConstant
          ? handlePips(profitPriceConstant)
          : Number(props.profitPriceConstantPlaceholder),
        additional_order_step: additionalOrderStep
          ? handlePips(additionalOrderStep)
          : Number(props.additionalOrderStepPlaceholder),
        // stop_limit_granularity: stopLimitGranularity
        //   ? handlePips(stopLimitGranularity)
        //   : Number(props.stopLimitGranularityPlaceholder),
        stop_limit_buffer: stopLimitBuffer
          ? handlePips(stopLimitBuffer)
          : Number(props.stopLimitBufferPlaceholder),
        order_timer: orderTimer ? Number(orderTimer) : props.orderTimerPlaceholder
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}editfxbotsettings`, body);
      if (response.data.success) {
        setSettingsConfirmed(true);
      } else {
        setServerError(response.data.error);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const renderPlaceholder = title => {
    if (title === 'Base Order') {
      return `${props.initialUnitsPlaceholder} units`;
    } else if (title === 'Next Order Trigger') {
      return `${
        props.pair.includes('JPY')
          ? Number(new Decimal(props.additionalOrderStepPlaceholder).times(100))
          : Number(new Decimal(props.additionalOrderStepPlaceholder).times(10000))
      } pips`;
    } else if (title === 'Order Increment') {
      return `${props.unitAdditionPlaceholder} units`;
    } else if (title === 'Min Profit Exit') {
      return `${
        props.pair.includes('JPY')
          ? Number(new Decimal(props.profitPriceConstantPlaceholder).times(100))
          : Number(new Decimal(props.profitPriceConstantPlaceholder).times(10000))
      } pips`;
    } else if (title === 'Profit Buffer') {
      return `${
        props.pair.includes('JPY')
          ? Number(new Decimal(props.stopLimitBufferPlaceholder).times(100))
          : Number(new Decimal(props.stopLimitBufferPlaceholder).times(10000))
      } pips`;
    } else if (title === 'Profit Exit Step') {
      return `${
        props.pair.includes('JPY')
          ? Number(new Decimal(props.stopLimitGranularityPlaceholder).times(100))
          : Number(new Decimal(props.stopLimitGranularityPlaceholder).times(10000))
      } pips`;
    } else if (title === 'Force Order Timer') {
      return `${props.orderTimerPlaceholder} hour(s)`;
    } else if (title === 'Max Margin to Use') {
      return dynamicCurrencyFormatter(userState.baseCurrency).format(props.maxMarginUtilizationPlaceHolder);
    }
  };

  const renderChangesScreen = () => {
    let text = `You are about to make below changes.`;
    if (status === 'Preview') {
      return (
        <Flex direction="column" w="full">
          <Text mb={6} fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
            {text}
          </Text>
          <Divider mb={4} />
          <Flex justify="space-between" alignItems="center" mb={4}>
            <Text mr={8} fontSize="sm">
              Bot Name:{' '}
            </Text>
            <Text fontWeight="semibold" fontSize="sm">
              {botName ? botName : props.botNamePlaceholder}
            </Text>
          </Flex>
          <Flex justify="space-between" alignItems="center" mb={4}>
            <Text mr={8} fontSize="sm">
              Max Margin to Use:{' '}
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              {maxMarginUtilization
                ? dynamicCurrencyFormatter(userState.baseCurrency).format(maxMarginUtilization)
                : dynamicCurrencyFormatter(userState.baseCurrency).format(props.maxMarginUtilizationPlaceHolder)}
            </Text>
          </Flex>
          <Flex justify="space-between" alignItems="center" mb={4}>
            <Text mr={8} fontSize="sm">
              Base Order:{' '}
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              {initialUnits ? initialUnits + ' units' : props.initialUnitsPlaceholder + ' units'}
            </Text>
          </Flex>
          <Flex justify="space-between" alignItems="center" mb={4}>
            <Text mr={8} fontSize="sm">
              Next Order Trigger:{' '}
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              {additionalOrderStep
                ? additionalOrderStep + ' pip(s)'
                : props.pair.includes('JPY')
                ? Number(new Decimal(props.additionalOrderStepPlaceholder).times(100)) + ' pip(s)'
                : Number(new Decimal(props.additionalOrderStepPlaceholder).times(10000)) + ' pip(s)'}
            </Text>
          </Flex>
          <Flex justify="space-between" alignItems="center" mb={4}>
            <Text fontSize="sm" mr={8}>
              Order Increment:{' '}
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              {unitAddition ? unitAddition + ' units' : props.unitAdditionPlaceholder + ' units'}
            </Text>
          </Flex>
          {/* <Flex justify="space-between" alignItems="center" mb={4}>
            <Text mr={8} fontWeight="semibold">
              Unit Multiplier:{' '}
            </Text>
            <Text>{unitMultiplier ? unitMultiplier : props.unitMultiplierPlaceholder}</Text>
          </Flex> */}
          <Flex justify="space-between" alignItems="center" mb={4}>
            <Text fontSize="sm" mr={8}>
              Min Profit Exit:{' '}
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              {profitPriceConstant
                ? profitPriceConstant + ' pip(s)'
                : props.pair.includes('JPY')
                ? Number(new Decimal(props.profitPriceConstantPlaceholder).times(100)) + ' pip(s)'
                : Number(new Decimal(props.profitPriceConstantPlaceholder).times(10000)) + ' pip(s)'}
            </Text>
          </Flex>
          <Flex justify="space-between" alignItems="center" mb={4}>
            <Text fontSize="sm" mr={8}>
              Profit Buffer:{' '}
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              {stopLimitBuffer
                ? stopLimitBuffer + ' pip(s)'
                : props.pair.includes('JPY')
                ? Number(new Decimal(props.stopLimitBufferPlaceholder).times(100)) + ' pip(s)'
                : Number(new Decimal(props.stopLimitBufferPlaceholder).times(10000)) + ' pip(s)'}
            </Text>
          </Flex>
          {/* <Flex justify="space-between" alignItems="center" mb={4}>
            <Text fontSize="sm" mr={8}>
              Profit Exit Step:{' '}
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              {stopLimitGranularity
                ? stopLimitGranularity + ' pip(s)'
                : props.pair.includes('JPY')
                ? Number(new Decimal(props.stopLimitGranularityPlaceholder).times(100)) + ' pip(s)'
                : Number(new Decimal(props.stopLimitGranularityPlaceholder).times(10000)) + ' pip(s)'}
            </Text>
          </Flex> */}
          <Flex justify="space-between" alignItems="center" mb={4}>
            <Text fontSize="sm" mr={8}>
              Force Order Timer:{' '}
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              {orderTimer ? orderTimer + ' hour(s)' : props.orderTimerPlaceholder + ' hour(s)'}
            </Text>
          </Flex>
          {/* <Flex direction="column">
            <Button
              title="Edit Settings"
              w="full"
              fontSize="sm"
              mt={8}
              bg={colorMode === 'light' ? 'white' : 'transparent'}
              // borderColor={epicFlowGradientColors.left}
              borderColor={props.bg}
              borderWidth={1}
              _hover={{
                bg: colorMode === 'light' ? 'white' : 'transparent'
              }}
              _focus={{
                outline: 'none'
              }}
              _active={{
                bg: colorMode === 'light' ? 'white' : 'transparent'
              }}
              // color={epicFlowGradientColors.left}
              color={userState.colorStatus === 'up' ? statusColors.up : statusColors.down}
              onClick={() => handleEditSettingButton()}
            >
              Edit Settings
            </Button>
            {!settingsConfirmed && (
              <EFButton
                title="Confirm"
                w="full"
                mt={4}
                mb={4}
                bg={epicFlowGradientColors.left}
                onClick={handleConfirmSettings}
                isLoading={loading}
              />
            )}
          </Flex> */}
          {settingsConfirmed && !serverError ? (
            <Alert
              w="full"
              textAlign="center"
              px={0}
              status="success"
              variant="unstyled"
              fontSize="13px"
              flexDirection="column"
            >
              <Divider mb={4} w="full" />
              <AlertIcon color="green.400" mb={4} boxSize="28px" />
              Your changes have been saved. It may take some time to see your changes.
            </Alert>
          ) : serverError ? (
            <Flex mt={4} w="90%" justify="flex-start" align="center" direction="column">
              <Heading size="sm" mt={2} mb={3} fontWeight="semibold">
                Oh Snap!
              </Heading>
              <WarningTwoIcon color="red.400" boxSize={8} mb={4} />
              <Text fontSize="sm" mb={2}>
                {serverError}.
              </Text>
              {/* <EFButton
                bg={props.bg}
                w="full"
                isLoading={botState.loading}
                mt={2}
                mb={4}
                title="Done"
                onClick={handleDone}
              /> */}
            </Flex>
          ) : null}
        </Flex>
      );
    }
  };

  return (
    <Modal
      isOpen={props.isopen}
      onClose={props.onclose}
      isCentered
      returnFocusOnClose={false}
      motionPreset="slideInBottom"
      scrollBehavior="inside"
      allowPinchZoom
    >
      <UpgradeSub isopen={isUpgradeSubOpen} onclose={handleUpgradeSubModalClose} />

      <ModalOverlay />
      <ModalContent borderRadius="2xl" bg={colorMode === 'light' ? 'white' : '#1f2124'}>
        <ModalCloseButton
          _focus={{
            outline: 'none'
          }}
        />
        <ModalHeader fontWeight="bold" fontSize="lg" mt={10} pb={1} pt={0}>
          Settings ( {props.name} )
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={2}>
          <Flex direction="column" justify="flex-start" align="flex-start" w="full">
            {status === 'Settings' && settings.length > 0
              ? settings.map((el, idx) => {
                  return (
                    <Flex direction="column" w="full" key={idx} justify="space-evenly" alignItems="center" mt={4}>
                      {/* <AdvancedInput
                        description={el.description}
                        title={el.title}
                        width="12rem"
                        ot={el.title === 'Order Timer'}
                        value={handleValue(el.title, el.placeholder)}
                        handleChange={e => handleInput(e, el.title, el.placeholder)}
                        placeholder={`Current: ${el.placeholder}`}
                        type={el.title === 'Bot Name' ? 'text' : 'number'}
                      /> */}
                      {el.title === 'Bot Name' ? (
                        <AdvancedInput
                          description={el.description}
                          title={el.title}
                          width="12rem"
                          ot={el.title === 'Order Timer'}
                          value={_handleValue(el.title)}
                          handleChange={e => handleInput(e, el.title, el.placeholder)}
                          placeholder={`${el.placeholder}`}
                          type={el.title === 'Bot Name' ? 'text' : 'number'}
                        />
                      ) : (
                        <FormattedInput
                          tmb={2}
                          style={{
                            outline: 'none',
                            borderColor:
                              // (el.title === 'Max Margin to Use' &&
                              //   Number(maxMarginUtilization) >
                              //     userState.oandaData.marginAvailable +
                              //       Number(props.maxMarginUtilizationPlaceHolder)) ||
                              // (el.title === 'Order Increment' && Number(unitAddition) <= 0)
                              //   ? 'red'
                              //   : ''
                              ''
                          }}
                          preventDecimals={el.title === 'Force Order Timer'}
                          column
                          title={el.title}
                          suffix={el.suffix ? el.suffix : null}
                          prefix={el.prefix ? el.prefix : null}
                          placeholder={renderPlaceholder(el.title)}
                          value={_handleValue(el.title)}
                          onValueChange={(value, name, values) => {
                            if (el.title === 'Base Order') {
                              setInitialUnits(value);
                            } else if (el.title === 'Next Order Trigger') {
                              setAdditionalOrderStep(value);
                            } else if (el.title === 'Order Increment') {
                              let uniterror = false;
                              if (Number(value) <= 0) {
                                uniterror = true;
                              }
                              setUnitAdditionError(uniterror);
                              setUnitAddition(value);
                            } else if (el.title === 'Min Profit Exit') {
                              setProfitPriceConstant(value);
                            } else if (el.title === 'Profit Buffer') {
                              setStopLimitBuffer(value);
                            } else if (el.title === 'Profit Exit Step') {
                              setStopLimitGranularity(value);
                            } else if (el.title === 'Force Order Timer') {
                              setOrderTimer(value);
                            } else if (el.title === 'Max Margin to Use') {
                              let maxerror = false;
                              // console.log(
                              //   userState.oandaData.marginAvailable + Number(props.maxMarginUtilizationPlaceHolder)
                              // );
                              // if (
                              //   Number(value) >
                              //   userState.oandaData.marginAvailable + Number(props.maxMarginUtilizationPlaceHolder)
                              // ) {
                              //   maxerror = true;
                              // }
                              setMarginError(maxerror);
                              setMaxMarginUtilization(value);
                            }
                          }}
                        />
                      )}
                      {/* {el.title === 'Max Margin to Use' && (
                        <Flex justifyContent="flex-start" alignItems="center" w="full">
                          <Text fontSize="11px" fontWeight="medium" mt={1} color="gray.500">
                            {`Available Margin: ${dynamicCurrencyFormatter(userState.baseCurrency).format(
                              userState.oandaData.marginAvailable
                            )}`}
                          </Text>
                        </Flex>
                      )} */}
                      {el.title === 'Base Order' && (
                        <Flex justifyContent="flex-start" alignItems="center" w="full">
                          <Text fontSize="11px" fontWeight="medium" mt={1} color="gray.500">
                            {props.pair.split('_')[0] === 'USD'
                              ? `${
                                  initialUnits ? initialUnits : Number(props.initialUnitsPlaceholder)
                                } units = ${dynamicCurrencyFormatter(userState.baseCurrency).format(
                                  (initialUnits ? Number(initialUnits) : Number(props.initialUnitsPlaceholder)) /
                                    (1 / userState.oandaData?.instruments[props.pair].marginRate)
                                )}`
                              : props.pair === 'CAD_CHF' || props.pair === 'CAD_JPY'
                              ? `${
                                  initialUnits ? initialUnits : props.initialUnitsPlaceholder
                                } units = ${dynamicCurrencyFormatter(userState.baseCurrency).format(
                                  (
                                    ((initialUnits
                                      ? Number(initialUnits)
                                      : Number(props.initialUnitsPlaceholder)) /
                                      (1 / userState.oandaData?.instruments[props.pair].marginRate)) *
                                    (1 / userState.oandaData?.instruments[`USD_CAD`]).currentPrice
                                  ).toFixed(2)
                                )}`
                              : `${
                                  initialUnits ? initialUnits : props.initialUnitsPlaceholder
                                } units = ${dynamicCurrencyFormatter(userState.baseCurrency).format(
                                  (
                                    ((initialUnits
                                      ? Number(initialUnits)
                                      : Number(props.initialUnitsPlaceholder)) /
                                      (1 / userState.oandaData?.instruments[props.pair].marginRate)) *
                                    userState.oandaData?.instruments[`${props.pair.split('_')[0]}_USD`]
                                      .currentPrice
                                  ).toFixed(2)
                                )}`}
                          </Text>
                        </Flex>
                      )}
                      {el.title === 'Order Increment' && (
                        <Flex justifyContent="flex-start" alignItems="center" w="full">
                          <Text fontSize="11px" fontWeight="medium" mt={1} color="gray.500">
                            {unitAdditionError ? (
                              <Text color="red">Order Increment should be more than 0.</Text>
                            ) : null}
                          </Text>
                        </Flex>
                      )}
                      {el.title === 'Profit Buffer' && (
                        <Flex justifyContent="flex-start" alignItems="center" w="full">
                          <Text fontSize="11px" fontWeight="medium" mt={1} color="gray.500">
                            {stopLimitBufferError ? (
                              <Text color="red"> Profit Buffer should be 5 or more pips.</Text>
                            ) : null}
                          </Text>
                        </Flex>
                      )}
                      {/* <Tooltip
                              hasArrow
                              label={el.description}
                              whiteSpace="break-spaces"
                              bg="gray.200"
                              color="gray.700"
                              pt={1}
                              pb={1}
                              fontSize="xs"
                            >
                              <span
                                style={{
                                  display: 'flex',
                                  marginLeft: '16px',
                                  marginRight: '8px',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '16px',
                                  height: '16px'
                                }}
                              >
                                <Icon
                                  as={RiInformationLine}
                                  w="16px"
                                  h="16px"
                                  variant="unstyled"
                                  _focus={{
                                    outline: 'none'
                                  }}
                                  color={epicFlowGradientColors.left}
                                />
                              </span>
                            </Tooltip> */}
                    </Flex>
                  );
                })
              : renderChangesScreen()}
            {/* {status === 'Settings' ? (
              <EFButton
                title="Preview"
                w="full"
                mt={8}
                mb={4}
                bg={epicFlowGradientColors.left}
                onClick={handlePreviewButton}
              />
            ) : null} */}
          </Flex>
        </ModalBody>
        <ModalFooter>
          {status === 'Settings' ? (
            <EFButton
              title="Preview"
              w="full"
              mt={8}
              mb={4}
              color={colorMode === 'light' ? 'white' : 'black'}
              bg={colorMode === 'light' ? 'black' : 'white'}
              onClick={handlePreviewButton}
            />
          ) : (
            <Flex alignItems="center" w="full">
              {!settingsConfirmed && (
                <Button
                  title="Edit Settings"
                  w="full"
                  fontSize="13px"
                  // mt={8}
                  bg={colorMode === 'light' ? 'white' : 'transparent'}
                  // borderColor={epicFlowGradientColors.left}
                  mr={4}
                  borderColor={props.bg}
                  borderWidth={1}
                  _hover={{
                    bg: colorMode === 'light' ? 'white' : 'transparent'
                  }}
                  _focus={{
                    outline: 'none'
                  }}
                  _active={{
                    bg: colorMode === 'light' ? 'white' : 'transparent'
                  }}
                  // color={epicFlowGradientColors.left}
                  // color={userState.colorStatus === 'up' ? statusColors.up : statusColors.down}
                  onClick={() => handleEditSettingButton()}
                >
                  Edit Settings
                </Button>
              )}
              {!settingsConfirmed && !serverError && (
                <EFButton
                  title="Confirm"
                  w="full"
                  // mt={4}
                  // mb={4}
                  fontSize="13px"
                  color={colorMode === 'light' ? 'white' : 'black'}
                  bg={colorMode === 'light' ? 'black' : 'white'}
                  onClick={handleConfirmSettings}
                  isLoading={loading}
                />
              )}
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OandaBotSettings;
