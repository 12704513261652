import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { Divider, Flex, Heading, Text, useColorMode } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import axios from '../../utilities/axios';
import FxCalendarCard from './fxCalendarCard';

const FxCalendarCardContainer = props => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [calendarEvents, setCalendarEvents] = useState();
  const [pageNumber, setPageNumber] = useState(0);

  const fetchCalendar = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}geteconomiccalendar`);
      const data = response.data;
      const currentDateInEST = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
      const currentDate = new Date(currentDateInEST);
      const currentDateKey = currentDate.toLocaleDateString('en-US', { timeZone: 'America/New_York' });

      const newData = [];
      let count = 0;
      for (const date in data) {
        if (new Date(date) >= new Date(currentDateKey) && count < 5) {
          newData.push({ [date]: data[date] });
          count++;
        }
      }
      setCalendarEvents(newData);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchCalendar();
  }, []);

  const renderCalendar = useMemo(() => {
    return (
      <Flex w="95%" my={16} borderRadius="lg" justifyContent="flex-start" alignItems="flex-start" flexDir="column">
        <Heading as="h2" size="md" textAlign="left">
          Economic Calendar Events
        </Heading>
        <Divider my={4} />
        <Flex w="full" justifyContent="Flex-start" mb={6}>
          <Flex alignItems="center" mr={6}>
            <Flex w={2} h={2} borderRadius="full" bg="#f3f700" mr={2}></Flex>
            <Text fontSize="13px" color={colorMode === 'light' ? 'gray.600' : 'gray.200'}>
              Low Impact
            </Text>
          </Flex>
          <Flex alignItems="center" mr={6}>
            <Flex w={2} h={2} borderRadius="full" bg="orange.400" mr={2}></Flex>
            <Text fontSize="13px" color={colorMode === 'light' ? 'gray.600' : 'gray.200'}>
              Medium Impact
            </Text>
          </Flex>
          <Flex alignItems="center">
            <Flex w={2} h={2} borderRadius="full" bg="red.500" mr={2}></Flex>
            <Text fontSize="13px" color={colorMode === 'light' ? 'gray.600' : 'gray.200'}>
              High Impact
            </Text>
          </Flex>
        </Flex>
        <Flex w="full" justifyContent="space-between" my={2}>
          <Text fontWeight="bold" fontSize="14px" flex={1} textAlign="left">
            Date
          </Text>
          <Flex flex={1} display={['none', 'none', 'flex']}>
            <Text ml={4} fontWeight="bold" fontSize="14px" textAlign="left">
              Time
            </Text>
          </Flex>
          <Text fontWeight="bold" fontSize="14px" flex={1} textAlign="left">
            Currency
          </Text>
          <Text fontWeight="bold" fontSize="14px" flex={1} textAlign="left">
            Event
          </Text>
        </Flex>
        <Flex
          w="full"
          // css={`
          //   > div:first-of-type {
          //     width: 100% !important;
          //   }
          //   .carousel.carousel-slider {
          //     overflow: inherit;
          //   }

          //   .carousel.carousel-slider .control-prev.control-arrow {
          //     display: block !important;
          //   }

          //   .carousel .control-next.control-arrow,
          //   .carousel .control-next.control-arrow:hover {
          //     background-color: transparent;
          //   }

          //   .carousel .control-prev.control-arrow,
          //   .carousel .control-prev.control-arrow:hover {
          //     background-color: transparent;
          //   }

          //   .carousel .control-arrow,
          //   .carousel.carousel-slider .control-arrow {
          //     opacity: 1;
          //   }

          //   .carousel .control-next.control-arrow:before {
          //     content: '';
          //     border: solid ${
          //       colorMode === 'light' && pageNumber < 4
          //         ? '#718096;'
          //         : colorMode === 'dark' && pageNumber < 4
          //         ? '#a0aec0;'
          //         : '#E2E8F0;'
          //     }
          //     border-width: 0 2px 2px 0;
          //     display: inline-block;
          //     padding: 4px;
          //     transform: rotate(-45deg);
          //     -webkit-transform: rotate(-45deg);
          //   }

          //   .carousel .control-prev.control-arrow:before {
          //     content: '';
          //     border: solid ${
          //       colorMode === 'light' && pageNumber > 0
          //         ? '#718096;'
          //         : colorMode === 'dark' && pageNumber > 0
          //         ? '#a0aec0;'
          //         : '#E2E8F0;'
          //     }            border-width: 0 2px 2px 0;
          //     display: inline-block;
          //     padding: 4px;
          //     transform: rotate(135deg);
          //     -webkit-transform: rotate(135deg);
          //   }
          // `}
        >
          <Flex w="full" mt={6} flexDirection="column" overflowX="hidden">
            {calendarEvents &&
              calendarEvents.map((items, idx) => {
                {
                  return items[Object.keys(items)[0]].map((item, index) => {
                    return (
                      <FxCalendarCard
                        key={index}
                        date={Object.keys(items)[0]}
                        pageNumber={pageNumber}
                        time={item.time}
                        currency={item.currency}
                        impact={item.impact}
                        event={item.event}
                      />
                    );
                  });
                }
              })}
          </Flex>
          {/* <Carousel
            onChange={(currentIndex, item) => setPageNumber(currentIndex)}
            transitionTime={750}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            dynamicHeight
          >
            {calendarEvents &&
              calendarEvents.map((items, idx) => {
                return (
                  <Flex w="full" mt={6} flexDirection="column" key={idx}>
                    {items[Object.keys(items)[0]].map((item, index) => {
                      return (
                        <FxCalendarCard
                          key={index}
                          date={Object.keys(items)[0]}
                          pageNumber={pageNumber}
                          time={item.time}
                          currency={item.currency}
                          impact={item.impact}
                          event={item.event}
                        />
                      );
                    })}
                  </Flex>
                );
              })}
          </Carousel> */}
        </Flex>
      </Flex>
    );
  }, [calendarEvents, colorMode]);

  return renderCalendar;
};

export default FxCalendarCardContainer;
