import { Flex, Heading } from '@chakra-ui/react';
import React from 'react';

const PageNotFound = () => {
  return (
    <Flex h="full" w="full" justify="center" align="center">
      <Heading>Oopps! Page not Found</Heading>
    </Flex>
  );
};

export default PageNotFound;
