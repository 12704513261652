import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Collapse,
  Divider,
  Flex,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  Text,
  useColorMode,
  useDisclosure,
  Wrap
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { set } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';

import SocialCard from '../components/cards/socialCard';
import AdvancedInput from '../components/UI/Input/advancedInput';
import { getSocialBots } from '../store/actions/social';
import axios from '../utilities/axios';
import { calculateBaseCurrencyIndex, setBaseCurrency } from '../utilities/exchangeHelpers';
import { dispatchSetPairs, setTargets } from '../utilities/wizardMenuItemsHelper';
import classes from './social.module.css';

const Social = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const dispatch = useDispatch();

  const socialState = useSelector(state => state.social);
  const authState = useSelector(state => state.auth);
  const userState = useSelector(state => state.user);

  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onConfirmationModalOpen,
    onClose: onConfirmationModalClose
  } = useDisclosure();

  const { isOpen: isMobileModalOpen, onOpen: onMobileModalOpen, onClose: onMobileModalClose } = useDisclosure();

  const [showMorePairs, setShowMorePairs] = useState(false);

  const handleShowMorePairsToggle = () => setShowMorePairs(!showMorePairs);

  const [showMoreTargets, setShowMoreTargets] = useState(false);

  const handleShowMoreTargetsToggle = () => setShowMoreTargets(!showMoreTargets);

  const [pairs, setPairs] = useState([]);
  const [pairBy, setPairBy] = useState('ALL');
  const [adjustableTargetFreeAmount, setAdjustableTargetFreeAmount] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [sortBy, setSortBy] = useState('Best Performing');
  const [showBy, setShowBy] = useState('ALL');
  const [exchangeBy, setExchangeBy] = useState('ALL');
  const [runTimeBy, setRunTimeBy] = useState(`> 1 Day`);
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);
  const [availableTargets, setAvailableTargets] = useState([]);
  const [currentTarget, setCurrentTarget] = useState();
  const [currentPair, setCurrentPair] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [availablePairs, setAvailablePairs] = useState([]);
  const [pairsInfo, setPairsInfo] = useState([]);
  const [maxAmountPlaceholder, setMaxAmountPlaceholder] = useState();
  const [maxAmount, setMaxAmount] = useState('');
  const [botName, setBotName] = useState('');
  const [nameError, setNameError] = useState();
  const [maxAmountError, setMaxAmountError] = useState();
  const [botID, setBotID] = useState();
  const [excID, setExcID] = useState();
  const [mobileModalMode, setMobileModalMode] = useState();
  const [checkboxSnapShot, setCheckboxSnapShot] = useState();
  const [requestBody, setRequestBody] = useState({
    status: 'ALL',
    exchange: 'ALL',
    pair: 'ALL',
    symbol_to_profit: 'ALL',
    time: '1d',
    APR_threshold: '0',
    page: 0,
    sort: 'APR'
  });

  const [pairCheckedItems, setPairCheckedItems] = useState([]);

  const [pairCheckedValues, setPairCheckedValues] = useState([]);

  const [defaultPairValues, setDefaultPairValues] = useState([]);

  const allPairsChecked = pairCheckedItems.every(Boolean);

  const [targetCheckedItems, setTargetCheckedItems] = useState([]);

  const [targetCheckedValues, setTargetCheckedValues] = useState([]);

  const [defaultTargetValues, setDefaultTargetValues] = useState([]);

  // const [defaultStatusValues, setDefaulStatusValues] = useState(['Active', 'Completed', 'Running']);

  useEffect(() => {
    if (!mobileModalMode) {
      dispatch(getSocialBots(requestBody));
    }
  }, [requestBody, mobileModalMode]);

  const calculateExchangeKey = useCallback(
    option => {
      let combinedKey;
      let binanceusKey;
      let binancecomKey;
      let coinbaseproKey;
      for (let i = 0; i < userState.assetData.length; i++) {
        if (userState.assetData[i]['combined']) {
          combinedKey = i;
        } else if (userState.assetData[i]['binanceus']) {
          binanceusKey = i;
        } else if (userState.assetData[i]['binancecom']) {
          binancecomKey = i;
        }
        if (userState.assetData[i]['coinbasepro']) {
          coinbaseproKey = i;
        }
      }
      // investigate below console log
      // console.log('option ', option);
      if (option === 'Binance US') {
        return {
          exc: 'binanceus',
          key: binanceusKey
        };
      } else if (option === 'Coinbase') {
        return {
          exc: 'coinbasepro',
          key: coinbaseproKey
        };
      } else if (option === 'Binance') {
        return {
          exc: 'binancecom',
          key: binancecomKey
        };
      } else if (option === 'Total') {
        return {
          exc: 'combined',
          key: combinedKey
        };
      }
    },
    [authState.currentOption, userState.assetData]
  );

  useEffect(() => {
    let pairItems = [];
    let pairValues = [];
    let pdv = [];
    if (socialState.pairs) {
      if (defaultPairValues.length === 0) {
        for (let item of socialState.pairs) {
          pairItems.push(true);
          pairValues.push(
            item === 'ALL' ? item : `${item.slice(0, item.indexOf('/'))}${item.slice(item.indexOf('/') + 1)}`
          );
          pdv.push(
            item === 'ALL' ? item : `${item.slice(0, item.indexOf('/'))}${item.slice(item.indexOf('/') + 1)}`
          );
        }
        setPairCheckedItems(pairItems);
        setPairCheckedValues(pairValues);
        setDefaultPairValues(pdv);
      }
    }
  }, [socialState.pairs]);

  useEffect(() => {
    let targetItems = [];
    let targetValues = [];
    let tdv = [];
    if (socialState.targets) {
      if (defaultTargetValues.length === 0) {
        for (let item of socialState.targets) {
          targetItems.push(true);
          targetValues.push(item);
          tdv.push(item);
        }
        setTargetCheckedItems(targetItems);
        setTargetCheckedValues(targetValues);
        setDefaultTargetValues(tdv);
      }
    }
  }, [socialState.targets]);

  useEffect(() => {
    // console.log('pcv ', pairCheckedValues, defaultPairValues, targetCheckedValues);
    // setRequestBody({
    //   ...requestBody,
    //   pair: pairCheckedValues.includes('ALL') ? 'ALL' : [...pairCheckedValues]
    // });
  }, [pairCheckedValues, defaultPairValues, targetCheckedValues]);

  const handleCheckbox = (e, field) => {
    if (field === 'Pairs') {
      let items = [...pairCheckedItems];
      let values = [...pairCheckedValues];
      let dpv = [...defaultPairValues];
      let idx = dpv.indexOf(e.target.value);
      let vidx = values.indexOf(e.target.value);
      if (e.target.value === 'ALL' && e.target.checked) {
        items.fill(true, 0);
        values = [...dpv];
        setPairCheckedValues(values);
        setPairCheckedItems(items);
        setRequestBody({
          ...requestBody,
          pair: values.includes('ALL') ? 'ALL' : [...values]
        });
        return;
      } else if (e.target.value !== 'ALL' && e.target.checked) {
        // console.log('value ', e.target.value, e.target.checked);
        // console.log('idx ', idx);
        items[idx] = true;
        // console.log('items ', items);
        values.push(e.target.value);
        if (!values.includes('ALL') && items.slice(1).every(Boolean)) {
          values.splice(0, 0, 'ALL');
          items[0] = true;
        }
      } else if (!e.target.checked) {
        // console.log('value ', e.target.value, e.target.checked);
        // console.log('defaultPairValues ', defaultPairValues);
        // console.log('idx ', idx);
        items.splice(0, 1, false);
        items.splice(idx, 1, false);
        // console.log('items ', items);
        values.splice(vidx, 1);
        if (values.includes('ALL')) {
          values.splice(0, 1);
        }
        // console.log('value ', values);
      }
      setPairCheckedValues(values);
      setPairCheckedItems(items);
      setRequestBody({
        ...requestBody,
        pair: values.includes('ALL') ? 'ALL' : [...values]
      });
    } else if (field === 'Targets') {
      let items = [...targetCheckedItems];
      let values = [...targetCheckedValues];
      let dpv = [...defaultTargetValues];
      // console.log('defaultTargetValues ', defaultTargetValues);
      let idx = dpv.indexOf(e.target.value);
      let vidx = values.indexOf(e.target.value);
      if (e.target.value === 'ALL' && e.target.checked) {
        items.fill(true, 0);
        values = [...dpv];
        setTargetCheckedValues(values);
        setTargetCheckedItems(items);
        setRequestBody({
          ...requestBody,
          symbol_to_profit: values.includes('ALL') ? 'ALL' : [...values]
        });
        return;
      } else if (e.target.value !== 'ALL' && e.target.checked) {
        // console.log('value ', e.target.value, e.target.checked);
        // console.log('idx ', idx);
        items[idx] = true;
        // console.log('items ', items);
        values.push(e.target.value);
        if (!values.includes('ALL') && items.slice(1).every(Boolean)) {
          values.splice(0, 0, 'ALL');
          items[0] = true;
        }
      } else if (!e.target.checked) {
        // console.log('defaultPairValues ', defaultPairValues);
        items.splice(0, 1, false);
        items.splice(idx, 1, false);
        values.splice(vidx, 1);
        if (values.includes('ALL')) {
          values.splice(0, 1);
        }
      }
      setTargetCheckedValues(values);
      setTargetCheckedItems(items);
      setRequestBody({
        ...requestBody,
        symbol_to_profit: values.includes('ALL') ? 'ALL' : [...values]
      });
    }
  };

  const handleMobileCheckbox = (e, field) => {
    // onMobileModalOpen();
    if (field === 'Pairs') {
      let items = [...pairCheckedItems];
      let values = [...pairCheckedValues];
      let dpv = [...defaultPairValues];
      let idx = dpv.indexOf(e.target.value);
      let vidx = values.indexOf(e.target.value);
      if (e.target.value === 'ALL' && e.target.checked) {
        items.fill(true, 0);
        values = [...dpv];
        setPairCheckedValues(values);
        setPairCheckedItems(items);
        setRequestBody({
          ...requestBody,
          pair: values.includes('ALL') ? 'ALL' : [...values]
        });
        return {
          items: items,
          values: values,
          requestBody: {
            ...requestBody,
            pair: values.includes('ALL') ? 'ALL' : [...values]
          }
        };
      } else if (e.target.value !== 'ALL' && e.target.checked) {
        items[idx] = true;
        values.push(e.target.value);
        if (!values.includes('ALL') && items.slice(1).every(Boolean)) {
          values.splice(0, 0, 'ALL');
          items[0] = true;
        }
      } else if (!e.target.checked) {
        items.splice(0, 1, false);
        items.splice(idx, 1, false);
        values.splice(vidx, 1);
        if (values.includes('ALL')) {
          values.splice(0, 1);
        }
      }
      setPairCheckedValues(values);
      setPairCheckedItems(items);
      setRequestBody({
        ...requestBody,
        pair: values.includes('ALL') ? 'ALL' : [...values]
      });
    } else if (field === 'Targets') {
      let items = [...targetCheckedItems];
      let values = [...targetCheckedValues];
      let dpv = [...defaultTargetValues];
      let idx = dpv.indexOf(e.target.value);
      let vidx = values.indexOf(e.target.value);
      if (e.target.value === 'ALL' && e.target.checked) {
        items.fill(true, 0);
        values = [...dpv];
        setTargetCheckedValues(values);
        setTargetCheckedItems(items);
        setRequestBody({
          ...requestBody,
          symbol_to_profit: values.includes('ALL') ? 'ALL' : [...values]
        });
        return;
      } else if (e.target.value !== 'ALL' && e.target.checked) {
        items[idx] = true;
        values.push(e.target.value);
        if (!values.includes('ALL') && items.slice(1).every(Boolean)) {
          values.splice(0, 0, 'ALL');
          items[0] = true;
        }
      } else if (!e.target.checked) {
        items.splice(0, 1, false);
        items.splice(idx, 1, false);
        values.splice(vidx, 1);
        if (values.includes('ALL')) {
          values.splice(0, 1);
        }
      }
      setTargetCheckedValues(values);
      setTargetCheckedItems(items);
      setRequestBody({
        ...requestBody,
        symbol_to_profit: values.includes('ALL') ? 'ALL' : [...values]
      });
    }
  };

  const handleMobileDone = useCallback(() => {
    dispatch(getSocialBots(requestBody));
    onMobileModalClose();
    setMobileModalMode();
  }, [requestBody]);

  const handleMobileModalOpen = field => {
    onMobileModalOpen();
    setCheckboxSnapShot({
      pci: pairCheckedItems,
      pcv: pairCheckedValues,
      tci: targetCheckedItems,
      tcv: targetCheckedValues
    });
    if (field === 'Pairs') {
      setMobileModalMode('Pairs');
    } else if (field === 'Targets') {
      setMobileModalMode('Targets');
    }
  };

  const handleMobileMenuClose = () => {
    setPairCheckedValues(checkboxSnapShot.pcv);
    setPairCheckedItems(checkboxSnapShot.pci);
    setTargetCheckedValues(checkboxSnapShot.tcv);
    setTargetCheckedItems(checkboxSnapShot.tci);
    onMobileModalClose();
    setMobileModalMode();
  };

  useEffect(() => {
    if (authState.exchanges[0] && userState.assetData) {
      setAvailableTargets(setTargets(authState.exchanges[0], userState.assetData));
    }
  }, [authState.exchanges, userState.assetData]);

  useEffect(() => {
    //TODO check for pair availability
    if (currentTarget && currentPair && pairsInfo && availablePairs?.includes(currentPair)) {
      let targetFree = Object.values(
        userState.assetData[calculateExchangeKey(authState.currentOption).key][
          calculateExchangeKey(authState.currentOption).exc
        ]
      ).find(e => e.symbol === currentTarget.toLowerCase());
      let free;
      if (targetFree !== undefined && pairsInfo.length > 0) {
        const currentPrice = calculateBaseCurrencyIndex(
          authState.currentOption,
          userState.assetData,
          calculateExchangeKey(authState.currentOption).key,
          calculateExchangeKey(authState.currentOption).exc,
          currentTarget.toLowerCase()
        ).current_price;
        let quote = pairsInfo.find(e => Object.keys(e)[0] === currentPair)[currentPair].quote;
        if (currentTarget === quote) {
          free = targetFree.free;
          setMaxAmountPlaceholder(`Max ${free} ${currentTarget}`);
          setAdjustableTargetFreeAmount(Number(maxAmount) * currentPrice);
        } else {
          let pairPrice = pairsInfo.find(e => Object.keys(e)[0] === currentPair)[currentPair].price;
          let max = targetFree.free * pairPrice;
          setMaxAmountPlaceholder(`Max ${max.toFixed(6)} ${quote}`);
          setAdjustableTargetFreeAmount((Number(maxAmount) * targetFree.free * currentPrice) / max);

          // setInvestmentAmount(amount);
        }
      }
    }
  }, [currentTarget, currentPair, pairsInfo, maxAmount]);

  const handleInput = (e, field) => {
    const currentPrice = calculateBaseCurrencyIndex(
      authState.currentOption,
      userState.assetData,
      calculateExchangeKey(authState.currentOption).key,
      calculateExchangeKey(authState.currentOption).exc,
      currentTarget.toLowerCase()
    ).current_price;
    let targetFree = Object.values(
      userState.assetData[calculateExchangeKey(authState.currentOption).key][
        calculateExchangeKey(authState.currentOption).exc
      ]
    ).find(e => e.symbol === currentTarget.toLowerCase());
    let quote = pairsInfo.find(e => Object.keys(e)[0] === currentPair)[currentPair].quote;
    let pairPrice = pairsInfo.find(e => Object.keys(e)[0] === currentPair)[currentPair].price;
    let max = targetFree.free * pairPrice;
    if (field === 'Amount') {
      if (e.target.value === '') {
        setMaxAmount('');
      } else {
        if (currentTarget === quote) {
          setAdjustableTargetFreeAmount(Number(e.target.value) * currentPrice);
        } else {
          setAdjustableTargetFreeAmount((Number(e.target.value) * targetFree.free * currentPrice) / Number(max));
        }
        setMaxAmount(e.target.value);
      }
    } else {
      setNameError();
      setBotName(e.target.value);
    }
  };

  const handleConfirmationModalClose = () => {
    setResult();
    // setCurrentTarget();
    // setCurrentPair();
    // setCurrentDate();
    onConfirmationModalClose();
    setNameError();
    setMaxAmountError();
    setBotName('');
    setMaxAmount('');
  };

  const handleOnCopy = async (target, pair, date, id, exc) => {
    //Once modali ac sonra islemleri yap
    // if (pair === 'QLCBTC') {
    //   setCurrentTarget(target);
    //   setCurrentPair('QLCBTC');
    //   return onConfirmationModalOpen();
    // } else if (pair === 'MINABNB') {
    //   setCurrentTarget(target);
    //   setCurrentPair('MINABNB');
    //   return onConfirmationModalOpen();
    // }
    setResult();
    setCurrentTarget(target);
    setPairsInfo();
    setAvailablePairs();
    setCurrentPair(pair);
    setBotID(id);
    setExcID(exc);
    setCurrentDate(new Date(date).toDateString());
    let pairs = await dispatchSetPairs(
      authState.currentOption === 'Binance US' ? 1 : 3,
      authState.id,
      userState.assetData,
      target,
      authState.currentOption
    );
    setPairsInfo(pairs.pairsInfo);
    setAvailablePairs(pairs.socialPairs);
    onConfirmationModalOpen();
  };

  const handleCreate = async data => {
    setLoading(true);
    let finalData = {
      id: data.botID,
      user_id: authState.id,
      max_amount: Number(data.amount),
      stripeCustomerId: authState.stripeCustomerId,
      exchange_id: data.excID,
      bot_name: data.botName
    };
    const errors = checkFields({ botName: finalData.bot_name, maxAmount: data.amount });
    if (errors.be) {
      setLoading(false);
      return setNameError(errors.be);
    } else if (errors.ae) {
      setLoading(false);
      return setMaxAmountError(errors.ae);
    } else {
      setNameError();
      setMaxAmountError();
    }
    let response = await axios.post(`${process.env.REACT_APP_API_URL}clonebot`, finalData);
    // let response = await axios.post('http://localhost:5020/clonebot', finalData);
    if (response.data) {
      setLoading(false);
    }
    if (response.data.success) {
      setResult(true);
    }
  };

  const handleShowBy = useCallback(
    option => {
      setShowBy(option);
      setRequestBody({
        ...requestBody,
        status: option === 'ALL' ? 'ALL' : option.toLowerCase()
      });
      dispatch(
        getSocialBots({
          ...requestBody,
          status: option === 'ALL' ? 'ALL' : option.toLowerCase()
        })
      );
    },
    [showBy, requestBody]
  );

  const handleExchangeBy = useCallback(
    option => {
      if (option === 'ALL') {
        setExchangeBy('ALL');
        setRequestBody({
          ...requestBody,
          exchange: option
        });
        dispatch(
          getSocialBots({
            ...requestBody,
            exchange: option
          })
        );
      } else if (option === 'BINANCE US') {
        setExchangeBy('BINANCE US');
        setRequestBody({
          ...requestBody,
          exchange: 'binanceus'
        });
        dispatch(
          getSocialBots({
            ...requestBody,
            exchange: 'binanceus'
          })
        );
      } else if (option === 'BINANCE') {
        setExchangeBy('BINANCE');
        setRequestBody({
          ...requestBody,
          exchange: 'binancecom'
        });
        dispatch(
          getSocialBots({
            ...requestBody,
            exchange: 'binancecom'
          })
        );
      }
    },
    [exchangeBy, requestBody]
  );

  const handleRunTimeBy = useCallback(
    option => {
      if (option === `> 1 Day`) {
        setRunTimeBy(`> 1 Day`);
        setRequestBody({
          ...requestBody,
          time: '1d'
        });
        dispatch(
          getSocialBots({
            ...requestBody,
            time: '1d'
          })
        );
      } else if (option === `> 1 Week`) {
        setRunTimeBy(`> 1 Week`);
        setRequestBody({
          ...requestBody,
          time: '1w'
        });
        dispatch(
          getSocialBots({
            ...requestBody,
            time: '1w'
          })
        );
      } else if (option === `> 1 Month`) {
        setRunTimeBy(`> 1 Month`);
        setRequestBody({
          ...requestBody,
          time: '1m'
        });
        dispatch(
          getSocialBots({
            ...requestBody,
            time: '1m'
          })
        );
      } else if (option === `> 3 Months`) {
        setRunTimeBy(`> 3 Months`);
        setRequestBody({
          ...requestBody,
          time: '3m'
        });
        dispatch(
          getSocialBots({
            ...requestBody,
            time: '3m'
          })
        );
      } else if (option === `> 6 Months`) {
        setRunTimeBy(`> 6 Months`);
        setRequestBody({
          ...requestBody,
          time: '6m'
        });
        dispatch(
          getSocialBots({
            ...requestBody,
            time: '6m'
          })
        );
      } else if (option === `> 1 Year`) {
        setRunTimeBy(`> 1 Year`);
        setRequestBody({
          ...requestBody,
          time: '1y'
        });
        dispatch(
          getSocialBots({
            ...requestBody,
            time: '1y'
          })
        );
      }
    },
    [exchangeBy, requestBody]
  );

  const handlePairBy = useCallback(
    option => {
      for (let item of socialState.pairs) {
        if (option === item) {
          setPairBy(item);
          setRequestBody({
            ...requestBody,
            pair: option === 'ALL' ? 'ALL' : [option]
          });
          dispatch(
            getSocialBots({
              ...requestBody,
              pair: option === 'ALL' ? 'ALL' : [option]
            })
          );
        }
      }
    },
    [pairBy, socialState.pairs, requestBody]
  );

  const handleSortBy = useCallback(
    option => {
      if (option === 'Best Performing') {
        setSortBy('Best Performing');
        setRequestBody({
          ...requestBody,
          sort: 'APR'
        });
        dispatch(
          getSocialBots({
            ...requestBody,
            sort: 'APR'
          })
        );
      } else if (option === 'New to Old') {
        setSortBy('New to Old');
        setRequestBody({
          ...requestBody,
          sort: 'Runtime_DESC'
        });
        dispatch(
          getSocialBots({
            ...requestBody,
            sort: 'Runtime_DESC'
          })
        );
      } else if (option === 'Old to New') {
        setSortBy('Old to New');
        setRequestBody({
          ...requestBody,
          sort: 'Runtime_ASC'
        });
        dispatch(
          getSocialBots({
            ...requestBody,
            sort: 'Runtime_ASC'
          })
        );
      }
    },
    [sortBy, requestBody]
  );

  const clearCheckboxes = field => {
    if (field === 'Pairs') {
      let pcv = [];
      let pci = [...pairCheckedItems];
      setPairCheckedValues(pcv);
      setPairCheckedItems(new Array(pci.length).fill(false));
      setRequestBody({
        ...requestBody,
        pair: []
      });
    } else if (field === 'Targets') {
      let tcv = [];
      let tci = [...targetCheckedItems];
      setTargetCheckedValues(tcv);
      setTargetCheckedItems(new Array(tci.length).fill(false));
      setRequestBody({
        ...requestBody,
        symbol_to_profit: []
      });
    }
  };

  const checkFields = inputs => {
    // setNameError();
    // setMaxAmountError();
    const currentPrice = calculateBaseCurrencyIndex(
      authState.currentOption,
      userState.assetData,
      calculateExchangeKey(authState.currentOption).key,
      calculateExchangeKey(authState.currentOption).exc,
      currentTarget.toLowerCase()
    ).current_price;
    let targetFree = Object.values(
      userState.assetData[calculateExchangeKey(authState.currentOption).key][
        calculateExchangeKey(authState.currentOption).exc
      ]
    ).find(e => e.symbol === currentTarget.toLowerCase());
    let quote = pairsInfo.find(e => Object.keys(e)[0] === currentPair)[currentPair].quote;
    let pairPrice = pairsInfo.find(e => Object.keys(e)[0] === currentPair)[currentPair].price;
    let max = targetFree.free * pairPrice;
    if (inputs.botName === '') {
      return { be: 'Name cannot be blank', ae: undefined };
    } else if (inputs.maxAmount === '') {
      return { be: undefined, ae: 'Amount cannot be blank' };
    } else if (Number(inputs.maxAmount) < 20 && (currentTarget === 'USD' || currentTarget === 'USDT')) {
      return {
        be: undefined,
        ae:
          authState.currentOption === 'Binance US'
            ? 'Minimum investment amount 20 USD'
            : 'Minimum investment amount 20 USDT'
      };
    } else if (currentTarget === quote) {
      if (targetFree.free < Number(maxAmount)) {
        return {
          be: undefined,
          ae: 'Not enough balance'
        };
      } else if (adjustableTargetFreeAmount < 20) {
        return {
          be: undefined,
          ae:
            authState.currentOption === 'Binance US'
              ? 'Minimum investment amount 20 USD'
              : 'Minimum investment amount 20 USDT'
        };
      }
    } else if (currentTarget !== quote) {
      if (Number(maxAmount) > max) {
        return {
          be: undefined,
          ae: 'Not enough balance'
        };
      } else if (adjustableTargetFreeAmount < 20) {
        return {
          be: undefined,
          ae:
            authState.currentOption === 'Binance US'
              ? 'Minimum investment amount 20 USD'
              : 'Minimum investment amount 20 USDT'
        };
      }
    }
    return {
      be: undefined,
      ae: undefined
    };
  };

  const calculateSince = ts => {
    const now = new Date().getTime();
    let delta = Math.abs(now - ts * 1000) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    return `${days}D ${hours}H ${minutes}M`;
  };

  const calculateLifeTime = (c, u) => {
    let delta = Math.abs((new Date(u).getTime() - new Date(c).getTime()) / 1000);

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    return `${days}D ${hours}H ${minutes}M`;
  };

  // const mounted = useRef();
  // useEffect(() => {
  //   if (!mounted.current) {
  //     // do componentDidMount logic
  //     console.log('mounting social');
  //     mounted.current = true;
  //   } else {
  //     console.log('updating social');
  //   }
  // });

  const renderSocialCards = useMemo(() => {
    return !socialState.error &&
      socialState.bots?.length > 0 &&
      targetCheckedItems.includes(true) &&
      !mobileModalMode &&
      pairCheckedItems.includes(true) ? (
      <Wrap spacing="40px" mt={12} w={['full', 'full', 'full', 'full', '95%', '95%']}>
        {/* {console.log(filteredData)} */}
        {socialState.bots &&
          socialState.bots.map((elem, idx) => {
            return (
              <SocialCard
                name={elem.full_name}
                onCopy={() =>
                  handleOnCopy(elem.symbol_to_profit, elem.symbol_pair, elem.created_at, elem.id, elem.exchange_id)
                }
                email={elem.email}
                isDisabled={authState.stripeCurrentPlan !== 'Pro'}
                pair={elem.symbol_pair}
                lifeTime={
                  elem.bot_status === 'active'
                    ? calculateSince(new Date(elem.created_at).getTime() / 1000)
                    : calculateLifeTime(elem.created_at, elem.updated_at)
                }
                createdAt={new Date(elem.created_at).toDateString()}
                best={idx === 0}
                profit={Number(elem.overall_gain).toFixed(4)}
                runCount={elem.run_count}
                investment={Number(elem.max_amount).toFixed(4)}
                rank={idx + 1}
                second={idx === 1}
                third={idx === 2}
                botID={elem.id}
                active={elem.bot_status === 'active'}
                botStyle={elem.bot_style.charAt(0).toUpperCase() + elem.bot_style.slice(1)}
                apr={Number(elem.overall_gain_percent).toFixed(2)}
                status={elem.bot_status.charAt(0).toUpperCase() + elem.bot_status.slice(1)}
                exchange={elem.exchange_id === 1 ? 'BINANCE US' : 'BINANCE'}
                exc={elem.exchange_id}
                target={elem.symbol_to_profit}
                // isDisabled={!availableTargets.includes(elem.symbol_to_profit)}
                key={idx}
              />
            );
          })}
      </Wrap>
    ) : mobileModalMode && socialState.bots?.length > 0 && !socialState.error ? (
      <Wrap spacing="40px" mt={12} w={['full', 'full', 'full', 'full', '95%', '95%']}>
        {/* {console.log(filteredData)} */}
        {socialState.bots &&
          socialState.bots &&
          socialState.bots.map((elem, idx) => {
            return (
              <SocialCard
                name={elem.full_name}
                onCopy={() =>
                  handleOnCopy(elem.symbol_to_profit, elem.symbol_pair, elem.created_at, elem.id, elem.exchange_id)
                }
                email={elem.email}
                pair={elem.symbol_pair}
                lifeTime={
                  elem.bot_status === 'active'
                    ? calculateSince(new Date(elem.created_at).getTime() / 1000)
                    : calculateLifeTime(elem.created_at, elem.updated_at)
                }
                createdAt={new Date(elem.created_at).toDateString()}
                best={idx === 0}
                profit={Number(elem.overall_gain).toFixed(4)}
                runCount={elem.run_count}
                investment={Number(elem.max_amount).toFixed(4)}
                rank={idx + 1}
                second={idx === 1}
                third={idx === 2}
                active={elem.bot_status === 'active'}
                botStyle={elem.bot_style.charAt(0).toUpperCase() + elem.bot_style.slice(1)}
                apr={Number(elem.overall_gain_percent).toFixed(2)}
                status={elem.bot_status.charAt(0).toUpperCase() + elem.bot_status.slice(1)}
                exchange={elem.exchange_id === 1 ? 'BINANCE US' : 'BINANCE'}
                target={elem.symbol_to_profit}
                // isDisabled={!availableTargets.includes(elem.symbol_to_profit)}
                key={idx}
              />
            );
          })}
      </Wrap>
    ) : null;
  }, [
    socialState.bots,
    socialState.error,
    targetCheckedItems,
    pairCheckedItems,
    authState.id,
    sortBy,
    showBy,
    exchangeBy,
    runTimeBy
  ]);

  return (
    <Flex
      w={['full', 'full', '95%', '95%', '95%', '95%']}
      // maxW="7xl"
      justify={!socialState.loading ? 'space-between' : 'center'}
      overflowY="hidden"
      overflowX="hidden"
      direction="column"
      margin="auto"
      pb={24}
      mt={[8, 8, 12, 12, 12, 12]}
    >
      {/* <Flex h="xs" w="100vw" bg="green.400" position="absolute" left="18rem" zIndex={-1} top={0}></Flex> */}
      {/* <Heading fontSize="2xl" w="full" textAlign="left" my={4} mt={72}> */}
      <Heading fontSize="2xl" w="full" textAlign="left" my={4}>
        Epicflow Social
      </Heading>
      {socialState.bots ? (
        <Box minH={socialState.bots ? '90vh' : ''} w="full">
          <Flex
            justifyContent="flex-start"
            alignItems="center"
            overflowX="scroll"
            pb={4}
            display={['flex', 'flex', 'flex', 'flex', 'none', 'none']}
          >
            <Flex direction="column" borderWidth={2} borderRadius="md" mr={3}>
              <Menu>
                <MenuButton
                  bg="transparent"
                  _focus={{ outline: 'none' }}
                  fontSize="xs"
                  fontWeight="normal"
                  _active={{ backgroundColor: 'transparent' }}
                  _hover={{
                    backgroundColor: 'transparent'
                  }}
                  size="sm"
                  as={Button}
                  rightIcon={
                    <ChevronDownIcon display={['none', 'none', 'none', 'block', 'block', 'block', 'block']} />
                  }
                  p={0}
                >
                  <Flex alignItems="center">
                    <Text mx={2}>Show: {showBy}</Text>
                    <ChevronDownIcon display={['block', 'block', 'block', 'none', 'none', 'none', 'none']} />
                  </Flex>
                </MenuButton>
                <MenuList>
                  <MenuItem fontSize="xs" onClick={() => handleShowBy('ALL')}>
                    ALL
                  </MenuItem>
                  <MenuItem fontSize="xs" onClick={() => handleShowBy('Active')}>
                    Active
                  </MenuItem>
                  <MenuItem fontSize="xs" onClick={() => handleShowBy('Completed')}>
                    Completed
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Flex direction="column" borderWidth={2} borderRadius="md" mr={4}>
              <Menu>
                <MenuButton
                  bg="transparent"
                  _focus={{ outline: 'none' }}
                  fontSize="xs"
                  fontWeight="normal"
                  _active={{ backgroundColor: 'transparent' }}
                  _hover={{
                    backgroundColor: 'transparent'
                  }}
                  size="sm"
                  as={Button}
                  rightIcon={
                    <ChevronDownIcon display={['none', 'none', 'none', 'block', 'block', 'block', 'block']} />
                  }
                  p={0}
                >
                  <Flex alignItems="center">
                    <Text mx={2}>Sort: {sortBy}</Text>
                    <ChevronDownIcon display={['block', 'block', 'block', 'none', 'none', 'none', 'none']} />
                  </Flex>
                </MenuButton>
                <MenuList>
                  <MenuItem fontSize="xs" onClick={() => handleSortBy('Best Performing')}>
                    Best Performing
                  </MenuItem>
                  <MenuItem fontSize="xs" onClick={() => handleSortBy('New to Old')}>
                    New to Old
                  </MenuItem>
                  <MenuItem fontSize="xs" onClick={() => handleSortBy('Old to New')}>
                    Old to New
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Flex direction="column" borderWidth={2} borderRadius="md" mr={4}>
              <Menu>
                <MenuButton
                  bg="transparent"
                  _focus={{ outline: 'none' }}
                  fontSize="xs"
                  fontWeight="normal"
                  _active={{ backgroundColor: 'transparent' }}
                  _hover={{
                    backgroundColor: 'transparent'
                  }}
                  size="sm"
                  as={Button}
                  rightIcon={
                    <ChevronDownIcon display={['none', 'none', 'none', 'block', 'block', 'block', 'block']} />
                  }
                  p={0}
                >
                  <Flex alignItems="center">
                    <Text mx={2}>Bot Run Time: {runTimeBy}</Text>
                    <ChevronDownIcon display={['block', 'block', 'block', 'none', 'none', 'none', 'none']} />
                  </Flex>
                </MenuButton>
                <MenuList>
                  <MenuItem fontSize="xs" onClick={() => handleRunTimeBy(`> 1 Day`)}>
                    {`> 1 Day`}
                  </MenuItem>
                  <MenuItem fontSize="xs" onClick={() => handleRunTimeBy(`> 1 Week`)}>
                    {`> 1 Week`}
                  </MenuItem>
                  <MenuItem fontSize="xs" onClick={() => handleRunTimeBy(`> 1 Month`)}>
                    {`> 1 Month`}
                  </MenuItem>
                  <MenuItem fontSize="xs" onClick={() => handleRunTimeBy(`> 3 Months`)}>
                    {`> 3 Months`}
                  </MenuItem>
                  <MenuItem fontSize="xs" onClick={() => handleRunTimeBy(`> 6 Months`)}>
                    {`> 6 Months`}
                  </MenuItem>
                  <MenuItem fontSize="xs" onClick={() => handleRunTimeBy(`> 1 Year`)}>
                    {`> 1 Year`}
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Flex direction="column" borderWidth={2} borderRadius="md" mr={4}>
              <Button
                bg="transparent"
                _focus={{ outline: 'none' }}
                fontSize="xs"
                fontWeight="normal"
                _active={{ backgroundColor: 'transparent' }}
                _hover={{
                  backgroundColor: 'transparent'
                }}
                size="sm"
                p={0}
                onClick={() => handleMobileModalOpen('Pairs')}
              >
                <Text mx={4}>Pairs</Text>
              </Button>
            </Flex>
            <Flex direction="column" borderWidth={2} borderRadius="md">
              <Button
                bg="transparent"
                _focus={{ outline: 'none' }}
                fontSize="xs"
                fontWeight="normal"
                _active={{ backgroundColor: 'transparent' }}
                _hover={{
                  backgroundColor: 'transparent'
                }}
                size="sm"
                p={0}
                onClick={() => handleMobileModalOpen('Targets')}
              >
                <Text mx={4}>Targets</Text>
              </Button>
            </Flex>
            <Flex
              w={['full', 'full', 'full', 'full', 'full', 'full']}
              direction="column"
              justify="flex-start"
              px={4}
            >
              <Modal
                isOpen={isMobileModalOpen}
                onClose={handleMobileMenuClose}
                returnFocusOnClose={false}
                motionPreset="slideInBottom"
              >
                <ModalOverlay />
                <ModalContent borderTopRadius="2xl" position="fixed" bottom="0px" mb="0" maxW="12000px">
                  <ModalCloseButton
                    _focus={{
                      outline: 'none'
                    }}
                  />
                  <ModalHeader
                    fontWeight="bold"
                    fontSize="lg"
                    mt={10}
                    mb={2}
                    pb={0}
                    pt={0}
                    pl={10}
                    textAlign="left"
                  >
                    {mobileModalMode}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pt={2} px={10}>
                    <Flex direction="column" maxH="40vh" overflowY="scroll">
                      {mobileModalMode === 'Pairs'
                        ? defaultPairValues?.map((el, idx) => {
                            return (
                              <Checkbox
                                css={`
                                  > span:first-of-type {
                                    box-shadow: unset;
                                  }
                                `}
                                size="sm"
                                mb={4}
                                key={idx}
                                value={el}
                                onChange={e => handleMobileCheckbox(e, 'Pairs')}
                                isChecked={pairCheckedItems[idx]}
                              >
                                {el}
                              </Checkbox>
                            );
                          })
                        : defaultTargetValues?.map((el, idx) => {
                            return (
                              <Checkbox
                                css={`
                                  > span:first-of-type {
                                    box-shadow: unset;
                                  }
                                `}
                                size="sm"
                                mb={4}
                                key={idx}
                                value={el}
                                onChange={e => handleMobileCheckbox(e, 'Targets')}
                                isChecked={targetCheckedItems[idx]}
                              >
                                {el}
                              </Checkbox>
                            );
                          })}
                    </Flex>
                    <Flex alignItems="center" w="full" justifyContent="space-between" py={4}>
                      <Button
                        w="47%"
                        fontSize="xs"
                        fontWeight="medium"
                        _focus={{ outline: 'none' }}
                        // bg="#48BB78"
                        borderWidth={1}
                        isLoading={loading}
                        onClick={
                          mobileModalMode === 'Pairs'
                            ? () => clearCheckboxes('Pairs')
                            : () => clearCheckboxes('Targets')
                        }
                        // _hover={{ bg: 'green.600' }}
                      >
                        Clear
                      </Button>
                      <Button
                        w="47%"
                        fontSize="xs"
                        fontWeight="medium"
                        _focus={{ outline: 'none' }}
                        bg="#48BB78"
                        isLoading={loading}
                        color="white"
                        onClick={handleMobileDone}
                        _hover={{ bg: 'green.600' }}
                      >
                        Done
                      </Button>
                    </Flex>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Flex>
          </Flex>
          <Flex justify="space-between" w="full">
            <Flex display={['none', 'none', 'none', 'none', 'flex', 'flex']}>
              <Flex
                w={['full', 'full', 'full', '14rem', '14rem', '14rem']}
                direction="column"
                justify="flex-start"
                // pl={24}
                mt={24}
                minH="100vh"
                align="flex-start"
              >
                <Flex direction="column" alignItems="flex-start" mt={8} pb={2}>
                  <Text fontWeight="semibold" fontSize="sm" mb={2}>
                    Sort By
                  </Text>
                  <Flex direction="column">
                    <Menu>
                      <MenuButton
                        bg="transparent"
                        _focus={{ outline: 'none' }}
                        fontSize="0.8125rem"
                        fontWeight="normal"
                        _active={{ backgroundColor: 'transparent' }}
                        _hover={{
                          backgroundColor: 'transparent'
                        }}
                        as={Button}
                        rightIcon={
                          <ChevronDownIcon
                            display={['none', 'none', 'none', 'block', 'block', 'block', 'block']}
                          />
                        }
                        p={0}
                      >
                        <Text display={['none', 'none', 'none', 'block', 'block', 'block', 'block']}>
                          {sortBy}
                        </Text>
                        <ChevronDownIcon display={['block', 'block', 'block', 'none', 'none', 'none', 'none']} />
                      </MenuButton>
                      <MenuList>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleSortBy('Best Performing')}>
                          Best Performing
                        </MenuItem>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleSortBy('New to Old')}>
                          New to Old
                        </MenuItem>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleSortBy('Old to New')}>
                          Old to New
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                </Flex>
                <Divider />
                <Flex direction="column" alignItems="flex-start" mt={16} pb={2}>
                  <Text fontWeight="semibold" fontSize="sm" mb={2}>
                    Status
                  </Text>
                  <Flex direction="column">
                    <Menu>
                      <MenuButton
                        bg="transparent"
                        _focus={{ outline: 'none' }}
                        fontSize="0.8125rem"
                        fontWeight="normal"
                        _active={{ backgroundColor: 'transparent' }}
                        _hover={{
                          backgroundColor: 'transparent'
                        }}
                        as={Button}
                        p={0}
                        rightIcon={
                          <ChevronDownIcon
                            display={['none', 'none', 'none', 'block', 'block', 'block', 'block']}
                          />
                        }
                      >
                        <Text display={['none', 'none', 'none', 'block', 'block', 'block', 'block']}>
                          {showBy}
                        </Text>
                        <ChevronDownIcon display={['block', 'block', 'block', 'none', 'none', 'none', 'none']} />
                      </MenuButton>
                      <MenuList>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleShowBy('ALL')}>
                          ALL
                        </MenuItem>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleShowBy('Active')}>
                          Active
                        </MenuItem>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleShowBy('Completed')}>
                          Completed
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                </Flex>
                <Divider />
                <Flex direction="column" alignItems="flex-start" mt={16} pb={2}>
                  <Text fontWeight="semibold" fontSize="sm" mb={2}>
                    Bot Run Time
                  </Text>
                  <Flex direction="column">
                    <Menu>
                      <MenuButton
                        bg="transparent"
                        _focus={{ outline: 'none' }}
                        fontSize="0.8125rem"
                        fontWeight="normal"
                        _active={{ backgroundColor: 'transparent' }}
                        _hover={{
                          backgroundColor: 'transparent'
                        }}
                        as={Button}
                        rightIcon={
                          <ChevronDownIcon
                            display={['none', 'none', 'none', 'block', 'block', 'block', 'block']}
                          />
                        }
                        p={0}
                      >
                        <Text display={['none', 'none', 'none', 'block', 'block', 'block', 'block']}>
                          {runTimeBy}
                        </Text>
                        <ChevronDownIcon display={['block', 'block', 'block', 'none', 'none', 'none', 'none']} />
                      </MenuButton>
                      <MenuList>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleRunTimeBy(`> 1 Day`)}>
                          {`> 1 Day`}
                        </MenuItem>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleRunTimeBy(`> 1 Week`)}>
                          {`> 1 Week`}
                        </MenuItem>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleRunTimeBy(`> 1 Month`)}>
                          {`> 1 Month`}
                        </MenuItem>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleRunTimeBy(`> 3 Months`)}>
                          {`> 3 Months`}
                        </MenuItem>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleRunTimeBy(`> 6 Months`)}>
                          {`> 6 Months`}
                        </MenuItem>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleRunTimeBy(`> 1 Year`)}>
                          {`> 1 Year`}
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                </Flex>
                <Divider />
                <Flex direction="column" alignItems="flex-start" mt={16} pb={2}>
                  <Text fontWeight="semibold" fontSize="sm" mb={2}>
                    Exchange
                  </Text>
                  <Flex direction="column">
                    <Menu>
                      <MenuButton
                        bg="transparent"
                        _focus={{ outline: 'none' }}
                        fontSize="0.8125rem"
                        fontWeight="normal"
                        _active={{ backgroundColor: 'transparent' }}
                        _hover={{
                          backgroundColor: 'transparent'
                        }}
                        as={Button}
                        rightIcon={
                          <ChevronDownIcon
                            display={['none', 'none', 'none', 'block', 'block', 'block', 'block']}
                          />
                        }
                        p={0}
                      >
                        <Text display={['none', 'none', 'none', 'block', 'block', 'block', 'block']}>
                          {exchangeBy}
                        </Text>
                        <ChevronDownIcon display={['block', 'block', 'block', 'none', 'none', 'none', 'none']} />
                      </MenuButton>
                      <MenuList>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleExchangeBy('ALL')}>
                          ALL
                        </MenuItem>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleExchangeBy('BINANCE US')}>
                          BINANCE US
                        </MenuItem>
                        <MenuItem fontSize="0.8125rem" onClick={() => handleExchangeBy('BINANCE')}>
                          BINANCE
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                </Flex>
                <Divider />
                <Flex direction="column" alignItems="flex-start" mt={16} pb={2}>
                  <Flex mb={2}>
                    <Text fontWeight="semibold" fontSize="sm" mr={6}>
                      Pairs
                    </Text>
                    <Button
                      variant="link"
                      fontSize="sm"
                      color="blue.400"
                      p={0}
                      _focus={{ outline: 'none' }}
                      onClick={() => clearCheckboxes('Pairs')}
                    >
                      Clear All
                    </Button>
                  </Flex>
                  <Flex direction="column">
                    {defaultPairValues?.slice(0, 5).map((el, idx) => {
                      return (
                        <Checkbox
                          css={`
                            > span:first-of-type {
                              box-shadow: unset;
                            }
                          `}
                          size="sm"
                          mt={2}
                          key={idx}
                          value={el}
                          onChange={e => handleCheckbox(e, 'Pairs')}
                          isChecked={pairCheckedItems[idx]}
                        >
                          {el}
                        </Checkbox>
                      );
                    })}
                    {!showMorePairs ? (
                      <Button
                        fontSize="sm"
                        color="#48BB78"
                        fontWeight="bold"
                        variant="unstyled"
                        onClick={handleShowMorePairsToggle}
                        w="full"
                        _focus={{ outline: 'none' }}
                        textAlign="left"
                      >
                        Show more
                      </Button>
                    ) : null}
                    <Collapse mt={4} in={showMorePairs}>
                      <Flex direction="column">
                        {defaultPairValues?.slice(5).map((el, idx) => {
                          return (
                            <Checkbox
                              css={`
                                > span:first-of-type {
                                  box-shadow: unset;
                                }
                              `}
                              size="sm"
                              mt={2}
                              key={idx}
                              value={el}
                              onChange={e => handleCheckbox(e, 'Pairs')}
                              isChecked={pairCheckedItems[idx + 5]}
                            >
                              {el}
                            </Checkbox>
                          );
                        })}
                      </Flex>
                    </Collapse>
                    {showMorePairs ? (
                      <Button
                        fontSize="sm"
                        color="#48BB78"
                        fontWeight="bold"
                        variant="unstyled"
                        onClick={handleShowMorePairsToggle}
                        w="full"
                        _focus={{ outline: 'none' }}
                        textAlign="left"
                      >
                        Show less
                      </Button>
                    ) : null}
                  </Flex>
                </Flex>
                <Divider />
                <Flex direction="column" alignItems="flex-start" mt={16}>
                  <Flex mb={2}>
                    <Text fontWeight="semibold" fontSize="sm" mr={6}>
                      Targets
                    </Text>
                    <Button
                      variant="link"
                      fontSize="sm"
                      color="blue.400"
                      p={0}
                      _focus={{ outline: 'none' }}
                      onClick={() => clearCheckboxes('Targets')}
                    >
                      Clear All
                    </Button>
                  </Flex>
                  <Flex direction="column">
                    {defaultTargetValues?.slice(0, 5)?.map((el, idx) => {
                      return (
                        <Checkbox
                          css={`
                            > span:first-of-type {
                              box-shadow: unset;
                            }
                          `}
                          size="sm"
                          mt={2}
                          key={idx}
                          value={el}
                          onChange={e => handleCheckbox(e, 'Targets')}
                          isChecked={targetCheckedItems[idx]}
                        >
                          {el}
                        </Checkbox>
                      );
                    })}
                    {!showMoreTargets ? (
                      <Button
                        fontSize="sm"
                        color="#48BB78"
                        fontWeight="bold"
                        variant="unstyled"
                        onClick={handleShowMoreTargetsToggle}
                        w="full"
                        _focus={{ outline: 'none' }}
                        textAlign="left"
                      >
                        Show more
                      </Button>
                    ) : null}
                    <Collapse mt={4} in={showMoreTargets}>
                      <Flex direction="column">
                        {defaultTargetValues?.slice(5).map((el, idx) => {
                          return (
                            <Checkbox
                              css={`
                                > span:first-of-type {
                                  box-shadow: unset;
                                }
                              `}
                              size="sm"
                              mt={2}
                              key={idx}
                              value={el}
                              onChange={e => handleCheckbox(e, 'Targets')}
                              isChecked={targetCheckedItems[idx + 5]}
                            >
                              {el}
                            </Checkbox>
                          );
                        })}
                      </Flex>
                    </Collapse>
                    {showMoreTargets ? (
                      <Button
                        fontSize="sm"
                        color="#48BB78"
                        fontWeight="bold"
                        variant="unstyled"
                        onClick={handleShowMoreTargetsToggle}
                        w="full"
                        _focus={{ outline: 'none' }}
                        textAlign="left"
                      >
                        Show less
                      </Button>
                    ) : null}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              w={['full', 'full', 'full', 'full', 'full', 'full']}
              direction="column"
              justify="flex-start"
              px={[0, 0, 4]}
              minH="100vh"
              align="flex-end"
            >
              <Modal
                isOpen={isConfirmationModalOpen}
                onClose={handleConfirmationModalClose}
                isCentered
                returnFocusOnClose={false}
                motionPreset="slideInBottom"
                size="lg"
              >
                <ModalOverlay />
                <ModalContent borderRadius="sm">
                  <ModalCloseButton
                    _focus={{
                      outline: 'none'
                    }}
                  />
                  <ModalHeader
                    fontWeight="bold"
                    fontSize="lg"
                    mt={10}
                    mb={4}
                    pb={0}
                    pt={0}
                    pl={10}
                    textAlign="left"
                  >
                    Clone Bot
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pt={2} px={10}>
                    <Flex direction="column">
                      <Flex
                        alignItems="flex-start"
                        mb={6}
                        borderRadius="md"
                        py={4}
                        direction="column"
                        pl={4}
                        bg={colorMode === 'light' ? 'gray.100' : 'gray.800'}
                        justify="flex-start"
                      >
                        <Flex alignItems="center">
                          <Text fontSize="xs" mr={2}>
                            Pair:
                          </Text>
                          <Text fontSize="xs" fontWeight="medium" mr={8}>
                            {currentPair}
                          </Text>
                          <Text fontSize="xs" mr={2}>
                            Profit in:{' '}
                          </Text>
                          <Text fontSize="xs" fontWeight="medium">
                            {currentTarget}
                          </Text>
                        </Flex>
                        <Flex alignItems="center" mt={3}>
                          <Text fontSize="xs" mr={2}>
                            Created on
                          </Text>
                          <Text fontSize="xs" fontWeight="medium">
                            {currentDate}
                          </Text>
                        </Flex>
                      </Flex>
                      <Text mb={4} fontSize="0.8125rem">
                        We will use the same parameters that were adjusted by the original creator. You can always
                        change them after creating the bot.
                      </Text>
                      <Text mb={8} fontSize="xs" color="teal.500" fontWeight="medium">
                        Due to the difference in market conditions, these parameters are not guaranteed to deliver
                        you the same results.
                      </Text>
                      <AdvancedInput
                        title="Bot Name"
                        width="full"
                        column
                        value={botName}
                        handleChange={e => handleInput(e, 'Bot Name')}
                        placeholder="Awesome Bot"
                        bc={nameError ? 'red' : null}
                        isDisabled={
                          !availableTargets.includes(currentTarget) || !availablePairs?.includes(currentPair)
                        }
                        // type={el.title === 'Max Investment' ? 'number' : 'text'}
                      />
                      <AdvancedInput
                        title="Max Investment"
                        width="full"
                        column
                        type="number"
                        value={maxAmount}
                        bc={maxAmountError ? 'red' : null}
                        handleChange={e => handleInput(e, 'Amount')}
                        // handleChange={e => handleInput(e, el.title, el.placeholder)}
                        placeholder={maxAmountPlaceholder ? maxAmountPlaceholder : `Max Investment`}
                        mt={6}
                        isDisabled={
                          !availableTargets.includes(currentTarget) || !availablePairs?.includes(currentPair)
                        }
                        // type={el.title === 'Max Investment' ? 'number' : 'text'}
                      />
                      {availablePairs?.includes(currentPair) &&
                      availableTargets.includes(currentTarget) &&
                      pairsInfo.find(e => Object.keys(e)[0] === currentPair)[currentPair].quote !== 'USD' &&
                      pairsInfo.find(e => Object.keys(e)[0] === currentPair)[currentPair].quote !== 'USDT' ? (
                        <Flex justifyContent="flex-start" alignItems="center" w="full">
                          <Text fontSize="xs" fontWeight="medium" mt={1} color="gray.400">
                            {adjustableTargetFreeAmount}{' '}
                            {`${authState.currentOption === 'Binance US' ? 'USD' : 'USDT'}`}
                          </Text>
                        </Flex>
                      ) : null}
                    </Flex>
                    <Flex direction="column" justify="flex-start" alignItems="center" mt={6}>
                      <Text fontSize="xs" fontWeight="medium" mb={2} color="red.400" w="full" textAlign="left">{`${
                        !availablePairs?.includes(currentPair)
                          ? `*Your exchange does not support ${currentPair} pair.`
                          : !availableTargets.includes(currentTarget)
                          ? `*To copy this bot, you should have ${currentTarget}`
                          : ''
                      }`}</Text>
                      <Flex direction="column" w="full">
                        {nameError && (
                          <Text fontSize="0.8125rem" mb={2} w="full" textAlign="left">
                            {nameError}
                          </Text>
                        )}
                        {maxAmountError && (
                          <Text fontSize="0.8125rem" mb={2} w="full" textAlign="left">
                            {maxAmountError}
                          </Text>
                        )}
                        <Button
                          mb={8}
                          size="md"
                          w="full"
                          fontSize="sm"
                          fontWeight="medium"
                          _focus={{ outline: 'none' }}
                          bg="#48BB78"
                          isLoading={loading}
                          color="white"
                          onClick={() =>
                            handleCreate({ botID: botID, amount: maxAmount, excID: excID, botName: botName })
                          }
                          _hover={{ bg: 'green.600' }}
                          isDisabled={
                            !availableTargets.includes(currentTarget) || !availablePairs?.includes(currentPair)
                          }
                        >
                          Create
                        </Button>
                      </Flex>
                      {result ? (
                        <Alert
                          fontSize="sm"
                          status={result.error ? 'error' : 'success'}
                          variant="subtle"
                          mb={4}
                          mt={4}
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          textAlign="center"
                        >
                          <AlertIcon boxSize="28px" my={2} />
                          <AlertTitle mt={1} mb={2} fontSize="lg">
                            Succesful!
                          </AlertTitle>
                        </Alert>
                      ) : null}
                    </Flex>
                  </ModalBody>
                </ModalContent>
              </Modal>
              <InfiniteScroll
                dataLength={socialState.bots?.length} //This is important field to render the next data
                next={() =>
                  dispatch(
                    getSocialBots({
                      ...requestBody,
                      page: Math.ceil(socialState.bots?.length / 20)
                    })
                  )
                }
                style={{
                  paddingLeft: 6,
                  paddingRight: 6,
                  paddingBottom: 16,
                  overflow: 'hidden'
                }}
                className={classes.CustomInfiteClass}
                hasMore={socialState.bots?.length < socialState.count}
                loader={
                  socialState.bots?.length < socialState.count && (
                    <Flex w="full" justify="center" pt={8}>
                      <Lottie
                        options={{
                          loop: true,
                          path: 'https://assets6.lottiefiles.com/packages/lf20_rwq6ciql.json'
                        }}
                        height={40}
                        width={40}
                      />
                    </Flex>
                  )
                }
                endMessage={
                  socialState.bots?.length === socialState.count &&
                  socialState.bots?.length > 20 && (
                    <Flex w="full" justify="center" mt={4}>
                      <Text fontSize="sm" fontWeight="medium">
                        You have reached the bottom of the ocean. 🐙
                      </Text>
                    </Flex>
                  )
                }
              >
                {renderSocialCards}
              </InfiniteScroll>
            </Flex>
          </Flex>
        </Box>
      ) : (
        <Flex
          minH={socialState.loading ? '50vh' : ''}
          align="center"
          // display={socialState.loading && !socialState.bots ? 'flex' : 'none'}
        >
          <Lottie
            options={{
              loop: true,
              path: 'https://assets6.lottiefiles.com/packages/lf20_hwm68tlm.json'
            }}
            height={250}
            width={250}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default Social;
