import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Button,
  Collapse,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useColorMode,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect } from 'react';
import CurrencyInput, { CurrencyInputOnChangeValues, CurrencyInputProps } from 'react-currency-input-field';

import { epicFlowGradientColors } from '../../../theme';

function FormattedInput(props) {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isPopOpen, onToggle: onPopToggle, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 48em)');

  const CustomInput = useCallback(
    styled(CurrencyInput)`
      &::placeholder {
        color: ${colorMode === 'light' ? 'gray.400' : '#718096'};
      }
    `,
    [colorMode]
  );

  useEffect(() => {
    if (!isPopOpen) {
      if (isOpen) {
        onToggle();
      }
    }
  }, [isPopOpen]);

  return (
    <Flex
      w="full"
      justify={props.column ? 'flex-start' : 'space-between'}
      align={props.column ? 'flex-start' : 'center'}
      direction={props.column ? 'column' : 'row'}
      mt={props.mt}
      mb={props.mb}
    >
      <Flex direction="row" mb={props.tmb} alignItems="center">
        <Text fontSize="0.8125rem">{props.title}</Text>
        {props.popover && (
          <Popover
            // strategy="fixed"
            preventOverflow={props.pof ? true : false}
            flip={false}
            placement="left-start"
            isOpen={isPopOpen}
            onClose={onClose}
          >
            <PopoverTrigger>
              <IconButton
                p={0}
                m={0}
                // size="sm"
                w="14px"
                onClick={onPopToggle}
                maxWidth="14px"
                minWidth="14px"
                h="14px"
                borderWidth={1}
                bg="transparent"
                ml={2}
                _hover={{
                  background: 'transparent'
                }}
                _active={{
                  background: 'transparent'
                }}
                _focus={{
                  background: 'transparent'
                }}
                aria-label="Info"
                icon={<InfoOutlineIcon boxSize="14px" p={0} color="gray.500" />}
              />
            </PopoverTrigger>
            <Portal>
              <PopoverContent
                _focus={{ outline: 'none' }}
                w={72}
                py={4}
                bg={colorMode === 'light' ? 'white' : '#1f2124'}
              >
                <PopoverHeader fontWeight="semibold" fontSize="15px" border={0}>
                  {props.popoverHeader}
                </PopoverHeader>
                {/* <PopoverArrow /> */}
                {/* <PopoverCloseButton /> */}
                <PopoverBody fontSize="13px" pt={0} display="flex" flexDirection="column" alignItems="flex-start">
                  {props.popoverText}
                  <Button
                    onClick={onToggle}
                    fontSize="13px"
                    p={0}
                    h={6}
                    my={2}
                    bg="transparent"
                    _hover={{
                      background: 'transparent'
                    }}
                    _active={{
                      background: 'transparent'
                    }}
                    _focus={{
                      background: 'transparent'
                    }}
                    color={epicFlowGradientColors.left}
                  >
                    {isOpen ? 'Read Less' : 'Read More'}
                  </Button>
                  <Collapse in={isOpen} animateOpacity>
                    <Flex>{props.popoverDescription}</Flex>
                  </Collapse>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        )}
      </Flex>
      <CustomInput
        prefix={props.prefix}
        allowNegativeValue={false}
        suffix={props.suffix}
        style={{
          fontSize: isMobile ? '16px' : '0.8125rem',
          border: colorMode === 'light' ? '1px solid #E2E8F0' : '1px solid rgba(255, 255, 255, 0.16)',
          borderRadius: '0.375rem',
          width: props.column ? '100%' : '9rem',
          height: '38px',
          textAlign: 'left',
          paddingLeft: '16px',
          paddingRight: '16px',
          backgroundColor: 'transparent',
          ...props.style
        }}
        id="input-example"
        value={props.value}
        name="input-name"
        placeholder={props.placeholder}
        allowDecimals={props.preventDecimals ? false : true}
        decimalsLimit={2}
        onValueChange={(value, name, values) => {
          props.onValueChange(value, name, values);
        }}
      />
    </Flex>
  );
}

export default FormattedInput;
