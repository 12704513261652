import { io } from 'socket.io-client';

import list from '../../constants/binance.json';
import { store } from '../../index';
import axios from '../../utilities/axios';
import { handleDecryption } from '../../utilities/encryption';
import eventEmitter from '../../utilities/eventEmitter';
import * as actions from '../actions/actionTypes';

const API_URL = process.env.REACT_APP_API_URL;

//const DEV_API_URL = 'http://localhost:5020/';

const COINGECKO_URL = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=';

const socketUrl = 'ws://ec2-3-93-220-251.compute-1.amazonaws.com:8080';

const addBotStart = () => {
  return {
    type: actions.ADD_BOT_START,
    loading: true,
    addBotStaus: null
  };
};

const addBotFail = error => {
  return {
    type: actions.ADD_BOT_FAIL,
    loading: false,
    error: error
  };
};

const addBotSuccess = data => {
  return {
    type: actions.ADD_BOT_SUCCESS,
    loading: false,
    addBotStatus: data
  };
};

const getDescriptionsStart = () => {
  return {
    type: actions.GET_DESCRIPTIONS_START,
    loading: true
  };
};

const getDescriptionsFail = error => {
  return {
    type: actions.GET_DESCRIPTIONS_FAIL,
    loading: false,
    error: error
  };
};

const getDescriptionsSuccess = data => {
  return {
    type: actions.GET_DESCRIPTIONS_SUCCESS,
    loading: true,
    descriptions: data
  };
};

const getActiveBotsStatsStart = () => {
  return {
    type: actions.GET_ACTIVE_BOTS_STATS_START,
    loading: true
  };
};

const getActiveBotsStatsFail = error => {
  return {
    type: actions.GET_ACTIVE_BOTS_STATS_FAIL,
    loading: false,
    error: error
  };
};

const getActiveBotsStatsSuccess = data => {
  return {
    type: actions.GET_ACTIVE_BOTS_STATS_SUCCESS,
    loading: false,
    activeBotsStats: data,
    isBotSocketOn: true
  };
};

const getPlayStatsStart = () => {
  return {
    type: actions.GET_PLAY_STATS_START,
    loading: true
  };
};

const getPlayStatsFail = error => {
  return {
    type: actions.GET_PLAY_STATS_FAIL,
    loading: false,
    error: error
  };
};

const getPlayStatsSuccess = data => {
  return {
    type: actions.GET_PLAY_STATS_SUCCESS,
    loading: false,
    playStats: data
  };
};

const getActiveBotsConfigStart = () => {
  return {
    type: actions.GET_ACTIVE_BOTS_CONFIG_START,
    loading: true
  };
};

const getActiveBotsConfigFail = error => {
  return {
    type: actions.GET_ACTIVE_BOTS_CONFIG_FAIL,
    loading: false,
    error: error
  };
};

const getActiveBotsConfigSuccess = data => {
  return {
    type: actions.GET_ACTIVE_BOTS_CONFIG_SUCCESS,
    loading: false,
    activeBotsConfig: data,
    activeBotsConfigChecked: true
  };
};

const getInitialActiveBotsStart = () => {
  return {
    type: actions.GET_INITIAL_ACTIVE_BOTS_STATS_START,
    loading: true
  };
};

const getInitialActiveBotsFail = error => {
  return {
    type: actions.GET_INITIAL_ACTIVE_BOTS_STATS_FAIL,
    loading: false,
    error: error
  };
};

const getInitialActiveBotsSuccess = (activeStats, activeConfigs) => {
  return {
    type: actions.GET_INITIAL_ACTIVE_BOTS_STATS_SUCCESS,
    loading: false,
    initialActiveBotsStats: activeStats,
    initialActiveBotsConfig: activeConfigs,
    isBotSocketOn: true
  };
};

const getCompletedBotsStart = () => {
  return {
    type: actions.GET_COMPLETED_BOTS_START,
    loading: true
  };
};

const getCompletedBotsFail = error => {
  return {
    type: actions.GET_COMPLETED_BOTS_FAIL,
    loading: false,
    error: error
  };
};

const getCompletedBotsSuccess = data => {
  return {
    type: actions.GET_COMPLETED_BOTS_SUCCESS,
    loading: false,
    completedBots: data,
    completedBotsChecked: true
  };
};

const setBotModifyOptionsStart = () => {
  return {
    type: actions.SET_BOT_MODIFY_TERMINATE_OPTIONS_START,
    modifyBotTerminationLoading: true
  };
};

const setBotModifyOptionsFail = error => {
  return {
    type: actions.SET_BOT_MODIFY_TERMINATE_OPTIONS_FAIL,
    modifyBotTerminationLoading: false,
    modifyTerminateError: error
  };
};

const setBotModifyOptionsSuccess = data => {
  return {
    type: actions.SET_BOT_MODIFY_TERMINATE_OPTIONS_SUCCESS,
    modifyBotTerminationLoading: false,
    botTermination: data
  };
};

const getFxBotSettingsStart = () => {
  return {
    type: actions.GET_FX_BOT_SETTINGS_START,
    loading: true
  };
};

const getFxBotSettingsFail = error => {
  return {
    type: actions.GET_FX_BOT_SETTINGS_FAIL,
    loading: false,
    error: error
  };
};

const getFxBotSettingsSuccess = settings => {
  return {
    type: actions.GET_FX_BOT_SETTINGS_SUCCESS,
    fxSettings: settings,
    loading: false
  };
};

export const getFxBotSettings = () => {
  return async dispatch => {
    try {
      dispatch(getFxBotSettingsStart());
      const response = await axios.get(`${API_URL}getfxbotsettings`);
      dispatch(getFxBotSettingsSuccess(response.data));
    } catch (error) {
      dispatch(getFxBotSettingsFail(error));
    }
  };
};

export const getDescriptionTexts = () => {
  return async dispatch => {
    try {
      dispatch(getDescriptionsStart());
      const response = await axios.get(`${API_URL}getdescriptions`);
      const data = response.data;
      dispatch(getDescriptionsSuccess(data));
    } catch (error) {
      dispatch(getDescriptionsFail(error));
    }
  };
};

// ADD Exchange ID

export const getCompletedBots = (uuid, exchangeID) => {
  return async dispatch => {
    try {
      dispatch(getCompletedBotsStart());
      const body = {
        id: uuid,
        exchange_id: exchangeID
        // uuid: '0b00fa3e-fb0b-4424-bf93-ed63b0cdf82a'
      };
      const response = await axios.post(`${API_URL}getcompletedbots`, body);
      let data = [];
      for (let bot in response.data) {
        data.push({ ...response.data[bot], bot_id: bot });
      }
      if (exchangeID === 5 || exchangeID === 6) {
        return dispatch(getCompletedBotsSuccess(data));
      }
      let symbols = [];
      for (let bot in response.data) {
        symbols.push({
          symbol: response.data[bot].symbol_to_profit.toLowerCase(),
          bot_id: response.data[bot].bot_id
        });
        // symbols.push(response.data[bot].quoteAsset.toLowerCase());
      }
      let ids = [];
      for (let coin of symbols) {
        for (let asset of list) {
          if (coin.symbol.toLowerCase() === asset.symbol) {
            ids.push(asset.id);
          }
        }
      }
      ids = ids.join('%2C');
      const priceResponse = await axios.get(`${COINGECKO_URL}${ids}&order=market_cap_desc&per_page=100&page=1`);
      const priceData = priceResponse.data;
      let finalData = [];
      for (let bot in response.data) {
        // Porfit in coin * USD
        let b = {};
        for (let coin of priceData) {
          if (
            response.data[bot].quoteAsset.toLowerCase() !== response.data[bot].symbol_to_profit.toLowerCase() &&
            (response.data[bot].symbol_to_profit.toLowerCase() === coin.symbol ||
              response.data[bot].quoteAsset.toLowerCase() === 'usd')
          ) {
            b = {
              ...response.data[bot],
              bot_id: bot,
              overall_gain_usd: Number(response.data[bot].overall_gain) * coin.current_price
            };
          } else if (
            response.data[bot].quoteAsset.toLowerCase() === coin.symbol &&
            response.data[bot].symbol_to_profit.toLowerCase() === coin.symbol
          ) {
            b = {
              ...response.data[bot],
              bot_id: bot,
              overall_gain_usd: Number(response.data[bot].overall_gain) * coin.current_price
            };
          } else if (!Object.keys(b).includes('overall_gain_usd')) {
            b = {
              ...response.data[bot],
              bot_id: bot,
              overall_gain_usd: Number(response.data[bot].overall_gain)
            };
          }
        }
        finalData.push(b);
      }
      dispatch(getCompletedBotsSuccess(finalData));
    } catch (error) {
      console.log('completed bots error ', error);
      dispatch(getCompletedBotsFail(error));
    }
  };
};

export const getPlayStat = botID => {
  return async dispatch => {
    try {
      dispatch(getPlayStatsStart());
      const body = {
        bot_id: botID
      };
      const response = await axios.post(`${API_URL}playstats`, body);
      dispatch(getPlayStatsSuccess(response.data));
    } catch (error) {
      console.log('play stats error ', error);
      dispatch(getPlayStatsFail(error));
    }
  };
};

export const setBotTerminationOptions = data => {
  return async dispatch => {
    try {
      // dispatch(setBotModifyOptionsStart());
      // const body = {
      //   bot_id: data.botID,
      //   terminate_options_ext: data.terminationOption
      // };
      // const response = await axios.post(`${DEV_API_URL}modifyterminateoptions`, body);
      // dispatch(setBotModifyOptionsSuccess(response.data));
      dispatch(setBotModifyOptionsSuccess(data));
    } catch (error) {
      console.log('play stats error ', error);
      dispatch(setBotModifyOptionsFail(error));
    }
  };
};

export const getActiveBotsStats = (id, exchangeID, exchange, isNewBot = false, isBotTerminated = false) => {
  console.log('in bot.js :: getActiveBotsStats method');
  console.log('in bot.js :: getActiveBotsStats method :: id : ', id);
  return async dispatch => {
    try {
      dispatch(getActiveBotsStatsStart());
      // dispatch(getInitialActiveBotsStart());
      let body = {
        id: id
        // id: '0b00fa3e-fb0b-4424-bf93-ed63b0cdf82a'
      };
      if (exchangeID === 5 || exchangeID === 6) {
        body = {
          id: id,
          exchangeID: exchangeID
        };
      }
      // console.log('bot body ====> ', body);
      const response = await axios.post(`${API_URL}activebotsstat`, body);
      let data = [];
      for (let bot in response.data) {
        data.push({ ...response.data[bot], bot_id: bot });
      }

      const idata = await axios.post(`${API_URL}getinitialbotdata`, body);
      let initialConfigData = [];
      let initialActiveData = [];
      for (let bot in idata.data.user_bot_data.config) {
        initialConfigData.push(idata.data.user_bot_data.config[bot]);
      }
      dispatch(getActiveBotsConfigSuccess(initialConfigData));
      for (let bot in idata.data.user_bot_data.config) {
        let botStat = {
          ...idata.data.user_bot_data.stat[bot],
          bot_id: bot
        };
        initialActiveData.push(botStat);
      }
      dispatch(getActiveBotsStatsSuccess(initialActiveData));

      let finalData = [];

      if (exchange === 'binance') {
        exchange = 'binancecom';
      }

      const channel = `user_specific_data_${exchangeID}_${handleDecryption(id)}`;

      eventEmitter.on('userData', function (data) {
        finalData = [];
        let finalConfigData = [];
        let statData = data.user_bot_data.stat;
        let configData = data.user_bot_data.config;
        // console.log('user_specific_data _ 2');
        for (let bot in configData) {
          finalConfigData.push(configData[bot]);
        }
        // console.log('user_specific_data _ 3');
        for (let bot in configData) {
          let b = {
            ...statData[bot],
            bot_id: bot
          };
          finalData.push(b);
        }
        if (finalData.length > 0) {
          dispatch(getActiveBotsStatsSuccess(finalData));
          dispatch(getActiveBotsConfigSuccess(finalConfigData));

          //dispatch confing should be added here.
        }
      });
    } catch (error) {
      // console.log(error);
      dispatch(getActiveBotsStatsFail(error));
      dispatch(getInitialActiveBotsFail(error));
    }
  };
};

export const addBot = data => {
  return async dispatch => {
    try {
      dispatch(addBotStart());
      // let amount;
      // if (data.exchangeID === 3) {
      //   amount = Number(data.amount.slice(5));
      // } else {
      //   amount = Number(data.amount.slice(1));
      // }
      if (data.exchangeID === 5 || data.exchangeID === 6) {
        let body = {
          bot_name: data.botName,
          direction: data.botStyle.toLowerCase(),
          instrument: data.pair,
          exchange_id: data.exchangeID,
          initial_units: data.initialUnits,
          unit_addition: data.unitAddition,
          unit_multiplier: data.unitMultiplier,
          profit_price_constant: data.profitPriceConstant,
          additional_order_step: data.additionalOrderStep,
          stop_limit_granularity: data.stopLimitGranularity,
          stop_limit_buffer: data.stopLimitBuffer,
          max_margin_utilization: data.maxMarginUtilization,
          order_timer: data.orderTimer,
          user_id: data.userID,
          stripeCustomerId: data.stripeCustomerId
        };
        // console.log(body);
        // return;
        const response = await axios.post(`${API_URL}addbot`, body);
        console.log(response.data);
        return dispatch(addBotSuccess(response.data));
      }
      let body = {
        bot_name: data.botName,
        bot_status: data.status,
        bot_style: data.botStyle.toLowerCase(),
        exchange_id: data.exchangeID,
        max_amount: data.amount,
        stripeCustomerId: data.stripeCustomerId,
        // terminate_options: 1,
        symbol_pair: data.pair,
        symbol_to_profit: data.target,
        user_id: data.userID
      };
      if (data.botStyle === 'customized') {
        body = {
          ...body,
          bot_style: data.botStyle,
          extra_buy_timer: data.extra_buy_timer,
          minimum_profit_margin_percent: data.minimum_profit_margin_percent,
          first_boost_order_percentage: data.first_boost_order,
          stop_loss_buffer: data.stop_loss_buffer,
          stop_loss_granularity: data.stop_loss_granularity,
          extrabuy_up_limit_vs_avg_cost: data.extrabuy_up_limit_vs_avg_cost,
          doubledown_multiplier: data.doubledown_multiplier,
          order_cancel_timer: data.order_cancel_timer,
          stop_loss_price_sensitivity: data.stop_loss_price_sensitivity,
          trade_frequency: data.trade_frequency,
          slot_price: data.slot_price,
          boost_timer_constant: data.boost_timer_constant
        };
      }
      const response = await axios.post(`${API_URL}addbot`, body);
      if (response.data === 'Invalid Amount') {
        return dispatch(addBotFail(response.data));
      }
      dispatch(addBotSuccess(response.data));
      //   dispatch(addBotSuccess(true));
    } catch (error) {
      console.log(error);
      dispatch(addBotFail(error.message));
    }
  };
};
