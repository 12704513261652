import { Flex, Image, useColorMode, useMediaQuery } from '@chakra-ui/react';
import React from 'react';

import EFDARK from '../../assets/ef_dark.svg';
import EFLIGHT from '../../assets/ef_light.svg';
import EPICFLOW from '../../assets/eflogo.svg';

export const DrawerLogo = () => {
  return <Image w={10} src={EPICFLOW} ml="2.5%" />;
};

//TODO Image flashing when isDesktop

const Logo = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  const [isDesktop] = useMediaQuery('(min-width: 80em)');
  const justifyOptions = ['center', 'center', 'center', 'center', 'flex-start', 'flex-start'];
  return (
    <Flex w="full" mb={10} mt={6} justify={justifyOptions} ml={[0, 0, 0, 0, 10, 10]}>
      <Image
        // boxSize= '144px'
        // w={[10, 10, 10, 10, 40, 40]}
        w={10}
        src={EPICFLOW}
        // src={!isDesktop ? EPICFLOW : colorMode === 'light' ? EFDARK : EFLIGHT}
        // src={EPICFLOW}
      />
      {/* <Flex 
                direction='column'
                align='flex-start'
                justify='center'
                ml={5}
            > 
                <Text  
                    fontSize='lg'
                    fontWeight
                >EpicFlow</Text>
            </Flex> */}
    </Flex>
  );
};

export default Logo;
