import { Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import Amplify, { Auth, Cache } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import EPICFLOW from '../../assets/eflogo.svg';
import Footer from '../../components/navigation/footer/footer';

const WaitingList = ({ isAuth, isApproved, history, id }) => {
  //   const checkAuth = () => {
  //     if (!isAuth) {
  //       history.push('/signup');
  //     } else if (isAuth && isApproved) {
  //       history.push('/');
  //     }
  //   };

  //   checkAuth();

  const handleLogout = async () => {
    let keysToRemove = ['token', 'ef_user_id', 'browser_id', 'token_exp', 'tf'];
    // dispatch(stopSocket(authState.id, socketState?.socket, localStorage.getItem('browser_id')));
    // dispatch(stopSocket(handleDecryption(authState.id), socketState?.socket, localStorage.getItem('browser_id')));
    for (let key of keysToRemove) {
      localStorage.removeItem(key);
    }
    await Auth.signOut().then(() => window.location.replace('/login'));
    // window.location.assign('/login');
    // history.push('/login');
  };

  return (
    <Flex
      overflow="hidden"
      w="full"
      minH="100vh"
      maxH="100vh"
      align="center"
      //   bg="black"
      flexDir="column"
    >
      <Flex w="90%" justifyContent="flex-end" alignItems="center" flex={1}>
        <Button
          bg="transparent"
          _active={{ background: 'transparent' }}
          _hover={{ background: 'transparent' }}
          fontSize="xs"
          borderWidth={1}
          _focus={{ outline: 'none' }}
          onClick={handleLogout}
        >
          Log Out
        </Button>
      </Flex>
      <Flex
        justify="center"
        align="center"
        //   bg="black"
        flexDir="column"
        pb={6}
        flex={10}
      >
        <Image w={14} src={EPICFLOW} mb={4} />
        <Text fontSize="4xl">Thank you for joining</Text>
        <Text
          background="linear-gradient(to right, #12c2e9, #c471ed, #f64f59)"
          backgroundClip="text"
          fontSize="5xl"
          fontWeight="black"
          lineHeight="3.5rem"
          style={{ WebkitTextFillColor: 'transparent' }}
        >
          Epicflow.io!
        </Text>
        <Text mt={3} color="gray.500" fontSize="normal">
          We&apos;ll notify you once you&apos;re off the waiting list.
        </Text>
      </Flex>
      <Flex w="full" flex={1}>
        <Footer waitinglist />
      </Flex>
    </Flex>
  );
};

export default WaitingList;
