import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  chakra,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { CgArrowsExchange } from 'react-icons/cg';
import { RiInformationLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import { setBotTerminationOptions } from '../../store/actions/bot';
import { epicFlowGradientColors } from '../../theme';
import axios from '../../utilities/axios';
import TerminationCard from '../cards/terminationCard';
import EFButton from '../UI/Button/efButton';

const Termination = props => {
  // if option 1 nothing is being selected otherwise select the current option when modal opens
  const [selected, setSelected] = useState();

  const [loading, setLoading] = useState(false);

  const [terminationOptions, setTerminationOptions] = useState([
    `Stop the bot now without ${props.inverse ? 'selling' : 'buying'} anything further.`,
    `Convert into ${props.symbol_to_profit} at market price.`,
    `Put limit order at 1.0% profit vs. current average cost and then stop the bot.`,
    `Stop the bot when the current active session is succesfully completed.`
  ]);

  const [radioValue, setRadioValue] = useState();

  const [limitInput, setLimitInput] = useState('');

  const dispatch = useDispatch();

  const [isRestored, setIsRestored] = useState(false);

  const botState = useSelector(state => state.bot);

  const [isReviewed, setIsReviewed] = useState(false);

  const [isTerminated, setIsTerminated] = useState(false);

  const [isTerminationError, setIsTerminationError] = useState(false);

  const handleInput = value => {
    value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    return value;
  };

  const handleChange = event => {
    let v = event.target.value;
    v = handleInput(v);
    setLimitInput(v);
  };

  // useEffect(() => {
  //   console.log(props.terminatedOption);
  //   setSelected(props.terminatedOption);
  // }, [props.terminatedOption]);

  //most likely termination actions will be handled here instead of redux. However, when current bot status is updated, trading bot page
  //will be needing to be updated too. <== This is where redux should be involved.

  const handleSelection = useCallback(option => {
    setSelected(option);
  }, []);

  useEffect(() => {
    if (!props.isopen) {
      if (props.terminatedOption !== 1) {
        setSelected(props.terminatedOption);
      } else {
        setSelected();
      }
      setIsReviewed(false);
      setIsTerminationError(false);
      setIsTerminated(false);
      setIsRestored(false);
    }
  }, [props.isopen, props.terminatedOption]);

  const handleTermination = useCallback(async option => {
    setLoading(true);
    let body = {
      bot_type: 'DCA',
      bot_id: props.botID,
      terminate_options: option
    };
    if (option === 3) {
      body = {
        ...body,
        terminate_options_ext: {
          type: 'market'
        }
      };
    }
    const API_URL = process.env.REACT_APP_API_URL;
    // const DEV_API_URL = 'http://localhost:5020/';
    const response = await axios.post(`${API_URL}modifyterminateoptions`, body);
    //TODO check response data here
    // console.log('response ', response);
    if (response.data.error) {
      setLoading(false);
      setIsReviewed(true);
      setIsTerminationError(response.data.error);
      return;
    }
    if (option === 1) {
      setIsRestored(true);
    }
    setLoading(false);
    setIsTerminated(true);
    setIsReviewed(true);
    // console.log(response.data);
    // dispatch(setBotTerminationOptions(response.data));
  }, []);

  const handleCancel = useCallback(() => {
    setIsTerminated(false);
    setIsReviewed(false);
    setIsTerminationError(false);
    if (props.terminatedOption !== 1) {
      setSelected(props.terminatedOption);
    } else {
      setSelected();
    }
  }, []);

  const handleReview = useCallback(async option => {
    setIsReviewed(true);
  }, []);

  const setBotMessage = sel => {
    switch (sel) {
      case 2:
        return `Your ${props.name} bot will be terminated immediately. It will not buy or sell what it has accumalated during the run. All the gains up to this run will stay in your gains except this last run since it is prematurely terminated.`;
      case 3:
        return `Your ${props.name} bot will be terminated immediately as long as you have enough assets to buy or sell.`;
      case 4:
        return `Your ${props.name} bot will be terminated by putting a limit order. The gains or losses will not be tracked.`;
      case 5:
        return `Your ${props.name} bot will be terminated once your run completes successfully.`;
      default:
        return `Your ${props.name} Opps!!! this should have not happened, please reach out to our support`;
    }
  };

  return (
    <Modal
      isOpen={props.isopen}
      onClose={props.onclose}
      isCentered
      returnFocusOnClose={false}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent borderRadius="sm">
        <ModalCloseButton
          _focus={{
            outline: 'none'
          }}
        />
        <ModalHeader fontWeight="bold" fontSize="xl" mt={10} pb={0} pt={0}>
          {props.title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={2}>
          <Flex direction="column" justify="flex-start" align="flex-start">
            <Text fontSize="sm">
              Current Net Gain:{' '}
              <chakra.span color={props.cpg < 0 ? '#ef233c' : '#48BB78'}>{props.currentPlayGain}</chakra.span>
            </Text>
            <Text fontSize="xs" color="gray.500">
              *All of the Sessions&apos; total profit plus/minus current sessions&apos; gain.
            </Text>
            <Text fontSize="xs" mt={2}>
              {!props.inverse
                ? `We bought ${props.total_qty_purchased} so far with ${props.quoteSpend}.`
                : `We sold ${props.quoteSpend} worth of ${props.symbol_to_profit} so far.`}
            </Text>
            {!isReviewed && (
              <Flex mt={4} direction="column">
                {terminationOptions.map((el, idx) => {
                  return (
                    <TerminationCard
                      selected={idx + 2 === selected}
                      onClick={() => handleSelection(idx + 2)}
                      option={el}
                      index={idx}
                      key={idx}
                      isTerminated={props.terminatedOption === 5}
                      onLetItRunClick={() => handleTermination(1)}
                      setMarketRadioValue={() => setRadioValue('market')}
                      setLimitRadioValue={() => setRadioValue('limit')}
                      limitInput={limitInput}
                      handleChange={handleChange}
                      radioValue={radioValue}
                    />
                  );
                })}
              </Flex>
            )}
          </Flex>
          {!isReviewed && (
            <Flex justify="flex-start" align="center">
              <Icon
                as={RiInformationLine}
                w="16px"
                h="16px"
                mr={2}
                variant="unstyled"
                _focus={{
                  outline: 'none'
                }}
                color="red.300"
              />
              <Text color="red.300" fontSize="0.65rem" mt={2}>
                The actual amounts may change due to market fluctuations and subject to how your Crypto Exchange
                executes these trades.
              </Text>
            </Flex>
          )}
          {!isReviewed && (
            <EFButton
              title="Review"
              isDisabled={!selected}
              w="full"
              mt={8}
              mb={4}
              isLoading={loading}
              bg={epicFlowGradientColors.left}
              onClick={() => handleReview(selected)}
            />
          )}
          {isReviewed && !isTerminated && !isTerminationError && (
            <Flex w="full" direction="column" alignItems="center" mt={10}>
              <AlertTitle fontWeight="semibold">Terminate Bot?</AlertTitle>
              <Text my={2}>Are you sure you want to terminate {props.name}?</Text>
              <Alert status="warning" variant="subtle" mb={4} mt={4} flexDirection="column" alignItems="center">
                <AlertIcon boxSize="28px" mb={2} />
                <AlertDescription fontSize="sm">{setBotMessage(selected)}</AlertDescription>
              </Alert>
              <Text fontSize="sm" my={4} w="full" textAlign="center" fontWeight="medium">
                {/* Termination Option: {terminationOptions[selected - 2]} */}
                {terminationOptions[selected - 2]}
              </Text>
              <Flex alignItems="center" justifyContent="space-evenly" mt={2} w="full" pb={8}>
                <Button
                  // maxW="fit-content"
                  // size="lg"
                  onClick={handleCancel}
                  w={36}
                  fontSize="xs"
                  colorScheme="gray"
                  // _hover={{ background: 'none' }}
                  _focus={{ outline: 'none' }}
                >
                  Cancel
                </Button>
                <Button
                  w={36}
                  fontSize="xs"
                  bg="red.500"
                  color="white"
                  isLoading={loading}
                  _hover={{ background: 'red' }}
                  _focus={{ outline: 'none' }}
                  onClick={() => handleTermination(selected)}
                  //   _active={{ background: epicFlowGradientColors.right }}
                >
                  Confirm
                </Button>
              </Flex>
            </Flex>
          )}
          {isReviewed && isTerminated && (
            <Flex direction="column" alignItems="center" mt={8}>
              <Alert
                status="success"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="150px"
              >
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  Succesfull!
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  {isRestored ? 'Your bot will continue to run.' : 'Your bot is successfully terminated.'}
                </AlertDescription>
              </Alert>
              <Button
                w={36}
                my={6}
                fontSize="xs"
                colorScheme="gray"
                _focus={{ outline: 'none' }}
                onClick={props.onclose}
                //   _active={{ background: epicFlowGradientColors.right }}
              >
                Close
              </Button>
            </Flex>
          )}
          {isReviewed && isTerminationError && (
            <Flex direction="column" alignItems="center" mt={8}>
              <Alert
                status="error"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="150px"
              >
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  Error!
                </AlertTitle>
                <AlertDescription maxWidth="sm">{isTerminationError}</AlertDescription>
              </Alert>
              <Button
                w={36}
                my={6}
                fontSize="xs"
                colorScheme="gray"
                _focus={{ outline: 'none' }}
                onClick={props.onclose}
                //   _active={{ background: epicFlowGradientColors.right }}
              >
                Close
              </Button>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Termination;
