import { Flex, Heading, Image, ListItem, OrderedList, Text } from '@chakra-ui/react';
import React from 'react';

import BNBBTC_Inverse_Createbotpage from '../../assets/bnbbtc-inverse-createbotpage.png';
import BNBBTC_Regular_Createbotpage from '../../assets/bnbbtc-regular-createbotpage.png';
import Inverse_BNBBTC_Graph from '../../assets/inverse-bnb-btc-bot-graph.png';
import Inverse_BNBBTC_Wizard from '../../assets/inverse-bnb-btc-bot-wizard2.png';
import Regular_BNBBTC_Wizard from '../../assets/regular-bnb-btc-bot-wizard2.png';
import Regular_BNBBTC_Graph from '../../assets/regular-bnb-btc-graph.png';
import Regular_BTCUSD_Wizard from '../../assets/regular-btc-usd-bot-wizard2.png';

const topHeadingSize = ['3xl', '3xl', '3xl', '3xl', '3xl', '4xl'];
const imageTextContainerDirection = ['column', 'column', 'column', 'row', 'row', 'row'];
const imageTextContainerGap = [6, 6, 6, 10, 10, 16];
const textContainerWidth = ['100%', '100%', '100%', '65%', '60%', '50%'];
const textSize = ['md', 'md', 'md', 'md', 'lg', 'xl'];
const wizardImageHeight = ['600px', '600px', '600px', '500px', '550px', '600px'];
const chartImagesWidth = ['95%', '90%', '90%', '80%', '80%', '80%'];

function CreateBots() {
  return (
    <Flex w="100%" direction="column" justify="center" align="center" mt={4} mb={20}>
      <Flex w="95%" direction="column" justify="center" align="center" fontWeight="light">
        <Heading mb={[8, 8, 10, 10, 12, 12]} fontWeight="light" textAlign="center" fontSize={topHeadingSize}>
          How to Create Regular and Inverse Bots?
        </Heading>

        <Flex id="creating-regular-bot" w="full" direction="column" mb={12}>
          <Heading w="full" textAlign="left" fontWeight="bold" fontSize="2xl" mb={4}>
            Creating The Regular Bots
          </Heading>
          <Flex
            direction={imageTextContainerDirection}
            w="full"
            justify="flex-start"
            align="center"
            gap={imageTextContainerGap}
            mb={16}
            // border="1px"
          >
            <Flex
              direction="column"
              w={textContainerWidth}
              textAlign="left"
              fontSize={textSize}
              //  border="1px"
            >
              <Text mb={4} w="full" textAlign="left">
                Creating bots has never been easier with EpicFlow.io. You can create bots with the bot automation
                wizard in few clicks.
              </Text>
              <OrderedList spacing={2}>
                <ListItem>
                  <Text>Select the exchange (if you have more than one registered exchange), </Text>
                </ListItem>
                <ListItem>
                  <Text>Select the target asset that you would like the bot to make a profit,</Text>
                </ListItem>
                <ListItem>
                  <Text>Select the crypto pair that you would like the bot to trade,</Text>
                </ListItem>
                <ListItem>
                  <Text>Type in a max allowed capital to be spend for this bot,</Text>
                </ListItem>
                <ListItem>
                  <Text>Select your risk profile from the bot type,</Text>
                </ListItem>

                <ListItem>
                  <Text>Give a name to your bot and hit preview before final confirmation.</Text>
                </ListItem>
              </OrderedList>

              <Text mb={4} mt={4}>
                Your bot will appear in my bots tab once the first trade is completed.
              </Text>
            </Flex>

            <Flex
              direction="column"
              justify="center"
              align="center"
              // rounded="3xl"
              // w="50%"
              // border="1px"
              // borderColor="red.300"
              // overflow="hidden"
            >
              <Image
                alt="EpicFlow.io regular BTC USD trading bot wizard."
                src={Regular_BTCUSD_Wizard}
                height={wizardImageHeight}
                py={4}
                // fit='contain'
              />
            </Flex>
          </Flex>

          <Flex
            direction={imageTextContainerDirection}
            w="full"
            justify="flex-start"
            align="center"
            gap={imageTextContainerGap}
            mb={20}
            //  border="1px"
          >
            <Flex
              display={['none', 'none', 'none', 'flex', 'flex', 'flex']}
              direction="column"
              justify="flex-start"
              align="flex-start"
              // w="30%"
              // border="1px"
              // borderColor="red.300"
            >
              <Image
                alt="EpicFlow.io regular BTC USD trading bot wizard."
                src={Regular_BNBBTC_Wizard}
                py={4}
                height={wizardImageHeight}
                // fit='contain'
              />
            </Flex>

            <Flex
              direction="column"
              w={textContainerWidth}
              textAlign="left"
              fontSize={textSize}
              justify="space-between"
              //  border="1px"
            >
              <Text w="full" textAlign="left" mb={8} fontSize={textSize}>
                Similarly, you can create a regular crypto-crypto pair bot as well. For example, you may want to
                make BTC using a BNB-BTC pair on Binance continuously…{' '}
              </Text>

              <Text w="full" mb={4} fontSize={textSize} textAlign="left">
                This BNB-BTC regular bot works the following way after being created. Below is a sample run where
                the bot smartly buys every time the BNB-BTC price goes down and finally sells all the accumulated
                BNB-BTC back when the price hikes to “mint” more BTC than you had when the run started.
              </Text>
            </Flex>

            <Flex
              display={['flex', 'flex', 'flex', 'none', 'none', 'none']}
              direction="column"
              justify="flex-start"
              align="flex-start"
              // w="30%"
              // border="1px"
              // borderColor="red.300"
            >
              <Image
                alt="EpicFlow.io regular BTC USD trading bot wizard."
                src={Regular_BNBBTC_Wizard}
                py={4}
                height={wizardImageHeight}
                // fit='contain'
              />
            </Flex>
          </Flex>

          <Flex width="full" justify="center" align="center" mb={14}>
            <Image
              w={chartImagesWidth}
              alt="Create Binance API Key"
              src={BNBBTC_Regular_Createbotpage}
              // src="/regular-bnb-btc-graph.ico"
              py={4}
            />
          </Flex>

          {/* <Flex width="full" justify="center" align="center" mb={14}>
            <Image w="80%" alt="Create Binance API Key" src={Regular_BNBBTC_Graph} py={4} />
          </Flex> */}
        </Flex>

        <Flex id="creating-inverse-bot" w="full" direction="column">
          <Heading w="full" textAlign="left" fontWeight="bold" fontSize="2xl" mb={4}>
            Creating The Inverse Bots
          </Heading>

          <Flex
            direction={imageTextContainerDirection}
            w="full"
            justify="flex-start"
            align="center"
            gap={imageTextContainerGap}
            mb={20}
            //  border="1px"
          >
            <Flex
              display={['none', 'none', 'none', 'flex', 'flex', 'flex']}
              direction="column"
              justify="flex-start"
              align="flex-start"
              // w="50%"
              // border="1px"
              // borderColor="red.300"
            >
              <Image
                alt="EpicFlow.io regular BTC USD trading bot wizard."
                src={Inverse_BNBBTC_Wizard}
                py={4}
                height={wizardImageHeight}
                // fit='contain'
              />
            </Flex>

            <Flex
              direction="column"
              w={textContainerWidth}
              textAlign="left"
              fontSize={textSize}
              justify="space-between"
              //  border="1px"
            >
              <Text mb={4} w="full" textAlign="left">
                Actually, you don&apos;t even need to know if a bot is regular or inverse but you will easily
                recognize the type when you open any of the run charts.
              </Text>

              <Text mb={4} mt={4}>
                Technically, inverse bots also smartly benefit from the volatility of the crypto pairs when the
                trend is upward. Our users typically create both types to continue profiting whether the trends is
                up or down.
              </Text>

              <Text mb={4} mt={4}>
                In the example at the right, we would like to continuously gain BNB asset using the BNB-BTC crypto
                pair (because it is a high volume pair or we prefer this pair due to our research). You can easily
                create it with very simple steps as explained.
              </Text>
            </Flex>

            <Flex
              display={['flex', 'flex', 'flex', 'none', 'none', 'none']}
              direction="column"
              justify="flex-start"
              align="flex-start"
              // w="50%"
              // border="1px"
              // borderColor="red.300"
            >
              <Image
                alt="EpicFlow.io regular BTC USD trading bot wizard."
                src={Inverse_BNBBTC_Wizard}
                py={4}
                height={wizardImageHeight}
                // fit='contain'
              />
            </Flex>

            {/* <Flex
              direction="column"
              justify="center"
              align="center"
              // w="50%"
              // border="1px"
              // borderColor="red.300"
            >
              <Image
                alt="EpicFlow.io regular BTC USD trading bot wizard."
                src={Inverse_BNBBTC_Wizard}
                // src="/inverse-bnb-btc-bot-wizard2.ico"
                py={4}
                height="650px"
                // fit='contain'
              />
            </Flex> */}
          </Flex>

          <Text mb={14} fontSize={textSize} w="100%">
            Once created, this (inverse) BNB-BTC bot will continuously make more BNB for your exchange balance
            following below like runs non-stop 7/24 as long as your “Max spend” amount is not exhausted. If the
            pre-set max amount of $1,000 is hit, you can also increase it from the basic bot settings if you wish
            to.
          </Text>

          <Flex
            direction="column"
            justify="center"
            align="center"
            w="100%"
            // border="1px"
            // borderColor="red.300"
          >
            <Image
              alt="EpicFlow.io regular BTC USD trading bot wizard."
              src={BNBBTC_Inverse_Createbotpage}
              py={4}
              w={chartImagesWidth}
              // width="600px"
              // fit='contain'
            />
          </Flex>

          {/* <Flex
            direction="column"
            justify="center"
            align="center"
            w="100%"
            // border="1px"
            // borderColor="red.300"
          >
            <Image
              alt="EpicFlow.io regular BTC USD trading bot wizard."
              src={Inverse_BNBBTC_Graph}
              py={4}
              w="50%"
              // width="600px"
              // fit='contain'
            />
          </Flex> */}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CreateBots;
