import { Flex, Heading, Image, ListItem, OrderedList, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

import CreateApiKeyBinance1 from '../../assets/create-api-key-binance1.png';
import CreateApiKeyBinance2 from '../../assets/create-api-key-binance2.png';
import CreateApiKeyBinance3 from '../../assets/create-api-key-binance3.png';
import CreateApiKeyBinance4 from '../../assets/create-api-key-binance4.png';
import CreateApiKeyBinance5 from '../../assets/create-api-key-binance5.png';

const topHeadingSize = ['2xl', '2xl', '2xl', '3xl', '3xl', '4xl'];
const imageWidths = ['100%', '100%', '80%', '70%', '60%', '60%'];

function CreateBinanceApiKey() {
  return (
    <Flex w="100%" direction="column" justify="center" align="center" mt={4}>
      <Flex w="95%" direction="column" justify="center" align="center">
        <Heading mb={[8, 8, 10, 10, 12, 12]} textAlign="center">
          How to Create an API Key on Binance?
        </Heading>
        <Text mb={4}>
          Once you have a Binance account, the next step is to enable the 2FA authentication and request an API
          Key. We can do that under settings API management as shown in below screenshot. Binance trading platform
          allows users to remotely access third-party platforms like epicflow.io with their accounts through this
          API. Here are the step by step guide on how to do it:
        </Text>
        <Flex mb={14}>
          <OrderedList spacing={2}>
            <ListItem>
              <Text>Login to the existing account on Binance.</Text>
            </ListItem>
            <ListItem>
              <Text>
                Verify your identity. Intermediate verification is mandatory in case you want to use all
                exchange&apos;s features, including automated trading on Binance.
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                Create your API by clicking on the user icon in the top right corner and hit [API Management]. In
                API management page click on the [Create API] button. Give it a custom name, something that makes
                it easy to identify like &quot;EpicFlow API&quot;.
              </Text>
            </ListItem>

            <Flex w="full" justify="center" align="center">
              <Image
                // boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
                alt="Create Binance API Key"
                src={CreateApiKeyBinance1}
                py={4}
                w={imageWidths}
              />
            </Flex>

            <Flex w="full" justify="center" align="center">
              <Image alt="Create Binance API Key" src={CreateApiKeyBinance2} py={4} w={imageWidths} />
            </Flex>

            <ListItem>
              <Text>Complete 2FA via your phone number, by email, or with Google authenticator app.</Text>
            </ListItem>
            <ListItem>
              <Text>Click on &quot;Edit Restrictions&quot; so that you can enable remote trading.</Text>
            </ListItem>

            <Flex w="full" justify="center" align="center">
              <Image alt="Create Binance API Key" src={CreateApiKeyBinance3} py={4} w={imageWidths} />
            </Flex>

            <ListItem>
              <Text>
                Click on the &quot;Enable Spot Trading&quot; for remote trading. Copy BOTH of the keys to somewhere
                safe so that you can later paste them to our epicflow.io application. If you do not get a copy of
                the Secret Key, it will become invisible once you click save on this screen and you may need to
                re-create another API. Never enable the &quot;Enable withdrawals&quot; box for increased security.
              </Text>
            </ListItem>

            <Flex w="full" justify="center" align="center">
              <Image alt="Create Binance API Key" src={CreateApiKeyBinance4} py={4} w={imageWidths} />
            </Flex>

            <Flex w="full" justify="center" align="center">
              <Image alt="Create Binance API Key" src={CreateApiKeyBinance5} py={4} w={imageWidths} />
            </Flex>

            <ListItem>
              <Text>Hit the [Save] and [Confirm] button when you are ready.</Text>
            </ListItem>

            <ListItem>
              <Text>
                Integrate EpicFlow.io trading bots with your Binance account. To do that follow steps in{' '}
                <Text as="span" color="messenger.600" textDecoration="underline">
                  <Link to="/help/howtointegrate"> here.</Link>
                </Text>
              </Text>
            </ListItem>
          </OrderedList>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CreateBinanceApiKey;
