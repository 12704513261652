import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Flex,
  Heading,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorMode
} from '@chakra-ui/react';
import React from 'react';
import { FiHelpCircle, FiLogOut, FiMoon, FiPlus, FiSettings, FiSun } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { darkThemeBackgroundColor, epicFlowGradientColors } from '../../theme';
import ConditionalLink from '../UI/Link/conditionalLink';

const User = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const IMAGE_URL = 'https://pbs.twimg.com/profile_images/1492772642151010304/4L-nVyMo_400x400.jpg';

  const authState = useSelector(state => state.auth);

  return (
    <Menu autoSelect={false}>
      <MenuButton
        ml={4}
        // boxSize={8}
        // borderRadius="full"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        _hover={
          {
            // backgroundColor: colorMode === 'light' ? 'gray.200' : 'gray.700'
            // marginRight: 0
            // backgroundColor: '#48BB78'
            // backgroundColor: props.color
          }
        }
      >
        <Flex alignItems="center">
          {props.isPicture ? (
            <Flex
              w="45px"
              h="45px"
              justify="center"
              align="center"
              m="auto"
              // borderWidth={1}
              borderRadius="full"
              borderColor={props.color}
              // bg={colorMode === 'light' ? 'gray.200' : 'gray.700'}
              // bg="#e2fcde"
              bg={props.faded}
            >
              <Avatar boxSize={10} src={props.imgLink} />
            </Flex>
          ) : null}
          {!props.isPicture ? (
            <Flex
              w="45px"
              h="45px"
              justify="center"
              align="center"
              m="auto"
              borderWidth={1}
              borderRadius="full"
              borderColor={colorMode === 'light' ? '#000' : '#fff'}
              // bg={colorMode === 'light' ? 'gray.200' : 'gray.700'}
              // bg="#e2fcde"
              bg={props.faded}
            >
              <Text fontSize="sm" color={colorMode === 'light' ? '#000' : '#fff'}>
                {props.userInitial}
              </Text>
            </Flex>
          ) : null}

          {/* <Flex alignItems="flex-start" direction="column" ml={2}>
            <Flex alignItems="center">
              <Text fontSize="xs" fontWeight="bold" color={colorMode === 'light' ? '#000' : '#fff'}>
                {authState.name}
              </Text>
              <ChevronDownIcon boxSize={3} ml={2} />
            </Flex>
            <Text fontSize="xs" fontWeight="bold" color="yellow.500">
              {authState.stripeCurrentPlan}
            </Text>
          </Flex> */}
        </Flex>
      </MenuButton>
      <MenuList
        bg={colorMode === 'light' ? 'white' : darkThemeBackgroundColor.dark}
        position="absolute"
        borderRadius="xl"
        w="xs"
        right={-10}
        borderWidth={1}
        boxShadow="sm"
        alignItems="center"
      >
        <Flex direction="column" p="6.4px 12.8px 6.4px 12.8px" alignItems="center">
          <Flex alignItems="center">
            {props.isPicture ? (
              <Flex
                w="75px"
                h="75px"
                justify="center"
                align="center"
                m="auto"
                // borderWidth={1}
                borderRadius="full"
                borderColor={props.color}
                // bg={colorMode === 'light' ? 'gray.200' : 'gray.700'}
                // bg="#e2fcde"
                bg={props.faded}
              >
                <Avatar boxSize={14} src={props.imgLink} />
              </Flex>
            ) : null}
            {!props.isPicture ? (
              <Flex
                w="56px"
                h="56px"
                justify="center"
                align="center"
                m="auto"
                mb={2}
                borderWidth={1}
                borderRadius="full"
                borderColor={colorMode === 'light' ? '#000' : '#fff'}
                // bg={colorMode === 'light' ? 'gray.200' : 'gray.700'}
                // bg="#e2fcde"
                bg={props.faded}
              >
                <Text fontSize="xl" color={colorMode === 'light' ? '#000' : '#fff'}>
                  {props.userInitial}
                </Text>
              </Flex>
            ) : null}
          </Flex>
          {/* <Heading fontWeight="bold" fontSize="sm">
            {props.email}
          </Heading> */}
          <Flex alignItems="center" direction="column">
            <Flex alignItems="center">
              <Text fontSize="sm" fontWeight="bold" color={colorMode === 'light' ? '#000' : '#fff'}>
                {authState.name ? authState.name : authState.email}
              </Text>
            </Flex>
            <Text fontSize="sm" fontWeight="bold" color="yellow.500" mt={2}>
              {authState.stripeCurrentPlan}
            </Text>
          </Flex>
          {/* <Text fontSize="0.8125rem" color="gray.500" mt={4}>
            Portfolio Value
          </Text>
          <Text fontSize="3xl" mt="-1">
            {props.portfolioTotal}
          </Text> */}
        </Flex>
        <Flex direction="column" px={6}>
          <MenuDivider mb={4} />
          {/* <MenuItem
          fontSize="sm"
          mb={1}
          onClick={props.onAddExClick}
          icon={<Icon as={FiPlus} w={4} h={4} mt={1.5} />}
        >
          Add Exchange
        </MenuItem> */}
          <Link
            as={RouterLink}
            to="/settings"
            textDecoration="none"
            _hover={{
              textDecoration: 'none'
            }}
          >
            <MenuItem
              fontSize="14px"
              // fontWeight="semibold"
              borderRadius="xl"
              py={3}
              mb={2}
              icon={<Icon as={FiSettings} w={4} h={4} mt={1.5} />}
            >
              Settings
            </MenuItem>
          </Link>
          <MenuItem
            fontSize="14px"
            // fontWeight="semibold"
            mb={2}
            borderRadius="xl"
            py={3}
            onClick={toggleColorMode}
            icon={<Icon as={colorMode === 'light' ? FiMoon : FiSun} w={4} h={4} mt={1.5} />}
          >
            {colorMode === 'light' ? 'Dark Theme' : 'Light Theme'}
          </MenuItem>
          {/* <Link
          as={RouterLink}
          to="/help"
          textDecoration="none"
          _hover={{
            textDecoration: 'none'
          }}
        >
          <MenuItem
            fontSize="13px"
            fontWeight="semibold"
            mb={1}
            icon={<Icon as={FiHelpCircle} w={4} h={4} mt={1.5} />}
          >
            Help
          </MenuItem>
        </Link> */}
          <MenuItem
            fontSize="14px"
            // fontWeight="semibold"
            borderRadius="xl"
            py={3}
            mb={1}
            icon={<Icon as={FiLogOut} w={4} h={4} mt={1.5} />}
            onClick={props.onLogout}
          >
            Log Out
          </MenuItem>
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default User;
