import { Divider, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import CreateBinanceApiKey from '../components/helpComponents/CreateBinanceApiKey';
import CreateBots from '../components/helpComponents/CreateBots';
import HelpDeskMenu from '../components/helpComponents/HelpDeskMenu';
import HowToIntegrate from '../components/helpComponents/HowToIntegrate';
import TrainingVideos from '../components/helpComponents/TrainingVideos';
import WhatIsDCA from '../components/helpComponents/WhatIsDCA';

const sideBarDisplay = ['none', 'none', 'flex', 'flex', 'flex', 'flex'];
const sideBarWidth = ['20%', '20%', '25%', '20%', '20%', '20%'];
const sideBarTextSize = ['lg', 'lg', 'xs', 'sm', 'md', 'md'];

// const gridColumns = [1, 1, 1, 3, 3, 3];
// const gridWidth = ['80%', '80%', '80%', '90%', '80%', '80%'];
// const videoContainerWidth = ['80%', '80%', '80%', '90%', '80%', '80%'];
// const boxHeight = ['300px', '270px', '270px', '300px', '270px', '300px'];
// const boxHeadingSize = ['xl', 'xl', '2xl', 'xl', 'xl', 'xl'];
// const videoWidth = '100%';
// const videoHeight = '90%';

const Help = props => {
  const renderHelpContent = useMemo(() => {
    if (props.helpContent === 'createbinanceapikey') {
      return <CreateBinanceApiKey />;
    } else if (props.helpContent === 'howtointegrate') {
      return <HowToIntegrate />;
    } else if (props.helpContent === 'whatisdca') {
      return <WhatIsDCA />;
    } else if (props.helpContent === 'createbots') {
      return <CreateBots />;
    } else if (props.helpContent === 'trainingvideos') {
      return <TrainingVideos />;
    } else {
      return <CreateBinanceApiKey />;
    }
  });

  return (
    <Flex
      w={['full', 'full', '95%', '95%', '95%', '95%']}
      // maxW="7xl"
      justify="space-between"
      overflowY="hidden"
      overflowX="hidden"
      margin="auto"
      pb={24}
      mt={8}
      // border="1px"
      // borderColor="red.500"
    >
      <Flex
        w={['full', 'full', 'full', 'full', 'full', 'full']}
        direction="column"
        justify="flex-start"
        minH="100vh"
        align="flex-start"
        // border="1px"
      >
        {/* <Heading w="full" fontSize="2xl" mt={4} mb={4} pl={2}>
          Help Desk
        </Heading> */}

        <Heading w="full" fontSize="2xl" my={4}>
          <HelpDeskMenu helpContent={props.helpContent} />
        </Heading>

        <Divider />

        <Flex w="full" justify="center" align="flex-start" mt={8}>
          <Flex w="full">{renderHelpContent}</Flex>

          <Flex
            display={sideBarDisplay}
            direction="column"
            w={sideBarWidth}
            h="full"
            pl={2}
            boxShadow="rgba(33, 35, 38, 0.1) -2px 0px 0px 0px"
            borderLeftWidth={1}
            fontSize={sideBarTextSize}
            // borderLeft="1px"
            // border="1px"
            // boxShadow="lg"
          >
            <Link>
              <Text w="full" textAlign="left" p={2} fontWeight="semibold">
                Getting Started
              </Text>
            </Link>

            <Link to="/help/createbinanceapikey">
              <Text
                w="full"
                textAlign="left"
                p={2}
                pl={6}
                color={props.helpContent === 'createbinanceapikey' ? 'blue.600' : ''}
              >
                Create Binance Api Key
              </Text>
            </Link>

            <Link to="/help/howtointegrate">
              <Text
                w="full"
                textAlign="left"
                p={2}
                pl={6}
                color={props.helpContent === 'howtointegrate' ? 'blue.600' : ''}
              >
                How To Integrate?
              </Text>
            </Link>

            <Link>
              <Text w="full" textAlign="left" p={2} fontWeight="semibold">
                Trading Bots
              </Text>
            </Link>

            <Link to="/help/whatisdca">
              <Text
                w="full"
                textAlign="left"
                p={2}
                pl={6}
                color={props.helpContent === 'whatisdca' ? 'blue.600' : ''}
              >
                What is DCA?
              </Text>
            </Link>

            <Link to="/help/createbots">
              <Text
                w="full"
                textAlign="left"
                p={2}
                pl={6}
                color={props.helpContent === 'createbots' ? 'blue.600' : ''}
              >
                Create Trading Bot
              </Text>
            </Link>

            <Link to="/help/trainingvideos">
              <Text
                w="full"
                textAlign="left"
                p={2}
                fontWeight="semibold"
                color={props.helpContent === 'trainingvideos' ? 'blue.600' : ''}
              >
                Training Videos
              </Text>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Help;
