import { WarningTwoIcon } from '@chakra-ui/icons';
import { RepeatIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  chakra,
  Collapse,
  Divider,
  Flex,
  Heading,
  Link,
  Switch,
  Text,
  useColorMode,
  useDisclosure
} from '@chakra-ui/react';
import { id } from 'chartjs-plugin-annotation';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Socket } from 'socket.io-client';

import rules from '../../constants/rules.json';
import { addBot, getDescriptionTexts } from '../../store/actions/bot';
import { epicFlowGradientColors } from '../../theme';
import { convertToInternationalCurrencySystem } from '../../utilities/currencyConverter';
import { calculateBaseCurrencyIndex, calculateExchangeID, setBaseCurrency } from '../../utilities/exchangeHelpers';
import { handleRestriction } from '../../utilities/rules';
import {
  dispatchSetPairs,
  getPairsDetails,
  setExchanges,
  setTargets
} from '../../utilities/wizardMenuItemsHelper';
import UpgradeSub from '../modals/upgradeSub';
import EFButton from '../UI/Button/efButton';
import DropDown from '../UI/Dropdown/dropdown';
import AdvancedInput from '../UI/Input/advancedInput';
import AdvancedSlider from '../UI/Slider/advancedSlider';
import PriceSlider from '../UI/Slider/priceSlider';
import AutoInput from './autoInput';
import { assetMenuItems, automationTypeMenuItems, availableExchanges, availablePairMenuItems } from './menuItems';

const AutomationWizard = props => {
  const [transactionStatus, setTransactionStatus] = useState(false);

  const [botStyle, setBotStyle] = useState('Conservative');

  const [botName, setBotName] = useState('');

  const [investmentAmount, setInvestmentAmount] = useState('');

  const [targetCurrentPrice, setTargetCurrentPrice] = useState();

  const [placeholder, setPlaceholder] = useState('');

  const [targetFreeAmount, setTargetFreeAmount] = useState();

  const [pairsInfo, setPairsInfo] = useState();

  const [adjustableTargetFreeAmount, setAdjustableTargetFreeAmount] = useState();

  const { colorMode, toggleColorMode } = useColorMode();

  const [amountErrorMessage, setAmountErrorMessage] = useState(false);

  const [minimumAmountError, setMinimumAmountError] = useState(false);

  const [maxAmountError, setMaxAmountError] = useState(false);

  const [nameErrorMessage, setNameErrorMessage] = useState(false);

  const [mode, setMode] = useState('creation');

  const [priceHasBeenChangedError, setPriceHasBeenChangedError] = useState();

  const [windowHeight, setWindowHeight] = useState();

  const [showSettings, setShowSettings] = useState(false);

  const authState = useSelector(state => state.auth);
  const botState = useSelector(state => state.bot);
  const socketState = useSelector(state => state.socket);
  const userState = useSelector(state => state.user);
  const profileState = useSelector(state => state.profile);

  const [exchange, setExchange] = useState(localStorage.getItem('exchange_default'));

  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  const [target, setTarget] = useState(
    setTargets(localStorage.getItem('exchange_default'), userState.assetData)[0]
  );

  // const [pair, setPair] = useState(setPairs(exchange, userState.assetData, target)[0]);

  const [pair, setPair] = useState();

  const [pairs, setPairs] = useState();

  // useEffect(() => {
  //   console.log('TARGET ====> ', target);
  // }, [target]);

  const advancedSettings = [
    {
      title: 'Min Profit Margin',
      default: 1,
      min: 0.5,
      max: 20,
      step: 0.5,
      agg: 2,
      mod: 1.5,
      con: 1
    },
    {
      title: 'Doubledown Multiplier',
      default: 1,
      min: 1,
      max: 2,
      step: 0.1,
      agg: 1,
      mod: 1,
      con: 1
    },
    {
      title: 'Stop Loss Buffer',
      default: 0.4,
      min: 0.1,
      max: 0.5,
      step: 0.1,
      agg: 0.2,
      mod: 0.3,
      con: 0.4
    },
    {
      title: 'Stop Loss Granularity',
      default: 0.03,
      min: 0.01,
      max: 0.05,
      step: 0.01,
      agg: 0.02,
      mod: 0.03,
      con: 0.03
    },
    {
      title: 'Stop Loss Price Sen',
      default: 0.2,
      min: 0.1,
      max: 0.5,
      step: 0.1,
      agg: 0.2,
      mod: 0.2,
      con: 0.2
    },
    {
      title: 'Extra Buy Up Limit',
      default: 1,
      min: 0.5,
      max: 20,
      step: 0.5,
      agg: 0.5,
      mod: 1,
      con: 1
    },
    {
      title: 'Order Cancel Timer',
      default: 60,
      min: 30,
      max: 600,
      step: 30,
      agg: 60,
      mod: 60,
      con: 60
    },
    {
      title: 'Extra Buy Timer',
      default: 600,
      min: 60,
      max: 3600,
      step: 120,
      agg: 240,
      mod: 480,
      con: 600
    },
    {
      title: 'Boost Timer Constant',
      default: 3600,
      min: 3600,
      max: 172800,
      step: 3600,
      agg: 3600,
      mod: 3600,
      con: 3600
    },
    {
      title: 'First Boost Order',
      default: 10,
      min: 1,
      max: 50,
      step: 1,
      agg: 10,
      mod: 10,
      con: 10
    }
  ];

  const tfTypes = ['Conservative', 'Moderative', 'Aggresive'];

  const [currentTf, setCurrentTf] = useState('Conservative');

  const [mpm, setMpm] = useState();
  const [ddm, setDdm] = useState();
  const [slb, setSlb] = useState();
  const [slg, setSlg] = useState();
  const [ebt, setEbt] = useState();
  const [fbo, setFbo] = useState();
  const [ora, setOra] = useState();
  const [btc, setBtc] = useState();
  const [sps, setSps] = useState();
  const [oct, setOct] = useState();
  const [ebu, setEbu] = useState();
  const [settingsReducer, setSettingsReducer] = useState();

  const dispatch = useDispatch();

  const { isOpen: isUpgradeSubOpen, onOpen: onUpgradeSubOpen, onClose: onUpgradeSubClose } = useDisclosure();

  const handleUpgradeSubModalClose = () => {
    onUpgradeSubClose();
  };

  const handleTfTypes = selected => {
    setCurrentTf(selected);
  };

  const resetAdvancedSettings = async () => {
    let data = await getPairsDetails(authState.currentOption);
    let orderAmount = data[pair].min_slot_price;
    let initialReducer = [...advancedSettings];
    initialReducer.push({
      title: 'Order Amount',
      default: Number(orderAmount),
      min: Number(orderAmount),
      max: Number(orderAmount) * 10,
      step: Number(orderAmount),
      agg: Number(orderAmount),
      mod: Number(orderAmount),
      con: Number(orderAmount)
    });
    setSettingsReducer([...initialReducer]);
    setCurrentTf('Conservative');
    setSettingsReducer(initialReducer);
    setAdvInitialValues(botStyle);
    setOra(Number(orderAmount));
  };

  const validate = evt => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  useEffect(async () => {
    if (pair) {
      let data = await getPairsDetails(authState.currentOption);
      let orderAmount = data[pair].min_slot_price;
      let initialReducer = [...advancedSettings];
      initialReducer.push({
        title: 'Order Amount',
        default: Number(orderAmount),
        min: Number(orderAmount),
        max: Number(orderAmount) * 10,
        step: Number(orderAmount),
        agg: Number(orderAmount),
        mod: Number(orderAmount),
        con: Number(orderAmount)
      });
      setSettingsReducer([...initialReducer]);
      setOra(Number(orderAmount));
      setCurrentTf('Conservative');
    }
  }, [pair]);

  // useEffect(() => {
  //   console.log('setting reducer ', settingsReducer);
  // }, [settingsReducer]);

  // const handleAdvancedSetting = (e, field) => {
  //   if (field === 'mpm') {
  //     setMpm(e.target.value);
  //   } else if (field === 'ddm') {
  //     setDdm(e.target.value);
  //   } else if (field === 'slb') {
  //     setSlb(e.target.value);
  //   } else if (field === 'slg') {
  //     setSlg(e.target.value);
  //   } else if (field === 'ebt') {
  //     setEbt(e.target.value);
  //   } else if (field === 'fpg') {
  //     setFpg(e.target.value);
  //   } else if (field === 'btc') {
  //     setBtc(e.target.value);
  //   } else if (field === 'spr') {
  //     setSpr(e.target.value);
  //   } else if (field === 'oct') {
  //     setOct(e.target.value);
  //   } else if (field === 'ebu') {
  //     setEbu(e.target.value);
  //   }
  // };

  useEffect(() => {
    dispatch(getDescriptionTexts());
  }, []);

  const calculateExchangeKey = useCallback(
    option => {
      // console.log('option ', option, userState.assetLoading);
      if (!userState.assetLoading) {
        let combinedKey;
        let binanceusKey;
        let binancecomKey;
        let coinbaseproKey;
        for (let i = 0; i < userState.assetData.length; i++) {
          if (userState.assetData[i]['combined']) {
            combinedKey = i;
          } else if (userState.assetData[i]['binanceus']) {
            binanceusKey = i;
          } else if (userState.assetData[i]['binancecom']) {
            binancecomKey = i;
          }
          if (userState.assetData[i]['coinbasepro']) {
            coinbaseproKey = i;
          }
        }
        // investigate below console log
        // console.log('option ', option);
        if (option === 'Binance US') {
          return {
            exc: 'binanceus',
            key: binanceusKey
          };
        } else if (option === 'Coinbase') {
          return {
            exc: 'coinbasepro',
            key: coinbaseproKey
          };
        } else if (option === 'Binance') {
          return {
            exc: 'binancecom',
            key: binancecomKey
          };
        } else if (option === 'Total') {
          return {
            exc: 'combined',
            key: combinedKey
          };
        }
      }
    },
    [exchange, userState.assetData]
  );

  useEffect(() => {
    // console.log(userState.assetData, pairsInfo, userState.assetLoading);
    if (userState.assetData?.length > 0 && authState.currentOption && pairsInfo && !userState.assetLoading) {
      // console.log('ifff ', userState.assetData, pairsInfo, userState.assetLoading);
      let quote = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].quote;
      if (target === quote) {
        setTargetFreeAmount(
          calculateBaseCurrencyIndex(
            authState.currentOption,
            userState.assetData,
            calculateExchangeKey(authState.currentOption).key,
            calculateExchangeKey(authState.currentOption).exc,
            target.toLowerCase()
          ).free
        );
      } else {
        let currentPrice = calculateBaseCurrencyIndex(
          authState.currentOption,
          userState.assetData,
          calculateExchangeKey(authState.currentOption).key,
          calculateExchangeKey(authState.currentOption).exc,
          target.toLowerCase()
        ).current_price;
        let free = calculateBaseCurrencyIndex(
          authState.currentOption,
          userState.assetData,
          calculateExchangeKey(authState.currentOption).key,
          calculateExchangeKey(authState.currentOption).exc,
          target.toLowerCase()
        ).free;
        setTargetFreeAmount(free * currentPrice);
      }
    }
  }, [userState.assetData, pairsInfo, userState.assetLoading]);

  const handleChange = useCallback(
    event => {
      if (!userState.assetLoading) {
        const currentPrice = calculateBaseCurrencyIndex(
          exchange,
          userState.assetData,
          calculateExchangeKey(exchange).key,
          calculateExchangeKey(exchange).exc,
          target.toLowerCase()
        ).current_price;
        if (event.target.value === '') {
          setInvestmentAmount('');
          setAdjustableTargetFreeAmount(
            calculateBaseCurrencyIndex(
              exchange,
              userState.assetData,
              calculateExchangeKey(exchange).key,
              calculateExchangeKey(exchange).exc,
              target.toLowerCase()
            ).free * currentPrice
          );
        }
        // else if (target === 'USD') {
        //   let value = event.target.value.replace('$', '');
        //   // let checkValue = parseFloat(value)
        //   // console.log(checkValue)
        //   let controlledInput = handleInput(value);
        //   if (controlledInput === '') {
        //     setInvestmentAmount('');
        //   } else {
        //     controlledInput =
        //       controlledInput.indexOf('.') >= 0
        //         ? controlledInput.substr(0, controlledInput.indexOf('.')) +
        //           controlledInput.substr(controlledInput.indexOf('.'), 3)
        //         : controlledInput;
        //     controlledInput = '$' + controlledInput;
        //     setInvestmentAmount(controlledInput);
        //   }
        // } else {
        else {
          let quote = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].quote;
          let pairPrice = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].price;
          let balance = calculateBaseCurrencyIndex(
            exchange,
            userState.assetData,
            calculateExchangeKey(exchange).key,
            calculateExchangeKey(exchange).exc,
            target.toLowerCase()
          ).balance;
          let free = calculateBaseCurrencyIndex(
            exchange,
            userState.assetData,
            calculateExchangeKey(exchange).key,
            calculateExchangeKey(exchange).exc,
            target.toLowerCase()
          ).free;
          let max = free * pairPrice;
          if (target === quote) {
            setAdjustableTargetFreeAmount(Number(event.target.value) * currentPrice);
          } else {
            setAdjustableTargetFreeAmount((Number(event.target.value) * free * currentPrice) / max);
          }
          setInvestmentAmount(event.target.value);
        }
      }
      // }
    },
    [userState.assetData, exchange, target, pair, pairsInfo, userState.assetLoading]
  );

  const handleChangeUSDT = useCallback(
    event => {
      if (!userState.assetLoading) {
        const currentPrice = calculateBaseCurrencyIndex(
          authState.currentOption,
          userState.assetData,
          calculateExchangeKey(authState.currentOption).key,
          calculateExchangeKey(authState.currentOption).exc,
          target.toLowerCase()
        ).current_price;
        if (event.target.value === '') {
          setInvestmentAmount('');
          setAdjustableTargetFreeAmount(
            calculateBaseCurrencyIndex(
              authState.currentOption,
              userState.assetData,
              calculateExchangeKey(authState.currentOption).key,
              calculateExchangeKey(authState.currentOption).exc,
              target.toLowerCase()
            ).free * currentPrice
          );
        } else {
          let quote = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].quote;
          let pairPrice = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].price;
          let balance = calculateBaseCurrencyIndex(
            authState.currentOption,
            userState.assetData,
            calculateExchangeKey(authState.currentOption).key,
            calculateExchangeKey(authState.currentOption).exc,
            target.toLowerCase()
          ).balance;
          let free = calculateBaseCurrencyIndex(
            authState.currentOption,
            userState.assetData,
            calculateExchangeKey(authState.currentOption).key,
            calculateExchangeKey(authState.currentOption).exc,
            target.toLowerCase()
          ).free;
          let max = free * pairPrice;
          if (target === quote) {
            setAdjustableTargetFreeAmount(Number(event.target.value) * currentPrice);
          } else {
            setAdjustableTargetFreeAmount((Number(event.target.value) * free * currentPrice) / max);
          }
          setInvestmentAmount(event.target.value);
        }
      }
    },
    [userState.assetData, authState.currentOption, target, pair, pairsInfo, userState.assetLoading]
  );

  const createPlaceHolder = useCallback(
    (exchange, tg) => {
      // console.log(
      //   'calling input',
      //   Object.values(
      //     userState.assetData[calculateExchangeKey(exchange).key][calculateExchangeKey(exchange).exc]
      //   ).find(e => e.symbol === target.toLowerCase()).free
      // );
      if (target) {
        const currentPrice = calculateBaseCurrencyIndex(
          exchange,
          userState.assetData,
          calculateExchangeKey(exchange).key,
          calculateExchangeKey(exchange).exc,
          target.toLowerCase()
        ).current_price;
        let free = calculateBaseCurrencyIndex(
          exchange,
          userState.assetData,
          calculateExchangeKey(exchange).key,
          calculateExchangeKey(exchange).exc,
          tg.toLowerCase()
        ).free;
        if (pair && pairsInfo.find(e => Object.keys(e)[0] === pair) !== undefined) {
          let quote = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].quote;
          let pairPrice = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].price;
          let balance = calculateBaseCurrencyIndex(
            exchange,
            userState.assetData,
            calculateExchangeKey(exchange).key,
            calculateExchangeKey(exchange).exc,
            tg.toLowerCase()
          ).total;
          //Max should be free * pair price, perhaps?
          let max = (balance * pairPrice).toFixed(6);
          if (target === quote) {
            setPlaceholder(
              `${convertToInternationalCurrencySystem(
                calculateBaseCurrencyIndex(
                  exchange,
                  userState.assetData,
                  calculateExchangeKey(exchange).key,
                  calculateExchangeKey(exchange).exc,
                  tg.toLowerCase()
                ).free
              )} ${quote}`
            );
            setTargetFreeAmount(free);
          } else {
            setPlaceholder(`${convertToInternationalCurrencySystem(max)} ${quote}`);
            setTargetFreeAmount(free * currentPrice);
          }
        }
      }
      // }
    },
    [
      pair,
      pairsInfo,
      authState.currentOption,
      // Object.values(
      //   userState.assetData[calculateExchangeKey(exchange).key][calculateExchangeKey(exchange).exc]
      // ).find(e => e.symbol === target.toLowerCase()).free,
      // calculateBaseCurrencyIndex(
      //   exchange,
      //   userState.assetData,
      //   calculateExchangeKey(exchange).key,
      //   calculateExchangeKey(exchange).exc,
      //   target.toLowerCase()
      // ).current_price,
      targetFreeAmount
    ]
  );

  // const createPlaceHolder = (exchange, tg) => {
  //   const currentPrice = calculateBaseCurrencyIndex(
  //     exchange,
  //     userState.assetData,
  //     calculateExchangeKey(exchange).key,
  //     calculateExchangeKey(exchange).exc,
  //     target.toLowerCase()
  //   ).current_price;
  //   setTargetFreeAmount(
  //     calculateBaseCurrencyIndex(
  //       exchange,
  //       userState.assetData,
  //       calculateExchangeKey(exchange).key,
  //       calculateExchangeKey(exchange).exc,
  //       tg.toLowerCase()
  //     ).free
  //   );
  //   if (exchange === 'Binance US' || exchange === 'Coinbase') {
  //     setInvestmentAmount('');
  //     setPlaceholder(`Max. ${setBaseCurrency(exchange)}${(targetFreeAmount * currentPrice).toFixed(2)}`);
  //   } else {
  //     setInvestmentAmount('');
  //     setPlaceholder(
  //       `Max.  ${setBaseCurrency(exchange)} ${convertToInternationalCurrencySystem(
  //         (targetFreeAmount * currentPrice).toFixed(2)
  //       )}`
  //     );
  //   }
  // };

  useEffect(() => {
    if (!userState.assetLoading && socketState.isSocketOn && target) {
      const currentPrice = calculateBaseCurrencyIndex(
        authState.currentOption,
        userState.assetData,
        calculateExchangeKey(authState.currentOption).key,
        calculateExchangeKey(authState.currentOption).exc,
        target.toLowerCase()
      ).current_price;
      const free = calculateBaseCurrencyIndex(
        authState.currentOption,
        userState.assetData,
        calculateExchangeKey(authState.currentOption).key,
        calculateExchangeKey(authState.currentOption).exc,
        target.toLowerCase()
      ).free;
      createPlaceHolder(authState.currentOption, target);
      if (investmentAmount === '') {
        setAdjustableTargetFreeAmount(free * currentPrice);
      } else {
        if (target !== 'USD') {
          let quote = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].quote;
          let pairPrice = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].price;
          let max = free * pairPrice;
          if (target !== quote) {
            setAdjustableTargetFreeAmount((Number(investmentAmount) * free * currentPrice) / max);
          } else {
            setAdjustableTargetFreeAmount(Number(investmentAmount) * currentPrice);
          }
        }
      }
    }
  }, [
    targetFreeAmount,
    userState.assetLoading,
    target
    // Object.values(
    //   userState.assetData[calculateExchangeKey(exchange).key][calculateExchangeKey(exchange).exc]
    // ).find(e => e.symbol === target.toLowerCase()).free,
    // calculateBaseCurrencyIndex(
    //   exchange,
    //   userState.assetData,
    //   calculateExchangeKey(exchange).key,
    //   calculateExchangeKey(exchange).exc,
    //   target.toLowerCase()
    // ).current_price
  ]);

  const setAdvancedSliderValue = (val, field) => {
    if (field === 'Min Profit Margin') {
      setMpm(val);
    } else if (field === 'Doubledown Multiplier') {
      setDdm(val);
    } else if (field === 'Stop Loss Buffer') {
      setSlb(val);
    } else if (field === 'Stop Loss Granularity') {
      setSlg(val);
    } else if (field === 'Stop Loss Price Sen') {
      setSps(val);
    } else if (field === 'Extra Buy Up Limit') {
      setEbu(val);
    } else if (field === 'Order Cancel Timer') {
      setOct(val);
    } else if (field === 'Extra Buy Timer') {
      setEbt(val);
    } else if (field === 'Boost Timer Constant') {
      setBtc(val);
    } else if (field === 'Order Amount') {
      setOra(val);
    } else if (field === 'First Boost Order') {
      setFbo(val);
    }
  };

  const setAdvInitialValues = botType => {
    if (botType === 'Conservative') {
      for (let item of advancedSettings) {
        if (item.title === 'Min Profit Margin') {
          setMpm(item.con);
        } else if (item.title === 'Doubledown Multiplier') {
          setDdm(item.con);
        } else if (item.title === 'Stop Loss Buffer') {
          setSlb(item.con);
        } else if (item.title === 'Stop Loss Granularity') {
          setSlg(item.con);
        } else if (item.title === 'Stop Loss Price Sen') {
          setSps(item.con);
        } else if (item.title === 'Extra Buy Up Limit') {
          setEbu(item.con);
        } else if (item.title === 'Order Cancel Timer') {
          setOct(item.con);
        } else if (item.title === 'Extra Buy Timer') {
          setEbt(item.con);
        } else if (item.title === 'Boost Timer Constant') {
          setBtc(item.con);
        } else if (item.title === 'First Boost Order') {
          setFbo(item.con);
        }
      }
    } else if (botType === 'Moderate') {
      for (let item of advancedSettings) {
        if (item.title === 'Min Profit Margin') {
          setMpm(item.mod);
        } else if (item.title === 'Doubledown Multiplier') {
          setDdm(item.mod);
        } else if (item.title === 'Stop Loss Buffer') {
          setSlb(item.mod);
        } else if (item.title === 'Stop Loss Granularity') {
          setSlg(item.mod);
        } else if (item.title === 'Stop Loss Price Sen') {
          setSps(item.mod);
        } else if (item.title === 'Extra Buy Up Limit') {
          setEbu(item.mod);
        } else if (item.title === 'Order Cancel Timer') {
          setOct(item.mod);
        } else if (item.title === 'Extra Buy Timer') {
          setEbt(item.mod);
        } else if (item.title === 'Boost Timer Constant') {
          setBtc(item.mod);
        } else if (item.title === 'First Boost Order') {
          setFbo(item.mod);
        }
      }
    } else if (botType === 'Aggresive') {
      for (let item of advancedSettings) {
        if (item.title === 'Min Profit Margin') {
          setMpm(item.agg);
        } else if (item.title === 'Doubledown Multiplier') {
          setDdm(item.agg);
        } else if (item.title === 'Stop Loss Buffer') {
          setSlb(item.agg);
        } else if (item.title === 'Stop Loss Granularity') {
          setSlg(item.agg);
        } else if (item.title === 'Stop Loss Price Sen') {
          setSps(item.agg);
        } else if (item.title === 'Extra Buy Up Limit') {
          setEbu(item.agg);
        } else if (item.title === 'Order Cancel Timer') {
          setOct(item.agg);
        } else if (item.title === 'Extra Buy Timer') {
          setEbt(item.agg);
        } else if (item.title === 'Boost Timer Constant') {
          setBtc(item.agg);
        } else if (item.title === 'First Boost Order') {
          setFbo(item.agg);
        }
      }
    }
  };

  useEffect(() => {
    setAdvInitialValues(botStyle);
  }, [botStyle]);

  const setSliderValue = useCallback(
    val => {
      const currentPrice = calculateBaseCurrencyIndex(
        authState.currentOption,
        userState.assetData,
        calculateExchangeKey(authState.currentOption).key,
        calculateExchangeKey(authState.currentOption).exc,
        target.toLowerCase()
      ).current_price;
      let amount;
      if (target === 'USD') {
        amount = ((targetFreeAmount * currentPrice * val) / 100).toFixed(2);
      } else {
        let quote = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].quote;
        if (target !== quote) {
          let pairPrice = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].price;
          let free = calculateBaseCurrencyIndex(
            authState.currentOption,
            userState.assetData,
            calculateExchangeKey(authState.currentOption).key,
            calculateExchangeKey(authState.currentOption).exc,
            target.toLowerCase()
          ).free;
          let max = (free * pairPrice).toFixed(6);
          amount = `${((max * val) / 100).toFixed(6)}`;
        } else {
          amount = ((targetFreeAmount * currentPrice * val) / 100 / currentPrice).toFixed(6);
        }
      }
      if (amount === '0.00' || amount === '0.000000') {
        setInvestmentAmount('');
        let quote = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].quote;
        if (target === quote) {
          setAdjustableTargetFreeAmount(targetFreeAmount * currentPrice);
        } else {
          setAdjustableTargetFreeAmount(targetFreeAmount);
        }
      } else if (authState.currentOption === 'Binance') {
        if (target === 'USDT') {
          setInvestmentAmount(amount);
        } else {
          let quote = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].quote;
          if (target === quote) {
            setInvestmentAmount(amount);
            setAdjustableTargetFreeAmount(Number(amount) * currentPrice);
          } else {
            let pairPrice = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].price;
            let free = calculateBaseCurrencyIndex(
              authState.currentOption,
              userState.assetData,
              calculateExchangeKey(authState.currentOption).key,
              calculateExchangeKey(authState.currentOption).exc,
              target.toLowerCase()
            ).free;
            let max = free * pairPrice;
            setInvestmentAmount(amount);
            setAdjustableTargetFreeAmount((Number(amount) * free * currentPrice) / max);
          }
        }
      } else {
        if (target === 'USD') {
          setInvestmentAmount(amount);
        } else {
          let quote = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].quote;
          if (target === quote) {
            setInvestmentAmount(amount);
            setAdjustableTargetFreeAmount(Number(amount) * currentPrice);
          } else {
            let pairPrice = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].price;
            let free = calculateBaseCurrencyIndex(
              authState.currentOption,
              userState.assetData,
              calculateExchangeKey(authState.currentOption).key,
              calculateExchangeKey(authState.currentOption).exc,
              target.toLowerCase()
            ).free;
            let max = free * pairPrice;
            setInvestmentAmount(amount);
            setAdjustableTargetFreeAmount((Number(amount) * free * currentPrice) / max);
          }
        }
      }
    },
    [
      targetFreeAmount,
      pair,
      pairsInfo
      // calculateBaseCurrencyIndex(
      //   exchange,
      //   userState.assetData,
      //   calculateExchangeKey(exchange).key,
      //   calculateExchangeKey(exchange).exc,
      //   target.toLowerCase()
      // ).current_price
    ]
  );

  // const setSliderValue = val => {
  //   console.log(exchange, target);
  //   const currentPrice = calculateBaseCurrencyIndex(
  //     exchange,
  //     userState.assetData,
  //     calculateExchangeKey(exchange).key,
  //     calculateExchangeKey(exchange).exc,
  //     target.toLowerCase()
  //   ).current_price;
  //   const amount = ((targetFreeAmount * currentPrice * val) / 100).toFixed(2);
  //   if (amount === '0.00') {
  //     setInvestmentAmount('');
  //   } else if (exchange === 'Binance') {
  //     setInvestmentAmount(`USDT ${amount}`);
  //   } else {
  //     setInvestmentAmount(`$${amount}`);
  //   }
  // };

  useEffect(() => {
    setPair(pairs ? pairs[0].pair : '');
  }, [pairs]);

  const handleDropDownTarget = selected => {
    const currentPrice = calculateBaseCurrencyIndex(
      authState.currentOption,
      userState.assetData,
      calculateExchangeKey(authState.currentOption).key,
      calculateExchangeKey(authState.currentOption).exc,
      target.toLowerCase()
    ).current_price;
    // createPlaceHolder(exchange, selected);
    setTarget(selected);
    setInvestmentAmount('');
    setAdjustableTargetFreeAmount(
      calculateBaseCurrencyIndex(
        authState.currentOption,
        userState.assetData,
        calculateExchangeKey(authState.currentOption).key,
        calculateExchangeKey(authState.currentOption).exc,
        target.toLowerCase()
      ).free * currentPrice
    );
    // setPair(pairs[0].pair);
    setMaxAmountError(false);
  };

  const handleDropDownExchange = selected => {
    // createPlaceHolder(selected, target);
    // setExchange(selected);
    // setTargets(selected, userState.assetData)[0];
    // setMaxAmountError(false);
  };
  const handleDropDownPair = selected => {
    setPair(selected);
    setMaxAmountError(false);
  };

  const handleDropDownBotStyle = selected => {
    setBotStyle(selected);
  };

  const handleNameChange = event => {
    setBotName(event.target.value);
  };

  const handlePreviewBot = () => {
    // if (handleWizardRestrictions(authState.stripeCurrentPlan)) {
    //   return onUpgradeSubOpen();
    // }

    let quote = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].quote;
    let pairPrice = pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].price;
    let currentPrice = calculateBaseCurrencyIndex(
      authState.currentOption,
      userState.assetData,
      calculateExchangeKey(authState.currentOption).key,
      calculateExchangeKey(authState.currentOption).exc,
      target.toLowerCase()
    ).current_price;
    let free = calculateBaseCurrencyIndex(
      authState.currentOption,
      userState.assetData,
      calculateExchangeKey(authState.currentOption).key,
      calculateExchangeKey(authState.currentOption).exc,
      target.toLowerCase()
    ).free;
    let max;
    if (target === quote) {
      max = free;
    } else {
      max = free * pairPrice;
    }
    if (authState.currentOption === 'Binance US' || authState.currentOption === 'Coinbase') {
      if (investmentAmount === '' && botName === '') {
        setMaxAmountError(false);
        setAmountErrorMessage(true);
        setNameErrorMessage(true);
        return;
      } else if (investmentAmount === '') {
        setMaxAmountError(false);
        setMinimumAmountError(false);
        setNameErrorMessage(false);
        return setAmountErrorMessage(true);
      } else if (botName === '') {
        setMaxAmountError(false);
        setMinimumAmountError(false);
        setAmountErrorMessage(false);
        return setNameErrorMessage(true);
      } else if (target === 'USD' && Number(investmentAmount) < 20) {
        setMinimumAmountError(true);
        return setMaxAmountError(false);
      } else if (target === 'USD' && Number(investmentAmount) > targetFreeAmount) {
        setMinimumAmountError(false);
        return setMaxAmountError(true);
      } else if (Number(adjustableTargetFreeAmount) < 20) {
        setMinimumAmountError(true);
        return setMaxAmountError(false);
      } else if (Number(investmentAmount) > max) {
        setMinimumAmountError(false);
        return setMaxAmountError(true);
      }
      // else if (Number(investmentAmount.slice(1)) < 10) {
      //   return setMinimumAmountError(true);
      // } else if (
      //   Number(investmentAmount.slice(1)) >
      //     calculateBaseCurrencyIndex(
      //       exchange,
      //       userState.assetData,
      //       calculateExchangeKey(exchange).key,
      //       calculateExchangeKey(exchange).exc,
      //       target.toLowerCase()
      //     ).current_price *
      //       targetFreeAmount &&
      //   target === 'USD'
      // ) {
      //   setMinimumAmountError(false);
      //   return setMaxAmountError(true);
      // }
    } else if (authState.currentOption === 'Binance') {
      if (investmentAmount === '' && botName === '') {
        setMaxAmountError(false);
        setAmountErrorMessage(true);
        setNameErrorMessage(true);
        return;
      } else if (investmentAmount === '') {
        setMaxAmountError(false);
        setMinimumAmountError(false);
        setNameErrorMessage(false);
        return setAmountErrorMessage(true);
      } else if (botName === '') {
        setMaxAmountError(false);
        setMinimumAmountError(false);
        setAmountErrorMessage(false);
        return setNameErrorMessage(true);
      } else if (Number(adjustableTargetFreeAmount) < 20) {
        setMaxAmountError(false);
        return setMinimumAmountError(true);
      } else if (Number(investmentAmount) > Number(max)) {
        setMinimumAmountError(false);
        return setMaxAmountError(true);
      }
    }
    setNameErrorMessage(false);
    setAmountErrorMessage(false);
    setMinimumAmountError(false);

    let inv;
    if (target === quote) {
      inv = Number(investmentAmount) * currentPrice;
    } else {
      inv = (Number(investmentAmount) * free * currentPrice) / max;
    }
    if (!handleRestriction(calculateExchangeID(authState.currentOption), authState.stripeCurrentPlan, inv)) {
      return setMode('preview');
    } else {
      return onUpgradeSubOpen();
    }
  };

  const handleEditButton = () => {
    setTargets(authState.currentOption, userState.assetData);
    setMode('creation');
  };

  const handleDone = () => {
    setMode('creation');
    setTarget(setTargets(authState.currentOption, userState.assetData)[0]);
    setInvestmentAmount('');
    // setExchange(authState.exchanges[0]);
    setBotName('');
    setPair(pairs[0].pair);
    setBotStyle('Conservative');
  };

  useEffect(() => {
    // console.log('EXCHANGE ====> ', exchange, localStorage.getItem('exchange_default'));
    // setExchange(localStorage.getItem('exchange_default'));
    // console.log(localStorage.getItem('exchange_default'), userState.assetData);
    if (userState.isInitialized) {
      if (setTargets(localStorage.getItem('exchange_default'), userState.assetData)[0]) {
        // console.log('if  ', setTargets(localStorage.getItem('exchange_default'), userState.assetData)[0]);
        setTarget(setTargets(localStorage.getItem('exchange_default'), userState.assetData)[0]);
      }
    }
  }, [
    localStorage.getItem('exchange_default'),
    userState.isInitialized
    // setTargets(localStorage.getItem('exchange_default'), userState.assetData)[0]
  ]);

  const renderAmountError = () => {
    if (minimumAmountError) {
      return (
        <Text fontSize="0.8125rem">
          {exchange !== 'Binance' ? 'Minimum amount is $20.' : 'Minimum amount is 20 USDT.'}
        </Text>
      );
    } else if (maxAmountError) {
      return <Text fontSize="0.8125rem">Not enough balance.</Text>;
    } else {
      return <Text fontSize="0.8125rem">Please enter required fields</Text>;
    }
  };

  const handleToggler = e => {
    if (e.target.checked) {
      setIsSwitchChecked(true);
    } else {
      setIsSwitchChecked(false);
    }
  };

  useEffect(async () => {
    if (target) {
      let exchangeID;
      if (authState.currentOption) {
        if (authState.currentOption === 'Binance US') {
          exchangeID = 1;
        } else if (authState.currentOption === 'Binance') {
          exchangeID = 3;
        } else if (authState.currentOption === 'Coinbase') {
          exchangeID = 2;
        }
        let pairs = await dispatchSetPairs(
          exchangeID,
          authState.id,
          userState.assetData,
          target,
          authState.currentOption
        );
        setPairs(pairs.arr);
        setPairsInfo(pairs.pairsInfo);
        setPair(pairs.arr[0].pair);
      }
      // createPlaceHolder(exchange, target);
      // setPairs(
      //   await dispatchSetPairs(exchangeID, authState.id, userState.assetData, target, exchange).arr
      //   // await dispatchSetPairs('0b00fa3e-fb0b-4424-bf93-ed63b0cdf82a', userState.assetData, target, exchange)
      // );
    }
  }, [target, authState.currentOption]);

  useEffect(() => {
    if (pair !== undefined && pairsInfo) {
      createPlaceHolder(authState.currentOption, target);
    }
  }, [pair, pairsInfo]);

  const handleCreateBot = async () => {
    let exchangeID;
    if (authState.currentOption === 'Binance US') {
      exchangeID = 1;
    } else if (authState.currentOption === 'Binance') {
      exchangeID = 3;
    } else if (authState.currentOption === 'Coinbase') {
      exchangeID = 2;
    }
    // This won't work now.
    if (Number(investmentAmount) > Number(targetFreeAmount)) {
      setPriceHasBeenChangedError(true);
      return setMode('pricedown');
    }
    let body = {
      botName: botName,
      status: 'new',
      botStyle: botStyle,
      exchangeID: exchangeID,
      amount: Number(investmentAmount),
      pair: pair,
      target: target,
      userID: authState.id || profileState.id,
      stripeCustomerId: authState.stripeCustomerId
    };
    if (isSwitchChecked) {
      body = {
        ...body,
        status: 'new',
        botStyle: 'customized',
        extra_buy_timer: Number(ebt),
        minimum_profit_margin_percent: Number(mpm) / 100 + 1,
        stop_loss_buffer: slb / 100 + 1,
        stop_loss_granularity: slg / 100 + 1,
        extrabuy_up_limit_vs_avg_cost: Number(ebu) / 100 + 1,
        doubledown_multiplier: ddm,
        order_cancel_timer: oct,
        first_boost_order: fbo,
        stop_loss_price_sensitivity: Number(sps) / 100 + 1,
        trade_frequency: currentTf.toLowerCase(),
        slot_price: ora,
        boost_timer_constant: btc
      };
    }
    dispatch(addBot(body));
    setMode('done');
  };

  const handleSliderDefaultValues = botType => {
    if (botType === 'Conservative') {
      return 'con';
    } else if (botType === 'Moderate') {
      return 'mod';
    } else if (botType === 'Aggressive') {
      return 'agg';
    }
  };

  const setDescriptions = title => {
    if (title === 'Min Profit Margin') {
      return botState.descriptions.as_min_profit_margin;
    } else if (title === 'Doubledown Multiplier') {
      return botState.descriptions.as_doubledown_multiplier;
    } else if (title === 'Stop Loss Buffer') {
      return botState.descriptions.as_stop_loss_buffer;
    } else if (title === 'Stop Loss Granularity') {
      return botState.descriptions.as_stop_loss_granularity;
    } else if (title === 'Stop Loss Price Sen') {
      return botState.descriptions.as_stop_loss_price_sensitivity;
    } else if (title === 'Extra Buy Up Limit') {
      return botState.descriptions.as_extra_buy_up_limit;
    } else if (title === 'Order Cancel Timer') {
      return botState.descriptions.as_order_cancel_timer;
    } else if (title === 'Extra Buy Timer') {
      return botState.descriptions.as_extra_buy_timer;
    } else if (title === 'Boost Timer Constant') {
      return botState.descriptions.as_boost_timer_constant;
    } else if (title === 'First Boost Order') {
      return botState.descriptions.as_first_boost_order;
    } else if (title === 'Order Amount') {
      return botState.descriptions.as_slot_price;
    }
  };

  const renderSlider = useMemo(() => {
    let type = handleSliderDefaultValues(botStyle);
    return (
      <Flex direction="column">
        {settingsReducer?.map((item, idx) => {
          return (
            <AdvancedSlider
              key={Math.random()}
              infoTitle={item.title}
              mb={8}
              title={item.title}
              description={setDescriptions(item.title)}
              sliderColor={props.sliderColor}
              default={item[type]}
              bg={props.bg}
              min={item.min}
              max={item.max}
              step={item.step}
              getSliderValue={val => setAdvancedSliderValue(val, item.title)}
            />
          );
        })}
      </Flex>
    );
  }, [settingsReducer, botStyle]);

  const renderDynamicContent = () => {
    if (mode === 'creation') {
      return (
        <Flex direction="column" w="85%">
          {/* <DropDown
            menuitems={profileState.exchanges}
            title="Exchange"
            showTitle
            functional
            value={exchange}
            selected={handleDropDownExchange}
            fontSize="0.8125rem"
            mtop={4}
            disabled={authState.exchanges.length <= 1}
            _hover={{
              backgroundColor: colorMode === 'light' ? 'white' : 'gray.900',
              borderColor: colorMode === 'light' ? 'gray.400' : 'gray.800'
            }}
          /> */}
          <DropDown
            menuitems={setTargets(authState.currentOption, userState.assetData)}
            selected={handleDropDownTarget}
            functional
            value={target}
            title="Target Asset"
            showTitle
            fontSize="0.8125rem"
            mtop={4}
            _hover={{
              backgroundColor: colorMode === 'light' ? 'white' : 'gray.900',
              borderColor: colorMode === 'light' ? 'gray.400' : 'gray.800'
            }}
          />
          <DropDown
            menuitems={pairs}
            selected={handleDropDownPair}
            functional
            value={pair}
            searchable
            isPair
            title="Available Pairs"
            showTitle
            fontSize="0.8125rem"
            mtop={4}
            _hover={{
              backgroundColor: colorMode === 'light' ? 'white' : 'gray.900',
              borderColor: colorMode === 'light' ? 'gray.400' : 'gray.800'
            }}
          />
          <AutoInput
            title="Max Investment"
            onKeyPress={validate}
            handleChange={exchange === 'Binance US' || exchange === 'Coinbase' ? handleChange : handleChangeUSDT}
            value={investmentAmount}
            type="number"
            width="9rem"
            bc={amountErrorMessage || minimumAmountError ? 'red' : null}
            placeholder={placeholder}
            textAlign="right"
            amount={`Max ~${adjustableTargetFreeAmount.toFixed(6)}`}
            target={target}
            isAmount
            base={exchange === 'Binance US' || exchange === 'Coinbase' ? 'USD' : 'USDT'}
            mb={5}
            mt={4}
          />
          <PriceSlider
            mb={5}
            sliderColor={props.sliderColor}
            bg={props.bg}
            getSliderValue={val => setSliderValue(val)}
          />
          <DropDown
            menuitems={automationTypeMenuItems}
            selected={handleDropDownBotStyle}
            functional
            value={botStyle}
            title="Bot Type"
            w="9rem"
            fontSize="0.8125rem"
            showTitle
            mtop={4}
            _hover={{
              backgroundColor: colorMode === 'light' ? 'white' : 'gray.900',
              borderColor: colorMode === 'light' ? 'gray.400' : 'gray.800'
            }}
          />

          <Text w="full" textAlign="left" fontSize="0.8125rem" lineHeight="1.2rem" mt={4} mb={4}>
            {botStyle === 'Conservative'
              ? 'Less frequent trades. Lower risk bot.'
              : botStyle === 'Moderate'
              ? ' Moderate trade frequency. Balanced investment approach.'
              : 'Frequent trade activity. High risk potential.'}
            <Link as={RouterLink} color={props.bg} fontWeight="semibold" to="/help">
              <br></br>
              Learn more.
            </Link>
          </Text>
          <Flex w="full" justify="space-between" align="center" my={6}>
            <Text fontSize="0.8125rem">Enable Advanced Settings</Text>
            <Switch
              size="md"
              disabled={authState.stripeCurrentPlan === 'Free' || authState.stripeCurrentPlan === 'Basic'}
              isChecked={isSwitchChecked}
              isFocusable={false}
              colorScheme="whatsapp"
              onChange={e => handleToggler(e)}
            />
          </Flex>

          <Collapse in={isSwitchChecked}>
            {renderSlider}
            <DropDown
              menuitems={tfTypes}
              selected={handleTfTypes}
              functional
              value={currentTf}
              title="Trade Frequency"
              w="9rem"
              fontSize="0.8125rem"
              showTitle
              mtop={4}
              _hover={{
                backgroundColor: colorMode === 'light' ? 'white' : 'gray.900',
                borderColor: colorMode === 'light' ? 'gray.400' : 'gray.800'
              }}
            />
            <Flex w="full" justifyContent="center" my={6}>
              <Button
                leftIcon={<RepeatIcon />}
                bg="transparent"
                _hover={{
                  backgroundColor: '#0062f7',
                  color: 'white'
                }}
                fontSize="sm"
                borderWidth={1}
                variant="solid"
                color={colorMode === 'light' ? 'black' : 'white'}
                w="full"
                onClick={resetAdvancedSettings}
              >
                Reset Advanced Settings
              </Button>
            </Flex>
          </Collapse>

          <AutoInput
            title="Bot Name"
            width="9rem"
            placeholder="Awesome Bot"
            textAlign="left"
            bc={nameErrorMessage ? 'red' : null}
            mb={3}
            value={botName}
            handleChange={handleNameChange}
          />
          {amountErrorMessage || nameErrorMessage || minimumAmountError || maxAmountError
            ? renderAmountError()
            : null}
          <EFButton
            bg={props.bg}
            isLoading={botState.loading}
            // disabled
            w="full"
            mt={2}
            title="Preview"
            onClick={handlePreviewBot}
          />
        </Flex>
      );
    } else if (mode === 'pricedown') {
      return (
        <Flex mt={4} mb={4} w="85%" justify="flex-start" align="center" direction="column">
          <Text fontSize="sm" mb={8}>
            {`The free amount of ${target} has been changed. It is now less than you want to spend. Please edit it.`}
          </Text>
          {!botState.loading ? (
            <Button
              w="full"
              fontSize="sm"
              bg={colorMode === 'light' ? 'white' : 'transparent'}
              // borderColor={epicFlowGradientColors.left}
              borderColor={props.bg}
              borderWidth={1}
              _hover={{
                bg: colorMode === 'light' ? 'white' : 'transparent'
              }}
              _focus={{
                outline: 'none'
              }}
              _active={{
                bg: colorMode === 'light' ? 'white' : 'transparent'
              }}
              // color={epicFlowGradientColors.left}
              color={props.bg}
              mt={4}
              onClick={handleEditButton}
            >
              Edit Bot
            </Button>
          ) : null}
        </Flex>
      );
    } else if (mode === 'preview') {
      return (
        <Flex mt={4} mb={4} w="85%" justify="flex-start" align="center" direction="column">
          <Text fontSize="sm" mb={8}>
            You are creating a bot named <chakra.span fontWeight="medium">{`${botName}`}</chakra.span> using the{' '}
            <chakra.span fontWeight="medium">{`${botStyle}`}</chakra.span> method. Your trading exchange is{' '}
            <chakra.span fontWeight="medium">{`${exchange}`}</chakra.span>. You will be spending{' '}
            <chakra.span fontWeight="medium" color={epicFlowGradientColors.left}>{`${investmentAmount} ${
              pairsInfo.find(e => Object.keys(e)[0] === pair)[pair].quote
            }
            `}</chakra.span>
            . Your goal is to gain <chakra.span fontWeight="medium">{`${target}`}</chakra.span>. Your trading pair
            will be <chakra.span fontWeight="medium">{`${pair}`}</chakra.span>. If everything looks good to you,
            click on create bot button.
          </Text>
          <EFButton
            bg={props.bg}
            w="full"
            isLoading={botState.loading}
            mt={2}
            title="Create Bot"
            onClick={handleCreateBot}
          />
          {!botState.loading ? (
            <Button
              w="full"
              fontSize="sm"
              bg={colorMode === 'light' ? 'white' : 'transparent'}
              // borderColor={epicFlowGradientColors.left}
              borderColor={props.bg}
              borderWidth={1}
              _hover={{
                bg: colorMode === 'light' ? 'white' : 'transparent'
              }}
              _focus={{
                outline: 'none'
              }}
              _active={{
                bg: colorMode === 'light' ? 'white' : 'transparent'
              }}
              // color={epicFlowGradientColors.left}
              color={props.bg}
              mt={4}
              onClick={handleEditButton}
            >
              Edit Bot
            </Button>
          ) : null}
        </Flex>
      );
    } else if (mode === 'done' && typeof botState.addBotStatus === 'string') {
      return (
        <Flex mt={4} mb={4} w="85%" justify="flex-start" align="center" direction="column">
          <Text fontSize="sm" mb={8}>
            {botState.addBotStatus}
          </Text>
          <EFButton
            bg={props.bg}
            w="full"
            isLoading={botState.loading}
            mt={2}
            mb={4}
            title="Done"
            onClick={handleDone}
          />
        </Flex>
      );
    } else {
      return (
        <Flex mt={4} mb={4} w="85%" justify="flex-start" align="center" direction="column">
          <Text fontSize="sm" mb={8}>
            Congrats 🎉 <chakra.span fontWeight="medium">{`${authState.name || profileState.name}`}</chakra.span>.
            Your bot <chakra.span fontWeight="medium">{`${botName}`}</chakra.span> has been created successfully.
            Go to My Bots tabs to see your bots.
          </Text>
          <EFButton
            bg={props.bg}
            w="full"
            isLoading={botState.loading}
            mt={2}
            mb={4}
            title="New Bot"
            onClick={handleDone}
          />
        </Flex>
      );
    }
  };

  useEffect(() => {
    setWindowHeight(window.innerHeight);
  }, [window.innerHeight]);

  return !profileState.loading && pairs && userState.isInitialized ? (
    <React.Fragment>
      <UpgradeSub isopen={isUpgradeSubOpen} onclose={handleUpgradeSubModalClose} />
      <Flex
        display={props.fixed ? ['none', 'none', 'none', 'none', 'flex', 'flex'] : 'flex'}
        justify="flex-end"
        align="flex-start"
        boxShadow="lg"
        borderRadius={16}
        // position="relative"
        borderWidth={1}
        overflowY="scroll"
        ml={props.fixed && '-20rem'}
        // position={props.fixed ? 'fixed' : 'relative'}
        w={props.fixed ? 'xs' : 'sm'}
        //TODO figure height
        // height={props.fixed && windowHeight - 104}
        // borderWidth={3}
        css={{
          '&::-webkit-scrollbar': {
            width: '1px'
          },
          '&::-webkit-scrollbar-track': {
            width: '1px'
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '2px'
          }
        }}
      >
        <Flex
          w={props.fixed ? 'xs' : 'sm'}
          direction="column"
          pb={6}
          // boxShadow={props.fixed ? 'lg' : ['lg', 'lg', 'lg', 'none', 'none', 'none', 'none']}
          align="center"
          // overflowY="scroll"
          borderRadius="md"
        >
          <Heading size="sm" w="85%" textAlign="left" mt={5} mb={5} fontWeight="semibold">
            Automation Wizard
          </Heading>
          {/* <Divider /> */}
          {renderDynamicContent()}
        </Flex>
      </Flex>
    </React.Fragment>
  ) : null;
};

AutomationWizard.propTypes = {};

export default React.memo(AutomationWizard);
