import { Flex, Link, Text, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { epicFlowGradientColors } from '../../theme';
import { usdFormatter } from '../../utilities/currencyConverter';

const MobileWizardFooter = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex
      w="full"
      display={['flex', 'flex', 'none']}
      alignItems="center"
      justifyContent="space-between"
      px="7.5%"
      position="fixed"
      bg={colorMode === 'light' ? 'white' : '#000'}
      bottom={0}
      h={32}
    >
      <Flex justifyContent="center" direction="column">
        <Text fontSize="14px" fontWeight="black" mb={1}>
          Account Value
        </Text>
        <Text fontSize="16px">{usdFormatter.format(props.av)}</Text>
      </Flex>
      <Link
        as={RouterLink}
        to="/mybots?mode=newbot"
        // borderLeftWidth={props.text !== 'Logout' ? 3 : 0}
        // borderColor={location === props.to.slice(1) && props.text !== 'location' ? props.activeColor : 'transparent'}
        borderRadius="xl"
        textDecoration="none"
        // color={activeColor()}
        // bg="red.400"
        _hover={{
          textDecoration: 'none'
        }}
        _focus={{
          outline: 'none'
        }}
      >
        <Flex
          direction="column"
          borderRadius="lg"
          w={44}
          h={12}
          justifyContent="center"
          alignItems="center"
          bgGradient="linear(to-r, #1874ff, #00ccff)"
        >
          <Text fontSize="14px" fontWeight="black" color="white">
            Create Bot
          </Text>
        </Flex>
      </Link>
    </Flex>
  );
};

export default MobileWizardFooter;
