import { ColorModeScript, Flex, Image } from '@chakra-ui/react';

import ControlPanel from './components/navigation/controlPanel/controlPanel';
import Layout from './containers/Layout/layout';

function App() {
  return (
    <Flex w="100wv" justify={['center', 'center', 'center', 'center', 'center', 'center']} align="flex-start">
      <ColorModeScript initialColorMode="light" />
      <Flex
        // my={[0, 0, 12, 12, , 12, 12]}
        w="full"
        // w={['full', 'full', '95%', '95%', '95%', '95%']}
        // boxShadow="base"
        // borderRadius={[0, 0, 24, 24, 24, 24]}
      >
        {/* <ControlPanel /> */}
        <Layout />
      </Flex>
    </Flex>
  );
}

export default App;

// in-app tutorial chemaleon
