import { ArrowForwardIcon, CheckCircleIcon, CopyIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  Button,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useClipboard,
  useColorMode,
  useMediaQuery,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import Amplify, { Auth, Cache } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CgArrowsExchange } from 'react-icons/cg';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';

import Binance from '../../assets/binance.png';
import BinanceUS from '../../assets/binancesecondary.png';
import Coinbase from '../../assets/coinbase.png';
// import EFLogo from '../../assets/eflogo.svg';
import BG from '../../assets/eflogo.svg';
import FD from '../../assets/onbg.jpg';
import Oanda from '../../assets/unnamed.png';
import { addExchange, getExchange } from '../../store/actions/profile';
import { epicFlowGradientColors, statusColors } from '../../theme';
import EFButton from '../UI/Button/efButton';

const ApiConnector = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const [copyValue, setCopyValue] = useState('3.74.231.239');

  const { onCopy, value, hasCopied } = useClipboard(copyValue);

  const [currentPage, setCurrentPage] = useState();

  const [exchange, setExchange] = useState(null);

  const authState = useSelector(state => state.auth);
  const profileState = useSelector(state => state.profile);
  const userState = useSelector(state => state.user);

  const [apiKey, setApiKey] = useState('');

  const [apiSecret, setApiSecret] = useState('');

  const [addError, setAddError] = useState(null);

  const dispatch = useDispatch();

  const [isMobile] = useMediaQuery('(max-width: 48em)');

  const handleAddExchange = exchange => {
    let data = {
      exchange: exchange,
      userID: authState.id,
      apiKey: apiKey,
      apiSecret: apiSecret
    };
    if (exchange === 'oandalive' || exchange === 'oandademo') {
      data = {
        exchange: exchange,
        userID: authState.id,
        token: apiKey
      };
    }
    dispatch(addExchange(data));
  };

  useEffect(() => {
    setAddError(profileState.error);
  }, [profileState.error]);

  useEffect(() => {
    if (authState.id && authState.exchanges.length === 0) {
      setCurrentPage('onboarding');
    } else if (authState.id && authState.exchanges.length > 0) {
      setCurrentPage('welcome');
    }
  }, [authState.id, authState.exchanges]);

  const imageSelector = exc => {
    if (exc === 'binanceus') {
      return BinanceUS;
    } else if (exc === 'binance') {
      return Binance;
    } else if (exc === 'oandalive' || exc === 'oandademo') {
      return Oanda;
    }
  };

  const handleLogout = async () => {
    let keysToRemove = ['token', 'ef_user_id', 'browser_id', 'token_exp'];
    // dispatch(stopSocket(authState.id, socketState?.socket, localStorage.getItem('browser_id')));
    // dispatch(stopSocket(handleDecryption(authState.id), socketState?.socket, localStorage.getItem('browser_id')));
    for (let key of keysToRemove) {
      localStorage.removeItem(key);
    }
    await Auth.signOut().then(() => window.location.replace('/login'));
    // window.location.assign('/login');
    // history.push('/login');
  };

  // const handleAddExchange = exchange => {
  //   const data = {
  //     exchange: exchange,
  //     userID: authState.id || profileState.id,
  //     apiKey: apiKey,
  //     apiSecret: apiSecret
  //   };
  //   dispatch(addExchange(data));
  // };

  const [binanceusText, setBinanceText] = useState({
    firstPart: `API Key and Secret Key are required for linking your account. Please follow Binance US `,
    secondPart: ` to create one, and then use it to add your account below. We will only be able to link your exchange account if spot trading is enabled.`
  });

  const [binancecomText, setBinancecomText] = useState({
    firstPart: `API Key and Secret Key are required for linking your account. Please follow Binance.com `,
    secondPart: ` to create one, and then use it to add your account below. We will only be able to link your exchange account if spot trading is enabled.`
  });

  const [oandaText, setOandaText] = useState({
    firstPart: `API Key is required for linking your account. Please follow `,
    secondPart: ` to create one, and then use it to add your account below.`
  });

  // const [coinbaseproText, setCoinbaseproText] = useState(
  //   'API Key, Secret Key, and Passphrase are required for linking your account. Please follow Coinbase Pro FAQ to create one, and then use it to add your account below. We will only be able to link your exchange account if spot trading is enabled.'
  // );

  const handleSelection = selection => {
    setExchange(selection);
    setCurrentPage('api');
  };

  const handleInput = (event, type) => {
    if (type === 'key') {
      setApiKey(event.target.value);
    } else {
      setApiSecret(event.target.value);
    }
  };

  const handleBack = () => {
    setExchange(null);
    setAddError(null);
    setCurrentPage('welcome');
  };

  const handleBoardingNext = () => {
    setCurrentPage('welcome');
  };

  const handleBoardingBack = () => {
    setCurrentPage('onboarding');
  };

  const initialRef = React.useRef();
  // const finalRef = React.useRef();

  const handleClosing = () => {
    if (currentPage === 'success') {
      // dispatch(getExchange(authState.id || profileState.id));
      props.onclose();
      window.location.reload();
    }
    setExchange(null);
    setCurrentPage('welcome');
    setApiKey('');
    setApiSecret('');
    setAddError(null);
    props.onclose();
  };

  useEffect(() => {
    if (profileState.addExchangeStatus && currentPage !== 'welcome') {
      setCurrentPage('success');
    }
  }, [profileState.addExchangeStatus]);

  //authState.exchanges.includes('Binance') || profileState.exchanges.includes('Binance')

  const handleFormControl = exchange => {
    if (exchange === 'binanceus') {
      return (
        <>
          <FormControl>
            <FormLabel fontSize="0.8125rem">API Key</FormLabel>
            <Input
              ref={initialRef}
              fontSize={['16px', '16px', '0.8125rem', '0.8125rem', '0.8125rem', '0.8125rem']}
              value={apiKey}
              onChange={e => handleInput(e, 'key')}
              placeholder="Binance US Api Key"
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel fontSize="0.8125rem">API Secret</FormLabel>
            <Input
              placeholder="Binance US Api Secret"
              fontSize={['16px', '16px', '0.8125rem', '0.8125rem', '0.8125rem', '0.8125rem']}
              value={apiSecret}
              onChange={e => handleInput(e, 'secret')}
            />
          </FormControl>
        </>
      );
    } else if (exchange === 'binance') {
      return (
        <>
          <FormControl>
            <FormLabel fontSize="0.8125rem">API Key</FormLabel>
            <Input
              fontSize={['16px', '16px', '0.8125rem', '0.8125rem', '0.8125rem', '0.8125rem']}
              ref={initialRef}
              placeholder="Binance Api Key"
              onChange={e => handleInput(e, 'key')}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel fontSize="0.8125rem">Secret</FormLabel>
            <Input
              fontSize={['16px', '16px', '0.8125rem', '0.8125rem', '0.8125rem', '0.8125rem']}
              placeholder="Binance Api Secret"
              onChange={e => handleInput(e, 'secret')}
            />
          </FormControl>
          {/* <FormControl mt={4}>
            <FormLabel>Passphrase</FormLabel>
            <Input placeholder="Passphrase" />
          </FormControl> */}
        </>
      );
    } else {
      return (
        <FormControl>
          <FormLabel fontSize="0.8125rem">API Key</FormLabel>
          <Input
            ref={initialRef}
            fontSize={['16px', '16px', '0.8125rem', '0.8125rem', '0.8125rem', '0.8125rem']}
            placeholder={exchange === 'oandalive' ? 'Oanda Live API Key' : 'Oanda Demo API Key'}
            onChange={e => handleInput(e, 'key')}
          />
        </FormControl>
      );
    }
  };

  const renderDynamicContent = page => {
    switch (page) {
      case 'onboarding':
        return (
          <>
            <Flex w="fit-content" ml="5%">
              <Image src={BG} height={12} width={12} borderWidth={1} />
              {/* <Image boxSize={8} src={EFLogo} />
            <Icon as={CgArrowsExchange} boxSize={8} />
            <Image boxSize={8} src={imageSelector(exchange)} borderRadius="lg" /> */}
            </Flex>
            <ModalHeader fontSize="lg" pt={0} pb={0} fontWeight="bold" mt={4}>
              Welcome to EpicFlow
            </ModalHeader>
            {authState.id && authState.exchanges.length > 0 && (
              <ModalCloseButton
                _focus={{
                  outline: 'none'
                }}
              />
            )}
            <ModalBody pb={6}>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                Automated Forex and Crypto Trading for Everyone.
              </Text>
              <Divider mt={6} />
              <Flex
                // backgroundImage={FD}
                // backgroundSize="cover"
                // backgroundRepeat="no-repeat"
                w="full"
                h={36}
                borderRadius="lg"
                justifyContent="center"
                position="relative"
                alignItems="center"
              >
                {/* <Flex w="88px" h="88px" borderRadius="xl" bg="gray.50" justifyContent="center" alignItems="center">
                 
                </Flex>
                <Flex w="88px" h="88px" borderRadius="xl" bg="gray.50" justifyContent="center" alignItems="center">
                  <Image borderRadius="lg" src={Oanda} boxSize={16} objectFit="contain" />
                </Flex>
                <Flex w="88px" h="88px" borderRadius="xl" bg="gray.50" justifyContent="center" alignItems="center">
                  <Image borderRadius="lg" src={Binance} boxSize={12} objectFit="contain" />
                </Flex> */}
                <Image borderRadius="lg" mr={6} src={BinanceUS} boxSize={12} objectFit="contain" />
                <Flex position="absolute">
                  <Image borderRadius="lg" src={Oanda} boxSize={16} objectFit="contain" />
                </Flex>
                <Image borderRadius="lg" ml={6} src={Binance} boxSize={12} objectFit="contain" />
              </Flex>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                Safely and securely connect your exchange accounts. Access and manage your portfolio with our
                user-friendly dashboard.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                title="Log Out"
                w="full"
                fontSize="13px"
                // mt={8}
                bg={colorMode === 'light' ? 'white' : 'transparent'}
                // borderColor={epicFlowGradientColors.left}
                mr={4}
                borderColor={props.bg}
                borderWidth={1}
                _hover={{
                  bg: colorMode === 'light' ? 'white' : 'transparent'
                }}
                _focus={{
                  outline: 'none'
                }}
                _active={{
                  bg: colorMode === 'light' ? 'white' : 'transparent'
                }}
                // color={epicFlowGradientColors.left}
                // color={userState.colorStatus === 'up' ? statusColors.up : statusColors.down}
                onClick={handleLogout}
              >
                Log Out
              </Button>
              <EFButton
                title="Next"
                w="full"
                // mt={4}
                // mb={4}
                fontSize="13px"
                color={colorMode === 'light' ? 'white' : 'black'}
                bg={colorMode === 'light' ? 'black' : 'white'}
                onClick={handleBoardingNext}
                isLoading={profileState.addExchangeLoading}
              />
            </ModalFooter>
          </>
        );
      case 'welcome':
        return (
          <>
            <ModalHeader fontSize="lg" pt={0} pb={1} fontWeight="bold">
              Add Exchange
            </ModalHeader>
            {authState.id && authState.exchanges.length > 0 && (
              <ModalCloseButton
                _focus={{
                  outline: 'none'
                }}
              />
            )}
            <ModalBody pb={6}>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                Please pick one of supported exchanges to link your account. EpicFlow will keep your account
                information secure and safe.
              </Text>
              <Divider mt={6} mb={8} />
              <Wrap spacing="24px" w="full" mb={8}>
                <WrapItem
                  w="full"
                  pointerEvents={authState.exchanges.includes('Oanda Demo') ? 'none' : 'auto'}
                  userSelect="none"
                >
                  <Flex
                    w="full"
                    justify="space-between"
                    align="center"
                    py={4}
                    px={3}
                    borderRadius="xl"
                    // boxShadow="rgba(0, 0, 0, 0.10) 0px 3px 15px"
                    // p={4}
                    // borderWidth={1}
                    // borderRadius="md"
                    // borderColor={colorMode === 'light' ? 'transparent' : 'gray.600'}
                    cursor="pointer"
                    _hover={{
                      // borderWidth: 1,
                      // borderColor: epicFlowGradientColors.left
                      backgroundColor: colorMode === 'light' ? 'gray.50' : '#2d2e30'
                    }}
                    onClick={() => handleSelection('oandademo')}
                  >
                    <Flex>
                      <Image borderRadius="lg" src={Oanda} boxSize={9} />
                      <Flex justify="center" align="center" ml={4}>
                        <Flex alignItems="flex-start" direction="column">
                          <Text fontSize="13px" fontWeight="semibold">
                            Oanda Demo
                          </Text>
                          <Text fontSize="12px" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                            The world&apos;s most popular forex pairs.
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    {authState.exchanges.includes('Oanda Demo') ? (
                      <Icon as={RiCheckboxCircleFill} boxSize={5} color="#48BB78" />
                    ) : (
                      <ArrowForwardIcon boxSize={4} />
                    )}
                  </Flex>
                </WrapItem>
                <WrapItem
                  w="full"
                  pointerEvents={authState.exchanges.includes('Oanda Live') ? 'none' : 'auto'}
                  // pointerEvents="none"
                  userSelect="none"
                >
                  <Flex
                    w="full"
                    justify="space-between"
                    align="center"
                    py={4}
                    px={3}
                    borderRadius="xl"
                    // boxShadow="rgba(0, 0, 0, 0.10) 0px 3px 15px"
                    // p={4}
                    // borderWidth={1}
                    // borderRadius="md"
                    // borderColor={colorMode === 'light' ? 'transparent' : 'gray.600'}
                    cursor="pointer"
                    position="relative"
                    _hover={{
                      // borderWidth: 1,
                      // borderColor: epicFlowGradientColors.left
                      backgroundColor: colorMode === 'light' ? 'gray.50' : '#2d2e30'
                    }}
                    onClick={() => handleSelection('oandalive')}
                  >
                    {/* <Flex
                      py={1}
                      px={3}
                      borderRadius="full"
                      bg="blue.200"
                      alignItems="center"
                      justifyContent="center"
                      position="absolute"
                      right={0}
                      top={-3}
                    >
                      <Text color="blue.500" fontWeight="bold" fontSize="11px">
                        Soon
                      </Text>
                    </Flex> */}
                    <Flex>
                      <Image borderRadius="lg" src={Oanda} boxSize={9} />
                      <Flex justify="center" align="center" ml={4}>
                        <Flex alignItems="flex-start" direction="column">
                          <Text fontSize="13px" fontWeight="semibold">
                            Oanda Live
                          </Text>
                          <Text fontSize="12px" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                            The world&apos;s most popular forex pairs.
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    {authState.exchanges.includes('Oanda Live') ? (
                      <Icon as={RiCheckboxCircleFill} boxSize={5} color="#48BB78" />
                    ) : (
                      <ArrowForwardIcon boxSize={4} />
                    )}
                  </Flex>
                </WrapItem>
                {/* <WrapItem
                  w="full"
                  pointerEvents={authState.exchanges.includes('Binance US') ? 'none' : 'auto'}
                  userSelect="none"
                >
                  <Flex
                    w="full"
                    justify="space-between"
                    align="center"
                    // boxShadow="rgba(0, 0, 0, 0.10) 0px 3px 15px"
                    // borderWidth={1}
                    // borderColor={colorMode === 'light' ? 'transparent' : 'gray.600'}
                    cursor="pointer"
                    py={4}
                    px={3}
                    borderRadius="xl"
                    // mb={3}
                    _hover={{
                      // borderColor: epicFlowGradientColors.left
                      backgroundColor: colorMode === 'light' ? 'gray.50' : '#2d2e30'
                    }}
                    onClick={() => handleSelection('binanceus')}
                  >
                    <Flex>
                      <Image borderRadius="lg" src={BinanceUS} boxSize={9} objectFit="contain" />
                      <Flex justify="center" align="center" ml={4} h="full">
                        <Flex alignItems="flex-start" direction="column">
                          <Text fontSize="13px" fontWeight="semibold">
                            Binance US
                          </Text>
                          <Text fontSize="12px" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                            Crypto trading platform for US residents
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    {authState.exchanges.includes('Binance US') ? (
                      <Icon ml={2} mt={0.5} as={RiCheckboxCircleFill} w="20px" h="20px" color="#48BB78" />
                    ) : (
                      <ArrowForwardIcon boxSize={4} />
                    )}
                  </Flex>
                </WrapItem>
                <WrapItem
                  w="full"
                  pointerEvents={authState.exchanges.includes('Binance') ? 'none' : 'auto'}
                  userSelect="none"
                >
                  <Flex
                    w="full"
                    justify="space-between"
                    align="center"
                    py={4}
                    px={3}
                    borderRadius="xl"
                    // boxShadow="rgba(0, 0, 0, 0.10) 0px 3px 15px"
                    // borderWidth={1}
                    // borderRadius="md"
                    // borderColor={colorMode === 'light' ? 'transparent' : 'gray.600'}
                    cursor="pointer"
                    _hover={{
                      backgroundColor: colorMode === 'light' ? 'gray.50' : '#2d2e30'
                      // borderWidth: 1,
                      // borderColor: epicFlowGradientColors.left
                    }}
                    onClick={() => handleSelection('binance')}
                  >
                    <Flex>
                      <Image borderRadius="lg" src={Binance} boxSize={9} />
                      <Flex justify="center" align="center" ml={4}>
                        <Flex alignItems="flex-start" direction="column">
                          <Text fontSize="13px" fontWeight="semibold">
                            Binance
                          </Text>
                          <Text fontSize="12px" color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
                            Worlds biggest crypto exchange
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    {authState.exchanges.includes('Binance') ? (
                      <Icon ml={2} mt={0.5} as={RiCheckboxCircleFill} w="20px" h="20px" color="#48BB78" />
                    ) : (
                      <ArrowForwardIcon boxSize={4} />
                    )}
                  </Flex>
                </WrapItem> */}
              </Wrap>
            </ModalBody>
            {authState.id && authState.exchanges.length === 0 && (
              <ModalFooter pt={0}>
                <Button
                  title="Back"
                  w="full"
                  fontSize="13px"
                  // mt={8}
                  bg={colorMode === 'light' ? 'white' : 'transparent'}
                  // borderColor={epicFlowGradientColors.left}
                  mr={4}
                  borderColor={props.bg}
                  borderWidth={1}
                  _hover={{
                    bg: colorMode === 'light' ? 'white' : 'transparent'
                  }}
                  _focus={{
                    outline: 'none'
                  }}
                  _active={{
                    bg: colorMode === 'light' ? 'white' : 'transparent'
                  }}
                  // color={epicFlowGradientColors.left}
                  // color={userState.colorStatus === 'up' ? statusColors.up : statusColors.down}
                  onClick={handleBoardingBack}
                >
                  Back
                </Button>
              </ModalFooter>
            )}
            {/* <ModalFooter>
              <Button
                onClick={props.onclose}
                backgroundColor={colorMode === 'light' ? 'white' : 'black'}
                pl={8}
                pr={8}
                borderWidth={1}
                borderColor={colorMode === 'light' ? 'black' : 'transparent'}
                borderRadius="full"
                _focus={{
                  outline: 'none'
                }}
                _hover={{
                  borderColor: 'transparent',
                  backgroundColor: colorMode === 'light' ? 'black' : 'white',
                  color: colorMode === 'light' ? 'white' : 'black'
                }}
                fontSize="sm"
              >
                Close
              </Button>
            </ModalFooter> */}
          </>
        );
      case 'api':
        return (
          <>
            <Flex w="fit-content" position="absolute" top={0} left={0}>
              <Lottie
                options={{
                  loop: true,
                  path: 'https://lottie.host/fb98c365-6085-4170-94a8-3d992eb7d138/OU0aT7avfq.json'
                }}
                height={100}
                width={100}
              />
              {/* <Image boxSize={8} src={EFLogo} />
              <Icon as={CgArrowsExchange} boxSize={8} />
              <Image boxSize={8} src={imageSelector(exchange)} borderRadius="lg" /> */}
            </Flex>
            <ModalHeader fontSize="lg" pt={0} pb={1} fontWeight="bold" mt={12}>
              Link your exchange account
            </ModalHeader>
            {authState.id && authState.exchanges.length > 0 && (
              <ModalCloseButton
                _focus={{
                  outline: 'none'
                }}
              />
            )}
            <ModalBody pb={6}>
              {(exchange === 'binanceus' || exchange === 'binance') && (
                <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} mb={2}>
                  {exchange === 'binanceus' ? `${binanceusText.firstPart}` : `${binancecomText.firstPart}`}
                  <Link
                    target="_blank"
                    href="https://www.binance.com/en/support/faq/360002502072"
                    color={epicFlowGradientColors.left}
                  >
                    FAQ
                  </Link>
                  {exchange === 'binanceus' ? `${binanceusText.secondPart}` : `${binancecomText.secondPart}`}
                </Text>
              )}
              {(exchange === 'oandalive' || exchange === 'oandademo') && (
                <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.300'} mb={2}>
                  {exchange === 'oandalive'
                    ? `Oanda Live ${oandaText.firstPart}`
                    : `Oanda Demo ${oandaText.firstPart}`}
                  <Link target="_blank" href="https://hub.oanda.com/" color={epicFlowGradientColors.left}>
                    Oanda Hub
                  </Link>
                  {oandaText.secondPart}
                </Text>
              )}
              <Divider my={6} />
              {exchange === 'binance' && (
                <Alert
                  // status="error"
                  bg={colorMode === 'light' ? 'gray.50' : 'gray.800'}
                  borderRadius="lg"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  textAlign="left"
                  // height="170px"
                  mb={4}
                >
                  <AlertDescription maxWidth="sm" fontWeight="700" fontSize="sm">
                    Enabling IP Whitelisting
                  </AlertDescription>
                  <AlertDescription maxWidth="sm" fontSize="sm">
                    Please log in to your Binance.com account and go to API Settings. Turn on IP whitelisting and
                    copy/paste the below IP address.
                  </AlertDescription>
                  <Flex my={2} borderRadius="lg" bg={colorMode === 'light' ? 'white' : 'gray.900'}>
                    <Input
                      value={copyValue}
                      border="none"
                      borderRadius="lg"
                      // onChange={e => setValue(e.target.value)}
                      isReadOnly
                      mr={2}
                      fontSize={['16px', '16px', '0.8125rem', '0.8125rem', '0.8125rem', '0.8125rem']}
                      fontWeight="md"
                    />
                    {!hasCopied ? (
                      <IconButton
                        bg="transparent"
                        _hover={{
                          bg: 'transparent'
                        }}
                        _focus={{
                          border: 'none'
                        }}
                        _active={{
                          border: 'none'
                        }}
                        aria-label="Copy to clipboard"
                        onClick={onCopy}
                        icon={<CopyIcon />}
                      />
                    ) : (
                      <Flex justifyContent="center" alignItems="center" mr={2}>
                        <CheckCircleIcon />
                        <Text fontSize="sm" ml={1}>
                          Copied!
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Alert>
              )}
              {handleFormControl(exchange)}
              {!profileState.addExchangeLoading && addError && (
                <Text w="full" textAlign="center" mt={4} color="red.400" fontSize="0.8125rem">
                  {profileState.error}
                </Text>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                title="Back"
                w="full"
                fontSize="13px"
                // mt={8}
                bg={colorMode === 'light' ? 'white' : 'transparent'}
                // borderColor={epicFlowGradientColors.left}
                mr={4}
                borderColor={props.bg}
                borderWidth={1}
                _hover={{
                  bg: colorMode === 'light' ? 'white' : 'transparent'
                }}
                _focus={{
                  outline: 'none'
                }}
                _active={{
                  bg: colorMode === 'light' ? 'white' : 'transparent'
                }}
                // color={epicFlowGradientColors.left}
                // color={userState.colorStatus === 'up' ? statusColors.up : statusColors.down}
                onClick={handleBack}
              >
                Back
              </Button>
              <EFButton
                title="Link Account"
                w="full"
                // mt={4}
                // mb={4}
                fontSize="13px"
                color={colorMode === 'light' ? 'white' : 'black'}
                bg={colorMode === 'light' ? 'black' : 'white'}
                onClick={() => handleAddExchange(exchange)}
                isLoading={profileState.addExchangeLoading}
              />
            </ModalFooter>
          </>
        );
      case 'success':
        return (
          <>
            <ModalHeader fontSize="lg" fontWeight="medium" mb={0} pb={0}>
              All done!
            </ModalHeader>
            {authState.id && authState.exchanges.length > 1 && (
              <ModalCloseButton
                _focus={{
                  outline: 'none'
                }}
              />
            )}
            <ModalBody pb={6}>
              <Text fontSize="sm" color="gray.600">
                Your exchange has been successfully linked to epicflow. You now will be able to see your assets on{' '}
                <chakra.span fontWeight="semibold">{exchange.toUpperCase()}</chakra.span> and create new bots on{' '}
                <chakra.span fontWeight="semibold">{exchange.toUpperCase()}</chakra.span>.
              </Text>
              <Divider my={6} />
              <Flex w="full" align="center" justify="center" mt={6}>
                <Lottie
                  options={{
                    loop: false,
                    path: 'https://lottie.host/3804ca79-943f-4566-b481-a2e04811bf3c/tA1X6rDgvp.json'
                  }}
                  height={50}
                  width={50}
                />
                {/* <Image boxSize={12} src={EFLogo} />
                <Icon as={CgArrowsExchange} mr={6} ml={6} w={8} h={8} />
                <Image boxSize={12} src={exchange === 'binanceus' ? BinanceUS : Binance} /> */}
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button
                title="Close"
                w="full"
                fontSize="13px"
                // mt={8}
                bg={colorMode === 'light' ? 'white' : 'transparent'}
                // borderColor={epicFlowGradientColors.left}
                borderColor={props.bg}
                borderWidth={1}
                _hover={{
                  bg: colorMode === 'light' ? 'white' : 'transparent'
                }}
                _focus={{
                  outline: 'none'
                }}
                _active={{
                  bg: colorMode === 'light' ? 'white' : 'transparent'
                }}
                // color={epicFlowGradientColors.left}
                // color={userState.colorStatus === 'up' ? statusColors.up : statusColors.down}
                onClick={handleClosing}
              >
                Close
              </Button>
            </ModalFooter>
          </>
        );
      default:
        break;
    }
  };

  return (
    // TODO handle responsive sizing
    currentPage ? (
      <Modal
        closeOnOverlayClick={authState.id && authState.exchanges.length === 0 ? false : true}
        // size={isMobile ? 'md' : 'xl'}
        isCentered
        motionPreset="slideInBottom"
        // margin="auto"
        // initialFocusRef={initialRef}

        // finalFocusRef={finalRef}
        // motionPreset="slideInBottom"
        isOpen={props.isopen}
        scrollBehavior="inside"
        // isCentered={isMobile ? false : true}
        onClose={handleClosing}
        returnFocusOnClose={false}
      >
        <ModalOverlay />
        <ModalContent
          borderRadius="2xl"
          // px={[2, 2, 8, 8, 8, 8]}
          pt={currentPage === 'onboarding' ? 6 : 12}
          bg={colorMode === 'light' ? 'white' : '#1f2124'}
          // mb={96}
        >
          {renderDynamicContent(currentPage)}
        </ModalContent>
      </Modal>
    ) : null
  );
};

ApiConnector.propTypes = {
  onclose: PropTypes.func,
  isopen: PropTypes.bool
};

export default ApiConnector;
