import 'react-chat-widget/lib/styles.css';
import './dashboard.css';

import {
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Text,
  Tooltip,
  useColorMode,
  useDisclosure
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { addResponseMessage, Widget } from 'react-chat-widget';
import { FiPlus } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import validator from 'validator';

import MobileWizardFooter from '../../components/automationWizard/mobileWizardFooter';
import ApiConnector from '../../components/modals/apiConnector';
import TOS from '../../components/modals/tos';
import ControlPanel from '../../components/navigation/controlPanel/controlPanel';
import Drawer from '../../components/navigation/Drawer/drawer';
import Footer from '../../components/navigation/footer/footer';
import Navigation from '../../components/navigation/navigation';
import { getActiveBotsStats, getFxBotSettings } from '../../store/actions/bot';
import { getOandaExchangeData } from '../../store/actions/exchange';
import { startSocket } from '../../store/actions/socket';
import { stopSocket } from '../../store/actions/socket';
import { fetchUserAssetData, fetchUserPortfolioData } from '../../store/actions/user';
import { epicFlowGradientColors, statusColors } from '../../theme';
import axios from '../../utilities/axios';
import { usdFormatter } from '../../utilities/currencyConverter';
import { handleDecryption } from '../../utilities/encryption';
import { calculateExchangeID, getExchangeNames } from '../../utilities/exchangeHelpers';
import WaitingList from '../Auth/waitingList';
import Help from '../help';
import Home from '../home';
import Coin from '../markets/coin';
import Markets from '../markets/markets';
import News from '../news';
import Reports from '../reports';
import Rewards from '../rewards';
import Settings from '../settings';
import Social from '../social';
import Subscriptions from '../subscriptions';
import Support from '../support';
import TradingBot from '../tradingBot';
import Unlinked from '../unlinked';

//TODO Change component name to ===> App once Layout hoc added.

// Footer is needed to be added for dashboard

const Dashboard = props => {
  window.onpageshow = function (event) {
    if (event.persisted) {
      console.log('persisting event');
      if (!localStorage.getItem('token_exp')) {
        window.location.reload();
      }
    }
  };

  const directionOptions = ['column', 'column', 'column', 'row', 'row', 'row'];
  // const alignOptions = ['flex-end', 'flex-end', 'flex-start', 'flex-start', 'flex-start', 'flex-start'];

  const { isOpen: isTOSOpen, onOpen: onTOSOpen, onClose: onTOSClose } = useDisclosure();

  const [currentTab, setCurrentTab] = useState(props.history.location.pathname.slice(1));
  const [coin, setCoin] = useState('');
  const [helpContent, setHelpContent] = useState('');
  const _containerRef = useRef(null);

  const authState = useSelector(state => state.auth);
  const profileState = useSelector(state => state.profile);
  const userState = useSelector(state => state.user);
  const socketState = useSelector(state => state.socket);

  const dispatch = useDispatch();

  const { colorMode, toggleColorMode } = useColorMode();

  const [content, setContent] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isOpen: isApiConnectorOpen, onOpen: onApiConnectorOpen, onClose: onApiConnectorClose } = useDisclosure();

  const handleLogout = async () => {
    let keysToRemove = ['token', 'ef_user_id', 'browser_id', 'token_exp'];
    // dispatch(stopSocket(authState.id, socketState?.socket, localStorage.getItem('browser_id')));
    // dispatch(
    //   stopSocket('f7abed40-b6d3-424c-b94a-a551e851f67c', socketState?.socket, localStorage.getItem('browser_id'))
    // );
    for (let key of keysToRemove) {
      localStorage.removeItem(key);
    }
    await stopSocket(socketState.socket);
    await Auth.signOut().then(() => window.location.replace('/login'));
  };

  // useEffect(() => {
  //   addResponseMessage('Hi There!\n How can we help you today?');
  // }, []);

  // const handleNewUserMessage = async newMessage => {
  //   const body = {
  //     uuid: authState.id,
  //     summary: `New Ticket has been issued for ${authState.email}`,
  //     description: newMessage
  //   };
  //   const response = await axios.post(`https://api.epicflow.io/createticket`, body);
  //   const data = response.data;
  //   addResponseMessage(
  //     `Thank you ${authState.email}. Your ticket has been issued.\n Ticket Number: ${data.success}`
  //   );
  //   addResponseMessage(
  //     `You will be receiving an email regarding on your issue. You can simply close this chatbox.`
  //   );
  //   // Now send the message throught the backend API
  // };

  useEffect(() => {
    if (authState.id && authState.currentOption) {
      // console.log('in dashboard.js :: useEffect :: socketState : ', socketState);
      if (!socketState.isSocketOn) {
        if (!localStorage.getItem('browser_id')) {
          localStorage.setItem('browser_id', uuidv4());
        }
        let id = localStorage.getItem('browser_id');
        dispatch(
          startSocket(
            authState.id,
            // getExchangeNames(authState.currentOption) || getExchangeNames(profileState.currentOption),
            calculateExchangeID(authState.currentOption) || calculateExchangeID(profileState.currentOption),
            id,
            getExchangeNames(authState.currentOption)
          )
        );
      }
      // ,
      // () => {
      dispatch(
        fetchUserAssetData(
          authState.id,
          getExchangeNames(authState.currentOption),
          calculateExchangeID(authState.currentOption)
        )
      );
      //         ).then(() => {
      //           dispatch(getActiveBotsStats(authState.id, calculateExchangeID(authState.currentOption)));
      //           dispatch(getOandaExchangeData(calculateExchangeID(authState.currentOption)));
      //         });
      //       }
      //     )
      //   );
      // }
      // // dispatch(
      // //   fetchUserAssetData(
      // //     authState.id,
      // //     getExchangeNames(authState.currentOption),
      // //     calculateExchangeID(authState.currentOption)
      // //   )
      // // );
      dispatch(fetchUserPortfolioData(authState.id, calculateExchangeID(authState.currentOption), 'day'));
      // dispatch(getOandaExchangeData(calculateExchangeID(authState.currentOption)));
      dispatch(getActiveBotsStats(authState.id, calculateExchangeID(authState.currentOption)));
      if (authState.currentOption === 'Oanda Live' || authState.currentOption === 'Oanda Demo') {
        dispatch(getFxBotSettings());
      }
      // else if (socketState.isSocketOn) {
      //   dispatch(
      //     fetchUserAssetData(
      //       authState.id,
      //       getExchangeNames(authState.currentOption),
      //       calculateExchangeID(authState.currentOption)
      //     )
      //   );
      //   // dispatch(
      //   //   fetchUserAssetData(
      //   //     authState.id,
      //   //     getExchangeNames(authState.currentOption),
      //   //     calculateExchangeID(authState.currentOption)
      //   //   )
      //   // );
      //   dispatch(getActiveBotsStats(authState.id));
      // }
      // window.pendo.initialize({
      //   visitor: {
      //     id: authState.id,
      //     email: authState.email,
      //     paidOrTrialUser: authState.stripeCurrentPlan.toLowerCase() !== 'free' ? 'unpaid' : 'paid'
      //   },
      //   account: {
      //     id: authState.id,
      //     payingStatus: authState.stripeCurrentPlan.toLowerCase() !== 'free' ? 'unpaid' : 'paid',
      //     location: 'US'
      //   }
      // });
    }
  }, [authState.id, authState.currentOption, socketState.isSocketOn, dispatch]);

  useEffect(() => {
    if (props.history.location.pathname.slice(1) !== currentTab) {
      if (props.history.location.pathname.slice(1, 17) === 'cryptocurrencies') {
        setCoin(props.history.location.pathname.slice(18));
        setCurrentTab(props.history.location.pathname.slice(1, 17));
      } else if (props.history.location.pathname.slice(1, 5) === 'help') {
        if (props.history.location.pathname.slice(6)) {
          setHelpContent(props.history.location.pathname.slice(6));
        } else {
          setHelpContent('createbinanceapikey');
        }
        setCurrentTab(props.history.location.pathname.slice(1, 5));
      } else {
        setCurrentTab(props.history.location.pathname.slice(1));
      }
    } else {
      if (props.history.location.pathname.slice(1, 5) === 'help') {
        setHelpContent(props.history.location.pathname.slice(6));
      }
      if (props.history.location.pathname.slice(1, 17) === 'cryptocurrencies') {
        setCoin(props.history.location.pathname.slice(18));
        setCurrentTab(props.history.location.pathname.slice(1, 17));
      }
    }
  }, [props.history.location.pathname]);

  const renderText = el => {
    if (el.symbol === 'ALL') {
      return <Text mr={1} fontSize="sm">{`$${el.base_value}`}</Text>;
    } else if (el.symbol !== 'USD') {
      if (el.symbol !== 'USDT') {
        return <Text mr={1} fontSize="sm">{`${el.amount} / $${el.base_value}`}</Text>;
      }
    } else {
      return <Text mr={1} fontSize="sm">{`$${el.base_value}`}</Text>;
    }
  };

  useEffect(() => {
    if (authState.exchanges.length > 0) {
      if (authState.tosVersion !== process.env.REACT_APP_TOS_VERSION) {
        onTOSOpen();
      }
    }
  }, [authState.tosVersion, authState.exchanges]);

  useEffect(() => {
    const content = (
      <Flex align="center">
        {userState.earnings.map((el, idx) => {
          return (
            <Flex key={idx} align="center" mr={2}>
              <Image boxSize={4} src={el.img} mr={1} />
              <Flex justify="center" align="center" flexShrink={0}>
                <Text mr={1} fontSize="sm">{`${el.symbol}`}</Text>
                {renderText(el)}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
    setContent(content);
  }, [userState]);

  useEffect(() => {
    if (
      // profileState.exchanges.length === 0 &&
      // profileState.isExchangesChecked &&
      authState.id &&
      authState.exchanges.length === 0 &&
      authState.approved
    ) {
      onApiConnectorOpen();
    }
  }, [authState.exchanges, authState.id, authState.approved]);

  useEffect(() => {
    const checkSessionExpiration = () => {
      const exp = Number(localStorage.getItem('token_exp'));
      // console.log(exp, '---exp');
      if (exp) {
        const timeUntilExpiration = exp - Date.now();
        if (timeUntilExpiration <= 0) {
          console.log('---session timeout');
          handleLogout();
        } else {
          const timer = setTimeout(() => {
            console.log('---- session expired');
            handleLogout();
          }, timeUntilExpiration);

          return () => clearTimeout(timer);
        }
      }
    };
    checkSessionExpiration();

    // Periodic check every minute
    const intervalId = setInterval(checkSessionExpiration, 60000);

    // Handle the visibility change event
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log('----in handle visibility change');
        checkSessionExpiration();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      console.log('----in handle return');
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  const renderTabs = useMemo(() => {
    // if (!props.isAuth && !authState.verified) {
    //   return props.history.push('/signup');
    // }
    if (!props.isAuth && !authState.verified) {
      if (localStorage.getItem('isReturning')) {
        return props.history.push('/login');
      }
      return props.history.push('/signup');
    }
    // } else {
    //   return <Unlinked />;
    // }
    else {
      switch (currentTab) {
        case '':
          return <Home onClick={onApiConnectorOpen} />;
        case 'mybots':
          return <TradingBot />;
        case 'rewards':
          return <Rewards />;
        case 'news':
          return <News />;
        case 'markets':
          return <Markets _containerRef={_containerRef} />;
        case 'reporting':
          return <Reports />;
        case 'social':
          return <Social />;
        case 'subscriptions':
          return <Subscriptions />;
        case 'settings':
          return <Settings />;
        // case 'cryptocurrencies':
        //   return <Coin title={coin.charAt(0).toUpperCase() + coin.slice(1)} id={coin} />;
        case currentTab.startsWith('help') ? currentTab : null:
          return <Help helpContent={helpContent} />;
        case 'support':
          return <Support />;
        default:
          break;
      }
    }
  }, [currentTab, helpContent]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [currentTab]);

  return (
    <Flex
      align="flex-start"
      direction={directionOptions}
      justify="flex-start"
      w="full"
      // w={['full', 'full', '95%', '95%', '95%', '95%']}
      // borderWidth={5}
      // bg={colorMode === 'dark' ? '#0f1114' : 'white'}
      // mt={32}
      // minW={['3xl', '3xl', '3xl', null, null, null]}
      // maxW='8xl'
      position="relative"
      pb={[32, 32, 0]}
      maxW="100rem"
      mx="auto"
      mt={[0, 0, 0, '72px', '72px', '72px']}
      // my={[0, 0, 12, 12, , 12, 12]}
      // boxShadow={['none', 'none', 'none', 'none', 'none', 'base']}
      // borderRadius={[0, 0, 24, 24, 24, 24]}
      minH="100vh"
      // maxH="70vh"
      overflowX="hidden"
      // bg="white"
      // bg="rgba(256,256,256,0.45)"
      // backdropFilter="blur(15px)"
    >
      {/* {authState.id && authState.exchanges.length > 0 && (
        <ControlPanel
          // activeColor={userState.colorStatus === 'up' ? statusColors.upFaded : statusColors.downFaded}
          // color={userState.colorStatus === 'up' ? statusColors.up : statusColors.down}
          activeColor={colorMode === 'light' ? 'black' : 'white'}
          color={colorMode === 'light' ? 'white' : 'black'}
        />
      )} */}
      {authState.id && authState.exchanges.length > 0 && authState.approved && (
        <Drawer onAddExchangeClick={onApiConnectorOpen} onLogout={handleLogout} />
      )}
      <TOS isopen={isTOSOpen} onclose={onTOSClose} />
      <ApiConnector isopen={isApiConnectorOpen} onclose={onApiConnectorClose} />
      <Flex w="full" bg={colorMode === 'light' ? 'white' : '#000'} justify="center">
        <Flex
          w="95%"
          ref={_containerRef}
          direction="column"
          align={['center', 'center', 'center', 'flex-start', 'flex-start', 'flex-start']}
          justify="center"
          // ml={[0, 0, 20, 20, 72, 72]}
          overflowY="hidden"
          overflowX="hidden"
          // pt={8}
        >
          {authState.id && authState.exchanges.length > 0 && (
            <Navigation
              userName={authState.name || profileState.name}
              history={props.history}
              onPlusClick={onApiConnectorOpen}
              // color={userState.colorStatus === 'up' ? statusColors.up : statusColors.down}
              // faded={userState.colorStatus === 'up' ? statusColors.upFaded : statusColors.downFaded}
              color={epicFlowGradientColors.left}
              faded="transparent"
              portfolioTotal={
                userState.portfolioTotalAmounts
                  ? usdFormatter.format(userState.portfolioTotalAmounts['Total'])
                  : ' '
              }
              email={authState.email}
              content={content}
              isPicture={authState.picture ? validator.isURL(authState.picture) : false}
              imgLink={authState.picture ? authState.picture : ''}
              onUserAddExClick={onApiConnectorOpen}
              userInitial={
                authState.name?.slice(0, 2).toUpperCase() || profileState.name?.slice(0, 2).toUpperCase()
              }
            />
          )}
          <Flex
            w="full"
            m="auto"
            ref={_containerRef}
            align={['center', 'center', 'flex-start', 'flex-start', 'flex-start', 'flex-start']}
            justify="center"
            // ml={[0, 0, 20, 20, 72, 72]}
            overflowY="hidden"
            overflowX="hidden"
            // mt={20}
            // pt={8}
          >
            {renderTabs}
          </Flex>
          {/* <Widget
          title=""
          subtitle="Epicflow Agent"
          showCloseButton={false}
          titleAvatar="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80"
          profileAvatar="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80"
          // profileClientAvatar={
          //   authState.picture
          //     ? authState.picture
          //     : require(`../../assets/avatars/${localStorage.getItem('avatar')}`)
          // }
          handleNewUserMessage={handleNewUserMessage}
        /> */}
          {currentTab === '' && userState.oandaData?.TOTAL && (
            <MobileWizardFooter av={userState.oandaData.TOTAL} />
          )}
          <Footer />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Dashboard;
