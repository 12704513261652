import { Divider, Flex, Heading, Text, useColorMode } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const FxCalendarCard = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex w="full" justifyContent="space-between" alignItems="center" pb={4} mb={4} borderBottomWidth={1}>
      <Flex direction="column" flex={1}>
        <Text fontSize="14px" textAlign="left">
          {props.date}
        </Text>
        <Text mt={2} fontSize="14px" color="gray.400" textAlign="left" display={['block', 'block', 'none']}>
          {props.time}
        </Text>
      </Flex>
      <Flex display={['none', 'none', 'flex']} flex={1}>
        <Text fontSize="14px" textAlign="left">
          {props.time}
        </Text>
      </Flex>
      <Flex flex={1}>
        <Text fontSize="14px" ml={3} flex={1} textAlign="left">
          {props.currency}
        </Text>
      </Flex>
      <Flex alignItems="center" flex={1} justifyContent="flex-start">
        <Flex
          w={2}
          h={2}
          borderRadius="full"
          bg={props.impact === 'low' ? '#F3F700' : props.impact === 'medium' ? 'orange' : 'red.500'}
          pl={[2, 2, 0]}
          mr="6px"
          mt="1px"
        ></Flex>
        <Text
          fontSize="14px"
          my={0}
          //   letterSpacing="0.05rem"
          //   maxW="12ch"
          //   overflow="hidden"
          //   textOverflow="ellipsis"
        >
          {props.event}
        </Text>
      </Flex>
    </Flex>
  );
};

export default FxCalendarCard;
