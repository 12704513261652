import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  chakra,
  Divider,
  Flex,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorMode,
  Wrap
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BsSortDown } from 'react-icons/bs';
import { IoIosCheckmark } from 'react-icons/io';
import { TbSortAscending2, TbSortDescending2 } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import AutomationWizard from '../components/automationWizard/automationWizard';
import FxAutomationWizard from '../components/automationWizard/fxAutomationWizard';
import BotCard from '../components/cards/botCard';
import BotContainer from '../components/cards/botContainer';
import OandaBotContainer from '../components/cards/oandaBotContainer';
//import coinData from '../constants/data.json';
import {
  getActiveBotsConfig,
  getActiveBotsStats,
  getCompletedBots,
  getDescriptionTexts,
  getPlayStat
} from '../store/actions/bot';
import { epicFlowGradientColors, statusColors } from '../theme';
import { usdFormatter } from '../utilities/currencyConverter';
import { calculateExchangeID, getExchangeNames } from '../utilities/exchangeHelpers';

const TradingBot = () => {
  const [sortBy, setSortBy] = useState('Date Created');

  const [tabIndex, setTabIndex] = useState();

  const [isDescending, setIsDescending] = useState(true);

  const { colorMode, toggleColorMode } = useColorMode();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const authState = useSelector(state => state.auth);
  const botState = useSelector(state => state.bot);
  const profileState = useSelector(state => state.profile);
  const userState = useSelector(state => state.user);

  const [bot, setBot] = useState([]);

  const socketState = useSelector(state => state.socket);

  const [completedBotCounts, setCompletedBotCounts] = useState([]);

  const [cardAccordionIndex, setCardAccordionIndex] = useState(0);

  const [isSwitchChecked, setIsSwitchChecked] = useState(true);

  // useEffect(async () => {
  //   // if (botState.activeBotsConfig.length === 0) {
  //   //   // dispatch(getActiveBotsConfig(authState.id || profileState.id));
  //   //   // dispatch(getActiveBotsConfig('0b00fa3e-fb0b-4424-bf93-ed63b0cdf82a'));
  //   // }
  //   console.log('hhhhhh');
  //   dispatch(getActiveBotsConfig(authState.id || profileState.id));
  //   dispatch(getActiveBotsStats(authState.id || profileState.id));
  //   dispatch(getCompletedBots(authState.id || profileState.id));
  //   // dispatch(getActiveBotsStats('0b00fa3e-fb0b-4424-bf93-ed63b0cdf82a'));
  // }, []);

  useEffect(() => {
    if (authState.id) {
      // dispatch(getActiveBotsConfig(authState.id));
      // if (authState.currentOption) {
      //   dispatch(getActiveBotsStats(authState.id, getExchangeNames(authState.currentOption)));
      // } else {
      //   dispatch(getActiveBotsStats(authState.id, getExchangeNames(socketState.currentOption)));
      // }
      dispatch(getCompletedBots(authState.id, calculateExchangeID(authState.currentOption)));
      dispatch(getDescriptionTexts());
    }
  }, [authState.id, authState.currentOption, authState.currentOption]);

  const handleGlobalToggler = e => {
    if (e.target.checked) {
      setIsSwitchChecked(true);
      setCardAccordionIndex(0);
    } else {
      setIsSwitchChecked(false);
      setCardAccordionIndex(-1);
    }
  };

  const handleDescending = bool => {
    setIsDescending(bool);
  };

  // const handleSorting = useCallback(option => {
  //   if (option === 'New to Old') {
  //     setSortBy('New to Old');
  //   } else if (option === 'Profit %') {
  //     setSortBy('Profit %');
  //   } else if (option === 'A - Z') {
  //     setSortBy('A - Z');
  //   }
  // }, []);

  const handleSorting = option => {
    if (option === 'Date Created') {
      setSortBy('Date Created');
    } else if (option === 'Profit') {
      setSortBy('Profit');
    } else if (option === 'A - Z') {
      setSortBy('A - Z');
    } else if (option === 'Margin Used') {
      setSortBy('Margin Used');
    }
  };

  const sortCompletedData = useCallback(
    d => {
      let data = [];
      for (let bot of d) {
        if (bot.exchange_id === calculateExchangeID(authState.currentOption)) {
          data.push(bot);
        }
      }
      if (sortBy === 'Date Created') {
        return data.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
      } else if (sortBy === 'Profit') {
        return data.sort((a, b) => b.overall_gain_percent - a.overall_gain_percent);
      } else if (sortBy === 'A - Z') {
        return data.sort((a, b) => a.bot_name.localeCompare(b.bot_name));
      }
    },
    [sortBy, authState]
  );

  const sortActiveData = (configData, statData) => {
    //TODO prevent unneccesary renders..
    // console.log('sorting data', configData, statData);
    if (sortBy === 'Date Created') {
      return configData.sort((a, b) => new Date(b.bot_run_time).getTime() - new Date(a.bot_run_time).getTime());
    } else if (sortBy === 'Profit') {
      let data = configData.map((bot, idx) => {
        let botStat = statData.find(element => bot.bot_id === element.bot_id);
        return (bot = {
          ...bot,
          overall_gain_percent: botStat.overall_gain_percent
        });
      });
      return data.sort((a, b) => b.overall_gain_percent - a.overall_gain_percent);
    } else if (sortBy === 'A - Z') {
      return configData.sort((a, b) => a.bot_name.localeCompare(b.bot_name));
    }
  };

  const sortActiveOandaData = useCallback(
    (configData, statData) => {
      //TODO prevent unneccesary renders..
      // console.log('sorting data', configData, statData);
      if (sortBy === 'Date Created') {
        if (isDescending) {
          return configData.sort(
            (a, b) => new Date(b.bot_run_time).getTime() - new Date(a.bot_run_time).getTime()
          );
        } else {
          return configData.sort(
            (a, b) => new Date(a.bot_run_time).getTime() - new Date(b.bot_run_time).getTime()
          );
        }
      } else if (sortBy === 'Profit') {
        if (isDescending) {
          return configData.sort((a, b) => b.completed_run_gain - a.completed_run_gain);
        } else {
          return configData.sort((a, b) => a.completed_run_gain - b.completed_run_gain);
        }
      } else if (sortBy === 'Margin Used') {
        let data = configData.map((bot, idx) => {
          let botStat = statData.find(element => Number(bot.bot_id) === Number(element.bot_id));
          return (bot = {
            ...bot,
            mu: botStat.margin_utilized
          });
        });
        if (isDescending) {
          return data.sort((a, b) => b.mu - a.mu);
        } else {
          return data.sort((a, b) => a.mu - b.mu);
        }
      } else if (sortBy === 'A - Z') {
        if (isDescending) {
          return configData.sort((a, b) => b.bot_name.localeCompare(a.bot_name));
        } else {
          return configData.sort((a, b) => a.bot_name.localeCompare(b.bot_name));
        }
      }
    },
    [isDescending, sortBy, isLoading]
  );

  // const sortActiveOandaData = (configData, statData) => {
  //   //TODO prevent unneccesary renders..
  //   // console.log('sorting data', configData, statData);
  //   if (sortBy === 'Date Created') {
  //     if (isDescending) {
  //       return configData.sort((a, b) => new Date(b.bot_run_time).getTime() - new Date(a.bot_run_time).getTime());
  //     } else {
  //       return configData.sort((a, b) => new Date(a.bot_run_time).getTime() - new Date(b.bot_run_time).getTime());
  //     }
  //   } else if (sortBy === 'Profit') {
  //     if (isDescending) {
  //       return configData.sort((a, b) => b.completed_run_gain - a.completed_run_gain);
  //     } else {
  //       return configData.sort((a, b) => a.completed_run_gain - b.completed_run_gain);
  //     }
  //   } else if (sortBy === 'A - Z') {
  //     return configData.sort((a, b) => a.bot_name.localeCompare(b.bot_name));
  //   }
  // };

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      // console.log('updating trading bot page');
      // do componentDidUpdate logic
    }
  });

  useEffect(() => {
    //Local 29 / prod 31
    let query = window.location.href.slice(31);
    if (query === 'mode=newbot') {
      setTabIndex(2);
    } else {
      setTabIndex(0);
    }
  }, []);

  return (
    <Flex
      w="full"
      // maxW="7xl"
      justify="space-between"
      overflowY="hidden"
      overflowX="hidden"
      margin="auto"
      pb={24}
      mt={[6, 6, 10]}
      pt={[6, 6, 12, 12, 12, 12]}
    >
      <Flex w="full" direction="column" justify="flex-start" minH="100vh" align="flex-start">
        {/* <Heading fontSize="2xl" mb={4} pl={2}>
          My Bots
        </Heading> */}
        {tabIndex !== undefined && (
          <Tabs variant="unstyled" pl={2} w="full" onChange={index => setTabIndex(index)} defaultIndex={tabIndex}>
            <Flex w="full" h={10} justifyContent="space-between">
              <TabList
                // mx={['auto', 'auto', 'auto']}
                alignItems="center"
                justifyContent={['space-between', 'space-between', 'center']}
                borderRadius="xl"
                w="full"
                // px={4}
                // w={['full', 'full', 'full', 'full']}
              >
                <Flex
                  alignItems="center"
                  justifyContent={['space-between', 'space-between', 'center']}
                  w="fit-content"
                  overflowX="scroll"
                  css={{
                    '&::-webkit-scrollbar': {
                      display: 'none'
                    },
                    '&::-webkit-scrollbar-track': {
                      display: 'none'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      display: 'none'
                    }
                  }}
                >
                  <Tab
                    _selected={{
                      // color: '#00ccff',
                      // borderBottom: '1px solid ' + `${'#00ccff'}`
                      bg: colorMode === 'light' ? 'gray.100' : 'gray.800'
                    }}
                    _focus={{
                      outline: 'none'
                    }}
                    // borderBottom="1px solid transparent"
                    fontSize="0.8125rem"
                    borderRadius="full"
                    fontWeight="semibold"
                  >
                    <Flex alignItems="center">
                      <Text mr={4}>Active</Text>
                      <Flex bg="blue.100" px={2} borderRadius="md">
                        <Text color="blue.500">
                          {' '}
                          {
                            botState.activeBotsConfig.filter(
                              e => e.exchange_id === calculateExchangeID(authState.currentOption)
                            ).length
                          }
                        </Text>
                      </Flex>
                    </Flex>
                  </Tab>
                  <Tab
                    _selected={{
                      // color: '#00ccff',
                      // borderBottom: '1px solid ' + `${'#00ccff'}`
                      bg: colorMode === 'light' ? 'gray.100' : 'gray.800'
                    }}
                    _focus={{
                      outline: 'none'
                    }}
                    // borderBottom="1px solid transparent"
                    borderRadius="full"
                    fontSize="0.8125rem"
                    ml={[0, 0, 6]}
                    fontWeight="semibold"
                  >
                    <Flex alignItems="center">
                      <Text mr={4}>Completed</Text>
                      <Flex bg="green.100" px={2} borderRadius="md">
                        <Text color="green.500">
                          {
                            botState.completedBots.filter(
                              e => e.exchange_id === calculateExchangeID(authState.currentOption)
                            ).length
                          }
                        </Text>
                      </Flex>
                    </Flex>
                  </Tab>
                  {/* <Tab
                    _selected={{
                      // color: '#00ccff',
                      // borderBottom: '1px solid ' + `${'#00ccff'}`
                      bg: colorMode === 'light' ? 'gray.100' : 'gray.800'
                    }}
                    _focus={{
                      outline: 'none'
                    }}
                    // borderBottom="1px solid transparent"
                    borderRadius="full"
                    fontSize="0.8125rem"
                    ml={[0, 0, 6]}
                    fontWeight="semibold"
                  >
                    <Flex alignItems="center">
                      <Text mr={4}>Run Stats</Text>
                      <Flex bg="orange.100" px={2} borderRadius="md">
                        <Text color="orange.500">Soon</Text>
                      </Flex>
                    </Flex>
                  </Tab> */}
                  <Tab
                    _selected={{
                      // color: '#00ccff',
                      // borderBottom: '1px solid ' + `${'#00ccff'}`
                      bg: colorMode === 'light' ? 'gray.100' : 'gray.800'
                    }}
                    _focus={{
                      outline: 'none'
                    }}
                    // borderBottom="1px solid transparent"
                    fontSize="0.8125rem"
                    ml={[0, 0, 6]}
                    borderRadius="full"
                    whiteSpace="nowrap"
                    fontWeight="semibold"
                  >
                    New Bot
                  </Tab>
                </Flex>
              </TabList>
              {tabIndex === 0 && (
                <Flex alignItems="center" direction="row">
                  <Menu>
                    <MenuButton
                      bg="transparent"
                      _focus={{ outline: 'none' }}
                      fontSize="0.8125rem"
                      _active={{ backgroundColor: 'transparent' }}
                      _hover={{
                        backgroundColor: 'transparent'
                      }}
                      as={Button}
                      // rightIcon={
                      //   <ChevronDownIcon display={['none', 'none', 'none', 'block', 'block', 'block', 'block']} />
                      // }
                      pl={[0, 0, 0, 4, 4, 4]}
                      pr={[0, 0, 0, 4, 4, 4]}
                    >
                      <Icon as={BsSortDown} boxSize={6} />
                      {/* <Text>{sortBy}</Text>
                    <ChevronDownIcon display={['block', 'block', 'block', 'none', 'none', 'none', 'none']} /> */}
                    </MenuButton>
                    <MenuList
                      bg={colorMode === 'light' ? 'white' : '#1f2124'}
                      borderRadius="xl"
                      w="xs"
                      boxShadow="sm"
                      px={4}
                      pb={16}
                    >
                      <Text fontSize="16px" fontWeight="bold" my={3} ml={4}>
                        Sort By
                      </Text>
                      <MenuItem
                        py={3}
                        borderRadius="lg"
                        fontSize="0.8125rem"
                        onClick={() => handleSorting('Date Created')}
                      >
                        <Flex justify="space-between" alignItems="center" w="full">
                          <Text>Date Created</Text>
                          {sortBy === 'Date Created' && <Icon as={IoIosCheckmark} boxSize={6} color="#48BB78" />}
                        </Flex>
                      </MenuItem>
                      <MenuItem
                        py={3}
                        borderRadius="lg"
                        fontSize="0.8125rem"
                        onClick={() => handleSorting('Profit')}
                      >
                        <Flex justify="space-between" alignItems="center" w="full">
                          <Text>Profit</Text>
                          {sortBy === 'Profit' && <Icon as={IoIosCheckmark} boxSize={6} color="#48BB78" />}
                        </Flex>
                      </MenuItem>
                      <MenuItem
                        py={3}
                        borderRadius="lg"
                        fontSize="0.8125rem"
                        onClick={() => handleSorting('Margin Used')}
                      >
                        <Flex justify="space-between" alignItems="center" w="full">
                          <Text>Margin Used</Text>
                          {sortBy === 'Margin Used' && <Icon as={IoIosCheckmark} boxSize={6} color="#48BB78" />}
                        </Flex>
                      </MenuItem>
                      <MenuItem
                        py={3}
                        borderRadius="lg"
                        fontSize="0.8125rem"
                        onClick={() => handleSorting('A - Z')}
                      >
                        <Flex justify="space-between" alignItems="center" w="full">
                          <Text>A - Z</Text>
                          {sortBy === 'A - Z' && <Icon as={IoIosCheckmark} boxSize={6} color="#48BB78" />}
                        </Flex>
                      </MenuItem>
                      <Flex
                        h="56px"
                        borderBottomLeftRadius="xl"
                        borderBottomRightRadius="xl"
                        position="absolute"
                        bottom={0}
                        left={0}
                        alignItems="center"
                        w="full"
                        borderTopWidth={1}
                      >
                        <Flex
                          onClick={() => handleDescending(true)}
                          cursor="pointer"
                          flex={1}
                          justify="center"
                          alignItems="center"
                          borderRightWidth={1}
                        >
                          <Icon as={TbSortDescending2} boxSize={5} mr={2} color={isDescending ? '#48BB78' : ''} />
                          <Text fontSize="13px" color={isDescending ? '#48BB78' : ''}>
                            Descending
                          </Text>
                        </Flex>
                        <Flex
                          onClick={() => handleDescending(false)}
                          cursor="pointer"
                          flex={1}
                          justify="center"
                          alignItems="center"
                        >
                          <Icon as={TbSortAscending2} boxSize={5} mr={2} color={!isDescending ? '#48BB78' : ''} />
                          <Text fontSize="13px" color={!isDescending ? '#48BB78' : ''}>
                            Ascending
                          </Text>
                        </Flex>
                      </Flex>
                    </MenuList>
                  </Menu>
                </Flex>
              )}
            </Flex>

            <TabPanels>
              <TabPanel display="flex" justifyContent="center">
                {/* <Flex
                alignItems="center"
                w="full"
                py={3}
                mt={2}
                mb={4}
                px="5%"
                color="gray.500"
                borderWidth={colorMode === 'dark' && 1}
                boxShadow="rgba(0, 0, 0, 0.10) 0px 3px 15px"
                fontWeight="normal"
                borderRadius="md"
                justify="space-between"
                display={['flex', 'flex', 'flex', 'none', 'none', 'none']}
              >
                <Flex alignItems="center">
                  <Text fontSize="sm">Show Bot Details</Text>
                </Flex>
                <Switch
                  size="md"
                  isChecked={isSwitchChecked}
                  isFocusable={false}
                  color="#00ccff"
                  onChange={e => handleGlobalToggler(e)}
                />
              </Flex> */}
                <Wrap alignItems="center" spacing="40px" mt={8} w="full" display="flex" justifyContent="center">
                  {botState.isBotSocketOn && botState.activeBotsStats ? (
                    authState.currentOption === 'Oanda Live' || authState.currentOption === 'Oanda Demo' ? (
                      <OandaBotContainer
                        active
                        sortBy={sortBy}
                        isDescending={isDescending}
                        currentOption={authState.currentOption}
                        activeBotsStats={botState.activeBotsStats}
                        // data={botState.activeBotsConfig}
                        data={sortActiveOandaData(botState.activeBotsConfig, botState.activeBotsStats)}
                        descriptions={botState.descriptions}
                        index={cardAccordionIndex}
                      />
                    ) : (
                      <BotContainer
                        active
                        sortBy={sortBy}
                        activeBotsStats={botState.activeBotsStats}
                        data={sortActiveData(botState.activeBotsConfig, botState.activeBotsStats)}
                        descriptions={botState.descriptions}
                        index={cardAccordionIndex}
                      />
                    )
                  ) : null}
                  {/* {!botState.activeBotsStatsLoading && !botState.activeBotsConfigLoading ? (
                  <BotContainer data={botState} sortBy={sortBy} />
                ) : null} */}
                </Wrap>
              </TabPanel>
              <TabPanel>
                <Wrap spacing="40px" mt={4} w="full">
                  {!botState.completedBotsLoading && tabIndex === 1 ? (
                    authState.currentOption === 'Oanda Live' || authState.currentOption === 'Oanda Demo' ? (
                      <OandaBotContainer
                        sortBy={sortBy}
                        // activeBotsStats={botState.activeBotsStats}
                        data={sortCompletedData(botState.completedBots)}
                      />
                    ) : (
                      <BotContainer
                        sortBy={sortBy}
                        // activeBotsStats={botState.activeBotsStats}
                        data={sortCompletedData(botState.completedBots)}
                      />
                    )
                  ) : null}
                  {/* {!botState.completedBotsLoading && tabIndex === 1
                  ? sortCompletedData(botState.completedBots).map((bot, idx) => {
                      return (
                        <BotCard
                          key={uuidv4()}
                          name={capitalizeFirstLetter(bot.bot_name)}
                          botStyle={capitalizeFirstLetter(bot.bot_style)}
                          baseGraph={bot.baseAsset}
                          quoteGraph={bot.quoteAsset}
                          inverse={bot.bot_type === 'inverse'}
                          maxSpent={Number(bot.max_amount)}
                          overallGainPercent={Number(bot.overall_gain_percent).toFixed(2)}
                          overallGain={calculateOverallGain(bot.overall_gain_usd)}
                          playCount={bot.play_count}
                          pair={bot.symbol_pair}
                          gain={Number(bot.overall_gain).toFixed(bot.quoteAssetPrecision)}
                          isBase={bot.isQuoteEqualsBaseCurrency}
                          quoteAssetPrecision={Number(bot.quoteAssetPrecision)}
                          quote={bot.quoteAsset}
                          since={calculateLifeTime(bot.created_at, bot.updated_at)}
                          botState="Completed"
                          botID={bot.bot_id}
                          target={bot.symbol_to_profit}
                          exchange={calculateExchangeKey(bot.exchange_id)}
                          exchangeName={calculateExchangeKey(bot.exchange_id).toLowerCase()}
                        />
                      );
                    })
                  : null} */}
                </Wrap>
              </TabPanel>
              <TabPanel>
                <Flex
                  w="100%"
                  justify="center"
                  // align={['center', 'center', 'center', 'flex-start', 'flex-start', 'flex-start', 'flex-start']}
                  alignItems="center"
                  flexDir={['column', 'column', 'column', 'row', 'row', 'row']}
                  pt={8}
                >
                  {(authState.currentOption === 'Oanda Demo' || authState.currentOption === 'Oanda Live') &&
                    socketState.isSocketOn &&
                    userState.oandaData && (
                      <FxAutomationWizard
                        // placeholder={
                        //   userState.assetData[calculateExchangeKey(currentOption).key][
                        //     calculateExchangeKey(currentOption).exc
                        //   ][1].free
                        // }
                        // placeholder={placeholder}
                        // maxInvestmentAmount={placeholder}
                        // placeholder={placeholder}
                        // maxInvestmentAmount={placeholder}
                        // sliderColor={userState.colorStatus === 'up' ? statusColors.upFaded : statusColors.downFaded}
                        // bg={userState.colorStatus === 'up' ? statusColors.up : statusColors.down}
                        // bg={epicFlowGradientColors.left}
                        // sliderColor={epicFlowGradientColors.left}
                        bg={colorMode === 'light' ? 'black' : 'white'}
                        sliderColor="black"
                      />
                    )}
                  {userState.assetData?.length > 0 && authState.currentOption && socketState.isSocketOn ? (
                    <AutomationWizard
                      // placeholder={
                      //   userState.assetData[calculateExchangeKey(currentOption).key][
                      //     calculateExchangeKey(currentOption).exc
                      //   ][1].free
                      // }
                      // placeholder={placeholder}
                      // maxInvestmentAmount={placeholder}
                      // placeholder={placeholder}
                      // maxInvestmentAmount={placeholder}
                      bg={colorMode === 'light' ? 'black' : 'white'}
                      sliderColor="black"
                    />
                  ) : null}
                  {/* <Flex
                  direction="column"
                  justify="flex-start"
                  w="sm"
                  borderWidth={1}
                  ml={[0, 0, 0, 20, 20, 20]}
                  mt={[12, 12, 12, 0, 0, 0]}
                  borderRadius={16}
                  overflowY="scroll"
                  // scrollbarColor={colorMode === 'light' ? 'green' : 'gray.900'}
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '1px'
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '1px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      width: '1px'
                    }
                  }}
                  p={8}
                  maxH="577.562px"
                  display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
                  boxShadow="lg"
                >
                  <Text fontSize="lg" fontWeight="semibold" mb={2} lineHeight={1}>
                    How do EpicFlow bots work?
                  </Text>
                  <Text fontSize="sm" fontWeight="md" mt={4}>
                    Our bots use a{' '}
                    <chakra.span fontWeight="semibold">
                      proprietary AI-driven Dollar Cost Averaging (DCA) technique
                    </chakra.span>{' '}
                    while investing in assets when certain favorable smart conditions are met to maximize the
                    crypto pairs’ return. EpicFlow.io is the first hyper-convenient and AI-driven DCA strategy that
                    automatically works 24/7 for you. If your goal is to get more crypto in the long run, doing DCA
                    beats simple buy and hold.
                    <Text mb={2} mt={2}>
                      Another advantage of EpicFlow.io is the bots can be created to profit from upward or downward
                      trends. We will call them <chakra.span fontWeight="semibold"> Regular </chakra.span> and{' '}
                      <chakra.span fontWeight="semibold"> Inverse </chakra.span> bots for the sake of simplicity.
                    </Text>
                    <Text>
                      <chakra.span fontWeight="semibold"> Regular </chakra.span> bots smartly buy at dips while
                      there is a downward pricing trend. This helps to continuously decrease the cost average of
                      your bot. Then once the price moves up at a min 1% higher than the latest cost average, then
                      the bot sells all current assets and exits the run with a min of 1% profit. Profit % is
                      typically higher thanks to our proprietary trailing stop techniques that maximize the %
                      profit as price ascends.
                    </Text>
                  </Text>
                </Flex> */}
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </Flex>
    </Flex>
  );
};

TradingBot.propTypes = {};

export default TradingBot;
