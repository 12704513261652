import { chakra, Flex, Icon, Image, Link, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { RiDiscordFill, RiInstagramFill, RiTwitterFill, RiYoutubeFill } from 'react-icons/ri';
import { Link as RouterLink } from 'react-router-dom';

import EPICFLOW from '../../../assets/eflogo.svg';

const Footer = props => {
  const directionOptions = ['column', 'column', 'column', 'row', 'row', 'row'];

  return (
    <Flex
      w={['full', 'full', '95%', '95%', '95%', '95%']}
      alignItems="center"
      direction={directionOptions}
      h={!props.waitinglist && '200px'}
      margin="auto"
      pb={8}
      justify="space-between"
      maxW="110rem"
      fontWeight="medium"
    >
      <Flex alignItems="center">
        <Link
          as={RouterLink}
          to={{ pathname: 'https://www.epicflow.io/about' }}
          target="_blank"
          textDecoration="none"
          fontSize="xs"
          mr={8}
          _hover={{
            textDecoration: 'none'
          }}
        >
          About
        </Link>
        <Link
          as={RouterLink}
          to={{ pathname: 'https://www.epicflow.io/terms' }}
          target="_blank"
          textDecoration="none"
          mr={8}
          fontSize="xs"
          _hover={{
            textDecoration: 'none'
          }}
        >
          Terms
        </Link>
        <Link
          as={RouterLink}
          to={{ pathname: 'https://www.epicflow.io/privacypolicy' }}
          target="_blank"
          textDecoration="none"
          fontSize="xs"
          _hover={{
            textDecoration: 'none'
          }}
        >
          Privacy Policy
        </Link>
      </Flex>
      <Flex direction="column" alignItems="center">
        {/* <Image src={EPICFLOW} w={10} /> */}
        <Text fontSize="xs" mt={4} textAlign="center">
          © 2024,{' '}
          <chakra.span bgClip="text" bgGradient="linear(to-r, #1874ff, #00ccff)">
            EpicFlow.io
          </chakra.span>{' '}
          is a registered product of DeFi Management Inc.
        </Text>
        <Text fontSize="xs" mt={1}>
          All Rights Reserved.
        </Text>
      </Flex>
      <Flex alignItems="center">
        <Link
          href="mailto: info@epicflow.io"
          isExternal
          textDecoration="none"
          fontSize="xs"
          _hover={{
            textDecoration: 'none'
          }}
          mr={4}
        >
          info@epicflow.io
        </Link>
        <Link
          href="https://discord.gg/kACTZQhG"
          isExternal
          textDecoration="none"
          fontSize="xs"
          _hover={{
            textDecoration: 'none'
          }}
          mr={4}
        >
          <Icon as={RiDiscordFill} boxSize={5} />
        </Link>
        <Link
          href="https://twitter.com/EpicFlow_io"
          isExternal
          textDecoration="none"
          fontSize="xs"
          _hover={{
            textDecoration: 'none'
          }}
          mr={4}
        >
          <Icon as={RiTwitterFill} boxSize={5} />
        </Link>
        <Link
          href="https://www.instagram.com/epicflow_io/"
          isExternal
          textDecoration="none"
          fontSize="xs"
          _hover={{
            textDecoration: 'none'
          }}
          mr={4}
        >
          <Icon as={RiInstagramFill} boxSize={4} />
        </Link>
        <Link
          href="https://www.youtube.com/@epicflowio"
          isExternal
          textDecoration="none"
          fontSize="xs"
          _hover={{
            textDecoration: 'none'
          }}
          mr={4}
        >
          <Icon as={RiYoutubeFill} boxSize={5} />
        </Link>
      </Flex>
    </Flex>
  );
};

export default Footer;
