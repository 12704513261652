import { TimeIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  Heading,
  Icon,
  Text,
  useColorMode,
  useDisclosure
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import axios from '../../utilities/axios';
import SubInfo from '../modals/subInfo';

const API_URL = process.env.REACT_APP_API_URL;
//const DEV_API_URL = 'http://localhost:5020/';

function SubscriptionCard({
  isDowngraded,
  price,
  stripeCustomerId,
  customerCurrentSubscription,
  interval,
  isFreeCard,
  isSchedule
}) {
  const { colorMode, toggleColorMode } = useColorMode();

  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isCurrentPlan, setIsCurrentPlan] = useState(false);
  const [isPaidPlan, setIsPaidPlan] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isModalError, setIsModalError] = useState(false);
  const [isModalResult, setIsModalResult] = useState();

  const { isOpen: isSubInfoOpen, onOpen: onSubInfoOpen, onClose: onSubInfoClose } = useDisclosure();

  useEffect(() => {
    if (customerCurrentSubscription?.plan.id === price.id) {
      setIsCurrentPlan(true);
    }
    if (customerCurrentSubscription?.plan.amount > 0) {
      setIsPaidPlan(true);
    }
    if (!customerCurrentSubscription) {
      setIsCurrentPlan(false);
    }
  }, [customerCurrentSubscription]);

  const handleModalConfirm = async (priceId, stripeCustomerId) => {
    try {
      setIsModalLoading(true);
      if (
        customerCurrentSubscription?.plan.amount !== 0 &&
        price.product.name.toUpperCase() !== 'FREE' &&
        price.unit_amount > customerCurrentSubscription?.plan.amount
      ) {
        const reqBody = {
          customerId: stripeCustomerId,
          priceId: priceId,
          action: 'upgrade',
          subscriptionId: customerCurrentSubscription.id
        };
        const response = await axios.post(`${API_URL}checkout/update-subscription`, reqBody);
        console.log('Upgrade response ', response.data);
        setIsModalLoading(false);
        if (response.data.error) {
          setIsModalError(response.data.error.message);
        } else {
          setIsModalResult(response.data);
        }
      } else if (
        customerCurrentSubscription?.plan.amount !== 0 &&
        price.product.name.toUpperCase() !== 'FREE' &&
        price.unit_amount < customerCurrentSubscription?.plan.amount
      ) {
        const reqBody = {
          customerId: stripeCustomerId,
          priceId: priceId,
          action: 'downgrade',
          subscriptionId: customerCurrentSubscription.id
        };
        const response = await axios.post(`${API_URL}checkout/update-subscription`, reqBody);
        console.log('Downgrade response ', response.data);
        setIsModalLoading(false);
        if (response.data.error && response.data.error.message !== 'Assignment to constant variable.') {
          setIsModalError(response.data.error.message);
        } else {
          setIsModalResult(response.data);
        }
      }
    } catch (error) {
      setIsModalError(error.message);
    }
  };

  const handleModalClose = () => {
    if (isModalResult) {
      onSubInfoClose();
      return window.location.reload();
    }
    setIsModalError(false);
    setIsModalLoading(false);
    setIsUpgrade(false);
    setIsModalResult();
    onSubInfoClose();
  };

  const createCheckoutSession = async (priceId, stripeCustomerId) => {
    try {
      const reqBody = { customerId: stripeCustomerId, priceId: priceId };
      console.log(priceId, stripeCustomerId);
      const createdCheckoutSession = await axios
        .post(`${API_URL}checkout/create-checkout-session`, reqBody)
        .then(response => response.data);
      if (createdCheckoutSession.url) {
        window.location.replace(`${createdCheckoutSession.url}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleSubscription = async (priceId, stripeCustomerId) => {
  //   // if (customerCurrentSubscription) {
  //   //   console.log('priceid is', priceId);
  //   //   console.log('customerCurrentSubscription is ', customerCurrentSubscription);
  //   //   await updateSubscription(customerCurrentSubscription, priceId);
  //   // } else {
  //   //   await createSubscription(priceId, stripeCustomerId, productName);
  //   // }
  //   // await createSubscription(priceId, stripeCustomerId, productName);

  //   // Method with cancelling and refunding
  //   // if (customerCurrentSubscription) {
  //   //   await refundToCustomer(customerCurrentSubscription);
  //   //   await cancelSubscription(customerCurrentSubscription);
  //   //   await createCheckoutSession(priceId, stripeCustomerId);
  //   // } else {
  //   //   await createCheckoutSession(priceId, stripeCustomerId);
  //   // }

  //   // Only creating checkout session
  //   console.log('creating checkout session...');
  //   await createCheckoutSession(priceId, stripeCustomerId);
  // };

  const handleSubscription = async (priceId, stripeCustomerId) => {
    if (
      customerCurrentSubscription?.plan.amount !== 0 &&
      price.product.name.toUpperCase() !== 'FREE' &&
      price.unit_amount > customerCurrentSubscription?.plan.amount
    ) {
      setIsUpgrade(true);
      onSubInfoOpen();
    } else if (
      customerCurrentSubscription?.plan.amount !== 0 &&
      price.product.name.toUpperCase() !== 'FREE' &&
      price.unit_amount < customerCurrentSubscription?.plan.amount
    ) {
      setIsUpgrade(false);
      onSubInfoOpen();
    } else {
      await createCheckoutSession(priceId, stripeCustomerId);
    }
  };

  const determineButtonText = () => {
    if (isCurrentPlan) {
      return 'Current Plan';
    } else if (
      customerCurrentSubscription?.plan.amount !== 0 &&
      price.product.name.toUpperCase() !== 'FREE' &&
      price.unit_amount > customerCurrentSubscription?.plan.amount
    ) {
      return 'Upgrade';
    } else if (
      customerCurrentSubscription?.plan.amount !== 0 &&
      price.product.name.toUpperCase() !== 'FREE' &&
      price.unit_amount < customerCurrentSubscription?.plan.amount
    ) {
      return 'Downgrade';
    } else {
      return 'Subscribe';
    }
  };

  useEffect(() => {
    if (
      customerCurrentSubscription?.plan.amount !== 0 &&
      price.product.name.toUpperCase() !== 'FREE' &&
      price.unit_amount > customerCurrentSubscription?.plan.amount
    ) {
      setIsUpgrade(true);
    } else if (
      customerCurrentSubscription?.plan.amount !== 0 &&
      price.product.name.toUpperCase() !== 'FREE' &&
      price.unit_amount < customerCurrentSubscription?.plan.amount
    ) {
      setIsUpgrade(false);
    }
  }, [customerCurrentSubscription]);

  if (subscriptionData) {
    return (
      <Redirect
        to={{
          pathname: '/subscribe',
          state: subscriptionData
        }}
      />
    );
  }

  // console.log('reqBody is', JSON.stringify({ customerId: stripeCustomerId, priceId: price.Id }));
  return (
    <Flex
      //   width={['70%', '70%', '70%', '30%']}
      flexDirection="column"
      align="flex-start"
      justify="flex-start"
      //   boxShadow="dark-lg"
      p={8}
      w={['95%', '95%', '450px']}
      // px={10}
      rounded="xl"
      h="610px"
      // bg="radial-gradient(
      //   circle closest-corner at center bottom,
      //   hsl(270, 15%, 18%),
      //   transparent 90%
      // ) no-repeat"
      // _after={{
      //   display: 'inline-block',
      //   position: 'absolute',
      //   background: 'hsl(199, 56%, 18%)',
      //   width: '300px',
      //   height: '300px',
      //   borderRadius: '50%',
      //   filter: 'blur(70px)',
      //   mixBlendMode: 'lighten',
      //   top: '50%',
      //   left: 0,
      //   transform: 'translate(-50%)'
      // }}
      // _before={{
      //   display: 'inline-block',
      //   position: 'absolute',
      //   background: 'hsl(199, 56%, 18%)',
      //   width: '300px',
      //   height: '300px',
      //   borderRadius: '50%',
      //   filter: 'blur(70px)',
      //   mixBlendMode: 'lighten',
      //   top: 0,
      //   right: 0,
      //   transform: 'translate(50%, -50%)'
      // }}
      // bg={price.product.name === 'Premium' ? 'purple.600' : ''}
      // color={price.product.name === 'Premium' ? 'white' : ''}
      borderWidth={2}
      borderColor={
        price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount
          ? 'gold'
          : ''
      }
      // boxShadow={price.product.name === 'Premium' ? 'rgba(255, 215, 0, 1) 0px 3px 15px' : ''}
      // _hover={{
      //   boxShadow: price.product.name !== 'Premium' ? 'lg' : ''
      // }}
      //   bg="gray.50"
      mx={1}
      my={4}
      // borderWidth={1}
    >
      <SubInfo
        title="Change Plan"
        onclose={handleModalClose}
        isopen={isSubInfoOpen}
        upgrade={isUpgrade}
        onClick={() => handleModalConfirm(price.id, stripeCustomerId)}
        result={isModalResult}
        loading={isModalLoading}
        error={isModalError}
        currentSub={customerCurrentSubscription?.plan.id}
      />
      {/* <Flex bg="gray.100" rounded="md"> */}
      <Flex w="full" justify="space-between" alignItems="center">
        <Text fontSize="sm" fontWeight="semibold" noOfLines={1}>
          {price.product.name.toUpperCase()}
        </Text>
        {price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount ? (
          <Flex py={2} px={3} justify="center" alignItems="center" borderRadius="lg" bg="gold">
            <Text fontSize="xs" fontWeight="medium" color="black">
              Recommended
            </Text>
          </Flex>
        ) : null}
        {isDowngraded ? (
          <Flex py={2} px={3} justify="center" alignItems="center" borderRadius="lg" borderWidth={1}>
            <Text fontSize="xs" fontWeight="medium" color={colorMode === 'light' ? 'gray.500' : 'gray.300'}>
              Scheduled
            </Text>
            <TimeIcon ml={2} boxSize={4} mb="2px" color={colorMode === 'light' ? 'gray.500' : 'gray.300'} />
          </Flex>
        ) : null}
      </Flex>
      <Flex direction="column">
        <Heading size="xl" mt={4}>
          {interval === 'month'
            ? `$${price.unit_amount / 100}`
            : price.product.name !== 'Free'
            ? `$${(price.unit_amount / 100 / 12).toFixed(2)}`
            : `$${price.unit_amount / 100 / 12}`}
          {/* ${price.unit_amount / 100} */}
        </Heading>
        <Text fontSize="sm" mt={4} fontWeight="semibold">
          {interval === 'month' ? 'Per month' : 'Per month'}
        </Text>
        <Text
          fontSize="sm"
          mt={4}
          fontWeight="semibold"
          mb={
            isDowngraded ||
            (price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount)
              ? 0
              : 3
          }
        >
          {interval === 'month' && price.product.name !== 'Free' ? (
            'Billed monthly'
          ) : price.product.name !== 'Free' ? (
            `Billed as $${price.unit_amount / 100} per year`
          ) : (
            <br></br>
          )}
        </Text>
      </Flex>

      <Divider my={4} />

      <Flex w="full" mt={4} direction="column" justify="space-between" h="full" alignItems="center">
        <Flex w="full" mt={4} direction="column">
          <Flex align="center" justify="flex-start" width="full" fontSize="md" mb={3}>
            {price.product.metadata.md1PortfolioTracking ? (
              <Flex align="center" justify="flex-start">
                {/* <Icon
                  as={RiCheckboxCircleFill}
                  w="16px"
                  h="16px"
                  color={
                    price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount
                      ? 'gold'
                      : ''
                  }
                /> */}
                <Text fontSize="sm" fontWeight="bold">
                  {price.product.metadata.md1PortfolioTracking}
                </Text>
              </Flex>
            ) : (
              ''
            )}
          </Flex>

          <Flex align="flex-start" justify="flex-start" width="full" fontSize="md" mb={3}>
            {price.product.metadata.md2BotNumber ? (
              <Flex align="center" justify="flex-start">
                <Icon
                  as={RiCheckboxCircleFill}
                  w="16px"
                  h="16px"
                  color={
                    price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount
                      ? 'gold'
                      : ''
                  }
                />
                <Text ml={2} fontSize="sm" fontWeight="normal">
                  {price.product.metadata.md2BotNumber}
                </Text>
              </Flex>
            ) : (
              ''
            )}
          </Flex>

          <Flex align="flex-start" justify="flex-start" width="full" fontSize="md" mb={3}>
            {price.product.metadata.md3BotInvestmentAmount ? (
              <Flex align="center" justify="flex-start">
                <Icon
                  as={RiCheckboxCircleFill}
                  w="16px"
                  h="16px"
                  color={
                    price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount
                      ? 'gold'
                      : ''
                  }
                />
                <Text ml={2} fontSize="sm" fontWeight="normal">
                  {price.product.metadata.md3BotInvestmentAmount}
                </Text>
              </Flex>
            ) : (
              ''
            )}
          </Flex>

          {/* <Flex align="flex-start" justify="flex-start" width="gold" fontSize="md" mb={3}>
            {price.product.metadata.md4ExchangeAccountNumber ? (
              <Flex align="center" justify="flex-start">
                <Icon
                  as={RiCheckboxCircleFill}
                  w="16px"
                  h="16px"
                  color={
                    price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount
                      ? 'gold'
                      : ''
                  }
                />
                <Text ml={2} fontSize="sm" fontWeight="normal">
                  {price.product.metadata.md4ExchangeAccountNumber}
                </Text>
              </Flex>
            ) : (
              ''
            )}
          </Flex> */}
          <Flex
            align="flex-start"
            justify="flex-start"
            width="full"
            fontSize="md"
            mb={price.product.metadata.md5Crypto ? 3 : 0}
          >
            {price.product.metadata.md5Crypto ? (
              <Flex align="center" justify="flex-start">
                {/* <Icon
                  as={RiCheckboxCircleFill}
                  w="16px"
                  h="16px"
                  color={
                    price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount
                      ? 'gold'
                      : ''
                  }
                /> */}
                <Text fontSize="sm" fontWeight="bold">
                  {price.product.metadata.md5Crypto}
                </Text>
              </Flex>
            ) : (
              ''
            )}
          </Flex>

          {/* <Flex
            align="flex-start"
            justify="flex-start"
            width="full"
            fontSize="md"
            mt={price.product.metadata.md6social ? 3 : 0}
          >
            {price.product.metadata.md6social ? (
              <Flex align="center" justify="flex-start">
                <Icon
                  as={RiCheckboxCircleFill}
                  w="16px"
                  h="16px"
                  color={
                    price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount
                      ? 'gold'
                      : ''
                  }
                />
                <Text ml={2} fontSize="sm" fontWeight="normal">
                  {price.product.metadata.md6social}
                </Text>
              </Flex>
            ) : null}
          </Flex> */}

          <Flex
            align="flex-start"
            justify="flex-start"
            width="full"
            fontSize="md"
            mb={price.product.metadata.md7FxExchange ? 3 : 0}
          >
            {price.product.metadata.md7FxExchange ? (
              <Flex align="center" justify="flex-start">
                <Icon
                  as={RiCheckboxCircleFill}
                  w="16px"
                  h="16px"
                  color={
                    price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount
                      ? 'gold'
                      : ''
                  }
                />
                <Text ml={2} fontSize="sm" fontWeight="normal">
                  {price.product.metadata.md7FxExchange}
                </Text>
              </Flex>
            ) : null}
          </Flex>
          <Flex align="flex-start" justify="flex-start" width="full" fontSize="md" mb={3}>
            {price.product.metadata.md8FxBotCount ? (
              <Flex align="center" justify="flex-start">
                <Icon
                  as={RiCheckboxCircleFill}
                  w="16px"
                  h="16px"
                  color={
                    price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount
                      ? 'gold'
                      : ''
                  }
                />
                <Text ml={2} fontSize="sm" fontWeight="normal">
                  {price.product.metadata.md8FxBotCount}
                </Text>
              </Flex>
            ) : null}
          </Flex>
          <Flex align="flex-start" justify="flex-start" width="full" fontSize="md">
            {price.product.metadata.md9FxMaxMargin ? (
              <Flex align="center" justify="flex-start">
                <Icon
                  as={RiCheckboxCircleFill}
                  w="16px"
                  h="16px"
                  color={
                    price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount
                      ? 'gold'
                      : ''
                  }
                />
                <Text ml={2} fontSize="sm" fontWeight="normal">
                  {price.product.metadata.md9FxMaxMargin}
                </Text>
              </Flex>
            ) : null}
          </Flex>
        </Flex>
        <Button
          // colorScheme="messenger"
          //   bg={isCurrentPlan ? 'green' : 'messenger'}
          bg={
            price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount
              ? 'gold'
              : colorMode === 'light'
              ? 'black'
              : 'white'
          }
          color={
            price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount
              ? 'black'
              : colorMode === 'light'
              ? 'white'
              : 'black'
          }
          width="full"
          fontWeight="semibold"
          fontSize="sm"
          py={6}
          m={2}
          _hover={{
            bg:
              price.product.name === 'Premium' && customerCurrentSubscription.plan.amount < price.unit_amount
                ? 'white'
                : colorMode === 'light'
                ? 'black'
                : 'white'
          }}
          // onClick={onSubInfoOpen}
          onClick={() =>
            handleSubscription(price.id, stripeCustomerId, price.product.name, customerCurrentSubscription)
          }
          disabled={isSchedule || isCurrentPlan || (isPaidPlan && price.product.name.toUpperCase() === 'FREE')}
        >
          {/* Select Plan */}
          {determineButtonText()}
        </Button>
      </Flex>
    </Flex>
  );
}

export default withRouter(SubscriptionCard);
