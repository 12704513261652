import { format } from 'prettier';

export const setBaseCurrency = exchange => {
  if (exchange === 'Binance US') {
    return '$';
  } else if (exchange === 'Binance') {
    return 'USDT';
  } else if (exchange === 'Coinbase') {
    return '$';
  }
};

export const calculateExchangeID = exchange => {
  if (exchange === 'Total') {
    return 3;
  } else if (exchange === 'Binance US') {
    return 1;
  } else if (exchange === 'Coinbase') {
    return 2;
  } else if (exchange === 'Binance') {
    return 3;
  } else if (exchange === 'Oanda Demo') {
    return 5;
  } else if (exchange === 'Oanda Live') {
    return 6;
  }
};

export const getExchangeNames = (option, time = null) => {
  // console.log('getExchangeNames ');
  // console.log('option ', option);
  if (option === 'Binance US') {
    return 'binanceus';
  } else if (option === 'Binance' && time) {
    return 'binance';
  } else if (option === 'Binance') {
    return 'binancecom';
  } else if (option === 'Coinbase') {
    return 'coinbasepro';
  } else if (option === 'Oanda Demo') {
    return 'oandademo';
  } else if (option === 'Oanda Live') {
    return 'oandalive';
  }
};

// export const calculateBaseCurrencyIndex = (exchange, assets, key, idx) => {
//   console.log('calculateBaseCurrencyIndex');
//   if (exchange === 'Binance') {
//     return assets[key][idx].find(elem => elem.symbol.toLowerCase() === 'usdt');
//   }
//   return assets[key][idx].find(elem => elem.symbol.toLowerCase() === 'usd');
// };

export const calculateBaseCurrencyIndex = (exchange, assets, key, idx, target) => {
  // console.log(exchange, assets, key, idx, target);
  let index;
  for (let exc of assets) {
    if (Object.keys(exc).includes(getExchangeNames(exchange))) {
      index = assets.indexOf(exc);
    }
  }
  // console.log(
  //   'index ',
  //   index,
  //   getExchangeNames(exchange),
  //   assets[index][idx].find(elem => elem.symbol.toLowerCase() === target)
  // );
  if (exchange === 'Binance') {
    return assets[index][idx].find(elem => elem.symbol.toLowerCase() === target);
  }
  return assets[index][idx].find(elem => elem.symbol.toLowerCase() === target);
};
