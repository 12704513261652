import { WarningTwoIcon } from '@chakra-ui/icons';
import { RepeatIcon } from '@chakra-ui/icons';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  chakra,
  Collapse,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Switch,
  Tag,
  TagLabel,
  Text,
  useColorMode,
  useDisclosure
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaGlasses } from 'react-icons/fa';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import validator from 'validator';

import rules from '../../constants/rules.json';
import { addBot, getDescriptionTexts } from '../../store/actions/bot';
import { epicFlowGradientColors } from '../../theme';
import axios from '../../utilities/axios';
import {
  convertToInternationalCurrencySystem,
  dynamicCurrencyFormatter,
  usdFormatter
} from '../../utilities/currencyConverter';
import { calculateBaseCurrencyIndex, calculateExchangeID, setBaseCurrency } from '../../utilities/exchangeHelpers';
import { handleRestriction } from '../../utilities/rules';
import {
  dispatchSetPairs,
  getPairsDetails,
  setExchanges,
  setTargets
} from '../../utilities/wizardMenuItemsHelper';
import GraphDemo from '../modals/graphDemo';
import UpgradeSub from '../modals/upgradeSub';
import EFButton from '../UI/Button/efButton';
import DropDown from '../UI/Dropdown/dropdown';
import AdvancedInput from '../UI/Input/advancedInput';
import FormattedInput from '../UI/Input/formattedInput';
import AdvancedSlider from '../UI/Slider/advancedSlider';
import PriceSlider from '../UI/Slider/priceSlider';
import AutoInput from './autoInput';
import { fxDirections } from './menuItems';

const FxAutomationWizard = props => {
  const [botStyle, setBotStyle] = useState(fxDirections[0]);

  const [botName, setBotName] = useState('');

  const { colorMode, toggleColorMode } = useColorMode();

  const [nameErrorMessage, setNameErrorMessage] = useState(false);

  const [unitAdditionError, setUnitAdditionError] = useState(false);

  const [stopLimitBufferError, setStopLimitBufferError] = useState(false);

  const [maxMarginUtilizationError, setMaxMarginUtilizationError] = useState(false);

  const [mode, setMode] = useState('creation');

  const [maxMarginUtilization, setMaxMarginUtilization] = useState('');

  const [errors, setErrors] = useState([]);

  const [fxPairs, setFxPairs] = useState([]);

  const [stopLimitBuffer, setStopLimitBuffer] = useState();

  const [stopLimitGranularity, setStopLimitGranularity] = useState('');

  const [profitPriceConstant, setProfitPriceConstant] = useState();

  const [initialUnits, setInitialUnits] = useState('');

  const [unitAddition, setUnitAddition] = useState('');

  const [unitMultiplier, setUnitMultiplier] = useState('');

  const [additionalOrderStep, setAdditionalOrderStep] = useState('');

  const [windowHeight, setWindowHeight] = useState();

  const [profitEntery, setProfitEntery] = useState('');

  const [forceOrderTimer, setForceOrderTimer] = useState();

  const authState = useSelector(state => state.auth);
  const botState = useSelector(state => state.bot);
  const userState = useSelector(state => state.user);
  const profileState = useSelector(state => state.profile);

  const [exchange, setExchange] = useState(localStorage.getItem('exchange_default'));

  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  const [target, setTarget] = useState(
    setTargets(localStorage.getItem('exchange_default'), userState.assetData)[0]
  );

  const [pair, setPair] = useState();

  const [pairs, setPairs] = useState();

  const dispatch = useDispatch();

  const { isOpen, onToggle } = useDisclosure();

  const { isOpen: isPopOpen, onToggle: onPopToggle, onClose } = useDisclosure();

  const { isOpen: isGraphDemoOpen, onToggle: onGraphDemoToggle, onClose: onGraphDemoClose } = useDisclosure();

  const { isOpen: isOrderPopOpen, onToggle: onOrderPopToggle, onClose: onOrderPopClose } = useDisclosure();

  const { isOpen: isUpgradeSubOpen, onOpen: onUpgradeSubOpen, onClose: onUpgradeSubClose } = useDisclosure();

  const handleUpgradeSubModalClose = () => {
    onUpgradeSubClose();
  };

  useEffect(async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    let body = {};
    let response = await axios.post(`${API_URL}get_fx_pairs`, body);
    let pairs = [];
    for (let pair of response.data) {
      pairs.push(pair);
    }
    setFxPairs(pairs);
    setPair(pairs[0]);
  }, []);

  //   const handleWizardRestrictions = rule => {
  //     const botCount = botState.activeBotsStats.length;
  //     if (rules[rule]) {
  //       // <=
  //       if (rules[rule].botCount <= botCount) {
  //         return true;
  //       }
  //     }
  //   };

  const handleDropDownPair = selected => {
    setPair(selected);
  };

  const handleDropDownBotStyle = selected => {
    setBotStyle(selected);
  };

  const handleNameChange = event => {
    setBotName(event.target.value);
  };

  const handlePreviewBot = () => {
    // return onUpgradeSubOpen();
    let nameerror = false;
    let maxerror = false;
    let uniterror = false;
    let buffererror = false;
    if (botName === '') {
      nameerror = true;
    }
    if (maxMarginUtilization > userState.oandaData.marginAvailable) {
      maxerror = true;
    }
    if (unitAddition && unitAddition <= 0) {
      uniterror = true;
    }
    if (stopLimitBuffer < 5) {
      buffererror = true;
    }
    setStopLimitBufferError(buffererror);
    setNameErrorMessage(nameerror);
    setMaxMarginUtilizationError(maxerror);
    setUnitAdditionError(uniterror);
    if (!nameerror && !maxerror && !uniterror && !buffererror) {
      if (
        !handleRestriction(
          calculateExchangeID(authState.currentOption),
          authState.stripeCurrentPlan,
          Number(maxMarginUtilization)
            ? Number(maxMarginUtilization)
            : Number(Math.floor(userState.oandaData.marginAvailable / 10))
        )
      ) {
        return setMode('preview');
      } else {
        return onUpgradeSubOpen();
      }
    }
  };

  const handleEditButton = () => {
    // setTargets(authState.currentOption, userState.assetData);
    calculateProfitEntery();
    setMode('creation');
  };

  // const validateMargin = evt => {
  //   var theEvent = evt || window.event;

  //   // Handle paste
  //   if (theEvent.type === 'paste') {
  //     key = event.clipboardData.getData('text/plain');
  //   } else {
  //     // Handle key press
  //     var key = theEvent.keyCode || theEvent.which;
  //     key = String.fromCharCode(key);
  //   }
  //   var regex = /[0-9]|\./;
  //   if (!regex.test(key)) {
  //     theEvent.returnValue = false;
  //     if (theEvent.preventDefault) theEvent.preventDefault();
  //   }
  // };

  // const handleInput = (event, field, placeholder = '') => {
  //   let v = event.target.value;
  //   if (field === 'Bot Name') {
  //     setBotName(v);
  //   } else if (field === 'initial_units') {
  //     setInitialUnits(v);
  //   } else if (field === 'unit_multiplier') {
  //     setUnitMultiplier(v);
  //   } else if (field === 'stop_limit_buffer') {
  //     setStopLimitBuffer(v);
  //   } else if (field === 'stop_limit_granularity') {
  //     setStopLimitGranularity(v);
  //   } else if (field === 'unit_addition') {
  //     setUnitAddition(v);
  //   } else if (field === 'additional_order_step') {
  //     setAdditionalOrderStep(v);
  //   } else if (field === 'profit_price_constant') {
  //     setProfitPriceConstant(v);
  //   } else if (field === 'Max Margin Utilization') {
  //   }
  // };

  // const handleValue = (field, placeholder = '') => {
  //   if (field === 'Max Margin Utilization') {
  //     if (maxMarginUtilization === '') {
  //       return '';
  //     } else {
  //       return maxMarginUtilization;
  //     }
  //   } else if (field === 'initial_units') {
  //     if (initialUnits === '') {
  //       return '';
  //     } else {
  //       return initialUnits;
  //     }
  //   } else if (field === 'unit_multiplier') {
  //     if (unitMultiplier === '') {
  //       return '';
  //     } else {
  //       return unitMultiplier;
  //     }
  //   } else if (field === 'unit_addition') {
  //     if (unitAddition === '') {
  //       return '';
  //     } else {
  //       return unitAddition;
  //     }
  //   } else if (field === 'stop_limit_buffer') {
  //     if (stopLimitBuffer === '') {
  //       return '';
  //     } else {
  //       return stopLimitBuffer;
  //     }
  //   } else if (field === 'stop_limit_granularity') {
  //     if (stopLimitGranularity === '') {
  //       return '';
  //     } else {
  //       return stopLimitGranularity;
  //     }
  //   } else if (field === 'additional_order_step') {
  //     if (additionalOrderStep === '') {
  //       return '';
  //     } else {
  //       return additionalOrderStep;
  //     }
  //   } else if (field === 'profit_price_constant') {
  //     if (profitPriceConstant === '') {
  //       return '';
  //     } else {
  //       return profitPriceConstant;
  //     }
  //   }
  // };

  const handleDone = () => {
    setMode('creation');
    setBotName('');
    setPair(fxPairs[0]);
    setBotStyle('LONG');
    setIsSwitchChecked(false);
    setMaxMarginUtilization();
    setInitialUnits();
    setUnitAddition();
    setUnitMultiplier();
    setProfitPriceConstant();
    setAdditionalOrderStep();
    setStopLimitBuffer();
    setStopLimitGranularity();
    setForceOrderTimer();
  };

  useEffect(() => {
    if (!isPopOpen) {
      if (isOpen) {
        onToggle();
      }
    }
  }, [isPopOpen]);

  useEffect(() => {
    // console.log('EXCHANGE ====> ', exchange, localStorage.getItem('exchange_default'));
    // setExchange(localStorage.getItem('exchange_default'));
    // console.log(localStorage.getItem('exchange_default'), userState.assetData);
    if (setTargets(localStorage.getItem('exchange_default'), userState.assetData)[0]) {
      // console.log('if  ', setTargets(localStorage.getItem('exchange_default'), userState.assetData)[0]);
      setTarget(setTargets(localStorage.getItem('exchange_default'), userState.assetData)[0]);
    }
  }, [
    localStorage.getItem('exchange_default'),
    setTargets(localStorage.getItem('exchange_default'), userState.assetData)[0]
  ]);

  const handlePips = type => {
    let number = pair?.includes('JPY') ? type / 100 : type / 10000;
    return Number(number);
  };

  const handleCreateBot = async () => {
    let exchangeID;
    if (authState.currentOption === 'Oanda Demo') {
      exchangeID = 5;
    } else if (authState.currentOption === 'Oanda Live') {
      exchangeID = 6;
    }
    // This won't work now.

    //TODO
    let body = {
      botName: botName,
      stripeCustomerId: authState.stripeCustomerId,
      botStyle: botStyle,
      pair: pair,
      exchangeID: exchangeID,
      userID: authState.id || profileState.id,
      maxMarginUtilization: maxMarginUtilization
        ? Number(maxMarginUtilization)
        : Math.floor(userState.oandaData.marginAvailable / 10),
      initialUnits: initialUnits ? Number(initialUnits) : botState.fxSettings.initial_units.default,
      unitAddition: unitAddition ? Number(unitAddition) : botState.fxSettings.unit_addition.default,
      unitMultiplier: unitMultiplier ? Number(unitMultiplier) : botState.fxSettings.unit_multiplier.default,
      profitPriceConstant: profitPriceConstant
        ? handlePips(profitPriceConstant)
        : pair?.includes('JPY')
        ? botState.fxSettings.profit_price_constant.default * 100
        : botState.fxSettings.profit_price_constant.default,
      additionalOrderStep: additionalOrderStep
        ? handlePips(additionalOrderStep)
        : pair?.includes('JPY')
        ? botState.fxSettings.additional_order_step.default * 100
        : botState.fxSettings.additional_order_step.default,
      // stopLimitGranularity: stopLimitGranularity
      //   ? handlePips(stopLimitGranularity)
      //   : pair?.includes('JPY')
      //   ? botState.fxSettings.stop_limit_granularity.default * 100
      //   : botState.fxSettings.stop_limit_granularity.default,
      stopLimitBuffer: stopLimitBuffer
        ? handlePips(stopLimitBuffer)
        : pair?.includes('JPY')
        ? botState.fxSettings.stop_limit_buffer.default * 100
        : botState.fxSettings.stop_limit_buffer.default,
      orderTimer: forceOrderTimer ? Number(forceOrderTimer) : botState.fxSettings.order_timer.default
    };
    dispatch(addBot(body));
  };

  useEffect(() => {
    if (mode !== 'creation') {
      if (botState.addBotStatus) {
        setMode('done');
      }
    }
  }, [botState.addBotStatus]);

  // useEffect(() => {
  //   if (botState.fxSettings) {
  //     setInitialUnits(botState.fxSettings.initial_units.default);
  //     setUnitAddition(botState.fxSettings.unit_addition.default);
  //     setUnitMultiplier(botState.fxSettings.unit_multiplier.default);
  //     setProfitPriceConstant(botState.fxSettings.profit_price_constant.default);
  //     setAdditionalOrderStep(botState.fxSettings.additional_order_step.default);
  //     setStopLimitBuffer(botState.fxSettings.stop_limit_buffer.default);
  //     setStopLimitGranularity(botState.fxSettings.stop_limit_granularity.default);
  //   }
  // }, [botState.fxSettings]);

  const handleToggler = e => {
    if (e.target.checked) {
      setIsSwitchChecked(true);
    } else {
      setIsSwitchChecked(false);
    }
  };

  // useEffect(() => {
  //   if (!isSwitchChecked) {
  //     if (botState.fxSettings) {
  //       setInitialUnits(botState.fxSettings.initial_units.default);
  //       setUnitAddition(botState.fxSettings.unit_addition.default);
  //       setUnitMultiplier(botState.fxSettings.unit_multiplier.default);
  //       setProfitPriceConstant(botState.fxSettings.profit_price_constant.default);
  //       setAdditionalOrderStep(botState.fxSettings.additional_order_step.default);
  //       setStopLimitBuffer(botState.fxSettings.stop_limit_buffer.default);
  //       setStopLimitGranularity(botState.fxSettings.stop_limit_granularity.default);
  //     }
  //   }
  // }, [isSwitchChecked]);

  const renderError = () => {
    if (nameErrorMessage) {
      return (
        <Text fontSize="0.8125rem" color="red">
          Please enter required fields
        </Text>
      );
    } else if (maxMarginUtilizationError) {
      return (
        <Text fontSize="0.8125rem" color="red">
          Not enough Margin
        </Text>
      );
    } else if (unitAdditionError) {
      return (
        <Text fontSize="0.8125rem" color="red">
          Order Increment should be more than 0.
        </Text>
      );
    } else if (stopLimitBufferError) {
      return (
        <Text fontSize="0.8125rem" color="red">
          Profit Buffer should be 5 or more pips.
        </Text>
      );
    }
  };

  const calculateProfitEntery = () => {
    let ppc = pair?.includes('JPY')
      ? botState.fxSettings?.profit_price_constant.default * 10000
      : botState.fxSettings?.profit_price_constant.default * 10000;
    let slb = pair?.includes('JPY')
      ? botState.fxSettings?.stop_limit_buffer.default * 10000
      : botState.fxSettings?.stop_limit_buffer.default * 10000;
    if (!profitPriceConstant && !stopLimitBuffer) {
      setProfitEntery(Number(ppc) + Number(slb));
    } else if (!profitPriceConstant && stopLimitBuffer) {
      setProfitEntery(Number(ppc) + Number(stopLimitBuffer));
    } else if (profitPriceConstant && !stopLimitBuffer) {
      setProfitEntery(Number(profitPriceConstant) + Number(slb));
    } else if (profitPriceConstant && stopLimitBuffer) {
      setProfitEntery(Number(profitPriceConstant) + Number(stopLimitBuffer));
    }
  };

  useEffect(() => {
    calculateProfitEntery();
  }, [pair, botState.fxSettings, profitPriceConstant, stopLimitBuffer]);

  const handlePipsForPreview = type => {
    let number = pair?.includes('JPY') ? type * 10000 : type * 10000;
    return Number(number);
  };

  // const calculateProfitEntery = () => {
  //   let ppc = pair?.includes('JPY')
  //     ? botState.fxSettings?.profit_price_constant.default * 100
  //     : botState.fxSettings?.profit_price_constant.default * 10000;
  //   let slb = pair?.includes('JPY')
  //     ? botState.fxSettings?.stop_limit_buffer.default * 100
  //     : botState.fxSettings?.stop_limit_buffer.default * 10000;
  //   if (profitPriceConstant === '' && stopLimitBuffer === '') {
  //     console.log('if ', ppc + slb);
  //     return ppc + slb;
  //   } else if (profitPriceConstant === '' && stopLimitBuffer !== '') {
  //     console.log('else if ', ppc + stopLimitBuffer);
  //     return ppc + stopLimitBuffer;
  //   } else if (profitPriceConstant !== '' && stopLimitBuffer === '') {
  //     console.log('else if ', profitPriceConstant + slb);
  //     return profitPriceConstant + slb;
  //   }
  // };

  const renderDynamicContent = () => {
    if (mode === 'creation') {
      return (
        <Flex direction="column" w="90%">
          <DropDown
            menuitems={fxPairs}
            selected={handleDropDownPair}
            functional
            py="18px"
            value={pair}
            // searchable
            autoHeight={fxPairs.length <= 7}
            title="Currency Pair"
            showTitle
            fontSize="0.8125rem"
            mtop={4}
            _hover={{
              backgroundColor: colorMode === 'light' ? 'white' : 'gray.900',
              borderColor: colorMode === 'light' ? 'gray.400' : 'gray.800'
            }}
          />
          {userState.oandaData?.instruments?.[pair]?.marginRate && (
            <Flex justifyContent="flex-end" alignItems="center" w="full">
              <Text fontSize="11px" fontWeight="medium" mt={1} color="gray.500">
                {`Leverage  ${(1 / Number(userState.oandaData?.instruments[pair]?.marginRate)).toFixed(0)}:1`}
              </Text>
            </Flex>
          )}
          {/* <AutoInput
            title="Max Margin Utilization"
            onKeyPress={validate}
            handleChange={handleChange}
            value={investmentAmount}
            type="number"
            width="9rem"
            bc={amountErrorMessage || minimumAmountError ? 'red' : null}
            placeholder={placeholder}
            textAlign="right"
            amount={`Max ~${adjustableTargetFreeAmount.toFixed(6)}`}
            target={target}
            isAmount
            base={exchange === 'Binance US' || exchange === 'Coinbase' ? 'USD' : 'USDT'}
            mb={5}
            mt={4}
          /> */}
          {/* <PriceSlider
            mb={5}
            sliderColor={props.sliderColor}
            bg={props.bg}
            getSliderValue={val => setSliderValue(val)}
          /> */}
          <DropDown
            menuitems={fxDirections}
            selected={handleDropDownBotStyle}
            py="18px"
            functional
            autoHeight={fxDirections.length <= 7}
            value={botStyle}
            title="Position Direction"
            w="9rem"
            fontSize="0.8125rem"
            showTitle
            mtop={4}
            _hover={{
              backgroundColor: colorMode === 'light' ? 'white' : 'gray.900',
              borderColor: colorMode === 'light' ? 'gray.400' : 'gray.800'
            }}
          />
          {/* <Text w="full" textAlign="left" fontSize="0.8125rem" lineHeight="1.2rem" mt={4} mb={4}>
            {botStyle === 'Conservative'
              ? 'Less frequent trades. Lower risk bot.'
              : botStyle === 'Moderate'
              ? ' Moderate trade frequency. Balanced investment approach.'
              : 'Frequent trade activity. High risk potential.'}
            <Link as={RouterLink} color={props.bg} fontWeight="semibold" to="/help">
              <br></br>
              Learn more.
            </Link>
          </Text> */}
          <AutoInput
            title="Bot Name"
            width="9rem"
            placeholder="Awesome Bot"
            textAlign="left"
            bc={nameErrorMessage ? 'red' : null}
            mb={3}
            mt={4}
            value={botName}
            handleChange={handleNameChange}
          />
          {/* <AutoInput
            title="Max Margin to Use"
            min={1}
            onKeyPress={validateMargin}
            onPaste={e => e.preventDefault()}
            // type="number"
            maxMargin={`( Available Margin: ${usdFormatter.format(userState.oandaData.marginAvailable)} )`}
            width="9rem"
            placeholder=""
            textAlign="left"
            bc={maxMarginUtilization > userState.oandaData.marginAvailable ? 'red' : null}
            mb={3}
            mt={3}
            value={handleDollarSign(maxMarginUtilization)}
            handleChange={e => handleInput(e, 'Max Margin Utilization')}
          /> */}
          <Flex direction="column" w="full" justify="flex-start" align="center" mt={3} mb={3}>
            <FormattedInput
              title="Max Margin to Use"
              style={{
                outline: 'none',
                borderColor:
                  maxMarginUtilizationError || Number(maxMarginUtilization) > userState.oandaData.marginAvailable
                    ? 'red'
                    : ''
              }}
              prefix=""
              popoverDescription="Maximum Margin to Use represents the maximum dollar amount of 
              available capital that can be utilized within a single bot 
              session (run). Once this limit is reached, the bot suspends 
              additional unit purchases while actively seeking a successful 
              exit strategy. This parameter serves as a risk management tool, 
              preventing overexposure of capital while allowing the bot to 
              continue executing trades within the established dollar limit. 
              By pausing further unit acquisitions, the bot maintains liquidity and 
              flexibility to optimize its exit strategy, safeguarding the trader's 
              investment while striving for profitable outcomes in the forex market.
               Adjusting this parameter enables traders to customize their bot's 
               behavior to their risk tolerance and trading objectives, ensuring 
               responsible capital allocation and prudent risk management."
              popoverText="The predetermined maximum dollar amount of capital allocated
               within a single bot run, pausing further unit purchases while seeking 
               a successful exit strategy."
              popoverHeader="Maximum Margin to Use"
              popover={props.fixed}
              value={maxMarginUtilization}
              placeholder={dynamicCurrencyFormatter(
                userState.baseCurrency ? userState.baseCurrency : 'USD'
              ).format(Math.floor(userState.oandaData.marginAvailable / 10))}
              onValueChange={(value, name, values) => {
                setMaxMarginUtilization(value);
              }}
            />

            <Flex justifyContent="flex-end" alignItems="center" w="full">
              <Text fontSize="11px" fontWeight="medium" mt={1} color="gray.500">
                {`( Available Margin: ${dynamicCurrencyFormatter(
                  userState.baseCurrency ? userState.baseCurrency : 'USD'
                ).format(userState.oandaData.marginAvailable)} )`}
              </Text>
            </Flex>
          </Flex>
          <Flex direction="column" w="full" justify="flex-start" align="center" mt={3} mb={3}>
            <FormattedInput
              title="Base Order"
              // style={{
              //   outline: 'none',
              //   borderColor:
              //     maxMarginUtilizationError || Number(maxMarginUtilization) > userState.oandaData.marginAvailable
              //       ? 'red'
              //       : '#E2E8F0'
              // }}
              suffix=" units"
              popoverDescription=" The Base Order parameter determines the 
              quantity of currency units to be initially bought by the forex 
              trading bot. It is essential to scale this value according to 
              the predefined maximum margin utilization to ensure responsible
               risk management. By adjusting the initial order size based on 
               the specified maximum margin, traders can maintain a balanced
                approach to capital allocation within their trading strategy. 
                This ensures that the bot initiates trades with an appropriate
                 volume of units relative to the allocated margin, optimizing 
                 the potential for profitable outcomes while mitigating undue 
                 risk exposure. Adjusting this parameter enables traders to 
                 fine-tune their bot's trading behavior in alignment with 
                 their risk tolerance and investment objectives."
              popoverText="The quantity of currency units to be purchased 
              as the first transaction, scaled proportionally based on the 
              predetermined maximum margin to use."
              popoverHeader="Base Order"
              popover={props.fixed}
              value={initialUnits}
              placeholder={`${botState.fxSettings?.initial_units.default} units`}
              onValueChange={(value, name, values) => {
                setInitialUnits(value);
              }}
            />
            {userState.oandaData?.instruments[pair]?.currentPrice && (
              <Flex justifyContent="flex-end" alignItems="center" w="full">
                <Text fontSize="11px" fontWeight="medium" mt={1} color="gray.500">
                  {pair.split('_')[0] === 'USD'
                    ? `${
                        initialUnits ? initialUnits : botState.fxSettings?.initial_units.default
                      } units = ${dynamicCurrencyFormatter(
                        userState.baseCurrency ? userState.baseCurrency : 'USD'
                      ).format(
                        (initialUnits ? Number(initialUnits) : botState.fxSettings?.initial_units.default) /
                          (1 / userState.oandaData?.instruments[pair].marginRate)
                      )}`
                    : pair === 'CAD_CHF' || pair == 'CAD_JPY'
                    ? `${
                        initialUnits ? initialUnits : botState.fxSettings?.initial_units.default
                      } units = ${dynamicCurrencyFormatter(
                        userState.baseCurrency ? userState.baseCurrency : 'USD'
                      ).format(
                        (
                          ((initialUnits ? Number(initialUnits) : botState.fxSettings?.initial_units.default) /
                            (1 / userState.oandaData?.instruments[pair].marginRate)) *
                          (1 / userState.oandaData?.instruments[`USD_CAD`].currentPrice)
                        ).toFixed(2)
                      )}`
                    : `${
                        initialUnits ? initialUnits : botState.fxSettings?.initial_units.default
                      } units = ${dynamicCurrencyFormatter(
                        userState.baseCurrency ? userState.baseCurrency : 'USD'
                      ).format(
                        (
                          ((initialUnits ? Number(initialUnits) : botState.fxSettings?.initial_units.default) /
                            (1 / userState.oandaData?.instruments[pair].marginRate)) *
                          userState.oandaData?.instruments[`${pair.split('_')[0]}_USD`].currentPrice
                        ).toFixed(2)
                      )}`}
                </Text>
              </Flex>
            )}
          </Flex>
          <FormattedInput
            title="Next Order Trigger"
            suffix=" pips"
            popoverDescription="The Next Order Trigger parameter determines 
            the incremental movement in pips required for the forex trading
             bot to execute another order and purchase additional units. 
             In a Long position, this value represents the number of pips 
             the market must move upwards before initiating another order. 
             Conversely, in a Short position, it signifies the number of 
             pips the market must move downwards. This step size facilitates 
             a systematic approach to scaling into positions, allowing the
              bot to incrementally enter trades in response to favorable 
              market movements while minimizing the risk of premature 
              accumulation. Traders can adjust this parameter to tailor their 
              bot's behavior to different market conditions and optimize 
              performance in alignment with their trading strategies and 
              risk management preferences."
            popoverText="Specifies the number of pips the market must move, 
            either upwards (Long position) or downwards (Short position), 
            before the bot purchases additional units."
            popoverHeader="Next Order Trigger"
            popover={props.fixed}
            placeholder={`${
              pair?.includes('JPY')
                ? botState.fxSettings?.additional_order_step.default * 10000
                : botState.fxSettings?.additional_order_step.default * 10000
            } pips`}
            value={additionalOrderStep}
            onValueChange={(value, name, values) => {
              setAdditionalOrderStep(value);
            }}
          />
          <FormattedInput
            mt={3}
            title="Order Increment"
            suffix=" units"
            style={{
              outline: 'none',
              borderColor: unitAdditionError ? 'red' : ''
            }}
            popoverDescription="The Order Increment parameter remains 
            constant throughout the trading session, dictating the fixed 
            increase in units added to each successive order. For instance,
            if the initial order is set at 1000 units and the order increment
             is defined as 10 units, each subsequent buy or sell order will 
             incrementally increase by 10 units. Therefore, the next order 
             after the initial one will be for 1010 units, followed by 1020
              units, and so forth. This unchanging incrementation ensures 
              a predictable scaling of position size, maintaining a 
              consistent approach to adding units to the position over time. 
              Traders can adjust the order increment to achieve their 
              desired position sizing and effectively manage risk within 
              the forex trading bot."
            popoverHeader="Order Increment"
            popoverText="Specifies a constant value representing the 
            incremental increase in units added to each subsequent order, 
            ensuring a consistent scaling strategy."
            popover={props.fixed}
            placeholder={`${botState.fxSettings?.unit_addition.default} unit`}
            value={unitAddition}
            onValueChange={(value, name, values) => {
              setUnitAddition(value);
            }}
          />
          {userState.oandaData && (
            <Flex
              mt={4}
              borderWidth={1}
              borderRadius="lg"
              justifyContent="flex-start"
              py={3}
              alignItems="center"
              w="full"
              flexDirection="column"
            >
              {/* <Text fontSize="11px" fontWeight="medium" mt={1} color="gray.500">
              With this settings your orders will be:
            </Text>
            <Text fontSize="11px" fontWeight="medium" mt={1} color="gray.500">
              {initialUnits} {`${'( First Order ) --->'}`} {initialUnits + unitAddition}{' '}
              {`${'( Second Order ) --->'}`} {initialUnits + 2 * unitAddition}
              {`${'( Third Order ) ...'}`}
            </Text> */}
              <Flex w="full" justifyContent="center" alignItems="center">
                <Text fontSize="13px">Order Preview</Text>
                {props.fixed && (
                  <Popover
                    preventOverflow={false}
                    flip={false}
                    placement="left-start"
                    isOpen={isOrderPopOpen}
                    onClose={onOrderPopClose}
                  >
                    <PopoverTrigger>
                      <IconButton
                        p={0}
                        m={0}
                        // size="sm"
                        onClick={onOrderPopToggle}
                        w="14px"
                        maxWidth="14px"
                        minWidth="14px"
                        h="14px"
                        borderWidth={1}
                        bg="transparent"
                        ml={2}
                        _hover={{
                          background: 'transparent'
                        }}
                        _active={{
                          background: 'transparent'
                        }}
                        _focus={{
                          background: 'transparent'
                        }}
                        aria-label="Info"
                        icon={<InfoOutlineIcon boxSize="14px" p={0} color="gray.500" />}
                      />
                    </PopoverTrigger>
                    <PopoverContent
                      // css={{
                      //   '&::-webkit-scrollbar': {
                      //     width: '1px'
                      //   },
                      //   '&::-webkit-scrollbar-track': {
                      //     width: '1px'
                      //   },
                      //   '&::-webkit-scrollbar-thumb': {
                      //     width: '1px'
                      //   }
                      // }}
                      // maxH={96}
                      // overflowY="scroll"
                      bg={colorMode === 'light' ? 'white' : '#1f2124'}
                      _focus={{ outline: 'none' }}
                      w={72}
                      pb={4}
                    >
                      <PopoverHeader
                        display="flex"
                        borderTopLeftRadius="base"
                        borderTopRightRadius="base"
                        textAlign="center"
                        fontWeight="semibold"
                        fontSize="64px"
                        alignItems="center"
                        justifyContent="center"
                        border={0}
                        h={28}
                      >
                        🔭
                      </PopoverHeader>
                      <PopoverHeader mb={2} textAlign="center" fontWeight="semibold" fontSize="15px" border={0}>
                        Order Preview
                      </PopoverHeader>
                      {/* <PopoverArrow /> */}
                      {/* <PopoverCloseButton /> */}
                      <PopoverBody
                        fontSize="13px"
                        pt={0}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        Our Order Preview offers a visual exploration of how orders evolve based on specific
                        parameters. By inputting base order size, next order trigger, and order increments, users
                        can witness the dynamic calculation of subsequent orders. For example, starting with a base
                        order of 1000 units, each movement of 10 pips triggers an increase of 100 units in
                        subsequent orders. This demo provides a tangible demonstration of our algorithm adjusts
                        order sizes in response to market movements. With clear visuals and step-by-step
                        calculations, users can grasp the mechanics behind order dynamics and make informed
                        decisions about their trading strategies.
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                )}
                {/* <Text>👁️‍🗨️</Text> */}
                {/* <Icon mb="2px" as={FaGlasses} boxSize={4} /> */}
              </Flex>
              <Flex w="full" mt={3}>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="bold"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  Order No
                </Text>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="bold"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  Trigger
                </Text>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="bold"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  Price
                </Text>
                <Text
                  flex={1}
                  textAlign="center"
                  fontSize="11px"
                  fontWeight="bold"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  Order Size
                </Text>
              </Flex>
              <Flex w="full" mt={2}>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  #1
                </Text>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  N/A
                </Text>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  {pair?.includes('JPY')
                    ? `~${new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: userState.baseCurrency ? userState.baseCurrency : 'USD',
                        // These options are needed to round to whole numbers if that's what you want.
                        minimumFractionDigits: 3 // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                      }).format(userState.oandaData?.instruments[pair]?.currentPrice.toFixed(3))}`
                    : `~${new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: userState.baseCurrency ? userState.baseCurrency : 'USD',
                        // These options are needed to round to whole numbers if that's what you want.
                        minimumFractionDigits: 5 // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                      }).format(userState.oandaData?.instruments[pair]?.currentPrice.toFixed(5))}`}
                </Text>
                <Text
                  flex={1}
                  textAlign="center"
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  {initialUnits ? Number(initialUnits) : botState.fxSettings?.initial_units.default} units
                </Text>
              </Flex>
              <Flex w="full" mt={2}>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  #2
                </Text>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  {additionalOrderStep
                    ? additionalOrderStep
                    : pair?.includes('JPY')
                    ? botState.fxSettings?.additional_order_step.default * 10000
                    : botState.fxSettings?.additional_order_step.default * 10000}{' '}
                  pips
                </Text>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  ~
                  {botStyle === 'LONG'
                    ? pair?.includes('JPY')
                      ? new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: userState.baseCurrency ? userState.baseCurrency : 'USD',
                          // These options are needed to round to whole numbers if that's what you want.
                          minimumFractionDigits: 3 // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                        }).format(
                          (
                            (Number(
                              String(userState.oandaData?.instruments[pair]?.currentPrice.toFixed(3))
                                .split('.')
                                .join('')
                            ) -
                              (additionalOrderStep
                                ? 10 * Number(additionalOrderStep)
                                : pair?.includes('JPY')
                                ? Number(botState.fxSettings?.additional_order_step.default * 100000)
                                : Number(botState.fxSettings?.additional_order_step.default * 100000))) /
                            1000
                          ).toFixed(3)
                        )
                      : new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: userState.baseCurrency ? userState.baseCurrency : 'USD',
                          // These options are needed to round to whole numbers if that's what you want.
                          minimumFractionDigits: 5 // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                        }).format(
                          (
                            (Number(
                              String(userState.oandaData?.instruments[pair]?.currentPrice.toFixed(5))
                                .split('.')
                                .join('')
                            ) -
                              (additionalOrderStep
                                ? 10 * Number(additionalOrderStep)
                                : pair?.includes('JPY')
                                ? Number(botState.fxSettings?.additional_order_step.default * 100000)
                                : Number(botState.fxSettings?.additional_order_step.default * 100000))) /
                            100000
                          ).toFixed(5)
                        )
                    : pair?.includes('JPY')
                    ? new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: userState.baseCurrency ? userState.baseCurrency : 'USD',
                        // These options are needed to round to whole numbers if that's what you want.
                        minimumFractionDigits: 3 // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                      }).format(
                        (
                          (Number(
                            String(userState.oandaData?.instruments[pair]?.currentPrice.toFixed(3))
                              .split('.')
                              .join('')
                          ) +
                            (additionalOrderStep
                              ? 10 * Number(additionalOrderStep)
                              : pair?.includes('JPY')
                              ? Number(botState.fxSettings?.additional_order_step.default * 100000)
                              : Number(botState.fxSettings?.additional_order_step.default * 100000))) /
                          1000
                        ).toFixed(3)
                      )
                    : new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: userState.baseCurrency ? userState.baseCurrency : 'USD',
                        // These options are needed to round to whole numbers if that's what you want.
                        minimumFractionDigits: 5 // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                      }).format(
                        (
                          (Number(
                            String(userState.oandaData?.instruments[pair]?.currentPrice.toFixed(5))
                              .split('.')
                              .join('')
                          ) +
                            (additionalOrderStep
                              ? 10 * Number(additionalOrderStep)
                              : pair?.includes('JPY')
                              ? Number(botState.fxSettings?.additional_order_step.default * 100000)
                              : Number(botState.fxSettings?.additional_order_step.default * 100000))) /
                          100000
                        ).toFixed(5)
                      )}
                </Text>
                <Text
                  flex={1}
                  textAlign="center"
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  {initialUnits
                    ? Number(initialUnits) +
                      (unitAddition ? Number(unitAddition) : Number(botState.fxSettings?.unit_addition.default))
                    : Number(botState.fxSettings?.initial_units.default) +
                      (unitAddition
                        ? Number(unitAddition)
                        : Number(botState.fxSettings?.unit_addition.default))}{' '}
                  units
                </Text>
              </Flex>
              <Flex w="full" mt={2}>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  #3
                </Text>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  {additionalOrderStep
                    ? additionalOrderStep
                    : pair?.includes('JPY')
                    ? botState.fxSettings?.additional_order_step.default * 10000
                    : botState.fxSettings?.additional_order_step.default * 10000}{' '}
                  pips
                </Text>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  ~
                  {botStyle === 'LONG'
                    ? pair?.includes('JPY')
                      ? new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: userState.baseCurrency ? userState.baseCurrency : 'USD',
                          // These options are needed to round to whole numbers if that's what you want.
                          minimumFractionDigits: 3 // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                        }).format(
                          (
                            (Number(
                              String(userState.oandaData?.instruments[pair]?.currentPrice.toFixed(3))
                                .split('.')
                                .join('')
                            ) -
                              (additionalOrderStep
                                ? 20 * Number(additionalOrderStep)
                                : pair?.includes('JPY')
                                ? 2 * Number(botState.fxSettings?.additional_order_step.default * 100000)
                                : 2 * Number(botState.fxSettings?.additional_order_step.default * 100000))) /
                            1000
                          ).toFixed(3)
                        )
                      : new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: userState.baseCurrency ? userState.baseCurrency : 'USD',
                          // These options are needed to round to whole numbers if that's what you want.
                          minimumFractionDigits: 5 // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                        }).format(
                          (
                            (Number(
                              String(userState.oandaData?.instruments[pair]?.currentPrice.toFixed(5))
                                .split('.')
                                .join('')
                            ) -
                              (additionalOrderStep
                                ? 20 * Number(additionalOrderStep)
                                : pair?.includes('JPY')
                                ? 2 * Number(botState.fxSettings?.additional_order_step.default * 100000)
                                : 2 * Number(botState.fxSettings?.additional_order_step.default * 100000))) /
                            100000
                          ).toFixed(5)
                        )
                    : pair?.includes('JPY')
                    ? new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: userState.baseCurrency ? userState.baseCurrency : 'USD',
                        // These options are needed to round to whole numbers if that's what you want.
                        minimumFractionDigits: 3 // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                      }).format(
                        (
                          (Number(
                            String(userState.oandaData?.instruments[pair]?.currentPrice.toFixed(3))
                              .split('.')
                              .join('')
                          ) +
                            (additionalOrderStep
                              ? 20 * Number(additionalOrderStep)
                              : pair?.includes('JPY')
                              ? 2 * Number(botState.fxSettings?.additional_order_step.default * 100000)
                              : 2 * Number(botState.fxSettings?.additional_order_step.default * 100000))) /
                          1000
                        ).toFixed(3)
                      )
                    : new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: userState.baseCurrency ? userState.baseCurrency : 'USD',
                        // These options are needed to round to whole numbers if that's what you want.
                        minimumFractionDigits: 5 // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                      }).format(
                        (
                          (Number(
                            String(userState.oandaData?.instruments[pair]?.currentPrice.toFixed(5))
                              .split('.')
                              .join('')
                          ) +
                            (additionalOrderStep
                              ? 20 * Number(additionalOrderStep)
                              : pair?.includes('JPY')
                              ? 2 * Number(botState.fxSettings?.additional_order_step.default * 100000)
                              : 2 * Number(botState.fxSettings?.additional_order_step.default * 100000))) /
                          100000
                        ).toFixed(5)
                      )}
                  {/* {usdFormatter.format(userState.oandaData?.instruments[pair]?.currentPrice)} */}
                </Text>
                <Text
                  flex={1}
                  textAlign="center"
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  {initialUnits
                    ? Number(initialUnits) +
                      (unitAddition
                        ? 2 * Number(unitAddition)
                        : 2 * Number(botState.fxSettings?.unit_addition.default))
                    : Number(botState.fxSettings?.initial_units.default) +
                      (unitAddition
                        ? 2 * Number(unitAddition)
                        : 2 * Number(botState.fxSettings?.unit_addition.default))}{' '}
                  units
                </Text>
              </Flex>
              <Flex w="full" mt={2}>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  ...
                </Text>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  ...
                </Text>
                <Text
                  textAlign="center"
                  flex={1}
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  ...
                </Text>
                <Text
                  flex={1}
                  textAlign="center"
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  ...
                </Text>
              </Flex>
              <Flex w="95%" justifyContent="center" mt={1}>
                <Text
                  textAlign="left"
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  *
                </Text>
                <Text
                  textAlign="left"
                  fontSize="11px"
                  fontWeight="medium"
                  mt={1}
                  color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
                >
                  Please note that while this preview provides simulated order calculations, real initial prices
                  may vary due to market volatility.
                </Text>
              </Flex>
            </Flex>
          )}
          <GraphDemo isopen={isGraphDemoOpen} onclose={onGraphDemoClose} />
          {/* <Button
            _hover={{ bg: 'transparent' }}
            onClick={onGraphDemoToggle}
            _focus={{
              bg: 'transparent',
              outline: 'none'
            }}
            _active={{
              bg: 'transparent',
              outline: 'none'
            }}
            bg="transparent"
            w="full"
            mt={4}
            alignItems="center"
            borderRadius="md"
            borderWidth={1}
            p={2}
          >
            <Text fontSize="13px" mr={2}>
              Graphical Visualization
            </Text>
            <Tag variant="subtle" size="sm" colorScheme="green">
              <TagLabel>New</TagLabel>
            </Tag>
          </Button> */}
          <Flex
            w="full"
            justifyContent="center"
            alignItems="center"
            flexDir="column"
            borderWidth={1}
            position="relative"
            px={3}
            py={3}
            mt={8}
            borderRadius="md"
          >
            <Flex
              alignItems="center"
              bg={colorMode === 'light' ? 'white' : '#000'}
              position="absolute"
              top={-2.5}
              px={2}
            >
              <Text fontWeight="bold" fontSize="xs">
                Profit
              </Text>
              {props.fixed && (
                <Popover
                  preventOverflow={false}
                  flip={false}
                  placement="left-start"
                  isOpen={isPopOpen}
                  onClose={onClose}
                >
                  <PopoverTrigger>
                    <IconButton
                      p={0}
                      m={0}
                      // size="sm"
                      onClick={onPopToggle}
                      w="14px"
                      maxWidth="14px"
                      minWidth="14px"
                      h="14px"
                      borderWidth={1}
                      bg="transparent"
                      ml={2}
                      _hover={{
                        background: 'transparent'
                      }}
                      _active={{
                        background: 'transparent'
                      }}
                      _focus={{
                        background: 'transparent'
                      }}
                      aria-label="Info"
                      icon={<InfoOutlineIcon boxSize="14px" p={0} color="gray.500" />}
                    />
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent
                      // css={{
                      //   '&::-webkit-scrollbar': {
                      //     width: '1px'
                      //   },
                      //   '&::-webkit-scrollbar-track': {
                      //     width: '1px'
                      //   },
                      //   '&::-webkit-scrollbar-thumb': {
                      //     width: '1px'
                      //   }
                      // }}
                      // maxH={96}
                      // overflowY="scroll"
                      bg={colorMode === 'light' ? 'white' : 'gray.900'}
                      _focus={{ outline: 'none' }}
                      w={72}
                      py={4}
                    >
                      <PopoverHeader fontWeight="semibold" fontSize="15px" border={0}>
                        Profit Entry
                      </PopoverHeader>
                      {/* <PopoverArrow /> */}
                      {/* <PopoverCloseButton /> */}
                      <PopoverBody
                        fontSize="13px"
                        pt={0}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        Calculated threshold for initiating profit-taking, determined by adding the minimum profit
                        exit and profit buffer inputs. When this threshold is reached, the position transitions to
                        a stop loss with the bot dynamically adjusting the stop loss according to the profit
                        buffer.
                        <Button
                          onClick={onToggle}
                          fontSize="13px"
                          p={0}
                          h={6}
                          my={2}
                          bg="transparent"
                          _hover={{
                            background: 'transparent'
                          }}
                          _active={{
                            background: 'transparent'
                          }}
                          _focus={{
                            background: 'transparent'
                          }}
                          color={epicFlowGradientColors.left}
                        >
                          {isOpen ? 'Read Less' : 'Read More'}
                        </Button>
                        <Collapse in={isOpen} animateOpacity>
                          <Flex>
                            {`The Profit Entry is calculated by combining the minimum profit exit and profit buffer
                        inputs, serving as the threshold for triggering profit-taking actions within the forex
                        trading bot. When this threshold is reached, the bot initiates a position transition to a
                        stop loss, with the stop loss dynamically adjusted according to the profit buffer. This
                        mechanism allows for effective risk management while also capitalizing on upward market
                        momentum by riding a wave up the amount of buffer assigned. Traders can adjust the minimum
                        profit exit and profit buffer inputs to fine-tune the profit entry threshold according to
                        their trading strategy and risk management preferences within the bot's operations.`}
                          </Flex>
                        </Collapse>
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              )}
            </Flex>

            <FormattedInput
              mt={3}
              title="Min Profit Exit"
              suffix=" pips"
              value={profitPriceConstant}
              placeholder={`${
                pair?.includes('JPY')
                  ? botState.fxSettings?.profit_price_constant.default * 10000
                  : botState.fxSettings?.profit_price_constant.default * 10000
              } pips`}
              onValueChange={(value, name, values) => {
                setProfitPriceConstant(value);
              }}
            />
            <FormattedInput
              mt={3}
              mb={3}
              title="Profit Buffer"
              suffix=" pips"
              value={stopLimitBuffer}
              placeholder={`${
                pair?.includes('JPY')
                  ? botState.fxSettings?.stop_limit_buffer.default * 10000
                  : botState.fxSettings?.stop_limit_buffer.default * 10000
              } pips`}
              onValueChange={(value, name, values) => {
                setStopLimitBuffer(value);
              }}
            />
            <Flex w="full" justifyContent="space-between" alignItems="center">
              <Flex alignItems="center">
                <Text fontSize="13px">Profit Entry</Text>
              </Flex>
              <Text fontSize="13px" fontWeight="semibold">
                {profitEntery} pips
              </Text>
            </Flex>
          </Flex>
          {/* <Divider mt={4} mb={6} /> */}
          {/* <Flex w="full" align="center" my={4}>
            <Text fontSize="0.8125rem" fontWeight="semibold">
              Advanced Settings
            </Text>
            <Switch
              size="sm"
              ml={4}
              // disabled={authState.stripeCurrentPlan === 'Free' || authState.stripeCurrentPlan === 'Basic'}
              isChecked={isSwitchChecked}
              _focus={{
                outline: 'none'
              }}
              colorScheme="whatsapp"
              onChange={e => handleToggler(e)}
            />
          </Flex> */}
          {/* <Collapse in={isSwitchChecked}> */}
          <Flex direction="column">
            {/* <FormattedInput
                mt={3}
                mb={3}
                pof
                title="Profit Exit Step"
                suffix=" pips"
                popoverDescription="Profit Exit Step function within the 
                forex trading bot enables a trailing stop loss mechanism that
                 automatically adjusts the stop loss price by a predetermined 
                 number of pips as the market price rises (or falls if 
                  shorting). This feature empowers traders to dynamically
                  manage their positions by continuously updating the stop 
                  loss order in response to changing market conditions. By 
                  setting the appropriate parameters, such as the trailing 
                  amount in pips, traders can effectively mitigate risk and 
                  optimize their trading strategies within the bot's 
                  operations."
                popoverHeader="Profit Exit Step"
                popoverText="A trailing stop loss feature that automatically 
                adjusts the stop loss price by a specified amount of pips as 
                the market price rises (or falls if shorting)."
                popover={props.fixed}
                value={stopLimitGranularity}
                placeholder={`${
                  pair?.includes('JPY')
                    ? botState.fxSettings?.stop_limit_granularity.default * 10000
                    : botState.fxSettings?.stop_limit_granularity.default * 10000
                } pips`}
                onValueChange={(value, name, values) => {
                  setStopLimitGranularity(value);
                }}
              /> */}
            <FormattedInput
              mt={3}
              pof
              mb={3}
              title="Force Order Timer"
              suffix=" hours"
              placeholder="0"
              popoverDescription="The Force Order Timer parameter determines
                 the duration, in hours, that the forex trading bot will wait
                  if the price fails to change before initiating an additional
                   order. If the value is set to 0, it signifies that the
                   timer is not configured, meaning that the bot will not
                    wait for a specified duration and will proceed with its 
                    default behavior. Traders can adjust this parameter 
                    according to their trading strategy and preferences, 
                    allowing for flexible management of order execution based 
                    on market conditions."
              popoverHeader="Force Order Timer"
              popoverText="Specifies the duration, in hours, to wait if 
                the price remains unchanged before triggering an additional 
                order. If set to 0, it indicates that the timer is not 
                configured."
              popover={props.fixed}
              preventDecimals
              value={forceOrderTimer}
              onValueChange={(value, name, values) => {
                setForceOrderTimer(value);
              }}
            />
          </Flex>
          {/* </Collapse> */}
          {nameErrorMessage || maxMarginUtilizationError || unitAdditionError || stopLimitBufferError
            ? renderError()
            : null}
          <EFButton
            bg="#fdc43f"
            isLoading={botState.loading}
            color="black"
            // disabled
            w="full"
            mt={2}
            title="Preview"
            onClick={handlePreviewBot}
          />
        </Flex>
      );
    } else if (mode === 'preview') {
      return (
        <Flex mb={4} w="90%" justify="flex-start" align="center" direction="column">
          <Text fontSize="sm" mb={4} color={colorMode === 'light' ? 'gray.600' : 'gray.300'}>
            You are creating a bot named <chakra.span fontWeight="bold">{`${botName}`}</chakra.span> using the{' '}
            <chakra.span fontWeight="bold">{`${botStyle}`}</chakra.span> method. Your trading pair will be{' '}
            <chakra.span fontWeight="bold">{`${pair}`}</chakra.span>. If everything looks good to you, click on
            create bot button.
          </Text>
          <Flex direction="column" w="full">
            <Divider mb={4} />
            <Flex justify="space-between" alignItems="center" mb={4}>
              <Text mr={8} fontSize="sm">
                Max Margin to Use
              </Text>
              <Text fontWeight="semibold" fontSize="sm">
                {maxMarginUtilization
                  ? dynamicCurrencyFormatter(userState.baseCurrency ? userState.baseCurrency : 'USD').format(
                      Number(maxMarginUtilization)
                    )
                  : dynamicCurrencyFormatter(userState.baseCurrency ? userState.baseCurrency : 'USD').format(
                      Math.floor(userState.oandaData.marginAvailable / 10)
                    )}
              </Text>
            </Flex>
            <Flex justify="space-between" alignItems="center" mb={4}>
              <Text mr={8} fontSize="sm">
                Base Order
              </Text>
              <Text fontWeight="semibold" fontSize="sm">
                {initialUnits ? initialUnits + ' unit(s)' : botState.fxSettings.initial_units.default + ' unit(s)'}
              </Text>
            </Flex>
            <Flex justify="space-between" alignItems="center" mb={4}>
              <Text mr={8} fontSize="sm">
                Next Order Trigger
              </Text>
              <Text fontWeight="semibold" fontSize="sm">
                {additionalOrderStep
                  ? additionalOrderStep + ' pips'
                  : handlePipsForPreview(botState.fxSettings.additional_order_step.default) + ' pips'}
              </Text>
            </Flex>
            <Flex justify="space-between" alignItems="center" mb={4}>
              <Text mr={8} fontSize="sm">
                Order Increment
              </Text>
              <Text fontWeight="semibold" fontSize="sm">
                {unitAddition ? unitAddition + ' unit(s)' : botState.fxSettings.unit_addition.default + ' unit(s)'}
              </Text>
            </Flex>
            <Flex justify="space-between" alignItems="center" mb={4}>
              <Text mr={8} fontSize="sm">
                Min Profit Exit
              </Text>
              <Text fontWeight="semibold" fontSize="sm">
                {profitPriceConstant
                  ? profitPriceConstant + ' pips'
                  : handlePipsForPreview(botState.fxSettings.profit_price_constant.default) + ' pips'}
              </Text>
            </Flex>
            <Flex justify="space-between" alignItems="center" mb={4}>
              <Text mr={8} fontSize="sm">
                Profit Buffer
              </Text>
              <Text fontWeight="semibold" fontSize="sm">
                {stopLimitBuffer
                  ? stopLimitBuffer + ' pips'
                  : handlePipsForPreview(botState.fxSettings.stop_limit_buffer.default) + ' pips'}
              </Text>
            </Flex>
            {/* <Flex justify="space-between" alignItems="center" mb={4}>
              <Text mr={8} fontSize="sm">
                Profit Exit Step
              </Text>
              <Text fontWeight="semibold" fontSize="sm">
                {stopLimitGranularity
                  ? stopLimitGranularity + ' pips'
                  : handlePipsForPreview(botState.fxSettings.stop_limit_granularity.default) + ' pips'}
              </Text>
            </Flex> */}
            <Flex justify="space-between" alignItems="center" mb={4}>
              <Text mr={8} fontSize="sm">
                Force Order Timer
              </Text>
              <Text fontWeight="semibold" fontSize="sm">
                {forceOrderTimer
                  ? forceOrderTimer + ' hour(s)'
                  : botState.fxSettings.order_timer.default + ' hour(s)'}
              </Text>
            </Flex>
          </Flex>
          <EFButton
            bg="#fdc43f"
            color="black"
            w="full"
            isLoading={botState.loading}
            mt={2}
            title="Create and Start Bot"
            onClick={handleCreateBot}
          />
          {!botState.loading ? (
            <Button
              w="full"
              fontSize="sm"
              bg={colorMode === 'light' ? 'white' : 'transparent'}
              // borderColor={epicFlowGradientColors.left}
              borderColor={props.bg}
              borderWidth={1}
              _hover={{
                bg: colorMode === 'light' ? 'white' : 'transparent'
              }}
              _focus={{
                outline: 'none'
              }}
              _active={{
                bg: colorMode === 'light' ? 'white' : 'transparent'
              }}
              // color={epicFlowGradientColors.left}
              color={props.bg}
              mt={4}
              onClick={handleEditButton}
            >
              Edit Bot
            </Button>
          ) : null}
        </Flex>
      );
    } else if (mode === 'done' && botState.addBotStatus?.error && !botState.loading) {
      return (
        <Flex mb={2} w="90%" justify="flex-start" align="center" direction="column">
          <WarningTwoIcon color="red.400" boxSize={8} mb={4} />
          <Text fontSize="sm" mb={6}>
            {botState.addBotStatus.error}.
          </Text>
          <EFButton
            bg={props.bg}
            w="full"
            isLoading={botState.loading}
            mt={2}
            mb={4}
            title="Done"
            onClick={handleDone}
          />
        </Flex>
      );
    } else if (!botState.loading) {
      return (
        <Flex mb={4} w="85%" justify="flex-start" align="center" direction="column">
          <Text fontSize="sm" mb={4}>
            Congrats 🎉 <chakra.span fontWeight="bold">{`${authState.name || profileState.name}`}</chakra.span>.
            Your bot <chakra.span fontWeight="medium">{`${botName}`}</chakra.span> has been created successfully.
            Go to My Bots tabs to see your bots.
          </Text>
          <Divider mb={4} />
          <Flex w="full" justifyContent="center" alignItems="center" mb={6} mt={4}>
            <Lottie
              options={{
                loop: false,
                path: 'https://lottie.host/3804ca79-943f-4566-b481-a2e04811bf3c/tA1X6rDgvp.json'
              }}
              height={40}
              width={40}
            />
          </Flex>
          <EFButton
            bg={props.bg}
            color={props.bg === 'white' ? 'black' : 'white'}
            w="full"
            isLoading={botState.loading}
            mt={2}
            // mb={2}
            title="New Bot"
            onClick={handleDone}
          />
        </Flex>
      );
    }
  };

  useEffect(() => {
    setWindowHeight(window.innerHeight);
  }, [window.innerHeight]);

  return !profileState.loading ? (
    <React.Fragment>
      <UpgradeSub isopen={isUpgradeSubOpen} onclose={handleUpgradeSubModalClose} />
      <Flex
        display={props.fixed ? ['none', 'none', 'none', 'none', 'flex', 'flex'] : 'flex'}
        justify="flex-end"
        align="flex-start"
        // boxShadow="lg"
        borderRadius={16}
        // bg={colorMode === 'light' ? 'white' : 'gray.900'}
        // position="relative"
        borderWidth={1}
        overflowY="scroll"
        height="fit-content"
        // ml={props.fixed && '-16rem'}
        // position={props.fixed ? 'fixed' : ''}
        w={props.fixed ? '330px' : 'sm'}
        //TODO figure height
        // borderWidth={3}
        css={{
          '&::-webkit-scrollbar': {
            width: '1px'
          },
          '&::-webkit-scrollbar-track': {
            width: '1px'
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '2px'
          }
        }}
      >
        <Flex
          w={props.fixed ? '330px' : 'sm'}
          direction="column"
          pb={6}
          // boxShadow={props.fixed ? 'lg' : ['lg', 'lg', 'lg', 'none', 'none', 'none', 'none']}
          align="center"
          // overflowY="scroll"
          borderRadius="md"
        >
          <Heading size="sm" w="90%" textAlign="left" mt={5} mb={5} fontWeight="semibold">
            {mode === 'creation'
              ? 'Automation Wizard'
              : mode === 'preview'
              ? 'Summary'
              : mode === 'done' && botState.addBotStatus?.error
              ? 'Oh Snap!'
              : 'All Done!'}
          </Heading>
          {/* <Divider /> */}
          {renderDynamicContent()}
        </Flex>
      </Flex>
    </React.Fragment>
  ) : null;
};

FxAutomationWizard.propTypes = {};

export default React.memo(FxAutomationWizard);
