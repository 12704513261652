import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Checkbox,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUser } from '../../store/actions/auth';
import axios from '../../utilities/axios';

const TOS = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const [loading, setLoading] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const authState = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const handleClosing = () => {
    props.onclose();
  };

  const handleCheckBox = () => {
    setIsChecked(!isChecked);
  };

  const handleAcceptToS = async () => {
    let response = await axios.post(`${process.env.REACT_APP_API_URL}updatetos`, { email: authState.email });
    if (response.data) {
      dispatch(getUser(authState));
    }
  };

  useEffect(() => {
    if (authState.tosVersion === process.env.REACT_APP_TOS_VERSION) {
      props.onclose();
    }
  }, [authState.tosVersion]);

  return (
    <Modal
      isCentered
      motionPreset="slideInBottom"
      closeOnOverlayClick={false}
      isOpen={props.isopen}
      scrollBehavior="inside"
      onClose={handleClosing}
      closeOnEsc={false}
      size="3xl"
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius="2xl" bg={colorMode === 'light' ? 'white' : 'gray.900'}>
        <ModalHeader fontWeight="bold" fontSize="xl" mt={10} pb={0} pt={0}>
          Terms of Service
        </ModalHeader>
        <ModalCloseButton
          _focus={{
            outline: 'none'
          }}
        />
        <ModalBody>
          <Flex w="90%" margin="auto" direction="column" fontSize="14px">
            <>
              <br></br>
              <p>
                <strong>EPICFLOW.IO TERMS OF SERVICE</strong>
              </p>
              <br></br>
              <p>
                <strong>Last Updated: February 18th 2024</strong>
              </p>
              <br></br>
              <p>
                EpicFlow provides software as a service, which allows you to utilize several functionalities for
                managing users&rsquo; (&ldquo;
                <strong>User</strong>&rdquo; or &ldquo;<strong>you</strong>&rdquo;) cryptocurrency, forex or fiat
                currency holdings across different exchange accounts for automated Trading Bots and portfolio
                tracking for your balance, changes, and allocations of personal cryptocurrency, forex or fiat
                currency portfolio tracking(the &ldquo;
                <strong>Service</strong>&rdquo;) at <a href="http://www.epicflow.io">www.epicflow.io</a> or via an
                App (collectively, the &ldquo;<strong>Site</strong>&rdquo;). The Service, the Software, and the
                Site may be collectively referred to herein as the &ldquo;<strong>Platform</strong>.&rdquo; The
                Service is owned and operated by DeFi Management, Inc. (hereinafter referred to as the &ldquo;
                <strong>Company</strong>&rdquo;, &ldquo;
                <strong>EpicFlow.io</strong>&rdquo;, &ldquo;<strong>EpicFlow</strong>
                &rdquo;, &ldquo;<strong>we</strong>&rdquo; or &ldquo;
                <strong>us</strong>&rdquo;).
              </p>
              <p>
                Your use of the Service is subject to the terms and conditions outlined in this Terms of Service
                (hereinafter referred to as the &ldquo;<strong>Terms</strong>&rdquo; or &ldquo;
                <strong>Terms of Service</strong>&rdquo; or &ldquo;
                <strong>TOS</strong>&rdquo;).&nbsp;
              </p>
              <br></br>
              <p>
                <strong>
                  PLEASE READ THE TERMS OF SERVICE CAREFULLY. BY ACCESSING OR USING ANY PART OF THE SERVICE, YOU
                  ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT
                  AGREE TO THESE TERMS, EXIT THIS PAGE AND DO NOT ACCESS OR USE THE SERVICE. USE OF THE SERVICE IS
                  EXPRESSLY CONDITIONED UPON YOUR ACCEPTANCE OF THE TERMS OF SERVICE.
                </strong>
              </p>
              <br></br>
              <p>
                <strong>Updates to Terms of Service; Integration.&nbsp;</strong>
              </p>
              <br></br>
              <p>
                We may, in our sole discretion, modify the Terms of Service by posting a notice on the Terms of
                Service page. The &ldquo;Last Updated&rdquo; date at the top the Terms of Service indicates when
                the latest modifications were made to the TOS. By continuing to access and use the Service You
                agree to any such modifications. Therefore, You are responsible for reviewing and should become
                familiar with any such modifications. You are encouraged to review these Terms of Service
                periodically and to check the &ldquo;Last Updated&rdquo; date at the top of the Terms of Service
                for the most recent version. In addition, when using services or features on the Site, You will be
                subject to any posted guidelines or policies applicable to such services or features that may be
                posted from time to time, including but not limited to our Privacy Policy, as noted below. All such
                guidelines or policies are hereby incorporated by reference into this Terms of Service.
              </p>
              <br></br>
              <p>
                <strong>Translation</strong>.&nbsp;
              </p>
              <br></br>
              <p>
                We may translate these Terms of Service into other languages for your convenience. Nevertheless,
                the English version governs your relationship with Company, and any inconsistencies among the
                different versions will be resolved in favor of the English version.
              </p>
              <br></br>
              <p>
                <strong>Service Availability.&nbsp;</strong>
              </p>
              <br></br>
              <p>
                The Service may be modified, updated, interrupted, suspended or discontinued at any time, in the
                sole discretion of the Company, without notice or liability. The Service may be unavailable at
                certain periods, including but not limited to systems failures, anticipated or unanticipated
                maintenance work, upgrades or force majeure events.&nbsp;
              </p>
              <p>
                The Company reserves the right, at any time, in its sole discretion to modify, temporarily or
                permanently block access to, suspend, or discontinue the Service, in whole or in part, with or
                without notice and effective immediately to any User.&nbsp;
              </p>
              <p>
                The Company will have no liability whatsoever for any losses, liabilities or damages you may incur
                as the result of any modification, suspension, or discontinuation of the Service or any part
                thereof.&nbsp;
              </p>
              <br></br>
              <p>
                <strong>Privacy Policy</strong>.&nbsp;
              </p>
              <br></br>
              <p>
                Use of the Service is subject to the terms of our{' '}
                <a href="https://www.epicflow.io/privacypolicy">Privacy Policy</a>
                which is hereby incorporated into and made part of this Terms of Service. Please review our Privacy
                Policy carefully. By using or accessing the Service, you agree to be bound by the terms of our
                Privacy Policy.&nbsp;
              </p>
              <br></br>
              <p>
                <strong>Age.</strong> The Service is meant for those at least eighteen years or age, or the age of
                majority where you reside, whichever is younger. Use of the Service by anyone under this age is a
                violation of these Terms.
              </p>
              <p>
                <br></br>
                <strong>Intellectual Property</strong>.&nbsp;
              </p>
              <br></br>
              <p>
                You acknowledge that all the intellectual property rights in the Service, including, but not
                limited to, the Software running as part of the Service, copyrights, patents, trademarks, and trade
                secrets, the website design, application design, graphics, text, sounds, pictures, service marks,
                trade names, domain names, slogans, logos, other files and the selection and arrangement thereof,
                and other indicia of origin that appear on or in connection with any aspect of the
                Service(collectively the &ldquo;<strong>IP</strong>&rdquo;) are either the property of the Company,
                its affiliates or licensors and are subject to and protected by United States and international
                copyright and other intellectual property laws and rights. All rights to IP not expressly granted
                in these Terms of Service are reserved to their respective copyright owners.&nbsp;
              </p>
              <p>
                Subject to these Terms, the Company grants you a limited non-transferable, non-exclusive,
                revocable, non-sublicensable licence to use and access the Service solely for your own personal or
                internal business purposes. You will not obtain any ownership interest therein through this Terms
                of Service or otherwise.&nbsp;
              </p>
              <p>
                Company authorizes you to view, download and/or print the IP provided that you keep intact all
                copyright and other proprietary notices contained in the original IP. Except as expressly
                authorized by the Terms of Service, you may not copy, reproduce, distribute, republish, perform,
                display, post, transmit, scrape, copy, exploit, create derivative works or otherwise use any of the
                IP in any form or by any means, without the prior written authorization of Company or the
                respective copyright owner. In the absence of a written agreement, you may not modify or adapt the
                IP in any way or otherwise use them for any public or commercial resale purposes. The Company
                retains the right to rescind and terminate the limited license granted hereunder at any point, for
                any reason. The Company reserves the right to enforce its intellectual property rights fully under
                United States and international law.
              </p>
              <p>
                Some of the company and product names, logos, brands, and other trademarks featured or referred to
                within the Service may not be owned by us and are the property of their respective trademark
                holders. These trademark holders are not affiliated with, nor do they sponsor or endorse the
                Service.
              </p>
              <br></br>
              <p>
                <strong>Third Party Operators and Websites.&nbsp;</strong>
              </p>
              <br></br>
              <p>
                In the event we include links via the Service to third-party operators and/or websites{' '}
                <strong>(</strong>hereinafter referred to as
                <strong> &ldquo;Third Party Provider&rdquo;)</strong> which may include products, goods, services
                or information offered therein, these links are provided only as a convenience. If you clickthrough
                using these links to other websites, you may leave our Site. We do not control nor endorse any such
                Third-Party Provider. You agree that the Company Parties (as defined below) will not be responsible
                or liable for any content, products, goods, services or information provided or made available by a
                Third-Party Provider, including related websites, or for your use or inability to use the services
                of a Third-Party Provider.
              </p>
              <p>
                You will use such links at your own risk. You are advised that other websites on the Internet,
                including Third-Party Provider websites linked from our Site, might contain material or
                information:&nbsp;
              </p>
              <p>(i) that some people may find offensive or inappropriate;&nbsp;</p>
              <p>(ii) that is inaccurate, untrue, misleading or deceptive; or,</p>
              <p>
                (iii) that is defamatory, libelous, infringing of others&rsquo; rights or otherwise unlawful.&nbsp;
              </p>
              <p>
                We expressly disclaim any responsibility for the content, legality, decency or accuracy of any
                information, and for any content, products, goods, services or information, which appear on any
                Third-Party Provider website or in advertisements or content that a Third-Party Provider may have
                listed or offered on our Site.
              </p>
              <p>
                Your interactions with a Third-Party Provider found on or through the Service, including payment
                and delivery of goods or services, if any, conditions, warranties or representations associated
                with such matters are solely between you and the Third-Party Provider, except as may be otherwise
                stated herein. You acknowledge and agree that we are not a party to any transactions you may enter
                into, except as may be stated herein, using the Service and we shall not under any circumstances be
                liable for any damages of any kind arising out of, or in connection with, or relating to, the
                content, products, goods, services or information of a Thirty-Party Provider.
              </p>
              <br></br>
              <p>
                <strong>Use of the Service</strong>
              </p>
              <br></br>
              <p>
                You may be required to create an account to use the Service and/or take advantage of certain
                features, in which case you agree to:
              </p>
              <ul>
                <li>
                  provide true, accurate, current and complete information about yourself, and your company if
                  applicable, as prompted by the Service;&nbsp;
                </li>
                <li>
                  as permitted, maintain and promptly update such information. If you provide any information that
                  is false, inaccurate or outdated, or Company has reasonable grounds to suspect that such
                  information is false, inaccurate or outdated, Company has the right to suspend or terminate your
                  account and prohibit all current or future use of the Service by you; and&nbsp;
                </li>
                <li>
                  that your account is for your personal and/or business use. You may not resell the Service.&nbsp;
                </li>
                <li>
                  by creating an account, you agree to receive certain communications in connection with the
                  Service.
                </li>
              </ul>
              <p>
                You are responsible for maintaining the confidentiality of your password and account and are fully
                responsible for all activities that occur under your account. Your account is meant to be private,
                and you shall not share your account for any reason. You agree to immediately notify us of any
                unauthorized use of your password or account or any other breach of security. You agree to be
                responsible for all charges resulting from the use of your account via the Service, including
                charges resulting from unauthorized use of your account.
              </p>
              <p>
                You may not impersonate someone else, create or use an account for anyone other than yourself,
                provide an email address other than your own, or create multiple accounts.&nbsp;
              </p>
              <p>
                You agree to use the Service only for lawful purposes and that you are responsible for your use of
                and communications and content you may post via the Service. You agree not to post or transmit any
                unlawful, infringing, threatening, harassing, defamatory, vulgar, obscene, profane, indecent,
                offensive, hateful or otherwise objectionable material of any kind, including any material that
                encourages criminal conduct or conduct that would give rise to civil liability, infringes upon
                others&rsquo; intellectual property rights, impersonates any individual or entity, or otherwise
                violates any applicable law. You agree not to solicit personal information from minors. You agree
                not to use the Service in any manner that interferes with its normal operation or with any other
                User&rsquo;s use of the Service.
              </p>
              <p>
                You may not use the Sie if you are identified as a &ldquo;Specially Designated National&rdquo; by
                the United States Office of Foreign Assets Control or if you are placed on the U.S. Commerce
                Department&rsquo;s Denied Persons List.
              </p>
              <p>You may not do any of the following while accessing or using the Service:&nbsp;</p>
              <ul>
                <ul>
                  <li>
                    access, tamper with, or use non-public areas of the Service, our computer systems, or the
                    technical delivery systems of our providers;&nbsp;
                  </li>
                  <li>
                    probe, scan, or test the vulnerability of any system or network or breach or circumvent any
                    security or authentication measures;&nbsp;
                  </li>
                  <li>
                    access or search or attempt to access or search the Service by any means other than through our
                    currently available, published interfaces that are provided by us, unless you have been
                    specifically allowed to do so in a separate agreement with us;&nbsp;
                  </li>
                  <li>
                    forge any TCP/IP packet header or any part of the header information in any email or posting,
                    or in any way use the Service to send altered, deceptive or false source-identifying
                    information;&nbsp;
                  </li>
                </ul>
              </ul>
              <ul>
                <li>
                  <strong>
                    disrupt or interfere with the access of any user, host or network, including, without
                    limitation, sending a virus, overloading, flooding, spamming, mail-bombing the Service, or
                    otherwise creating an undue burden on the Service;
                  </strong>
                </li>
              </ul>
              <ul>
                <li>
                  use manual or automated software, devices, or other processes to &ldquo;crawl,&rdquo;
                  &ldquo;scrape,&rdquo; or &ldquo;spider&rdquo; any page of the Service. You will not decompile,
                  reverse engineer, or otherwise attempt to obtain the source code of any part of the Service; or
                </li>
                <li>
                  access the Service by any means except through the interface provided by Company for access to
                  the Service. Creating or maintaining any link from another application to any page at the Service
                  without the prior authorization of Company is prohibited. Running or displaying the Service, or
                  any information or material displayed via the Service in frames or through similar means on
                  another website or application without the prior authorization of Company is prohibited. Any
                  permitted links to the Service must comply with all applicable laws, rule and regulations.
                </li>
              </ul>
              <p>Furthermore, you herein agree not to make use of the Services for:</p>
              <ul>
                <ul>
                  <li>
                    uploading, posting, emailing, transmitting, or otherwise making available any content that
                    shall be deemed unlawful, harmful, threatening, abusive, harassing, tortious, vulgar, obscene,
                    libelous, or invasive of another&apos;s privacy or which is hateful, and/or racially,
                    ethnically, or otherwise objectionable;
                  </li>
                  <li>causing harm to any minor in any manner whatsoever;</li>
                  <li>
                    impersonating any individual or entity, including, but not limited to, any company, group or
                    forum leaders, or hosts or falsely stating or otherwise misrepresenting any affiliation with an
                    individual or entity;
                  </li>
                  <li>
                    forging captions, headings or titles or otherwise offering any content that you personally have
                    no right to pursuant to any law nor having any contractual or fiduciary relationship with;
                  </li>
                  <li>
                    uploading, posting, emailing, transmitting or otherwise offering any such content that may
                    infringe upon any patent, copyright, trademark, or any other proprietary or intellectual rights
                    of any other party;
                  </li>
                  <li>
                    uploading, posting, emailing, transmitting or otherwise offering any content that you do not
                    personally have any right to offer pursuant to any law or in accordance with any contractual or
                    fiduciary relationship;
                  </li>
                  <li>
                    uploading, posting, emailing, transmitting, or otherwise offering any unsolicited or
                    unauthorized advertising, promotional flyers, &quot;junk mail,&quot; &quot;spam,&quot; or any
                    other form of solicitation, except in any such areas that may have been designated for such
                    purpose;
                  </li>
                  <li>
                    uploading, posting, emailing, transmitting, or otherwise offering any source that may contain a
                    software virus or other computer code, any files and/or programs which have been designed to
                    interfere, destroy and/or limit the operation of any computer software, hardware, or
                    telecommunication equipment;
                  </li>
                  <li>
                    disrupting the normal flow of communication, or otherwise acting in any manner that would
                    negatively affect other Users&apos; ability to participate in any real-time interactions;
                  </li>
                  <li>
                    interfering with or disrupting any of the Services, servers and/or networks that may be
                    connected or related to our website, including, but not limited to, the use of any software
                    and/or routine to bypass the robot exclusion headers;
                  </li>
                  <li>
                    intentionally or unintentionally violating any local, state, federal, national or international
                    law, including, but not limited to any securities rules, regulations or laws of any nation or
                    other securities exchange, and any regulations having the force of law;
                  </li>
                  <li>
                    providing informational support or resources, concealing and/or disguising the character,
                    location, and or source to any organization delegated by the United States government as a
                    &quot;foreign terrorist organization&quot; in accordance with Section 219 of the Nationality
                    Act;
                  </li>
                  <li>stalking or with the intent to otherwise harass another individual; and/or,</li>
                </ul>
              </ul>
              <ul>
                <li>
                  <strong>
                    collecting or storing of any personal data relating to any other User in connection with the
                    prohibited conduct and/or activities which have been set forth in the aforementioned
                    paragraphs.
                  </strong>
                </li>
              </ul>
              <p>
                Your use of the Service is at your own risk, including the risk that you might be exposed to
                content that is offensive, indecent, inaccurate, objectionable, or otherwise inappropriate.
              </p>
              <p>
                Company makes no representation that Materials contained, described or offered via the Service are
                accurate, appropriate or available for use in any particular jurisdiction or that these Terms of
                Service comply with the laws of any specific country. Visitors who use the Service do so on their
                own initiative and are responsible for compliance with all applicable law. You agree that you will
                not access the Service from any territory where its Material or other company posted content
                (hereinafter referred to as &ldquo;Content&rdquo;), or User Content (as defied below) is illegal,
                and that you, and not the Company Parties (as defined below), are responsible for compliance with
                applicable law.
              </p>
              <br></br>
              <p>
                <strong>Use of APIs</strong>
              </p>
              <br></br>
              <p>
                You are solely responsible for maintaining and protecting your Application Program Interface(s)
                keys (&ldquo;API Keys) of the 3rd party trading platforms (i.e. Oanda, Binance) that are supported
                by us. The Company may make available to you, from time to time, as a matter of convenience service
                or third-party applications relying on such an APIs. You shall maintain adequate security and
                control of all of your account(s) details, passwords, personal identification numbers, API keys,
                API secret keys, or any other codes that you use to access the Services. You may not share your
                personal API keys. The Company shall have no obligations arising from a security breach, viruses,
                phishing campaigns, or if the API keys are lost or stolen and the Company have no liability arising
                from any of the above or similar actions.
              </p>
              <br></br>
              <p>
                <strong>User Content</strong>
              </p>
              <br></br>
              <p>
                &ldquo;User Content&rdquo; means any and all information and content that a User uploads, submits
                to, or uses with the Services. You may choose to enter this information manually or, where
                available, synchronize with a third-party website. The Company does not verify the accuracy or
                completeness of User Content, and these may therefore be subject to errors.
              </p>
              <p>
                You are solely responsible for your User Content. You assume all risks associated with use of your
                User Content, including any reliance on its accuracy, completeness or usefulness by others, or any
                disclosure of your User Content that personally identifies you or any third party. You hereby
                represent and warrant that your User Content does not violate any of the Terms of Service.
              </p>
              <p>
                Company does not and is not obligated to backup any User Content, and your User Content may be
                deleted at any time without prior notice. You are solely responsible for creating and maintaining
                your own backup copies of your User Content.
              </p>
              <p>
                We may, at our discretion, but shall have no obligation to, pre-screen User Content submissions and
                may choose to remove User Content at any time we see fit. You agree that the Company is not
                responsible for any financial loss, liability or damage of any kind that you may incur as a result
                of our removing or refusing to publish User Content.
              </p>
              <p>
                By posting User Content to the Site, you agree that (i) your User Content does not contain the
                confidential or proprietary information of third parties, (ii) we are under no obligation of
                confidentiality, express or implied, with respect to the User Content, (iii) we may have something
                similar to the User Content already under consideration or in development, and (iv) you grant us an
                irrevocable, non-exclusive, royalty-free, perpetual, worldwide license to use, modify, prepare
                derivative works, publish, distribute and sublicense the User Content, and (v) you irrevocably
                waive, and cause to be waived, against the Company Parties and its Users any claims and assertions
                of any moral rights contained in such User Content.
              </p>
              <p>
                Suggestions and Improvements. By sending us any ideas, suggestions, documents or proposals
                (hereinafter referred to as &ldquo;Feedback&rdquo;), you agree that (i) your Feedback does not
                contain the confidential or proprietary information of third parties, (ii) we are under no
                obligation of confidentiality, express or implied, with respect to the Feedback, (iii) we may have
                something similar to the Feedback already under consideration or in development, and (iv) you grant
                us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide license to use, modify,
                prepare derivative works, publish, distribute and sublicense the Feedback, and (v) you irrevocably
                waive, and cause to be waived, against the Company Parties and its Users any claims and assertions
                of any moral rights contained in such Feedback.
              </p>
              <br></br>
              <p>
                <strong>Term and Termination</strong>
              </p>
              <br></br>
              <p>
                Subject to this section, the Terms herein will remain in full force and effect while you use the
                Service. We may suspend or terminate your rights to use the Service (including your account) at any
                time for any reason, or no reason, at our sole discretion, including for any use of the Service in
                violation of these Terms.
              </p>
              <p>
                Upon termination of your rights under these Terms, your Account and right to access and use the
                Service will terminate immediately.&nbsp;
              </p>
              <p>
                The Company will not have any liability whatsoever to you for any termination of your rights under
                these Terms, including for termination of your account or deletion of your information. You may
                terminate your use of the Company Service at any time.&nbsp;
              </p>
              <p>
                You understand you may not use the Services if you are located in, or a citizen or resident of any
                state, country, territory or other jurisdiction that is embargoed by the United States or where
                your use of the Services would be illegal or otherwise violate any applicable law. You represent
                and warrant that you are not a citizen or resident of any such jurisdiction and that you will not
                use any Services while located in any such jurisdiction. You also may not use the Services if you
                are located in, or a citizen or resident of, any other jurisdiction where EpicFlow has determined,
                at its discretion, to prohibit use of the Services. EpicFlow may implement controls to restrict
                access to the Services from any jurisdiction prohibited&nbsp;
              </p>
              <br></br>
              <p>
                <strong>User Representations.</strong>
              </p>
              <br></br>
              <p>You expressly acknowledge, represent, warrant, and agree that you understand:&nbsp;</p>
              <p>
                The information contained herein is for informational purposes only and is not intended nor should
                be construed as advice or recommendations and are not guaranteed to produce results.
              </p>
              <p>
                Company does not warrant or guarantee the suitability or availability of any materials or Content,
                including without limitation any, data, products, or services, found through the Service.&nbsp;
              </p>
              <p>
                Company does not screen the authenticity or quality of any material or content or any provider of
                material or content, including, data, products, or services found through the Service.
              </p>
              <p>
                You are of legal age to form a binding contract and are at least eighteen (18) years of age or you
                have the authority of such legal entity to form a binding contract; all information you provide to
                us is accurate and truthful; you will maintain the accuracy of such information; and you are
                legally permitted to use and access the Service and take full responsibility for the selection and
                use of and access to the Service.
              </p>
              <p>
                You acknowledge the Services are intended only to assist you with your crypto, forex or money
                holding exchange and decision-making and is broad in scope. Accordingly, before making any final
                decisions or implementing any trading strategy, you should consider obtaining additional
                information and advice from your accountant or other financial advisers who are fully aware of your
                individual circumstances.
              </p>
              <p>
                This company is a software provider, and does not provide financial, investment, legal, tax or any
                other professional advice. Company is not a broker, financial advisor, investment advisor,
                portfolio manager or tax advisor. Nothing on or in the software or on the Site shall constitute or
                be construed as an offering of any currency or any financial instrument or as investment advice or
                investment recommendations (such as recommendations as to whether to purchase a currency or
                instrument) by Company or a recommendation as to an investment strategy by Company. You acknowledge
                and agree that Company is not responsible for your use of any information that you obtain on the
                Software. Your decisions made in reliance on the products or services in the software or your
                interpretations of the data found in the Software are your own for which you have full
                responsibility. You expressly agree that you use the software at your sole risk.
              </p>
              <br></br>
              <p>
                <strong>Indemnification </strong>
              </p>
              <br></br>
              <p>
                By using our algorithmic trading bot services (&quot;Services&quot;), you agree to indemnify,
                defend, and hold EpicFlow.io and its affiliates, directors, officers, employees, agents, and
                licensors harmless from and against any and all claims, losses, damages, liabilities, costs, and
                expenses (including reasonable attorneys&apos; fees) arising out of or relating to:
              </p>
              <ul>
                <li>
                  Any defects, errors, bugs, glitches, or inaccuracies in the operation of the trading bot or the
                  trading platform utilized by EpicFlow.io and its Parent company.
                </li>
                <li>
                  Any delays, interruptions, or disruptions in trade execution, including but not limited to trade
                  execution slips, failures, or malfunctions caused by EpicFlow platform and/or due to one of its
                  connected trade platforms (Oanda, Binance.us or any other future trading platforms that customers
                  are able to integrate with).
                </li>
                <li>
                  Any unauthorized access to or use of the trading bot or trading platform by you or any third
                  party.
                </li>
                <li>
                  Any violation of these Terms of Service or any applicable laws, rules, or regulations by you or
                  any third party accessing the Services through your account.
                </li>
                <li>
                  Any infringement or misappropriation of any intellectual property rights or other rights of any
                  third party by you or any third party accessing the Services through your account.
                </li>
                <li>Any transactions entered into or executed through the trading bot or trading platform.</li>
                <li>
                  You agree to cooperate fully in the defense of any claim. Our Company reserves the right to
                  assume the exclusive defense and control of any matter subject to indemnification by you, and you
                  will not settle any such matter without the prior written consent of us.
                </li>
              </ul>
              <p>
                You acknowledge and agree that we are not responsible for any financial losses, damages, or
                liabilities incurred as a result of the actions or inactions of third-party trading platforms
                utilized in conjunction with our our algorithmic bot services (&quot;Services&quot;). Therefore,
                you agree to indemnify, defend, and hold harmless epicflow.io and its affiliates, directors,
                officers, employees, agents, and licensors from and against any and all claims, losses, damages,
                liabilities, costs, and expenses (including reasonable attorneys&apos; fees) arising out of or
                relating to:
              </p>
              <ul>
                <li>
                  Bankruptcy, insolvency, financial difficulty, or any other financial issues encountered by
                  third-party trading platforms.
                </li>
                <li>
                  Failure or delay in transferring funds or executing transactions by third-party trading
                  platforms.
                </li>
                <li>
                  Any other actions or inactions of third-party trading platforms that result in financial losses
                  or damages to you.
                </li>
              </ul>
              <p>
                You acknowledge that the use of third-party trading platforms involves inherent risks, and our
                Company does not guarantee the performance, reliability, or availability of these platforms. It is
                your responsibility to conduct due diligence and assess the risks associated with using third-party
                trading platforms.
              </p>
              <p>
                You agree to indemnify, defend and hold harmless the Company, its parents, subsidiaries and other
                affiliated companies, and their respective officers, directors, employees, agents and other
                representatives (collectively, the &ldquo;
                <strong>Company Parties</strong>&rdquo;) against all claims, demands, causes of action, losses,
                expenses, damages and costs (including any reasonable attorneys&rsquo; fees), resulting or arising
                from or relating to your use of the Service, any activity related to your account by you or any
                other person permitted by you, any Content that you submit to, post on or transmit through the
                Service, your breach of this Terms of Service, your infringement or violation of any rights of
                another, or termination of your access to the Service. We reserve the right to assume, at our sole
                expense, the exclusive defense and control of any such claim or action and all negotiations for
                settlement or compromise, and you agree to fully cooperate with us in the defense of any such
                claim, action, settlement or compromise negotiations, as requested by us.
              </p>
              <p>
                You hereby release and forever discharge the Company Parties from, and hereby waive and relinquish,
                each and every past, present and future dispute, claim, controversy, demand, right, obligation,
                liability, action and cause of action of every kind and nature (including personal injuries, death,
                and property damage), that has arisen or arises directly or indirectly out of, or that relates
                directly or indirectly to, the Service, including any interactions with, or act or omission of,
                other Service Users or any Third-Party sites, including but not limited to: (i) your use of the
                Service, (ii) any activity related to your accounts by you or any other person, (iii) your
                violation of this Terms; (iv) your infringement or violation of any rights of another, or (v) your
                violation of applicable laws or regulations.
              </p>
              <p>
                Company reserves the right, at your expense, to assume the exclusive defense and control of any
                matter for which you are required to indemnify us, and you agree to cooperate with our defense of
                these claims. You agree not to settle any matter without the prior written consent of Company.
                Company will use reasonable efforts to notify you of any such claim, action or proceeding upon
                becoming aware of it.
              </p>
              <p>
                This indemnification clause shall survive any termination or expiration of these Terms of Service
                or your use of the Services.
              </p>
              <p>
                <strong>Warranties, Disclaimers and Limitations of Liability</strong>
                .&nbsp;
              </p>
              <p>You expressly understand and agree that:</p>
              <p>
                Your use of the Service is at your sole risk. The Service and the associated materials and content
                are provided on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis. The Company Parties
                expressly disclaim all warranties of any kind, whether express or implied, including, but not
                limited to, implied warranties of merchantability, fitness for a purpose and non-infringement.
                Without limiting the generality of the foregoing, the Company Parties make no warranty that: (i)
                the Service will meet your requirements; (ii) the Service will be uninterrupted, timely, secure, or
                error-free; (iii) information that may be obtained via the Service will be accurate or reliable;
                (iv) the quality of any and all products, services, information or other materials, including all
                merchandise, products, goods or services, obtained or purchased by you directly or indirectly
                through the company Service will meet your expectations or needs; and (v) any errors in the Service
                will be corrected.
              </p>
              <p>
                The Company Parties shall not under any circumstances be liable for any damages of any kind arising
                out of, in connection with or relating to the use of or inability to use the Service, including any
                liability: (i) as a publisher of information; (ii) for any incorrect or inaccurate information or
                any &lsquo;bug&rsquo; of the Service; (iii) for any unauthorized access to or disclosure of your
                transmissions or data; (iv) for statements or conduct of any third party on or via the Service; (v)
                for any disputes between Users of the Service or between a User of the Service and a Third Party;
                or (vi) for any other matter relating to the Service or any Third Party. This is a comprehensive
                limitation of liability that applies to all damages of any kind, including any direct, indirect,
                special, incidental or consequential damages, whether based on breach of contract, breach of
                warranty, tort (including negligence), product liability or otherwise, even if an individual
                advises the Company Parties of the possibility of such damages. The limitations of liability set
                forth herein are fundamental elements of the basis of the bargain between Company and you. The
                products, information and services offered on and through the Service would not be provided to you
                without such limitations.
              </p>
              <p>
                The Company Parties shall not under any circumstances be liable for any damages of any kind arising
                out of, or in connection with or relating to the actions and activities of any third-party
                contractors and suppliers of services we may engage to provide services to you.
              </p>
              <p>
                Notwithstanding the foregoing, the sole and entire maximum liability of the Company Parties for any
                reason, and your sole and exclusive remedy for any cause or claim whatsoever, shall be limited to
                the charges paid by you directly to Company via the Service, if any, or $100 otherwise, whichever
                is higher
              </p>
              <p>
                You agree that regardless of any statute or law to the contrary, any claim you may bring must be
                filed within one (1) year after the cause of action occurred or it will be permanently barred.
              </p>
              <p>
                Some jurisdictions do not allow the disclaimer of certain warranties or the limitation or exclusion
                of liability for certain types of damages. Accordingly, some of the above disclaimers and
                limitations may not apply to you.
              </p>
              <p>
                If you are a California resident, you shall and hereby do waive California Civil Code Section 1542,
                which says: &ldquo;A general release does not extend to claims which the creditor does not know or
                suspect to exist in his favor at the time of executing the release, which, if known by him must
                have materially affected his settlement with the debtor.&rdquo;
              </p>
              <br></br>
              <p>
                <strong>Purchasing Subscription Plan&nbsp;</strong>
              </p>
              <br></br>
              <p>
                All prices, discounts, and promotions posted on the Software are subject to change without notice.
                The price charged for the Subscription selected by you will be the price advertised on the Site at
                the time the order is placed, the terms of any promotions or discounts, your geographical location
                or residence, and chosen payment method. If you choose a subscription for one month and wish to pay
                via Stripe, you can set up monthly recurring payments and thereafter the price of the Subscription
                will be invoiced automatically each month until the Purchase Agreement is terminated as outlined
                under these Terms of Service. If you pay via Stripe, your payment will be processed through DeFi
                Management Inc.&nbsp;
              </p>
              <p>
                If we increase our prices, that increase will only apply to purchases made after the date the
                increase comes into effect. The prices posted in the Site may not include applicable discounts or
                taxes until the profile data in your Client Account is fully completed by you. We strive to display
                accurate price information, however, we may, on occasion, make inadvertent typographical errors,
                inaccuracies or omissions related to pricing and availability. We reserve the right to correct any
                errors, inaccuracies, or omissions at any time and to cancel any orders arising from such
                occurrences.
              </p>
              <p>
                You must initiate payment for the charges related to the Subscription or other products or services
                provided through the Site when submitting the order. If you do not complete this payment to our
                satisfaction, we will cancel your offer to conclude the Purchase Agreement.
              </p>
              <p>
                You can use any available and the most convenient payment method currently available on the Site
                for all purchases. However, Company does not guarantee the availability of any payment method at
                any moment. Company may add, remove or suspend any payment method temporarily or permanently by its
                own discretion.
              </p>
              <p>
                Any payments you make through the Site and for the Software may be subjected to VAT (value added
                tax) with the appropriate rate and in accordance with the law of jurisdiction, you are established.
                Company accrues and charges VAT for your payments based on your location that is automatically
                determined by IP address of your device and/or manually provided by you to Company when entering
                your billing address.&nbsp;
              </p>
              <p>
                If you disagree with the default payment-related information that our Software generated
                automatically, you should provide: your billing address (so long as the Software will be used at
                this location); enter the address data in the Software when proceeding with the payment; and
                sending us a valid proof of this address afterwards. We will then make a determination as to
                whether the default payment-related information should be adjusted. For more information on how we
                handle your personal information, please refer to our Privacy Policy.
              </p>
              <p>
                When registering for the Services, you have the opportunity to choose between different
                subscription plans, i.e &ldquo;Free,&rdquo; &ldquo;Starter&rdquo;, &ldquo;Advancer&rdquo;,
                &ldquo;Pro&rdquo; , and &quot;Enterprise&quot; plan (collectively &ldquo;Plans&rdquo; or
                &ldquo;Subscription&rdquo;).
              </p>
              <p>
                A detailed description of Company Subscriptions, including the prices and the functions associated
                with each type of Subscription, is available on our Plans page or in Help Center. Company reserves
                the right to change the Subscriptions published on the Plans page (e.g add or remove Plans) at any
                given moment. When removing a Plan, the Company shall endeavor to give notice to those who might be
                affected by such actions.&nbsp;
              </p>
              <p>
                The Subscriptions available at the Plans page are subject to these Terms of Service. By accepting
                these Terms of Service, you also confirm that you agree with the Subscriptions features conditions
                as described on the Plans page.
              </p>
              <p>
                Company reserves the right to, at their full discretion, provide Services to Clients based on
                customized Plans (&ldquo;Customized Plans&rdquo;). Customized Plans will not be displayed on the
                Plans page and will be offered to Clients on an individual basis. Customized Plans are subject to
                these Terms of Use.
              </p>
              <p>
                To purchase the Subscription, other than a Customized Plan, select the Subscription you want to
                purchase on our website&rsquo;s Plans page or Client Account&rsquo;s &ldquo;Subscription&rdquo;
                tab, choose the preferred payment method. All payments made with debit/credit cards and Paypal are
                processed by our merchant of record &ndash; stripe.com. By purchasing a Subscription using any of
                the said payment methods, You also enter into an agreement with stripe.com. Your relationship with
                Stripe is governed by Stripe&rsquo;s Terms and Conditions available at{' '}
                <a href="https://stripe.com/legal/consumer">https://stripe.com/legal/consumer</a>. Prior to
                clicking the &ldquo;Pay&rdquo; button, you must make sure that you accept these Terms of Use and
                the Privacy Policy. Further, you must confirm that you are at least 18 years old, and you agree
                with receiving the Subscription functions as of the conclusion of the Purchase Agreement. Selecting
                the Subscription, term of the Subscription (for example, a month or a year) and submitting your
                payment information is an offer to conclude an agreement with Company for the use of the Software
                functions provided under the selected Subscription on the basis of these Terms of Use. The offer
                must be accepted by us. We may choose not to accept the offer at our sole discretion. The Purchase
                Agreement will be accepted at such time at which you receive confirmation from us, or we activate
                your selected Subscription functions.
              </p>
              <p>
                If you wish to upgrade your Subscription, you may do so at any time through your Client
                Account&rsquo;s &ldquo;Subscription&rdquo; tab. Your new Subscription will start after the payment
                has been processed. Your new Subscription will be activated immediately after processing of your
                payment, irrespective of any remaining time on your old Subscription. Ordering a new Subscription
                will result in the immediate termination of the Purchase Agreement in relation to your old
                Subscription, and the conclusion of a new Purchase Agreement for the new Subscription.&nbsp;
              </p>
              <br></br>
              <p>
                <strong>Refund Policy:</strong>
              </p>
              <br></br>
              <p>
                We understand that circumstances may change, and you may need to adjust your subscription to our
                Services. However, please note that all subscription fees paid to us are non-refundable.
              </p>
              <br></br>
              <p>Cancellation of Subscription:</p>
              <br></br>
              <p>
                While we do not offer refunds for subscription fees, you have the flexibility to cancel your
                subscription at any time through our Subscription page. Once you cancel your subscription, you will
                no longer be billed for future subscription periods, and access to the Services will continue until
                the end of the current billing cycle. To cancel your subscription, simply log in to your account on
                our website and navigate to the Subscription page. Follow the instructions provided to cancel your
                subscription. Upon cancellation, you will retain access to the Services until the end of your
                current billing period. After the current billing period ends, your access to the Services will be
                terminated unless you choose to subscribe again.
              </p>
              <br></br>
              <p>Changes to Subscription:</p>
              <p>
                <br></br>
                You have the flexibility to upgrade or downgrade your subscription plan at any time through our
                Subscription page. Changes to your subscription plan will take effect immediately, and you will be
                charged or refunded the prorated difference in subscription fees for the current billing period.
              </p>
              <p>Contact Us:</p>
              <p>
                If you have any questions or concerns regarding our refund policy or the cancellation process,
                please contact our customer support team at support@epicflow.io. We&apos;re here to assist you with
                any inquiries you may have.
              </p>
              <p>
                We reserve the right to update or modify this refund policy at any time without prior notice. Any
                changes to the refund policy will be effective immediately upon posting on our website. It is your
                responsibility to review the refund policy periodically for any updates or changes.
              </p>
              <br></br>
              <p>
                <strong>Digital Millennium Copyright Act (&quot;DMCA&quot;) NOTICE</strong>
              </p>
              <br></br>
              <p>
                The Company respects the intellectual property rights of others. Per the DMCA, we will respond
                expeditiously to claims of copyright infringement on the Site if submitted to our Copyright Agent
                as described below. Upon receipt of a notice alleging copyright infringement, the Company will take
                whatever action it deems appropriate within its sole discretion, including removal of the allegedly
                infringing IP and termination of access for repeat infringers of copyright protected content.
              </p>
              <p>
                Procedure for Notifying the Company of Copyright Infringement. If you believe that your
                intellectual property rights have been violated by us or by a third party who has uploaded IP to
                our website, please provide the following information to the designated Copyright Agent listed
                below:
              </p>
              <p>
                (i) A description of the copyrighted work or other intellectual property that you claim has been
                infringed;
              </p>
              <p>(ii) A description of where the material that you claim is infringing is located on the Site;</p>
              <p>
                (iii) An address, telephone number, and email address where we can contact you and, if different,
                an email address where the alleged infringing party, if not us, can contact you;
              </p>
              <p>
                (iv) A statement that you have a good-faith belief that the use is not authorized by the copyright
                owner or other intellectual property rights owner, by its agent, or by law;
              </p>
              <p>
                (v) A statement by you under penalty of perjury that the information in your notice is accurate and
                that you are the copyright or intellectual property owner or are authorized to act on the
                owner&apos;s behalf;
              </p>
              <p>(vi) Your electronic or physical signature.</p>
              <p>
                We may request additional information before removing any allegedly infringing material. In the
                event we remove the allegedly infringing IP, we will immediately notify the person responsible for
                posting such IP that we removed or disabled access to the IP. We may also provide the responsible
                person with your email address so that the person may respond to your allegations.
              </p>
              <p>Pursuant to 17 U.S.C. 512(c). the Company&rsquo;s designated Copyright Agent is:</p>
              <p>Northwest Registered Agent Service, Inc.</p>
              <p>Address:8 The Green, STE B, Dover, Delaware, 19901,&nbsp;United&nbsp;States</p>
              <p>Email: info@epicflow.io</p>
              <p>
                <br />
                <br />
              </p>
              <br></br>
              <p>
                <strong>Dispute Resolution</strong>.&nbsp;
              </p>
              <br></br>
              <p>
                Arbitration. Any dispute, controversy or claim arising out of or related in any way to this
                Agreement, or any Services performed hereunder which cannot be amicably resolved by the Parties
                shall be solely and finally settled by arbitration administered by the American Arbitration
                Association in accordance with its Expedited Commercial Arbitration Rules (the
                &ldquo;Rules&rdquo;). The arbitration shall be conducted before a single arbitrator (the
                &ldquo;Arbitrator&rdquo;) who shall sign an oath agreeing to be bound by the code of ethics for
                arbitrators in commercial disputes promulgated by the AAA for neutral arbitrators. Arbitration
                shall take place in the State of Delaware, except that as permitted by the Rules, the meeting of
                the Parties and the Arbitrator shall be conducted virtually. The decision of the Arbitrator shall
                be in writing with written findings of fact and shall be final and binding on the Parties. This
                Section provides the sole recourse for the settlement of any disputes arising out of, in connection
                with, or related to this Agreement, except as may be otherwise stated herein. You have the right to
                opt out of binding arbitration within 30 days of the date you first accepted the Terms of Service,
                by emailing us at support@epicflow.io. In order to be effective, the opt-out notice must include
                your full name and address and clearly indicate your intent to opt out of binding arbitration. By
                opting out of binding arbitration, you are agreeing to resolve disputes in accordance with this
                paragraph.
              </p>
              <p>
                Governing Law, Venue. These Terms shall be governed by and construed in accordance with the
                internal laws of the State of Delaware applicable to agreements made and to be performed in the
                State of Delaware. Any legal suit, action or proceeding arising out of or based upon Arbitration or
                otherwise contemplated hereby (&ldquo;Related Proceedings&rdquo;) may only be instituted in the
                state or federal courts of the State of Delaware (collectively, the &ldquo;Specified
                Courts&rdquo;), and each Party irrevocably submits to the exclusive jurisdiction of such courts in
                any such suit, action or proceeding. Service of any process, summons, notice, or document by mail
                to such Party&rsquo;s address shall be effective service of process for any suit, action or other
                proceeding brought in any such court. The Parties irrevocably and unconditionally waive any
                objection to the laying of venue of any suit, action or other proceeding in the Specified Courts
                and irrevocably and unconditionally waive and agree not to plead or claim in any such court that
                any such suit, action or other proceeding brought in any such court has been brought in an
                inconvenient forum. THE PARTIES EXPRESSLY AND KNOWINGLY WAIVE ANY RIGHT TO A JURY TRIAL IN THE
                EVENT ANY ACTION ARISING UNDER OR IN CONNECTION WITH THESE TERMS IS LITIGATED OR HEARD IN ANY
                COURT.
              </p>
              <br></br>
              <p>
                <strong>Attorney Fees.</strong>
              </p>
              <br></br>
              <p>
                In the event that any dispute between the parties should result in litigation or arbitration, the
                prevailing party in such dispute shall be entitled to recover from the other party all reasonable
                fees, costs and expenses of enforcing any right of the Prevailing Party, including without
                limitation, reasonable attorneys&apos; fees and expenses, all of which shall be deemed to have
                accrued upon the commencement of such action and shall be paid whether or not such action is
                prosecuted to judgment. Any judgment or order entered in such action shall contain a specific
                provision providing for the recovery of attorney fees and costs incurred in enforcing such judgment
                and an award of prejudgment interest from the date of the breach at the maximum rate allowed by
                law. For the purposes of this Section: (a) attorney fees shall include, without limitation, fees
                incurred in the following: (1) post judgment motions, (2) contempt proceedings, (3) garnishment,
                levy, and debtor and third party examinations, (4) discovery, and (5) bankruptcy litigation; and
                (b) &ldquo;
                <strong>Prevailing Party</strong>&rdquo; shall mean the party who is determined in the proceeding
                to have prevailed or who prevails by dismissal, default or otherwise.
              </p>
              <br></br>
              <p>
                <strong>Miscellaneous.</strong>
              </p>
              <br></br>
              <p>
                These Terms constitute the entire agreement between the parties and contains all of the agreements
                between the parties with respect to the subject matter hereof; these Terms supersedes any and all
                other agreements, either oral or in writing (including any interim agreements executed by the
                parties), between the parties hereto with respect to the subject matter hereof. No change or
                modification of these Terms shall be valid unless the same be in writing and signed by both
                Parties, respectively. All notices and other communications hereunder shall be in writing and shall
                be deemed given on delivery if delivered personally or sent by facsimile transmission with
                electronic confirmation, or four (4) business days following such notice being sent, if mailed by
                registered or registered, postage prepaid, to the other Party at the addresses as last reported to
                the other Party. Company is and shall remain an independent contractor of Users and nothing
                contained in these Terms shall be deemed to create an employer/employee, principal/agent,
                partnership, or joint venture relationship between the Parties Neither Party will have the right to
                assign, pledge or transfer all or any part of these Terms without the prior written consent of the
                other, except that Company may assign its rights and interests to another party without the
                approval of any User. If any provision of these Terms is held by a competent court to be invalid or
                unenforceable under applicable law, then such provision shall be severed from these Terms and the
                remainder of these Terms shall be interpreted as if such provision were so severed and shall be
                enforceable in accordance with its terms. Those provisions of these Terms which by their nature
                should survive termination, and all accrued and unpaid obligations arising hereunder, shall survive
                the expiration or termination of these Terms for any reason. No delay or omission by either party
                to exercise any right or power it has under these Terms shall impair or be construed as a waiver of
                such right or power. A waiver by either party of any covenant or breach shall not be construed to
                be a waiver of any succeeding breach or of any other covenant. All waivers must be in writing and
                signed by the party waiving its rights. The paragraph headings of these Terms are inserted for
                convenience only and shall not constitute a part of these Terms for the purposes of construing or
                interpreting any provision hereof. Whenever the context requires, words used in the singular shall
                be construed to include the plural and vice versa, and pronouns of any gender shall be deemed to
                include and designate the masculine, feminine or neutral gender. These Terms shall inure to the
                benefit of and be binding upon the respective successors and assigns of the Parties. Nothing in
                these Terms, express or implied, is intended to confer upon any party other than the Parties hereto
                or their respective successors and assigns any rights, remedies, obligations, or liabilities under
                or by reason of these Terms, except as expressly provided in these Terms. Each Party shall use all
                reasonable efforts to take all actions necessary or desirable to consummate and make effective the
                transactions these Terms contemplates or to evidence or carry out the intent and purposes of these
                Terms.
              </p>
              <br></br>
              <p>
                <strong>Customer Service</strong>. If you have any comments or questions regarding these Terms or
                wish to report any violation of these Terms, you may contact us at support@epicflow.io. However, we
                only provide support services for the operation of the Software. Should you become aware of misuse
                of the Software including libelous or defamatory conduct, you must report it to Company. We
                recommend contacting us for assistance if you experience any issues regarding the Software in the
                following ways:
              </p>
              <br></br>
              <p>By sending email to support@epicflow.io.</p>
              <br></br>
              <p>
                By following one of the ways communicated via the homepage i.e. Social media channels or chat tools
                if applicable.
              </p>
            </>
          </Flex>
        </ModalBody>
        <ModalFooter w="90%" m="auto" alignItems="center" mb={6} mt={2} justifyContent="space-between">
          <Checkbox onChange={handleCheckBox} isChecked={isChecked}>
            <Text fontSize="13px">I have read and accepted all terms and conditions</Text>
          </Checkbox>
          <Button
            w="30%"
            fontSize="13px"
            isDisabled={!isChecked}
            color={colorMode === 'light' ? 'white' : 'black'}
            bg={colorMode === 'light' ? 'black' : 'white'}
            _hover={{
              bg: colorMode === 'light' ? 'black' : 'white'
            }}
            _focus={{
              outline: 'none'
            }}
            _active={{
              bg: colorMode === 'light' ? 'black' : 'white'
            }}
            onClick={handleAcceptToS}
            //   _active={{ background: epicFlowGradientColors.right }}
          >
            Accept
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TOS;
