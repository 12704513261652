import { TimeIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  Heading,
  Icon,
  Text,
  useColorMode,
  useDisclosure
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import axios from '../../utilities/axios';
import SubInfo from '../modals/subInfo';

const API_URL = process.env.REACT_APP_API_URL;
//const DEV_API_URL = 'http://localhost:5020/';

function SubscriptionCardSmall({
  isDowngraded,
  price,
  stripeCustomerId,
  customerCurrentSubscription,
  interval,
  isFreeCard,
  isSchedule,
  isSelected,
  handleSelection
}) {
  const { colorMode, toggleColorMode } = useColorMode();

  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isCurrentPlan, setIsCurrentPlan] = useState(false);
  const [isPaidPlan, setIsPaidPlan] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isModalError, setIsModalError] = useState(false);
  const [isModalResult, setIsModalResult] = useState();

  const { isOpen: isSubInfoOpen, onOpen: onSubInfoOpen, onClose: onSubInfoClose } = useDisclosure();

  useEffect(() => {
    if (customerCurrentSubscription?.plan.id === price.id) {
      setIsCurrentPlan(true);
    }
    if (customerCurrentSubscription?.plan.amount > 0) {
      setIsPaidPlan(true);
    }
    if (!customerCurrentSubscription) {
      setIsCurrentPlan(false);
    }
  }, [customerCurrentSubscription]);

  const handleModalConfirm = async (priceId, stripeCustomerId) => {
    try {
      setIsModalLoading(true);
      if (
        customerCurrentSubscription?.plan.amount !== 0 &&
        price.product.name.toUpperCase() !== 'FREE' &&
        price.unit_amount > customerCurrentSubscription?.plan.amount
      ) {
        const reqBody = {
          customerId: stripeCustomerId,
          priceId: priceId,
          action: 'upgrade',
          subscriptionId: customerCurrentSubscription.id
        };
        const response = await axios.post(`${API_URL}checkout/update-subscription`, reqBody);
        // console.log('Upgrade response ', response.data);
        setIsModalLoading(false);
        if (response.data.error) {
          setIsModalError(response.data.error.message);
        } else {
          setIsModalResult(response.data);
        }
      } else if (
        customerCurrentSubscription?.plan.amount !== 0 &&
        price.product.name.toUpperCase() !== 'FREE' &&
        price.unit_amount < customerCurrentSubscription?.plan.amount
      ) {
        const reqBody = {
          customerId: stripeCustomerId,
          priceId: priceId,
          action: 'downgrade',
          subscriptionId: customerCurrentSubscription.id
        };
        const response = await axios.post(`${API_URL}checkout/update-subscription`, reqBody);
        // console.log('Downgrade response ', response.data);
        setIsModalLoading(false);
        if (response.data.error && response.data.error.message !== 'Assignment to constant variable.') {
          setIsModalError(response.data.error.message);
        } else {
          setIsModalResult(response.data);
        }
      }
    } catch (error) {
      setIsModalError(error.message);
    }
  };

  const handleModalClose = () => {
    if (isModalResult) {
      onSubInfoClose();
      return window.location.reload();
    }
    setIsModalError(false);
    setIsModalLoading(false);
    setIsUpgrade(false);
    setIsModalResult();
    onSubInfoClose();
  };

  const createCheckoutSession = async (priceId, stripeCustomerId) => {
    try {
      const reqBody = { customerId: stripeCustomerId, priceId: priceId };
      // console.log(priceId, stripeCustomerId);
      const createdCheckoutSession = await axios
        .post(`${API_URL}checkout/create-checkout-session`, reqBody)
        .then(response => response.data);
      if (createdCheckoutSession.url) {
        window.location.replace(`${createdCheckoutSession.url}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleSubscription = async (priceId, stripeCustomerId) => {
  //   // if (customerCurrentSubscription) {
  //   //   console.log('priceid is', priceId);
  //   //   console.log('customerCurrentSubscription is ', customerCurrentSubscription);
  //   //   await updateSubscription(customerCurrentSubscription, priceId);
  //   // } else {
  //   //   await createSubscription(priceId, stripeCustomerId, productName);
  //   // }
  //   // await createSubscription(priceId, stripeCustomerId, productName);

  //   // Method with cancelling and refunding
  //   // if (customerCurrentSubscription) {
  //   //   await refundToCustomer(customerCurrentSubscription);
  //   //   await cancelSubscription(customerCurrentSubscription);
  //   //   await createCheckoutSession(priceId, stripeCustomerId);
  //   // } else {
  //   //   await createCheckoutSession(priceId, stripeCustomerId);
  //   // }

  //   // Only creating checkout session
  //   console.log('creating checkout session...');
  //   await createCheckoutSession(priceId, stripeCustomerId);
  // };

  const handleSubscription = async (priceId, stripeCustomerId) => {
    if (
      customerCurrentSubscription?.plan.amount !== 0 &&
      price.product.name.toUpperCase() !== 'FREE' &&
      price.unit_amount > customerCurrentSubscription?.plan.amount
    ) {
      setIsUpgrade(true);
      onSubInfoOpen();
    } else if (
      customerCurrentSubscription?.plan.amount !== 0 &&
      price.product.name.toUpperCase() !== 'FREE' &&
      price.unit_amount < customerCurrentSubscription?.plan.amount
    ) {
      setIsUpgrade(false);
      onSubInfoOpen();
    } else {
      await createCheckoutSession(priceId, stripeCustomerId);
    }
  };

  const determineButtonText = () => {
    if (isCurrentPlan) {
      return 'Current Plan';
    } else if (
      customerCurrentSubscription?.plan.amount !== 0 &&
      price.product.name.toUpperCase() !== 'FREE' &&
      price.unit_amount > customerCurrentSubscription?.plan.amount
    ) {
      return 'Select';
    } else if (
      customerCurrentSubscription?.plan.amount !== 0 &&
      price.product.name.toUpperCase() !== 'FREE' &&
      price.unit_amount < customerCurrentSubscription?.plan.amount
    ) {
      return 'Select';
    } else {
      return 'Select';
    }
  };

  useEffect(() => {
    if (
      customerCurrentSubscription?.plan.amount !== 0 &&
      price.product.name.toUpperCase() !== 'FREE' &&
      price.unit_amount > customerCurrentSubscription?.plan.amount
    ) {
      setIsUpgrade(true);
    } else if (
      customerCurrentSubscription?.plan.amount !== 0 &&
      price.product.name.toUpperCase() !== 'FREE' &&
      price.unit_amount < customerCurrentSubscription?.plan.amount
    ) {
      setIsUpgrade(false);
    }
  }, [customerCurrentSubscription]);

  if (subscriptionData) {
    return (
      <Redirect
        to={{
          pathname: '/subscribe',
          state: subscriptionData
        }}
      />
    );
  }

  // console.log('reqBody is', JSON.stringify({ customerId: stripeCustomerId, priceId: price.Id }));
  return (
    <Flex
      //   width={['70%', '70%', '70%', '30%']}
      flexDirection="column"
      align="flex-start"
      justify="flex-start"
      //   boxShadow="dark-lg"
      p={6}
      // px={10}
      borderWidth={1}
      rounded="xl"
      _hover={{
        cursor: 'pointer'
      }}
      mx={1}
      my={4}
      w={56}
      onClick={handleSelection}
      borderColor={
        isSelected && colorMode === 'light' ? 'black' : isSelected && colorMode === 'dark' ? 'white' : ''
      }
      // borderWidth={1}
    >
      {/* <SubInfo
        title="Change Plan"
        onclose={handleModalClose}
        isopen={isSubInfoOpen}
        upgrade={isUpgrade}
        onClick={() => handleModalConfirm(price.id, stripeCustomerId)}
        result={isModalResult}
        loading={isModalLoading}
        error={isModalError}
        currentSub={customerCurrentSubscription?.plan.id}
      /> */}
      {/* <Flex bg="gray.100" rounded="md"> */}
      <Flex w="full" justify="space-between" alignItems="center">
        <Text fontSize="sm" fontWeight="semibold" noOfLines={1}>
          {price.product.name.toUpperCase()}
        </Text>
        {isDowngraded ? (
          <Flex
            userSelect="none"
            py={2}
            px={3}
            ml={3}
            justify="center"
            alignItems="center"
            borderRadius="lg"
            borderWidth={1}
          >
            <Text fontSize="xs" fontWeight="medium" color={colorMode === 'light' ? 'gray.500' : 'gray.300'}>
              Scheduled
            </Text>
            <TimeIcon ml={2} boxSize={4} mb="2px" color={colorMode === 'light' ? 'gray.500' : 'gray.300'} />
          </Flex>
        ) : null}
      </Flex>
      <Flex direction="column" userSelect="none">
        <Heading size="lg" mt={2}>
          {interval === 'month'
            ? `$${price.unit_amount / 100}`
            : price.product.name !== 'Free'
            ? `$${(price.unit_amount / 100 / 12).toFixed(2)}`
            : `$${price.unit_amount / 100 / 12}`}
          {/* ${price.unit_amount / 100} */}
        </Heading>
        <Text fontSize="sm" mt={4} fontWeight="semibold">
          {interval === 'month' ? 'Per month' : 'Per month'}
        </Text>
        <Text
          fontSize="13px"
          mt={2}
          mb={
            isDowngraded ||
            (price.product.name === 'Premium' && customerCurrentSubscription?.plan.amount < price.unit_amount)
              ? 0
              : 0
          }
        >
          {interval === 'month' && price.product.name !== 'Free' ? (
            'Billed monthly'
          ) : price.product.name !== 'Free' ? (
            `Billed as $${price.unit_amount / 100} per year`
          ) : (
            <br></br>
          )}
        </Text>
      </Flex>
      <Flex w="full" mt={4} direction="column" justify="space-between" h="full" alignItems="center">
        <Button
          // colorScheme="messenger"
          //   bg={isCurrentPlan ? 'green' : 'messenger'}
          bg={colorMode === 'light' ? 'black' : 'white'}
          color={colorMode === 'light' ? 'white' : 'black'}
          width="full"
          fontWeight="semibold"
          fontSize="sm"
          py={6}
          m={2}
          _hover={{
            bg: colorMode === 'light' ? 'black' : 'white'
          }}
          _active={{
            outline: 'none'
          }}
          _focus={{
            outline: 'none'
          }}
          // onClick={onSubInfoOpen}
          //   onClick={() =>
          //     handleSubscription(price.id, stripeCustomerId, price.product.name, customerCurrentSubscription)
          //   }
          disabled={isSchedule || isCurrentPlan || (isPaidPlan && price.product.name.toUpperCase() === 'FREE')}
        >
          {/* Select Plan */}
          {determineButtonText()}
        </Button>
      </Flex>
    </Flex>
  );
}

export default withRouter(SubscriptionCardSmall);
