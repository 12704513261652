import {
  Box,
  Button,
  Center,
  chakra,
  Checkbox,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Image,
  Input,
  Link,
  List,
  ListIcon,
  ListItem,
  Spacer,
  Text,
  useColorMode,
  VStack
} from '@chakra-ui/react';
import { Auth, Cache } from 'aws-amplify';
// import axios from 'axios';
import regularAxios from 'axios';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { AiFillCheckCircle, AiFillExclamationCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import validator from 'validator';

import EFLogo from '../../assets/eflogo.svg';
import SocialLogin from '../../components/UI/Button/socialLoginContainer';
import { singup } from '../../store/actions/auth';
import { epicFlowGradientColors } from '../../theme';
// import { useNavigate } from 'react-router-dom';
// import FormField from '../components/FormField';

const serializePhone = p =>
  !!p
    ? Array.from(p).reduce(
        (acc, c) => (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(c) ? acc + c : acc),
        '+1'
      )
    : '';

const url = window.location.search.slice(8);
const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const SignUp = ({ signUpFields, redirectToVerification, socialProviders, history, isAuth }) => {
  const [errorMessage, setErrorMessage] = useState(undefined);

  // const [isChecked, setIsChecked] = useState(false);

  const { colorMode, toggleColorMode } = useColorMode();

  const [recaptcha, setRecaptcha] = useState(false);

  const [isRecaptchaError, setIsRecaptchaError] = useState(false);

  const [passwordRequirements, setPasswordRequirements] = useState({
    isMinLength: false,
    isContainsUpperCase: false,
    isContainsLowerCase: false,
    isContainsSpecialCharacter: false,
    isContainsNumber: false
  });

  // useEffect(() => {
  //   if (history.location.search.slice() !== '') {
  //     localStorage.setItem('ref_id', history.location.search.slice(7));
  //   } else {
  //     localStorage.setItem('ref_id', '');
  //   }
  //   console.log('ddd', history.location.search.slice(8));
  // }, []);

  const dispatch = useDispatch();

  // const navigate = useNavigate();

  const checkUrl = useCallback(async () => {
    try {
      let response = await fetch(`${process.env.REACT_APP_API_URL}checkrefid`, {
        method: 'POST',
        body: JSON.stringify({
          refId: url
        }),
        headers: { 'Content-Type': 'application/json' }
      });
      let data = await response.json();
      if (data.isRefIdExist) {
        if (localStorage.getItem('ref_id') === history.location.search.slice(8)) {
          return;
        } else {
          localStorage.setItem('ref_id', history.location.search.slice(8));
        }
      } else {
        if (history.location.search !== '') {
          history.replace('/signup');
          return null;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (history.location.search.slice(1, 7) === 'ref_id') {
      checkUrl();
    }
  }, []);

  const {
    register,
    handleSubmit,
    getFieldState,
    formState: { errors }
  } = useForm({
    defaultValues: { email: '', name: '', password: '' }
  });

  // const handleSignUp = formData => onSignUp(formData);

  const checkAuth = () => {
    if (isAuth) {
      history.push('/');
    }
  };

  checkAuth();

  const validatePassword = value => {
    setPasswordRequirements({
      isMinLength: validator.isStrongPassword(value, {
        minLength: 8,
        minUppercase: 0,
        minLowercase: 0,
        minSymbols: 0,
        minNumbers: 0
      }),
      isContainsUpperCase: validator.isStrongPassword(value, {
        minLength: 1,
        minUppercase: 1,
        minLowercase: 0,
        minSymbols: 0,
        minNumbers: 0
      }),
      isContainsLowerCase: validator.isStrongPassword(value, {
        minLength: 1,
        minUppercase: 0,
        minLowercase: 1,
        minSymbols: 0,
        minNumbers: 0
      }),
      isContainsSpecialCharacter: validator.isStrongPassword(value, {
        minLength: 1,
        minUppercase: 0,
        minLowercase: 0,
        minSymbols: 1,
        minNumbers: 0
      }),
      isContainsNumber: validator.isStrongPassword(value, {
        minLength: 1,
        minUppercase: 0,
        minLowercase: 0,
        minSymbols: 0,
        minNumbers: 1
      })
    });
    return validator.isStrongPassword(value, {
      minLength: 8,
      minUppercase: 1,
      minLowercase: 1,
      minSymbols: 1,
      minNumbers: 1
    });
  };

  // useEffect(() => {
  //   console.log(getFieldState('email'));
  //   console.log(getFieldState('name'));
  //   console.log(getFieldState('password'));
  // }, [getFieldState('email'), getFieldState('name'), getFieldState('password')]);

  const handleSignUp = formData => {
    let attributes = {};

    if (signUpFields) {
      signUpFields.forEach(f => {
        if (f.name === 'phone_number') {
          attributes[f.name] = serializePhone(formData[f.name]);
        } else {
          attributes[f.name] = formData[f.name];
        }
      });
    }
    // https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/
    Auth.signUp({
      username: formData.email,
      password: formData.password,
      attributes
    })
      .then(async res => {
        // console.log(res);
        dispatch(singup(formData.name, res.user.username, res.userSub));
        await redirectToVerification(formData.email);
      })
      .then(() => history.push(`verify/accountname?=${formData.email}`))
      .catch(error => {
        // console.log(error);
        if (error.code === 'UserLambdaValidationException') {
          const errorMessageArray = error.message.split(' ');
          // console.log(errorMessageArray);
          if (errorMessageArray.includes('GoogleUserExist.')) {
            setErrorMessage('This email address uses Google Sign-In.');
          } else if (errorMessageArray.includes('CognitoUserExist.')) {
            setErrorMessage('There is already a user with this email, please sign up with email and password!');
          }
        } else if (error.code === 'UsernameExistsException') {
          setErrorMessage('There is already a user with this email, please sign up with email and password!');
        }
      });

    // console.log('errorMessage1 is', errorMessage);
    // navigate('/verification');
  };

  const handleGoogleSignin = async () => {
    try {
      const res = await Auth.federatedSignIn({ provider: 'Google' });
    } catch (error) {
      console.log(`Google login error --->`, error);
    }
  };

  const handleAppleSignin = () => Auth.federatedSignIn({ provider: 'SignInWithApple' });

  const onReCaptchaChange = async value => {
    console.log('in signup.js :: onReCaptchaChange');
    console.log('value : ', value);
    const body = {
      token: value
    };
    const response = await regularAxios.post(`${process.env.REACT_APP_API_URL}verifyrecaptcha`, body);
    const data = response.data;
    if (data) {
      setRecaptcha(true);
    } else {
      setRecaptcha(false);
    }
  };

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      pt={[18, 18, 28, 28, 28, 28]}
      w="full"
      bg={colorMode === 'light' ? 'white' : '#000'}
      h="full"
      minH="100vh"
    >
      <Flex width="100%" fontSize="3xl" justifyContent="center" alignItems="center">
        <Flex align="center" justify="center" width="fit-content">
          <Image src={EFLogo} boxSize={16} objectFit="contain" />
        </Flex>
      </Flex>
      <VStack w={['full', 'full', 'full', 'full', '80%', '80%']} spacing="10px">
        <Center fontWeight="bold" mb={1} mt={2}>
          Request access to create awesome forex bots.
        </Center>

        <SocialLogin
          providers={socialProviders}
          onGoogleSignIn={handleGoogleSignin}
          // onAppleSignIn={handleAppleSignin}
        />

        <FormControl
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          w={['85%', '85%', '85%', 'full', 'full', 'full']}
        >
          <Flex flexDirection="column" justify="center" w="full" maxW="450px" mb={4}>
            <FormLabel htmlFor="email" fontSize="sm" fontWeight="semibold">
              Email
            </FormLabel>
            <Input
              border="2px"
              type="email"
              id="email"
              name="email"
              className={clsx('form-control', errors.email && 'border-red-600')}
              {...register('email', {
                required: 'Email is required',
                validate: value => validator.isEmail(value)
              })}
            />
            {errors.email?.message ? (
              <chakra.span className="form-feedback-invalid" color="red.500" fontSize="sm" fontWeight="medium">
                {errors.email?.message}
              </chakra.span>
            ) : errors.email?.type === 'validate' ? (
              <chakra.span className="form-feedback-invalid" color="red.500" fontSize="sm" fontWeight="medium">
                Please enter an valid email!
              </chakra.span>
            ) : null}
          </Flex>

          <Flex flexDirection="column" justify="center" align="flex-start" mb={4} maxW="450px" w="full">
            <FormLabel width="full" htmlFor="name" fontSize="sm" fontWeight="semibold">
              Name
            </FormLabel>
            <Input
              border="2px"
              type="name"
              id="name"
              name="name"
              className={clsx('form-control', errors.name && 'border-red-600')}
              {...register('name', {
                required: 'Name is required!',
                maxLength: {
                  value: 20,
                  message: 'Name should be at least 3 characters'
                }
              })}
            />
            {errors.name?.message && (
              <chakra.span className="form-feedback-invalid" color="red.500" fontSize="sm" fontWeight="medium">
                {errors.name?.message}
              </chakra.span>
            )}
            {/* {errors.name?.type === 'required' ? (
              <chakra.span className="form-feedback-invalid" color="red.500" fontSize="sm" fontWeight="normal">
                This field is required!
              </chakra.span>
            ) : (
              <chakra.span className="form-feedback-invalid" color="red.500" fontSize="sm" fontWeight="normal">
                {errors.name?.message}
              </chakra.span>
            )} */}
          </Flex>

          <Flex flexDirection="column" justify="center" align="flex-start" mb={4} maxW="450px" w="full">
            <FormLabel width="full" htmlFor="password" fontSize="sm" fontWeight="semibold">
              Password
            </FormLabel>
            <Input
              border="2px"
              type="password"
              id="password"
              name="password"
              className={clsx(errors?.password && { borderColor: 'red' })}
              {...register('password', {
                required: 'Password is required!',
                validate: value => validatePassword(value)
              })}
            />
            {errors.password?.message ? (
              <chakra.span className="form-feedback-invalid" color="red.500" fontSize="sm" fontWeight="medium">
                {errors.password?.message}
              </chakra.span>
            ) : errors.password?.type === 'validate' ? (
              <chakra.span className="form-feedback-invalid" fontSize="sm" fontWeight="medium">
                <List spacing={2} mt={2} mb={4}>
                  <ListItem color={passwordRequirements.isMinLength ? 'black' : 'red.500'}>
                    <ListIcon
                      as={passwordRequirements.isMinLength ? AiFillCheckCircle : AiFillExclamationCircle}
                      color={passwordRequirements.isMinLength ? 'green.500' : 'red.500'}
                    />
                    Password should be at least 8 characters long
                  </ListItem>
                  <ListItem color={passwordRequirements.isContainsNumber ? 'black' : 'red.500'}>
                    <ListIcon
                      as={passwordRequirements.isContainsNumber ? AiFillCheckCircle : AiFillExclamationCircle}
                      color={passwordRequirements.isContainsNumber ? 'green.500' : 'red.500'}
                    />
                    Password should contain at least 1 number
                  </ListItem>
                  <ListItem color={passwordRequirements.isContainsUpperCase ? 'black' : 'red.500'}>
                    <ListIcon
                      as={passwordRequirements.isContainsUpperCase ? AiFillCheckCircle : AiFillExclamationCircle}
                      color={passwordRequirements.isContainsUpperCase ? 'green.500' : 'red.500'}
                    />
                    Password should contain at least 1 upper case character
                  </ListItem>
                  <ListItem color={passwordRequirements.isContainsLowerCase ? 'black' : 'red.500'}>
                    <ListIcon
                      as={passwordRequirements.isContainsLowerCase ? AiFillCheckCircle : AiFillExclamationCircle}
                      color={passwordRequirements.isContainsLowerCase ? 'green.500' : 'red.500'}
                    />
                    Password should contain at least 1 lower case character
                  </ListItem>
                  <ListItem color={passwordRequirements.isContainsSpecialCharacter ? 'black' : 'red.500'}>
                    <ListIcon
                      as={
                        passwordRequirements.isContainsSpecialCharacter
                          ? AiFillCheckCircle
                          : AiFillExclamationCircle
                      }
                      color={passwordRequirements.isContainsSpecialCharacter ? 'green.500' : 'red.500'}
                    />
                    Password should contain at least 1 special character
                  </ListItem>
                </List>
              </chakra.span>
            ) : null}
            {/* {errors.password?.type === 'required' ? (
              <chakra.span className="form-feedback-invalid" color="red.500" fontSize="sm" fontWeight="normal">
                This field is required!
              </chakra.span>
            ) : (
              <chakra.span className="form-feedback-invalid" color="red.500" fontSize="sm" fontWeight="normal">
                {errors.password?.message}
              </chakra.span>
            )} */}

            <Flex
              // overflow="hidden"
              mt={4}
              borderWidth={isRecaptchaError ? 2 : 0}
              borderRadius={isRecaptchaError ? 'md' : 0}
              borderColor={isRecaptchaError ? 'red' : 0}
            >
              <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={onReCaptchaChange} />
            </Flex>
          </Flex>

          {/* <FormField
            name="email"
            label="Email Address"
            placeholder="This will be your username"
            validation={{
              required: 'Enter your email address',
            }}
            register={register}
            errors={errors}
          /> */}

          {/* <FormField
            name="password"
            type="password"
            label="Password"
            placeholder="Create a password at least 8 characters long"
            validation={{
              required: 'Password must be at least 8 characters',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters',
              },
            }}
            register={register}
            errors={errors}
          /> */}

          {errorMessage ? (
            <Flex justify="center" my={4}>
              <Text color="red.500" fontSize="sm" fontWeight="medium">
                {errorMessage}
              </Text>
            </Flex>
          ) : (
            ''
          )}
          <Button
            bg={epicFlowGradientColors.left}
            _hover={{
              backgroundColor: epicFlowGradientColors.left
            }}
            px={8}
            mt={4}
            color="white"
            w="full"
            maxW="450px"
            fontWeight="semibold"
            fontSize="sm"
            borderRadius="full"
            onClick={handleSubmit(handleSignUp)}
            isDisabled={!recaptcha}
          >
            REQUEST ACCESS
          </Button>
        </FormControl>
        <Flex justify="center" align="flex-start" my={5} maxW="450px" gap="2" w="full">
          {/* <Checkbox
                size="md"
                colorScheme="green"
                mt={0.5}
                isChecked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              /> */}
          <Text fontSize="13px">
            By clicking Request Access, Continue with Google, you certify that you are 18 years of age or older,
            and you agree to the{' '}
            <Link color="blue.600" target="_blank" href="https://www.epicflow.io/terms">
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link color="blue.600" target="_blank" href="https://www.epicflow.io/privacypolicy">
              Privacy Policy.
            </Link>
          </Text>
        </Flex>
        <Flex width="full" alignItems="center" justify="center" pb={4}>
          <Text mr={2} fontWeight="semibold" fontSize="sm">
            Have an account?
          </Text>

          <Text
            fontWeight="semibold"
            as="u"
            fontSize="sm"
            onClick={() => history.push('/login')}
            _hover={{ cursor: 'pointer' }}
          >
            Sign in
          </Text>
        </Flex>
      </VStack>
    </Flex>
  );
};

export default SignUp;
