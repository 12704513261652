import { Flex, Heading, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OandaCard from './oandaCard';

const OandaCardContainer = props => {
  const displayOptions = ['none', 'none', 'flex', 'flex', 'flex', 'flex'];
  const userState = useSelector(state => state.user);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      // console.log('mounting card container');
      mounted.current = true;
    } else {
      // console.log('updating card container');
      // do componentDidUpdate logic
    }
  });

  const calculateMU = asset => {
    if (userState.oandaData?.instruments[asset.market]) {
      if (asset.market.split('_')[0] === 'USD') {
        return (1000 * asset.units * Number(userState.oandaData?.instruments[asset.market].marginRate)).toFixed(2);
      } else if (asset.market === 'CAD_CHF' || asset.market === 'CAD_JPY') {
        return (
          1000 *
          asset.units *
          Number(userState.oandaData?.instruments[asset.market].marginRate) *
          (1 / userState.oandaData?.instruments[`USD_CAD`].currentPrice)
        ).toFixed(2);
      } else {
        return (
          1000 *
          asset.units *
          Number(userState.oandaData?.instruments[asset.market].marginRate) *
          userState.oandaData?.instruments[`${asset.market.split('_')[0]}_USD`].currentPrice
        ).toFixed(2);
      }
    } else {
      return 0;
    }
  };

  return (
    <Flex justify="flex-start" align="center" direction="column" w="95%" mb={[12, 12, 0, 0, 0, 0]}>
      <Flex justify="flex-start" align="center" w="full" mt={12} mb={4}>
        <Heading as="h2" size="md" textAlign="left">
          Positions
        </Heading>
      </Flex>
      <Flex w="full" m="auto" pt={5} pb={5} justify="space-between" alignItems="flex-start">
        <Heading fontSize="sm" fontWeight="semibold" textAlign="left" w="full" order={0}>
          Market
        </Heading>
        <Heading
          display="flex"
          justifyContent={['flex-end', 'flex-end', 'center']}
          fontSize="sm"
          fontWeight="semibold"
          w="full"
          textAlign={['right', 'right', 'center', 'center', 'center', 'center']}
          order={[2, 2, 1, 1, 1, 1]}
        >
          Type
        </Heading>
        <Heading
          display={displayOptions}
          justifyContent="center"
          fontSize="sm"
          fontWeight="semibold"
          w="full"
          textAlign="left"
          order={2}
        >
          Units
        </Heading>
        <Heading
          display={displayOptions}
          justifyContent="center"
          fontSize="sm"
          fontWeight="semibold"
          w="full"
          textAlign={['center', 'center']}
          order={2}
        >
          Margin In Use
        </Heading>
        <Heading
          order={[1, 1, 2, 2, 2, 2]}
          fontSize="sm"
          fontWeight="semibold"
          w="full"
          textAlign={['center', 'center', 'center']}
          // order={3}
        >
          Current Run
        </Heading>
        <Heading
          // order={[1, 1, 2, 2, 2, 2]}
          display={displayOptions}
          justifyContent="flex-end"
          fontSize="sm"
          fontWeight="semibold"
          w="full"
          textAlign={['center', 'center', 'right', 'right', 'right', 'right']}
          order={3}
        >
          Profit
        </Heading>
        {/* <Heading
          minW={36}
          maxW={36}
          order={[1, 1, 4, 4, 4, 4]}
          fontSize="sm"
          fontWeight="semibold"
          // w='full'
          textAlign={['center', 'center', 'right', 'right', 'right', 'right']}
        >
          Last 7 Days
        </Heading> */}
      </Flex>
      <Flex w="full" direction="column">
        {props.data?.map((asset, idx) => {
          return (
            <OandaCard
              key={idx}
              top
              to="/"
              index={idx}
              market={asset.market}
              rup={asset.resettablePL >= 0}
              // imgUrl={asset.image}
              type={asset.type}
              isSwitched={asset.isSwitched}
              units={asset.units}
              rpl={asset.resettablePL}
              upl={asset.unrealizedPL}
              up={asset.unrealizedPL >= 0}
              mu={calculateMU(asset)}
              // dailyUp={asset.price_change_percentage_24h >= 0}
              // to={handleRouting(asset.id)}
              // sparklineData={asset.sparkline_in_7d.price}
              // weekUp={
              //   asset.sparkline_in_7d.price[asset.sparkline_in_7d.price.length - 1] >=
              //   asset.sparkline_in_7d.price[0]
              // }
            />
          );
        })}
        {userState.oandaData && (
          <OandaCard
            top
            rup={userState.oandaData.resettablePL >= 0}
            // imgUrl={asset.image}
            units="TOTAL"
            rpl={userState.oandaData.resettablePL + userState.oandaData.financing}
            upl={userState.oandaData.unrealizedPL}
            up={userState.oandaData.unrealizedPL >= 0}
            mu={userState.oandaData.marginUsed}
            total
            // dailyUp={asset.price_change_percentage_24h >= 0}
            // to={handleRouting(asset.id)}
            // sparklineData={asset.sparkline_in_7d.price}
            // weekUp={
            //   asset.sparkline_in_7d.price[asset.sparkline_in_7d.price.length - 1] >=
            //   asset.sparkline_in_7d.price[0]
            // }
          />
        )}
      </Flex>
    </Flex>
  );
};

OandaCardContainer.propTypes = {
  data: PropTypes.array
};

export default OandaCardContainer;
