export const assetMenuItems = ['USD', 'BTC', 'ETH', 'BNB', 'XRP'];

export const availableExchanges = ['Binance US', 'Binance', 'Coinbase'];

export const availablePairMenuItems = ['BTC/USD', 'ETH/USD', 'BNB/USD'];

export const automationTypeMenuItems = ['Conservative', 'Moderate', 'Aggressive'];

export const exchangeMenuItems = ['Total', 'Binance US', 'Binance'];

export const fxPairs = ['EUR_USD', 'EUR_GBP', 'GBP_USD', 'USD_CHF', 'USD_CAD', 'EUR_CAD'];

export const fxDirections = ['LONG', 'SHORT'];
