import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const epicFlowGradientColors = {
  left: '#1874ff',
  right: '#00ccff',
  gradient: 'linear(to-r, #1874ff, #00ccff)'
};

export const darkThemeBackgroundColor = {
  dark: '#0f1114'
};

export const statusColors = {
  up: '#48BB78',
  down: '#ff5400',
  upFaded: 'rgba(72, 187, 120, 0.1)',
  downFaded: 'rgba(255, 84, 0, 0.1)'
};

const styles = {
  global: props => ({
    body: {
      fontFamily: 'body',
      color: mode('gray.800', 'whiteAlpha.900')(props),
      // bg: mode('#F3F4FD', '#1A1A1A')(props),
      bg: mode('#FFF', 'rgba(0,0,0,1)')(props),
      // {colorMode === 'light' ? 'rgba(256,256,256,0.7)' : 'rgba(16,16,16,1)'}
      lineHeight: 'base'
    }
  })
};

/// TODO Learn how to change component style
const components = {};

const theme = extendTheme({
  components,
  styles,
  fonts: {
    heading: navigator.vendor === 'Google Inc.' ? `'Noto Sans', sans-serif` : 'sans-serif',
    body: navigator.vendor === 'Google Inc.' ? `'Noto Sans', sans-serif` : 'sans-serif'
  }
});

export default theme;
