import * as actionTypes from '../actions/actionTypes';

const initialState = {
  addBotStatus: null,
  loading: false,
  activeBotsStatsLoading: false,
  activeBotsConfigLoading: false,
  playStats: {},
  playStatsLoading: false,
  playStatsError: null,
  activeBotsStats: [],
  activeBotsConfig: [],
  completedBots: [],
  descriptions: [],
  completedBotsError: null,
  completedBotsChecked: false,
  completedBotsLoading: false,
  activeBotsConfigChecked: false,
  activeBotsError: null,
  botTermination: null,
  modifyBotTerminationLoading: false,
  modifyTerminateError: null,
  isBotSocketOn: false,
  descriptionsLoading: false,
  descriptionsError: null,
  initialActiveBotsStats: [],
  initialActiveBotsConfig: [],
  initialError: null,
  initialLoading: false,
  fxSettings: null,
  fxLoading: false,
  fxError: null,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_BOT_START:
      return {
        ...state,
        addBotStatus: action.addBotStatus,
        loading: action.loading
      };
    case actionTypes.ADD_BOT_FAIL:
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    case actionTypes.ADD_BOT_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        addBotStatus: action.addBotStatus
      };
    case actionTypes.GET_ACTIVE_BOTS_STATS_START:
      return {
        ...state,
        activeBotsStatsLoading: true
      };
    case actionTypes.GET_ACTIVE_BOTS_STATS_FAIL:
      return {
        ...state,
        activeBotsStatsLoading: false,
        activeBotsError: action.error
      };
    case actionTypes.GET_ACTIVE_BOTS_STATS_SUCCESS:
      return {
        ...state,
        activeBotsStatsLoading: false,
        activeBotsStats: [...action.activeBotsStats],
        isBotSocketOn: action.isBotSocketOn
      };
    //
    case actionTypes.GET_DESCRIPTIONS_START:
      return {
        ...state,
        descriptionsLoading: true
      };
    case actionTypes.GET_DESCRIPTIONS_FAIL:
      return {
        ...state,
        descriptionsLoading: false,
        descriptionsError: action.error
      };
    case actionTypes.GET_DESCRIPTIONS_SUCCESS:
      return {
        ...state,
        descriptionsLoading: false,
        descriptions: action.descriptions
      };
    case actionTypes.GET_COMPLETED_BOTS_START:
      return {
        ...state,
        completedBotsLoading: action.loading
      };
    case actionTypes.GET_COMPLETED_BOTS_FAIL:
      return {
        ...state,
        completedBotsLoading: action.loading,
        completedBotsError: action.error
      };
    case actionTypes.GET_COMPLETED_BOTS_SUCCESS:
      return {
        ...state,
        completedBotsLoading: action.loading,
        completedBots: action.completedBots,
        completedBotsChecked: action.completedBotsChecked
      };
    case actionTypes.GET_PLAY_STATS_START:
      return {
        ...state,
        playStatsLoading: true
      };
    case actionTypes.GET_PLAY_STATS_FAIL:
      return {
        ...state,
        playStatsLoading: false,
        playStatsError: action.error
      };
    case actionTypes.GET_PLAY_STATS_SUCCESS:
      return {
        ...state,
        playStatsLoading: false,
        playStats: action.playStats
      };
    case actionTypes.GET_ACTIVE_BOTS_CONFIG_START:
      return {
        ...state,
        activeBotsConfigLoading: true
      };
    case actionTypes.GET_ACTIVE_BOTS_CONFIG_FAIL:
      return {
        ...state,
        activeBotsConfigLoading: false,
        activeBotsError: action.error
      };
    case actionTypes.GET_ACTIVE_BOTS_CONFIG_SUCCESS:
      return {
        ...state,
        activeBotsConfigLoading: false,
        activeBotsConfig: [...action.activeBotsConfig],
        activeBotsConfigChecked: action.activeBotsConfigChecked
      };
    case actionTypes.SET_BOT_MODIFY_TERMINATE_OPTIONS_START:
      return {
        ...state,
        modifyBotTerminationLoading: action.modifyBotTerminationLoading
      };
    case actionTypes.SET_BOT_MODIFY_TERMINATE_OPTIONS_FAIL:
      return {
        ...state,
        modifyBotTerminationLoading: action.modifyBotTerminationLoading,
        modifyTerminateError: action.modifyTerminateError
      };
    case actionTypes.SET_BOT_MODIFY_TERMINATE_OPTIONS_SUCCESS:
      return {
        ...state,
        modifyBotTerminationLoading: action.modifyBotTerminationLoading,
        botTermination: action.botTermination
      };
    case actionTypes.GET_INITIAL_ACTIVE_BOTS_STATS_START:
      return {
        ...state,
        initialLoading: action.loading
      };
    case actionTypes.GET_INITIAL_ACTIVE_BOTS_STATS_FAIL:
      return {
        ...state,
        initialLoading: action.loading,
        initialError: action.error
      };
    case actionTypes.GET_INITIAL_ACTIVE_BOTS_STATS_SUCCESS:
      return {
        ...state,
        initialActiveBotsStats: action.initialActiveBotsStats,
        initialActiveBotsConfig: action.initialActiveBotsConfig,
        initialLoading: action.loading
      };
    case actionTypes.GET_FX_BOT_SETTINGS_START:
      return {
        ...state,
        fxLoading: action.loading
      };
    case actionTypes.GET_FX_BOT_SETTINGS_FAIL:
      return {
        ...state,
        fxLoading: action.loading,
        fxError: action.error
      };
    case actionTypes.GET_FX_BOT_SETTINGS_SUCCESS:
      return {
        ...state,
        fxSettings: action.fxSettings,
        fxLoading: action.loading
      };
    default:
      return state;
  }
};

export default reducer;
