import { Flex, Image, Link, Skeleton, Text, useColorMode } from '@chakra-ui/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Link as RouterLink } from 'react-router-dom';

import { darkThemeBackgroundColor, epicFlowGradientColors } from '../../../theme';
import { titleCase } from '../../../utilities/stringConverters';

const MarketAssetCard = props => {
  const { colorMode, toggleColorMode } = useColorMode();

  const mobileDisplayOptions = ['none', 'none', 'flex', 'flex', 'flex', 'flex'];

  const CustomLink = useCallback(
    styled(Link)`
      &:hover {
        border-color: transparent;
        text-decoration: none;
        background-color: ${colorMode === 'light' ? '#F7FAFC' : '#171923'};
      }
      &:hover + a > div {
        border-top-color: transparent;
      }
    `,
    [colorMode]
  );

  const [data, setData] = useState({
    labels: props.sparklineData,
    datasets: [
      {
        label: '',
        data: props.sparklineData,
        fill: true,
        backgroundColor: 'transparent',
        borderColor: props.weekUp ? '#48BB78' : '#F56565',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        borderWidth: 2
      }
    ]
  });

  const [options, setOptions] = useState({
    layout: {
      padding: {
        right: 6
      }
    },
    legend: {
      display: false
    },
    hover: { mode: null },
    tooltips: {
      enabled: false
    },
    elements: {
      line: {
        tension: 0
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: false //this will remove only the label
          },
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            display: false
          },
          gridLines: {
            display: false
          }
        }
      ]
    },
    maintainAspectRatio: false
  });

  useEffect(() => {
    setData({
      labels: props.sparklineData,
      datasets: [
        {
          label: '',
          data: props.sparklineData,
          fill: true,
          backgroundColor: 'transparent',
          borderColor: props.weekUp ? '#48BB78' : '#F56565',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          borderWidth: 2
        }
      ]
    });
  }, [props.sparklineData]);

  return (
    <CustomLink
      w="full"
      direction="row"
      cursor="pointer"
      role="group"
      to={props.to}
      as={RouterLink}
      _focus={{ outline: 'none' }}
    >
      <Flex
        w="95%"
        m="auto"
        pb={4}
        pt={4}
        borderTopWidth={props.index !== 0 ? 1 : 0}
        borderBottomWidth={props.index === props.length - 1 ? 1 : 0}
        _groupHover={{
          borderColor: 'transparent'
        }}
      >
        <Text
          w="30%"
          display={mobileDisplayOptions}
          justifyContent="flex-start"
          alignItems="center"
          fontSize="xs"
          fontWeight="bold"
        >
          {props.cap}
        </Text>
        <Flex w="full" justify="flex-start" align="center" fontSize={3.5} mr={8} order={0}>
          <Image src={props.imgUrl} boxSize={8} borderRadius="full" />
          <Flex direction="column" align="flex-start" lineHeight={5} pl={3}>
            <Text
              w="full"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              textAlign="left"
              fontSize="sm"
              fontWeight="medium"
            >
              {props.ticker === 'USD' ? 'US Dollar' : titleCase(props.name)}
            </Text>
            <Text fontSize="sm" color="gray.400">
              {props.ticker}
            </Text>
          </Flex>
        </Flex>
        <Text
          w="full"
          display="flex"
          alignItems="center"
          justifyContent={['flex-end', 'flex-end', 'flex-start', 'flex-start', 'flex-start', 'flex-start']}
          fontSize="sm"
          fontWeight="medium"
          order={[2, 2, 1, 1, 1, 1]}
        >
          ${props.price}
        </Text>
        <Text
          w="full"
          display={mobileDisplayOptions}
          alignItems="center"
          justifyContent="flex-start"
          textAlign="left"
          fontWeight="medium"
          color={props.dailyUp ? '#48BB78' : '#F56565'}
          fontSize="sm"
          order={2}
        >
          {props.changeDay?.toFixed(2)}%
        </Text>
        <Text
          w="full"
          display={mobileDisplayOptions}
          alignItems="center"
          justifyContent="flex-start"
          textAlign="left"
          fontWeight="medium"
          fontSize="sm"
          order={3}
        >
          {props.volumeDay}
        </Text>
        {props.isMarketCap && (
          <Text
            w="full"
            display={['none', 'none', 'none', 'flex', 'flex', 'flex']}
            alignItems="center"
            justifyContent="flex-start"
            textAlign="left"
            fontWeight="medium"
            fontSize="sm"
            order={4}
          >
            {props.marketCap}
          </Text>
        )}
        {props.isCirculatingSupply && (
          <Text
            w="full"
            display={['none', 'none', 'none', 'none', 'flex', 'flex']}
            alignItems="center"
            justifyContent="flex-start"
            textAlign="left"
            fontWeight="medium"
            fontSize="sm"
            order={5}
          >
            {props.circulatingSupply}
          </Text>
        )}
        <Flex minW={36} maxW={36} alignItems="center" justifyContent="flex-start" order={[1, 1, 4, 5, 6, 6]}>
          <Flex w="full" h="full" align="flex-end" pointerEvents="none">
            {props.sparklineData ? <Line data={data} width={100} height={null} options={options}></Line> : null}
          </Flex>
        </Flex>
      </Flex>
    </CustomLink>
  );
};

MarketAssetCard.propTypes = {
  rank: PropTypes.number,
  name: PropTypes.string,
  ticker: PropTypes.string,
  imgUrl: PropTypes.string,
  price: PropTypes.number,
  // volumeDay: PropTypes.string,
  marketCap: PropTypes.string,
  changeDay: PropTypes.number,
  // circulatingSupply: PropTypes.string,
  sparklineData: PropTypes.array
};

export default MarketAssetCard;
