import { Flex, Heading, Text } from '@chakra-ui/react';
import Decimal from 'decimal.js';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { dynamicCurrencyFormatter, usdFormatter } from '../../utilities/currencyConverter';
import { calculateExchangeID } from '../../utilities/exchangeHelpers';
import BotCard from './botCard';
import OandaBotCard from './oandaBotCard';

const OandaBotContainer = props => {
  //   const [botStat, setBotStat] = useState(props.data.activeBotsStats[0]);

  //   const [bot, setBot] = useState(props.data.activeBotsConfig[0]);

  const userState = useSelector(state => state.user);

  const calculateOverallGain = gain => {
    // if (style === 'inverse') {
    //   return usdFormatter.format((currentPrice * gain).toFixed(3));
    // }
    return dynamicCurrencyFormatter(userState.baseCurrency).format(gain.toFixed(3));
  };

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const calculateSince = ts => {
    const now = new Date().getTime();
    let delta = ts;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    return `${days}D ${hours}H ${minutes}M`;
  };

  const calculateLifeTime = (c, u) => {
    let delta = Math.abs((new Date(u).getTime() - new Date(c).getTime()) / 1000);

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    return `${days}D ${hours}H ${minutes}M`;
  };

  const calculateExchangeKey = useCallback(key => {
    if (key === 1) {
      return 'BINANCEUS';
    } else if (key === 2) {
      return 'COINBASE';
    } else if (key === 3) {
      return 'BINANCECOM';
    } else if (key === 5) {
      return 'OANDADEMO';
    } else if (key === 6) {
      return 'OANDALIVE';
    }
  }, []);

  const handleFormatter = useCallback(input => {
    if (input >= 0) {
      return dynamicCurrencyFormatter(userState.baseCurrency).format(input).slice(1);
    } else {
      return `${dynamicCurrencyFormatter(userState.baseCurrency).format(input)[0]}${dynamicCurrencyFormatter(
        userState.baseCurrency
      )
        .format(input)
        .slice(2)}`;
    }
  }, []);

  //   useEffect(() => {
  //     setBotStat(props.data.activeBotsStats[0]);
  //     setBot(props.data.activeBotsConfig[0]);
  //   }, [props.data]);

  //   useEffect(() => {
  //     setBotStat(props.data.activeBotsStats[0]);
  //   }, [props.data.activeBotsStats[0]]);

  //   const sortActiveData = (configData, statData) => {
  //     console.log('sorting data');
  //     if (props.sortBy === 'New to Old') {
  //       return configData.sort((a, b) => new Date(b.bot_run_time).getTime() - new Date(a.bot_run_time).getTime());
  //     } else if (sortBy === 'Profit %') {
  //       let data = configData.map((bot, idx) => {
  //         let botStat = statData.find(element => props.data.activeBotsConfig[0].bot_id === element.bot_id);
  //         return (bot = {
  //           ...bot,
  //           overall_gain_percent: botStat.overall_gain_percent
  //         });
  //       });
  //       console.log(data);
  //       return data.sort((a, b) => b.overall_gain_percent - a.overall_gain_percent);
  //     }
  //   };

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic

      mounted.current = true;
    } else {
      // console.log('updating bot container');
      //   console.log('updating oanda bot', props.data, props.activeBotsStats);
      // do componentDidUpdate logic
    }
  });

  return useMemo(
    () => (
      <>
        {props.active
          ? props.data?.map((bot, idx) => {
              let botStat = props.activeBotsStats.find(element => bot.bot_id == element.bot_id);
              if (botStat !== undefined) {
                if (bot.exchange_id === calculateExchangeID(props.currentOption))
                  return (
                    <OandaBotCard
                      active={props.active}
                      index={props.index}
                      initialUnits={bot.initial_units}
                      unitAddition={bot.unit_addition}
                      orderTimer={bot.order_timer}
                      additionalOrderStep={bot.additional_order_step}
                      unitMultiplier={bot.unit_multiplier}
                      profitPriceConstant={bot.profit_price_constant}
                      stopLimitGranularity={bot.stop_limit_granularity}
                      stopLimitBuffer={bot.stop_limit_buffer}
                      maxMarginUtilization={bot.max_margin_utilization}
                      maxLossAmount={bot.max_loss_amount}
                      finance={botStat.financing}
                      totalFinance={bot.total_financing}
                      poc={botStat.pendingOrderCount}
                      nou={botStat.open_order_units}
                      nop={botStat.open_order_price}
                      leverage={(
                        1 / new Decimal(userState.oandaData?.instruments[bot.instrument]?.marginRate)
                      ).toFixed(0)}
                      // maxAmountDesc={props.descriptions.bs_max_amount}
                      // botNameDesc={props.descriptions.bs_bot_name}
                      // botStyleDesc={props.descriptions.bs_bot_type}
                      // minProfitDesc={props.descriptions.as_min_profit_margin}
                      // ddmDesc={props.descriptions.as_doubledown_multiplier}
                      // slbDesc={props.descriptions.as_stop_loss_buffer}
                      // slgDesc={props.descriptions.as_stop_loss_granularity}
                      // slpsDesc={props.descriptions.as_stop_loss_price_sensitivity}
                      // ebuDesc={props.descriptions.as_extra_buy_up_limit}
                      // octDesc={props.descriptions.as_order_cancel_timer}
                      // ebtDesc={props.descriptions.as_extra_buy_timer}
                      // btcDesc={props.descriptions.as_boost_timer_constant}
                      // spDesc={props.descriptions.as_slot_price}
                      // fboDesc={props.descriptions.as_first_boost_order}
                      // tfDesc={props.descriptions.as_trade_frequency}
                      currentPlayGain={botStat.current_run_gain}
                      bcpg={botStat.current_run_gain}
                      units={Math.abs(botStat.units_purchased)}
                      // cpg={botStat.current_play_gain_per_base_currency}
                      name={bot.bot_name}
                      // exchange={bot.exchange_name.toUpperCase()}
                      maxSpent={bot.max_margin_utilization}
                      // overallGain={calculateOverallGain(botStat.overall_play_gain_per_base_currency)}
                      botID={bot.bot_id}
                      // isBase={bot.isQuoteEqualsBaseCurrency}
                      // terminatedOption={bot.terminate_options}
                      terminatedOption={bot.terminate_options}
                      // quote={bot.quoteAsset}
                      // quoteAssetPrecision={botStat.quoteAssetPrecision}
                      // ebu={bot.extrabuy_up_limit_vs_avg_cost}
                      // orderCancelTimer={bot.order_cancel_timer}
                      // minProfitMargin={bot.minimum_profit_margin_percent}
                      // exchangeName={bot.exchange_name}
                      // baseCurrency={bot.symbol_to_profit}
                      // botState={botStat.bot_state}
                      // inverse={bot.bot_type === 'inverse'}
                      minAmount={bot.max_margin_utilization}
                      gain={botStat.completed_run_gain}
                      pause_options={bot.pause_options}
                      // slotPrice={bot.slot_price}
                      // precision={botStat.quoteAssetPrecision}
                      // quoteGraph={bot.quoteAsset}
                      // tradeFrequency={capitalizeFirstLetter(bot.trade_frequency)}
                      // doubledownMultiplier={bot.doubledown_multiplier}
                      // baseAssetPrecision={botStat.baseAssetPrecision}
                      // stopLossPriceSensitivity={bot.stop_loss_price_sensitivity}
                      // boostTimerConstant={bot.boost_timer_constant}
                      // firstBoostOrder={bot.first_boost_order_percentage}
                      // extraBuyTimer={bot.extra_buy_timer}
                      // stopLossBuffer={bot.stop_loss_buffer}
                      // stopLossGranularity={bot.stop_loss_granularity}
                      // target={bot.symbol_to_profit}
                      slp={botStat.stop_limit_count > 0}
                      slc={botStat.stop_limit_count}
                      prp={botStat.take_profit_price}
                      avgCost={botStat.avg_price}
                      botStyle={capitalizeFirstLetter(bot.direction)}
                      playCount={botStat.run_count >= 1 ? botStat.run_count - 1 : 0}
                      overallGain={bot.completed_run_gain}
                      // overallGainPercent={botStat.overall_gain_percent.toFixed(2)}
                      pair={bot.instrument}
                      // baseGraph={bot.baseAsset}
                      // cp={botStat.current_price}
                      // ra={
                      //   bot.isQuoteEqualsBaseCurrency !== 1
                      //     ? `${String(botStat.remaining_amount.toFixed(botStat.quoteAssetPrecision))} ${
                      //         bot.quoteAsset
                      //       }`
                      //     : `${handleFormatter(botStat.remaining_amount.toFixed(botStat.quoteAssetPrecision))} ${
                      //         bot.quoteAsset
                      //       }`
                      // }
                      // qtyPurchased={botStat.total_qty_purchased.toFixed(
                      //   bot.bot_type === 'inverse' || bot.isQuoteEqualsBaseCurrency !== 0
                      //     ? botStat.baseAssetPrecision
                      //     : botStat.quoteAssetPrecision
                      // )}
                      // qtyPurchased={`${usdFormatter
                      //   .format(botStat.total_qty_purchased.toFixed(botStat.baseAssetPrecision))
                      //   .slice(1)} ${bot.baseAsset}`}
                      currentSince={calculateSince(botStat.current_run_time)}
                      marginUtilized={botStat.margin_utilized}
                      quoteSpend={dynamicCurrencyFormatter(userState.baseCurrency).format(botStat.margin_utilized)}
                      quantityTitle={bot.bot_type === 'inverse' ? 'Quantity Sold' : 'Quantity Purchased'}
                      totalOrders={botStat.order_count}
                      currentPrice={botStat.current_price}
                      // avgCost={
                      //   bot.isQuoteEqualsBaseCurrency !== 1
                      //     ? `${String(botStat.avg_cost.toFixed(botStat.quoteAssetPrecision))} ${bot.quoteAsset}`
                      //     : `${usdFormatter
                      //         .format(botStat.avg_cost.toFixed(botStat.quoteAssetPrecision))
                      //         .slice(1)} ${bot.quoteAsset}`
                      // }
                      since={calculateSince(bot.bot_run_time)}
                      // prp={
                      //   bot.isQuoteEqualsBaseCurrency !== 1
                      //     ? `${String(botStat.price_for_profit.toFixed(botStat.quoteAssetPrecision))} ${
                      //         bot.quoteAsset
                      //       }`
                      //     : `${usdFormatter
                      //         .format(botStat.price_for_profit.toFixed(botStat.quoteAssetPrecision))
                      //         .slice(1)} ${bot.quoteAsset}`
                      // }
                      // use bot id as key to fix many bugs
                      key={bot.bot_id}
                      length={bot.instrument.length}
                    />
                  );
              } else {
                return null;
              }
            })
          : null}
        {!props.active
          ? props.data?.map((bot, idx) => {
              return (
                <OandaBotCard
                  key={bot.bot_id}
                  name={capitalizeFirstLetter(bot.bot_name)}
                  marginUtilized={bot.margin_utilized}
                  playCount={bot.run_count}
                  overallGain={bot.completed_run_gain}
                  botID={bot.bot_id}
                  maxSpent={bot.margin_utilized}
                  leverage={(
                    1 / new Decimal(userState.oandaData?.instruments[bot.instrument]?.marginRate)
                  ).toFixed(0)}
                  pair={bot.instrument}
                  totalFinance={Number(bot.total_financing)}
                  gain={bot.completed_run_gain}
                  exchange={calculateExchangeKey(bot.exchange_id)}
                  exchangeName={calculateExchangeKey(bot.exchange_id).toLowerCase()}
                />
              );
            })
          : null}
        <Flex />
      </>
    ),
    [props.data, props.sortBy, props.index, props.isDescending]
  );
};

OandaBotContainer.propTypes = {
  data: PropTypes.array
};

export default OandaBotContainer;
