// AUTH ACTION TYPES

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS ';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const AUTH_SIGNUP_START = 'AUTH_SIGNUP_START';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS ';
export const AUTH_SIGNUP_FAIL = 'AUTH_SIGNUP_FAIL';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_ON_PAGE_LOAD = 'AUTH_ON_PAGE_LOAD';

export const AUTH_VERIFICATION_START = 'AUTH_VERIFICATION_START';
export const AUTH_VERIFICATION_SUCCESS = 'AUTH_VERIFICATION_SUCCESS';
export const AUTH_VERIFICATION_FAIL = 'AUTH_VERIFICATION_FAIL';

export const AUTH_SET_CURRENT_OPTION = 'AUTH_SET_CURRENT_OPTION';

// USER ACTION TYPES

export const FETCH_USER_OANDA_ASSET_DATA_START = 'FETCH_USER_OANDA_ASSET_DATA_START';
export const FETCH_USER_OANDA_ASSET_DATA_FAIL = 'FETCH_USER_OANDA_ASSET_DATA_FAIL';
export const FETCH_USER_OANDA_ASSET_DATA_SUCCESS = 'FETCH_USER_OANDA_ASSET_DATA_SUCCESS';

export const FETCH_USER_ASSET_DATA_START = 'FETCH_USER_ASSET_DATA_START';
export const FETCH_USER_ASSET_DATA_FAIL = 'FETCH_USER_ASSET_DATA_FAIL';
export const FETCH_USER_ASSET_DATA_SUCCESS = 'FETCH_USER_ASSET_DATA_SUCCESS';

export const FETCH_USER_PORTFOLIO_DATA_START = 'FETCH_USER_PORTFOLIO_DATA_START';
export const FETCH_USER_PORTFOLIO_DATA_FAIL = 'FETCH_USER_PORTFOLIO_DATA_FAIL';
export const FETCH_USER_PORTFOLIO_DATA_SUCCESS = 'FETCH_USER_PORTFOLIO_DATA_SUCCESS';
export const SET_CURRENT_GRAPH_DATA = 'SET_CURRENT_GRAPH_DATA';

export const USER_EXCHANGE_ADD_START = 'USER_EXCHANGES_START';
export const USER_EXCHANGE_ADD_FAIL = 'USER_EXCHANGE_ADD_FAIL';
export const USER_EXCHANGE_ADD_SUCCESS = 'USER_EXCHANGE_ADD_FAIL';

export const FETCH_TOTAL_EARNINGS_START = 'FETCH_TOTAL_EARNINGS_START';
export const FETCH_TOTAL_EARNINGS_FAIL = 'FETCH_TOTAL_EARNINGS_FAIL';
export const FETCH_TOTAL_EARNINGS_SUCCESS = 'FETCH_TOTAL_EARNINGS_SUCCESS';

export const SET_USER_BASE_CURRENCY = 'SET_USER_BASE_CURRENCY';
// REPORTS ACTION TYPES

export const FETCH_REPORTS_START = 'FETCH_REPORTS_START';
export const FETCH_REPORTS_FAIL = 'FETCH_REPORTS_FAIL';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';

// export const INITIALIZE_SOCKET = 'INITIALIZE_SOCKET';
// export const KILL_SOCKET = 'KILL_SOCKET';

export const USER_SET_COLORSET = 'USER_SET_COLORSET';

// PROFILE ACTION TYPES

export const FETCH_PROFILE_DATA_START = 'FETCH_PROFILE_DATA_START';
export const FETCH_PROFILE_DATA_FAIL = 'FETCH_PROFILE_DATA_FAIL';
export const FETCH_PROFILE_DATA_SUCCESS = 'FETCH_PROFILE_DATA_SUCCESS';

export const ADD_EXCHANGE_START = 'ADD_EXCHANGE_START';
export const ADD_EXCHANGE_FAIL = 'ADD_EXCHANGE_FAIL';
export const ADD_EXCHANGE_SUCCESS = 'ADD_EXCHANGE_SUCCESS';

export const GET_EXCHANGE_START = 'GET_EXCHANGE_START';
export const GET_EXCHANGE_FAIL = 'GET_EXCHANGE_FAIL';
export const GET_EXCHANGE_SUCCESS = 'GET_EXCHANGE_SUCCESS';

export const PROFILE_SET_CURRENT_OPTION = 'PROFILE_SET_CURRENT_OPTION';

// MARKET & COIN ACTION TYPES

export const FETCH_MARKET_DATA_START = 'FETCH_MARKET_DATA_START';
export const FETCH_MARKET_DATA_SUCCESS = 'FETCH_MARKET_DATA_SUCCESS';
export const FETCH_MARKET_DATA_FAIL = 'FETCH_MARKET_DATA_FAIL';

export const FETCH_COIN_DATA_START = 'FETCH_COIN_DATA_START';
export const FETCH_COIN_DATA_SUCCESS = 'FETCH_COIN_DATA_SUCCESS';
export const FETCH_COIN_DATA_FAIL = 'FETCH_COIN_DATA_FAIL';

// BOT ACTION TYPES

export const GET_DESCRIPTIONS_START = 'GET_DESCRIPTIONS_START';
export const GET_DESCRIPTIONS_FAIL = 'GET_DESCRIPTIONS_FAIL';
export const GET_DESCRIPTIONS_SUCCESS = 'GET_DESCRIPTIONS_SUCCESS';

export const ADD_BOT_START = 'ADD_BOT_START';
export const ADD_BOT_FAIL = 'ADD_BOT_FAIL';
export const ADD_BOT_SUCCESS = 'ADD_BOT_SUCCESS';

export const GET_FX_BOT_SETTINGS_START = 'GET_FX_BOT_SETTINGS_START';
export const GET_FX_BOT_SETTINGS_FAIL = 'GET_FX_BOT_SETTINGS_FAIL';
export const GET_FX_BOT_SETTINGS_SUCCESS = 'GET_FX_BOT_SETTINGS_SUCCESS';

export const GET_PLAY_STATS_START = 'GET_PLAY_STATS_START';
export const GET_PLAY_STATS_FAIL = 'GET_PLAY_STATS_FAIL';
export const GET_PLAY_STATS_SUCCESS = 'GET_PLAY_STATS_SUCCESS';

export const GET_ACTIVE_BOTS_CONFIG_START = 'GET_ACTIVE_BOTS_CONFIG_START';
export const GET_ACTIVE_BOTS_CONFIG_FAIL = 'GET_ACTIVE_BOTS_CONFIG_FAIL';
export const GET_ACTIVE_BOTS_CONFIG_SUCCESS = 'GET_ACTIVE_BOTS_CONFIG_SUCCESS';

export const GET_ACTIVE_BOTS_STATS_START = 'GET_ACTIVE_BOTS_STATS_START';
export const GET_ACTIVE_BOTS_STATS_FAIL = 'GET_ACTIVE_BOTS_STATS_FAIL';
export const GET_ACTIVE_BOTS_STATS_SUCCESS = 'GET_ACTIVE_BOTS_STATS_SUCCESS';

export const GET_INITIAL_ACTIVE_BOTS_STATS_START = 'GET_INITIAL_ACTIVE_BOTS_STATS_START';
export const GET_INITIAL_ACTIVE_BOTS_STATS_FAIL = 'GET_INITIAL_ACTIVE_BOTS_STATS_FAIL';
export const GET_INITIAL_ACTIVE_BOTS_STATS_SUCCESS = 'GET_INITIAL_ACTIVE_BOTS_STATS_SUCCESS';

export const GET_COMPLETED_BOTS_START = 'GET_COMPLETED_BOTS_START';
export const GET_COMPLETED_BOTS_FAIL = 'GET_COMPLETED_BOTS_FAIL';
export const GET_COMPLETED_BOTS_SUCCESS = 'GET_COMPLETED_BOTS_SUCCESS';

export const SET_BOT_MODIFY_TERMINATE_OPTIONS_START = 'SET_BOT_MODIFY_TERMINATE_OPTIONS_START';
export const SET_BOT_MODIFY_TERMINATE_OPTIONS_FAIL = 'SET_BOT_MODIFY_TERMINATE_OPTIONS_FAIL';
export const SET_BOT_MODIFY_TERMINATE_OPTIONS_SUCCESS = 'SET_BOT_MODIFY_TERMINATE_OPTIONS_SUCCESS';

// SOCKET ACTION TYPES

export const INITIALIZE_SOCKET = 'INITIALIZE_SOCKET';
export const KILL_SOCKET = 'KILL_SOCKET';
export const SET_CURRENT_OPTION = 'SET_CURRENT_OPTION';

// SOCIAL ACTION TYPES

export const GET_SOCIAL_BOTS_START = 'GET_SOCIAL_BOTS_START';
export const GET_SOCIAL_BOTS_FAIL = 'GET_SOCIAL_BOTS_FAIL';
export const GET_SOCIAL_BOTS_SUCCESS = 'GET_SOCIAL_BOTS_SUCCESS';

// EXCHANGE ACTION TYPES

export const GET_EXCHANGE_DATA_START = 'GET_EXCHANGE_DATA_START';
export const GET_EXCHANGE_DATA_FAIL = 'GET_EXCHANGE_DATA_FAIL';
export const GET_EXCHANGE_DATA_SUCCESS = 'GET_EXCHANGE_DATA_SUCCESS';
