// // DataContext.js
// import React, { createContext, useState, useEffect } from 'react';

// export const DataContext = createContext();

// export const DataProvider = ({ children }) => {
//     const [data, setData] = useState(null);

//     // Function to update data
//     const updateData = (newData) => {
//         setData(newData);
//     };

//     return (
//         <DataContext.Provider value={{ data, updateData }}>
//             {children}
//         </DataContext.Provider>
//     );
// };

import { EventEmitter } from 'events';
const eventEmitter = new EventEmitter();
export default eventEmitter;
