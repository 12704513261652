import { Flex, Heading } from '@chakra-ui/react';
import React from 'react';

const topHeadingSize = ['2xl', '2xl', '2xl', '3xl', '3xl', '4xl'];
const videoFlexHeight = ['190px', '250px', '280px', '400px', '400px', '500px'];

function TrainingVideos() {
  return (
    <Flex w="100%" direction="column" justify="center" align="center" mt={4}>
      <Flex w="95%" direction="column" justify="center" align="center" fontWeight="light" mb={14}>
        <Heading mb={[8, 8, 10, 10, 12, 12]} fontWeight="light" textAlign="center" fontSize={topHeadingSize}>
          Training Videos
        </Heading>

        <Flex w="90%" h={videoFlexHeight} justify="center" align="center" border="1px">
          <iframe
            height="100%"
            width="100%"
            src="https://www.youtube.com/embed/_GioQT2PWC8"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TrainingVideos;
