import { Flex, Link } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const CondotionalLink = ({ isLink, onFlexClick, ...rest }) => {
  const LinkComponent = () => {
    if (isLink) {
      return (
        <Link as={RouterLink} {...rest}>
          {rest.children}
        </Link>
      );
    } else {
      return (
        <Flex {...rest} cursor="pointer" userSelect="none" onClick={onFlexClick}>
          {rest.children}
        </Flex>
      );
    }
  };

  return <LinkComponent />;
};

CondotionalLink.propTypes = {
  isLink: PropTypes.bool.isRequired,
  onFlexClick: PropTypes.func
};

export default CondotionalLink;
