import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  background,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Input,
  Link,
  Text,
  Tooltip,
  useClipboard,
  useColorMode
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { addDays, format, isWeekend } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { CSVDownload, CSVLink } from 'react-csv';
import { Calendar } from 'react-date-range';
import { RiInformationLine } from 'react-icons/ri';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';

import AutoInput from '../components/automationWizard/autoInput';
import EFButton from '../components/UI/Button/efButton';
import Dropdown from '../components/UI/Dropdown/dropdown';
import { getCompletedBots } from '../store/actions/bot';
import { fetchReports } from '../store/actions/reports';
import axios from '../utilities/axios';
import { dynamicCurrencyFormatter, usdFormatter } from '../utilities/currencyConverter';
import { calculateExchangeID, getExchangeNames } from '../utilities/exchangeHelpers';

const Reports = props => {
  const [loading, setLoading] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const [report, setReport] = useState();
  const [acc, setAcc] = useState();
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);

  const authState = useSelector(state => state.auth);
  const userState = useSelector(state => state.user);
  const botState = useSelector(state => state.bot);
  const reportsState = useSelector(state => state.reports);

  const [startDate, setStartDate] = useState(new Date(new Date().getTime() - 60 * 60 * 1000 * 24 * 7));
  const [endDate, setEndDate] = useState(new Date());

  const [bots, setBots] = useState({});
  const [bot, setBot] = useState();

  const [csvData, setCsvData] = useState([]);

  const calendarRef = useRef(null);

  const calendar2Ref = useRef(null);
  const buttonRef = useRef(null);

  const button2Ref = useRef(null);

  const dispatch = useDispatch();

  const csvHeaders = [
    { label: 'Bot Name', key: 'bot_name' },
    { label: 'Bot ID', key: 'bot_id' },
    { label: 'Run Number', key: 'run_count' },
    { label: 'Run Status', key: 'run_status' },
    { label: 'Run Start Date', key: 'created_at' },
    { label: 'Direction', key: 'direction' },
    { label: 'Average Price', key: 'avg_price' },
    { label: 'Financing', key: 'financing' },
    { label: 'Run Duration', key: 'run_duration' },
    { label: 'Margin Utilized', key: 'margin_utilized' },
    { label: 'Final PNL', key: 'final_pnl' },
    { label: 'Trade Count', key: 'openTradeCount' },
    { label: 'Units Purchased', key: 'units_purchased' }
  ];

  const handleDropDownAccount = selected => {
    setAcc(selected);
  };

  const handleDropDownBot = selected => {
    for (let bot in bots) {
      if (bots[bot] === selected) {
        setBot({ [bot]: selected });
      }
    }
  };

  const handleDateFormat = date => {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split('T')[0];
  };

  const CustomCalendar = useCallback(
    styled(Calendar)`
      & {
        background: ${colorMode === 'light' ? 'white' : '#1f2124'};
        border: 0.5px solid ${colorMode === 'light' ? ' #edf2f7' : '#2D3748'};
        border-radius: 12px;
        color: ${colorMode === 'light' ? 'black' : 'white'};
      }
      .rdrDayDisabled {
        background: ${colorMode === 'light' ? '#EDF2F7' : '#0d0e14'};
      }
    `,
    [colorMode]
  );

  useEffect(() => {
    if (authState.exchanges.length > 0) {
      setAcc(authState.currentOption);
    }
  }, [authState.exchanges]);

  useEffect(() => {
    if (reportsState.reports.length > 0) {
      let arr = [];
      for (let report of reportsState.reports[0]) {
        for (let dp in report) {
          for (let a of report[dp]) {
            arr.push(a);
          }
        }
      }
      setCsvData(arr);
    }
  }, [reportsState.reports]);

  useEffect(() => {
    if (authState.id) {
      // dispatch(getActiveBotsConfig(authState.id));
      // if (authState.currentOption) {
      //   dispatch(getActiveBotsStats(authState.id, getExchangeNames(authState.currentOption)));
      // } else {
      //   dispatch(getActiveBotsStats(authState.id, getExchangeNames(socketState.currentOption)));
      // }
      dispatch(getCompletedBots(authState.id, calculateExchangeID(authState.currentOption)));
    }
  }, [authState.id, authState.currentOption, authState.currentOption]);

  useEffect(() => {
    if (botState.completedBotsChecked && botState.activeBotsConfigChecked) {
      let bots = {};
      if (botState.activeBotsConfig.length > 0) {
        for (let bot of botState.activeBotsConfig) {
          bots = {
            ...bots,
            [bot.bot_id]: bot.bot_name
          };
        }
      }
      if (botState.completedBots.length > 0) {
        for (let bot of botState.completedBots) {
          bots = {
            ...bots,
            [bot.bot_id]: bot.bot_name
          };
        }
      }
      if (Object.keys(bots).length > 0) {
        setBot({ [Object.keys(bots)[0]]: bots[Object.keys(bots)[0]] });
        setBots(bots);
      }
    }
  }, [botState.completedBotsChecked, botState.activeBotsConfigChecked]);

  const fetchReport = async (id, startDate, endDate) => {
    dispatch(fetchReports(id, startDate, endDate, Object.values(bot)[0]));
  };

  const handleSelect = date => {
    setStartDate(date);
    // setEndDate(ranges.selection.endDate);
  };

  const handleEndSelect = date => {
    setEndDate(date);
  };

  const customDayContent = day => {
    return (
      <div>
        <span style={{ color: colorMode === 'light' ? 'black' : 'white' }}>{format(day, 'd')}</span>
      </div>
    );
  };

  useEffect(() => {
    // Function to handle click outside of the calendar
    const handleClickOutside = event => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        // Clicked outside of the calendar and button, close the calendar or handle as needed
        setShowStartCalendar(false);
        // Add logic here to close the calendar or perform other actions
      }
    };

    // Add event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Function to handle click outside of the calendar
    const handleClickOutside = event => {
      if (
        calendar2Ref.current &&
        !calendar2Ref.current.contains(event.target) &&
        button2Ref.current &&
        !button2Ref.current.contains(event.target)
      ) {
        // Clicked outside of the calendar and button, close the calendar or handle as needed
        setShowEndCalendar(false);
        // Add logic here to close the calendar or perform other actions
      }
    };

    // Add event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // const selectionRange = {
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   key: 'selection'
  // };

  return (
    userState.oandaData && (
      <Flex
        w={['full', 'full', '95%', '95%', '95%', '95%']}
        // maxW="7xl"
        justify="center"
        overflowY="hidden"
        overflowX="hidden"
        pb={24}
        mt={[10, 10, 16]}
        pt={[4, 4, 8]}
      >
        <Flex
          w={['full', 'full', 'full', 'full', 'full', 'full']}
          direction="column"
          justify="flex-start"
          alignItems="center"
          minH="100vh"
          align="center"
        >
          <Text fontSize="16px" fontWeight="black">
            REPORTS
          </Text>
          <Flex
            w="full"
            mt={8}
            justifyContent="center"
            alignItems="flex-start"
            flexDirection={['column', 'column', 'column', 'column', 'row', 'row']}
          >
            <Flex flexDir="column">
              <Flex
                justifyContent={['center', 'center', 'center', 'center', 'space-between', 'space-between']}
                display={['none', 'none', 'none', 'none', 'flex']}
              >
                <Flex
                  w={['280px', '280px', '280px', '280px', '230px', '320px']}
                  h={40}
                  borderWidth={1}
                  justifyContent="space-between"
                  flexDir="column"
                  pl={4}
                  py={4}
                  borderRadius="xl"
                  mr={4}
                >
                  <Flex flexDir="column" w="full">
                    <Text fontWeight="bold" fontSize="16px">
                      Balance
                    </Text>
                    <Text fontSize="13px" mt={2}>
                      Life Time Total Balance
                    </Text>
                  </Flex>
                  <Text
                    fontSize="32px"
                    fontWeight="bold"
                    letterSpacing="wider"
                    lineHeight="short"
                    background="linear-gradient(to right, #0061FF, #60EFFF)"
                    backgroundClip="text"
                    style={{ WebkitTextFillColor: 'transparent' }}
                  >
                    {dynamicCurrencyFormatter(userState.baseCurrency).format(userState.oandaData?.balance)}
                  </Text>
                </Flex>
                <Flex
                  w={['280px', '280px', '280px', '280px', '230px', '320px']}
                  h={40}
                  borderWidth={1}
                  justifyContent="space-between"
                  flexDir="column"
                  pl={4}
                  py={4}
                  borderRadius="xl"
                  mr={4}
                >
                  <Flex flexDir="column" w="full">
                    <Text fontWeight="bold" fontSize="16px">
                      NAV
                    </Text>
                    <Text fontSize="13px" mt={2}>
                      Life Time Total Net Asset Value
                    </Text>
                  </Flex>
                  <Text
                    fontSize="32px"
                    fontWeight="bold"
                    letterSpacing="wider"
                    lineHeight="short"
                    background="linear-gradient(to right, #ff930f, #fff95b)"
                    backgroundClip="text"
                    style={{ WebkitTextFillColor: 'transparent' }}
                  >
                    {dynamicCurrencyFormatter(userState.baseCurrency).format(userState.oandaData?.TOTAL)}
                  </Text>
                </Flex>
                <Flex
                  w={['280px', '280px', '280px', '280px', '230px', '320px']}
                  h={40}
                  borderWidth={1}
                  justifyContent="space-between"
                  flexDir="column"
                  pl={4}
                  py={4}
                  borderRadius="xl"
                >
                  <Flex flexDir="column" w="full">
                    <Text fontWeight="bold" fontSize="16px">
                      Available Margin
                    </Text>
                    <Text fontSize="13px" mt={2}>
                      Current Available Margin
                    </Text>
                  </Flex>
                  <Text
                    fontSize="32px"
                    fontWeight="bold"
                    letterSpacing="wider"
                    lineHeight="short"
                    background="linear-gradient(to right, #BC1B68, #D3989B)"
                    backgroundClip="text"
                    style={{ WebkitTextFillColor: 'transparent' }}
                  >
                    {dynamicCurrencyFormatter(userState.baseCurrency).format(userState.oandaData?.marginAvailable)}
                  </Text>
                </Flex>
                {/* <Flex w="240px" h={40} borderWidth={1}></Flex> */}
              </Flex>
              <Flex w="full" mt={12} flexDir="column" display={['none', 'none', 'none', 'none', 'flex']}>
                <Text fontSize={24} fontWeight="black" mb={4}>
                  Your Report
                </Text>
                <Flex w="full" flexDir="column">
                  <Flex>
                    <Text flex={1} fontSize="14px" fontWeight="black">
                      Bot Name
                    </Text>
                    <Text flex={1} fontSize="14px" fontWeight="black">
                      Start Date
                    </Text>
                    <Text flex={1} fontSize="14px" fontWeight="black">
                      End Date
                    </Text>
                    <Text flex={1} fontSize="14px" fontWeight="black">
                      Report
                    </Text>
                  </Flex>
                  {reportsState.reports.length <= 0 ? (
                    <Flex w="full" mt={24} justifyContent="center" alignItems="center">
                      <Text fontSize="13px">Your generated report will appear here.</Text>
                    </Flex>
                  ) : (
                    reportsState.reports.map((r, idx) => {
                      return (
                        <Accordion key={idx} w="full" mt={6} allowToggle>
                          <AccordionItem w="full" borderWidth={0}>
                            {({ isExpanded }) => (
                              <>
                                <AccordionButton
                                  _focus={{
                                    outline: 'none'
                                  }}
                                  w="full"
                                  pb={4}
                                  pl={0}
                                  pr={0}
                                >
                                  <Flex alignItems="center" w="full">
                                    <Text flex={1} fontSize="14px" textAlign="left">
                                      {r[0][Object.keys(r[0])][0].bot_name}
                                    </Text>
                                    <Text flex={1} fontSize="14px" textAlign="left">
                                      {r[0][Object.keys(r[0])][0].start_date}
                                    </Text>
                                    <Text flex={1} fontSize="14px" textAlign="left">
                                      {r[0][Object.keys(r[0])][0].end_date}
                                    </Text>
                                    <Flex
                                      flex={1}
                                      fontSize="14px"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <CSVLink
                                        data={csvData}
                                        filename={`epicflow-${Object.keys(r[0])[0]}`}
                                        headers={csvHeaders}
                                      >
                                        <Flex borderWidth={1} borderRadius="lg" py={2} px={4} fontSize="13px">
                                          Download CSV
                                        </Flex>
                                      </CSVLink>
                                      <AccordionIcon />
                                    </Flex>
                                  </Flex>
                                </AccordionButton>
                                <AccordionPanel p={0} mb={4} mt={6}>
                                  <Flex w="full">
                                    <Flex w="full" justifyContent="space-between" alignItems="center">
                                      <Text flex={0.5} fontSize="sm" fontWeight="black">
                                        Run No
                                      </Text>
                                      <Text flex={1} fontSize="sm" textAlign="center" fontWeight="black">
                                        Direction
                                      </Text>
                                      <Flex align="center" flex={1} justifyContent="center">
                                        <Text fontSize="sm" fontWeight="black">
                                          MU
                                        </Text>
                                        <Tooltip
                                          hasArrow
                                          label="Margin Utilized"
                                          whiteSpace="break-spaces"
                                          bg="gray.200"
                                          color="gray.700"
                                          pt={1}
                                          pb={1}
                                          fontSize="sm"
                                        >
                                          <span
                                            style={{
                                              marginLeft: '6px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              width: '12px',
                                              height: '12px'
                                            }}
                                          >
                                            <Icon
                                              as={RiInformationLine}
                                              w="12px"
                                              h="12px"
                                              variant="unstyled"
                                              _focus={{
                                                outline: 'none'
                                              }}
                                              color="blue.300"
                                            />
                                          </span>
                                        </Tooltip>
                                      </Flex>
                                      <Text fontSize="sm" flex={1} textAlign="center" fontWeight="black">
                                        Final PNL
                                      </Text>
                                      <Flex align="center" flex={1} justifyContent="center">
                                        <Text fontSize="sm" fontWeight="black">
                                          TC
                                        </Text>
                                        <Tooltip
                                          hasArrow
                                          label="Trade Count"
                                          whiteSpace="break-spaces"
                                          bg="gray.200"
                                          color="gray.700"
                                          pt={1}
                                          pb={1}
                                          fontSize="sm"
                                        >
                                          <span
                                            style={{
                                              marginLeft: '6px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              width: '12px',
                                              height: '12px'
                                            }}
                                          >
                                            <Icon
                                              as={RiInformationLine}
                                              w="12px"
                                              h="12px"
                                              variant="unstyled"
                                              _focus={{
                                                outline: 'none'
                                              }}
                                              color="blue.300"
                                            />
                                          </span>
                                        </Tooltip>
                                      </Flex>
                                      <Flex align="center" flex={0.5} justifyContent="flex-end">
                                        <Text fontSize="sm" fontWeight="black">
                                          UP
                                        </Text>
                                        <Tooltip
                                          hasArrow
                                          label="Units Purchased"
                                          whiteSpace="break-spaces"
                                          bg="gray.200"
                                          color="gray.700"
                                          pt={1}
                                          pb={1}
                                          fontSize="sm"
                                        >
                                          <span
                                            style={{
                                              marginLeft: '6px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              width: '12px',
                                              height: '12px'
                                            }}
                                          >
                                            <Icon
                                              as={RiInformationLine}
                                              w="12px"
                                              h="12px"
                                              variant="unstyled"
                                              _focus={{
                                                outline: 'none'
                                              }}
                                              color="blue.300"
                                            />
                                          </span>
                                        </Tooltip>
                                      </Flex>
                                    </Flex>
                                  </Flex>
                                  {reportsState.reports[0][0][Object.keys(reportsState.reports[0][0])[0]].map(
                                    (el, idx) => {
                                      return (
                                        <Flex w="full" key={idx} my={5}>
                                          <Text flex={0.5} fontSize="sm">
                                            {el.run_count}
                                          </Text>
                                          <Text flex={1} fontSize="sm" textAlign="center">
                                            {el.direction.toUpperCase()}
                                          </Text>
                                          <Text flex={1} fontSize="sm" textAlign="center">
                                            {dynamicCurrencyFormatter(userState.baseCurrency).format(
                                              el.margin_utilized
                                            )}
                                          </Text>
                                          <Text flex={1} fontSize="sm" textAlign="center">
                                            {dynamicCurrencyFormatter(userState.baseCurrency).format(el.final_pnl)}
                                          </Text>
                                          <Text flex={1} fontSize="sm" textAlign="center">
                                            {el.openTradeCount}
                                          </Text>
                                          <Text flex={0.5} fontSize="sm" textAlign="right">
                                            {el.units_purchased}
                                          </Text>
                                        </Flex>
                                      );
                                    }
                                  )}
                                </AccordionPanel>
                              </>
                            )}
                          </AccordionItem>
                        </Accordion>
                      );
                    })
                  )}
                </Flex>
              </Flex>
            </Flex>

            <Flex w="full" justifyContent="center" flexDir="column" alignItems="center">
              <Flex
                w="330px"
                minH="fit-content"
                borderWidth={1}
                ml={[0, 0, 0, 0, 16, 16]}
                py={6}
                px={4}
                borderRadius="xl"
                position="relative"
                flexDir="column"
              >
                <Text fontWeight="bold" fontSize="16px">
                  Generate Report
                </Text>
                {Object.keys(bots).length <= 0 &&
                botState.completedBotsChecked &&
                botState.activeBotsConfigChecked ? (
                  <Flex w="full" h="full" justifyContent="center" alignItems="center" flexDir="column">
                    <Text mt={12} fontWeight="bold" fontSize="14px">
                      You have not created any bot yet.
                    </Text>
                    <EFButton
                      bg="#DE6E4B"
                      isLoading={loading}
                      color="white"
                      disabled
                      w="full"
                      mt={16}
                      title="Generate Report"
                    />
                  </Flex>
                ) : Object.keys(bots).length > 0 &&
                  botState.completedBotsChecked &&
                  botState.activeBotsConfigChecked ? (
                  <>
                    <Dropdown
                      menuitems={authState.exchanges}
                      selected={handleDropDownAccount}
                      functional
                      value={acc}
                      py="18px"
                      title="Account"
                      showTitle
                      mtop={4}
                      fontSize="0.8125rem"
                      _hover={{
                        backgroundColor: colorMode === 'light' ? 'white' : 'gray.900',
                        borderColor: colorMode === 'light' ? 'gray.400' : 'gray.800'
                      }}
                    />
                    <Dropdown
                      menuitems={Object.values(bots)}
                      selected={handleDropDownBot}
                      functional
                      value={bot ? Object.values(bot)[0] : ''}
                      py="18px"
                      title="Bot"
                      mtop={4}
                      showTitle
                      fontSize="0.8125rem"
                      _hover={{
                        backgroundColor: colorMode === 'light' ? 'white' : 'gray.900',
                        borderColor: colorMode === 'light' ? 'gray.400' : 'gray.800'
                      }}
                    />
                    <Flex w="full" h="38px" justify="space-between" alignItems="center" mt={4} pos="relative">
                      <Flex w="full">
                        <Text fontSize="13px">Start Date</Text>
                      </Flex>
                      <Button
                        ref={buttonRef}
                        h="full"
                        bg="transparent"
                        borderWidth={1}
                        w="full"
                        fontSize="14px"
                        justifyContent="flex-end"
                        onClick={() => setShowStartCalendar(!showStartCalendar)}
                      >
                        {handleDateFormat(startDate)}
                      </Button>
                      <Flex
                        position="absolute"
                        zIndex={22}
                        top={10}
                        right={0}
                        ref={calendarRef}
                        display={showStartCalendar ? 'flex' : 'none'}
                      >
                        <CustomCalendar
                          showMonthArrow
                          showMonthAndYearPickers={false}
                          dayContentRenderer={customDayContent}
                          maxDate={new Date()}
                          // ranges={[{ startDate: startDate, endDate: endDate, key: 'selection' }]}
                          date={startDate}
                          onChange={handleSelect}
                        />
                      </Flex>
                    </Flex>
                    <Flex w="full" h="38px" justify="space-between" alignItems="center" mt={4} pos="relative">
                      <Flex w="full">
                        <Text fontSize="13px">End Date</Text>
                      </Flex>
                      <Button
                        ref={button2Ref}
                        h="full"
                        bg="transparent"
                        borderWidth={1}
                        w="full"
                        fontSize="14px"
                        justifyContent="flex-end"
                        onClick={() => setShowEndCalendar(!showEndCalendar)}
                      >
                        {handleDateFormat(endDate)}
                      </Button>
                      <Flex
                        position="absolute"
                        zIndex={22}
                        top={10}
                        right={0}
                        ref={calendar2Ref}
                        display={showEndCalendar ? 'flex' : 'none'}
                      >
                        <CustomCalendar
                          showMonthArrow
                          showMonthAndYearPickers={false}
                          dayContentRenderer={customDayContent}
                          maxDate={new Date()}
                          // ranges={[{ startDate: startDate, endDate: endDate, key: 'selection' }]}
                          date={endDate}
                          onChange={handleEndSelect}
                        />
                      </Flex>
                    </Flex>
                    <EFButton
                      bg="#DE6E4B"
                      isLoading={reportsState.loading}
                      color="white"
                      // disabled
                      w="full"
                      mt={20}
                      title="Generate Report"
                      onClick={() => fetchReport(Object.keys(bot)[0], startDate, endDate)}
                    />
                  </>
                ) : (
                  <Flex w="full" h="full" pt={12} justifyContent="center" alignItems="center" flexDir="column">
                    <Lottie
                      options={{
                        loop: true,
                        path: 'https://lottie.host/81c5c7b2-2ef6-4dff-a1b3-8430ac3a60e1/6xx96QsW3x.json'
                      }}
                      height={30}
                      width={30}
                    />
                    <EFButton
                      bg="#DE6E4B"
                      isLoading={reportsState.loading}
                      color="white"
                      disabled
                      w="full"
                      mt={16}
                      title="Generate Report"
                    />
                  </Flex>
                )}
              </Flex>
              <Flex w="full" mt={12} flexDir="column" display={['flex', 'flex', 'flex', 'flex', 'none']}>
                <Text fontSize={18} fontWeight="black" mb={4}>
                  Your Report
                </Text>
                <Flex w="full" flexDir="column">
                  <Flex>
                    <Text flex={1} fontSize="14px" fontWeight="black">
                      Bot Name
                    </Text>
                    <Text flex={1} fontSize="14px" fontWeight="black">
                      Report
                    </Text>
                  </Flex>
                  {reportsState.reports.length <= 0 ? (
                    <Flex w="full" mt={16} justifyContent="center" alignItems="center">
                      <Text fontSize="13px">Your generated report will appear here.</Text>
                    </Flex>
                  ) : (
                    reportsState.reports.map((r, idx) => {
                      return (
                        <Flex key={idx} mt={8} borderBottomWidth="1px" pb={4} alignItems="center">
                          <Text flex={1} fontSize="14px">
                            {r[0][Object.keys(r[0])][0].bot_name}
                          </Text>
                          <Flex flex={1} fontSize="14px">
                            <CSVLink
                              data={csvData}
                              filename={`epicflow-${Object.keys(r[0])[0]}`}
                              headers={csvHeaders}
                            >
                              <Flex borderWidth={1} borderRadius="lg" py={2} px={4} fontSize="13px">
                                Download CSV
                              </Flex>
                            </CSVLink>
                          </Flex>
                        </Flex>
                      );
                    })
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    )
  );
};

export default Reports;
