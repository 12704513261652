import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, useColorMode } from '@chakra-ui/react';
import styled from '@emotion/styled';
// import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

import MarketContainer from '../../components/cards/marketCards/marketContainer';
// import MarketNews from '../components/cards/marketCards/marketNews';
import { epicFlowGradientColors } from '../../theme';

const Markets = props => {
  const [marketData, setMarketData] = useState([]);

  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  // const [itemOffset, setItemOffset] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const { colorMode, toggleColorMode } = useColorMode();

  const CustomReactPaginate = useCallback(
    styled(ReactPaginate)`
      display: flex;
      width: 100%;
      justify-content: center;
      list-style-type: none;
      margin: 32px 0 196px 0;
      li {
        margin-right: 4px;
        cursor: pointer;
        padding: 0;
        font-size: 14px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        &:hover {
          background-color: ${colorMode === 'light' ? '#E2E8F0' : '#1A202C'};
        }
        &:first-of-type {
          margin-right: 0;
          &:hover {
            background-color: white;
          }
        }
        &:last-of-type {
          &:hover {
            background-color: white;
          }
          margin-right: 0;
        }
      }
      li.selected {
        background-color: ${epicFlowGradientColors.left};
        color: white;
        &:hover {
          background-color: ${epicFlowGradientColors.left};
        }
      }
      li.disabled {
        color: gray;
      }
      li > a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `,
    [colorMode]
  );

  const fetchMarketData = async page => {
    try {
      setLoading(true);
      const responseGlobal = await fetch('https://api.coingecko.com/api/v3/global');
      const dataGlobal = await responseGlobal.json();
      setTotalPageNumber(Math.ceil(dataGlobal.data.active_cryptocurrencies / 100));
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=${page}&sparkline=true&price_change_percentage=7d`
      );
      const data = await response.json();
      setMarketData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchMarketData(currentPage);
  }, []);

  const handlePageClick = async event => {
    // event.preventdefault();
    await fetchMarketData(event.selected + 1);
    props._containerRef.current.scrollIntoView({ behavior: 'auto' });
    // const newOffset = (event.selected * 100) % items.length;
    // setItemOffset(newOffset);
  };

  return (
    <Flex
      w={['full', 'full', '95%', '95%', '95%', '95%']}
      maxW="7xl"
      direction="column"
      justify="flex-start"
      overflowY="hidden"
      margin="auto"
      mt={[8, 8, 14, 14, 14, 14]}
    >
      {/* <Heading
                as='h1'
                size='md'
            >
                Latest Stories
            </Heading>
            <MarketNews /> */}
      <MarketContainer data={marketData} loading={loading} isMarketCap isCirculatingSupply />
      <CustomReactPaginate
        breakLabel="..."
        nextLabel={<ChevronRightIcon w={4} h={4} />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        pageCount={totalPageNumber}
        className="pagination"
        previousLabel={<ChevronLeftIcon w={4} h={4} />}
        renderOnZeroPageCount={null}
      />
    </Flex>
  );
};

export default Markets;
