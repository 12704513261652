import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Avatar,
  Button,
  chakra,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  Wrap
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Setting from '../components/UI/setting';
import { getUser } from '../store/actions/auth';
import { darkThemeBackgroundColor, epicFlowGradientColors, statusColors } from '../theme';
import axios from '../utilities/axios';
import { calculateExchangeID } from '../utilities/exchangeHelpers';
const API_URL = process.env.REACT_APP_API_URL;

const Settings = props => {
  const dispatch = useDispatch();
  const { colorMode, toggleColorMode } = useColorMode();

  const toast = useToast();

  const [modalContent, setModalContent] = useState();

  const [checkedExchange, setCheckedExchange] = useState();

  const [checkedApi, setCheckedApi] = useState();

  const [prefArr, setPrefArr] = useState([]);

  const [checkedEmailPrefs, setCheckedEmailPrefs] = useState();

  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onConfirmationModalOpen,
    onClose: onConfirmationModalClose
  } = useDisclosure();

  const [tabIndex, setTabIndex] = useState(0);

  const authState = useSelector(state => state.auth);
  const botState = useSelector(state => state.bot);
  const profileState = useSelector(state => state.profile);
  const userState = useSelector(state => state.user);

  const socketState = useSelector(state => state.socket);

  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [modifyType, setModifyType] = useState();
  const [result, setResult] = useState(false);
  const [isNameChanged, setIsNameChanged] = useState(false);
  const [isNameLoading, setIsNameLoading] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [name, setName] = useState('');
  const [curentImage, setCurrentImage] = useState('');
  // const [avatar, setAvatar] = useState(require(localStorage.getItem('avatar')));

  // useEffect(async () => {
  //   // if (botState.activeBotsConfig.length === 0) {
  //   //   // dispatch(getActiveBotsConfig(authState.id || profileState.id));
  //   //   // dispatch(getActiveBotsConfig('0b00fa3e-fb0b-4424-bf93-ed63b0cdf82a'));
  //   // }
  //   console.log('hhhhhh');
  //   dispatch(getActiveBotsConfig(authState.id || profileState.id));
  //   dispatch(getActiveBotsStats(authState.id || profileState.id));
  //   dispatch(getCompletedBots(authState.id || profileState.id));
  //   // dispatch(getActiveBotsStats('0b00fa3e-fb0b-4424-bf93-ed63b0cdf82a'));
  // }, []);

  const handleInput = (e, field) => {
    if (field === 'name') {
      setName(e.target.value);
    }
  };

  useEffect(() => {
    if (authState.emailPreferences?.configuration) {
      let a = JSON.parse(JSON.stringify(authState.emailPreferences?.configuration));
      setCheckedEmailPrefs(a);
    }
  }, [authState.emailPreferences?.configuration]);

  const handleOnchange = idx => {
    if (idx === 3) {
      let a = JSON.parse(JSON.stringify(authState.emailPreferences?.configuration));
      if (authState.emailPreferences?.configuration) {
        setCheckedEmailPrefs(a);
      }
    }
  };

  const handleCheckEmailConfig = (e, option) => {
    let config = { ...checkedEmailPrefs };
    if (option === 'marketing') {
      config.marketing = e.target.checked ? 1 : 0;
    }
    if (option === 'bot_exits') {
      config.bot_updates.bot_exits = e.target.checked ? 1 : 0;
    }
    if (option === 'bot_errors') {
      config.bot_updates.bot_errors = e.target.checked ? 1 : 0;
    }
    if (option === 'bot_summary') {
      config.bot_updates.bot_summary = e.target.checked ? 1 : 0;
    }
    if (option === 'invalid_api_key') {
      config.invalid_api_key = e.target.checked ? 1 : 0;
    }
    setCheckedEmailPrefs(config);
  };

  const confirmEmailPref = async () => {
    try {
      setIsEmailLoading(true);
      //userID email_subscription stripe_
      let body = {
        email_subscription: {
          configuration: { ...checkedEmailPrefs },
          state: authState.emailPreferences.state
        },
        userID: authState.id
      };
      let response = await axios.post(`${process.env.REACT_APP_API_URL}updateemailpreferences`, body);
      if (response.data.success) {
        setIsEmailLoading(false);
        toast({
          title: 'Successful',
          description: 'Your email preferencees has been saved.',
          status: 'success',
          duration: 9000,
          isClosable: true
        });
        dispatch(getUser(authState));
      } else {
        setIsEmailLoading(false);
        toast({
          title: 'Error',
          description: 'An error has been occured.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    } catch (error) {
      setIsEmailLoading(false);
    }
  };

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleApiKey = (event, exc) => {
    if (checkedApi !== exc) {
      setApiKey('');
    } else {
      setApiKey(event.target.value);
    }
  };

  const handleApiSecret = (event, exc) => {
    if (checkedApi !== exc) {
      setApiSecret('');
    } else {
      setApiSecret(event.target.value);
    }
  };

  const handleConfirmationModal = inputType => {
    onConfirmationModalOpen();
    if (inputType === 'deleteexchange') {
      setModalContent({
        action: 'delete_exchange_api',
        buttonColor: 'red.500',
        content:
          'You are about to unlink your exchange permanently. Please make sure you do not have any active bot at the moment.'
      });
      // window.location.reload();
    } else if (inputType === 'modifyexchange') {
      setModalContent({
        action: 'update_exchange_api',
        buttonColor: 'blue.500',
        content:
          'You are about to change your api keys. Please make sure you have entered the correct information.'
      });
    }
  };

  const handleConfirmationModalClose = () => {
    let action = modalContent.action;
    let res = result;
    setModalContent();
    setResult(false);
    setApiKey('');
    setApiSecret('');
    onConfirmationModalClose();
    if (action === 'delete_exchange_api' && res) {
      window.location.replace(process.env.REACT_APP_CLIENT_URL);
    }
  };

  const handleCheckExchange = (e, exc) => {
    if (e.target.checked) {
      setCheckedExchange(exc);
    } else {
      setCheckedExchange();
    }
  };

  const handleCheckApi = (e, exc) => {
    if (e.target.checked) {
      setCheckedApi(exc);
      setApiKey('');
      setApiSecret('');
    } else {
      setCheckedApi();
    }
  };

  const handleDisplayNameChange = useCallback(
    async name => {
      try {
        setIsNameLoading(true);
        const body = {
          uuid: authState.id,
          displayName: name === '' ? authState.name : name
        };
        // console.log('debug', authState.id, name);
        const response = await axios.post(`${process.env.REACT_APP_API_URL}changedisplayname`, body);
        if (response.data.status === 'success') {
          setIsNameLoading(false);
          setIsNameChanged(true);
          toast({
            title: 'Successful',
            description: 'Your display name has been changed.',
            status: 'success',
            duration: 9000,
            isClosable: true
          });
          dispatch(getUser(authState));
        } else {
          setIsNameLoading(false);
          setIsNameChanged(false);
          toast({
            title: 'Error',
            description: 'An error has been occured.',
            status: 'error',
            duration: 9000,
            isClosable: true
          });
          dispatch(getUser(authState));
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    [authState]
  );

  const handleExpansion = bool => {
    if (bool === -1) {
      setIsNameChanged(false);
    }
  };

  const handleBillingInformation = async customerId => {
    try {
      // console.log(customerId);
      const reqBody = { customerId: customerId };
      const portalSession = await axios
        .post(`${API_URL}checkout/create-portal-session`, reqBody)
        .then(response => response.data);
      if (portalSession.url) {
        window.location.replace(`${portalSession.url}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const modifyOrDeleteExchange = useCallback(
    async (key, secret = '', modifyType) => {
      try {
        const id = calculateExchangeID(checkedExchange || checkedApi);
        let body = {};
        setIsApiLoading(true);
        if (id === 5 || id === 6) {
          body = {
            userID: authState.id,
            modifyType: modifyType,
            //TODO make this dynamic
            exchangeID: id,
            apiToken: key
          };
        } else {
          body = {
            userID: authState.id,
            modifyType: modifyType,
            //TODO fix exchange id
            exchangeID: id,
            apiKey: key,
            apiSecret: secret
          };
        }
        const response = await axios.post(`${process.env.REACT_APP_API_URL}modifyexchange`, body);
        setResult(response.data);
        console.log('res ', response.data);
        if (response.data.success) {
          toast({
            title: 'Successful',
            description: 'Your Api Keys has been updated',
            status: 'success',
            duration: 9000,
            isClosable: true
          });
        } else {
          toast({
            title: 'Error',
            description: response.data.error,
            status: 'error',
            duration: 9000,
            isClosable: true
          });
        }
        setIsApiLoading(false);
        setCheckedApi();
      } catch (error) {
        // console.log(error);
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true
        });
        setIsApiLoading(false);
        setCheckedApi();
      }
    },
    [authState.id, checkedExchange, checkedApi]
  );

  return (
    authState.id && (
      <Flex
        w="full"
        // maxW="7xl"
        justify="center"
        mt={[12, 12, 12, 20]}
      >
        <Flex
          w={['full', 'full', 'full', '3xl', '3xl', '3xl']}
          direction="column"
          justify="flex-start"
          minH="100vh"
          align="flex-start"
        >
          <Flex alignItems="center" mb={4} justifyContent="space-between" w="full">
            <Flex alignItems="center">
              {authState.picture && !authState.loading && authState.isUserExistInDB ? (
                <Avatar mr={2} boxSize={12} src={authState.picture} />
              ) : (
                <Flex
                  w="48px"
                  h="48px"
                  justify="center"
                  align="center"
                  m="auto"
                  borderWidth={1}
                  borderRadius="full"
                  borderColor={colorMode === 'light' ? '#000' : '#fff'}
                  // bg={colorMode === 'light' ? 'gray.200' : 'gray.700'}
                  // bg="#e2fcde"
                  // bg={props.faded}
                >
                  <Text fontSize="xl" color={colorMode === 'light' ? '#000' : '#fff'}>
                    {authState.name?.slice(0, 2).toUpperCase()}
                  </Text>
                </Flex>
              )}
              <Flex direction="column" ml={4}>
                <Text fontSize="lg" fontWeight="semibold">
                  {authState.name}
                </Text>
                <Text fontSize="14px" color="gray.500">
                  {authState.email}
                </Text>
              </Flex>
            </Flex>
            {/* <Link
              as={RouterLink}
              to="/subscriptions"
              // borderLeftWidth={props.text !== 'Logout' ? 3 : 0}
              // borderColor={location === props.to.slice(1) && props.text !== 'location' ? props.activeColor : 'transparent'}
              borderRadius="xl"
              textDecoration="none"
              // color={activeColor()}
              // bg="red.400"
              _hover={{
                textDecoration: 'none'
              }}
              _focus={{
                outline: 'none'
              }}
            >
              <Flex
                direction="column"
                borderRadius="lg"
                borderWidth={2}
                w={[32, 32, 56]}
                h={[12, 12, 16]}
                justifyContent="center"
                alignItems="center"
              >
                <Text fontSize="14px" fontWeight="black" bgClip="text" bgGradient="linear(to-r, #1874ff, #00ccff)">
                  Subscribe
                </Text>
              </Flex>
            </Link> */}
          </Flex>
          <Modal
            isOpen={isConfirmationModalOpen}
            onClose={handleConfirmationModalClose}
            isCentered
            returnFocusOnClose={false}
            motionPreset="slideInBottom"
            size="lg"
          >
            <ModalOverlay />
            <ModalContent borderRadius="sm" maxH="sm">
              <ModalCloseButton
                _focus={{
                  outline: 'none'
                }}
              />
              <ModalHeader pl={10} fontWeight="bold" fontSize="xlg" mt={10} pb={0} pt={0}>
                Confirmation
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pt={2} maxH="2xl" pl={10}>
                <Flex direction="column" justify="flex-start" alignItems="center">
                  <Text fontSize="sm" mb={4}>
                    {modalContent?.content}
                  </Text>
                  {!result && (
                    <Flex pb={4}>
                      <Button
                        mr={4}
                        size="sm"
                        fontSize="xs"
                        _focus={{ outline: 'none' }}
                        onClick={handleConfirmationModalClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        fontSize="xs"
                        _focus={{ outline: 'none' }}
                        bg={modalContent?.buttonColor}
                        color="white"
                        onClick={() => modifyOrDeleteExchange(apiKey, apiSecret, modalContent?.action)}
                        _hover={{ background: modalContent?.buttonColor === 'red.500' ? 'red' : '#0162d1' }}
                      >
                        Confirm
                      </Button>
                    </Flex>
                  )}
                  {result ? (
                    <Alert
                      fontSize="sm"
                      status={result.error ? 'error' : 'success'}
                      variant="subtle"
                      mb={4}
                      mt={4}
                    >
                      <AlertIcon />
                      {`${
                        result.error
                          ? result.error
                          : result.success && modalContent?.action === 'delete_exchange_api'
                          ? 'Your exchange has been unlinked. Feel free link it again on Add Exchange Section'
                          : 'Your Api Keys have been changed succesfully.'
                      }`}
                    </Alert>
                  ) : null}
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Tabs
            variant="unstyled"
            w="full"
            onChange={handleOnchange}
            display="flex"
            flexDirection={['column', 'column', 'column', 'row']}
            overflow="hidden"
            mt={8}
          >
            <TabList
              alignItems="center"
              justifyContent="space-between"
              h="fit-content"
              // display={['none', 'none', 'none', 'flex']}
              w={['full', 'full', 'full', 56]}
              pb={[4, 4, 4, 0]}
              pr={[4, 4, 4, 0]}
              mb={[4, 4, 4, 0]}
              overflowX="auto"
            >
              <Flex
                flexDirection={['row', 'row', 'row', 'column']}
                alignItems="flex-start"
                // minW={[['full', 'full', 'full', 56]]}
                pb={[0, 0, 0, 8]}
                mr={[0, 0, 0, 8]}
                borderBottomWidth={[0, 0, 0, 1]}
              >
                <Tab
                  p={0}
                  mr={[4, 4, 4, 0]}
                  // pr={2}
                  _selected={{
                    color: colorMode === 'light' ? 'black' : 'white',
                    fontWeight: 'bold'
                  }}
                  _focus={{
                    outline: 'none'
                  }}
                  fontSize="15px"
                  color="gray.500"
                  mb={[0, 0, 0, 4]}
                >
                  Profile
                </Tab>
                <Tab
                  p={0}
                  mr={[4, 4, 4, 0]}
                  _selected={{
                    color: colorMode === 'light' ? 'black' : 'white',
                    fontWeight: 'bold'
                  }}
                  _focus={{
                    outline: 'none'
                  }}
                  fontSize="15px"
                  mb={[0, 0, 0, 4]}
                  color="gray.500"
                >
                  Exchanges
                </Tab>
                <Tab
                  p={0}
                  mr={[4, 4, 4, 0]}
                  _selected={{
                    color: colorMode === 'light' ? 'black' : 'white',
                    fontWeight: 'bold'
                  }}
                  _focus={{
                    outline: 'none'
                  }}
                  fontSize="15px"
                  mb={[0, 0, 0, 4]}
                  whiteSpace="nowrap"
                  color="gray.500"
                >
                  API Keys
                </Tab>
                <Tab
                  p={0}
                  mr={[4, 4, 4, 0]}
                  _selected={{
                    color: colorMode === 'light' ? 'black' : 'white',
                    fontWeight: 'bold'
                  }}
                  whiteSpace="nowrap"
                  _focus={{
                    outline: 'none'
                  }}
                  fontSize="15px"
                  mb={[0, 0, 0, 4]}
                  color="gray.500"
                >
                  Email Notifications
                </Tab>
                <Tab
                  p={0}
                  mr={[4, 4, 4, 0]}
                  _selected={{
                    color: colorMode === 'light' ? 'black' : 'white',
                    fontWeight: 'bold'
                  }}
                  _focus={{
                    outline: 'none'
                  }}
                  fontSize="15px"
                  mb={[0, 0, 0, 4]}
                  color="gray.500"
                >
                  Billing
                </Tab>
                <Tab
                  p={0}
                  _selected={{
                    color: colorMode === 'light' ? 'black' : 'white',
                    fontWeight: 'bold'
                  }}
                  _focus={{
                    outline: 'none'
                  }}
                  fontSize="15px"
                  color="gray.500"
                >
                  Apperance
                </Tab>
              </Flex>
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                <Flex
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-end"
                  // w={['full', 'full', '60%', '60%', '40%', '40%']}
                >
                  <Setting
                    value={name}
                    title="Display Name"
                    current={authState.name}
                    editable
                    isSuccessful={isNameChanged}
                    isLoading={isNameLoading}
                    handleExpansion={handleExpansion}
                    onChange={e => handleInput(e, 'name')}
                  />
                  <Flex alignItems="center">
                    <Flex alignItems="center">
                      <Button
                        onClick={() => handleDisplayNameChange(name)}
                        w={32}
                        fontSize="sm"
                        isLoading={isNameLoading}
                        bg={colorMode === 'light' ? 'black' : 'white'}
                        color={colorMode === 'light' ? 'white' : 'black'}
                        _focus={{ outline: 'none' }}
                        _hover={{
                          bg: colorMode === 'light' ? 'black' : 'white'
                        }}
                        //   _active={{ background: epicFlowGradientColors.right }}
                      >
                        Save
                      </Button>
                    </Flex>
                  </Flex>
                  {/* <Setting
                  option="Change Your Avatar"
                  image
                  onAvatarClick={handleImageClick}
                  onClick={() => handleAvatarSave(curentImage)}
                /> */}
                  {/* <Setting option="Date of Birth" current="04/28/1989" editable /> */}
                </Flex>
              </TabPanel>
              <TabPanel p={0} pt={6}>
                <Flex
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  // w={['full', 'full', '60%', '60%', '40%', '40%']}
                  w="full"
                >
                  <Text fontSize="16px" fontWeight="bold">
                    My Exchanges
                  </Text>
                  <Divider mt={4} mb={8} />
                  {authState.exchanges?.map((exc, idx) => {
                    return (
                      <Flex direction="column" justify="flex-start" key={idx} w="full">
                        {/* <Text fontSize="md" my={2} fontWeight="semibold">
                        {exc}
                      </Text> */}
                        {/* <Setting
                        option="Exchange"
                        current={exc}
                        // onClick={() => modifyOrDeleteExchange(apiKey, apiSecret, 'delete_exchange_api')}
                      /> */}
                        <Checkbox
                          size="lg"
                          mb={idx === 0 ? 3 : 0}
                          isChecked={checkedExchange === exc}
                          onChange={e => handleCheckExchange(e, exc)}
                          css={`
                            > span:first-of-type {
                              box-shadow: unset;
                              border-radius: 20%;
                              background: ${checkedExchange === exc ? '#F56565;' : ''};
                              border: ${checkedExchange === exc
                                ? 'none;'
                                : colorMode === 'light'
                                ? '1px solid #cbd5e0;'
                                : '1px solid #4a5568;'};
                            }
                            > span:first-of-type:hover {
                              background: ${checkedExchange === exc ? '#F56565;' : ''};
                            }
                            > span:nth-child(3) {
                              font-size: 15px;
                              margin-left: 12px;
                            }
                          `}
                        >
                          {exc}
                        </Checkbox>
                        {idx === 0 && <Divider mb={6} />}
                      </Flex>
                    );
                  })}
                  <Flex alignItems="center" mt={6} w="full" justifyContent="flex-end">
                    <Flex alignItems="center">
                      <Button
                        onClick={() => handleConfirmationModal('deleteexchange')}
                        w={48}
                        fontSize="sm"
                        bg="red.500"
                        color="white"
                        disabled={!checkedExchange}
                        _hover={{ bg: 'red' }}
                        _focus={{ outline: 'none' }}
                        //   _active={{ background: epicFlowGradientColors.right }}
                      >
                        Delete Exchange
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </TabPanel>
              <TabPanel p={0} pt={6}>
                <Flex
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  // w={['full', 'full', '60%', '60%', '40%', '40%']}
                >
                  <Text fontSize="16px" fontWeight="bold">
                    My API Keys
                  </Text>
                  <Divider mt={4} mb={8} />
                  <Flex justify="space-between" alignItems="center" w="full" mb={2} flexDirection="column">
                    {authState.exchanges?.map((exc, idx) => {
                      return (
                        <Flex direction="column" justify="flex-start" key={idx} w="full">
                          <Checkbox
                            size="lg"
                            mb={3}
                            isChecked={checkedApi === exc}
                            onChange={e => handleCheckApi(e, exc)}
                            css={`
                              > span:first-of-type {
                                box-shadow: unset;
                                border-radius: 20%;
                                background: ${checkedApi === exc ? '#F56565;' : ''};
                                border: ${checkedApi === exc
                                  ? 'none;'
                                  : colorMode === 'light'
                                  ? '1px solid #cbd5e0;'
                                  : '1px solid #4a5568;'};
                              }
                              > span:first-of-type:hover {
                                background: ${checkedApi === exc ? '#F56565;' : ''};
                              }
                              > span:nth-child(3) {
                                font-size: 15px;
                                margin-left: 12px;
                              }
                            `}
                          >
                            {exc}
                          </Checkbox>
                          <Divider mb={6} />
                          <Setting
                            title="API Key"
                            option="API Keys"
                            current="*****"
                            editable
                            isDisabled={checkedApi !== exc}
                            isApiSettings={
                              calculateExchangeID(authState.currentOption) === 5 ||
                              calculateExchangeID(authState.currentOption) === 6
                                ? false
                                : true
                            }
                            onChange={e => handleApiKey(e, exc)}
                            value={checkedApi == exc ? apiKey : ''}
                            secretValue={checkedApi == exc ? apiSecret : ''}
                            secretCurrent="*****"
                            // onClick={() => modifyOrDeleteExchange(apiKey, apiSecret, 'update_exchange_api')}
                            onSecretChange={e => handleApiSecret(e, exc)}
                          />
                        </Flex>
                      );
                    })}
                  </Flex>
                  <Flex justifyContent="flex-end" w="full">
                    <Flex alignItems="flex-end">
                      <Button
                        onClick={() => modifyOrDeleteExchange(apiKey, apiSecret, 'update_exchange_api')}
                        w={32}
                        fontSize="sm"
                        isLoading={isApiLoading}
                        disabled={!checkedApi || isApiLoading}
                        bg={colorMode === 'light' ? 'black' : 'white'}
                        color={colorMode === 'light' ? 'white' : 'black'}
                        _focus={{ outline: 'none' }}
                        _hover={{
                          bg: colorMode === 'light' ? 'black' : 'white'
                        }}
                        _active={{
                          bg: colorMode === 'light' ? 'black' : 'white'
                        }}
                        //   _active={{ background: epicFlowGradientColors.right }}
                      >
                        Update API
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </TabPanel>
              <TabPanel p={0} pt={6}>
                {checkedEmailPrefs && (
                  <Flex
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                    // w={['full', 'full', '60%', '60%', '40%', '40%']}
                  >
                    <Flex alignItems="flex-start" w="full" flexDirection="column">
                      {Object.keys(authState.emailPreferences.configuration).map((el, idx) => {
                        return (
                          <Flex
                            key={idx}
                            justify="space-between"
                            alignItems="flex-start"
                            w="full"
                            mt={idx !== 0 ? 12 : 0}
                            flexDirection="column"
                          >
                            <Text fontSize="16px" fontWeight="semibold">
                              {el === 'marketing'
                                ? 'Marketing'
                                : el === 'bot_updates'
                                ? 'Bot Updates'
                                : 'API Update'}
                            </Text>
                            <Divider mt={4} mb={4} />
                            <Text mb={4} fontSize="14px">
                              {el === 'marketing'
                                ? 'Notify me:'
                                : el === 'bot_updates'
                                ? 'Email me when:'
                                : 'Send me:'}
                            </Text>
                            {Object.keys(authState.emailPreferences.configuration[el]).length > 1 ? (
                              Object.keys(authState.emailPreferences.configuration[el]).map((a, i) => {
                                return (
                                  <Checkbox
                                    key={i}
                                    size="lg"
                                    mb={3}
                                    isChecked={checkedEmailPrefs.bot_updates[a] === 1}
                                    onChange={e => handleCheckEmailConfig(e, a)}
                                    css={`
                                      > span:first-of-type {
                                        box-shadow: unset;
                                        border-radius: 20%;
                                        background: ${checkedEmailPrefs.bot_updates[a] === 1 ? '#F56565;' : ''};
                                        border: ${checkedEmailPrefs.bot_updates[a] === 1
                                          ? 'none;'
                                          : colorMode === 'light'
                                          ? '1px solid #cbd5e0;'
                                          : '1px solid #4a5568;'};
                                      }
                                      > span:first-of-type:hover {
                                        background: ${checkedEmailPrefs.bot_updates[a] === 1 ? '#F56565;' : ''};
                                      }
                                      > span:nth-child(3) {
                                        font-size: 15px;
                                        margin-left: 12px;
                                      }
                                    `}
                                  >
                                    {a === 'bot_exits'
                                      ? 'one of your bots completes a run'
                                      : a === 'bot_errors'
                                      ? 'there is a bot error'
                                      : 'daily/weekly bot summary is ready'}
                                  </Checkbox>
                                );
                              })
                            ) : (
                              <Checkbox
                                size="lg"
                                mb={3}
                                isChecked={checkedEmailPrefs[el] === 1}
                                onChange={e => handleCheckEmailConfig(e, el)}
                                css={`
                                  > span:first-of-type {
                                    box-shadow: unset;
                                    border-radius: 20%;
                                    background: ${checkedEmailPrefs[el] === 1 ? '#F56565;' : ''};
                                    border: ${checkedEmailPrefs[el] === 1
                                      ? 'none;'
                                      : colorMode === 'light'
                                      ? '1px solid #cbd5e0;'
                                      : '1px solid #4a5568;'};
                                  }
                                  > span:first-of-type:hover {
                                    background: ${checkedEmailPrefs[el] === 1 ? '#F56565;' : ''};
                                  }
                                  > span:nth-child(3) {
                                    font-size: 15px;
                                    margin-left: 12px;
                                  }
                                `}
                              >
                                {el === 'marketing'
                                  ? 'Epicflow.io news, announcements, and product updates'
                                  : 'When invalid API update'}
                              </Checkbox>
                            )}
                          </Flex>
                        );
                      })}
                      <Flex justifyContent="flex-end" w="full" mt={4}>
                        <Flex alignItems="flex-end">
                          <Button
                            onClick={confirmEmailPref}
                            w={48}
                            fontSize="sm"
                            isLoading={isEmailLoading}
                            disabled={isEmailLoading}
                            bg={colorMode === 'light' ? 'black' : 'white'}
                            color={colorMode === 'light' ? 'white' : 'black'}
                            _focus={{ outline: 'none' }}
                            _hover={{
                              bg: colorMode === 'light' ? 'black' : 'white'
                            }}
                            _active={{
                              bg: colorMode === 'light' ? 'black' : 'white'
                            }}
                            //   _active={{ background: epicFlowGradientColors.right }}
                          >
                            Update Notifications
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </TabPanel>
              <TabPanel p={0} pt={6}>
                <Flex
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  // w={['full', 'full', '60%', '60%', '40%', '40%']}
                >
                  <Flex justify="space-between" alignItems="center" w="full" mb={2}>
                    <Text fontSize="sm" fontWeight="semibold">
                      {authState.stripeCurrentPlan} Member
                    </Text>
                    <Button
                      _focus={{ outline: 'none' }}
                      px={4}
                      py={2}
                      transition="all 0.2s"
                      backgroundColor="transparent"
                      borderRadius="md"
                      fontSize="sm"
                      borderWidth="1px"
                      fontWeight="normal"
                      onClick={() => handleBillingInformation(authState.stripeCustomerId)}
                    >
                      Manage Subscriptions
                    </Button>
                  </Flex>
                  <Divider mt={2} />
                </Flex>
              </TabPanel>
              <TabPanel p={0} pt={6}>
                <Flex
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  // w={['full', 'full', '60%', '60%', '40%', '40%']}
                >
                  <Flex justify="space-between" alignItems="center" w="full" mb={2}>
                    <Text fontSize="sm" fontWeight="semibold">
                      Theme
                    </Text>
                    <Menu>
                      <MenuButton
                        _focus={{ outline: 'none' }}
                        px={4}
                        py={2}
                        transition="all 0.2s"
                        borderRadius="md"
                        fontSize="sm"
                        bg={colorMode === 'light' ? 'white' : 'black'}
                        borderWidth="1px"
                      >
                        <Flex alignItems="center">
                          <Text mr={1}>{capitalizeFirstLetter(colorMode)}</Text>
                          <ChevronDownIcon />
                        </Flex>
                      </MenuButton>
                      <MenuList bg={colorMode === 'light' ? 'white' : darkThemeBackgroundColor.dark}>
                        <MenuItem fontSize="sm">{capitalizeFirstLetter(colorMode)}</MenuItem>
                        <MenuItem onClick={toggleColorMode} fontSize="sm">
                          {colorMode === 'light' ? 'Dark' : 'Light'}
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                  <Divider mt={2} />
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
    )
  );
};

Settings.propTypes = {};

export default Settings;
