import { Flex, Heading } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const SignupEmail = props => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isSignupProcess, setIsSignupProcess] = useState(localStorage.getItem('isSignupProcess'));
  const authState = useSelector(state => state.auth);

  // useEffect(() => {

  //   return props.history.push('/login');
  // }, [props]);

  const response = useMemo(() => {
    if (token && authState.isSignupFlow) {
      return (
        <Flex w="full" height="100vh" justify="center" align="center">
          <Heading fontSize="lg">
            Please check your email for confirmation mail. Click link in email to verify your account.
          </Heading>
        </Flex>
      );
    } else {
      props.history.push('/login');
      return null;
    }
  }, [props]);

  return response;
};

export default SignupEmail;
