import axios from '../../utilities/axios';
import * as actions from '../actions/actionTypes';
const API_URL = process.env.REACT_APP_API_URL;

//const DEV_API_URL = 'http://localhost:5020/';

const fetchProfileStart = () => {
  return {
    type: actions.FETCH_PROFILE_DATA_START,
    loading: true
  };
};

const fetchProfileError = err => {
  return {
    type: actions.FETCH_PROFILE_DATA_FAIL,
    loading: false,
    error: err
  };
};

const fetchProfileSuccess = user => {
  return {
    type: actions.FETCH_PROFILE_DATA_SUCCESS,
    name: user.full_name,
    email: user.email,
    id: user.id,
    verified: user.verified,
    role: user.role,
    loading: false,
    error: null
  };
};

export const getUser = (id, token) => {
  return async dispatch => {
    try {
      dispatch(fetchProfileStart());
      const body = {
        id: id,
        token: token
      };
      const response = await axios.post(`${API_URL}`, body);
      dispatch(fetchProfileSuccess(response.data));
      return response.data;
    } catch (error) {
      console.log(error);
      // dispatch(fetchProfileError(response.data.errors.msg));
    }
  };
};

const addExchangeStart = () => {
  return {
    type: actions.ADD_EXCHANGE_START,
    addExchangeLoading: true,
    addExchangeStatus: null,
    error: null
  };
};

const addExchangeFail = err => {
  return {
    type: actions.ADD_EXCHANGE_FAIL,
    addExchangeLoading: false,
    addExchangeStatus: null,
    error: err
  };
};

const addExchangeSuccess = response => {
  return {
    type: actions.ADD_EXCHANGE_SUCCESS,
    addExchangeLoading: false,
    addExchangeStatus: response
    //needs to be fixed if more exchanges are supported
    // exhange: id === 1 ? 'binanceus' : id === 2 ? 'coinbase' : 'binance'
  };
};

const getExchangeStart = () => {
  return {
    type: actions.GET_EXCHANGE_START,
    getExchangeLoading: true
  };
};

const getExchangeFail = err => {
  return {
    type: actions.GET_EXCHANGE_FAIL,
    getExchangeLoading: false,
    error: err
  };
};

const getExchangeSuccess = arr => {
  return {
    type: actions.GET_EXCHANGE_SUCCESS,
    getExchangeLoading: false,
    //needs to be fixed if more exchanges are supported
    exchanges: arr,
    currentOption: arr[0],
    isExchangesChecked: true
  };
};

const setCurrentOption = option => {
  return {
    type: actions.PROFILE_SET_CURRENT_OPTION,
    currentOption: option
  };
};

export const getCurrentOption = option => {
  return dispatch => {
    dispatch(setCurrentOption(option));
  };
};

export const getExchange = userID => {
  return async dispatch => {
    try {
      dispatch(getExchangeStart());
      const body = { userID: userID };
      const response = await axios.post(`${API_URL}getexchange`, body);
      const exchanges = [];
      if (response.data.length > 0) {
        for (let exchange of response.data) {
          if (exchange === 1) {
            exchanges.push('Binance US');
          } else if (exchange === 2) {
            exchanges.push('Coinbase');
          } else if (exchange === 3) {
            exchanges.push('Binance');
          } else if (exchange === 5) {
            exchanges.push('Oanda Demo');
          } else if (exchange === 6) {
            exchanges.push('Oanda Live');
          }
        }
      }
      dispatch(getExchangeSuccess(exchanges));
    } catch (error) {
      dispatch(getExchangeFail());
    }
  };
};

export const addExchange = data => {
  return async dispatch => {
    try {
      dispatch(addExchangeStart());
      const setExchange = () => {
        if (data.exchange === 'binanceus') {
          return 1;
        } else if (data.exchange === 'coinbase') {
          return 2;
        } else if (data.exchange === 'binance') {
          return 3;
        } else if (data.exchange === 'oandademo') {
          return 5;
        } else if (data.exchange === 'oandalive') {
          return 6;
        }
      };
      const body = { ...data, exchangeID: setExchange() };
      const response = await axios.post(`${API_URL}addexchange`, body);
      if (response.data.success) {
        dispatch(addExchangeSuccess(response.data));
        dispatch(getExchange(data.userID));
      } else if (response.data.error) {
        dispatch(addExchangeFail(response.data.error));
      }
    } catch (error) {
      dispatch(addExchangeFail(error));
    }
  };
};
