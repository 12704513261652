import * as actionTypes from '../actions/actionTypes';

const initialState = {
  name: null,
  email: null,
  id: null,
  refId: null,
  // isSignupFlow: false,
  // token: null,
  verified: false,
  usedRefId: null,
  exchanges: [],
  loading: false,
  isSignUpSuccess: false,
  isExchangesChecked: false,
  tosVersion: null,
  stripeCurrentPlan: null,
  stripeCustomerId: null,
  currentOption: null,
  isUserExistInDB: false,
  isUserExistenceChecked: false,
  picture: null,
  userCheckLoading: false,
  emailPreferences: null,
  approved: null,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_SIGNUP_START:
      return {
        ...state,
        loading: action.loading
      };
    case actionTypes.AUTH_SIGNUP_FAIL:
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    case actionTypes.AUTH_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: action.loading,
        // isSignupFlow: action.isSignupFlow,
        isSignUpSuccess: action.isSignUpSuccess
        // token: action.token
      };
    case actionTypes.AUTH_START:
      return {
        ...state,
        loading: action.loading
      };
    case actionTypes.AUTH_FAIL:
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        name: action.name,
        id: action.id,
        refId: action.refId,
        email: action.email,
        emailPreferences: action.emailPreferences,
        token: action.token,
        error: action.error,
        isExchangesChecked: action.isExchangesChecked,
        loading: action.loading,
        verified: action.verified,
        usedRefId: action.usedRefId,
        tosVersion: action.tosVersion,
        exchanges: action.ecxs,
        picture: action.picture,
        stripeCustomerId: action.stripeCustomerId,
        stripeCurrentPlan: action.stripeCurrentPlan,
        currentOption: action.currentOption,
        approved: action.approved,
        currentOption: action.currentOption
      };
    case actionTypes.AUTH_LOGOUT:
      return state;
    case actionTypes.PROFILE_SET_CURRENT_OPTION:
      return {
        ...state,
        currentOption: action.currentOption
      };
    case actionTypes.GET_USER_START:
      return {
        ...state,
        userCheckLoading: action.loading
      };
    case actionTypes.GET_USER_FAIL:
      return {
        ...state,
        userCheckLoading: action.loading
      };
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        userCheckLoading: action.loading,
        isUserExistInDB: action.isUserExistInDB,
        isUserExistenceChecked: action.isUserExistenceChecked,
        emailPreferences: action.emailPreferences,
        tosVersion: action.tosVersion,
        name: action.name
      };
    case actionTypes.AUTH_SET_CURRENT_OPTION:
      return {
        ...state,
        currentOption: action.currentOption
      };
    default:
      return state;
  }
};

export default reducer;
