import { Flex, Heading, Icon, Skeleton } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { v4 as uuidv4 } from 'uuid';

import { convertToInternationalCurrencySystem } from '../../../utilities/currencyConverter';
import MarketAssetCard from './marketAssetsCard';

const MarketContainer = props => {
  const mobileDisplayOptions = ['none', 'none', 'flex', 'flex', 'flex', 'flex'];

  const [currentOrder, setCurrentOrder] = useState(true);

  const [currentOrdeBy, setCurrentOrderBy] = useState('market_cap_rank');

  const [skeletonArray, setSkeletonArray] = useState(new Array(10).fill(undefined));

  const orderIcon = useMemo(() => {
    if (currentOrder) {
      return <Icon as={AiOutlineCaretDown} w={3} h={3} ml={1} />;
    } else {
      return <Icon as={AiOutlineCaretUp} w={3} h={3} ml={1} />;
    }
  }, [currentOrder]);

  const handleRouting = useCallback(name => {
    if (name === 'US Dollar') {
      return '';
    } else {
      return `/cryptocurrencies/${name.toLowerCase().replace(/\s/g, '').split('').join('')}`;
    }
  }, []);

  const order = useCallback(
    (orderBy, orderType) => {
      if (orderType) {
        if (orderBy === 'name') {
          props.data.sort((a, b) => {
            return a[orderBy].toLowerCase() > b[orderBy].toLowerCase() ? 1 : -1;
          });
        } else {
          props.data.sort((a, b) => {
            return a[orderBy] - b[orderBy];
          });
        }
        setCurrentOrderBy(orderBy);
        setCurrentOrder(true);
      } else {
        if (orderBy === 'name') {
          props.data.sort((a, b) => {
            return b[orderBy].toLowerCase() > a[orderBy].toLowerCase() ? 1 : -1;
          });
        } else {
          props.data.sort((a, b) => {
            return b[orderBy] - a[orderBy];
          });
        }
        setCurrentOrderBy(orderBy);
        setCurrentOrder(false);
      }
    },
    [props.data]
  );

  return (
    <Flex justify="flex-start" align="center" direction="column" w="full" m="auto" position={props.position}>
      <Heading as="h1" size="md" mt={12} textAlign="left" mb={8} w="95%">
        Today Top Market
      </Heading>
      <Flex w="95%" m="auto" pt={5} pb={5} justify="space-between" alignItems="center">
        <Flex
          display={mobileDisplayOptions}
          align="center"
          justifyContent="flex-start"
          w="30%"
          cursor="pointer"
          onClick={() => order('market_cap_rank', !currentOrder)}
        >
          <Heading fontSize="xs" fontWeight="extrabold">
            #
          </Heading>
          {currentOrdeBy === 'market_cap_rank' ? orderIcon : null}
        </Flex>
        <Flex
          w="full"
          order={0}
          justify="flex-start"
          align="center"
          mr={8}
          cursor="pointer"
          onClick={() => order('name', !currentOrder)}
        >
          <Heading fontSize="xs" fontWeight="extrabold" textAlign="left">
            Name
          </Heading>
          {currentOrdeBy === 'name' ? orderIcon : null}
        </Flex>
        <Flex
          w="full"
          order={[2, 2, 1, 1, 1, 1]}
          justify="flex-start"
          align="center"
          cursor="pointer"
          onClick={() => order('current_price', !currentOrder)}
        >
          <Heading
            w="full"
            fontSize="xs"
            fontWeight="extrabold"
            textAlign={['right', 'right', 'left', 'left', 'left', 'left']}
          >
            Price
          </Heading>
          {currentOrdeBy === 'current_price' ? orderIcon : null}
        </Flex>
        <Flex
          display={mobileDisplayOptions}
          w="full"
          justify="flex-start"
          align="center"
          order={2}
          cursor="pointer"
          onClick={() => order('price_change_percentage_24h', !currentOrder)}
        >
          <Heading fontSize="xs" fontWeight="extrabold" textAlign="left">
            24h %
          </Heading>
          {currentOrdeBy === 'price_change_percentage_24h' ? orderIcon : null}
        </Flex>
        <Flex
          display={mobileDisplayOptions}
          w="full"
          justify="flex-start"
          align="center"
          order={3}
          cursor="pointer"
          onClick={() => order('total_volume', !currentOrder)}
        >
          <Heading fontSize="xs" fontWeight="extrabold" textAlign="left">
            Volume 24h
          </Heading>
          {currentOrdeBy === 'total_volume' ? orderIcon : null}
        </Flex>
        {props.isMarketCap && (
          <Flex
            display={['none', 'none', 'none', 'flex', 'flex', 'flex']}
            w="full"
            justify="flex-start"
            align="center"
            order={4}
            cursor="pointer"
            onClick={() => order('market_cap', !currentOrder)}
          >
            <Heading fontSize="xs" fontWeight="extrabold" textAlign="left">
              Market Cap
            </Heading>
            {currentOrdeBy === 'market_cap' ? orderIcon : null}
          </Flex>
        )}
        {props.isCirculatingSupply && (
          <Flex
            display={['none', 'none', 'none', 'none', 'flex', 'flex']}
            w="full"
            order={5}
            cursor="pointer"
            onClick={() => order('circulating_supply', !currentOrder)}
            justify="flex-start"
            align="flex-start"
          >
            <Heading fontSize="xs" fontWeight="extrabold" textAlign="left">
              Circulating Supply
            </Heading>
            {currentOrdeBy === 'circulating_supply' ? orderIcon : null}
          </Flex>
        )}
        <Heading
          minW={36}
          maxW={36}
          fontSize="xs"
          fontWeight="extrabold"
          // w='full'
          order={[1, 1, 4, 5, 6, 6]}
          textAlign={['center', 'center', 'right', 'right', 'right', 'right']}
          // cursor='pointer'
          // onClick={() => order('price_change_percentage_7d_in_currency', !currentOrder)}
        >
          Last 7 Days
        </Heading>
      </Flex>
      <Flex w="full" direction="column">
        {!props.loading ? (
          props.data?.map((asset, idx) => {
            return (
              <MarketAssetCard
                key={uuidv4()}
                isMarketCap={props.isMarketCap}
                isCirculatingSupply={props.isCirculatingSupply}
                top
                index={idx}
                length={props.data.length}
                cap={asset.market_cap_rank}
                name={asset.name}
                imgUrl={asset.image}
                // to={handleRouting(asset.id)}
                ticker={asset.symbol.toUpperCase()}
                dailyUp={asset.price_change_percentage_24h >= 0}
                price={asset.current_price}
                marketCap={convertToInternationalCurrencySystem(asset.market_cap)}
                volumeDay={convertToInternationalCurrencySystem(asset.total_volume)}
                circulatingSupply={convertToInternationalCurrencySystem(asset.circulating_supply)}
                changeDay={asset.price_change_percentage_24h}
                sparklineData={asset.sparkline_in_7d.price}
                weekUp={
                  asset.sparkline_in_7d.price[asset.sparkline_in_7d.price.length - 1] >=
                  asset.sparkline_in_7d.price[0]
                }
              />
            );
          })
        ) : (
          <Flex direction="column">
            {skeletonArray.map((el, idx) => {
              return <Skeleton w="full" h="90px" mb={4} key={idx} />;
            })}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

MarketContainer.propTypes = {
  data: PropTypes.array
};

export default MarketContainer;
