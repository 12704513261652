import { chakra, Flex, Heading, Icon, Text, Tooltip, useColorMode } from '@chakra-ui/react';
import styled from '@emotion/styled';
import ProgressBar from '@ramonak/react-progress-bar';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { MdOutlineShowChart } from 'react-icons/md';
import { RiInformationLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import Reel from 'react-reel';

import { epicFlowGradientColors } from '../../theme';
// import { usdFormatter } from '../../utilities/currencyConverter';
import { dynamicCurrencyFormatter, usdFormatter } from '../../utilities/currencyConverter';
import Dropdown from './Dropdown/dropdown';

// import theme from './theme.css';

const PortfolioTitle = props => {
  const { colorMode, toggleColorMode } = useColorMode();
  const userState = useSelector(state => state.user);
  const CustomProgressBar = useCallback(
    styled(ProgressBar)`
      & {
        border: 3px solid blue;
        border-radius: '50px';
      }
    `,
    [colorMode]
  );

  const reelTheme = {
    reel: {
      height: '2.5rem',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      letterSpacing: '-1px',
      overflowY: 'hidden',
      fontSize: '2.5rem',
      // fontWeight: "300",
      lineHeight: '0.95em'
      // background: 'linear-gradient(to right, #12c2e9, #c471ed, #f64f59)',
      // WebkitBackgroundClip: 'text',
      // backgroundClip: 'text',
      // WebkitTextFillColor: 'transparent'
    },
    number: {
      height: '1em',
      fontWeight: '600'
      // WebkitBackgroundClip: 'text',
      // backgroundClip: 'text',
      // color: 'transparent',
      // background: 'red'
    }
  };

  return (
    <Flex direction="column" justify="center" align="flex-start" {...props} w="full">
      <Flex w="full" justifyContent="space-between" alignItems="flex-start">
        <Flex direction="column">
          <Text fontSize="2xl" fontWeight="bold" lineHeight="shorter">
            {props.title}
          </Text>
          {/* <Heading>{props.usd}</Heading>{' '} */}
          {/* <Flex mt={2}>
      </Flex> */}
          <Text
            fontSize={['32px', '32px', '40px']}
            fontWeight="black"
            letterSpacing="wider"
            lineHeight="short"
            background="linear-gradient(to right, #12c2e9, #c471ed, #f64f59)"
            backgroundClip="text"
            h={props.usd === ' ' && 49.5}
            style={{ WebkitTextFillColor: 'transparent' }}
          >
            {/* {String(props.usd)} */}
            {props.oanda && userState.baseCurrency ? String(props.currency) : String(props.crypto)}
          </Text>
          {/* <Reel text={String(props.usd)} theme={reelTheme}></Reel> */}
        </Flex>
        {props.isExchangeArray && (
          <Dropdown
            menuitems={props.menuitems}
            fontSize="xs"
            functional
            flexW={32}
            py="18px"
            autoHeight={props.autoHeight}
            selected={props.selected}
            value={props.value}
            radius="full"
            shadow="sm"
            fontWeight="medium"
          />
        )}
      </Flex>

      {/* <Text fontSize="13px" fontWeight="normal" my={2}>
        {props.timerange}
      </Text> */}
      <Flex
        w="full"
        h={['', '', '', '', '', 8]}
        alignItems={['flex-start', 'flex-start', 'flex-start', 'flex-start', 'flex-start', 'center']}
        justifyContent="space-between"
      >
        <Flex w="fit-content" flexDirection={['column', 'column', 'column', 'row', 'row', 'row']}>
          {props?.portchange && (
            <Flex fontSize="0.8125rem" fontWeight="semibold" mt={2} alignItems="center">
              <Flex alignItems="center">
                <Text fontSize="13px">
                  {/* {console.log(
                    'portchange:',
                    props.portchange,
                    'userstate:',
                    userState.baseCurrency,
                    'props:',
                    props
                  )} */}
                  {props.portchange || props.portchange === 0 ? (
                    <>
                      <chakra.span color={props.portperfcolor}>
                        {props.portchange > 0 ? '+' : props.portchange < 0 ? '-' : ''}
                        {props.portchange < 0
                          ? dynamicCurrencyFormatter(userState.baseCurrency).format(
                              String(props.portchange).slice(1)
                            )
                          : dynamicCurrencyFormatter(userState.baseCurrency).format(String(props.portchange))}{' '}
                      </chakra.span>
                      <chakra.span color={props.portperfcolor}>
                        ({props.portperf > 0 ? '+' : props.portperf < 0 ? '-' : ''}
                        {props.portperf < 0 ? String(props.portperf).slice(1) : String(props.portperf)}%)
                      </chakra.span>
                    </>
                  ) : null}
                </Text>
              </Flex>
              {/* <Text fontSize="13px" fontWeight="normal" ml={2}>
            NAV
          </Text> */}
              {props?.oanda && (
                <Text fontSize="13px" fontWeight="normal" ml={2}>
                  NAV
                </Text>
              )}
              {/* <Flex
                w="5px"
                h={4}
                bg="#4361ee"
                ml={1}
                justify="flex-start"
                align="center"
                borderRightRadius="md"
              ></Flex> */}
              {props.oanda && <Icon as={MdOutlineShowChart} boxSize={5} color="#4361ee" ml={1} pb="2px" />}
            </Flex>
          )}
          {props.balance && props.balancechange && props.oanda && (
            <Flex fontSize="0.8125rem" fontWeight="semibold" ml={[0, 0, 0, 16]} mt={2} alignItems="center">
              <Flex alignItems="center">
                <Text fontSize="13px">
                  {props.balancechange || props.balancechange === 0 ? (
                    <>
                      <chakra.span color={props.portbalancecolor}>
                        {props.balancechange > 0 ? '+' : props.balancechange < 0 ? '-' : ''}
                        {props.balancechange < 0
                          ? dynamicCurrencyFormatter(userState.baseCurrency).format(
                              String(props.balancechange).slice(1)
                            )
                          : dynamicCurrencyFormatter(userState.baseCurrency).format(
                              String(props.balancechange)
                            )}{' '}
                      </chakra.span>
                      <chakra.span color={props.portbalancecolor}>
                        ({props.portbalance > 0 ? '+' : props.portbalance < 0 ? '-' : ''}
                        {props.portbalance < 0 ? String(props.portbalance).slice(1) : String(props.portbalance)}%)
                      </chakra.span>
                    </>
                  ) : null}
                </Text>
              </Flex>
              <Text fontSize="13px" fontWeight="normal" ml={2}>
                Balance
              </Text>
              {/* <Flex
                w="5px"
                h={4}
                bg="#48BB78"
                ml={1}
                justify="flex-start"
                align="center"
                borderRightRadius="md"
              ></Flex> */}
              <Icon as={MdOutlineShowChart} boxSize={5} color="#48BB78" ml={1} pb="2px" />

              {/* <Text fontSize="13px" fontWeight="normal" ml={2}>
              {props.timerange}
            </Text> */}
            </Flex>
          )}
          {/* {props.margin && props.marginchange && (
            <Flex fontSize="0.8125rem" ml={[0, 0, 0, 0, 0, 16]} fontWeight="semibold" mt={2} alignItems="center">
              <Flex w={2} h={2} borderRadius="full" alignItems="center" bg="#ffbd00" />
              <Text fontSize="13px" fontWeight="normal" ml={2}>
                Available Margin
              </Text>
              <Text fontSize="0.8125rem" fontWeight="bold" ml={2}>
                {usdFormatter.format(props.marginAvailable)}
              </Text>
            </Flex>
          )} */}
        </Flex>
        <Text fontSize="13px" fontWeight="bold">
          {props.timerange}
        </Text>
      </Flex>
    </Flex>
  );
};

PortfolioTitle.propTypes = {
  title: PropTypes.string.isRequired,
  usd: PropTypes.string || PropTypes.number,
  botchange: PropTypes.number || PropTypes.string,
  portperf: PropTypes.number || PropTypes.string,
  portperfcolor: PropTypes.string,
  portchange: PropTypes.number,
  botperf: PropTypes.number || PropTypes.string,
  botperfcolor: PropTypes.string,
  botchangedescription: PropTypes.string,
  changepercentage: PropTypes.string || PropTypes.number,
  timerange: PropTypes.string
};

export default PortfolioTitle;
